import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
} from '@material-ui/core'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
import { CustomLabel, CustomInput, useStyles } from './fields.style'
import { ReactComponent as IconWarning } from '../../../../theme/icons/warrning.svg'

export const PasswordRepeatType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
  isGridable = true,
  classes = {},
  width = '100%',
}) => {
  const [repeat, setRepeat] = useState({
    value: null,
    error: false,
  })

  const handleChange = {
    password: e => {
      const passwordValue = getValue(e.target.value)
      setValue(name, passwordValue || null)
      validateField(passwordValue, repeat.value)
    },
    repeat: e => {
      const repeatValue = getValue(e.target.value)
      setRepeat(state => ({ ...state, value: repeatValue || null }))
      validateField(value, repeatValue)
    },
  }

  const validateField = useCallback(
    (passwordValue, repeatValue) => {
      const isPasswordValid = validate(validators, passwordValue)

      setError(name, !isPasswordValid.result && isPasswordValid.message)

      const isRepeatValid = {
        result: passwordValue === repeatValue,
        message:
          passwordValue === repeatValue ||
          'T_VALIDATION_PASSWORDS_DO_NOT_MATCH',
      }

      setRepeat(state => ({
        ...state,
        error: !isRepeatValid.result && isRepeatValid.message,
      }))

      setError(name + 'Repeat', !isRepeatValid.result && isRepeatValid.message)
    },
    [validators, setError, name, setRepeat]
  )

  useEffect(() => {
    validateField(initialValue, null)
  }, [validateField, initialValue])

  const defaultClasses = useStyles()

  const passwordElement = (
    <FormControl
      error={renderError && !!error}
      disabled={disabled}
      fullWidth={fullWidth}
      className={defaultClasses.form_control}
    >
      <CustomLabel shrink={false} error={false} htmlFor={'id_' + name}>
        {translate(label.text || label) + ' *'}
      </CustomLabel>
      <CustomInput
        value={value || ''}
        id={'id_' + name}
        name={name}
        disabled={disabled}
        error={renderError && !!error}
        type="password"
        onChange={handleChange.password}
        aria-describedby={'helper_' + name}
        endAdornment={
          renderError &&
          value &&
          error && (
            <InputAdornment
              position="end"
              className={defaultClasses.field_icon}
            >
              {<IconWarning />}
              {/*{renderError && value && !error && <IconSuccess />}*/}
            </InputAdornment>
          )
        }
      />
      <FormHelperText id={'helper_' + name}>
        {translate(renderError && error ? error : '')}
      </FormHelperText>
    </FormControl>
  )

  const passwordRepeatElement = (
    <FormControl
      error={renderError && !!repeat.error}
      disabled={disabled}
      fullWidth={fullWidth}
      className={defaultClasses.form_control}
    >
      <CustomLabel
        shrink={false}
        error={false}
        htmlFor={'idrepeat_' + name}
      >
        {translate((label.text || label) + '_REPEAT') + ' *'}
      </CustomLabel>
      <CustomInput
        value={repeat.value || ''}
        id={'idrepeat_' + name}
        name={name + 'Repeat'}
        disabled={disabled}
        error={renderError && !!repeat.error}
        type="password"
        onChange={handleChange.repeat}
        aria-describedby={'helperrepeat_' + name}
        endAdornment={
          renderError &&
          repeat.value &&
          repeat.error && (
            <InputAdornment
              position="end"
              className={defaultClasses.field_icon}
            >
              {renderError && repeat.value && repeat.error && (
                <IconWarning />
              )}
              {/*{renderError && value && !error && <IconSuccess />}*/}
            </InputAdornment>
          )
        }
      />
      <FormHelperText id={'helperrepeat_' + name}>
        {translate(renderError && repeat.error ? repeat.error : '')}
      </FormHelperText>
    </FormControl>
  )

  return isGridable ? (
    <Grid
      container
      spacing={3}
      style={{
        maxWidth: width,
      }}
    >
      <Grid item xs>
        {passwordElement}
      </Grid>
      <Grid item xs>
        {passwordRepeatElement}
      </Grid>
    </Grid>
  ) : (
    <>
      <div>
        {passwordElement}
      </div>
      <div>
        {passwordRepeatElement}
      </div>
    </>
  )
}

const getValue = value => (value === '' ? null : value)

PasswordRepeatType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.shape({
    origin: PropTypes.string,
    repeat: PropTypes.string,
  }),
  initialValue: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  isGridable: PropTypes.bool,
  width: PropTypes.string,
  classes: PropTypes.shape({
    repeatInput: PropTypes.string,
  }),
}
