export const COLORS = {
  BLACK: '#18191b',
  GRAY: {
    DARK_1: '#212529',
    DARK_2: '#2b303c',
    DARK_3: '#424242',
    DARK_4: '#474747',
    DARK_5: '#5a6169',
    DARK_6: '#979FA8',
    LIGHT_1: '#aaa',
    LIGHT_2: '#bdbdbd',
    LIGHT_3: '#eef0f4',
    LIGHT_4: '#fafbfc',
    LIGHT_5: '#E9ECEF',
  },
  WHITE: '#fff',
  PRIMARY: '#0B2B7C',
  SECONDARY: '#1467FB',
  DISABLED: '#B6C0D8',
  SUCCESS: '#007A78',
  INFO: '#3AC8DC',
  WARNING: '#FFDCA3',
  ERROR: '#CA0000',
  WARIANT: '#48609D',
  LIGHTNING: '#DBE7FD',
  BACK: '#F7F7FC',
  SEPARATOR: '#CFD4DE',
  YELLOW: '#FFDCA3',
  ORANGE: '#F4B5A2',
  BLUE: '#8ED4EB',
}
