import React from 'react'
import { Route } from 'react-router-dom'
import { Administrators } from './Administrators'
import { Dictionary } from './Dictionary'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { EditAdmin } from './EditAdmin'
import { Faq } from './Faq'

const routes = () => {
  return {
    administrators: {
      title: 'Administratorzy',
      type: Route,
      render: props => <Administrators {...props} />,
      path: '/settings',
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN'],
    },
    dictionary: {
      title: 'Ustawienia aplikacji',
      type: Route,
      render: props => <Dictionary {...props} />,
      path: '/settings/dictionary',
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN'],
    },
    faq: {
      title: 'FAQ',
      type: Route,
      render: props => <Faq {...props} />,
      path: '/settings/faq',
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN'],
    },
    edit_administrator: {
      title: 'Edycja administratora',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={EditAdmin}
          uniqueKey={true}
          {...props}
        />
      ),
      path: '/settings/admin/:id',
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN'],
    },
  }
}

export default routes
