import React, { useCallback, useState, useEffect } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { RadioType } from './RadioType'
import {
  PROJECT_END_REJECTED,
  PROJECT_END_CANCELED,
  PROJECT_END_FINISHED,
} from '_schema/proposal'
import { StringType } from 'core/components/form/fields/StringType'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { SectionTitle } from 'core/components/SectionTitle'
import { ButtonTrash } from 'core/components/buttons/button-trash'
import { CustomDialog } from 'core/components/Dialog'
import routes from './../routes'
import { useHistory } from 'react-router-dom'

const useStyle = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
}))

export const ProjectEnd = ({
  eventObject,
  event,
  resource,
  updateResource,
}) => {
  const classes = useStyle()
  const [openRemove, setOpenRemove] = useState(false)
  const handleToggle = useCallback(() => {
    setOpenRemove(prevState => !prevState)
  }, [])

  const [state, setState] = useState({
    proposal: resource['@id'],
    proposalEvent: `/proposal_events/${event}/`,
  })

  const handleChange = useCallback((name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }))
  }, [])

  const { closeRequest } = eventObject

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    const iri = closeRequest?.[0]?.['@id']
    closeRequest?.length > 0 &&
      fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
        setState(prevState => ({ ...prevState, ...resp }))
      })
    return () => controller.abort()
  }, [closeRequest])

  const handleSubmit = useCallback(() => {
    const iri = '/proposal_close_requests'
    fetchDataHandleAuthError(
      iri,
      'POST',
      { body: JSON.stringify(state) },
      () => {
        updateResource()
        notification('success', translate('Zamknięto projekt'))
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [state, updateResource])

  const history = useHistory()
  const handleRemoveEvent = () => {
    const iri = `/proposal_events/${event}`
    fetchDataHandleAuthError(
      iri,
      'DELETE',
      {},
      () => {
        handleToggle()

        history.push(routes().item.path.replace(':id', resource.uuid))

        updateResource()

        notification('success', translate('Wycofano'))
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return (
    <>
      <Grid container spacing={3} alignItems={'center'}>
        <Grid item xs={10} md={11}>
          <SectionTitle label={'Zamknięcie projektu'} marginTopSm={true} />
        </Grid>
        <Grid item xs={4} md={3}>
          <RadioType
            value={PROJECT_END_FINISHED}
            name={'status'}
            rate={state.status}
            label={translate(
              `T_MODULE_PROJECT_END_STATUS_${PROJECT_END_FINISHED}`
            )}
            disabled={closeRequest?.length > 0 || false}
            color={'green'}
            onChange={event => handleChange('status', event.target.value)}
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <RadioType
            value={PROJECT_END_REJECTED}
            name={'status'}
            rate={state.status}
            label={translate(
              `T_MODULE_PROJECT_END_STATUS_${PROJECT_END_REJECTED}`
            )}
            disabled={closeRequest?.length > 0 || false}
            color={'blue'}
            onChange={event => handleChange('status', event.target.value)}
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <RadioType
            value={PROJECT_END_CANCELED}
            name={'status'}
            rate={state.status}
            label={translate(
              `T_MODULE_PROJECT_END_STATUS_${PROJECT_END_CANCELED}`
            )}
            disabled={closeRequest?.length > 0 || false}
            color={'red'}
            onChange={event => handleChange('status', event.target.value)}
          />
        </Grid>
        {!closeRequest?.length > 0 && (
          <Grid item xs={2} md={1}>
            <ButtonTrash text={''} marginTopSm={true} onClick={handleToggle} />
            <CustomDialog
              handleToogle={handleToggle}
              open={openRemove}
              title={'Wycofać zamknięcie'}
              component={() => (
                <>
                  <Typography variant={'body1'} color={'primary'}>
                    {translate('Czy na pewno wycofać zamknięcie projektu?')}
                  </Typography>
                </>
              )}
              onConfirm={handleRemoveEvent}
              confirmedText={'Wycofaj'}
              confirmedButton={true}
            />
          </Grid>
        )}
        <Grid xs={12} item>
          <StringType
            name={'description'}
            setValue={handleChange}
            disabled={closeRequest?.length > 0 || false}
            renderError={false}
            type={'textarea'}
            width={'100%'}
            setError={() => null}
            label={''}
            value={state.description}
          />
          <div className={classes.buttons}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
              size="small"
              disabled={closeRequest?.length > 0 || false}
            >
              <span>{translate('Zamknij projekt')}</span>
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  )
}
