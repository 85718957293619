import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyle = makeStyles(theme => ({
  root: {
    lineHeight: 1.75,
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.primary.main,
  },
}))

export const Info = ({ block, user }) => {
  const classess = useStyle()
  const renderRawHTML = text => {
    const ID = 'np. NIP:684123452'
    const fullAddress = `
Ulica, nr domu: ex. Kwiatowa 00<br />
Poczta: ex. Warszawa, Kod pocztowy: ex. 00-000<br />
Miejscowość: ex. Warszawa<br />
Gmina: ex. Warszawa<br />
Powiat: ex. Warszawski<br />
Województwo: ex. Mazowieckie
`

    const representatives = `Dane osób reprezentujących <br />
Imię i Nazwisko, rola <br />`
    let html = `${text}`
    html = html.replace('&lt;ID&gt;', ID || '')
    html = html.replace('&lt;TAXIDTYPE&gt;', 'Rodzaj numeru ident.')
    html = html.replace('&lt;ORGNAME&gt;', 'ex. Nazwa organizacji')
    html = html.replace('&lt;ORGTYPE&gt;', 'ex. Szkoła')
    html = html.replace('&lt;ORGMAIL&gt;', user.email)
    html = html.replace('&lt;ORGADDRESS&gt;', fullAddress)
    html = html.replace('&lt;ORGSUBACCOUNT&gt;', '20214589632145698745632145')
    html = html.replace('&lt;ORGREPRESENTATIVES&gt;', representatives)

    return html
  }

  return (
    <>
      <div
        className={classess.root}
        dangerouslySetInnerHTML={{ __html: renderRawHTML(block.data) }}
      ></div>
    </>
  )
}
