import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  Typography,
} from '@material-ui/core'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
import { ReactComponent as IconWarning } from '../../../../theme/icons/warrning.svg'
import { CustomLabel, CustomInput, useStyles } from './fields.style'

const TEXTAREA_ROWS = 5
const TEXTAREA_MAX_ROWS = 80

export const StringType = ({
  name,
  type = 'string',
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setAdditional = null,
  setError,
  syncWithAdditional = false,
  fullWidth = false,
  width = '350px',
  widthInput = '100%',
  endIcon = null,
  readonly = false,
  placeholder = '',
  maxLength = null,
  endText = null,
  min = 0,
  max = null,
  row = null,
  dateTimeType = false,
}) => {
  const handleChange = e => {
    let raw =
      e.target.value === ''
        ? null
        : maxLength
        ? e.target.value.slice(0, maxLength)
        : e.target.value

    let value =
      type === 'integer'
        ? parseInt(raw)
        : type === 'number'
        ? parseFloat(raw)
        : raw

    if (['nip', 'regon', 'krs', 'contractSubAccount'].includes(name)) {
      value = value && value.length > 0 ? value.replace(/\D/g, '') : ''
    }

    if (type === 'integer' || type === 'number') {
      if (max && value > max) {
        value = max
      }
      if (min && value < min) {
        value = min
      }
    }

    setValue(name, value)
    validateField(value, true)

    syncWithAdditional && setAdditional && setAdditional({ [name]: value })
  }

  const validateField = useCallback(
    (value, change = false) => {
      if (change && ['number', 'integer'].includes(type) && isNaN(value)) {
        setError(name, 'Niewłaściwy format danych')
      } else {
        if (!validators) {
          setError(name, false)

          return
        }

        const valid = validate(validators, value)

        setError(name, !valid.result && valid.message)
      }
    },
    [validators, setError, name, type]
  )

  useEffect(() => {
    validateField(initialValue)

    syncWithAdditional &&
      setAdditional &&
      setAdditional({ [name]: initialValue })
  }, [validateField, initialValue, syncWithAdditional, setAdditional, name])

  const additional =
    type === 'integer'
      ? { inputProps: { step: 1 } }
      : type === 'textarea'
      ? {
          minRows: row || TEXTAREA_ROWS,
          maxRows: TEXTAREA_MAX_ROWS,
          multiline: true,
        }
      : {}

  const classes = useStyles()

  return (
    <>
      <FormControl
        error={renderError && !!error}
        className={classes.form_control}
        disabled={disabled}
        fullWidth={fullWidth}
        style={{
          maxWidth: width,
        }}
      >
        {label !== '' && (
          <CustomLabel shrink={true} error={false} htmlFor={'id_' + name}>
            {translate(label?.text || label) +
              (validators && validators.includes('required') ? ' *' : '')}
          </CustomLabel>
        )}
        <CustomInput
          value={value !== undefined ? value : ''}
          id={'id_' + name}
          name={name}
          disabled={disabled}
          {...additional}
          style={{
            width: '100%',
          }}
          placeholder={placeholder}
          inputProps={{
            style: {
              width: '100%',
              maxWidth: widthInput,
              fontSize: dateTimeType ? 14 : 'initial',
            },
            readOnly: readonly,
            maxLength,
            min: min,
            max: max,
          }}
          error={renderError && !!error}
          type={
            type === 'integer'
              ? 'number'
              : ['string', 'textarea'].includes(type)
              ? 'text'
              : type
          }
          onChange={handleChange}
          aria-describedby={'helper_' + name}
          endAdornment={
            endText ? (
              <Typography
                style={{ paddingLeft: 10 }}
                variant={'body1'}
                color={'primary'}
              >
                {endText}
              </Typography>
            ) : endIcon ? (
              endIcon
            ) : (
              renderError &&
              value &&
              error && (
                <InputAdornment position="end" className={classes.field_icon}>
                  <IconWarning />
                  {/*{renderError && value && !error && <IconSuccess />}*/}
                </InputAdornment>
              )
            )
          }
        />
        <FormHelperText id={'helper_' + name}>
          {translate(renderError && error ? error : hint)}
        </FormHelperText>
      </FormControl>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue}
        </div>
      )}
    </>
  )
}

StringType.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compareValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setAdditional: PropTypes.func,
  setError: PropTypes.func.isRequired,
  syncWithAdditional: PropTypes.bool,
  fullWidth: PropTypes.bool,
  width: PropTypes.string,
  widthInput: PropTypes.string,
  endIcon: PropTypes.object,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  endText: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
}
