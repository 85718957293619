import React from 'react'
import { StyledTab, StyledTabs } from 'components/StyledTab'
import { translate } from 'core/_helpers/translate'
import routes from '../routes'
import { useHistory } from 'react-router-dom'

export const Tabs = ({ path }) => {
  const history = useHistory()
  const handleConfirmChange = (event, newValue) => {
    history.push(newValue)
  }
  return (
    <>
      <StyledTabs
        value={path}
        onChange={handleConfirmChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <StyledTab
          label={translate(routes().administrators.title)}
          value={routes().administrators.path}
        />
        <StyledTab
          label={translate(routes().dictionary.title)}
          value={routes().dictionary.path}
        />
        <StyledTab
          label={translate(routes().faq.title)}
          value={routes().faq.path}
        />
      </StyledTabs>
    </>
  )
}
