import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useSelector } from 'react-redux'
import { Paper } from 'core/components/Paper'
import { Form } from 'core/components/form'
import schema from '_schema/profile'

const useStyles = makeStyles({
  paper: {
    marginTop: 50,
    textAlign: 'center',
  },
  form: {
    margin: 'auto',
    marginTop: 30
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  submit: {
    marginRight: '0 !important'
  }
})

export const ChangePassword = () => {
  const profile = useSelector(state => state.profile)

  const classes = useStyles()

  return (
    <Paper
      elevation={0}
      withPadding={false}
      classes={{ root: classes.paper }}
    >
      <Form
        title="T_FORM_CHANGE_PASSWORD_TITLE"
        url={schema.changePassword.set.replace(':id', profile.uuid)}
        method="PUT"
        properties={schema.changePassword.properties}
        showSubmitAndStayButton={false}
        showCancelButton={false}
        classes={{
          root: classes.form,
          buttons: classes.buttons,
          submit: classes.submit
        }}
        loaderAlign="center"
      />
    </Paper>
  )
}
