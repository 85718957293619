/**
 * Checks if there is at least one budget with a percentage value greater than zero.
 *
 * Funkcjonalność dodana na życzenie klienta w tasku: 0026139: Budżet w składanym wniosku
 * Funkcja włącza możliwość zaawansowanej edycji budżetu dla konkursów,
 * które już działają, dla nowych powinna wyłączyć
 * Funkcja sprawdza czy w kategoriach budżetowych jest jakaś kategoria z ustawioną kategorią nadrzędną lub czy nie ma żadnej kategorii.
 *
 * @param {Array<Object>} budgets - The array of budget objects to check.
 * @param {Array<Object>} budgetCategories - The array of budget categories objects to check.
 * @returns {boolean} - Returns true if there is a budget with a percentage value greater than zero, otherwise false.
 */
export const isEnableBudgetMenageAdv = (budgets, budgetCategories) => {
  const findElementWithpercentage = budgets.find(
    el => Number(el.categoryId) > 0
  )
  return (
    (Array.isArray(budgets) && budgets.length === 0) ||
    !!findElementWithpercentage ||
    budgetCategories.length !== 0
  )
}
