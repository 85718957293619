import React, { useState, useMemo, useCallback } from 'react'
import { Card, Typography, AppBar } from '@material-ui/core'
import { Logo } from 'core/components/Logo'
import { translate } from 'core/_helpers/translate'
import { useStyles } from '.'
import clsx from 'clsx'
import { Footer } from 'core/components/footer/Footer'
import schema from 'core/_schema/user'
import { Form as FormComponent } from 'core/components/form'
import { Link } from 'react-router-dom'

export const ActivationExperts = ({ match }) => {
  const schemaUser = useMemo(() => schema('exper_activation'), [])

  const url = `${schemaUser.endpoint}${match.params.id}/${match.params.hash}`
  const classes = useStyles()

  const [isRegister, setIsRegister] = useState(false)

  const handleSuccess = useCallback(() => {
    setIsRegister(true)
  }, [])

  return (
    <div className={classes.root}>
      <Logo className={classes.logo} />
      <Card className={classes.card}>
        <AppBar position="static" className={classes.login_app_bar}>
          <div className={clsx(classes.login_menu, classes.login_menu_title)}>
            <Typography
              variant="h1"
              className={clsx(classes.login_title, classes.login_title_full)}
              color="secondary"
            >
              <span>{translate('T_GENERAL_ACCOUNT_ACTIVATION_TITLE')}</span>
            </Typography>
          </div>
        </AppBar>
        {isRegister ? (
          <div className={classes.form_message}>
            <div className={classes.reset_message}>
              {translate(
                'Konto zostało aktywowane. Zaloguj się do panelu eksperta'
              )}
            </div>
            <Link to="/login" className={classes.lower_link}>
              {translate('T_GENERAL_BACK_TO_LOGIN')}
            </Link>
          </div>
        ) : (
          <div className={classes.form}>
            <Typography
              variant={'subtitle1'}
              color={'primary'}
              style={{ marginBottom: 20 }}
            >
              {translate(
                'Aby aktywować konto podaj nowe hasło do logowania się na swoje konto'
              )}
            </Typography>
            <div className={classes.form_inner}>
              <FormComponent
                properties={schemaUser.resource.properties}
                url={url}
                handleSuccess={handleSuccess}
                method={'PUT'}
                showSubmitAndStayButton={false}
                showCancelButton={false}
                fieldsFullWidth={true}
                width="100%"
                submitButtonLabel={translate('T_CREATE_ACCOUNT')}
                handleCancel={() => null}
                classes={{
                  submit: classes.submit_register,
                }}
              />
            </div>
          </div>
        )}
      </Card>
      <Footer />
    </div>
  )
}
