import React, { useState, useMemo } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { ArrowBackOutlined } from '@material-ui/icons'
import { ProposalSteps } from 'components/ProposalSteps'
import { TabPanel } from 'components/TabPanel'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import { Sidebar } from './components'
import { useProposalState } from './_helpers/useProposalState'
import { useProposalFetch } from './_helpers/useProposalFetch'
import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
import {
  ApplicantStep,
  ProjectStep,
  BudgetStep,
  AttachmentStep,
} from 'pages/applicant/AppliedProposals/components'
import { useViewStyles } from './View.styles'
import { translate } from 'core/_helpers/translate'
import { contestPublicationStatuses } from '_helpers/contestPublicationStatuses'
import { proposalApplicationStatuses } from '_helpers/proposalApplicationStatuses'
import { proposalSteps } from '_helpers/proposalSteps'
import schema from '_schema/proposal'
import routes from './routes'
import provinceSchema from '_schema/province'
import populationSchema from '_schema/population'
import organizationTypeSchema from '_schema/organizationType'

export const View = ({ match }) => {
  const iri = `${schema.endpoint}/${match.params.id}`

  const [proposalState, setProposalState] = useProposalState()

  const { proposal, contest, isFetching, fetchError } = proposalState

  useProposalFetch(
    iri,
    setProposalState.proposal,
    setProposalState.contest,
    setProposalState.form,
    setProposalState.fetchError,
    setProposalState.subresourceFetched
  )

  const [activeStep, setActiveStep] = useState(proposalSteps.APPLICANT)

  const handleStepChange = step => () => {
    setActiveStep(step)
  }

  const additionalResourcesMapping = useMemo(
    () => ({
      provinces: `${provinceSchema.endpoint}?order[title]=desc&pagination=false`,
      populations: `${populationSchema.endpoint}?order[title]=desc&pagination=false`,
      organizationTypes: `${organizationTypeSchema.endpoint}?order[title]=desc&pagination=false`,
    }),
    []
  )

  const [additionalResources] = usePropertyResourcesFetch(
    additionalResourcesMapping
  )

  const classes = useViewStyles()

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ||
    proposal.applicationStatus !== proposalApplicationStatuses.APPLIED ? (
    <NotFound />
  ) : (
    <Grid container>
      <Grid item xs={8}>
        <div>
          <Link to={routes().index.path} className={classes.back_to_list}>
            <ArrowBackOutlined />{' '}
            {translate(
              `T_MODULE_PROPOSALS_APPLICATION_STATUS_${proposal.applicationStatus}`
            )}
          </Link>
        </div>
        <div>
          <ProposalSteps
            isAttachable={contest.attachmentsRequired}
            activeStep={activeStep}
            handleStepChange={handleStepChange}
          />
          <div className={classes.steps_container}>
            <TabPanel
              key={proposalSteps.APPLICANT}
              index={proposalSteps.APPLICANT}
              value={activeStep}
            >
              <ApplicantStep
                mapping={contest.applicationApplicantForm?.formSections}
                values={proposal.applicationApplicantFormValues}
                coordinators={proposal.coordinators}
                contest={contest}
                proposal={proposal}
                additionalResources={additionalResources}
              />
            </TabPanel>
            <TabPanel
              key={proposalSteps.PROJECT}
              index={proposalSteps.PROJECT}
              value={activeStep}
            >
              <ProjectStep
                mapping={contest.applicationProjectForm?.formSections}
                title={proposal.title}
                values={proposal.applicationProjectFormValues}
                contest={contest}
                proposal={proposal}
                additionalResources={additionalResources}
              />
            </TabPanel>
            <TabPanel
              key={proposalSteps.BUDGET}
              index={proposalSteps.BUDGET}
              value={activeStep}
            >
              {proposal.budgets && <BudgetStep budgets={proposal.budgets} />}
            </TabPanel>
            {contest.attachmentsRequired && (
              <TabPanel
                key={proposalSteps.ATTACHMENTS}
                index={proposalSteps.ATTACHMENTS}
                value={activeStep}
              >
                {proposal.attachments && (
                  <AttachmentStep attachments={proposal.attachments} />
                )}
              </TabPanel>
            )}
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <Sidebar
          proposalUuid={match.params.id}
          contestUuid={contest.uuid}
          collectingEndDate={contest.proposalCollectingDateTo}
          applicationDate={proposal.applicationDate}
          formalRatingDateTo={contest.formalRatingDateTo}
          expertRatingDateTo={contest.expertRatingDateTo}
          contractSigningDate={proposal.contractSigningDate}
          contractSignedFile={proposal.contractFile}
          contestTrainings={contest.trainings}
          proposalTrainings={proposal.proposalTrainings}
          acceptationStatus={proposal.acceptationStatus}
          substantiveRateStatus={proposal.substantiveRateStatus}
          formalRateStatus={proposal.formalRateStatus}
          contractStatus={proposal.contractStatus}
          withEditButton={
            contest.publicationStatus === contestPublicationStatuses.CURRENT &&
            proposal.applicationStatus ===
              proposalApplicationStatuses.APPLIED &&
            contest.proposalCollectingDateTo &&
            moment() <= moment(contest.proposalCollectingDateTo)
          }
          proposalNumber={proposal?.proposalNumber}
        />
      </Grid>
    </Grid>
  )
}
