import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'core/_helpers/translate'
import { DataInfo } from 'components/DataInfo'

export const CompanyDataBlock = ({ values, organizationTypes }) => {
  return (
    <div>
      <DataInfo
        label={
          values?.nip
            ? translate('T_MODULE_ORGANIZATION_DATA_NIP')
            : values?.regon
            ? translate('T_MODULE_ORGANIZATION_DATA_REGON')
            : values?.krs
            ? translate('T_MODULE_ORGANIZATION_DATA_KRS')
            : translate('T_MODULE_ORGANIZATION_DATA_OTHER')
        }
        data={values?.nip || values?.regon || values?.krs || ''}
        bold
      />
      <DataInfo
        label={translate('T_MODULE_ORGANIZATION_DATA_ORGANIZATION_NAME')}
        data={values?.organizationName || ''}
        bold
      />
      <DataInfo
        label={translate('T_MODULE_ORGANIZATION_DATA_ORGANIZATION_TYPE')}
        data={
          organizationTypes?.find(
            organizationType =>
              organizationType['@id'] === values?.organizationType
          )?.title || ''
        }
        bold
      />
    </div>
  )
}

CompanyDataBlock.propTypes = {
  values: PropTypes.object,
  organizationTypes: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
}
