import React from 'react'
import { NotFound } from 'core/pages/NotFound'
import { Loader } from 'core/components/Loader'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { OrganizationDataForm } from 'components/organizationData/components'
import { translate } from 'core/_helpers/translate'
import schema from 'core/_schema/user'
import { CollectionHeader } from 'core/components/CollectionHeader'

export const Edit = ({ schema: definitions, match }) => {
  const iri = `${schema('user').endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError
  )

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <div>
      <CollectionHeader
        buttonTitleLeft={'Wróć do detalu'}
        hideButtonBack={false}
        buttonPathLeft={`/organizations/${match.params.id}`}
        hideButton={true}
      >
        {translate('T_MODULE_ORGANIZATION_DATA')}
      </CollectionHeader>
      <OrganizationDataForm
        url={`${schema('applicant_edit').endpoint}/${match.params.id}`}
        resetUrl={`/security/startResettingPassword`}
        method="PUT"
        resource={resource}
        definitionSchema={
          definitions[schema('applicant_edit').resource.definition]
        }
        customResourceSchema={schema('applicant_edit').resource}
      />
    </div>
  )
}
