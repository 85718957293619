import React, { useEffect, useCallback, useState } from 'react'
import { SectionTitle } from 'core/components/SectionTitle'
import { Button, Grid, Typography } from '@material-ui/core'
import { StringType } from 'core/components/form/fields/StringType'
import { DateTimeType } from 'core/components/form/fields/DateTimeType'
import { translate } from 'core/_helpers/translate'
import { ButtonTrash } from 'core/components/buttons/button-trash'
import { CustomDialog } from 'core/components/Dialog'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import Moment from 'react-moment'
import { notification } from 'core/_helpers/notification'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { ButtonEdit } from 'core/components/buttons/button-edit'
import routes from './../routes'
import { useHistory } from 'react-router-dom'
import { TinymceType } from '../../../../core/components/form/fields/TinymceType'
import moment from 'moment/moment'

const useStyle = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
  label: {
    fontSize: 16,
    color: theme.palette.wariant,
    marginBottom: 4,
    lineHeight: 1.4,
  },
  label_bold: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 14,
    lineHeight: 1.4,
  },
  buttonSave: {
    marginBottom: theme.spacing(3),
  },
  buttonsEdit: {
    display: 'flex',
  },
}))

const DefaultRoleVizitHtml = `
Zgodnie z § 7 Umowy dotacji: „Fundacja ma prawo wizytowania Dotowanego wraz z możliwością uczestnictwa w zajęciach finansowanych ze środków pochodzących z przyznanej Dotacji”.<br /><br />
<ul>
<li>Wizyta przedstawicieli Fundacji jest zgłaszana organizacji, w której planowany jest monitoring na minimum 7 dni przed planowanym terminem wizyty.</li>
<li>Termin wizyty podawany przez Fundację jest nieprzesuwalny i nienegocjowalny ze względu na ograniczone zasoby Fundacji i konieczność odwiedzania wielu projektów w czasie jednej podróży monitoringowej.</li>
<li>Wizyta w miejscu realizacji projektów składa się z: rozmowy z koordynatorem/koordynatorką projektu (do spotkania mogą dołączyć osoby reprezentujące władze Grantobiorcy) oraz rozmowy z młodzieżą uczestniczącą w projekcie (jeśli udział całej grupy projektowej jest niemożliwy, w spotkaniu może uczestniczyć część grupy projektowej).</li>
<li>Spotkanie powinno odbyć się w miejscu, gdzie odbywają się spotkania grupy projektowej. Z obiektywnych względów spotkanie może odbyć się w innym miejscu, które umożliwia swobodną rozmowę.</li>
<li>Spotkanie zwyczajowo trwa około 90 minut, w tym spotkanie z młodzieżą ok. 30 minut.</li>
<li>Jeśli Fundacja nie zgłosi potrzeby, na spotkaniu nie jest konieczna obecność osoby odpowiedzialnej za finanse projektu, ani wgląd w dokumentację projektową.</li>
</ul>
`
const DATE_FORMAT = 'DD.MM.YYYY HH:mm'

const ReportItem = ({ el, handleChangeItem }) => {
  const classes = useStyle()
  const [report, setReport] = useState(el)
  const [edit, setEdit] = useState(false)
  const handleChangeReport = useCallback((name, value) => {
    setReport(prevState => ({ ...prevState, [name]: value }))
  }, [])
  const handleSubmitReport = useCallback(() => {
    handleChangeItem(report)
    setEdit(prevState => !prevState)
  }, [report, handleChangeItem])
  const handleChangeEdit = useCallback(() => {
    setEdit(prevState => !prevState)
  }, [])

  return (
    <>
      <Grid item md={2}>
        {edit ? (
          <>
            <DateTimeType
              name={'dateVisitation'}
              disabled={false}
              renderError={false}
              type={'string'}
              setError={() => null}
              label={''}
              setValue={handleChangeReport}
              value={report.dateVisitation}
            />
          </>
        ) : (
          <div className={classes.label}>{el.dateVisitation}</div>
        )}
      </Grid>

      <Grid item md={8}>
        {edit ? (
          <StringType
            name={'whoWasvisitation'}
            disabled={false}
            renderError={false}
            type={'string'}
            setError={() => null}
            label={''}
            setValue={handleChangeReport}
            value={report.whoWasvisitation}
          />
        ) : (
          <div className={classes.label}>{el.whoWasvisitation}</div>
        )}

        {edit ? (
          <StringType
            name={'description'}
            disabled={false}
            renderError={false}
            type={'textarea'}
            width={'100%'}
            setError={() => null}
            label={''}
            setValue={handleChangeReport}
            value={report.description}
          />
        ) : (
          <div className={classes.label}>{el.description}</div>
        )}
      </Grid>

      {edit ? (
        <Grid item md={2}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleSubmitReport}
            className={classes.buttonSave}
          >
            <span>{translate('Zapisz')}</span>
          </Button>
        </Grid>
      ) : (
        <Grid item md={2}>
          <div className={classes.buttonsEdit}>
            <ButtonEdit text={''} onClick={handleChangeEdit} />
          </div>
        </Grid>
      )}
    </>
  )
}

export const Appointment = ({
  eventObject,
  event,
  resource,
  updateResource,
}) => {
  const classes = useStyle()
  const [openRemove, setOpenRemove] = useState(false)
  const handleToggle = useCallback(() => {
    setOpenRemove(prevState => !prevState)
  }, [])

  const { proposalVisitation } = eventObject

  const [state, setState] = useState({})

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    const iri = proposalVisitation?.[0]?.['@id']
    proposalVisitation?.length > 0
      ? fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
          setState(resp)
        })
      : setState({})
    return () => controller.abort()
  }, [proposalVisitation, event])

  const [newVisit, setNewVisit] = useState({})

  const [reports, setReports] = useState([])
  useEffect(() => {
    setNewVisit({
      createdAt: state.createdAt,
      visitationDate: state.visitationDate,
      whoWillVisitation: state.whoWillVisitation,
      proposal: resource['@id'],
      proposalEvent: `/proposal_events/${event}/`,
      status: state.status,
      uuid: state.uuid,
      visitationDescription: state.visitationDescription,
    })
    setReports(state.proposalVisitationReport)
    setNewReport(prevState => ({
      ...prevState,
      proposalVisitation: state['@id'],
    }))
  }, [event, resource, state])

  const handleChangeNewVisit = useCallback((name, value) => {
    setNewVisit(prevState => ({ ...prevState, [name]: value }))
  }, [])

  const handleSubmitNewVisit = useCallback(() => {
    const iri = '/proposal_visitations'
    fetchDataHandleAuthError(
      iri,
      'POST',
      { body: JSON.stringify(newVisit) },
      resp => {
        setNewVisit({
          ...newVisit,
          status: resp.status,
        })
        updateResource()
        notification('success', translate('Ustawiono wizytę'))
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [newVisit, updateResource])

  const [addNewStat, setAddNewStat] = useState(false)

  const handleAddNewButtonClick = useCallback(() => {
    setAddNewStat(prevState => !prevState)
    setNewReport({
      proposalVisitation: state['@id'],
      dateVisitation: state.visitationDate,
      whoWasvisitation: '',
      description: '',
    })
  }, [state])

  const [newReport, setNewReport] = useState({
    proposalVisitation: state['@id'],
    dateVisitation: '',
    whoWasvisitation: '',
    description: '',
  })

  const handleChangeNewReport = useCallback((name, value) => {
    setNewReport(prevState => ({ ...prevState, [name]: value }))
  }, [])

  const handleSubmitNewReport = useCallback(() => {
    const iri = '/proposal_visitation_reports'
    fetchDataHandleAuthError(
      iri,
      'POST',
      { body: JSON.stringify(newReport) },
      resp => {
        setReports(prevState => [...prevState, resp])
        setAddNewStat(false)
        setNewReport({
          proposalVisitation: state['@id'],
          dateVisitation: '',
          whoWasvisitation: '',
          description: '',
        })
        notification('success', translate('Dodano nowy raport'))
        updateResource()
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [newReport, state, updateResource])

  const handleChangeItem = useCallback(
    el => {
      const iri = el['@id']
      fetchDataHandleAuthError(
        iri,
        'PUT',
        { body: JSON.stringify(el) },
        () => {
          // const newArray = reports.map(item =>
          //   el.uuid === item.uuid ? el : item
          // )
          // setReports(newArray)
          updateResource()
          notification('success', translate('Zaktualizowano raport'))
        },
        error => {
          notification('error', error.response.detail, error.response.title)
        }
      )
    },
    [updateResource]
  )

  const history = useHistory()
  const handleRemoveEvent = () => {
    const iri = `/proposal_events/${event}`
    fetchDataHandleAuthError(
      iri,
      'DELETE',
      {},
      () => {
        handleToggle()

        history.push(routes().item.path.replace(':id', resource.uuid))

        updateResource()

        notification('success', translate('Usunięto wizytę'))
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return (
    <>
      <Grid container spacing={3} alignItems={'center'}>
        <Grid item xs={10} md={11}>
          <SectionTitle
            label={'Zapowiedź wizyty kontrolnej:'}
            marginTopSm={true}
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <ButtonTrash text={''} marginTopSm={true} onClick={handleToggle} />
          <CustomDialog
            handleToogle={handleToggle}
            open={openRemove}
            title={'Usuń wizytę kontrolną'}
            component={() => (
              <>
                <Typography variant={'body1'} color={'primary'}>
                  {translate('Czy usunąć zapowiedź wizyty?')}
                </Typography>
              </>
            )}
            onConfirm={handleRemoveEvent}
            confirmedText={'Usuń'}
            confirmedButton={true}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} alignItems={'center'}>
        <Grid item md={2}>
          <Typography variant={'subtitle2'} color={'primary'}>
            {state.createdAt ? (
              <Moment date={state.createdAt} format={'DD.MM.YYYY'} />
            ) : (
              '--.--.----'
            )}
          </Typography>
        </Grid>
        <Grid item md={3}>
          <StringType
            name={'whoWillVisitation'}
            disabled={newVisit.status || false}
            renderError={false}
            type={'string'}
            setError={() => null}
            label={'Imię i Nazwisko'}
            setValue={handleChangeNewVisit}
            value={newVisit.whoWillVisitation}
          />
        </Grid>
        <Grid item md={2}>
          <DateTimeType
            name={'visitationDate'}
            disabled={newVisit.status || false}
            renderError={false}
            type={'string'}
            setError={() => null}
            label={'Data'}
            setValue={handleChangeNewVisit}
            value={newVisit.visitationDate}
          />
        </Grid>
        <Grid item md={3}>
          <Button
            onClick={handleSubmitNewVisit}
            variant="contained"
            color="secondary"
            size="small"
            disabled={newVisit.status || false}
          >
            <span>{translate('Ustaw')}</span>
          </Button>
        </Grid>
        <Grid item md={2}></Grid>
        <Grid item md={10}>
          <TinymceType
            setError={() => null}
            label={'Zasady organizacji wizyt monitoringowych'}
            setValue={handleChangeNewVisit}
            name={'visitationDescription'}
            disabled={newVisit.status || false}
            renderError={false}
            formWidth={600}
            value={newVisit.visitationDescription || DefaultRoleVizitHtml}
          />
        </Grid>
      </Grid>
      <SectionTitle label={'Ankieta wizyty kontrolnej:'} />
      <Grid container spacing={3}>
        <Grid item md={2}>
          <Typography variant={'subtitle2'} color={'primary'}>
            {state.createdAt ? (
              <Moment date={state.createdAt} format={'DD.MM.YYYY'} />
            ) : (
              '--.--.----'
            )}
          </Typography>
        </Grid>
        <Grid item md={10}>
          {state.createdAt ? (
            <>
              <div className={classes.label}>Wizytujący:</div>
              <div className={classes.label_bold}>
                {state.whoWillVisitation}
              </div>
              <div className={classes.label}>Wyznaczony termin wizyty :</div>
              <div className={classes.label_bold}>
                {moment(state.visitationDate).format(DATE_FORMAT)}
              </div>
              <div className={classes.label}>Potwierdzony termin wizyty:</div>
              <div className={classes.label_bold}>
                {state?.userVisitationAcceptance ? 'TAK' : 'NIE'}
              </div>
              <div className={classes.label}>Miejsce spotkania :</div>
              <div className={classes.label_bold}>{state?.address}</div>
              <div className={classes.label}>
                Potwierdzona obecność koordynatora:
              </div>
              <div className={classes.label_bold}>
                {state?.coordinatorPresenceConfirmation ? 'TAK' : 'NIE'}
              </div>
              <div className={classes.label}>
                Potwierdzona obecność grupy projektowej:
              </div>
              <div className={classes.label_bold}>
                {state?.projectGroupPresenceConfirmation ? 'TAK' : 'NIE'}
              </div>
              <div className={classes.label}>
                Zapoznałem/-am się z zasadami organizacji wizyt monitoringowych:
              </div>
              <div className={classes.label_bold}>
                {state?.visitationRulesAcceptation ? 'TAK' : 'NIE'}
              </div>
              <div className={classes.label}>Dodatkowe uwagi :</div>
              <div className={classes.label_bold}>{state?.description}</div>
            </>
          ) : (
            'ustaw wizytę'
          )}
        </Grid>
      </Grid>
      {state.status === 'ACCEPTED' && (
        <>
          <SectionTitle
            label={'Raport z wizyty kontrolnej:'}
            marginTopSm={true}
          />

          <Grid container spacing={3}>
            {reports?.map((el, index) => (
              <ReportItem
                el={el}
                key={index}
                handleChangeItem={handleChangeItem}
              />
            ))}

            {addNewStat ? (
              <>
                <Grid item md={2}>
                  <DateTimeType
                    name={'dateVisitation'}
                    disabled={false}
                    renderError={false}
                    type={'string'}
                    setError={() => null}
                    label={'Data wizyty'}
                    setValue={handleChangeNewReport}
                    value={newReport.dateVisitation}
                  />
                </Grid>
                <Grid item md={8}>
                  <StringType
                    name={'whoWasvisitation'}
                    disabled={false}
                    renderError={false}
                    type={'string'}
                    setError={() => null}
                    label={'Osoba monitorująca'}
                    setValue={handleChangeNewReport}
                    value={newReport.whoWasvisitation}
                  />

                  <StringType
                    name={'description'}
                    disabled={false}
                    renderError={false}
                    type={'textarea'}
                    width={'100%'}
                    setError={() => null}
                    label={'Raport'}
                    setValue={handleChangeNewReport}
                    value={newReport.description}
                  />
                </Grid>
                <Grid item md={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={handleSubmitNewReport}
                    className={classes.buttonSave}
                  >
                    <span>{translate('Dodaj')}</span>
                  </Button>
                </Grid>
              </>
            ) : (
              <Grid item md={2}>
                <ButtonPlus text={'Dodaj'} onClick={handleAddNewButtonClick} />
              </Grid>
            )}
          </Grid>
        </>
      )}
    </>
  )
}
