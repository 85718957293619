import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'components/applicant/form'
import { applyProperties } from 'core/_helpers/applyProperties'

export const BlankForm = ({
  url,
  method = 'PUT',
  resource = null,
  definitionSchema,
  customResourceSchema,
  handleSubmit = null,
  handleSuccess = null,
  handleCancel = null,
  notify = false,
  onlyPassedProperties = false,
  buttonContainerRef = null,
  submitButtonTitle = null,
  submitButtonVariant = 'contained',
  submitButtonSize = 'large',
  submitButtonDisabled = false,
  additionalButtons = null,
  areButtonsIcons = false,
  children,
  getKrsFromApi = false,
  classes = {},
}) => {
  const properties = applyProperties(
    customResourceSchema.properties,
    definitionSchema.properties,
    'missing'
  )

  return (
    <Form
      url={url}
      method={method}
      properties={properties}
      resource={resource}
      handleSubmit={handleSubmit}
      handleSuccess={handleSuccess}
      handleCancel={handleCancel}
      notify={notify}
      onlyPassedProperties={onlyPassedProperties || method === 'PATCH'}
      buttonContainerRef={buttonContainerRef}
      submitButtonTitle={submitButtonTitle}
      submitButtonVariant={submitButtonVariant}
      submitButtonSize={submitButtonSize}
      submitButtonDisabled={submitButtonDisabled}
      additionalButtons={additionalButtons}
      areButtonsIcons={areButtonsIcons}
      classes={classes}
      getKrsFromApi={getKrsFromApi}
    >
      {children}
    </Form>
  )
}

BlankForm.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string,
  resource: PropTypes.object,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  handleSubmit: PropTypes.func,
  handleSuccess: PropTypes.func,
  handleCancel: PropTypes.func,
  notify: PropTypes.func,
  onlyPassedProperties: PropTypes.bool,
  buttonContainerRef: PropTypes.object,
  submitButtonTitle: PropTypes.string,
  submitButtonSize: PropTypes.oneOf(['large', 'small']),
  submitButtonVariant: PropTypes.oneOf(['contained', 'outlined', 'inherit']),
  submitButtonDisabled: PropTypes.bool,
  additionalButtons: PropTypes.node,
  areButtonsIcons: PropTypes.bool,
  children: PropTypes.func.isRequired,
  classes: PropTypes.object,
  getKrsFromApi: PropTypes.bool,
}
