import React, { useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { hasResourceAccess } from '../core/_helpers/hasResourceAccess'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDataHandleAuthError } from '../core/_helpers/fetchDataHandleAuthError'
import { authConstants, profileConstants } from '../core/_constants'
import schema from '../core/_schema/profile'
import { prop } from '../core/_helpers/prop'

const accessProperties = [
  'resourceAccess',
  'isSuperAdmin',
  'isOpenform',
  'wasPasswordChangedByOtherAdmin',
  'showContextualHints',
  `group.${process.env.REACT_APP_RESOURCE_ID}`,
]

export const ShowUser = () => {
  const { id } = useParams()
  const history = useHistory()
  const [isAdmin, setIsAdmin] = React.useState(0)
  const user = useSelector(state => state.profile)
  const dispatch = useDispatch()
  const [token, setToken] = React.useState(null)

  useEffect(() => {
    if (isAdmin === 0 && Object.keys(user).length > 0) {
      if (hasResourceAccess(['ROLE_ADMIN'], user)) {
        setIsAdmin(1)
      } else {
        setIsAdmin(2)
      }
    }
  }, [user, isAdmin])

  useEffect(() => {
    if (isAdmin === 1) {
      fetchDataHandleAuthError(
        `/users/${id}/get_preview_token`,
        'GET',
        {},
        res => {
          dispatch({
            type: authConstants.REFRESH_SUCCESS,
            payload: { token: res.token, showUser: true },
          })
          setToken(res.token)
        }
      )
    }
  }, [isAdmin, id, dispatch])

  useEffect(() => {
    if (token) {
      fetchDataHandleAuthError('/users/profile', 'GET', {}, res => {
        dispatch({
          type: profileConstants.SUCCESS,
          payload: {
            ...[
              process.env.REACT_APP_RESOURCE_ID,
              ...accessProperties,
              ...Object.keys(schema.profile.properties),
            ].reduce(
              (obj, key) =>
                Object.assign(obj, {
                  [key.split('.')[0]]: prop(res, key),
                }),
              {}
            ),
          },
        })
        history.push('/')
      })
    }
  }, [dispatch, history, token])

  return (
    <>
      {isAdmin === 0 && <div>Przygotowuję dane użytkownika</div>}
      {isAdmin === 1 && (
        <div>
          Przygotowuję dane użytkownika, za chwilę przekieruję Cię na stronę
          użytkownika
        </div>
      )}
      {isAdmin === 2 && <div>Nie masz uprawnień, aby zobaczyć zawartość</div>}
    </>
  )
}
