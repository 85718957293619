import React from 'react'
import { Link } from 'react-router-dom'

export const Logo = props => {
  return (
    <Link to="/" {...props}>
      <img
        src="/img/logo.svg"
        alt="logo"
        style={{
          width: 100,
          marginTop: 10,
          marginBottom: 10,
        }}
      />
    </Link>
  )
}
