import { contestBudgetCategoriesConstans } from '_constans/contestBudgetCategories.constans'

const initialState = {}

export const contestBudgetCategoriesReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case contestBudgetCategoriesConstans.SET_CONTEST_BUDGET_CATEGOIRES:
      return {
        ...state,
        [action.payload.contest]: action.payload.budgetCategories,
      }
    default:
      return state
  }
}
