import React from 'react'
import { Route } from 'react-router-dom'
import { Item } from './Item'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { Collection } from './Collection'
import { ProposalCollection } from './ProposalCollection'
import { Pdf } from 'pages/Foundation/Projects/Pdf'

const routes = () => {
  return {
    index: {
      title: 'Projekty',
      type: Route,
      render: props => <Collection {...props} />,
      path: '/projects',
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
    list: {
      title: 'Lista wniosków',
      type: Route,
      render: props => <ProposalCollection {...props} />,
      path: '/projects/list/:id',
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
    item: {
      title: 'Projekt',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={Item}
          uniqueKey={true}
          {...props}
        />
      ),
      path: '/projects/item/:id',
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
    itemWithEvent: {
      title: 'Projekt',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={Item}
          uniqueKey={true}
          {...props}
        />
      ),
      path: '/projects/item/:id/:event',
      exact: false,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
    itemPdf: {
      title: 'Pdf',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={Pdf}
          uniqueKey={true}
          {...props}
        />
      ),
      path: '/projects/pdf/:id/',
      exact: false,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
  }
}

export default routes
