import 'date-fns'
import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import DateFnsUtils from '@date-io/date-fns'
import plLocale from 'date-fns/locale/pl'
import moment from 'moment'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers'
import { InputAdornment, FormHelperText } from '@material-ui/core'
import { ReactComponent as DateTimeIcon } from 'theme/icons/datetime.svg'
import { makeStyles } from '@material-ui/styles'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'

const LOCALES = {
  pl: plLocale,
}

const FORMAT = 'yyyy-MM-dd'

const DATE_PICKER_ERRORS = [
  translate('T_GENERAL_INVALID_DATE'),
  translate('T_GENERAL_INVALID_MIN_DATE'),
  translate('T_GENERAL_INVALID_MAX_DATE'),
]

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  picker_root: {
    width: '100%',
    backgroundColor: 'white',
    margin: 0,
    '&> div': {
      borderRadius: 0,
      padding: 4,
      height: 33,
      '&> fieldset': {
        borderColor: theme.palette.primary.main,
      }
    },
    '&> p': {
      marginLeft: 0,
    },
  },
  input: {
    padding: 3,
  },
  adornment: {
    '&> button': {
      padding: 0,
    },
    '&> button:hover': {
      background: 'none',
    },
  },
}))

export const DateType = ({
  name,
  value,
  error = false,
  renderError = false,
  validators,
  setValue,
  setError,
  disabled = false,
  classes = {},
}) => {
  const [id] = useState(randomHash())

  const handleChange = value => {
    const date = value ? moment(value).format(FORMAT.toUpperCase()) : null
    setValue(name, date)
  }

  const handleError = message => {
    if (message && message !== error) {
      setError(name, message)
    }
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(value)
  }, [validateField, value])

  const defaultClasses = useStyles()

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}
      locale={LOCALES[process.env.REACT_APP_LOCALE]}
    >
      <div className={classes.root}>
        <KeyboardDatePicker
          id={id}
          name={name}
          value={![null, undefined].includes(value) ? new Date(value) : null}
          disabled={disabled}
          disableToolbar
          variant="inline"
          format={FORMAT}
          margin="normal"
          invalidDateMessage={DATE_PICKER_ERRORS[0]}
          minDateMessage={DATE_PICKER_ERRORS[1]}
          maxDateMessage={DATE_PICKER_ERRORS[2]}
          inputVariant="outlined"
          inputProps={{ className: defaultClasses.input }}
          InputAdornmentProps={{ className: defaultClasses.adornment }}
          KeyboardButtonProps={{ disableRipple: true }}
          classes={{ root: defaultClasses.picker_root }}
          keyboardIcon={
            <InputAdornment position="end">
              <DateTimeIcon />
            </InputAdornment>
          }
          onChange={handleChange}
          onError={handleError}
        />
        {renderError && !!error && !DATE_PICKER_ERRORS.includes(error) && (
          <FormHelperText error={true}>
            {translate(error)}
          </FormHelperText>
        )}
      </div>
    </MuiPickersUtilsProvider>
  )
}

DateType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
}
