import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 0,
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  label: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 18,
    verticalAlign: 'top',
    marginRight: 16,
    paddingTop: 9,
  },
  radioInline: {
    display: 'flex',
    flexDirection: 'row',
  },
  hint: {
    color: theme.palette.text.secondary,
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
  radioLabel: {
    color: theme.palette.primary.main,
    fontSize: 16,
    marginRight: theme.spacing(4),
    letterSpacing: '0.02em',
    '& .MuiRadio-root:not(.Mui-checked)': {
      color: theme.palette.primary.main,
    },
  },
}))

export const RadioType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  disabled = false,
  validators,
  setValue,
  setError,
  values,
  width = '350px',
  classes = {},
  inline = false,
}) => {
  const handleChange = e => {
    const value =
      e.target.value === 'true'
        ? true
        : e.target.value === 'false'
        ? false
        : e.target.value
    setValue(name, value)
    validateField(name, value)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(!!initialValue)
  }, [validateField, initialValue])

  useEffect(() => {
    if (initialValue === null) {
      setValue(name, values[0].value)
    }
  }, [setValue, name, initialValue, values])

  const defaultClasses = useStyles()

  return (
    <FormControl
      className={clsx(defaultClasses.root, classes.root)}
      disabled={disabled}
      style={{
        maxWidth: width,
      }}
    >
      {label && (
        <FormLabel>
          <span
            style={label.color && { color: label.color }}
            className={defaultClasses.label}
          >
            {translate(label.text || label)}
          </span>
        </FormLabel>
      )}
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={handleChange}
        className={clsx(inline && defaultClasses.radioInline)}
      >
        {values.map(el => (
          <FormControlLabel
            key={el.value}
            control={<Radio />}
            label={translate(el.name)}
            value={el.value}
            className={defaultClasses.radioLabel}
          />
        ))}
      </RadioGroup>
      <FormHelperText className={clsx(defaultClasses.hint, classes.hint)}>
        {hint}
      </FormHelperText>
    </FormControl>
  )
}

RadioType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }).isRequired,
  ]),
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  compareValue: PropTypes.bool,
  compare: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
    hint: PropTypes.string,
  }),
  inline: PropTypes.bool,
}
