import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Popper from '@material-ui/core/Popper'
import Paper from '@material-ui/core/Paper'
import moment from 'moment'
import clsx from 'clsx'
import { Info } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { Delete } from 'core/components/resource/Delete'
import { translate } from 'core/_helpers/translate'
import { STATUS_DRAFT, STATUS_RETURNED } from '_schema/proposalRelation'
import routes from '../../routes'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyles = makeStyles(theme => ({
  hideFirstPadding: {
    '& td:first-of-type, & th:first-of-type': {
      paddingLeft: 0,
      maxWidth: 53,
    },
    '& td:first-of-type': {
      textAlign: 'center',
    },
  },

  table_cell_head: {
    color: '#A19AAA',
    fontWeight: 600,
    fontStyle: 'italic',
    border: 0,
  },
  table_cell: {
    fontWeight: 600,
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
  },
  table_header: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    margin: 0,
  },
  table_header_line: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '&::after': {
      content: '""',
      height: 1,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      marginLeft: 10,
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
  },
  status_cell: {
    display: 'flex',
  },
  info_wrapper: {
    marginLeft: 5
  },
  info: {
    cursor: 'pointer'
  },
  return_explanation: {
    marginTop: 5,
    padding: 5
  }
}))

export const TableList = ({ items, setState, title, id }) => {
  const tableClasses = useStyles()

  const handleDelete = uuid => () => {
    setState(state => ({
      ...state,
      items: state.items.filter(item => item.uuid !== uuid),
      totalItems: state.totalItems - 1
    }))
  }

  const [returnExplanationAnchorEl, setReturnExplanationAnchorEl] = useState(null)

  const handleShowReturnExplanation = e => {
    const target = e.currentTarget

    setReturnExplanationAnchorEl(returnExplanationAnchorEl ? null : target)
  }

  const isReturnExplanationOpen = Boolean(returnExplanationAnchorEl)

  return (
    <>
      <TableContainer>
        <h3 className={tableClasses.table_header_line}>{title}</h3>
        <Table>
          <TableHead>
            <TableRow className={tableClasses.hideFirstPadding}>
              <TableCell className={tableClasses.table_cell_head}>
                Numer relacji
              </TableCell>
              <TableCell className={tableClasses.table_cell_head}>
                data dodania
              </TableCell>
              <TableCell className={tableClasses.table_cell_head}>
                Tytuł relacji
              </TableCell>
              <TableCell className={tableClasses.table_cell_head}>
                status
              </TableCell>
              <TableCell className={tableClasses.table_cell_head}>
                link do relacji
              </TableCell>
              <TableCell className={tableClasses.table_cell_head}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items?.map((row, index) => (
              <TableRow
                key={`${row.uuid}-td`}
                className={tableClasses.hideFirstPadding}
              >
                <TableCell className={tableClasses.table_cell}>
                  {index + 1}
                </TableCell>
                <TableCell className={tableClasses.table_cell}>
                  {moment(row.createdAt).format(DATE_FORMAT)}
                </TableCell>
                <TableCell className={tableClasses.table_cell}>
                  {row.title}
                </TableCell>
                <TableCell className={clsx(tableClasses.table_cell, tableClasses.status_cell)}>
                  {translate(`T_MODULE_PROPOSAL_RELATIONS_STATUS_${row.status}`)}
                  {row.status === STATUS_RETURNED && row.returnExplanation && (
                    <div className={tableClasses.info_wrapper}>
                      <Info onClick={handleShowReturnExplanation} color="secondary" className={tableClasses.info} />
                      <Popper open={isReturnExplanationOpen} anchorEl={returnExplanationAnchorEl}>
                        <Paper className={tableClasses.return_explanation}>
                          {row.returnExplanation}
                        </Paper>
                      </Popper>
                    </div>
                  )}
                </TableCell>
                <TableCell className={tableClasses.table_cell}>
                  {row.link}
                </TableCell>
                <TableCell className={tableClasses.table_cell}>
                  <div className={tableClasses.actions}>
                    {[STATUS_DRAFT, STATUS_RETURNED].includes(row.status) && (
                      <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        component={Link}
                        to={routes()
                          .relationsItemEdit.path.replace(':id', id)
                          .replace(':itemId', row.uuid)}
                      >
                        Edytuj
                      </Button>)}
                    {row.status === STATUS_DRAFT && (
                      <Delete
                        resource={row}
                        accessor="title"
                        onSuccess={handleDelete(row.uuid)}
                      />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ButtonPlus
        path={routes().relationsItemAdd.path.replace(':id', id)}
        text="Dodaj relację"
      />
    </>
  )
}
