import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import schemaProvince from '_schema/province'
import schemaProvinceExpoerts from '_schema/contestProvinceExpert'
import schemaUser from 'core/_schema/user'
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { makeStyles } from '@material-ui/styles'
import { Item } from './Item'
import { Add } from './Add'
import { ReactComponent as RemoveIcon } from 'theme/icons/close.svg'
import { All } from 'components/contestExpertProvinceCollection/All'

const useStyles = makeStyles(theme => ({
  tableHead: {
    borderBottom: `1px solid ${theme.palette.separator}`,
  },
  table: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(8),
  },
  tableHeadCell: {
    opacity: 0,
    border: 0,
    paddingBottom: theme.spacing(3),
  },
  tableHeadCellProvince: {
    color: theme.elements.table.tHeadC,
    fontSize: 14,
    fontStyle: 'italic',
    border: 0,
    paddingBottom: theme.spacing(3),
    width: '5%',
    '& span': {
      transform: 'rotate(-80deg)',
      display: 'block',
      whiteSpace: 'nowrap',
      transformOrigin: '0 100%',
      position: 'absolute',
      marginLeft: '20px',
    },
  },
  tableCell: {
    border: 0,
    color: theme.palette.primary.main,
    padding: 0,
    textAlign: 'center',
  },
  tableCellName: {
    border: 0,
    color: theme.palette.primary.main,
    padding: 0,
  },
}))

const TableRowItem = ({
  expert,
  contest,
  provinces,
  handleRemove,
  handleChangeProvince,
  disabled,
  contestUuid,
  handleRemoveAll,
  handleAddAll,
}) => {
  const classess = useStyles()
  const [contestProvinceExperts, setContestProvinceExperts] = useState(
    expert.contestProvinceExperts
  )

  useEffect(() => {
    setContestProvinceExperts(expert.contestProvinceExperts)
  }, [expert])

  const handleDecrement = uuid => {
    const array = contestProvinceExperts.filter(el => el.uuid !== uuid)
    setContestProvinceExperts(array)
  }
  const handleIncrement = collect => {
    setContestProvinceExperts(prevState => [...prevState, collect])
  }

  const handleCheckAll = contestProvinceExperts => {
    handleAddAll(contestProvinceExperts)
  }

  const handleUncheckAll = () => {
    handleRemoveAll(expert.uuid)
  }

  useEffect(() => {
    handleChangeProvince(contestProvinceExperts, expert.uuid)
  }, [contestProvinceExperts, expert]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {contestProvinceExperts !== undefined && (
        <>
          <TableCell className={classess.tableCellName}>
            {contestProvinceExperts.length === 0 && (
              <IconButton onClick={() => handleRemove(expert.uuid)}>
                <RemoveIcon />
              </IconButton>
            )}
            <span>
              {expert.firstName} {expert.lastName}
            </span>
          </TableCell>
          <TableCell className={classess.tableCell}>
            <All
              contest={contestUuid}
              expert={expert['@id']}
              disabled={disabled}
              contestProvinceExperts={contestProvinceExperts}
              handleCheckAll={handleCheckAll}
              handleUncheckAll={handleUncheckAll}
            />
          </TableCell>
          {provinces.map(province => {
            const provinceExpert = contestProvinceExperts.find(
              el => el.province === province['@id']
            )
            return (
              <TableCell className={classess.tableCell} key={province.uuid}>
                <Item
                  provinceExpert={provinceExpert}
                  contest={contest}
                  expert={expert['@id']}
                  province={province['@id']}
                  decrement={handleDecrement}
                  increment={handleIncrement}
                  disabled={disabled}
                />
              </TableCell>
            )
          })}
        </>
      )}
    </>
  )
}

TableRowItem.propTypes = {
  expert: PropTypes.object,
  contest: PropTypes.string,
  provinces: PropTypes.array,
  handleRemove: PropTypes.func,
  handleChangeProvince: PropTypes.func,
  contestUuid: PropTypes.string,
}

const prepareResource = arr => {
  // const arr = resource.contestProvinceExperts
  const experts = []

  arr.forEach(el => {
    const found = experts.find(element => element.uuid === el.expert.uuid)
    if (found === undefined) {
      experts.push(el.expert)
    }
  })

  const contestProvinceExperts = experts.map(el => {
    const provincesExpert = []
    arr.forEach(element => {
      if (element.expert.uuid === el.uuid) {
        provincesExpert.push({
          province: element.province,
          uuid: element.uuid,
        })
      }
    })
    return {
      ...el,
      contestProvinceExperts: provincesExpert,
    }
  })

  return contestProvinceExperts
}

const prepareResource2 = (arr, defineExperts) => {
  // const arr = resource.contestProvinceExperts
  console.log(defineExperts)
  const experts = []

  arr.forEach(el => {
    const found = experts.find(element => element['@id'] === el.expert)
    if (found === undefined) {
      const foundExpert = defineExperts.find(
        dElement => dElement['@id'] === el.expert
      )
      if (foundExpert !== undefined) {
        experts.push(foundExpert)
      }
    }
  })

  const contestProvinceExperts = experts.map(el => {
    const provincesExpert = []
    arr.forEach(element => {
      if (element.expert === el['@id']) {
        provincesExpert.push({
          province: element.province,
          uuid: element.uuid,
        })
      }
    })
    return {
      ...el,
      contestProvinceExperts: provincesExpert,
    }
  })

  return contestProvinceExperts
}

export const CollectionContestExpertProvince = ({
  resourceContest,
  disabled,
}) => {
  const [resource, setResource] = useState(resourceContest)
  const classess = useStyles()
  const [province, setProvince] = useState([])

  const [collection, setCollection] = useState([])

  const [experts, setExperts] = useState([])
  const [provinceExperts, setProvinceExperts] = useState([])

  useEffect(() => {
    const iriExpert = `${
      schemaUser('expert').endpoint
    }?isDeleted=false&pagination=false&stat=true`
    fetchDataHandleAuthError(iriExpert, 'GET', {}, resp => {
      setExperts(resp['hydra:member'])
    })
  }, [])

  useEffect(() => {
    const url = `${schemaProvinceExpoerts.endpoint}?contest.uuid=${resource.uuid}&pagination=false`
    fetchDataHandleAuthError(url, 'GET', {}, resp => {
      setProvinceExperts(resp['hydra:member'])
    })
  }, [resource.uuid])

  useEffect(() => {
    if (experts.length > 0 && provinceExperts.length > 0) {
      setCollection(prepareResource2(provinceExperts, experts))
    }
  }, [experts, provinceExperts])

  useEffect(() => {
    const iriProvince = `${schemaProvince.endpoint}?pagination=false`
    fetchDataHandleAuthError(iriProvince, 'GET', {}, resp => {
      setProvince(resp['hydra:member'])
    })
  }, [])

  const handleSetExpert = collect => {
    setCollection(state => [...state, collect])
  }

  const handleRemove = uuid => {
    const array = collection.filter(el => el.uuid !== uuid)
    setCollection(array)
  }

  const handleRemoveAll = expert => {
    const array = collection.map(el => {
      if (el.uuid === expert) {
        return { ...el, contestProvinceExperts: [] }
      } else {
        return el
      }
    })
    setCollection(array)
  }

  const handleAddAll = contestProvinceExperts => {
    setResource(prevState => ({ ...prevState, contestProvinceExperts }))
    const array = prepareResource(contestProvinceExperts)
    setCollection(array)
  }

  const handleChangeProvince = (contestProvinceExperts, uuid) => {
    const array = collection.map(el => {
      if (el.uuid === uuid) {
        el.contestProvinceExperts = contestProvinceExperts
      }
      return el
    })
    setCollection(array)
  }

  return (
    <>
      {collection !== undefined && (
        <>
          <Table size={'small'} className={classess.table}>
            <TableHead className={classess.tableHead}>
              <TableRow>
                <TableCell className={classess.tableHeadCell}>
                  {translate('Ekspert')}
                </TableCell>
                <TableCell className={classess.tableHeadCellProvince}>
                  <span>
                    <strong>{translate('Wszystkie województwa')}</strong>
                  </span>
                </TableCell>
                {province.map((el, index) => (
                  <TableCell
                    key={el.uuid}
                    className={classess.tableHeadCellProvince}
                  >
                    <span>
                      {index + 1} {el.title}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {collection.map(el => (
                <TableRow key={el.uuid}>
                  <TableRowItem
                    expert={el}
                    provinces={province}
                    contest={resource['@id']}
                    contestUuid={resource.uuid}
                    handleRemove={handleRemove}
                    handleChangeProvince={handleChangeProvince}
                    disabled={disabled}
                    handleRemoveAll={handleRemoveAll}
                    handleAddAll={handleAddAll}
                  />
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {!disabled && (
            <Add
              onSetExpert={handleSetExpert}
              expertsCollection={collection}
              experts={experts}
            />
          )}
        </>
      )}
    </>
  )
}

CollectionContestExpertProvince.propTypes = {
  resource: PropTypes.object,
}
