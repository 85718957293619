// Typy eventów
export const TYPE_PROPOSAL = 'PROPOSAL' // wniosek
export const TYPE_RATE_INTERNAL = 'RATE_INTERNAL' // ocena wewnętrzna
export const TYPE_RATE_INTERNAL_MANUAL = 'RATE_INTERNAL_MANUAL' // Ponowna ocena wewnętrzna
export const TYPE_RATE_FORMAL = 'RATE_FORMAL' // ocena formalna
export const TYPE_RATE_FORMAL_BIS = 'RATE_FORMAL_BIS' // Ponowna ocena formalna
export const TYPE_RATE_SUBSTANTIVE = 'RATE_SUBSTANTIVE' // ocena merytoryczna
export const TYPE_SUMMARY = 'SUMMARY' // podsumowanie
export const TYPE_TRAINING_INQUIRY = 'TRAINING_INQUIRY' // ankieta szkolenia
export const TYPE_TRAINING_INQUIRY_BIS = 'TRAINING_INQUIRY_BIS' // Ponowny formularz szkolenia
export const TYPE_TRAINING_RESULT = 'TRAINING_RESULT' // wyniki szkolenia
export const TYPE_CONTRACT_INQUIRY = 'CONTRACT_INQUIRY' // ankieta umowy
export const TYPE_CONTRACT = 'CONTRACT' // umowa
export const TYPE_QUARTER_DECLARATION = 'QUARTER_DECLARATION' // deklaracja kwartalna
export const TYPE_FINAL_REPORT = 'FINAL_REPORT' // Raport
export const TYPE_RELATION = 'RELATION' // Relacje
export const TYPE_APPOINTMENT = 'APPOINTMENT' // Spotkanie
export const TYPE_BUDGET_SHIFT = 'BUDGET_SHIFT' // Zmiana budżetu
export const TYPE_SCHEDULE_SHIFT = 'SCHEDULE_SHIFT' // Zmiana harmonogramu
export const TYPE_PROJECT_END_SHIFT = 'PROJECT_END_SHIFT' // Zmiana zakończenia projektu
export const TYPE_PROJECT_END = 'PROJECT_END' // Zamknięcie projektu
export const TYPE_COORDINATOR_CHANGE = 'COORDINATOR_CHANGE' // Zmiana koordynatora
export const TYPE_PARTIAL_REPORT = 'TYPE_PARTIAL_REPORT' // Raport częściowy
export const TYPE_REPORT_END_DATE_SHIFT = 'REPORT_END_DATE_SHIFT' // Zmiana daty raportu końcowego
export const TYPE_FINANCIAL_PARTIAL_REPORT = 'FINANCIAL_PARTIAL_REPORT'
export const TYPE_FINANCIAL_FINAL_REPORT = 'FINANCIAL_FINAL_REPORT'
export const TYPE_CONTENT_PARTIAL_REPORT = 'CONTENT_PARTIAL_REPORT' // Raport częściowy merytoryczny
export const TYPE_CONTENT_FINAL_REPORT = 'CONTENT_FINAL_REPORT' // Raport końcowy merytoryczny
export const TYPE_SUBSIDY_TRANCHES = 'SUBSIDY_TRANCHES' // Transze

const schema = {
  endpoint: '/proposal_events',
  endpointTab: '/proposal_events/tabs',
  endpointInternalRate: '/proposal_event/internal_rates',
  endpointFormalRate: '/proposal_event/formal_rates',
  endpointSubsidyTranches: '/proposal_subsidy_tranches',
}

export default schema
