import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: 16,
    '&> *': {
      margin: '0px 10px',
    },

    '&> *:last-child': {
      marginRight: 0,
    },
  },
})
