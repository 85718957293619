import React from 'react'
import { Route } from 'react-router-dom'
import { BusinessOutlined } from '@material-ui/icons'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { OrganizationData } from './OrganizationData'
import { roles } from '_helpers/roles'

const routes = () => {
  return {
    index: {
      title: 'T_MODULE_ORGANIZATION_DATA',
      type: Route,
      render: props =>  (
        <SchemableComponent
          path="definitions"
          component={OrganizationData}
          {...props}
        />
      ),
      path: '/organization_data',
      exact: true,
      icon: BusinessOutlined,
      resourceAccess: [ roles.APPLICANT ],
    },
  }
}

export default routes
