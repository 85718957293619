import React from 'react'
import { SectionTitle } from 'core/components/SectionTitle'
import schema from '_schema/proposal'
import { useCollectionFetch } from 'core/components/table/_helpers'
import { reducer } from 'core/components/table/_state'
import { Loader } from 'core/components/Loader'
import { useStyleTable } from 'components/styledTable'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import Moment from 'react-moment'

export const HistoryProposal = ({ resource }) => {
  const classes = useStyleTable()
  const endpoint = schema.endpoint_foundation_user

  const filters = {
    applicant: {
      name: 'applicant.uuid',
      value: resource.applicant.uuid,
      title: 'applicant.uuid',
      type: 'string',
      in: 'query',
      prop: 'value',
    },
  }

  const [state] = useCollectionFetch(
    reducer,
    endpoint,
    filters,
    [],
    null,
    false,
    null,
    endpoint + resource.applicant.uuid
  )

  return (
    <>
      <SectionTitle
        label={'Dotychczas złożone wnioski'}
        marginTopSm={true}
        divider={false}
      />
      {state.isFetching ? (
        <Loader align="center" marginTop={15} />
      ) : (
        <Table className={classes.table}>
          <TableHead className={classes.tableHead}>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>
                {translate('Nazwa konkursu')}
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                {translate('Nazwa projektu')}
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                {translate('Data złożenia wniosku')}
              </TableCell>
              <TableCell className={classes.tableHeadCell}>
                {translate('Status')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.data.items.map(el => (
              <TableRow key={el.uuid}>
                <TableCell className={classes.tableCell}>{el.title}</TableCell>
                <TableCell className={classes.tableCell}>
                  {el.contest.title}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Moment date={el.applicationDate} format={'DD.MM.YYYY'} />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  {el.acceptationStatus && (
                    <>
                      {translate(
                        `T_ACCEPTATION_STATUS_${el.acceptationStatus}`
                      )}
                    </>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  )
}
