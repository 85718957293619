import React, { useCallback, useState } from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { RadioType } from './RadioType'
import schema, {
  ACCEPTATION_STATUS_REJECTED,
  ACCEPTATION_STATUS_RESERVE,
  ACCEPTATION_STATUS_SUBSIDIZED,
  APPLICATION_STATUS_DRAFT,
  REALIZATION_STATUS_FINISHED,
  RECOMMENDATION_STATUS_NO,
  RECOMMENDATION_STATUS_YES,
} from '_schema/proposal'
import { StringType } from 'core/components/form/fields/StringType'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { useSelector } from 'react-redux'
import schemaUser from 'core/_schema/user'
import Moment from 'react-moment'

const useStyle = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
  root: {
    marginTop: theme.spacing(10),
  },
}))

const Status = ({ resource, updateResource, iriUser, disabled }) => {
  const classes = useStyle()
  const [state, setState] = useState({
    acceptationStatusMessage: resource.acceptationStatusMessage,
    acceptationStatus: resource.acceptationStatus,
    acceptationApplicantNotification: false,
    acceptationStatusEvaluator: iriUser,
  })

  const handleChange = useCallback((name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }))
  }, [])

  const handleSubmit = useCallback(
    (acceptationApplicantNotification = false) => {
      const data = state

      if (acceptationApplicantNotification) {
        data.acceptationApplicantNotification = true
      }

      data.acceptationStatusDate = new Date()

      fetchDataHandleAuthError(
        `${schema.endpoint_acceptation_status}/${resource.uuid}`,
        'PUT',
        { body: JSON.stringify(data) },
        () => {
          updateResource({
            ...resource,
            acceptationStatus: data.acceptationStatus,
          })
          notification(
            'success',
            translate(
              acceptationApplicantNotification
                ? 'Status wniosku został zapisany i wysłany do klienta'
                : 'Status wniosku został zapisany'
            )
          )
        },
        error => {
          notification('error', error.response.detail, error.response.title)
        }
      )
    },
    [resource, state, updateResource]
  )

  return (
    <>
      <Grid container spacing={3} alignItems={'center'}>
        <Grid item xs={4} md={3}>
          <RadioType
            value={ACCEPTATION_STATUS_SUBSIDIZED}
            name={'acceptationStatus'}
            rate={state.acceptationStatus}
            label={translate(
              `T_ACCEPTATION_STATUS_${ACCEPTATION_STATUS_SUBSIDIZED}`
            )}
            disabled={disabled}
            color={'green'}
            onChange={event =>
              handleChange('acceptationStatus', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <RadioType
            value={ACCEPTATION_STATUS_RESERVE}
            name={'acceptationStatus'}
            rate={state.acceptationStatus}
            label={translate(
              `T_ACCEPTATION_STATUS_${ACCEPTATION_STATUS_RESERVE}`
            )}
            disabled={disabled}
            color={'blue'}
            onChange={event =>
              handleChange('acceptationStatus', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={4} md={3}>
          <RadioType
            value={ACCEPTATION_STATUS_REJECTED}
            name={'acceptationStatus'}
            rate={state.acceptationStatus}
            label={translate(
              `T_ACCEPTATION_STATUS_${ACCEPTATION_STATUS_REJECTED}`
            )}
            disabled={disabled}
            color={'red'}
            onChange={event =>
              handleChange('acceptationStatus', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography variant={'body1'} color={'primary'}>
            {resource.acceptationStatusDate && (
              <Moment
                date={resource?.acceptationStatusDate}
                format={'DD.MM.YYYY'}
              />
            )}{' '}
            {resource?.acceptationStatusEvaluator?.firstName}{' '}
            {resource?.acceptationStatusEvaluator?.lastName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StringType
            name={'acceptationStatusMessage'}
            type={'textarea'}
            setError={() => false}
            error={false}
            renderError={false}
            value={state.acceptationStatusMessage}
            setValue={handleChange}
            label={'Komunikat'}
            width={'100%'}
            disabled={disabled}
          />
        </Grid>
        {!disabled && (
          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button
                variant="outlined"
                size="large"
                color={'secondary'}
                onClick={() => handleSubmit(false)}
              >
                <span>{translate('T_GENERAL_SAVE')}</span>
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => handleSubmit(true)}
              >
                <span>{translate('T_GENERAL_SAVE_AND_SEND')}</span>
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  )
}

const Recommended = ({ resource, updateResource, iriUser, disabled }) => {
  const classes = useStyle()
  const [state, setState] = useState({
    recommendationStatusMessage: resource.recommendationStatusMessage,
    recommendationStatus: resource.recommendationStatus,
    recommendationApplicantNotification: false,
    recommendationStatusEvaluator: iriUser,
  })

  const handleChange = useCallback((name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }))
  }, [])

  const handleSubmit = useCallback(
    (recommendationApplicantNotification = false) => {
      const data = state

      if (recommendationApplicantNotification) {
        data.recommendationApplicantNotification = true
      }
      data.recommendationStatusDate = new Date()

      fetchDataHandleAuthError(
        `${schema.endpoint_recommendation_status}/${resource.uuid}`,
        'PUT',
        { body: JSON.stringify(data) },
        () => {
          updateResource({
            ...resource,
            recommendationStatus: data.recommendationStatus,
          })
          notification(
            'success',
            translate(
              recommendationApplicantNotification
                ? 'Status rekomendacji wniosku został zapisany i wysłany do klienta'
                : 'Status rekomendacji wniosku został zapisany'
            )
          )
        },
        error => {
          notification('error', error.response.detail, error.response.title)
        }
      )
    },
    [resource, state, updateResource]
  )

  return (
    <>
      <Grid
        container
        spacing={3}
        className={classes.root}
        alignItems={'center'}
      >
        <Grid item xs={6} md={4}>
          <RadioType
            value={RECOMMENDATION_STATUS_YES}
            name={'recommendationStatus'}
            rate={state.recommendationStatus}
            label={translate(
              `T_RECOMMENDATION_STATUS_${RECOMMENDATION_STATUS_YES}`
            )}
            disabled={disabled}
            color={'blue'}
            onChange={event =>
              handleChange('recommendationStatus', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={6} md={4}>
          <RadioType
            value={RECOMMENDATION_STATUS_NO}
            name={'recommendationStatus'}
            rate={state.recommendationStatus}
            label={translate(
              `T_RECOMMENDATION_STATUS_${RECOMMENDATION_STATUS_NO}`
            )}
            disabled={disabled}
            color={'red'}
            onChange={event =>
              handleChange('recommendationStatus', event.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant={'body1'} color={'primary'}>
            {resource.recommendationStatusDate && (
              <Moment
                date={resource?.recommendationStatusDate}
                format={'DD.MM.YYYY'}
              />
            )}{' '}
            {resource?.recommendationStatusEvaluator?.firstName}{' '}
            {resource?.recommendationStatusEvaluator?.lastName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StringType
            name={'recommendationStatusMessage'}
            type={'textarea'}
            setError={() => false}
            error={false}
            renderError={false}
            value={state.recommendationStatusMessage}
            setValue={handleChange}
            label={'Notes / Komunikat'}
            width={'100%'}
            disabled={disabled}
          />

          {resource.recommendationApplicantNotificationDate && (
            <>
              <Typography variant={'body1'} color={'primary'}>
                {translate('Wysłane do Wnioskodawcy')}:{' '}
                <Moment
                  date={resource?.recommendationApplicantNotificationDate}
                  format={'DD.MM.YYYY'}
                />
              </Typography>
            </>
          )}
        </Grid>
        {!disabled && (
          <Grid item xs={12}>
            <div className={classes.buttons}>
              <Button
                variant="outlined"
                size="large"
                color={'secondary'}
                onClick={() => handleSubmit(false)}
              >
                {translate('T_GENERAL_SAVE')}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => handleSubmit(true)}
              >
                {translate('T_GENERAL_SAVE_AND_SEND')}
              </Button>
            </div>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export const Summary = ({ resource, updateResource }) => {
  const user = useSelector(state => state.profile)
  const iriUser = `${schemaUser('user').endpoint}/${user.uuid}`
  const disabled =
    resource.applicationStatus === APPLICATION_STATUS_DRAFT ||
    resource.realizationStatus === REALIZATION_STATUS_FINISHED ||
    resource.contractStatus !== null
  return (
    <>
      <Status
        resource={resource}
        updateResource={updateResource}
        iriUser={iriUser}
        disabled={disabled}
      />
      <Recommended
        resource={resource}
        updateResource={updateResource}
        iriUser={iriUser}
        disabled={disabled}
      />
    </>
  )
}
