import React, { useEffect } from 'react'
import { useCollectionContainerStyles } from 'components/applicant/collection_styles/CollectionContainer.styles'
import { Paper } from 'core/components/Paper'
import { ProjectScheduleTabs } from 'pages/applicant/InProgressProposals/ProjectSchedule/ProjectScheduleTabs'
import routes from '../routes'
import { useProposalFetch } from './_helpers/useProposalFetch'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import { useResourceState } from 'core/_helpers/useResourceState'
import proposalSchema from '_schema/proposal'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'
import { FormStatus, DetailsForm } from './Details/components'
import { useProject } from '_helpers/useProject'
import { Typography } from '@material-ui/core'
import { DetailsHistory } from './Details/components/DetailsHistory'

const useStyles = makeStyles(theme => ({
  table_header_line: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '&::after': {
      content: '""',
      height: 1,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      marginLeft: 10,
    },
  },
}))

export const Details = ({ schema: definitions, match }) => {
  const classes = useCollectionContainerStyles()
  const localClasses = useStyles()
  const proposalUuid = match.params.id

  const project = useProject(proposalUuid)

  const [proposalState, setProposalState] = useResourceState()

  const {
    resource: proposalResource,
    isFetching: proposalResourceIsFetching,
    fetchError: proposalResourceFetchError,
  } = proposalState

  const iri = `${proposalSchema.endpoint}/${proposalUuid}`

  useProposalFetch(
    proposalSchema.endpoint_schedule_requests,
    proposalUuid,
    setProposalState.resource,
    setProposalState.fetchError
  )

  useEffect(() => {
    if (!proposalState.isFetching && proposalState.resource.length === 0) {
      setProposalState.resource([{ schedule: project.data.projectSchedule }])
    }
  }, [proposalState, project, setProposalState])

  const handleSuccess = resp => {
    setProposalState.resource([resp, ...proposalState.resource])
  }

  return proposalResourceIsFetching || project.isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : proposalResourceFetchError ? (
    <NotFound />
  ) : (
    <>
      <ProjectScheduleTabs
        value={routes().details.path.replace(':id', match.params.id)}
        classes={{ root: classes.tabs }}
        id={match.params.id}
      />
      <Paper classes={{ root: classes.paper_wrapper }}>
        <h3 className={localClasses.table_header_line}>
          {translate(routes().details.title)}
        </h3>
        {proposalResource &&
          proposalResource.length > 0 &&
          proposalResource[0].status === 'PENDING' && (
            <>
              <Typography
                variant={'subtitle1'}
                color={'error'}
                style={{ marginBottom: 20 }}
              >
                Status zmiany: w trakcie weryfikacji
              </Typography>
              {proposalResource?.[0].question}
              <FormStatus
                url={`/proposal_schedule_requests/${proposalResource?.[0].uuid}/answer`}
                method="PATCH"
                definitionSchema={
                  definitions[
                    proposalSchema.resource_schedule_requests_answer.definition
                  ]
                }
                customResourceSchema={
                  proposalSchema.resource_schedule_requests_answer
                }
                submitButtonTitle={'Wyślij'}
              />
            </>
          )}

        {proposalResource &&
          proposalResource.length > 0 &&
          proposalResource[0].status === 'AUTO_DRAFT' && (
            <Typography
              variant={'subtitle1'}
              color={'error'}
              style={{ marginBottom: 20 }}
            >
              Szkic automatyczny wygenerowany po zgłoszeniu zmiany czasu
              realizacji.
              <br />
              Proszę sprawdzić harmonogram i potwierdzić zgłoszenie zmiany.
              <br />
              Operacja WYMAGANA do zatwierdzenia zmiany czasu realizacji
              projektu.
            </Typography>
          )}

        <DetailsForm
          resource={{
            proposal: iri,
            status:
              proposalResource.length > 0 &&
              ['PENDING', 'AUTO_DRAFT'].includes(proposalResource[0].status)
                ? proposalResource?.[0].status
                : 'NEW',
            schedule:
              proposalResource.length > 0 &&
              ['PENDING', 'AUTO_DRAFT'].includes(proposalResource[0].status)
                ? proposalResource?.[0].schedule
                : project.data.currentProjectSchedule,
            current: project.data.currentProjectSchedule,
            description:
              proposalResource.length > 0 &&
              ['PENDING', 'AUTO_DRAFT'].includes(proposalResource[0].status)
                ? proposalResource[0].description
                : '',
          }}
          disabled={
            proposalResource.length > 0 &&
            ['PENDING'].includes(proposalResource[0].status)
          }
          definitionSchema={
            definitions[proposalSchema.resource_schedule_requests.definition]
          }
          handleSuccess={handleSuccess}
          customResourceSchema={proposalSchema.resource_schedule_requests}
          url={
            proposalResource.length > 0 &&
            proposalResource[0].status === 'AUTO_DRAFT'
              ? `${proposalSchema.endpoint_schedule_requests}/${proposalResource[0].uuid}`
              : proposalSchema.endpoint_schedule_requests
          }
        />

        {proposalResource.length > 1 && (
          <>
            <h3
              className={localClasses.table_header_line}
              style={{ marginTop: 100 }}
            >
              Historia zmian
            </h3>
            {proposalResource.map(el => (
              <DetailsHistory scheduleRequest={el} key={el.uuid} />
            ))}
          </>
        )}
      </Paper>
    </>
  )
}
