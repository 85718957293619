import { makeStyles } from '@material-ui/core/styles'

export const useBlockCollectionStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 25,
    borderBottom: `1px solid ${theme.elements.background}`,
  },
  massExpand: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    marginLeft: theme.spacing(3),
    color: theme.palette.secondary.main,
    fontWeight: 700,
    fontSize: 16,
    maxHeight: 20,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  addContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 8,
    '& > *': {
      marginRight: 15,
    },
    backgroundColor: theme.elements.embedded_collection.background,
    color: theme.elements.embedded_collection.color,
  },
  collectionContainer: {
    position: 'relative',
    overflow: 'hidden',
    marginBottom: 20,
  },
  cell: {
    verticalAlign: 'middle',
    borderBottom: 'none',
    border: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  itemContainer: {
    display: 'block',
  },
  emptyResults: {
    textAlign: 'left',
  },
  draggedRow: {
    display: 'block',
    background: theme.elements.content,
    '& td': {
      display: 'inline-block',
      borderBottom: 'none',
    },
  },
  contextualHint: {
    display: 'flex',
    flexGrow: 2,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  expansionPanel: {
    borderRadius: 0,
    boxShadow: 'none',
  },
  expansionPanelSummary: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 0,
  },
  blockTitle: {
    flexGrow: 3,
    marginLeft: 10,
  },
  blockIcon: {
    margin: '0 5px',
    display: 'flex',
    alignItems: 'center',
  },
  expandIcon: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 5,
    color: theme.palette.primary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(5),
    overflow: 'hidden',
    '& hr': {
      display: 'block',
      width: '100%',
      backgroundColor: theme.palette.separator,
    },
    '& h2': {
      display: 'block',
      width: '100%',
      flexGrow: 1,
      whiteSpace: 'nowrap',
      paddingRight: theme.spacing(3),
    },
  },
}))
