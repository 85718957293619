import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useFormTemplateSectionBlockTypeStyles } from './FormTemplateSectionBlockType.styles'
import { blocks } from 'pages/applicant/Proposals/components/form/blocks'
import { isFormTemplateInvalid } from 'pages/applicant/Proposals/_helpers/isFormTemplateInvalid'

export const FormTemplateSectionBlockType = ({
  uuid,
  type,
  data,
  disabled,
  values,
  errors,
  renderError,
  globalRenderError,
  additional,
  sectionUuid,
  sectionItemIndex,
  setState,
  contest,
  proposal
}) => {
  const setValue = useCallback(
    (name, value) => {
      setState(state => ({
        ...state,
        values: {
          ...state.values,
          [sectionUuid]: state.values?.[sectionUuid]?.map(
            (item, index) => index === sectionItemIndex
              ? {
                ...item,
                [uuid]: {
                  ...item[uuid],
                  [name]: value
                }
              } : item
          ) || [{
            [uuid]: {
              [name]: value
            }
          }]
        },
        renderError: {
          ...state.renderError,
          [sectionUuid]: state.renderError?.[sectionUuid]?.map(
            (item, index) => index === sectionItemIndex
              ? {
                ...item,
                [uuid]: {
                  ...item[uuid],
                  [name]: true
                }
              } : item
          ) || [{
            [uuid]: {
              [name]: true
            }
          }]
        },
        init: false,
      }))
    },
    [sectionUuid, sectionItemIndex, uuid, setState]
  )

  const setError = useCallback(
    (name, error, show = false) => {
      setState(state => ({
        ...state,
        errors: getCurrent(
          state.errors,
          sectionUuid,
          sectionItemIndex,
          uuid,
          name,
          error
        ),
        renderError: state.isFormSubmitted
          ? getCurrent(
            state.renderError,
            sectionUuid,
            sectionItemIndex,
            uuid,
            name,
            true
          ) : show
            ? {
              ...state.renderError,
              [sectionUuid]: state.renderError?.[sectionUuid]?.map(
                (item, index) => index === sectionItemIndex
                  ? {
                    ...item,
                    [uuid]: {
                      ...item[uuid],
                      [name]: true
                    }
                  } : item
              ) || [{
                [uuid]: {
                  [name]: true
                }
              }]
            } : state.renderError,
        isInvalid: isFormTemplateInvalid(
          getCurrent(
            state.errors,
            sectionUuid,
            sectionItemIndex,
            uuid,
            name,
            error
          )
        )
      }))
    },
    [sectionUuid, sectionItemIndex, uuid, setState]
  )

  const setAdditional = useCallback(
    additional => {
      setState(state => ({
        ...state,
        additional: {
          ...state.additional,
          ...additional(state.additional)
        }
      }))
    }, [setState]
  )

  const BlockComponent = blocks[type]

  const classes = useFormTemplateSectionBlockTypeStyles()

  return (
    <div className={classes.root}>
      {BlockComponent && (
        <BlockComponent
          data={data}
          values={values}
          errors={errors}
          renderError={renderError}
          globalRenderError={globalRenderError}
          setValue={setValue}
          setError={setError}
          disabled={disabled}
          contest={contest}
          proposal={proposal}
          additional={additional}
          setAdditional={setAdditional}
        />
      )}
    </div>
  )
}

const getCurrent = (errors, sectionUuid, sectionItemIndex, blockUuid, fieldName, fieldError) => ({
  ...errors,
  [sectionUuid]: errors?.[sectionUuid]?.map(
    (item, index) => index === sectionItemIndex
      ? {
        ...item,
        [blockUuid]: {
          ...item[blockUuid],
          [fieldName]: fieldError
        }
      } : item
  ) || [{
    [blockUuid]: {
      [fieldName]: fieldError
    }
  }]
})

FormTemplateSectionBlockType.propTypes = {
  uuid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.string,
  disabled: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  sectionUuid: PropTypes.string.isRequired,
  sectionItemIndex: PropTypes.number.isRequired,
  setState: PropTypes.func.isRequired,
  contest: PropTypes.shape({
    projectRealizationDateFrom: PropTypes.string,
    projectRealizationDateTo: PropTypes.string
  }).isRequired,
  proposal: PropTypes.object.isRequired
}
