import { validators } from './validators'

export const validate = (validatorNames, value) => {
  return (validatorNames && validatorNames.length
    ? validatorNames.map(name => {
        if (name === null || !name) {
          return [{ result: true, message: null }]
        } else {
          return name.includes('(')
            ? validators[name.split('(')[0]](
                value,
                name
                  .split('(')
                  .pop()
                  .split(')')[0]
              )
            : typeof validators[name] === 'function'
            ? validators[name](value)
            : [{ result: true, message: null }]
        }
      })
    : [{ result: true, message: null }]
  ).reduce((prev, curr) => (prev.result && !curr.result ? curr : prev))
}
