import arrayMove from 'array-move'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { constants } from 'core/components/table/_state'

export const performManualOrderChange = (
  items,
  selected,
  oldIndex,
  newIndex,
  dispatch,
  direction
) => {
  const oldOrd = items[oldIndex].ord
  const newOrd = items[newIndex].ord

  dispatch({ type: constants.MANUAL_ORDER_CHANGE_START })

  fetchDataHandleAuthError(
    items[oldIndex]['@id'],
    'PATCH',
    {
      body: JSON.stringify({
        newOrd,
      }),
    },
    () => {
      dispatch({
        type: constants.MANUAL_ORDER_CHANGE_SUCCESS,
        payload: {
          items: arrayMove(items, oldIndex, newIndex).map(item => ({
            ...item,
            ord:
              item.ord === oldOrd
                ? newOrd
                : oldOrd > newOrd
                ? item.ord >= newOrd && item.ord < oldOrd
                  ? item.ord + (direction === 'desc' ? 1 : -1)
                  : item.ord
                : item.ord <= newOrd && item.ord > oldOrd
                ? item.ord + (direction === 'desc' ? -1 : 1)
                : item.ord,
          })),
          selected: arrayMove(selected, oldIndex, newIndex),
        },
      })
      notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')
    },
    error => {
      dispatch({ type: constants.MANUAL_ORDER_CHANGE_FAILURE })
      notification(
        'error',
        error.response.violations.length ? 'Error' : error.response.detail,
        error.response.title
      )
    }
  )
}
