import _media from './_media'
import photoSchema from './photo'

const schema = {
  endpoint: '/package_galleries',
  resource: {
    definition: 'PackageGallery-packageGallery:read',
    properties: {
      title: {
        validate: ['required'],
      },
    },
  },
  subresources: {
    item: {
      endpoint: '/package_gallery_items',
      definition: 'PackageGalleryItem-packageGalleryItem:read',
      types: _media(photoSchema.endpoint.single, '/package_gallery_item_thumbs')
        .types,
    },
  },
}

export default schema
