import React from 'react'
import { Route } from 'react-router-dom'
import { Collection } from './Collection'
import { Edit } from './Edit'
import { Preview } from './Preview'
import schema from '_schema/form'
import { SchemableComponent } from 'core/components/SchemableComponent'

const routes = () => {
  return {
    index: {
      title: 'Szablony',
      type: Route,
      render: props => <Collection {...props} />,
      path: schema.endpoint,
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN'],
    },
    preview: {
      title: 'Edycja szablonu',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={Preview}
          uniqueKey={true}
          {...props}
        />
      ),
      path: `${schema.endpoint}/preview/:id`,
      exact: false,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN'],
    },
    item: {
      title: 'Edycja szablonu',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={Edit}
          uniqueKey={true}
          {...props}
        />
      ),
      path: `${schema.endpoint}/:id`,
      exact: false,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN'],
    },
  }
}

export default routes
