import React, { useEffect, useMemo, useState } from 'react'
import { translate } from 'core/_helpers/translate'
import schemaEdition from '_schema/contestEdition'
import schema from '_schema/contests'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { standard } from 'core/components/table/_columns/standard'
import { operations } from 'core/components/table/_columns/operations'
import { DateCell } from 'core/components/table/cells/DateCell'
import { AddInfoCell } from 'core/components/table/cells/AddInfoCell'
import { Paper } from 'core/components/Paper'
import { TranslateCell } from 'core/components/table/cells/TranslateCell'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { makeStyles } from '@material-ui/styles'
import { ReactComponent as ArrowIcon } from 'theme/icons/arrow-down.svg'
import { Menu, MenuItem } from '@material-ui/core'
import { Form } from 'core/components/form'
import { CustomDialog } from 'core/components/Dialog'
import { ButtonTrash } from 'core/components/buttons/button-trash'
import routes from 'pages/Foundation/Home/routes'

const useStyles = makeStyles(theme => ({
  button: {
    fontFamily: ['Sora', 'sans-serif'],
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '42px',
    letterSpacing: '0.05em',
    border: 0,
    boxShadow: 0,
    background: 'none',
    cursor: 'pointer',
    padding: '0 10px',
    color: theme.palette.primary.main,
    transition: 'color 0.2s',
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 12,
      '& path': {
        transition: 'stroke 0.2s',
      },
    },
    '&:hover, &:focus': {
      color: theme.palette.secondary.main,
      '& svg path': {
        stroke: theme.palette.secondary.main,
      },
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      minWidth: '250px',
    },
  },
  removeContent: {
    position: 'absolute',
    bottom: theme.spacing(5),
    right: theme.spacing(4),
  },
}))

export const Collection = () => {
  const classes = useStyles()
  const columns = useMemo(
    () => [
      {
        ...standard('title'),
        sortable: false,
        width: '20%',
      },
      {
        Cell: TranslateCell,
        accessor: 'type',
        sortable: false,
        width: '10%',
      },
      {
        Cell: TranslateCell,
        accessor: 'publicationStatus',
        sortable: false,
        width: '10%',
      },
      {
        accessor: 'publicationDate',
        Cell: DateCell,
        sortable: false,
        width: '10%',
      },
      {
        accessor: 'proposalCollectingDateFrom',
        Cell: DateCell,
        sortable: false,
        width: '10%',
      },
      {
        accessor: 'projectRealizationDateFrom',
        Cell: DateCell,
        sortable: false,
        width: '10%',
      },
      {
        accessor: 'projectRealizationDateTo',
        Cell: DateCell,
        sortable: false,
        width: '10%',
      },
      {
        accessor: 'proposalSubmittedCount',
        Cell: AddInfoCell,
        sortable: false,
        width: '20%',
      },
      {
        ...operations(
          'title',
          true,
          true,
          false,
          () => false,
          false,
          false,
          resource => `${schema.endpointDelete}/${resource.uuid}`
        ),
        width: '10%',
        editable: true,
        deletable: true,
      },
    ],
    []
  )

  const [reload, setReload] = useState(false)
  const [editionMenu, setEditionMenu] = useState(null)
  const [edition, setEdition] = useState(null)
  const [listEdition, setListEdition] = useState([])
  const url = `${schemaEdition.endpoint}?pagination=false`

  useEffect(() => {
    const controller = new AbortController()
    fetchDataHandleAuthError(url, 'GET', {}, response => {
      const preparedResources = [...response['hydra:member']].reverse()
      if (preparedResources.length > 0) {
        setListEdition(preparedResources)
      }
    })
    return () => controller.abort()
  }, [url])

  useEffect(() => {
    setEdition(listEdition[0])
  }, [listEdition])

  const handleEditionChange = el => {
    setEdition(el)
    setReload(true)
    setEditionMenu(null)
  }

  const handleSetReload = () => {
    setReload(state => !state)
  }

  const handleOpenClick = event => {
    setEditionMenu(event.currentTarget)
  }

  const handleClose = () => {
    setEditionMenu(null)
  }

  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const handleConfirmToggle = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
  }

  const handleConfirmSuccess = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
    setReload(true)
  }

  const [buttonRef, setButtonRef] = useState(null)

  const onButtonRef = element => {
    setButtonRef(element)
  }

  const [properties, setProperties] = useState(schema.resource_new.properties)

  useEffect(() => {
    if (!!edition) {
      setProperties(state => ({
        ...state,
        contestEdition: {
          type: 'hidden',
          defaultValue: edition['@id'],
        },
      }))
    }
  }, [edition])

  const Dialog = () => (
    <>
      <Form
        url={schema.endpoint}
        method="POST"
        properties={properties}
        showSubmitAndStayButton={false}
        showCancelButton={false}
        fieldsFullWidth={true}
        width="100%"
        handleCancel={handleConfirmToggle}
        buttonContainerRef={buttonRef}
        handleSuccess={handleConfirmSuccess}
      />
    </>
  )

  return (
    <>
      <CollectionHeader
        buttonTitle={translate('T_CONTEST_EDITION_URL')}
        buttonPath={routes().contest_edition.path}
      >
        {translate('Edycja')}:
        {edition && (
          <>
            <button
              className={classes.button}
              aria-controls="edition-menu"
              aria-haspopup="true"
              onClick={handleOpenClick}
            >
              <span>{edition ? edition.title : translate('Wybierz')}</span>
              <ArrowIcon />
            </button>
            <Menu
              id="edition-menu"
              keepMounted
              anchorEl={editionMenu}
              onClose={handleClose}
              open={Boolean(editionMenu)}
              className={classes.menu}
            >
              {listEdition.map(el => (
                <MenuItem key={el.id} onClick={() => handleEditionChange(el)}>
                  {el.title}
                </MenuItem>
              ))}
            </Menu>
          </>
        )}
      </CollectionHeader>
      <Paper withPadding={false}>
        {edition ? (
          <>
            <ButtonPlus
              text="Dodaj konkurs do edycji"
              onClick={handleConfirmToggle}
            />
            <SchemableCollection
              component={CollectionTable}
              path={`paths.${schema.endpoint}.get`}
              endpoint={`${schema.endpoint}`}
              customResourceSchema={schema.resource}
              columns={columns}
              customFilters={[
                {
                  name: 'contestEdition.id',
                  value: edition.id,
                  title: 'editionId',
                },
                {
                  name: 'isDeleted',
                  value: 'false',
                  title: 'isDeleted',
                },
              ]}
              autoWidth={false}
              filterable={false}
              sortable={false}
              numerable={false}
              resetable={false}
              paginable={false}
              definitionSchema={schema.resource}
              reload={reload}
              setReload={handleSetReload}
            />
            <CustomDialog
              title="Dodaj konkurs do edycji"
              component={Dialog}
              confirmedButton={false}
              onConfirm={() => false}
              open={state.isConfirmOpen}
              handleToogle={handleConfirmToggle}
              isFetching={state.isFetching}
              showActions={true}
              onButtonRef={onButtonRef}
            />
          </>
        ) : (
          <>{translate('Brak edycji')}</>
        )}
        <div className={classes.removeContent}>
          <ButtonTrash text={'Kosz'} path={`${schema.endpoint}/trash`} />
        </div>
      </Paper>
    </>
  )
}
