import { COLORS } from '../colors'

export default {
  root: {
    width: '100%',
    fontSize: 16,
    maxWidth: '350px',
    color: COLORS.WARIANT,
    padding: '4px 12px',
    '&:hover': {
      background: COLORS.LIGHTNING,
    },
  },
}
