import { makeStyles } from '@material-ui/core/styles'
import Badge from '@material-ui/core/Badge'
import { withStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  menuItem: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  listItem: {
    minWidth: 32,
  },
  menu_button: {
    marginRight: theme.spacing(5),
    '& svg': {
      transition: 'all 0.2s',
    },
    '&:hover': {
      '& svg': {
        '& path': {
          fill: theme.palette.secondary.main,
        },
      },
    },
  },
  list_item: {
    maxWidth: 388,
    width: '100%',
    color: theme.palette.primary.main,
    padding: '18px 0',
  },
  list_item_date: {
    marginRight: theme.spacing(2),
    paddingTop: '8px',
    paddingBottom: '6px',
  },
  divider: {
    backgroundColor: theme.palette.separator,
  },
  list_item_avatar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  list_item_button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  list: {
    // maxHeight: '578px',
    overflow: 'auto',
  },
  paper: {
    '&:after': {
      background:
        'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 86.98%)',
      width: '100%',
      height: '64px',
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
  },
}))

export const StyledBadge = withStyles(theme => ({
  badge: {
    right: 2,
    top: 2,
    border: `0`,
    padding: '0 4px',
  },
}))(Badge)
