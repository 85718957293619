import React from 'react'
import { Route } from 'react-router-dom'
import { Collection } from './Collection'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { Edit } from './Edit'
import { Item } from './Item'
import { TrashCollection } from './TrashCollection'

const routes = () => {
  return {
    index: {
      title: 'Organizacje',
      type: Route,
      render: props => <Collection {...props} />,
      path: '/organizations',
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
    trash: {
      title: 'Kosz organizacji',
      type: Route,
      render: props => <TrashCollection {...props} />,
      path: '/organizations/trash',
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
    item: {
      title: 'Edycja organizacji',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={Item}
          uniqueKey={true}
          {...props}
        />
      ),
      path: '/organizations/:id',
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
    edit: {
      title: 'Edycja organizacji',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={Edit}
          uniqueKey={true}
          {...props}
        />
      ),
      path: '/organizations/edit/:id',
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
  }
}

export default routes
