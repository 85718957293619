import React, { useCallback, useMemo, useState } from 'react'
import { DataInfo } from 'components/DataInfo'
import { Grid } from '@material-ui/core'
import Moment from 'react-moment'
import { makeStyles } from '@material-ui/styles'
import { SectionTitle } from 'core/components/SectionTitle'
import moment from 'moment/min/moment-with-locales'
import { ContactPerson } from './ContactPerson'
import { ButtonRight } from 'core/components/buttons/button-right'
import { CustomDialog } from 'core/components/Dialog'

Moment.globalMoment = moment
Moment.globalLocale = 'pl'

const useStyles = makeStyles(theme => ({
  bordered: {
    borderRight: '1px solid #ccc',
  },
  columns: {
    marginTop: theme.spacing(5),
  },
  more: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
}))

export const OrganizationData = ({ resource }) => {
  const classes = useStyles()
  const contactPersonCount = resource.proposalCoordinators.length

  const [open, setOpen] = useState(false)

  const handleToogle = useCallback(() => {
    setOpen(prevState => !prevState)
  }, [])

  const DialogText = useMemo(
    () => (
      <>
        {resource.proposalCoordinators.map((el, index) => {
          return (
            <ContactPerson
              item={el}
              key={index}
              index={index}
              count={contactPersonCount}
            />
          )
        })}
      </>
    ),
    [contactPersonCount, resource.proposalCoordinators]
  )
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <DataInfo label={'NIP'} data={resource.nip} bold />
          <DataInfo label={'KRS'} data={resource.krs} bold />
          <DataInfo label={'Regon'} data={resource.regon} bold />
          <DataInfo
            label={'T_MODULE_ORGANIZATION_DATA_ORGANIZATION_TYPE'}
            data={resource?.organizationType?.title}
            bold
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataInfo label={'E-mail'} data={resource.email} bold />
          <DataInfo label={'Telefon'} data={resource.phone} bold />
        </Grid>
        <Grid item xs={12} md={4}>
          <DataInfo
            label={'Założenie konta'}
            data={
              <>
                <Moment date={resource.createdAt} format={'DD-MM-YYYY'} /> (
                <Moment date={resource.createdAt} fromNow />)
              </>
            }
            bold
          />
          <DataInfo
            label={'Suma dotacji'}
            data={`${String(resource.subsidySum)} zł`}
            bold
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.columns}>
        <Grid item className={classes.bordered} xs={12} md={4}>
          <SectionTitle
            label={'T_MODULE_ORGANIZATION_DATA_ORGANIZATION_ADDRESS'}
            divider={false}
          />
          <DataInfo
            label={'T_MODULE_ORGANIZATION_DATA_POST_OFFICE'}
            data={resource.postOffice}
          />
          <DataInfo
            label={'T_MODULE_ORGANIZATION_DATA_POST_CODE'}
            data={resource.postCode}
          />
          <DataInfo
            label={'T_MODULE_ORGANIZATION_DATA_ADDRESS'}
            data={resource.address}
          />
          <DataInfo
            label={'T_MODULE_ORGANIZATION_DATA_CITY'}
            data={resource.city}
          />
          <DataInfo
            label={'T_MODULE_ORGANIZATION_DATA_COMMUNE'}
            data={resource.commune}
          />
          <DataInfo
            label={'T_MODULE_ORGANIZATION_DATA_DISTRICT'}
            data={resource.district}
          />
          <DataInfo
            label={'T_MODULE_ORGANIZATION_DATA_PROVINCE'}
            data={resource?.province.title}
          />
        </Grid>
        <Grid item className={classes.bordered} xs={12} md={4}>
          <SectionTitle
            label={'T_MODULE_ORGANIZATION_DATA_ADDITIONAL_INFORMATIONS'}
            divider={false}
          />
          <DataInfo
            label={'T_MODULE_ORGANIZATION_DATA_POPULATION'}
            data={resource?.population?.title}
          />
          <DataInfo
            label={'T_MODULE_ORGANIZATION_DATA_POPULATION_DATA_SOURCE'}
            data={resource.populationDataSource}
          />
          <DataInfo
            label={'T_MODULE_ORGANIZATION_DATA_WEBSITE'}
            data={resource.website}
          />
          <DataInfo
            label={'T_MODULE_ORGANIZATION_DATA_ORGANIZATION_CREATION_DATE'}
            data={resource?.organizationCreationDate}
          />
          <SectionTitle
            label={'Osoby kontaktowe (z poprzednich wniosków)'}
            divider={false}
          />
          <div>
            {contactPersonCount > 0 &&
              resource.proposalCoordinators.slice(0, 3).map((el, index) => {
                return (
                  <ContactPerson
                    item={el}
                    key={index}
                    index={index}
                    count={contactPersonCount}
                  />
                )
              })}
            {contactPersonCount > 3 && (
              <div className={classes.more}>
                <ButtonRight text={'więcej'} onClick={handleToogle} />
                <CustomDialog
                  handleToogle={handleToogle}
                  open={open}
                  title={'Osoby kontaktowe (z poprzednich wniosków)'}
                  showActions={false}
                  component={() => DialogText}
                />
              </div>
            )}
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <SectionTitle
            label={'T_MODULE_ORGANIZATION_DATA_GOALS_INFO'}
            divider={false}
          />
          <DataInfo
            label={'T_MODULE_ORGANIZATION_DATA_ORGANIZATION_GOALS'}
            data={resource.organizationGoals}
          />
          <DataInfo
            label={'T_MODULE_ORGANIZATION_DATA_STATUTORY_ACTIVITIES'}
            data={resource.statutoryActivities}
          />
        </Grid>
      </Grid>
    </>
  )
}
