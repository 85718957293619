import React, { useEffect, useState } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { MenuItem, Select } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'

export const SelectFilter = ({ column, stateFilters, onChange }) => {
  const [collection, setCollection] = useState([])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    fetchDataHandleAuthError(column.filterSchema, 'GET', { signal }, resp => {
      setCollection(resp['hydra:member'])
    })

    return () => controller.abort()
  }, [column.filterSchema])

  return (
    <>
      <Select
        value={stateFilters[column.filterName || column.accessor].value || null}
        name={stateFilters[column.filterName || column.accessor].name}
        onChange={e =>
          onChange(
            stateFilters[column.filterName || column.accessor].name,
            e.target.value
          )
        }
      >
        <MenuItem value={null}>{translate('Wszystkie')}</MenuItem>
        {collection.map(el => (
          <MenuItem key={el.uuid} value={el.uuid}>
            {el.title}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}
