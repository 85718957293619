import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  docked: {
    position: 'sticky',
    top: 0,
    alignSelf: 'flex-start',
  },
  drawer: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: theme.elements.sideBar.background,
    zIndex: theme.zIndex.drawer + 2,
    minHeight: '100vh',
  },
  drawerSidebarClosed: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    zIndex: theme.zIndex.drawer,
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    backgroundColor: theme.elements.logo,
    ...theme.mixins.toolbar,
  },
  divider: {
    backgroundColor: theme.elements.sideBar.divider,
  },
  userBox: {
    color: theme.elements.sideBar.text,
    paddingTop: 16,
    paddingBottom: 12,
    height: 53,
  },
  userBoxSidebarClosed: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
  },
  profileLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '.1em',
    height: 53,
    color: theme.elements.sideBar.text,
    '&:hover': {
      color: theme.elements.sideBar.hover,
    },
  },
}))
