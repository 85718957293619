import MuiButton from './MuiButton'
import MuiIconButton from './MuiIconButton'
import MuiPaper from './MuiPaper'
import MuiTableCell from './MuiTableCell'
import MuiTypography from './MuiTypography'
import MuiMenu from './MuiMenu'
import MuiMenuItem from './MuiMenuItem'
import MuiStepper from './MuiStepper'
import MuiStepButton from './MuiStepButton'
import MuiCheckbox from './MuiCheckbox'
import MuiTooltip from './MuiTooltip'

export default {
  MuiButton,
  MuiIconButton,
  MuiPaper,
  MuiTableCell,
  MuiTypography,
  MuiMenu,
  MuiMenuItem,
  MuiStepper,
  MuiStepButton,
  MuiCheckbox,
  MuiTooltip,
  MuiLinearProgress: {
    root: {
      borderRadius: 4,
      height: 20,
    },
  },
}
