import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { FileType } from 'core/components/form/fields/file/FileType'
import { isContractAttachmentCollectionInvalid } from 'pages/applicant/AppliedProposals/_helpers/isContractAttachmentCollectionInvalid'
import { useContractAttachmentItemTypeStyles } from './ContractAttachmentItemType.styles'
import { translate } from 'core/_helpers/translate'

export const ContractAttachmentItemType = ({
  index,
  values,
  errors,
  renderError,
  globalRenderError,
  setState,
  contest,
  disabled
}) => {
  const setValue = useCallback(
    (name, value) => {
      setState(state => ({
        ...state,
        values: state.values.map((item, i) => i === index
          ? {
            ...item,
            [name]: value
          } : item
        ),
        renderError: state.renderError.map((item, i) => i === index
          ? {
            ...item,
            [name]: true
          } : item
        ),
        init: false,
      }))
    },
    [index, setState]
  )

  const setError = useCallback(
    (name, error) => {
      setState(state => ({
        ...state,
        errors: state.errors.map((item, i) => i === index
          ? {
            ...item,
            [name]: error
          } : item
        ),
        renderError: state.isFormSubmitted
          ? state.renderError.map(
            (item, i) => i === index
              ? {
                ...item,
                [name]: true
              } : item
            )
          : state.renderError,
        isInvalid: isContractAttachmentCollectionInvalid(
          state.errors.map((item, i) => i === index
            ? {
              ...item,
              [name]: !!error
            } : item
          )
        )
      }))
    },
    [index, setState]
  )

  const validators = useMemo(() => ({
      file: [ 'required' ],
    }),
    []
  )

  const classes = useContractAttachmentItemTypeStyles()

  return (
    <div className={classes.root}>
      <div className={classes.file_wrapper}>
        <FileType
          type="file"
          name="file"
          value={values?.file}
          initialValue={values?.file}
          error={errors?.file}
          renderError={globalRenderError && renderError?.file}
          validators={validators.file}
          setValue={setValue}
          setError={setError}
          disabled={disabled}
          endpoint="/files"
          formMethod="POST"
          label={translate('T_GENERAL_FILE_ADD')}
          formUrl={contest['@id']}
          hideHeader={true}
        />
      </div>
    </div>
  )
}

ContractAttachmentItemType.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
  contest: PropTypes.shape({
    '@id': PropTypes.string.isRequired
  }).isRequired,
  disabled: PropTypes.bool,
}
