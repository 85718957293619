import { COLORS } from '../colors'

export default {
  tooltip: {
    fontSize: 14,
    padding: '9px 18px',
    backgroundColor: COLORS.PRIMARY,
    color: COLORS.WHITE,
    boxShadow: '2px 2px 4px rgba(37, 0, 84, 0.3)',
    borderRadius: 0,
  },
  arrow: {
    color: COLORS.PRIMARY,
    fontSize: 8,
  },
}
