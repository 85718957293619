import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import schema from '_schema/proposal'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages'
import routes from './routes'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { Grid } from '@material-ui/core'
import { StyledTab, StyledTabs } from 'components/StyledTab'
import { translate } from 'core/_helpers/translate'
import { Paper } from 'core/components/Paper'
import { ContainerProposal } from 'pages/Foundation/Projects/components/ContainerProposal'
import { RateSubstantive } from './components/RateSubstantive'
import { HistoryProposal } from './components/HistoryProposal'
import { ButtonLeft } from 'core/components/buttons/button-left'
import { ButtonRight } from 'core/components/buttons/button-right'

const useStyles = makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  tab: {
    maxWidth: 450,
  },
}))

export const Proposal = ({ match }) => {
  const classes = useStyles()
  const contest = match.params.id
  const proposal = match.params.proposal
  const iri = `${schema.endpoint_foundation_item}/${proposal}`

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError
  )

  const [tab, setTab] = useState('details')

  const handleChangeTab = useCallback((event, value) => {
    setTab(value)
  }, [])

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={8}>
          <CollectionHeader
            hideButton={true}
            hideButtonBack={false}
            buttonTitleLeft={'Przypisane projekty'}
            buttonPathLeft={routes().collection_proposals.path.replace(
              ':id',
              contest
            )}
          >
            <Grid container spacing={3}>
              <Grid item xs={2} md={1}>
                {resource.prevProposal &&
                  resource.prevProposal.uuid !== undefined && (
                    <ButtonLeft
                      size={'bigger'}
                      href={`/contest/${resource.contest.uuid}/proposal/${resource?.prevProposal?.uuid}`}
                      text={''}
                    />
                  )}
              </Grid>
              <Grid item xs={8} md={10}>
                {resource.title}
              </Grid>
              <Grid item xs={2} md={1}>
                {resource.nextProposal &&
                  resource.nextProposal.uuid !== undefined && (
                    <ButtonRight
                      size={'bigger'}
                      color={'primary'}
                      href={`/contest/${resource.contest.uuid}/proposal/${resource?.nextProposal?.uuid}`}
                      text={''}
                    />
                  )}
              </Grid>
            </Grid>
          </CollectionHeader>
          <StyledTabs
            value={tab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            className={classes.tabs}
          >
            <StyledTab
              label={translate('Szczegóły wniosku')}
              value={'details'}
            />
            <StyledTab
              className={classes.tab}
              label={translate('Historia wniosków tej organizacji')}
              value={'history'}
            />
          </StyledTabs>
          <Paper withPadding={true}>
            {tab === 'details' ? (
              <ContainerProposal resource={resource} withHeader={false} />
            ) : (
              <HistoryProposal resource={resource} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper withPadding={false}>
            <RateSubstantive resource={resource} />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
