import React from 'react'
import { StringType } from 'core/components/form/fields/StringType'

export const Textarea = ({ block }) => {
  const values = block.data ? JSON.parse(block.data) : {}

  return (
    <>
      <div>
        <StringType
          name={'textarea'}
          setValue={() => false}
          disabled={false}
          renderError={false}
          error={false}
          label={values.label}
          type={'textarea'}
          setError={() => false}
          placeholder={values.input}
          validators={[values.validate, values.required ? 'required' : null]}
          maxLength={values.limit}
          endText={values.unit}
          width={'520px'}
          row={values.rows}
        />
      </div>
    </>
  )
}
