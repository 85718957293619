export const generateContractAttachmentCollectionRenderError = (contractAttachments, renderError) => {
  if (!contractAttachments) {
    return []
  }

  let currentRenderError = []

  contractAttachments.forEach((contractAttachment, index) => {
    if (!contractAttachment) {
      return
    }

    if (!currentRenderError[index]) {
      currentRenderError[index] = {}
    }

    Object.keys(contractAttachment).forEach(field => {
      currentRenderError[index][field] = renderError
    })
  })

  return currentRenderError
}
