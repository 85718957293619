import { useState, useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

const fetchResource = (endpoint, setState, field) => {
  const controller = new AbortController()
  const { signal } = controller

  fetchDataHandleAuthError(
    endpoint,
    'GET',
    { signal },
    response => {
      setState(state => ({
        ...state,
        [field]: response['hydra:member'],
      }))
    },
    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
    }
  )

  return () => controller.abort()
}

export const usePropertyResourcesFetch = properties => {
  const [state, setState] = useState(
    Object.assign({}, ...Object.keys(properties).map(name => ({ [name]: [] })))
  )

  useEffect(() => {
    const aborters = Object.keys(properties).map(name => {
      return fetchResource(properties[name], setState, name)
    })

    return () => aborters.map(aborter => aborter())
  }, [properties, setState])

  return [state, setState]
}
