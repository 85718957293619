import React, { useEffect, useState } from 'react'
import { CONTEST_PUBLICATION_STATUS_CURRENT } from '_schema/contests'
import moment from 'moment'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { Button } from '@material-ui/core'

export const ExpertShowButton = ({ resource }) => {
  const url = `/contests/setProposalsVisibleForExperts/${resource.uuid}`

  const proposalCollectingDateTo = moment(resource.proposalCollectingDateTo)
  const disabled =
    resource.publicationStatus !== CONTEST_PUBLICATION_STATUS_CURRENT ||
    moment().isBefore(proposalCollectingDateTo)

  const [state, setState] = useState(false)

  const handleChange = () => {
    setState(true)
  }

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    if (state) {
      fetchDataHandleAuthError(
        url,
        'PUT',
        { signal, body: JSON.stringify({}) },
        () => {
          notification(
            'success',
            'Wszystkie dotychczas złożone wnioski zostaną wyświetlone przydzielonym ekspertom.',
            'T_FORM_SUCCESS'
          )
          setState(false)
        }
      )
    }
    return () => controller.abort()
  }, [state, url])

  return (
    <>
      <Button
        onClick={handleChange}
        disabled={disabled}
        color={'secondary'}
        variant="contained"
      >
        Włącz wyświetlanie wniosków ekspertom
      </Button>
    </>
  )
}
