import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { prop } from 'core/_helpers/prop'
import Moment from 'react-moment'

export const InfoCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
  propertySchema = null,
}) => {
  const history = useHistory()
  const [date, setDate] = useState('')
  const [user, setUser] = useState('')

  useEffect(() => {
    const dataDate = new Date(resource[accessor])

    if (propertySchema.addInfo) {
      if (prop(resource, propertySchema.addInfo) !== null) {
        setDate(dataDate)
        const firstName = prop(
          prop(resource, propertySchema.addInfo),
          'firstName'
        )
        const lastName = prop(
          prop(resource, propertySchema.addInfo),
          'lastName'
        )
        setUser(firstName && `${firstName} ${lastName}`)
      }
    }
  }, [resource, accessor, propertySchema.addInfo])

  const onClick = () => {
    if (isRowLinkable) {
      editPath &&
        history.push(
          editPath.replace(':id', resource[process.env.REACT_APP_RESOURCE_ID])
        )
    }
  }

  return (
    <div onClick={onClick}>
      {!!date && <Moment date={date} format={'DD.MM.YYYY'} />}
      {propertySchema.addInfo && !!user && ` ${user}`}
    </div>
  )
}

InfoCell.propTypes = {
  resource: PropTypes.shape({
    [process.env.REACT_APP_RESOURCE_ID]: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
  propertySchema: PropTypes.object,
}
