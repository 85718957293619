import React, { useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Button, Checkbox } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import { prop } from 'core/_helpers/prop'

const useStyles = makeStyles(theme => ({
  switch_track: {
    backgroundColor: theme.palette.error.main,
  },
  switch_base: {
    color: theme.palette.error.main,
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  switch_primary: {
    '&.Mui-checked': {
      color: theme.palette.secondary.main,
    },
  },
  disabled: {
    color: theme.palette.disabled,
  },
  active: {
    cursor: 'pointer',
  },
  checkbox: {
    '&:not(.Mui-checked)': {
      color: theme.palette.primary.main,
    },
  },
}))

export const Status = ({
  resource,
  accessor = 'stat',
  disabled = false,
  onSuccess = null,
  translations = {
    publish: 'T_GENERAL_PUBLISH',
    unpublish: 'T_GENERAL_UNPUBLISH',
  },
  isSwitch = true,
  url = null,
}) => {
  const [status, setStatus] = useState(prop(resource, accessor))
  const classes = useStyles()
  const handleChange = () => {
    if (disabled) {
      return
    }

    setStatus(!status)
    fetchDataHandleAuthError(
      typeof url === 'function'
        ? url(resource)
        : url !== null
        ? url
        : resource['@id'],
      'PUT',
      {
        body: JSON.stringify({
          [accessor]: !status,
        }),
      },
      () => {
        notification('success', 'T_GENERAL_STATUS_CHANGED', 'T_FORM_SUCCESS')
        onSuccess && onSuccess(!status)
      },
      error => {
        setStatus(status)
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return isSwitch ? (
    <Checkbox
      name={`${resource['@id']}-status`}
      onChange={handleChange}
      disabled={disabled}
      checked={status}
      color="secondary"
      size="small"
      className={classes.checkbox}
    />
  ) : (
    // <Switch
    //   name={`${resource['@id']}-status`}
    //   onChange={handleChange}
    //   disabled={disabled}
    //   checked={status}
    //   color="primary"
    //   size="small"
    //   classes={{
    //     track: classes.switch_track,
    //     switchBase: classes.switch_base,
    //     colorPrimary: classes.switch_primary,
    //   }}
    // />
    <div className={clsx(disabled && classes.disabled)}>
      {status ? (
        <>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disableRipple
            disableFocusRipple
            onClick={handleChange}
          >
            {translate(translations.unpublish)}
          </Button>
        </>
      ) : (
        <>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disableRipple
            disableFocusRipple
            onClick={handleChange}
          >
            {translate(translations.publish)}
          </Button>
        </>
      )}
    </div>
  )
}

Status.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.string,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  translations: PropTypes.shape({
    publish: PropTypes.string.isRequired,
    unpublish: PropTypes.string.isRequired,
  }),
  isSwitch: PropTypes.bool,
  url: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
}
