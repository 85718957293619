import { useState, useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

const initialState = {
  items: [],
  totalItems: null,
  init: true,
  refresh: false,
}

export const useReportsCollectionFetch = endpoint => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      endpoint,
      'GET',
      { signal },
      response => {
        setState({
          items: response['hydra:member'],
          totalItems: response['hydra:totalItems'],
          init: false,
          refresh: false,
        })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, state.refresh])

  return [state, setState]
}
