import React, { useCallback, useEffect, useState } from 'react'
import { SectionTitle } from 'core/components/SectionTitle'
import { makeStyles } from '@material-ui/styles'
import { Button, Grid, Typography } from '@material-ui/core'
import moment from 'moment'
import { StringType } from 'core/components/form/fields/StringType'
import { translate } from 'core/_helpers/translate'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import schemaEvent from '_schema/proposalEvents'
import { notification } from '../../../../core/_helpers/notification'

const useStyle = makeStyles(theme => ({
  item: {
    marginBottom: theme.spacing(6),
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(5),
  },
}))

const ItemTranche = ({ item, index, setValue, disabled }) => {
  const classes = useStyle()
  const date = moment(item.date)
  return (
    <div className={classes.item}>
      <Grid container spacing={3} alignItems={'center'}>
        <Grid item xs={4}>
          <Typography variant={'h3'} component={'span'} color={'primary'}>
            Transza {index + 1}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant={'h3'} component={'span'} color={'primary'}>
            {date.format('YYYY-MM-DD')}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <StringType
            type={'number'}
            disabled={disabled}
            renderError={false}
            setError={() => false}
            setValue={(name, value) => {
              setValue(name, value, item.contestSubsidyTranche)
            }}
            name={'trancheAmount'}
            value={item.trancheAmount}
            label={'Wysokość transzy (zł)'}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export const SubsidyTranches = ({
  resource,
  updateResource,
  updateGlobalResource,
  event,
  eventObject,
}) => {
  const classes = useStyle()
  const [state, setState] = useState(eventObject.proposalSubsidyTranches)
  console.log(resource)
  const subsidyAmountSum = resource.budgets.reduce(
    (sum, budget) => sum + (budget.subsidyAmount || 0),
    0
  )
  const transchSum = state.reduce(
    (sum, tranche) => sum + (tranche.trancheAmount || 0),
    0
  )
  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    if (state.length === 0) {
      const iri = schemaEvent.endpointSubsidyTranches
      resource.contest.subsidyTranches.forEach((el, i) => {
        fetchDataHandleAuthError(
          iri,
          'POST',
          {
            signal,
            body: JSON.stringify({
              contestSubsidyTranche: el['@id'],
              trancheAmount: 0,
              proposalEvent: eventObject['@id'],
              proposal: resource['@id'],
            }),
          },
          e => {
            setState(prevState => [...prevState, e])
          }
        )
      })
    }
  }, [eventObject, resource, state, updateResource, event])

  const setValue = (name, value, contestSubsidyTranche) => {
    setState(prevState => {
      const index = prevState.findIndex(
        el => el.contestSubsidyTranche === contestSubsidyTranche
      )
      const array = [...prevState]
      array[index][name] = value
      return array
    })
  }

  const handleSubmit = () => {
    fetchDataHandleAuthError(
      `${schemaEvent.endpoint}/${event}`,
      'PUT',
      {
        body: JSON.stringify({
          proposalSubsidyTranches: state.map(el => ({
            '@id': el['@id'],
            '@type': el['@type'],
            trancheAmount: el.trancheAmount,
          })),
        }),
      },
      e => {
        notification('success', translate('Transze zostały ustawione'))
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return (
    <>
      <SectionTitle
        label={'Transze wypłat dotacji'}
        divider={true}
        marginTopSm={true}
      />
      <Typography variant={'h3'} color={'primary'}>
        Wnioskowana kwota: {Number(subsidyAmountSum).toFixed(2)} zł
      </Typography>
      <Typography variant={'h3'} color={'primary'}>
        Wartość transz: {Number(transchSum).toFixed(2)} zł
      </Typography>
      {state.map((el, index) => (
        <ItemTranche
          item={el}
          index={index}
          key={index}
          setValue={setValue}
          disabled={false}
        />
      ))}
      <div className={classes.button}>
        <Button
          color={'primary'}
          variant="contained"
          onClick={handleSubmit}
          disabled={subsidyAmountSum !== transchSum}
        >
          {translate('Zapisz')}
        </Button>
      </div>
    </>
  )
}
