import { notification } from 'core/_helpers/notification'

export const downloadFileFromFile = async (
  uuid,
  handleError = null
) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_ENTRYPOINT}/downloads/${uuid}`,
      {
        method: 'GET',
        credentials: 'same-origin',
      }
    )

    if (!response.ok) {
      throw new Error('Network response was not ok')
    }

    // Pobierz nazwę pliku z nagłówka
    const contentDisposition = response.headers.get('Content-Disposition')
    const fileName = contentDisposition
      ? contentDisposition.split('filename=')[1].replace(/"/g, '')
      : 'downloaded-file'

    // Odbierz dane jako blob
    const blob = await response.blob()

    // Utwórz URL do blobu
    const url = window.URL.createObjectURL(blob)

    // Utwórz element <a> do pobrania pliku
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()

    // Usuń element <a> po pobraniu
    document.body.removeChild(a)

    // Zwolnij URL dla blobu
    window.URL.revokeObjectURL(url)
  } catch (error) {
    notification('error', error.response.detail, error.response.title)
    if (handleError !== null && typeof handleError === 'function') {
      handleError()
      return error
    }
    console.error('Error downloading file:', error)
  }
}
