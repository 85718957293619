import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Edit } from '@material-ui/icons'
import { BlankForm } from 'pages/applicant/BlankForm'
import { useFillStepStyles } from './FillStep.styles'
import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
import { FieldWrapper } from 'components/applicant/form'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/proposal'
import provinceSchema from '_schema/province'
import organizationTypeSchema from '_schema/organizationType'

export const FillStep = ({
  resource,
  setResource,
  definitionSchema,
  customResourceSchema,
}) => {
  const [isDataEditMode, setIsDataEditMode] = useState(false)
  const [isAddressEditMode, setIsAddressEditMode] = useState(false)

  const handleDataEditMode = () => {
    setIsDataEditMode(true)
  }

  const handleAddressEditMode = () => {
    setIsAddressEditMode(true)
  }

  const handleSuccess = response => {
    setResource({ ...resource, ...response })
  }

  const additionalResourcesMapping = useMemo(
    () => ({
      provinces: `${provinceSchema.endpoint}?order[title]=desc&pagination=false`,
      organizationTypes: `${organizationTypeSchema.endpoint}?order[title]=desc&pagination=false`,
    }),
    []
  )

  const [additionalResources] = usePropertyResourcesFetch(
    additionalResourcesMapping
  )

  const classes = useFillStepStyles()

  return (
    <BlankForm
      url={schema.endpoint_contract_edit.replace(':id', resource.uuid)}
      method="PUT"
      resource={resource}
      definitionSchema={definitionSchema}
      customResourceSchema={customResourceSchema}
      handleSuccess={handleSuccess}
      submitButtonTitle={translate('T_GENERAL_SAVE_DATA')}
      submitButtonVariant="contained"
      classes={{
        root: classes.form,
        buttons: classes.form_buttons,
      }}
    >
      {({ state, properties, resource, setValue, setError }) => (
        <>
          <div className={classes.row}>
            <div className={classes.title}>
              {translate('T_MODULE_PROPOSALS_CONTRACT_REPRESENTATIVES')} *
            </div>
            <FieldWrapper
              name="contractRepresentatives"
              property={properties.contractRepresentatives}
              resource={resource}
              state={state}
              setValue={setValue}
              setError={setError}
            />
          </div>
          <div className={classes.row}>
            <div className={classes.title}>
              {translate('T_MODULE_PROPOSALS_CONTRACT_SUBACCOUNT')}
            </div>
            <div className={classes.label}>
              {translate('T_MODULE_PROPOSALS_CONTRACT_SUBACCOUNT_NUMBER')}
            </div>
            <FieldWrapper
              name="contractSubAccount"
              property={properties.contractSubAccount}
              resource={resource}
              state={state}
              setValue={setValue}
              setError={setError}
            />
          </div>
          <div className={classes.row}>
            <div className={classes.title}>
              {translate('T_MODULE_PROPOSALS_CONTRACT_ORGANIZATION_DATA')}
            </div>
            {isDataEditMode ? (
              <>
                <div className={classes.row_two_col}>
                  <FieldWrapper
                    label={''}
                    name="taxId"
                    property={properties.taxId}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.select_tax_id,
                    }}
                    provideAdditionalValues={['nip', 'regon', 'krs']}
                  />
                  {state.values.taxId === 'nip' && (
                    <FieldWrapper
                      label={''}
                      name="contractNip"
                      property={properties.contractNip}
                      resource={resource}
                      state={state}
                      setValue={setValue}
                      syncWithAdditional={false}
                      setError={setError}
                      classes={{
                        root: classes.input_tax_number,
                      }}
                    />
                  )}
                  {state.values.taxId === 'regon' && (
                    <FieldWrapper
                      label={''}
                      name="contractRegon"
                      property={properties.contractRegon}
                      resource={resource}
                      state={state}
                      syncWithAdditional={false}
                      setValue={setValue}
                      setError={setError}
                      classes={{
                        root: classes.input_tax_number,
                      }}
                    />
                  )}
                  {state.values.taxId === 'krs' && (
                    <FieldWrapper
                      label={''}
                      name="contractKrs"
                      property={properties.contractKrs}
                      resource={resource}
                      state={state}
                      syncWithAdditional={false}
                      setValue={setValue}
                      setError={setError}
                      classes={{
                        root: classes.input_tax_number,
                      }}
                    />
                  )}
                </div>
                <div>
                  <FieldWrapper
                    label={'T_MODULE_ORGANIZATION_DATA_ORGANIZATION_NAME'}
                    name="contractOrganizationName"
                    property={properties.contractOrganizationName}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.input,
                    }}
                  />
                </div>
                <div className={classes.row}>
                  <FieldWrapper
                    label={'T_MODULE_ORGANIZATION_DATA_ORGANIZATION_TYPE'}
                    name="contractOrganizationType"
                    property={properties.contractOrganizationType}
                    resource={resource}
                    resources={additionalResources.organizationTypes}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.select,
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                {(resource.contractNip ||
                  resource.contractRegon ||
                  resource.contractKrs) && (
                  <div className={classes.not_editable_row}>
                    <div className={classes.not_editable_label}>
                      {resource.contractNip
                        ? translate('T_MODULE_ORGANIZATION_DATA_NIP')
                        : resource.contractRegon
                        ? translate('T_MODULE_ORGANIZATION_DATA_REGON')
                        : resource.contractKrs
                        ? translate('T_MODULE_ORGANIZATION_DATA_KRS')
                        : null}
                    </div>
                    {resource.contractNip ||
                      resource.contractRegon ||
                      resource.contractKrs}
                  </div>
                )}
                <div className={classes.not_editable_row}>
                  <div className={classes.not_editable_label}>
                    {translate('T_MODULE_ORGANIZATION_DATA_ORGANIZATION_NAME')}
                  </div>
                  {resource.contractOrganizationName}
                </div>
                <div className={classes.not_editable_row}>
                  <div className={classes.not_editable_label}>
                    {translate('T_MODULE_ORGANIZATION_DATA_ORGANIZATION_TYPE')}
                  </div>
                  {
                    additionalResources.organizationTypes?.find(
                      organizationType =>
                        organizationType['@id'] ===
                        resource.contractOrganizationType
                    )?.title
                  }
                </div>
              </>
            )}
            {!isDataEditMode && (
              <div className={classes.edit_mode_button_container}>
                <div
                  className={classes.edit_mode_button}
                  onClick={handleDataEditMode}
                >
                  <Edit /> {translate('T_GENERAL_EDIT')}
                </div>
              </div>
            )}
          </div>
          <div className={classes.row}>
            <div className={classes.title}>
              {translate('T_MODULE_PROPOSALS_CONTRACT_ORGANIZATION_ADDRESS')}
            </div>
            {isAddressEditMode ? (
              <>
                <div className={classes.row_two_col}>
                  <div>
                    <FieldWrapper
                      label={'T_MODULE_ORGANIZATION_DATA_POST_OFFICE'}
                      name="contractPostOffice"
                      property={properties.contractPostOffice}
                      resource={resource}
                      state={state}
                      setValue={setValue}
                      setError={setError}
                      classes={{
                        root: classes.input,
                      }}
                    />
                  </div>
                  <div>
                    <FieldWrapper
                      label={'T_MODULE_ORGANIZATION_DATA_POST_CODE'}
                      name="contractPostCode"
                      property={properties.contractPostCode}
                      resource={resource}
                      state={state}
                      setValue={setValue}
                      setError={setError}
                      classes={{
                        root: classes.input_post_code,
                      }}
                    />
                  </div>
                </div>
                <div>
                  <FieldWrapper
                    label={'T_MODULE_ORGANIZATION_DATA_ADDRESS'}
                    name="contractAddress"
                    property={properties.contractAddress}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.input,
                    }}
                  />
                </div>
                <div>
                  <FieldWrapper
                    label={'T_MODULE_ORGANIZATION_DATA_CITY'}
                    name="contractCity"
                    property={properties.contractCity}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.input,
                    }}
                  />
                </div>
                <div>
                  <FieldWrapper
                    label={'T_MODULE_ORGANIZATION_DATA_COMMUNE'}
                    name="contractCommune"
                    property={properties.contractCommune}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.input,
                    }}
                  />
                </div>
                <div>
                  <FieldWrapper
                    label={'T_MODULE_ORGANIZATION_DATA_DISTRICT'}
                    name="contractDistrict"
                    property={properties.contractDistrict}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.input,
                    }}
                  />
                </div>
                <div>
                  <FieldWrapper
                    label={'T_MODULE_ORGANIZATION_DATA_PROVINCE'}
                    name="contractProvince"
                    property={properties.contractProvince}
                    resource={resource}
                    resources={additionalResources.provinces}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.select,
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={classes.not_editable_row_post}>
                  <div>
                    <div className={classes.not_editable_label}>
                      {translate(
                        'T_MODULE_PROPOSALS_BLOCK_ADDRESS_POST_OFFICE'
                      )}
                    </div>
                    {resource.contractPostOffice}
                  </div>
                  <div>
                    <div className={classes.not_editable_label}>
                      {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_POST_CODE')}
                    </div>
                    {resource.contractPostCode}
                  </div>
                </div>
                <div className={classes.not_editable_row}>
                  <div className={classes.not_editable_label}>
                    {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_ADDRESS')}
                  </div>
                  {resource.contractAddress}
                </div>
                <div className={classes.not_editable_row}>
                  <div className={classes.not_editable_label}>
                    {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_CITY')}
                  </div>
                  {resource.contractCity}
                </div>
                <div className={classes.not_editable_row}>
                  <div className={classes.not_editable_label}>
                    {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_COMMUNE')}
                  </div>
                  {resource.contractCommune}
                </div>
                <div className={classes.not_editable_row}>
                  <div className={classes.not_editable_label}>
                    {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_DISTRICT')}
                  </div>
                  {resource.contractDistrict}
                </div>
                <div className={classes.not_editable_row}>
                  <div className={classes.not_editable_label}>
                    {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_PROVINCE')}
                  </div>
                  {
                    additionalResources.provinces?.find(
                      province => province['@id'] === resource.contractProvince
                    )?.title
                  }
                </div>
              </>
            )}
            {!isAddressEditMode && (
              <div className={classes.edit_mode_button_container}>
                <div
                  className={classes.edit_mode_button}
                  onClick={handleAddressEditMode}
                >
                  <Edit /> {translate('T_GENERAL_EDIT')}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </BlankForm>
  )
}

FillStep.propTypes = {
  resource: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    contractRepresentatives: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        surname: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
      })
    ),
    contractNip: PropTypes.string,
    contractRegon: PropTypes.string,
    contractKrs: PropTypes.string,
    contractOrganizationName: PropTypes.string,
    contractOrganizationType: PropTypes.string,
    contractPostOffice: PropTypes.string,
    contractPostCode: PropTypes.string,
    contractAddress: PropTypes.string,
    contractCity: PropTypes.string,
    contractCommune: PropTypes.string,
    contractDistrict: PropTypes.string,
    contractProvince: PropTypes.string,
  }).isRequired,
  definitionSchema: PropTypes.object.isRequired,
  customResourceSchema: PropTypes.object.isRequired,
}
