import React from 'react'
import { useHistory } from 'react-router-dom'
import { prop } from 'core/_helpers/prop'
import { Typography } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import PropTypes from 'prop-types'

export const RoleCell = ({
  resource,
  accessor,
  isRowLinkable = false,
  editPath = null,
}) => {
  const history = useHistory()

  const onClick = () => {
    if (isRowLinkable) {
      editPath &&
        history.push(
          editPath.replace(':id', resource[process.env.REACT_APP_RESOURCE_ID])
        )
    }
  }

  const value = prop(resource, accessor)

  return (
    <div onClick={onClick}>
      {value.map((el, index) => (
        <Typography key={index} variant={'body1'} color={'primary'}>
          {translate(el)} {index + 1 < value.length && ', '}
        </Typography>
      ))}
    </div>
  )
}

RoleCell.propTypes = {
  resource: PropTypes.shape({
    [process.env.REACT_APP_RESOURCE_ID]: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]).isRequired,
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  isRowLinkable: PropTypes.bool,
  editPath: PropTypes.string,
}
