import React from 'react'
import { StringType } from 'core/components/form/fields/StringType'

export const Target = ({ user }) => {
  return (
    <>
      <div>
        <StringType
          name={'add2'}
          setValue={() => false}
          disabled={false}
          renderError={false}
          type={'textarea'}
          setError={() => false}
          width={'520px'}
          label={'Cele organizacji'}
          value={user.organizationGoals}
        />
      </div>
      <div>
        <StringType
          name={'add3'}
          setValue={() => false}
          disabled={false}
          renderError={false}
          type={'textarea'}
          setError={() => false}
          width={'520px'}
          label={'Formy działań statutowych'}
          value={user.statutoryActivities}
        />
      </div>
    </>
  )
}
