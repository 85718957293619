import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@material-ui/core'
import schema from '_schema/contestProvinceExpert'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

export const All = ({
  contestProvinceExperts,
  contest,
  expert,
  handleCheckAll,
  handleUncheckAll,
  disabled,
}) => {
  const [collect, setCollect] = useState(false)

  useEffect(() => {
    setCollect(contestProvinceExperts.length === 16)
  }, [contestProvinceExperts])

  const handleChange = () => {
    let url = `${schema.endpointMassAsign}${contest}`
    let method = 'PUT'
    let data = {}
    if (collect) {
      data = {
        expertMassUnAssign: expert,
      }
    } else {
      data = {
        expertMassAssign: expert,
      }
    }
    fetchDataHandleAuthError(
      url,
      method,
      { body: JSON.stringify(data) },
      resp => {
        if (collect) {
          handleUncheckAll()
        } else {
          handleCheckAll(resp.contestProvinceExperts)
        }
        setCollect(prevState => !prevState)
      },
      error => {
        if (error.response.detail !== undefined) {
          notification('error', error.response.detail)
        } else {
          notification(
            'error',
            'Błąd zapisu danych. Odśwież stronę i spróbuj jeszcze raz'
          )
        }
      }
    )
  }
  return (
    <>
      <Checkbox
        checked={collect}
        color="secondary"
        size="small"
        onChange={handleChange}
        disabled={disabled}
      />
    </>
  )
}

All.propTypes = {
  provinceExpert: PropTypes.object,
  contest: PropTypes.string,
  expert: PropTypes.string,
  province: PropTypes.string,
  decrement: PropTypes.func,
  increment: PropTypes.func,
}
