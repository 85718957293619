import React, { useCallback, useEffect, useState } from 'react'
import { useCollectionContainerStyles } from 'components/applicant/collection_styles/CollectionContainer.styles'
import { Paper } from 'core/components/Paper'
import { BudgetTabs } from 'pages/applicant/InProgressProposals/Budget/BudgetTabs'
import routes from '../routes'
import { Loader } from 'core/components/Loader'
import { CustomDialog } from 'core/components/Dialog'
import { Button, Grid, Typography } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { useReportsCollectionFetch } from './_helpers/useReportsCollectionFetch'
import { downloadFile } from '_helpers/downloadFile'
import moment from 'moment'
import { useProject } from '_helpers/useProject'
import { ReportBudgetSteppers } from 'pages/applicant/InProgressProposals/Budget/components/ReportBudgetSteppers'
import { CONTENT_REPORT_STATUS_NEW } from '_schema/proposal'
import {downloadFileFromFile} from "../../../../_helpers/downloadFileFromFile";
const DATE_FORMAT = 'DD.MM.YYYY HH:mm'
const DATE_FORMAT_SHORT = 'DD.MM.YYYY'

const useStyles = makeStyles(theme => ({
  btns: {
    margin: '20px auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 40,
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  reports: {
    textAlign: 'center',
    fontWeight: 600,
    margin: '20px auto',
    fontSize: 16,
    '& > div': {
      marginBottom: 10,
    },
    '& span': {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    },
  },
  headerList: {
    display: 'flex',
    minWidth: 'fit-content',
    '& > div': {
      marginRight: 20,
      width: 153,
      fontWeight: 'bold',
    },
  },
  reportItem: {
    display: 'flex',
    minWidth: 'fit-content',
    '& > div': {
      marginRight: 20,
      width: 153,
      fontWeight: '400',
    },
  },
}))

const ReportItemRow = ({ item, handleDownload, handleDownloadSigned }) => {
  const localClasses = useStyles()
  return (
    <>
      <div className={localClasses.reportItem}>
        <div>{moment(item.createdAt).format(DATE_FORMAT)}</div>
        <div>{translate(`${item.type}_REPORT`)}</div>
        <div>
          {item.status === 'NEW' && <>nowy </>}
          {item.status === 'REJECTED' && <>odrzucony </>}
          {item.status === 'ACCEPTED' && <>zaakceptowany </>}
        </div>
        <div>
          <span onClick={() => handleDownload(item.uuid)}>pobierz</span>
        </div>
        <div>
          {!!item.uploadedReportFile && (
            <span
              onClick={() =>
                handleDownloadSigned(
                  item.uploadedReportFile.url,
                  item.uploadedReportFile.uuid
                )
              }
            >
              pobierz
            </span>
          )}
        </div>
      </div>
    </>
  )
}

export const ReportBudget = ({ schema: definitions, match }) => {
  const classes = useCollectionContainerStyles()
  const localClasses = useStyles()
  const proposalUuid = match.params.id

  const project = useProject(proposalUuid)

  const [state, setState] = useState({
    PARTIAL: false,
    FINAL: false,
  })

  const [submit, setSubmit] = useState(false)

  const handleToogle = useCallback(type => {
    setState(prevState => ({ ...prevState, [type]: !prevState[type] }))
  }, [])

  const [reports, setReports] = useReportsCollectionFetch(
    '/proposal_financial_reports?proposal.uuid=' + proposalUuid
  )

  const handleRefresh = () => {
    notification(
      'error',
      'Nie znaleziono pliku raportu. Proszę ponownie wygenerowac raport.',
      'Błąd'
    )
    setReports(prevState => ({ ...prevState, refresh: true }))
  }

  const handleRefreshReports = () => {
    setType(null)
    setReports({
      ...reports,
      refresh: true,
    })
    setSubmit(true)
    notification('success', translate('Raport został zapisany'))
  }

  const handleSubmit = useCallback(
    type => {
      const iri = '/proposal_financial_reports'
      fetchDataHandleAuthError(
        iri,
        'POST',
        {
          body: JSON.stringify({
            proposal: `/proposals/${proposalUuid}`,
            type: type,
          }),
        },
        () => {
          handleToogle(type)
          setReports({
            ...reports,
            refresh: true,
          })
          setSubmit(true)
          notification('success', translate('Złożono raport'))
        },
        error => {
          notification('error', error.response.detail, error.response.title)
        }
      )
    },
    [proposalUuid, handleToogle, setReports, reports]
  )

  const handleDownload = (uuid, cotractNumber) => {
    downloadFile(
      `/proposal_financial_reports/download/${uuid}`,
      `raport-${cotractNumber || uuid}.pdf`,
      handleRefresh
    )
  }

  const handleDownloadSigned = (url, id) => {
    downloadFileFromFile(id, handleRefresh)
    // downloadFile(`/files/original/${id}`, `${id}-raport.pdf`, handleRefresh)
  }

  const [type, setType] = useState(null)

  const handleChangeType = type => {
    setType(type)
  }

  const [activeReport, setActiveReport] = useState(null)

  useEffect(() => {
    if (reports.items.length > 0) {
      const activeReport = reports.items.find(
        el => el.status === CONTENT_REPORT_STATUS_NEW
      )
      if (activeReport && !activeReport.uploadedReportFile) {
        setActiveReport(activeReport)
        setType(activeReport.type)
      }
    }
  }, [reports.items])

  return (
    <>
      <BudgetTabs
        value={routes().report.path.replace(':id', match.params.id)}
        classes={{ root: classes.tabs }}
        id={match.params.id}
      />
      <Paper classes={{ root: classes.paper_wrapper }}>
        <h3 className={localClasses.header}>Złóż raport finansowy</h3>

        <Grid
          container
          spacing={2}
          style={{ maxWidth: 1000, margin: '15px auto' }}
        >
          <Grid item xs={12} md={5}>
            <div className={localClasses.btns}>
              {project?.data?.contestPartialReportPeriods?.length > 0 && (
                <div>
                  <Typography
                    variant={'body2'}
                    color={'primary'}
                    style={{ display: 'grid', gridTemplateColumns: '50% 50%' }}
                  >
                    <span>Daty raportów częściowych:</span>
                    <span></span>
                    {project?.data?.contestPartialReportPeriods.map(
                      (el, index) => (
                        <span
                          key={index}
                          style={{ display: 'block', marginTop: 5 }}
                        >
                          {index + 1}.{' '}
                          {moment(el.dateFrom).format(DATE_FORMAT_SHORT)} -{' '}
                          {moment(el.dateTo).format(DATE_FORMAT_SHORT)}
                        </span>
                      )
                    )}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    // onClick={() => handleToogle('PARTIAL')}
                    onClick={() => handleChangeType('PARTIAL')}
                    disabled={
                      !getNextRaport(project?.data?.contestPartialReportPeriods)
                        .isActive || reports.items.length > 0
                    }
                  >
                    Składam raport finansowy częściowy
                  </Button>
                </div>
              )}
              {(project.data.currentEndingReportDateTo ||
                project.data.reportStartDate) && (
                <div>
                  <Typography variant={'body2'} color={'primary'}>
                    Możliwość złożenia raportu od{' '}
                    {moment(project.data.reportStartDate).format(
                      DATE_FORMAT_SHORT
                    )}{' '}
                    do{' '}
                    {moment(project.data.currentEndingReportDateTo).format(
                      DATE_FORMAT_SHORT
                    )}
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    // onClick={() => handleToogle('FINAL')}
                    onClick={() => handleChangeType('FINAL')}
                    disabled={
                      !moment().isBetween(
                        moment(project.data.reportStartDate),
                        moment(project.data.currentEndingReportDateTo),
                        'days',
                        '[]'
                      ) ||
                      project.data
                        .hasFinalFinancialReportWaitingForAcceptation ||
                      submit
                    }
                  >
                    Składam raport finansowy końcowy
                  </Button>
                </div>
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={7}>
            {type && (
              <ReportBudgetSteppers
                match={match}
                type={type}
                handleToogle={handleToogle}
                activeReport={activeReport}
                handleDownload={(id) => handleDownload(id, project.data.contractNumber)}
                definitions={definitions}
                handleRefresh={handleRefreshReports}
              />
            )}
          </Grid>
        </Grid>

        <CustomDialog
          handleToogle={() => handleToogle('PARTIAL')}
          open={state['PARTIAL']}
          title={'Potwierdzenie'}
          component={() => (
            <Typography variant={'body1'} color={'primary'}>
              {translate(
                'Czy potwierdzasz, że chcesz złożyć raport częściowy finansowy?'
              )}
            </Typography>
          )}
          confirmedButton={true}
          onConfirm={() => handleSubmit('PARTIAL')}
        />
        <CustomDialog
          handleToogle={() => handleToogle('FINAL')}
          open={state['FINAL']}
          title={'Potwierdzenie'}
          component={() => (
            <Typography variant={'body1'} color={'primary'}>
              {translate(
                'Czy potwierdzasz, że chcesz złożyć raport końcowy finansowy?'
              )}
            </Typography>
          )}
          confirmedButton={true}
          onConfirm={() => handleSubmit('FINAL')}
        />

        <h3 className={localClasses.header}> Złożone raporty</h3>
        <div className={localClasses.reports}>
          {reports.init && <Loader align="center" />}
          {reports.items.length > 0 && (
            <>
              <div className={localClasses.headerList}>
                <div>Data złożenia raportu</div>
                <div>Typ raportu</div>
                <div>Status</div>
                <div>Pobierz PDF raportu</div>
                <div>Pobierz podpisany raport</div>
              </div>
            </>
          )}

          {reports.items?.map(item => (
            <ReportItemRow
              key={item.uuid}
              item={item}
              handleDownload={id => handleDownload(id, project.data.contractNumber)}
              handleDownloadSigned={handleDownloadSigned}
            />
          ))}
        </div>
      </Paper>
    </>
  )
}

const getNextRaport = periods => {
  const activePeriod = periods.find(el =>
    moment().isBetween(el.dateFrom, el.dateTo, 'days', '[]')
  )
  return {
    isActive: !!activePeriod,
  }
}
