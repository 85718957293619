import schemaBlock from './formSectionBlock'

const schema = {
  endpoint: '/form_sections',
  resource: {
    definition: 'FormSection-form_section:read',
    properties: {
      names: {
        type: 'group',
        width: '884px',
        properties: {
          title: {
            type: 'string',
            validate: ['required'],
            column: 7,
            width: '600px',
            label: 'Tytuł sekcji',
          },
          titleVisibility: {
            type: 'boolean',
            column: 3,
            label: '',
            labelTooltip: 'czy widoczny tytuł?',
          },
          isCloneable: {
            type: 'boolean',
            label: 'Multi',
            column: 2,
          },
        },
      },
      clones: {
        type: 'group',
        width: '500px',
        properties: {
          cloneButtonTitle: {
            type: 'string',
            column: 6,
            width: '200px',
            label: 'Etykieta przycisku',
            condition: {
              name: 'isCloneable',
              value: true,
            },
          },
          cloneMaxCount: {
            type: 'integer',
            column: 4,
            width: '100px',
            label: 'Max',
            condition: {
              name: 'isCloneable',
              value: true,
            },
          },
        },
      },
    },
  },
  subresources: {
    formSectionBlocks: {
      endpoint: schemaBlock().endpoint,
      definition: schemaBlock().resource.definition,
    },
  },
}

export default schema
