import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Divider, Grid, Typography } from '@material-ui/core'
import { useFormTemplateSectionTypeStyles } from './FormTemplateSectionType.styles'
import { FormTemplateSectionBlockType } from './FormTemplateSectionBlockType'
import { ReactComponent as Delete } from 'theme/icons/remove.svg'
import { ButtonPlus } from 'core/components/buttons/button-plus'

export const FormTemplateSectionType = ({
  uuid,
  title,
  titleVisibility,
  isMulti,
  addButtonTitle,
  addMaxCount,
  blocksMapping,
  disabled,
  values,
  errors,
  renderError,
  globalRenderError,
  additional,
  handleAddSectionItem,
  handleDeleteSectionItem,
  setState,
  contest,
  proposal,
}) => {
  const classes = useFormTemplateSectionTypeStyles()

  useEffect(() => {
    if (values?.length) {
      return
    }

    handleAddSectionItem()
  }, [values, handleAddSectionItem])

  return (
    <div className={classes.root}>
      {titleVisibility && title && <div className={classes.title}>{title}</div>}
      <div>
        {values?.map((item, index) => (
          <Grid container key={`${uuid}_${index}`}>
            {isMulti && (
              <Grid item xs={12}>
                <div className={classes.blockMultiDiv}>
                  <Typography variant={'body2'} color={'primary'}>
                    {index + 1}
                  </Typography>
                  <Divider color={'primary'} />

                  {!!index && (
                    <Delete onClick={handleDeleteSectionItem(index)} />
                  )}
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              {blocksMapping
                ?.filter(block => block.stat)
                .map((block, i) => (
                  <div className={classes.block} key={block.uuid}>
                    <FormTemplateSectionBlockType
                      ord={block.ord}
                      uuid={block.uuid}
                      type={block.type}
                      data={block.data}
                      disabled={disabled}
                      values={values?.[index]?.[block.uuid]}
                      errors={errors?.[index]?.[block.uuid]}
                      renderError={renderError?.[index]?.[block.uuid]}
                      globalRenderError={globalRenderError}
                      additional={additional}
                      sectionUuid={uuid}
                      sectionItemIndex={index}
                      setState={setState}
                      contest={contest}
                      proposal={proposal}
                    />
                  </div>
                ))}
            </Grid>
          </Grid>
        ))}
      </div>
      {isMulti && (values?.length || null) < addMaxCount && (
        <ButtonPlus
          text={addButtonTitle}
          disabled={disabled}
          onClick={handleAddSectionItem}
        />
      )}
    </div>
  )
}

FormTemplateSectionType.propTypes = {
  uuid: PropTypes.string.isRequired,
  title: PropTypes.string,
  titleVisibility: PropTypes.bool,
  isMulti: PropTypes.bool,
  addButtonTitle: PropTypes.string,
  addMaxCount: PropTypes.number,
  blocksMapping: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      title: PropTypes.string,
      titleVisibility: PropTypes.bool,
      type: PropTypes.string.isRequired,
      data: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  values: PropTypes.array,
  errors: PropTypes.array,
  renderError: PropTypes.array,
  globalRenderError: PropTypes.bool.isRequired,
  additional: PropTypes.object.isRequired,
  handleAddSectionItem: PropTypes.func.isRequired,
  handleDeleteSectionItem: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  contest: PropTypes.shape({
    projectRealizationDateFrom: PropTypes.string,
    projectRealizationDateTo: PropTypes.string,
  }).isRequired,
  proposal: PropTypes.object.isRequired,
}
