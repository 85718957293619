import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { ButtonPlus } from '../buttons/button-plus'
import schema from '_schema/contestPageBlockFile'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { Grid, IconButton } from '@material-ui/core'
import { ReactComponent as RemoveIcon } from '../../../theme/icons/remove.svg'
import SaveIcon from '@material-ui/icons/Save'
import { FileType } from '../form/fields/file/FileType'
import { StringType } from '../form/fields/StringType'
import { createObjectFromString } from 'core/_helpers/createObjectFromString'
import _ from 'lodash'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  item: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  itemRemove: {
    '&:hover svg path': {
      fill: theme.palette.primary.main,
    },
  },
  itemSave: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  fileType: {
    paddingTop: '40px !important',
  },
  buttons: {
    paddingTop: '34px !important',
  },
}))

const ItemFile = ({
  resourceId,
  count,
  collect,
  onSuccess,
  onRemove,
  disabled,
}) => {
  const classes = useStyles()
  const [state, setState] = useState(
    collect
      ? collect
      : {
          title: '',
          fileName: '',
          fileSize: '',
          contestPageBlock: resourceId,
          file: null,
          id: null,
          uuid: '',
        }
  )

  const [error, setError] = useState({
    title: {
      error: null,
      renderError: false,
    },
    fileName: {
      error: null,
      renderError: false,
    },
    file: {
      error: null,
      renderError: false,
    },
  })

  const [logChange, setLogChange] = useState(false)

  const handleChange = (name, value) => {
    setState(state => ({
      ...state,
      [name]: value,
    }))
    setLogChange(true)
    setError({
      title: {
        error: null,
        renderError: false,
      },
      fileName: {
        error: null,
        renderError: false,
      },
      file: {
        error: null,
        renderError: false,
      },
    })
  }

  const handleError = (name, value) => {
    // setError(state => ({
    //   ...state,
    //   [name]: {
    //     error: value,
    //     renderError: true,
    //   },
    // }))
  }

  const handleRemove = () => {
    if (state.uuid) {
      const url = `${schema.endpoint}/${state.uuid}`
      fetchDataHandleAuthError(url, 'DELETE', {}, () => {
        onRemove(count, state.uuid)
        notification('warning', 'Załącznik został usunięty')
      })
    } else {
      onRemove(count, state.uuid)
    }
  }

  const handleSubmit = useCallback(() => {
    setLogChange(false)
    let url = schema.endpoint
    let method = 'POST'
    if (state.uuid) {
      url = `${schema.endpoint}/${state.uuid}`
      method = 'PUT'
    }
    if (!state.file) {
      setError({
        ...error,
        file: {
          error: 'Wgraj plik',
          renderError: true,
        },
      })
      return false
    } else {
      const data = { ...state, file: state.file['@id'] }
      fetchDataHandleAuthError(
        url,
        method,
        {
          body: JSON.stringify(data),
        },
        response => {
          notification('success', 'Załącznik został zapisany')
          onSuccess(response, count)
        },
        errorRes => {
          const errors = errorRes.response.violations.reduce(
            (processedErrors, item) => {
              const processedError = createObjectFromString(
                item.propertyPath.replace('[', '.').replace(']', ''),
                item.message
              )

              return _.merge(processedErrors, processedError)
            },
            {}
          )
          Object.keys(errors).forEach(er => {
            setError({
              ...error,
              [er]: {
                renderError: true,
                error: errors[er],
              },
            })
          })
        }
      )
    }
  }, [count, error, onSuccess, state])

  return (
    <Grid container spacing={3} className={classes.item}>
      <Grid item xs={6} md={3} className={classes.fileType}>
        <FileType
          endpoint={'/files'}
          formMethod={'POST'}
          name={'file'}
          label={'Dodaj plik'}
          setValue={handleChange}
          value={state.file}
          error={error.file.error}
          formUrl={resourceId}
          validators={['required']}
          disabled={false}
          renderError={error.file.renderError}
          setError={handleError}
          type={'file'}
          hideHeader={true}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <StringType
          name={'fileName'}
          value={state.fileName}
          disabled={disabled}
          renderError={error.fileName.renderError}
          error={error.fileName.error}
          type={'text'}
          setError={handleError}
          setValue={handleChange}
          label={'Nazwa pliku'}
          validators={['required']}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <StringType
          name={'title'}
          value={state.title}
          disabled={disabled}
          renderError={error.title.renderError}
          error={error.title.error}
          type={'text'}
          setError={handleError}
          setValue={handleChange}
          label={'Opis pliku'}
          validators={['required']}
        />
      </Grid>
      <Grid item xs={6} md={2} className={classes.buttons}>
        {!disabled && (
          <IconButton
            className={classes.itemRemove}
            aria-label={'remove'}
            onClick={handleRemove}
          >
            <RemoveIcon />
          </IconButton>
        )}
        {logChange && (
          <IconButton
            className={classes.itemSave}
            aria-label={'save'}
            color={'primary'}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </IconButton>
        )}
      </Grid>
    </Grid>
  )
}

export const FilesCollection = ({ resource, disabled }) => {
  const classes = useStyles()
  const [collection, setCollection] = useState(resource.files)
  const [newFiles, setNewFiles] = useState(false)

  const handleSuccess = (collect, index) => {
    if (index === collection.length + 1) {
      setCollection(state => [...state, collect])
      setNewFiles(false)
    } else {
      setCollection(state => {
        const array = state
        array[index] = collect
        return array
      })
    }
  }

  const handleRemove = (index, uuid) => {
    if (index === collection.length + 1) {
      setNewFiles(false)
    } else {
      const array = collection.filter(el => el.uuid !== uuid)
      setCollection([])
      setCollection(array)
    }
  }

  const handleClickAdd = () => {
    setNewFiles(true)
  }

  return (
    <div className={classes.root}>
      {collection.map((el, index) => (
        <ItemFile
          key={index}
          resourceId={resource['@id']}
          count={index + 1}
          collect={el}
          onSuccess={handleSuccess}
          onRemove={handleRemove}
          disabled={disabled}
        />
      ))}
      {newFiles ? (
        <ItemFile
          resourceId={resource['@id']}
          count={collection.length + 1}
          collect={null}
          onSuccess={handleSuccess}
          onRemove={handleRemove}
          disabled={disabled}
        />
      ) : (
        <ButtonPlus
          text={'Dodaj plik'}
          onClick={handleClickAdd}
          disabled={disabled}
        />
      )}
    </div>
  )
}
