import { makeStyles } from '@material-ui/styles'

export const useEditStyles = makeStyles(theme => ({
  title_wrapper: {
    marginTop: 60,
    marginLeft: 82,
    marginRight: 82,
    fontSize: 30,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  title_contest: {
    marginTop: 40,
    fontSize: 20,
  },
  form: {
    width: 'auto',
    marginTop: 60,
    marginLeft: 82,
    marginRight: 82,
  },
  form_buttons: {
    justifyContent: 'center'
  },
  container: {
    alignItems: 'flexStart'
  },
  info: {
    paddingTop: 80,
    paddingBottom: 60,
    paddingRight: 100,
    paddingLeft: 100,
    backgroundColor: 'white',
    position: 'sticky',
    top: 0,
    height: '100%',
    minHeight: '100vh',
    // overflow: 'auto',
  },
  upper_info_buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '&> *': {
      paddingRight: 0,
      paddingLeft: 0
    },
    marginBottom: 15
  },
  lower_info_buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 40,
    '&> *': {
      marginBottom: 15
    }
  }
}))
