import { makeStyles } from '@material-ui/styles'

export const useBudgetFormStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main
  },
  row: {
    marginBottom: 22
  }
}))
