import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Step, StepButton, Stepper } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { proposalSteps } from '_helpers/proposalSteps'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 90,
  },
  stepError: {
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight: 700,
    border: '1px solid',
    borderRadius: '50%',
    minWidth: 25,
    height: 25,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5,
    marginRight: -30,
  },
}))

export const ProposalSteps = ({
  isAttachable,
  activeStep,
  handleStepChange,
  errors,
}) => {
  const step1Errors =
    (errors?.coordinators || 0) + (errors?.applicationApplicantFormValues || 0)
  const step2Errors =
    (errors?.applicationProjectFormValues || 0) + (!!errors?.title || 0)
  const step3Errors = (errors?.budgets || 0) + (!!errors?.subsidyAmount || 0)
  const step4Errors = errors?.attachments || 0

  const classes = useStyles()

  return (
    <Stepper
      alternativeLabel
      nonLinear
      activeStep={activeStep}
      className={classes.root}
    >
      <Step key={proposalSteps.APPLICANT}>
        <StepButton
          onClick={handleStepChange(proposalSteps.APPLICANT)}
          id="step-button-applicant"
          aria-controls="step-applicant"
        >
          {translate('T_MODULE_PROPOSALS_STEP_APPLICANT')}{' '}
          {step1Errors ? (
            <span className={classes.stepError}>{step1Errors}</span>
          ) : (
            ''
          )}
        </StepButton>
      </Step>
      <Step key={proposalSteps.PROJECT}>
        <StepButton
          onClick={handleStepChange(proposalSteps.PROJECT)}
          id="step-button-project"
          aria-controls="step-project"
        >
          {translate('T_MODULE_PROPOSALS_STEP_PROJECT')}{' '}
          {step2Errors ? (
            <span className={classes.stepError}>{step2Errors}</span>
          ) : (
            ''
          )}
        </StepButton>
      </Step>
      <Step key={proposalSteps.BUDGET}>
        <StepButton
          onClick={handleStepChange(proposalSteps.BUDGET)}
          id="step-button-budget"
          aria-controls="step-budget"
        >
          {translate('T_MODULE_PROPOSALS_STEP_BUDGET')}{' '}
          {step3Errors ? (
            <span className={classes.stepError}>{step3Errors}</span>
          ) : (
            ''
          )}
        </StepButton>
      </Step>
      {isAttachable && (
        <Step key={proposalSteps.ATTACHMENTS}>
          <StepButton
            onClick={handleStepChange(proposalSteps.ATTACHMENTS)}
            id="step-button-attachments"
            aria-controls="step-attachments"
          >
            {translate('T_MODULE_PROPOSALS_STEP_ATTACHMENTS')}{' '}
            {step4Errors ? (
              <span className={classes.stepError}>{step4Errors}</span>
            ) : (
              ''
            )}
          </StepButton>
        </Step>
      )}
    </Stepper>
  )
}

ProposalSteps.propTypes = {
  isAttachable: PropTypes.bool,
  activeStep: PropTypes.number.isRequired,
  handleStepChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    coordinators: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    applicationApplicanFormValues: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]),
    applicationProjectFormValues: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.number,
    ]),
    budget: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  }),
}
