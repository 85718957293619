export const isBudgetCollectionInvalid = budgets => {
  if (!budgets) {
    return false
  }

  let isInvalid = 0

  budgets.forEach(budget => {
    if (!budget) {
      return
    }

    Object.keys(budget).forEach(field => {
      if (budget[field]) {
        isInvalid++
      }
    })
  })

  return isInvalid
}
