import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  footer: {
    width: '100%',
    background: theme.palette.white,
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderTop: `1px solid ${theme.palette.separator}`,
  },
  logos: {
    display: 'flex',
    '& img': {
      marginRight: '75px',
    },
  },
  footerInfo: {
    color: theme.palette.wariant,
    fontSize: '14px',
    maxWidth: '500px',
  },
  footerSocial: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& a': {
      display: 'flex',
      marginLeft: '15px',
      padding: '8px',
    },
  },
}))
