import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { useStyles } from '../style'
import { Typography } from '@material-ui/core'

export const Text = ({ title, description }) => {
  const classes = useStyles()

  const cleanDescription = DOMPurify.sanitize(description, {
    USE_PROFILES: { html: true },
  })

  return (
    <div className={classes.block}>
      {title && (
        <Typography
          variant={'h3'}
          color={'primary'}
          className={classes.titleSection}
        >
          {title}
        </Typography>
      )}
      {description && (
        <div
          dangerouslySetInnerHTML={{ __html: cleanDescription }}
          className={classes.description}
        ></div>
      )}
    </div>
  )
}

Text.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
