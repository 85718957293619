export const generateRelationAttachmentCollectionRenderError = (relationAttachments, renderError) => {
  if (!relationAttachments) {
    return []
  }

  let currentRenderError = []

  relationAttachments.forEach((relationAttachment, index) => {
    if (!relationAttachment) {
      return
    }

    if (!currentRenderError[index]) {
      currentRenderError[index] = {}
    }

    Object.keys(relationAttachment).forEach(field => {
      currentRenderError[index][field] = renderError
    })
  })

  return currentRenderError
}
