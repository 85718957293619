import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as Right } from 'theme/icons/right.svg'
import { translate } from 'core/_helpers/translate'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    boxShadow: 'none',
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    '& *': {
      transition: 'all 0.2s',
    },
    '& span': {
      fontFamily: ['Source Sans Pro', 'sans-serif'],
      fontWeight: '700',
      fontSize: 16,
      lineHeight: '20px',
      marginRight: 7,
      textDecoration: 'none',
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      '& span': {
        color: theme.palette.primary.main,
      },
      '& svg': {
        '& path': {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
  linkPrimary: {
    '& span': {
      color: theme.palette.primary.main,
    },
    '& svg': {
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
    '&:hover': {
      '& span': {
        color: theme.palette.secondary.main,
      },
      '& svg': {
        '& path': {
          fill: theme.palette.secondary.main,
        },
      },
    },
  },
  iconBigger: {
    '& svg': {
      height: 23,
      width: 'auto',
    },
  },
}))

export const ButtonRight = ({
  href = null,
  text,
  onClick = null,
  color,
  size = 'normal',
  target = null,
}) => {
  const classes = useStyles()
  return (
    <>
      {href ? (
        <Link
          to={href}
          target={target}
          className={clsx(
            classes.link,
            color === 'primary' && classes.linkPrimary,
            size === 'bigger' && classes.iconBigger
          )}
        >
          <span>{translate(text)}</span>
          <Right />
        </Link>
      ) : (
        <button
          className={clsx(
            classes.link,
            color === 'primary' && classes.linkPrimary,
            size === 'bigger' && classes.iconBigger
          )}
          onClick={onClick}
        >
          <span>{translate(text)}</span>
          <Right />
        </button>
      )}
    </>
  )
}

ButtonRight.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  color: PropTypes.string,
  size: PropTypes.string,
}
