import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { translate } from 'core/_helpers/translate'
import { ReactComponent as Plus } from 'theme/icons/plus.svg'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    border: 'none',
    background: 'none',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    textDecoration: 'none',
    cursor: 'pointer',
    marginBottom: theme.spacing(4),
    '&:hover, &:focus': {
      '& svg circle': {
        fill: theme.palette.primary.main,
      },
      '& span': {
        color: theme.palette.primary.main,
      },
    },
    '&[disabled]': {
      opacity: 0.5,
    },
  },
  icon: {
    marginRight: 15,
    '& circle': {
      transition: 'fill 0.2s',
    },
  },
  text: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: '0.02em',
    lineHeight: 1.75,
    fontFamily: ['Source Sans Pro', 'sans-serif'],
    transition: 'color 0.2s',
  },
}))

export const ButtonPlus = ({
  text,
  onClick,
  path,
  disabled,
  customClasses = {},
}) => {
  const classes = useStyles()
  return (
    <>
      {path ? (
        <Link
          to={path}
          className={clsx(classes.root, customClasses.buttonAddRoot)}
        >
          <Plus className={classes.icon} />
          <span className={clsx(classes.text, customClasses.buttonAddText)}>
            {translate(text)}
          </span>
        </Link>
      ) : (
        <button
          className={clsx(classes.root, customClasses.buttonAddRoot)}
          onClick={onClick}
          disabled={disabled}
          type="button"
        >
          <Plus className={classes.icon} />
          <span className={clsx(classes.text, customClasses.buttonAddText)}>
            {translate(text)}
          </span>
        </button>
      )}
    </>
  )
}

ButtonPlus.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  path: PropTypes.string,
  disabled: PropTypes.bool,
  customClasses: PropTypes.object,
}
