import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { getBlockFieldsMapping } from 'pages/applicant/Proposals/_helpers/getBlockFieldsMapping'
import { blockTypes } from 'pages/applicant/Proposals/_helpers/blockTypes'
import { translate } from 'core/_helpers/translate'
import { Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  row: {
    marginBottom: theme.spacing(3),
  },
  help: {
    marginBottom: theme.spacing(3),
    color: theme.palette.disabled,
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    '&> *:first-child': {
      marginRight: 20,
    },
  },
}))

export const ParticipantsCountBlock = ({ data, values }) => {
  const mapping = useMemo(
    () => getBlockFieldsMapping(blockTypes.PARTICIPANTS_COUNT, data),
    [data]
  )

  const classes = useStyles()

  return (
    <Grid container spacing={3} className={classes.row}>
      <Grid item md={8}>
        <Typography variant={'subtitle1'} color={'primary'}>
          {translate('T_MODULE_PROPOSALS_PARTICIPANTS_COUNT_MIN')}:{' '}
          {mapping.inputs.from ||
            translate('T_MODULE_PROPOSALS_PARTICIPANTS_COUNT_NO_LIMIT')}
          , {translate('T_MODULE_PROPOSALS_PARTICIPANTS_COUNT_MAX')}:{' '}
          {mapping.inputs.to ||
            translate('T_MODULE_PROPOSALS_PARTICIPANTS_COUNT_NO_LIMIT')}
        </Typography>
        <div className={classes.flex}>
          <div className={classes.flex}>
            <Typography variant={'h3'} color={'primary'}>
              {translate('T_MODULE_PROPOSALS_REALIZATION_DATE_TIME_FROM')}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary'}>
              {values?.from}
            </Typography>
          </div>
          <div className={classes.flex}>
            <Typography variant={'h3'} color={'primary'}>
              {translate('T_MODULE_PROPOSALS_REALIZATION_DATE_TIME_TO')}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary'}>
              {values?.to}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item md={4}>
        {mapping.inputs.help && (
          <Typography
            variant={'body1'}
            color={'primary'}
            className={classes.help}
          >
            {mapping.inputs.help}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

ParticipantsCountBlock.propTypes = {
  data: PropTypes.string,
  values: PropTypes.object,
}
