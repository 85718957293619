import React, { useState } from 'react'
import { Grid, IconButton, Typography } from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { makeStyles } from '@material-ui/styles'
import Moment from 'react-moment'
import { translate } from 'core/_helpers/translate'
import moment from 'moment/moment'

const useStyles = makeStyles(theme => ({
  table_header_line: {
    fontSize: 18,
    fontWeight: 'regular',
    color: theme.palette.primary.main,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    flexGrow: 1,
    marginRight: 30,
  },
  header_status: {
    fontWeight: 'bold',
    marginLeft: 20,
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    cursor: 'pointer',
  },
  listSchedule: {
    maxWidth: 800,
    marginTop: 30,
  },
  header: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}))

const DATE_FORMAT = 'DD.MM.YYYY'

export const DetailsHistory = ({ scheduleRequest }) => {
  const tableClasses = useStyles()
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(prevState => !prevState)
  }

  const [scheduleDate] = useState([
    ...new Set(
      [
        ...Object.keys(scheduleRequest.schedule),
        ...Object.keys(scheduleRequest.previousSchedule),
      ].sort((a, b) => {
        const dateA = moment(a)
        const dateB = moment(b)
        if (dateA.isAfter(dateB)) {
          return 1
        } else {
          return -1
        }
      })
    ),
  ])

  return (
    <>
      <div className={tableClasses.titleRow} onClick={handleOpen}>
        <h3 className={tableClasses.table_header_line}>
          <Moment date={scheduleRequest.createdAt} format={DATE_FORMAT} />
          <div className={tableClasses.header_status}>
            {translate(`T_SCHEDULE_CHANGE_STATUS_${scheduleRequest.status}`)}
          </div>
        </h3>
        <IconButton color={'primary'}>
          {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        </IconButton>
      </div>
      {scheduleRequest.message && (
        <Typography variant={'body2'} color={'primary'}>
          {scheduleRequest.message}
        </Typography>
      )}
      {open && (
        <div className={tableClasses.listSchedule}>
          <Grid container spacing={4}>
            <Grid item xs={2}>
              <Typography
                variant={'subtitle1'}
                color={'primary'}
                className={tableClasses.header}
              >
                Miesiąc
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                variant={'subtitle1'}
                color={'primary'}
                className={tableClasses.header}
              >
                Było
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                variant={'subtitle1'}
                color={'primary'}
                className={tableClasses.header}
              >
                Zgłoszenie
              </Typography>
            </Grid>
          </Grid>
          {scheduleDate.map(date => (
            <div key={date}>
              <Grid container spacing={4}>
                <Grid item xs={2}>
                  <Typography variant={'subtitle2'} color={'primary'}>
                    <Moment date={date} format={'YYYY-MM'} />
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={'body1'} color={'primary'}>
                    {scheduleRequest?.previousSchedule?.[date]}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant={'body1'} color={'primary'}>
                    {scheduleRequest?.schedule?.[date]}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
