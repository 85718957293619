import { useReducer, useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import {
  constants,
  reducer,
} from 'components/applicant/proposal_collection/_state'
import buildUrl from 'build-url'

const PER_PAGE = 21

const initialState = {
  items: [],
  totalItems: null,
  init: true,
  config: {
    page: 1,
  },
}

export const useProposalCollectionFetch = (
  endpoint,
  initFilters,
  perPage,
  extractFilters = null
) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    config: {
      ...initialState.config,
      perPage: perPage || PER_PAGE,
      filters: initFilters,
    },
  })

  useEffect(() => {
    dispatch({ type: constants.FETCH_START })

    const controller = new AbortController()
    const { signal } = controller

    const url = buildUrl(endpoint, {
      queryParams: {
        ...(extractFilters ? extractFilters(state.config.filters) : state.config.filters),
        page: state.config.page,
        perPage: state.config.perPage,
      },
    })

    fetchDataHandleAuthError(
      url,
      'GET',
      { signal },
      response => {
        dispatch({
          type: constants.FETCH_SUCCESS,
          payload: {
            items: response['hydra:member'],
            totalItems: response['hydra:totalItems'],
          },
        })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        dispatch({ type: constants.FETCH_FAILURE })
        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, extractFilters, state.config])

  return [state, dispatch]
}
