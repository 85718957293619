import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import Moment from 'react-moment'
import { CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_SUBSTANTIVE } from '_schema/contestProposalRateGuidelines'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    paddingLeft: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.separator}`,
  },
  item: {
    maxWidth: 439,
    display: 'grid',
    gridTemplateColumns: '20px auto 50px',
    marginBottom: theme.spacing(6),
    gridGap: 5,
  },
  itemCount: {
    fontWeight: 700,
  },
  itemTitle: {
    marginBottom: theme.spacing(1),
  },
}))

const RateItem = ({ count, el }) => {
  const classes = useStyles()
  return (
    <div className={classes.item}>
      <Typography
        variant={'h2'}
        color={'primary'}
        component={'span'}
        className={classes.itemCount}
      >
        {count}
      </Typography>
      <div>
        <Typography
          variant={'subtitle1'}
          color={'primary'}
          className={classes.itemTitle}
        >
          {el.title}
        </Typography>
        <Typography variant={'subtitle1'} component={'p'} color={'primary'}>
          {el.description}
        </Typography>
      </div>
      <Typography variant={'subtitle1'} color={'primary'} component={'span'}>
        {el.pointsMin}-{el.pointsMax}
      </Typography>
    </div>
  )
}

export const RateGuidelines = ({ resource }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography variant={'h2'} color={'primary'} className={classes.title}>
        {translate('Kryteria oceny')}
      </Typography>
      <Typography
        variant={'subtitle1'}
        color={'primary'}
        className={classes.subtitle}
      >
        {translate('Ostateczny termin oceny')}:{' '}
        <Moment date={resource.formalRatingDateTo} format={'DD.MM.YYYY'} />
      </Typography>
      {resource.contestProposalRateGuidelines
        .filter(
          el => el.type === CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_SUBSTANTIVE
        )
        .map((el, index) => (
          <RateItem key={index} count={index + 1} el={el} />
        ))}
    </div>
  )
}
