import React, { useState, useMemo, useCallback, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { IconButton, Tooltip, FormHelperText, Button } from '@material-ui/core'
import { PhotoSizeSelectLargeOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { UploadDialog } from './UploadDialog'
import { ThumbsDialog } from './ThumbsDialog'
import { RemoveDialog } from './RemoveDialog'
import { YoutubeThumbnail } from './YoutubeThumbnail'
import { ThumbCollection } from 'core/components/thumb/ThumbCollection'
import { translate } from 'core/_helpers/translate'
import { validate } from 'core/_helpers/validate'
import { CustomLabel } from '../fields.style'
import { ReactComponent as EditIcon } from 'theme/icons/edit.svg'
import { ReactComponent as RemoveIcon } from 'theme/icons/remove.svg'
import { formatBytes } from '_helpers/formatBytes'
import {downloadFileFromFile} from "../../../../../_helpers/downloadFileFromFile";

const useStyles = makeStyles(theme => ({
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 500,
    flexWrap: 'wrap',
  },
  editButton: {
    '&:hover': {
      '& svg path': {
        fill: theme.palette.secondary.main,
      },
    },
    '&[disabled]': {
      '& svg path': {
        fill: theme.palette.disabled,
      },
    },
  },
  editButtonInline: {
    display: 'inline-block',
    '&:hover': {
      '& svg path': {
        fill: theme.palette.secondary.main,
      },
    },
    '&[disabled]': {
      '& svg path': {
        fill: theme.palette.disabled,
      },
    },
  },
  removeIcon: {
    '&:hover': {
      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },
    '&[disabled]': {
      '& svg path': {
        fill: theme.palette.disabled,
      },
    },
  },
  label: {
    position: 'relative',
    transform: 'none',
    display: 'inline-flex',
    color: theme.palette.text.secondary,
    marginRight: 5,
    verticalAlign: 'middle',
  },
  labelButton: {
    cursor: 'pointer',
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  disabledButton: {
    color: theme.palette.disabled,
  },
  imagePreview: {
    maxWidth: 500,
    maxHeight: 300,
    marginTop: 5,
  },
  link: {
    color: theme.palette.primary.main,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    textAlign: 'left',
    textDecoration: 'none',
    wordBreak: 'break-all',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  thumbs: {
    marginTop: 15,
  },
  image: {
    marginBottom: theme.spacing(3),
  },
}))

export const FileType = ({
  name,
  type = 'file',
  label,
  hint = null,
  initialValue,
  value,
  error = false,
  renderError = false,
  endpoint,
  disabled = false,
  validators,
  setValue,
  setError,
  formUrl,
  formMethod,
  uuid,
  thumbs = false,
  accept = null,
  ytCode = null,
  endpointYoutube = null,
  nodeRef = null,
  classes = {},
  hideHeader = false,
  updateButton = false,
  customUploadSuccess = null,
}) => {
  const [state, setState] = useState({
    isUploadDialogOpen: false,
    isThumbsDialogOpen: false,
    isRemoveDialogOpen: false,
  })

  const handleToggle = type => () => {
    const fieldName = `is${type.charAt(0).toUpperCase()}${type.slice(
      1
    )}DialogOpen`

    setState(state => ({
      ...state,
      [fieldName]: !state[fieldName],
    }))
  }

  const handleChange = value => {
    setValue(name, value, true)
    validateField(value)
  }

  const setUpdatedAt = value => {
    setValue('updatedAt', value)
  }

  const uploadValidators = useMemo(
    () => validators && validators.filter(item => item !== 'required'),
    [validators]
  )

  const validateField = useCallback(
    value => {
      if (!validators || !validators.includes('required')) {
        setError(name, false)

        return
      }

      const valid = validate(['required'], value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [initialValue, validateField])

  const TooltipComponent = disabled ? 'span' : Tooltip

  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.image, classes.imageContainer)}>
      <div
        className={clsx(defaultClasses.labelContainer, classes.labelContainer)}
      >
        {hideHeader ? (
          <>
            {!value ? (
              <Button
                color="secondary"
                onClick={handleToggle('upload')}
                disabled={disabled}
                className={defaultClasses.editButton}
                variant={'outlined'}
                size={'small'}
              >
                {translate('Wybierz plik z dysku')}
              </Button>
            ) : (
              <>
                {updateButton && (
                  <IconButton
                    color="primary"
                    onClick={handleToggle('upload')}
                    disabled={disabled}
                    className={defaultClasses.editButtonInline}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </>
            )}
            {renderError && error && (
              <FormHelperText style={{ display: 'block' }} error={true}>
                {translate(error)}
              </FormHelperText>
            )}
          </>
        ) : (
          <>
            <CustomLabel shrink={false} error={false} htmlFor={'id_' + name}>
              {translate(label.text || label) +
                (validators && validators.includes('required') ? ' *' : '')}
              {renderError && error && (
                <FormHelperText error={true}>{translate(error)}</FormHelperText>
              )}
            </CustomLabel>
            <TooltipComponent
              title={translate(
                `T_GENERAL_${type.toUpperCase()}_${value ? 'CHANGE' : 'ADD'}`
              )}
            >
              <IconButton
                color="primary"
                onClick={handleToggle('upload')}
                disabled={disabled}
                className={defaultClasses.editButton}
              >
                <EditIcon />
              </IconButton>
            </TooltipComponent>
            {ytCode && endpointYoutube && (
              <YoutubeThumbnail
                name={name}
                code={ytCode}
                endpoint={endpointYoutube}
                setValue={handleChange}
                setUpdatedAt={setUpdatedAt}
                formUrl={formUrl}
                formMethod={formMethod}
                disabled={disabled}
              />
            )}
            {uuid && value && thumbs && !nodeRef && (
              <TooltipComponent title={translate('T_GENERAL_PHOTO_THUMBS')}>
                <IconButton
                  color="primary"
                  onClick={handleToggle('thumbs')}
                  disabled={disabled}
                >
                  <PhotoSizeSelectLargeOutlined
                    className={clsx(
                      defaultClasses.labelButton,
                      classes.labelButton
                    )}
                  />
                </IconButton>
              </TooltipComponent>
            )}
            {value && (!validators || !validators.includes('required')) && (
              <TooltipComponent
                title={translate(`T_GENERAL_${type.toUpperCase()}_REMOVE`)}
              >
                <IconButton
                  color="primary"
                  onClick={handleToggle('remove')}
                  disabled={disabled}
                  className={defaultClasses.removeIcon}
                >
                  <RemoveIcon />
                </IconButton>
              </TooltipComponent>
            )}
          </>
        )}
      </div>
      <UploadDialog
        name={name}
        type={type}
        hint={hint}
        endpoint={endpoint}
        disabled={disabled}
        validators={uploadValidators}
        setValue={handleChange}
        setUpdatedAt={setUpdatedAt}
        formUrl={formUrl}
        formMethod={formMethod}
        accept={accept}
        isOpen={state.isUploadDialogOpen}
        handleToggle={handleToggle('upload')}
        customUploadSuccess={customUploadSuccess}
      />
      {value &&
        (type === 'image' ? (
          <img
            src={`${process.env.REACT_APP_API_ENTRYPOINT}/${value.url}`}
            className={clsx(defaultClasses.imagePreview, classes.imagePreview)}
            alt="preview"
          />
        ) : (
          <button
            type="button"
            className={clsx(defaultClasses.link, classes.link)}
            // href={`${process.env.REACT_APP_API_ENTRYPOINT}/${value.url}`}
            // target="_blank"
            rel="noopener noreferrer"
            onClick={() => downloadFileFromFile(value.uuid)}
          >
            {value.originalName} - {formatBytes(value.fileSize)}
          </button>
        ))}
      {uuid &&
        value &&
        thumbs &&
        (nodeRef ? (
          <ThumbCollection
            endpoint={thumbs.endpoint}
            pid={uuid}
            originalPhotoUrl={value && value.url}
            disabled={disabled}
            twoColLayout={false}
            cropperNodeRef={nodeRef}
            align="left"
            classes={{ root: clsx(defaultClasses.thumbs, classes.thumbs) }}
            key={JSON.stringify(value)}
          />
        ) : (
          <ThumbsDialog
            endpoint={thumbs.endpoint}
            pid={uuid}
            originalPhotoUrl={value && value.url}
            disabled={disabled}
            isOpen={state.isThumbsDialogOpen}
            handleToggle={handleToggle('thumbs')}
          />
        ))}
      {value && (!validators || !validators.includes('required')) && (
        <RemoveDialog
          name={name}
          type={type}
          setValue={handleChange}
          isOpen={state.isRemoveDialogOpen}
          handleToggle={handleToggle('remove')}
          disabled={disabled}
        />
      )}
    </div>
  )
}

FileType.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['file', 'image']),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }),
  value: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool,
  endpoint: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  formUrl: PropTypes.string.isRequired,
  formMethod: PropTypes.string.isRequired,
  uuid: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  thumbs: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      endpoint: PropTypes.string.isRequired,
    }),
  ]),
  accept: PropTypes.string,
  ytCode: PropTypes.string,
  endpointYoutube: PropTypes.string,
  nodeRef: PropTypes.object,
  classes: PropTypes.shape({
    labelContainer: PropTypes.string,
    label: PropTypes.string,
    labelButton: PropTypes.string,
    deleteButton: PropTypes.string,
    imagePreview: PropTypes.string,
    link: PropTypes.string,
    thumbs: PropTypes.string,
  }),
  hideHeader: PropTypes.bool,
  updateButton: PropTypes.bool,
  customUploadSuccess: PropTypes.func,
}
