export const isCoordinatorCollectionInvalid = coordinators => {
  if (!coordinators) {
    return false
  }

  let isInvalid = 0

  coordinators.forEach(coordinator => {
    if (!coordinator) {
      return
    }

    Object.keys(coordinator).forEach(field => {
      if (coordinator[field]) {
        isInvalid++
      }
    })
  })

  return isInvalid
}
