import React from 'react'
import PropTypes from 'prop-types'

export const TabPanel = ({ index, value, children, ...rest }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`form-panel-${index}`}
    aria-labelledby={`form-panel-step-${index}`}
    {...rest}
  >
    {value === index && <div>{children}</div>}
  </div>
)

TabPanel.propTypes = {
  index: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]).isRequired,
  value: PropTypes.number.isRequired,
  children: PropTypes.node,
}
