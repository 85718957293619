import React, { useState } from 'react'
import moment from 'moment'
import { Link, useHistory } from 'react-router-dom'
import { Grid, Button, Typography } from '@material-ui/core'
import { ArrowBackOutlined } from '@material-ui/icons'
import { useShowStyles } from './Show.styles'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import { contestPublicationStatuses } from '_helpers/contestPublicationStatuses'
import { getBackToCollectionLink } from './_helpers/getBackToCollectionLink'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { ShowDetailContest } from 'components/showDetailContest/ShowDetailContest'
import { ContestDetails } from 'components/applicant'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/contests'
import proposalRoutes from 'pages/applicant/Proposals/routes'
import { CustomDialog } from 'core/components/Dialog'

const DATE_FORMAT = 'YYYY-MM-DD'

export const Show = ({ schema: definitions, ...rest }) => {
  const { match } = rest
  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError
  )

  let history = useHistory()

  const classes = useShowStyles()

  const [openClosedDialog, setOpenClosedDialog] = useState(false)

  const handleOpenClosedDialog = () => {
    setOpenClosedDialog(prevState => !prevState)
  }

  const handleConfirmClosedDialog = () => {
    history.push(
      proposalRoutes().edit.path.replace(':contest_id', resource.uuid)
    )
  }

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Grid container className={classes.root}>
      <Grid item xs={8} className={classes.description_col}>
        <div>
          <Link
            to={getBackToCollectionLink(resource.publicationStatus)}
            className={classes.description_back_to_list}
          >
            <ArrowBackOutlined />{' '}
            {translate(
              `T_MODULE_CONTESTS_PUBLICATION_STATUS_${resource.publicationStatus}`
            )}
          </Link>
        </div>
        <div className={classes.description}>
          <ShowDetailContest resource={resource} />
        </div>
      </Grid>
      <Grid item xs={4} className={classes.info_col}>
        <div>
          <ContestDetails resource={resource} />
          {resource.grantMaxAmount && (
            <div className={classes.info_apply_for_the_amount}>
              {`${translate(
                'T_MODULE_CONTESTS_APPLY_FOR_THE_AMOUNT_START'
              ).toUpperCase()} ${resource.grantMaxAmount} ${translate(
                'T_MODULE_CONTESTS_APPLY_FOR_THE_AMOUNT_END'
              ).toUpperCase()}`}
            </div>
          )}
          {resource.publicationStatus === contestPublicationStatuses.CURRENT &&
            resource.proposalCollectingDateTo &&
            moment(resource.proposalCollectingDateTo).format(DATE_FORMAT) >=
              moment().format(DATE_FORMAT) &&
            moment(resource.proposalCollectingDateFrom).format(DATE_FORMAT) <=
              moment().format(DATE_FORMAT) && (
              <div className={classes.info_button}>
                {resource.type === 'CLOSED' &&
                resource.closedContestInfo &&
                !resource.applicantAlreadyApplied ? (
                  <>
                    <Button
                      onClick={handleOpenClosedDialog}
                      variant="contained"
                      color="secondary"
                      disabled={
                        resource.applicantApplicationStatus === 'APPLIED'
                      }
                    >
                      <span style={{ textAlign: 'center' }}>
                        {translate('T_MODULE_CONTESTS_FILL')}
                      </span>
                    </Button>
                    <CustomDialog
                      open={openClosedDialog}
                      handleToogle={handleOpenClosedDialog}
                      title={'Konkurs zamknięty'}
                      cancelText={'Zamknij'}
                      confirmedButton={true}
                      confirmedText={'Spełniam warunki'}
                      onConfirm={handleConfirmClosedDialog}
                      component={() => (
                        <Typography variant={'subtitle1'} color={'primary'}>
                          {resource.closedContestInfo}
                        </Typography>
                      )}
                    />
                  </>
                ) : (
                  <Button
                    component={Link}
                    to={proposalRoutes().edit.path.replace(
                      ':contest_id',
                      resource.uuid
                    )}
                    variant="contained"
                    color="secondary"
                    disabled={resource.applicantApplicationStatus === 'APPLIED'}
                  >
                    <span style={{ textAlign: 'center' }}>
                      {resource.applicantApplicationStatus === 'APPLIED' &&
                        'Wniosek został już złożony'}
                      {resource.applicantApplicationStatus === 'DRAFT' &&
                        'Kontynuuj wypełnianie wniosku'}
                      {!resource.applicantAlreadyApplied &&
                        translate('T_MODULE_CONTESTS_FILL')}
                    </span>
                  </Button>
                )}
              </div>
            )}
        </div>
      </Grid>
    </Grid>
  )
}
