import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { StringType } from 'components/applicant/form/fields/StringType'
import { useDetailsItemTypeStyles } from './DetailsItemType.styles'
import { Button, Typography } from '@material-ui/core'

export const DetailsItemType = ({
  index,
  values,
  errors,
  renderError,
  globalRenderError,
  setState,
  disabled,
  resource,
  edited,
}) => {
  const setValue = useCallback(
    (name, value) => {
      setState(state => ({
        ...state,
        values: {
          ...state.values,
          [name]: value === null ? resource?.current?.[index] : value,
        },
        renderError: state.renderError.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: true,
              }
            : item
        ),
        init: false,
        edited: {
          ...state.edited,
          [name]: value !== null,
        },
      }))
    },
    [index, resource, setState]
  )
  const setError = useCallback(
    (name, error) => {
      setState(state => ({
        ...state,
        errors: state.errors.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: error,
              }
            : item
        ),
        renderError: state.isFormSubmitted
          ? state.renderError.map((item, i) =>
              i === index
                ? {
                    ...item,
                    [name]: true,
                  }
                : item
            )
          : state.renderError,
        isInvalid: null,
      }))
    },
    [index, setState]
  )

  const validators = useMemo(() => ({}), [])

  const classes = useDetailsItemTypeStyles()

  const [ifChange, setIfChange] = React.useState(false)

  const handleOnChange = e => {
    setIfChange(true)
  }

  return (
    <div className={classes.root}>
      <div className={classes.input}>{index}</div>
      <div className={classes.input}>{resource?.current?.[index]}</div>
      <div>
        {resource.status === 'AUTO_DRAFT' && values?.[index] === undefined ? (
          <Typography variant={'body2'} color={'secondary'}>
            poza zakresem czasu realizacji projektu
          </Typography>
        ) : (
          <>
            {ifChange ? (
              <StringType
                type="textarea"
                name={index}
                value={
                  edited?.[index] ||
                  disabled ||
                  resource.status === 'AUTO_DRAFT'
                    ? values?.[index]
                    : ''
                }
                //error={errors}
                renderError={globalRenderError && renderError}
                validators={validators}
                disabled={disabled}
                setValue={setValue}
                setError={setError}
                classes={{
                  root: classes.textarea,
                }}
                placeholder={values?.[index]}
              />
            ) : (
              <Button
                size={'small'}
                onClick={handleOnChange}
                variant="outlined"
                color={'secondary'}
              >
                Zmień
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  )
}

DetailsItemType.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
