import React, { useCallback, useMemo, useState } from 'react'
import schema from 'core/_schema/user'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { translate } from 'core/_helpers/translate'
import routes from './routes'
import { ButtonLeft } from 'core/components/buttons/button-left'
import { ButtonRight } from 'core/components/buttons/button-right'
import { BlankForm } from 'core/pages'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { useResourceState } from 'core/_helpers/useResourceState'
import { commonConstants } from 'core/_constants'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { Loader } from 'core/components/Loader'
import { Paper } from 'core/components/Paper'
import { Grid } from '@material-ui/core'
import { Sidebar as OriginalSidebar } from 'core/components/resource/sidebar'

const useStyles = makeStyles(theme => ({
  edit: {
    cursor: 'pointer',
    '&:hover, &:focus': {
      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  text: {
    cursor: 'pointer',
    width: '100%',
  },
  disabled: {
    '& path': {
      fill: theme.palette.disabled,
    },
  },
  confirmText: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 0,
  },
  tableHead: {
    borderBottom: `1px solid ${theme.palette.separator}`,
  },
  tableContainer: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(3),
  },
  tableHeadCell: {
    color: theme.elements.table.tHeadC,
    fontSize: 16,
    fontStyle: 'italic',
    border: 0,
    paddingBottom: theme.spacing(3),
  },
  tableTitle: {
    color: theme.elements.table.tHeadC,
    fontSize: 18,
    border: 0,
    paddingBottom: theme.spacing(3),
    fontWeight: 600,
  },
  tableCell: {
    border: 0,
    color: theme.palette.primary.main,
  },
  table: {
    marginBottom: theme.spacing(3),
  },
  prevNext: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    padding: theme.spacing(2),
    backgroundColor: 'transparent',
  },
  grid: {
    marginBottom: 70,
    padding: theme.spacing(4),
  },
  sidebar: {
    marginTop: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    position: 'sticky',
    top: theme.spacing(13),
  },
}))

export const EditAdmin = ({ schema: definitions, ...rest }) => {
  const user = useSelector(state => state.profile)
  const { match } = rest
  const classes = useStyles()

  const iri = useMemo(() => `${schema('user').endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const sidebarTitleAccessor = resources =>
    `${resources.firstName} ${resources.lastName}`

  const [state, setState] = useResourceState()
  const dispatch = useDispatch()
  const dispatchOnFetch = useCallback(
    resource =>
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      }),
    [dispatch]
  )

  const { resource, isFetching } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    false,
    null,
    setState.isNotEditable,
    dispatchOnFetch
  )

  const WrapComponent = Paper

  const collectionPath = routes().administrators.path

  const calculatedStoreCollectionId = schema('user').endpoint

  const [buttonRef, setButtonRef] = useState(null)

  const onButtonRef = element => {
    setButtonRef(element)
  }

  const handleSuccessAndStay = res => {
    setState.resource({ ...resource, ...res })
  }

  return isFetching ? (
    <WrapComponent>
      <Loader />
    </WrapComponent>
  ) : (
    <>
      {resource !== null && (
        <>
          <div className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <CollectionHeader
                  hideButton
                  hideButtonBack={false}
                  buttonTitleLeft={translate('Lista administratorów')}
                  buttonPathLeft={routes().administrators.path}
                >
                  {resource.firstName} {resource.lastName}
                </CollectionHeader>
                <div className={classes.grid}>
                  <Wrapper wrapWithPaper={false}>
                    <BlankForm
                      resource={resource}
                      iri={iri}
                      collectionPath={collectionPath}
                      editPath={null}
                      storeCollectionId={calculatedStoreCollectionId}
                      isCurrentResource={true}
                      buttonsFixed={false}
                      buttonContainerRef={buttonRef}
                      handleSuccessAndStay={handleSuccessAndStay}
                      fieldsFullWidth={true}
                      fetchCompareResource={false}
                      showSubmitButton={false}
                      submitButtonLabel={null}
                      submitAndStayButtonLabel={translate('Zapisz')}
                      customResourceSchema={
                        schema(
                          user.roles.includes('ROLE_ADMIN')
                            ? 'admin_edit'
                            : 'foundation_edit'
                        ).resource
                      }
                      definitionSchema={
                        definitions[
                          schema(
                            resource.roles.includes('ROLE_ADMIN')
                              ? 'admin_edit'
                              : 'foundation_edit'
                          ).resource.definition
                        ]
                      }
                      url={`${
                        schema(
                          resource.roles.includes('ROLE_ADMIN')
                            ? 'admin_edit'
                            : 'foundation_edit'
                        ).endpoint
                      }/${match.params.id}`}
                      method="PUT"
                      showSubmitAndStayButton={true}
                      showCancelButton={false}
                      sidebar={true}
                      key={iri}
                      width="100%"
                      {...rest}
                    />
                  </Wrapper>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <Wrapper wrapWithPaper={true} className={classes.sidebar}>
                  <OriginalSidebar
                    resource={resource}
                    titleAccessor={sidebarTitleAccessor}
                    statAccessor={'stat'}
                    collectionPath={collectionPath}
                    deleteText={''}
                    sidebarButtonInline={false}
                    preview={false}
                    prevurl={''}
                    setResource={res => setState.resource(res)}
                    statable={false}
                    deletable={!resource.isDeleted && !resource.stat}
                    onButtonSaveRef={onButtonRef}
                    sidebarStatusIsSwitch={true}
                    statusTitle={translate('Aktywny')}
                    sidebarUrl={`${schema('user').endpoint}/${match.params.id}`}
                    dispatch={dispatch}
                  />
                </Wrapper>
              </Grid>
            </Grid>
          </div>
          {user.roles.includes('ROLE_ADMIN') && (
            <div className={classes.prevNext}>
              {resource.prevUser ? (
                <ButtonLeft
                  href={routes().edit_administrator.path.replace(
                    ':id',
                    resource.prevUser.uuid
                  )}
                  text={`${resource.prevUser.firstName ||
                    resource.prevUser.email} ${resource.prevUser.lastName ||
                    ''}`}
                  color={'secondary'}
                />
              ) : (
                <span></span>
              )}
              {resource.nextUser ? (
                <ButtonRight
                  href={routes().edit_administrator.path.replace(
                    ':id',
                    resource.nextUser.uuid
                  )}
                  text={`${resource.nextUser.firstName ||
                    resource.nextUser.email} ${resource.nextUser.lastName ||
                    ''}`}
                />
              ) : (
                <span></span>
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}

const Wrapper = ({ children, wrapWithPaper, className }) =>
  wrapWithPaper ? (
    <Paper className={className} withPadding={false}>
      {children}
    </Paper>
  ) : (
    <div className={className}>{children}</div>
  )
