import React from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
} from '@material-ui/core'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { ReactComponent as CloseIcon } from '../../theme/icons/close.svg'
import { translate } from '../_helpers/translate'
import { Loader } from './Loader'

const useStyles = makeStyles(theme => ({
  delete: {
    cursor: 'pointer',
    '&:hover, &:focus': {
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  },
  text: {
    cursor: 'pointer',
    width: '100%',
  },
  disabled: {
    '& path': {
      fill: theme.palette.disabled,
    },
  },
  confirmText: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 0,
  },
}))

const styles = theme => ({
  root: {
    minHeight: 56,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    backgroundColor: 'transparent',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      '& svg path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
})

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h2" color={'primary'}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export const CustomDialog = ({
  component: Component,
  open,
  handleToogle,
  title = '',
  secondTitle = '',
  confirmedButton = false,
  onConfirm = () => false,
  onCancel = null,
  isFetching = false,
  showActions = true,
  onButtonRef = () => false,
  cancelText = 'T_GENERAL_CANCEL',
  confirmedText = 'T_GENERAL_CONFIRM',
  dividers = true,
  maxWidth = 'sm',
  children = null,
}) => {
  const classes = useStyles()

  return (
    <>
      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={maxWidth}
        classes={{
          paper: classes.paper,
        }}
      >
        {title && (
          <DialogTitle onClose={handleToogle}>
            {translate(title)}
            {secondTitle}
          </DialogTitle>
        )}
        <DialogContent dividers={dividers}>
          {isFetching && <Loader align="center" marginBottom={15} />}
          {typeof Component === 'function' ? Component() : Component}
          {children}
        </DialogContent>
        {showActions && (
          <DialogActions>
            <Button
              onClick={onCancel || handleToogle}
              disabled={isFetching}
              autoFocus
              variant="outlined"
              size="large"
              color={'secondary'}
            >
              {translate(cancelText)}
            </Button>
            <div ref={onButtonRef}></div>
            {confirmedButton && (
              <Button
                onClick={onConfirm}
                disabled={isFetching}
                variant="contained"
                color="secondary"
                size="large"
              >
                {translate(confirmedText)}
              </Button>
            )}
          </DialogActions>
        )}
      </Dialog>
    </>
  )
}

CustomDialog.propTypes = {
  title: PropTypes.string,
  secondTitle: PropTypes.string,
  component: PropTypes.elementType.isRequired,
  onConfirm: PropTypes.func,
  confirmedButton: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  handleToogle: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  showActions: PropTypes.bool,
  onButtonRef: PropTypes.func,
  cancelText: PropTypes.string,
  confirmedText: PropTypes.string,
  onCancel: PropTypes.func,
  dividers: PropTypes.bool,
  maxWidth: PropTypes.string,
  children: PropTypes.any,
}
