export const blockTypes = {
  TEXT: 'TEXT',
  INPUT: 'INPUT_TEXT',
  TEXTAREA: 'TEXTAREA',
  CHOICES_SELECT: 'CHOICES_SELECT',
  CHOICES_CHECKBOX: 'CHOICES_CHECKBOX',
  DATE_TIME: 'DATE_TIME',
  ADDRESS: 'ADDRESS',
  COMPANY_DATA: 'COMPANY_DATA',
  COMPANY_ADDRESS: 'COMPANY_ADDRESS',
  COMPANY_ADDITIONAL_INFO: 'ADDITIONAL_INFO',
  COMPANY_TARGET_INFORMATIONS: 'TARGET_INFORMATIONS',
  REALIZATION_DATE_TIME: 'REALIZATION_DATE_TIME',
  PARTICIPANTS_COUNT: 'PARTICIPANTS_COUNT',
  PROJECT_SCHEDULE: 'PROJECT_SCHEDULE',
}
