import React from 'react'
import PropTypes from 'prop-types'
import { AppBar, Toolbar } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { UserMenu } from 'core/components/menu'
import { useStyles } from './styles'
import { Logo } from '../../Logo'
import { Notifications } from '../../notifications'
import { Menu } from '../../menu'

export const TopBar = ({ loading, menu }) => {
  const classes = useStyles()

  return (
    <AppBar position="absolute" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Logo />
        {loading && <Skeleton animation="wave" className={classes.skeleton} />}
        <div className={classes.toolbar_box}>
          {/*<div className={classes.grow} />*/}
          <Menu items={menu} />
          <Notifications />
          <UserMenu />
        </div>
      </Toolbar>
    </AppBar>
  )
}

const itemShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
})

TopBar.propTypes = {
  loading: PropTypes.bool,
  menu: PropTypes.arrayOf(
    PropTypes.oneOfType([
      itemShape,
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(itemShape).isRequired,
      }),
    ])
  ).isRequired,
}
