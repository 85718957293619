export const generateBudgetCollectionRenderError = (budgets, renderError) => {
  if (!budgets) {
    return []
  }

  let currentRenderError = []

  budgets.forEach((budget, index) => {
    if (!budget) {
      return
    }

    if (!currentRenderError[index]) {
      currentRenderError[index] = {}
    }

    Object.keys(budget).forEach(field => {
      currentRenderError[index][field] = renderError
    })
  })

  return currentRenderError
}
