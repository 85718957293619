import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
import { RemoveRedEye } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'block',
    marginTop: 5,
  },
  marginTop: {
    marginTop: 20,
  },
  label: {
    color: theme.palette.primary.main,
    verticalAlign: 'middle',
    fontSize: 16,
    letterSpacing: '0.02em',
    '& .MuiCheckbox-root:not(.Mui-checked)': {
      color: theme.palette.primary.main,
    },
  },
  hint: {
    color: theme.palette.text.secondary,
  },
  compare: {
    marginTop: 5,
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
  tooltip: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  error: {
    color: theme.palette.error.main,
  },
  errorCheckbox: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.error.main,
    },
  },
}))

export const BooleanType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  disabled = false,
  validators,
  error = false,
  setValue,
  setError,
  classes = {},
  labelTooltip = '',
  top = true,
  labelNotTrans = null,
}) => {
  const handleChange = e => {
    const value = e.target.checked
    setValue(name, value)
    validateField(value)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }
      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(!!initialValue)
  }, [validateField, initialValue])

  // useEffect(() => {
  //   if (!initialValue) {
  //     setValue(name, false)
  //   }
  // }, [setValue, name, initialValue])

  const defaultClasses = useStyles()

  return (
    <FormControl
      className={clsx(
        defaultClasses.root,
        classes.root,
        top && defaultClasses.marginTop
      )}
      disabled={disabled}
    >
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            checked={value || false}
            onChange={handleChange}
            color="secondary"
            size="small"
            className={clsx(error ? defaultClasses.errorCheckbox : null)}
          />
        }
        className={defaultClasses.label}
        color="primary"
        label={
          labelTooltip ? (
            <>
              <Tooltip
                title={translate(labelTooltip)}
                className={defaultClasses.tooltip}
              >
                <RemoveRedEye />
              </Tooltip>
            </>
          ) : (
            <div className={clsx(error ? defaultClasses.error : null)}>
              {labelNotTrans ? (
                <span
                  dangerouslySetInnerHTML={{ __html: labelNotTrans }}
                ></span>
              ) : (
                ''
              )}
              {translate(label.text || label)}
            </div>
          )
        }
      />
      {compare && (
        <div
          className={clsx(
            defaultClasses.compare,
            value !== compareValue && defaultClasses.compareNeq
          )}
        >
          {translate(
            compareValue
              ? 'T_FORM_FIELD_BOOLEAN_ON'
              : 'T_FORM_FIELD_BOOLEAN_OFF'
          )}
        </div>
      )}
      <FormHelperText className={clsx(defaultClasses.hint, classes.hint)}>
        {hint}
      </FormHelperText>
    </FormControl>
  )
}

BooleanType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.bool,
  value: PropTypes.bool,
  compareValue: PropTypes.bool,
  compare: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    label: PropTypes.string,
    hint: PropTypes.string,
  }),
  labelTooltip: PropTypes.string,
  top: PropTypes.bool,
  labelNotTrans: PropTypes.string,
}
