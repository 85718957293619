import React from 'react'
import PropTypes from 'prop-types'
import { useCollectionTabsStyles } from 'components/applicant/collection_styles/CollectionTabs.styles'
import { Link } from 'react-router-dom'
import { Tabs, Tab } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import appliedProposalsRoutes from 'pages/applicant/AppliedProposals/routes'
import inProgressProposalsRoutes from 'pages/applicant/InProgressProposals/routes'
import draftProposalsRoutes from 'pages/applicant/DraftProposals/routes'
import finishedProposalsRoutes from 'pages/applicant/FinishedProposals/routes'

export const ProposalsTabs = ({ value, classes = {} }) => {
  const defaultClasses = useCollectionTabsStyles()

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        style={{ marginRight: 30 }}
        classes={{ indicator: defaultClasses.indicator }}
        TabIndicatorProps={{ children: <span /> }}
      >
        <Tab
          disableRipple
          value={inProgressProposalsRoutes().index.path}
          label={translate(inProgressProposalsRoutes().index.title)}
          to={inProgressProposalsRoutes().index.path}
          component={Link}
          classes={{ root: defaultClasses.tab }}
        />
        <Tab
          disableRipple
          value={appliedProposalsRoutes().index.path}
          label={translate(appliedProposalsRoutes().index.title)}
          to={appliedProposalsRoutes().index.path}
          component={Link}
          classes={{ root: defaultClasses.tab }}
        />
        <Tab
          disableRipple
          value={draftProposalsRoutes().index.path}
          label={translate(draftProposalsRoutes().index.title)}
          to={draftProposalsRoutes().index.path}
          component={Link}
          classes={{ root: defaultClasses.tab }}
        />
        <Tab
          disableRipple
          value={finishedProposalsRoutes().index.path}
          label={translate(finishedProposalsRoutes().index.title)}
          to={finishedProposalsRoutes().index.path}
          component={Link}
          classes={{ root: defaultClasses.tab }}
        />
      </Tabs>
    </div>
  )
}

ProposalsTabs.propTypes = {
  value: (props, propName, componentName) => {
    if (
      [
        inProgressProposalsRoutes().index.path,
        appliedProposalsRoutes().index.path,
        draftProposalsRoutes().index.path,
        finishedProposalsRoutes().index.path,
      ].includes(props[propName])
    ) {
      return
    }

    return new Error(
      'Invalid prop `' +
        propName +
        '` supplied to' +
        ' `' +
        componentName +
        '`. Validation failed.'
    )
  },
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
}
