export const isAttachmentCollectionInvalid = attachments => {
  if (!attachments) {
    return false
  }

  let isInvalid = 0

  attachments.forEach(attachment => {
    if (!attachment) {
      return
    }

    Object.keys(attachment).forEach(field => {
      if (attachment[field]) {
        isInvalid++
      }
    })
  })

  return isInvalid
}
