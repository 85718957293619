import React, { useCallback, useEffect, useState } from 'react'
import schema from 'core/_schema/user'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages'
import { CollectionHeader } from 'core/components/CollectionHeader'
import routes from './routes'
import { Delete } from 'core/components/resource/Delete'
import { makeStyles } from '@material-ui/styles'
import { Restore } from 'core/components/resource/Restore'
import { StyledTab, StyledTabs } from 'components/StyledTab'
import { translate } from 'core/_helpers/translate'
import { Paper } from 'core/components/Paper'
import { OrganizationData } from './components/OrganizationData'
import { ProjectsCollection } from './components/ProjectsCollection'
import { ButtonRight } from '../../../core/components/buttons/button-right'

const useStyles = makeStyles(theme => ({
  delete: {
    position: 'absolute',
    right: theme.spacing(3),
    top: 0,
  },
  tabs: {
    padding: theme.spacing(1.5),
  },
  data: {
    padding: theme.spacing(3),
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 20,
    marginTop: 10,
  },
}))

export const Item = ({ match }) => {
  const classess = useStyles()
  const iri = `${schema('user').endpoint}/${match.params.id}`
  const urlDelete = `${schema('user').endpoint}/applicantIsDeleted/${
    match.params.id
  }`

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError,
    true
  )

  const [stateDelete, setStateDelete] = useState(false)

  useEffect(() => {
    setStateDelete(resource?.isDeleted)
  }, [resource])

  const handleChangeDelete = useCallback(() => {
    setStateDelete(prevState => !prevState)
  }, [])

  const [tab, setTab] = useState('data')

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
  }

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <div>
      <CollectionHeader
        buttonTitleLeft={'Lista organizacji'}
        hideButtonBack={false}
        buttonPathLeft={routes().index.path}
        hideButton={false}
        buttonTitle={'Edytuj dane organizacji'}
        buttonPath={`/organizations/edit/${match.params.id}`}
        additionalButtons={
          <div className={classess.buttons}>
            <ButtonRight
              href={`/showUser/${resource.uuid}`}
              text={'Podglądnij konto organizacji'}
              target={'_blank'}
            />
            {stateDelete ? (
              <Restore
                resource={resource}
                accessor={'organizationName'}
                url={urlDelete}
                onSuccess={handleChangeDelete}
                restoreText={'Przywróć'}
              />
            ) : (
              <Delete
                resource={resource}
                accessor={'organizationName'}
                url={urlDelete}
                isIcon={false}
                onSuccess={handleChangeDelete}
                deleteText={'Przenieś do kosza'}
              />
            )}
          </div>
        }
      >
        {resource.organizationName}
      </CollectionHeader>
      <div className={classess.tabs}>
        <StyledTabs
          value={tab}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
        >
          <StyledTab label={translate('Dane ogólne')} value={'data'} />
          <StyledTab label={translate('Projekty')} value={'projects'} />
        </StyledTabs>
      </div>
      <div>
        <Paper withPadding={true}>
          {tab === 'data' ? (
            <OrganizationData resource={resource} />
          ) : (
            <ProjectsCollection resource={resource} />
          )}
        </Paper>
      </div>
    </div>
  )
}
