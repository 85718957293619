import schemaEditions from './contestEdition'
import _block from 'core/_schema/_block'
import photoSchema from 'core/_schema/photo'
import schemaForms, {
  FORM_TYPE_AGREEMENT_TEMPLATE,
  FORM_TYPE_APPLICATION_APPLICANT,
  FORM_TYPE_APPLICATION_PROJECT,
  FORM_TYPE_APPOINTMENT,
  FORM_TYPE_FINAL_REPORT,
  FORM_TYPE_PARTIAL_REPORT,
} from '_schema/form'
const CONTEST_TYPE_OPEN = 'OPEN'
const CONTEST_TYPE_CLOSED = 'CLOSED'

export const CONTEST_PUBLICATION_STATUS_DRAFT = 'DRAFT'
export const CONTEST_PUBLICATION_STATUS_ANNOUNCED = 'ANNOUNCED'
export const CONTEST_PUBLICATION_STATUS_CURRENT = 'CURRENT'
export const CONTEST_PUBLICATION_STATUS_FINISHED = 'FINISHED'

export const EXPERT_RATING_STATUS_NOT_RATED = 'NOT_RATED' // Ocena merytoryczna pojedynczego experta nie wystawiona
export const EXPERT_RATING_STATUS_NOT_CONFIRMED = 'NOT_CONFIRMED' // Ocena merytoryczna pojedynczego experta wystawiona ale nie potwierdzona
export const EXPERT_RATING_STATUS_CONFIRMED = 'CONFIRMED' // Ocena merytoryczna pojedynczego experta wystawiona

const schema = {
  endpoint: '/contests',
  endpointPublicationStatus: '/contests/publicationStatus',
  endpointDelete: '/contests/isDeleted',
  endpoint_proposal_status: '/contests/proposalStatusCounts',
  endpoint_expert_list: '/contests/expertProposalsCounts',
  endpoint_expert_confirmed_rate: '/contest/expertRatingConfirmation',
  resource: {
    definition: 'Contest-contest:read',
    properties: {
      title: {
        description: {
          text: 'Nazwa konkursu',
        },
      },
      type: {
        description: {
          text: 'Typ',
        },
      },
      publicationStatus: {
        description: {
          text: 'Status',
        },
      },
      publicationDate: {
        description: {
          text: 'Publikacja',
        },
      },
      proposalCollectingDateFrom: {
        description: {
          text: 'Nabory',
        },
      },
      projectRealizationDateFrom: {
        description: {
          text: 'Rozpoczęcie',
        },
      },
      projectRealizationDateTo: {
        description: {
          text: 'Zakończenie',
        },
      },
      proposalSubmittedCount: {
        description: {
          text: 'Wnioski złożone/rozpatrzone',
        },
        addInfo: 'proposalExaminedCount',
      },
    },
  },
  parameters: {
    editionId: {
      name: 'contestEdition.id',
    },
    isCurrent: {
      name: 'isCurrent',
    },
    isAnnounced: {
      name: 'isAnnounced',
    },
    isDeleted: {
      name: 'isDeleted',
    },
  },
  resource_new: {
    definition: 'Contest-contest:write',
    properties: {
      title: {
        type: 'string',
        validate: ['required'],
        label: 'Nazwa konkursu',
      },
      contestEdition: {
        type: 'hidden',
        defaultValue: '',
      },
    },
  },
  resource_new_step_1: {
    properties: {
      names: {
        type: 'group',
        width: '884px',
        properties: {
          contestEdition: {
            type: 'resource',
            label: 'Edycja',
            endpoint: `${schemaEditions.endpoint}?pagination=false`,
            column: 3,
            titleAccessor: 'title',
            validate: ['required'],
            width: '100%',
            disabledByStatus: true,
          },
          title: {
            type: 'string',
            validate: ['required'],
            column: 9,
            width: '650px',
            label: 'Nazwa konkursu',
            disabledByStatus: true,
          },
          contractNumberSuffix: {
            type: 'string',
            validate: [],
            column: 12,
            width: '200px',
            label: 'Skrót konkursu (używany w numerze umowy)',
            disabledByStatus: true,
          },
        },
      },
      types: {
        title: 'Rodzaj',
        type: 'sectionTitle',
        divider: false,
      },
      type: {
        label: '',
        type: 'radio',
        width: '273px',
        inline: true,
        disabledByStatus: true,
        values: [
          {
            name: 'T_type_OPEN',
            value: CONTEST_TYPE_OPEN,
          },
          {
            name: 'T_type_CLOSED',
            value: CONTEST_TYPE_CLOSED,
          },
        ],
      },
      closedContestInfo: {
        type: 'textarea',
        label:
          'komunikat dla konkursu zamkniętego (wyświetlany w popupie dla wnioskodowcy):',
        width: '650px',
        condition: {
          name: 'type',
          value: CONTEST_TYPE_CLOSED,
        },
      },
      subsideAmount: {
        type: 'group',
        width: '500px',
        properties: {
          grantMaxAmount: {
            label: 'Kwota dotacji',
            width: '150px',
            type: 'number',
            disabledByStatus: true,
          },
          ownContributionMinAmount: {
            label: 'Minimalny wkład własny %',
            type: 'integer',
            width: '300px',
            widthInput: '80px',
            disabledByStatus: true,
          },
        },
      },
      attachments: {
        title: 'Wymagane załączniki',
        type: 'sectionTitle',
        divider: false,
      },
      attachmentsRequired: {
        type: 'radio',
        label: '',
        width: '273px',
        inline: true,
        disabledByStatus: true,
        values: [
          {
            name: 'Tak',
            value: true,
          },
          {
            name: 'Nie',
            value: false,
          },
        ],
      },
      attachment: {
        type: 'subform',
        property: 'attachment',
      },
      publicationDate: {
        type: 'datetime',
        label: 'Widoczny na stronie od:',
        width: '185px',
        disabledByStatus: true,
      },
      dates: {
        title: 'Daty konkursu',
        type: 'sectionTitle',
        divider: true,
      },
      datesColumn: {
        type: 'column',
        properties: {
          implementationContest: {
            type: 'group',
            width: '100%',
            label: 'Realizacja projektu',
            disabledByStatus: true,
            properties: {
              projectRealizationDateFrom: {
                type: 'date',
                label: 'Od',
                width: '129px',
                maxDate: 'projectRealizationDateTo',
              },
              projectRealizationDateTo: {
                type: 'date',
                label: 'Do',
                width: '129px',
                minDate: 'projectRealizationDateFrom',
              },
            },
          },
          proposalCollectingDates: {
            type: 'group',
            width: '100%',
            label: 'Nabór wniosków',
            properties: {
              proposalCollectingDateFrom: {
                type: 'datetime',
                label: 'Od',
                width: '220px',
                maxDate: 'proposalCollectingDateTo',
              },
              proposalCollectingDateTo: {
                type: 'datetime',
                label: 'Do',
                width: '220px',
                minDate: 'proposalCollectingDateFrom',
              },
            },
          },
          formalRatingDates: {
            type: 'group',
            width: '100%',
            label: 'Ocena formalna',
            properties: {
              formalRatingDateFrom: {
                type: 'date',
                label: 'Od',
                width: '129px',
                minDate: 'proposalCollectingDateTo',
                maxDate: 'formalRatingDateTo',
              },
              formalRatingDateTo: {
                type: 'date',
                label: 'Do',
                width: '129px',
                minDate: 'formalRatingDateFrom',
              },
            },
          },
          minWorkingMonth: {
            type: 'group',
            width: '100%',
            label: 'Minimalna liczba miesięcy działań',
            disabledByStatus: true,
            properties: {
              minWorkingMonthNumber: {
                type: 'integer',
                label: '',
                width: '129px',
              },
            },
          },
          internalRating: {
            type: 'group',
            width: '100%',
            label: 'Ocena wewnętrzna',
            properties: {
              internalRatingDateTo: {
                type: 'date',
                label: 'Do',
                width: '129px',
              },
            },
          },
          expertRatingDates: {
            type: 'group',
            width: '100%',
            label: 'Ocena merytoryczna - ekspercka',
            properties: {
              expertRatingDateFrom: {
                type: 'date',
                label: 'Od',
                width: '129px',
                maxDate: 'expertRatingDateTo',
                minDate: 'formalRatingDateTo',
              },
              expertRatingDateTo: {
                type: 'date',
                label: 'Do',
                width: '129px',
                minDate: 'expertRatingDateFrom',
              },
            },
          },
        },
      },
      datesTraining: {
        title: 'Szkolenia',
        type: 'sectionTitle',
        divider: true,
      },
      datesTrainingSubform: {
        type: 'subform',
        property: 'datesTraining',
      },
      datesReports: {
        title: 'raport częściowy (finansowy i merytoryczny)',
        type: 'sectionTitle',
        divider: true,
      },
      datesReportsSubform: {
        type: 'subform',
        property: 'datesReports',
      },
      subsidyTranche: {
        title: 'Transze wypłat dotacji',
        type: 'sectionTitle',
        divider: true,
      },
      subsidyTrancheSubform: {
        type: 'subform',
        property: 'subsidyTranche',
      },
      contractSigningDate: {
        type: 'date',
        label: 'Data podpisania umowy',
      },
      // datesPartialReport: {
      //   title: 'deklaracje wydatków kwartalne  (finansowe)',
      //   type: 'sectionTitle',
      //   divider: true,
      // },
      // datesPartialReportSubform: {
      //   type: 'subform',
      //   property: 'datesPartialReport',
      // },
      datesEnd: {
        title: 'Sprawozdanie końcowe',
        type: 'sectionTitle',
        divider: true,
      },
      datesEndColumn: {
        type: 'column',
        properties: {
          endingReportDates: {
            type: 'group',
            width: '100%',
            label: 'Sprawozdanie końcowe',
            properties: {
              endingReportDateFrom: {
                type: 'date',
                label: 'Od',
                width: '129px',
                maxDate: 'endingReportDateTo',
              },
              endingReportDateTo: {
                type: 'date',
                label: 'Do',
                width: '129px',
                minDate: 'endingReportDateFrom',
              },
            },
          },
        },
      },
    },
  },
  resource_new_step_2: {
    properties: {
      pageTitle: {
        type: 'string',
        label: 'Tytuł strony',
        width: '650px',
      },
      pageHeader: {
        type: 'textarea',
        label: 'Treść nagłówka dla konkursu zamkniętego',
        width: '650px',
        condition: {
          name: 'type',
          value: CONTEST_TYPE_CLOSED,
        },
      },
      pageLead: {
        type: 'textarea',
        label: 'Lead',
        width: '650px',
      },
    },
  },
  resource_new_step_3: {
    properties: {
      applicationApplicantForm: {
        type: 'resource',
        label: 'Formularz zgłoszenia - wnioskodawca',
        endpoint: `${schemaForms.endpoint}?pagination=false&type=${FORM_TYPE_APPLICATION_APPLICANT}`,
        titleAccessor: 'title',
        width: '360px',
        // disabledByStatus: true,
        disabledByStatusIfNotNull: true,
      },
      applicationProjectForm: {
        type: 'resource',
        label: 'Formularz zgłoszenia - projekt',
        endpoint: `${schemaForms.endpoint}?pagination=false&type=${FORM_TYPE_APPLICATION_PROJECT}`,
        titleAccessor: 'title',
        width: '360px',
        // disabledByStatus: true,
        disabledByStatusIfNotNull: true,
      },
      // appointmentForm: {
      //   type: 'resource',
      //   label: 'Formularz wizyta',
      //   endpoint: `${schemaForms.endpoint}?pagination=false&type=${FORM_TYPE_APPOINTMENT}`,
      //   titleAccessor: 'title',
      //   width: '360px',
      //   // disabledByStatus: true,
      //   disabledByStatusIfNotNull: true,
      // },
      agreementTemplateForm: {
        type: 'resource',
        label: 'Szablon umowy',
        endpoint: `${schemaForms.endpoint}?pagination=false&type=${FORM_TYPE_AGREEMENT_TEMPLATE}`,
        titleAccessor: 'title',
        width: '360px',
        // disabledByStatus: true,
        disabledByStatusIfNotNull: true,
      },
      // relationForm: {
      //   type: 'resource',
      //   label: 'Formularz relacja',
      //   endpoint: `${schemaForms.endpoint}?pagination=false&type=${FORM_TYPE_RELATION}`,
      //   titleAccessor: 'title',
      //   width: '360px',
      // },
      partialReportForm: {
        type: 'resource',
        label: 'Formularz raportu częściowego',
        endpoint: `${schemaForms.endpoint}?pagination=false&type=${FORM_TYPE_PARTIAL_REPORT}`,
        titleAccessor: 'title',
        width: '360px',
        // disabledByStatus: true,
        disabledByStatusIfNotNull: true,
      },
      finalReportForm: {
        type: 'resource',
        label: 'Formularz raportu końcowego',
        endpoint: `${schemaForms.endpoint}?pagination=false&type=${FORM_TYPE_FINAL_REPORT}`,
        titleAccessor: 'title',
        width: '360px',
        // disabledByStatus: false,
        disabledByStatusIfNotNull: true,
      },
    },
  },
  resource_new_step_4: {
    properties: {},
  },
  resource_new_step_5: {
    properties: {
      expertShowButtonTitle: {
        title: 'Widoczny dla ekspertów?',
        type: 'sectionTitle',
        divider: true,
      },
      expertShowButton: {
        type: 'subform',
        property: 'expertShowButton',
      },
      expertProvince: {
        title: 'Przypisywanie ekspertów do konkursu',
        type: 'sectionTitle',
        divider: true,
      },
    },
  },
  resource_new_step_6: {
    properties: {
      title: {
        title: 'Budżet - informacje wstępne',
        type: 'sectionTitle',
        divider: true,
      },
      budgetDescriptionHint: {
        label: 'Opis',
        type: 'textarea',
      },
      title_hints: {
        title: 'Pomoc kontekstowa',
        type: 'sectionTitle',
        divider: true,
      },
      hints: {
        type: 'column',
        properties: {
          budgetTypeHint: {
            label: 'Rodzaj kosztów',
            properties: {
              budgetTypeHint: {
                label: 'Opis',
                type: 'textarea',
              },
            },
          },
          budgetCalculationMethodHint: {
            label: 'Sposób kalkulacji',
            properties: {
              budgetCalculationMethodHint: {
                label: 'Opis',
                type: 'textarea',
              },
            },
          },
          budgetOwnContributionHint: {
            label: 'Wkład własny oraz z innych źródeł',
            properties: {
              budgetOwnContributionHint: {
                label: 'Opis',
                type: 'textarea',
              },
            },
          },
          budgetSubsidyHint: {
            label: 'Wnioskowane od FCP',
            properties: {
              budgetSubsidyHint: {
                label: 'Opis',
                type: 'textarea',
              },
            },
          },
        },
      },
      categoriesBudget: {
        title: 'Kategorie budżetu',
        type: 'sectionTitle',
        divider: true,
      },
      categoriesBudgetSubform: {
        type: 'subform',
        property: 'categoriesBudget',
      },
    },
  },
  subresources: {
    block: {
      endpoint: '/contest_page_blocks',
      definition: 'ContestPageBlock-contest_page_block:read',
      types: _block(
        photoSchema.endpoint.single,
        '/page_block_sections',
        '/page_block_thumbs',
        '/page_block_section_thumbs'
      ).types,
    },
  },
  resource_proposal_status: {
    definition: 'Contest-contest:list_proposal_status_counts',
    properties: {
      contestEdition: {
        description: {
          text: 'Edycja',
        },
      },
      title: {
        description: {
          text: 'Konkurs',
        },
      },
      type: {
        description: {
          text: 'Typ',
        },
      },
      proposalCollectingDateFrom: {
        description: {
          text: 'Nabory',
        },
      },
      projectRealizationDateFrom: {
        description: {
          text: 'Rozpoczęcie',
        },
      },
      projectRealizationDateTo: {
        description: {
          text: 'Zakończenie',
        },
      },
      grantMaxAmount: {
        description: {
          text: 'Dotacja',
        },
      },
      proposalDraftCount: {
        description: {
          text: 'W edycji',
        },
      },
      proposalSubmittedCount: {
        description: {
          text: 'Złożone',
        },
      },
      proposalFormalCount: {
        description: {
          text: 'Formalne',
        },
      },
      proposalNotFormalCount: {
        description: {
          text: 'Nieformalne',
        },
      },
      proposalRecommendationYesCount: {
        description: {
          text: 'Rekomendowane',
        },
      },
      proposalRecommendationNoCount: {
        description: {
          text: 'Nierekomendowane',
        },
      },
      proposalReserveCount: {
        description: {
          text: 'Rezerwa',
        },
      },
      proposalSubsidizedCount: {
        description: {
          text: 'Dotowane',
        },
      },
      proposalRejectedCount: {
        description: {
          text: 'Niedotowane',
        },
      },
      proposalEventOccurencesCount: {
        description: {
          text: 'Zdarzenia',
        },
      },
    },
  },
}

export default schema
