import React, { useCallback, useState } from 'react'
import clsx from 'clsx'
import {
  TableContainer,
  Table,
  TableRow,
  TableCell,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Divider,
} from '@material-ui/core'
import { ExpandMoreOutlined, ExpandLessOutlined } from '@material-ui/icons'
import {
  ManuallyOrderableTableBody,
  ManuallyOrderableTableRow,
  ManualOrderDragHandle,
} from 'core/components/table'
import { Form } from 'core/components/form'
import { ContextualHint } from 'core/components/ContextualHint'
import { BodySkeleton, TableLoader } from 'core/components/table'
import { Status } from 'core/components/resource/Status'
import { Delete } from 'core/components/resource/Delete'
import { Add } from './Add'
import { useEmbeddedCollectionFetch } from 'core/components/embedded/_helpers/useEmbeddedCollectionFetch'
import { performOrderChange } from 'core/components/embedded/_helpers/performOrderChange'
import { applyProperties } from 'core/_helpers/applyProperties'
import { prop } from 'core/_helpers/prop'
import { useBlockCollectionStyles, blockCollectionPropTypes } from '.'
import { translate } from 'core/_helpers/translate'
import { constants, reducer } from 'core/components/embedded/_state'
import { boolean } from 'core/_helpers/boolean'
import { FilesCollection } from './FilesCollection'

export const BlockCollection = ({
  endpoint,
  pid,
  parentIri,
  definitionSchema,
  types,
  headerTitle = `${translate('T_GENERAL_BLOCK_COLLECTION_TITLE')}:`,
  expandTitle = translate('T_GENERAL_EXPAND_BLOCKS'),
  titleAccessor = null,
  statable = false,
  statAccessor = 'stat',
  contextualHint = null,
  disabled = false,
  classes = {},
  parent = 'parent',
}) => {
  const [state, dispatch] = useEmbeddedCollectionFetch(
    reducer,
    endpoint,
    pid,
    parent
  )

  const defaultClasses = useBlockCollectionStyles()

  const handleDelete = resource => {
    if (disabled) {
      return
    }

    dispatch({
      type: constants.REMOVE_ITEM,
      payload: {
        value: resource,
      },
    })
  }

  const handleOrderChange = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex || disabled) {
        return
      }

      performOrderChange(state.items, oldIndex, newIndex, dispatch)
    },
    [state.items, dispatch, disabled]
  )

  const [expanded, setExpanded] = useState({
    switch: false,
    items: {},
  })

  const handleExpand = () => {
    if (state.isFetching) {
      return
    }

    setExpanded(state => ({
      switch: !state.switch,
      items: Object.assign(
        {},
        ...Object.keys(state).map(key => ({ [key]: !state.switch }))
      ),
    }))
  }

  const handleSingleExpand = iri => () => {
    setExpanded(state => ({
      ...state,
      items: {
        ...state.items,
        [iri]:
          state.items[iri] !== undefined ? !state.items[iri] : !state.switch,
      },
    }))
  }

  const subForm = resource => ({
    files: {
      component: <FilesCollection resource={resource} disabled={disabled} />,
    },
  })

  return (
    <>
      <div className={clsx(defaultClasses.header, classes.header)}>
        <div className={defaultClasses.sectionTitle}>
          <Typography color="primary" variant="h2">
            {headerTitle}
          </Typography>
          <Divider />
        </div>
        <div
          className={clsx(defaultClasses.massExpand, classes.massExpand)}
          onClick={handleExpand}
        >
          {translate(`T_GENERAL_EXPAND_${expanded.switch ? 'ON' : 'OFF'}`)}
          {expandTitle ? ` ${expandTitle}:` : ':'}{' '}
          {expanded.switch ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        </div>
      </div>
      <TableContainer
        className={clsx(
          defaultClasses.collectionContainer,
          classes.collectionContainer
        )}
      >
        <TableLoader show={!state.init && state.isFetching} align="center" />
        <Table size="small">
          <ManuallyOrderableTableBody
            onSortEnd={handleOrderChange}
            helperClass={clsx(defaultClasses.draggedRow, classes.draggedRow)}
            useDragHandle={true}
          >
            {state.init ? (
              <BodySkeleton rows={1} columns={1} />
            ) : state.items.length > 0 ? (
              state.items.map((item, i) => {
                const type = types.find(type => type.name === item.type)

                const properties = applyProperties(
                  type.properties,
                  definitionSchema.properties,
                  'missing'
                )

                const handleSuccess = resource => {
                  dispatch({
                    type: constants.UPDATE_ITEM,
                    payload: {
                      value: resource,
                    },
                  })
                }

                return (
                  <ManuallyOrderableTableRow
                    index={i}
                    key={item[process.env.REACT_APP_RESOURCE_ID]}
                  >
                    <TableCell
                      key="element"
                      className={clsx(defaultClasses.cell, classes.cell)}
                      style={{ width: '100%' }}
                    >
                      <ExpansionPanel
                        className={defaultClasses.expansionPanel}
                        square={true}
                        expanded={
                          expanded.items[item['@id']] !== undefined
                            ? expanded.items[item['@id']]
                            : expanded.switch
                        }
                      >
                        <ExpansionPanelSummary
                          classes={{
                            content: clsx(
                              defaultClasses.expansionPanelSummary,
                              classes.expansionPanelSummary
                            ),
                          }}
                        >
                          {!disabled ? <ManualOrderDragHandle /> : null}
                          <Typography
                            onClick={handleSingleExpand(item['@id'])}
                            className={clsx(
                              defaultClasses.blockTitle,
                              classes.blockTitle
                            )}
                            color={'primary'}
                            variant={'h2'}
                          >
                            <span>{translate(type.label)}</span>
                            {titleAccessor
                              ? prop(item, titleAccessor)
                                ? `: ${prop(item, titleAccessor)}`
                                : `: ${translate('T_GENERAL_MISSING_TITLE')}`
                              : item.translations?.[
                                  process.env.REACT_APP_LOCALE
                                ]?.title
                              ? `: ${
                                  item.translations?.[
                                    process.env.REACT_APP_LOCALE
                                  ]?.title
                                }`
                              : `: ${translate('T_GENERAL_MISSING_TITLE')}`}
                          </Typography>
                          {type.contextualHint && (
                            <div
                              className={clsx(
                                defaultClasses.blockIcon,
                                classes.blockIcon
                              )}
                            >
                              <ContextualHint name={type.contextualHint} />
                            </div>
                          )}
                          {statable && (
                            <div
                              className={clsx(
                                defaultClasses.blockIcon,
                                classes.blockIcon
                              )}
                            >
                              <Status
                                resource={item}
                                accessor={statAccessor}
                                disabled={disabled || state.isFetching}
                              />
                            </div>
                          )}
                          <div
                            className={clsx(
                              defaultClasses.blockIcon,
                              classes.blockIcon
                            )}
                          >
                            <Delete
                              resource={item}
                              accessor={
                                titleAccessor ||
                                `${process.env.REACT_APP_LOCALE}.title`
                              }
                              disabled={disabled || state.isFetching}
                              isIcon={true}
                              onSuccess={handleDelete}
                            />
                          </div>
                          <div
                            onClick={handleSingleExpand(item['@id'])}
                            className={clsx(
                              defaultClasses.expandIcon,
                              classes.expandIcon
                            )}
                          >
                            {(expanded.items[item['@id']] !== undefined ? (
                              expanded.items[item['@id']]
                            ) : (
                              expanded.switch
                            )) ? (
                              <ExpandLessOutlined />
                            ) : (
                              <ExpandMoreOutlined />
                            )}
                          </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          classes={{
                            root: clsx(
                              defaultClasses.itemContainer,
                              classes.itemContainer
                            ),
                          }}
                        >
                          <Form
                            readOnly={disabled}
                            url={item['@id']}
                            method="PUT"
                            properties={properties}
                            resource={item}
                            width={800}
                            fetchCompareResource={boolean(
                              process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
                            )}
                            handleSuccess={handleSuccess}
                            showSubmitAndStayButton={false}
                            showCancelButton={false}
                            key={`${
                              item[process.env.REACT_APP_RESOURCE_ID]
                            }-${i}`}
                            subForm={subForm}
                          />
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </TableCell>
                  </ManuallyOrderableTableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={2}
                  className={clsx(
                    defaultClasses.emptyResults,
                    classes.emptyResults
                  )}
                >
                  {translate('T_GENERAL_ITEMS_EMPTY')}
                </TableCell>
              </TableRow>
            )}
          </ManuallyOrderableTableBody>
        </Table>
      </TableContainer>
      <div className={clsx(defaultClasses.addContainer, classes.addContainer)}>
        {!disabled && (
          <Add
            types={types}
            endpoint={endpoint}
            parent={parentIri}
            dispatch={dispatch}
            disabled={disabled || state.isFetching}
          />
        )}
      </div>
    </>
  )
}

BlockCollection.propTypes = blockCollectionPropTypes
