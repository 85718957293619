import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Grid, Typography } from '@material-ui/core'
import { SectionTitle } from 'core/components/SectionTitle'
import { translate } from 'core/_helpers/translate'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import Moment from 'react-moment'
import schema, {
  REPORT_END_DATE_CHANGE_STATUS_ACCEPTED,
  REPORT_END_DATE_CHANGE_STATUS_PENDING,
  REPORT_END_DATE_CHANGE_STATUS_REJECTED,
} from '_schema/proposal'
import clsx from 'clsx'
import { notification } from 'core/_helpers/notification'
import { CustomDialog } from '../../../../core/components/Dialog';
import { StringType } from '../../../../core/components/form/fields/StringType';

const useStyle = makeStyles(theme => ({
  marginBot: {
    marginBottom: theme.spacing(5),
  },
  textGreen: {
    color: theme.palette.success.main,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
  buttonRed: {
    borderColor: theme.palette.error.main,
    '& .MuiButton-label': {
      color: theme.palette.error.main,
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 24px rgba(11, 43, 124, 0.4)',
      '& .MuiButton-label': {
        color: theme.palette.primary.main,
      },
    },
  },
}))

export const ReportEndDateShift = ({ eventObject, updateResource }) => {
  const classes = useStyle()

  const [realizationDateChange, setRealizationDateChange] = useState({})
  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    const iri = eventObject.endDateRequests[0]?.['@id']
    fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
      setRealizationDateChange(resp)
    })
    return () => controller.abort()
  }, [eventObject.endDateRequests])

  const [question, setQuestion] = useState('')
  const handleChangeQuestion = useCallback(
    (name, value) => setQuestion(value),
    []
  )

  const [state, setState] = useState(false)
  const handleChagneState = state => {
    setState(state)
  }


  const handleRejectShift = useCallback(() => {
    const iri = `${schema.endpoint_end_date_requests}/${realizationDateChange.uuid}/reject`
    fetchDataHandleAuthError(
      iri,
      'PATCH',
      { body: JSON.stringify({ message: question }) },
      resp => {
        setRealizationDateChange(resp)
        notification(
          'warning',
          translate(
            'Wniosek o zmianę daty złożenia raportu końcowego został odrzucony'
          )
        )
        updateResource()
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [realizationDateChange.uuid, updateResource, question])

  const handleAcceptShift = useCallback(() => {
    const iri = `${schema.endpoint_end_date_requests}/${realizationDateChange.uuid}/accept`
    fetchDataHandleAuthError(
      iri,
      'PATCH',
      { body: JSON.stringify({ message: question }) },
      resp => {
        setRealizationDateChange(resp)
        notification(
          'success',
          translate(
            'Wniosek o zmianę daty złożenia raportu końcowego został zaakceptowany'
          )
        )
        updateResource()
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [realizationDateChange.uuid, updateResource, question])

  const handleQuestionConfirmed = useCallback(() => {
    switch (state) {
      case 'accept':
        handleAcceptShift()
        break
      case 'reject':
        handleRejectShift()
        break
      default:
        handleChagneState(false)
        break
    }
  }, [state, handleAcceptShift, handleRejectShift])

  return (
    <>
      {Object.keys(realizationDateChange).length > 0 && (
        <>
          <Grid container spacing={3} className={classes.marginBot}>
            <Grid item xs={12} md={2}>
              <Typography variant={'subtitle1'} color={'primary'}>
                <Moment
                  date={realizationDateChange.createdAt}
                  format={'DD.MM.YYYY'}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography variant={'h3'} color={'secondary'}>
                {translate('Zgłoszenie')}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <SectionTitle label={'Z'} marginTopSm={true} />
                  <Typography
                    variant={'subtitle1'}
                    color={'primary'}
                    className={classes.marginBot}
                  >
                    <Moment
                      date={realizationDateChange.previousDateEnd}
                      format={'DD MMMM YYYY'}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <SectionTitle label={'Na'} marginTopSm={true} />
                  <Typography
                    variant={'subtitle1'}
                    color={'primary'}
                    className={classes.marginBot}
                  >
                    <Moment
                      date={realizationDateChange.dateEnd}
                      format={'DD MMMM YYYY'}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <SectionTitle label={'Uzasadnienie zmiany'} />
              <Typography
                variant={'body1'}
                color={'primary'}
                className={classes.marginBot}
              >
                {realizationDateChange.description}
              </Typography>
            </Grid>
          </Grid>
        </>
      )}
      {realizationDateChange.status ===
        REPORT_END_DATE_CHANGE_STATUS_PENDING && (
        <div className={classes.buttons}>
          <Button
            variant="outlined"
            className={classes.buttonRed}
            onClick={() => handleChagneState('reject')}
          >
            <span>{translate('Odrzuć')}</span>
          </Button>
          <Button
            color={'secondary'}
            variant="contained"
            onClick={() => handleChagneState('accept')}
          >
            <span>{translate('Akceptuj')}</span>
          </Button>
          <CustomDialog
            handleToogle={() => handleChagneState(null)}
            open={!!state}
            title={'Informacja dla Wnioskodawcy'}
            confirmedButton={true}
            onConfirm={handleQuestionConfirmed}
            component={() => (
              <StringType
                name={'question'}
                setValue={handleChangeQuestion}
                value={question}
                disabled={false}
                renderError={false}
                type={'textarea'}
                setError={() => {}}
                label={''}
                width={'100%'}
              />
            )}
          />
        </div>
      )}
      {realizationDateChange.status !==
        REPORT_END_DATE_CHANGE_STATUS_PENDING && (
        <Grid container spacing={3} className={classes.marginBot}>
          <Grid item xs={12} md={2}>
            <Typography variant={'subtitle1'} color={'primary'}>
              <Moment
                date={realizationDateChange.updatedAt}
                format={'DD.MM.YYYY'}
              />
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              variant={'h3'}
              color={
                realizationDateChange.status ===
                REPORT_END_DATE_CHANGE_STATUS_REJECTED
                  ? 'error'
                  : 'primary'
              }
              className={clsx(
                classes.marginBot,
                realizationDateChange.status ===
                  REPORT_END_DATE_CHANGE_STATUS_ACCEPTED && classes.textGreen
              )}
            >
              {translate(
                `T_REPORT_END_CHANGE_STATUS_${realizationDateChange.status}`
              )}
            </Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography
              variant={'subtitle1'}
              color={'primary'}
              style={{ lineHeight: '28px' }}
            >
              {realizationDateChange?.userAcceptedRejected?.replace('|', ' ')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={10}>
            <Typography
              variant={'body1'}
              color={'primary'}
              className={classes.marginBot}
            >
              {realizationDateChange.message}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}
