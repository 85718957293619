import {
  text,
  textWithPhoto,
  // textWithQuotation,
  textWithLink,
  textWithFiles,
  // textWithSections,
  // packageFile,
  // packageGallery,
  // packageFaq,
  // packageForm,
} from 'core/_schema/_blocks'

const schema = (
  photoEndpoint,
  sectionEndpoint,
  blockThumbEndpoint,
  sectionThumbEndpoint
) => ({
  types: [
    text(),
    textWithPhoto(photoEndpoint, blockThumbEndpoint),
    // textWithQuotation(),
    textWithLink(),
    textWithFiles(),
    // textWithSections(photoEndpoint, sectionEndpoint, sectionThumbEndpoint),
    // packageFile(),
    // packageGallery(),
    // packageFaq(),
    // packageForm(),
  ],
})

export default schema
