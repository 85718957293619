import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { StringType } from 'components/applicant/form/fields/StringType'
import { FileType } from 'core/components/form/fields/file/FileType'
import { isRelationAttachmentCollectionInvalid } from 'pages/applicant/InProgressProposals/Relations/_helpers/isRelationAttachmentCollectionInvalid'
import { useRelationAttachmentItemTypeStyles } from './RelationAttachmentItemType.styles'
import { translate } from 'core/_helpers/translate'

export const RelationAttachmentItemType = ({
  index,
  values,
  errors,
  renderError,
  globalRenderError,
  setState,
  relation,
  disabled
}) => {
  const setValue = useCallback(
    (name, value) => {
      setState(state => ({
        ...state,
        values: state.values.map((item, i) => i === index
          ? {
            ...item,
            [name]: value
          } : item
        ),
        renderError: state.renderError.map((item, i) => i === index
          ? {
            ...item,
            [name]: true
          } : item
        ),
        init: false,
      }))
    },
    [index, setState]
  )

  const setError = useCallback(
    (name, error) => {
      setState(state => ({
        ...state,
        errors: state.errors.map((item, i) => i === index
          ? {
            ...item,
            [name]: error
          } : item
        ),
        renderError: state.isFormSubmitted
          ? state.renderError.map(
            (item, i) => i === index
              ? {
                ...item,
                [name]: true
              } : item
            )
          : state.renderError,
        isInvalid: isRelationAttachmentCollectionInvalid(
          state.errors.map((item, i) => i === index
            ? {
              ...item,
              [name]: !!error
            } : item
          )
        )
      }))
    },
    [index, setState]
  )

  const validators = useMemo(() => ({
      file: [ 'required' ],
      title: [],
      author: []
    }),
    []
  )

  const classes = useRelationAttachmentItemTypeStyles()

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.label}>
          {translate('T_MODULE_PROPOSALS_RELATION_ATTACHMENT_TITLE')}
        </div>
        <StringType
          type="string"
          name="title"
          value={values?.title}
          error={errors?.title}
          renderError={globalRenderError && renderError?.title}
          validators={validators.title}
          disabled={disabled}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input
          }}
        />
      </div>
      <div>
        <div className={classes.label}>
          {translate('T_MODULE_PROPOSALS_RELATION_ATTACHMENT_AUTHOR')}
        </div>
        <StringType
          type="string"
          name="author"
          value={values?.author}
          error={errors?.author}
          renderError={globalRenderError && renderError?.author}
          validators={validators.author}
          disabled={disabled}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input
          }}
        />
      </div>
      <div className={classes.file_wrapper}>
        <FileType
          type="image"
          name="file"
          value={values?.file}
          initialValue={values?.file}
          error={errors?.file}
          renderError={globalRenderError && renderError?.file}
          validators={validators.file}
          setValue={setValue}
          setError={setError}
          disabled={disabled}
          endpoint="/files"
          formMethod="POST"
          label={translate('T_GENERAL_FILE_ADD')}
          formUrl={relation['@id']}
          hideHeader={true}
        />
      </div>
    </div>
  )
}

RelationAttachmentItemType.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
  relation: PropTypes.shape({
    '@id': PropTypes.string
  }),
  disabled: PropTypes.bool,
}
