import { COLORS } from '../colors'

export default {
  root: {
    background: 'transparent',
    '& .MuiStepIcon-root': {
      color: COLORS.PRIMARY,
      width: 36,
      height: 36,
      '&.MuiStepIcon-active': {
        color: COLORS.SECONDARY,
      },
    },
    '& .MuiStepLabel-label': {
      color: COLORS.PRIMARY,
      fontFamily: ['Source Sans Pro', 'sans-serif'],
      fontWeight: 600,
      fontSize: 18,
      lineHeight: 1.44,
      letterSpacing: '0.02em',
      '&.MuiStepLabel-active': {
        color: COLORS.SECONDARY,
      },
    },
  },
}
