import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useBudgetFormStyles } from './BudgetForm.styles'
import { FieldWrapper } from 'components/applicant/form'

export const BudgetForm = ({
  state,
  properties,
  resource,
  setValue,
  setError,
  extraStuff,
}) => {
  const classes = useBudgetFormStyles()

  return (
    <div className={classes.root}>
      <FieldWrapper
        name="charges"
        property={properties.charges}
        resource={resource}
        state={state}
        setValue={setValue}
        setError={setError}
        extraStuff={extraStuff}
      />
    </div>
  )
}

BudgetForm.propTypes = {
  state: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
}
