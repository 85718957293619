import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { createPortal } from 'react-dom'
import { Button as OriginalButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { translate } from 'core/_helpers/translate'

export const useStyles = makeStyles(theme => ({
  submit: {
    // marginTop: '1.5em',
  },
  // button: {
  //   boxShadow: 'none',
  //   textTransform: 'none',
  //   fontSize: 18,
  //   padding: '16px 50px',
  //   border: 0,
  //   lineHeight: 1.5,
  //   backgroundColor: theme.palette.secondary.main,
  //   color: theme.palette.white,
  //   borderRadius: 0,
  //   borderColor: '#0063cc',
  //   '&.Mui-disabled': {
  //     backgroundColor: theme.palette.disabled,
  //     color: theme.palette.white,
  //   },
  // },
}))

export const SubmitButton = ({
  title = null,
  handleSubmit,
  disabled = false,
  nodeRef = null,
  classes = {},
  variant = 'contained',
}) => {
  const button = (
    <Button
      title={title}
      handleSubmit={handleSubmit}
      disabled={disabled}
      classes={classes}
      variant={variant}
    />
  )

  return nodeRef ? createPortal(button, nodeRef) : button
}

const Button = ({
  title = null,
  handleSubmit,
  disabled = false,
  classes = {},
  variant = 'contained',
}) => {
  const defaultClasses = useStyles()

  return (
    <div className={clsx(defaultClasses.submit, classes.submit)}>
      <OriginalButton
        type="submit"
        variant={variant}
        color="secondary"
        size="large"
        disabled={disabled}
        onClick={handleSubmit}
        className={defaultClasses.button}
      >
        {title || translate('T_FORM_SAVE')}
      </OriginalButton>
    </div>
  )
}

SubmitButton.propTypes = {
  title: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  nodeRef: PropTypes.object,
  classes: PropTypes.shape({
    submit: PropTypes.string,
  }),
  variant: PropTypes.string,
}
