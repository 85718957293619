import React, { useCallback } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { makeStyles } from '@material-ui/styles'
import { constants } from 'core/components/table/_state'
import { notification } from 'core/_helpers/notification'
import { ButtonPlus } from 'core/components/buttons/button-plus'

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.elements.embedded_collection.color,
    '&:hover': {
      '& svg': {
        '& circle': {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
}))

export const AddQuestion = ({ endpoint, parent, dispatch }) => {
  const classes = useStyles()
  const handleSubmit = useCallback(() => {
    fetchDataHandleAuthError(
      endpoint,
      'POST',
      {
        body: JSON.stringify({
          question: '',
          answer: '',
          faqCategory: parent,
        }),
      },
      () => {
        notification('success', 'Pytanie zostało utworzone', 'T_FORM_SUCCESS')
        dispatch({ type: constants.RELOAD })
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [dispatch, endpoint, parent])

  return (
    <>
      <ButtonPlus
        onClick={handleSubmit}
        text={'Dodaj pytanie'}
        disabled={false}
        className={classes.button}
      />
    </>
  )
}
