import { useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

export const useBudgetChargesFetch = (
  getEndpoint,
  id,
  setResource,
  setFetchError
) => {
  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    getBudgetCharges(getEndpoint, id, signal, setResource, setFetchError)

    return () => controller.abort()
  }, [getEndpoint, id, setResource, setFetchError])

  return
}

const getBudgetCharges = (
  getEndpoint,
  id,
  signal,
  setResource,
  setFetchError
) => {
  fetchDataHandleAuthError(
    `${getEndpoint}?proposalBudget.uuid=${id}&pagination=false`,
    'GET',
    { signal },
    response => {
      setResource({ charges: response['hydra:member'] })
    },
    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
      setFetchError(true)
    }
  )
}
