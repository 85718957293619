import React from 'react'
import { Route } from 'react-router-dom'
import { Home } from './Home'
import { roles } from '_helpers/roles'

const routes = () => {
  return {
    index: {
      type: Route,
      render: props => <Home {...props} />,
      path: '/',
      exact: true,
      resourceAccess: [ roles.APPLICANT ],
    },
  }
}

export default routes
