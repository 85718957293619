import { constants } from './constants'

export const reducer = (state, action) => {
  switch (action.type) {
    case constants.FETCH_START:
      return state
    case constants.FETCH_SUCCESS:
      return {
        ...state,
        items:
          state.config.page === 1
            ? action.payload.items
            : [
                ...state.items,
                ...action.payload.items,
              ],
        totalItems: action.payload.totalItems,
        init: false,
      }
    case constants.FETCH_FAILURE:
      return {
        ...state,
        init: false,
      }
    case constants.HANDLE_MORE: {
      return {
        ...state,
        config: {
          ...state.config,
          page: state.config.page + 1,
        },
      }
    }
    case constants.HANDLE_FILTERS:
      return {
        ...state,
        config: {
          ...state.config,
          filters: {
            ...state.config.filters,
            ...action.payload.filters,
          },
          page: 1,
        },
      }
    default:
      return {
        ...state,
        ...action.payload,
      }
  }
}
