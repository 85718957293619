import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { Collection } from './Collection'
import { CollectionTrash } from './CollectionTrash'
import { EditionCollection } from './Edition/Collection'
import { SchemableComponent } from 'core/components/SchemableComponent'
import schemaEdition from '_schema/contestEdition'
import schemaContest from '_schema/contests'
import { Edit } from './Edit'
import { PreviewPage } from 'pages/Foundation/Home/PreviewPage'

const routes = () => {
  return {
    home: {
      type: Redirect,
      from: '/',
      to: `${schemaContest.endpoint}`,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
      exact: true,
      path: '/',
    },
    contest_foundation: {
      title: 'T_MODULE_CONTESTS',
      type: Route,
      render: props => <Collection {...props} />,
      path: `${schemaContest.endpoint}`,
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
    contest_foundation_trash: {
      title: 'T_MODULE_CONTESTS',
      type: Route,
      render: props => <CollectionTrash {...props} />,
      path: `${schemaContest.endpoint}/trash`,
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
    contest_edition: {
      title: 'T_MODULE_CONTEST_EDITION',
      type: Route,
      render: props => <EditionCollection {...props} />,
      path: `${schemaContest.endpoint}${schemaEdition.endpoint}`,
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
    contest_edit: {
      title: 'Edycja konkursu',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={Edit}
          uniqueKey={true}
          {...props}
        />
      ),
      path: `${schemaContest.endpoint}/:id`,
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
    contest_preview: {
      title: 'Podgląd strony',
      type: Route,
      render: props => <PreviewPage {...props} />,
      path: `${schemaContest.endpoint}/:id/preview`,
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
    contest_edit_width_tab: {
      title: 'Edycja konkursu',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={Edit}
          uniqueKey={true}
          {...props}
        />
      ),
      path: `${schemaContest.endpoint}/:id/:tab`,
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
  }
}

export default routes
