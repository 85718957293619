import { useState, useMemo, useEffect } from 'react'

export const useProposalState = () => {
  const [state, setState] = useState({
    isFetching: true,
    proposal: null,
    contest: null,
    fetchError: false,
    contestFetched: false,
    applicationApplicantFormFetched: false,
    applicationProjectFormFetched: false
  })

  const setters = useMemo(
    () => ({
      isFetching: isFetching =>
        setState(state => ({
          ...state,
          isFetching,
        })),
      proposal: proposal =>
        setState(state => ({
          ...state,
          proposal,
        })),
      contest: contest => {
        setState(state => ({
          ...state,
          contestFetched: true,
          contest,
        }))
      },
      form: (name, form) => {
        setState(state => ({
          ...state,
          [`${name}Fetched`]: true,
          contest: {
            ...state.contest,
            [name]: form
          }
        }))
      },
      fetchError: fetchError =>
        setState(state => ({
          ...state,
          isFetching: false,
          fetchError,
        })),
      subresourceFetched: name =>
        setState(state => ({
          ...state,
          [`${name}Fetched`]: true,
        }))
    }),
    [setState]
  )

  useEffect(() => {
    if (
      !state.contestFetched ||
      !state.applicationApplicantFormFetched ||
      !state.applicationProjectFormFetched
    ) {
      return
    }

    setState(state => ({
      ...state,
      isFetching: false
    }))
  }, [
    state.contestFetched,
    state.applicationApplicantFormFetched,
    state.applicationProjectFormFetched,
    setState
  ])

  return [state, setters]
}
