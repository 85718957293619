import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { useStyleTable } from 'components/styledTable'
import { translate } from 'core/_helpers/translate'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { CustomDialog } from 'core/components/Dialog'
import { StringType } from 'core/components/form/fields/StringType'
import schema, {
  BUDGET_CHANGE_STATUS_ACCEPTED,
  BUDGET_CHANGE_STATUS_NEW,
  BUDGET_CHANGE_STATUS_REJECTED,
} from '_schema/proposal'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import Moment from 'react-moment'
import { notification } from 'core/_helpers/notification'
import { DataInfo } from 'components/DataInfo'

const useStyle = makeStyles(theme => ({
  marginBot: {
    marginBottom: theme.spacing(5),
  },
  textGreen: {
    color: theme.palette.success.main,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
  buttonRed: {
    borderColor: theme.palette.error.main,
    '& .MuiButton-label': {
      color: theme.palette.error.main,
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 24px rgba(11, 43, 124, 0.4)',
      '& .MuiButton-label': {
        color: theme.palette.primary.main,
      },
    },
  },
}))

export const BudgetShift = ({ eventObject, updateResource }) => {
  const tableClasses = useStyleTable()
  const classes = useStyle()
  const [state, setState] = useState(false)
  const handleChagneState = useCallback(state => {
    setState(state)
  }, [])

  const [budgetChange, setBudgetChange] = useState({})
  const [budgetHistory, setBudgetHistory] = useState([])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    const iri = eventObject.budgetChangeRequest[0]['@id']
    fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
      setBudgetChange(resp)
    })
    return () => controller.abort()
  }, [eventObject.budgetChangeRequest])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    const iri = `${schema.endpoint_budget_change_history}?budgetChangeRequest.uuid=${budgetChange.uuid}&pagination=false`
    fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
      setBudgetHistory(resp['hydra:member'])
    })
    return () => controller.abort()
  }, [budgetChange])

  const [question, setQuestion] = useState('')
  const handleChangeQuestion = useCallback(
    (name, value) => setQuestion(value),
    []
  )
  const handleQuestionShift = useCallback(() => {
    const iri = `${schema.endpoint_budget_change_requests}/${budgetChange.uuid}/question`
    fetchDataHandleAuthError(
      iri,
      'PATCH',
      { body: JSON.stringify({ question }) },
      resp => {
        setBudgetChange(resp)
        setState(false)
        notification('success', translate('Odpowiedź została wysłana'))
        updateResource()
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [budgetChange.uuid, question, updateResource])

  const handleRejectShift = useCallback(() => {
    const iri = `${schema.endpoint_budget_change_requests}/${budgetChange.uuid}/reject`
    fetchDataHandleAuthError(
      iri,
      'PATCH',
      { body: JSON.stringify({ message: question }) },
      resp => {
        setBudgetChange(resp)
        setState(false)
        notification(
          'warning',
          translate('Wniosek o zmianę budżetu został odrzucony')
        )
        updateResource()
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [budgetChange.uuid, updateResource, question])

  const handleAcceptShift = useCallback(() => {
    const iri = `${schema.endpoint_budget_change_requests}/${budgetChange.uuid}/accept`
    fetchDataHandleAuthError(
      iri,
      'PATCH',
      { body: JSON.stringify({ message: question }) },
      resp => {
        setBudgetChange(resp)
        setState(false)
        notification(
          'success',
          translate('Wniosek o zmianę budżetu został zaakceptowany')
        )
        updateResource()
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [budgetChange.uuid, updateResource, question])

  const handleQuestionConfirmed = useCallback(() => {
    switch (state) {
      case 'accept':
        handleAcceptShift()
        break
      case 'reject':
        handleRejectShift()
        break
      case 'question':
        handleQuestionShift()
        break
      default:
        handleChagneState(false)
        break
    }
  }, [
    state,
    handleAcceptShift,
    handleRejectShift,
    handleQuestionShift,
    handleChagneState,
  ])

  return (
    <>
      <Grid container spacing={3} className={classes.marginBot}>
        <Grid item xs={12} md={2}>
          <Typography variant={'subtitle1'} color={'primary'}>
            <Moment date={budgetChange.createdAt} format={'DD.MM.YYYY'} />
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Typography
            variant={'h3'}
            color={'secondary'}
            className={classes.marginBot}
          >
            {translate('Zgłoszenie')}
          </Typography>
          <Table className={tableClasses.table}>
            <TableHead className={tableClasses.tableHead}>
              <TableRow>
                <TableCell
                  className={tableClasses.tableHeadCell}
                  style={{ width: '60%' }}
                >
                  {translate('Rodzaj kosztów')}
                </TableCell>
                <TableCell className={tableClasses.tableHeadCell}>
                  {translate('Kwote dotowana')}
                </TableCell>
                <TableCell className={tableClasses.tableHeadCell}>
                  {translate('Zmiana')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {budgetHistory.map((el, index) => (
                <TableRow
                  key={index}
                  className={clsx(
                    el.subsidyAmountBudget !== el.subsidyAmount
                      ? tableClasses.rowStrong
                      : null
                  )}
                >
                  <TableCell className={clsx(tableClasses.tableCell)}>
                    {el.titleOld && el.titleOld !== el.title ? (
                      <>
                        <span style={{ textDecoration: 'line-through' }}>
                          {el?.titleOld}
                        </span>
                        <br />
                        <span style={{ fontWeight: 'bold' }}>{el?.title}</span>
                      </>
                    ) : (
                      <span>{el.budget?.title}</span>
                    )}
                  </TableCell>
                  <TableCell className={clsx(tableClasses.tableCell)}>
                    <span>{el.subsidyAmountBudget} zł</span>
                  </TableCell>
                  <TableCell className={tableClasses.tableCell}>
                    <span>{el.subsidyAmount} zł</span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <DataInfo
            label={'Uzasadnienie'}
            bold={true}
            data={budgetChange.description}
          />
        </Grid>
      </Grid>
      {budgetChange.question && budgetChange.questionDate && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <Typography variant={'subtitle1'} color={'primary'}>
              <Moment date={budgetChange.questionDate} format={'DD.MM.YYYY'} />
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography variant={'h3'} color={'secondary'}>
              {translate('Do wyjaśnienia')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography
              variant={'subtitle1'}
              color={'primary'}
              style={{ lineHeight: '28px' }}
            >
              {budgetChange?.userQuestion.replace('|', ' ')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={10}>
            <Typography
              variant={'body1'}
              color={'primary'}
              className={classes.marginBot}
            >
              {budgetChange.question}
            </Typography>
          </Grid>
        </Grid>
      )}
      {budgetChange.answer && budgetChange.answerDate && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <Typography variant={'subtitle1'} color={'primary'}>
              <Moment date={budgetChange.answerDate} format={'DD.MM.YYYY'} />
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography variant={'h3'} color={'secondary'}>
              {translate('Wyjaśnienie')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={10}>
            <Typography
              variant={'body1'}
              color={'primary'}
              className={classes.marginBot}
            >
              {budgetChange.answer}
            </Typography>
          </Grid>
        </Grid>
      )}
      {budgetChange.status !== BUDGET_CHANGE_STATUS_REJECTED &&
        budgetChange.status !== BUDGET_CHANGE_STATUS_ACCEPTED && (
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              className={classes.buttonRed}
              onClick={() => handleChagneState('reject')}
            >
              <span>{translate('Odrzuć')}</span>
            </Button>
            {budgetChange.status === BUDGET_CHANGE_STATUS_NEW && (
              <>
                <Button
                  color={'secondary'}
                  variant="outlined"
                  onClick={() => handleChagneState('question')}
                >
                  <span>{translate('Poproś o wyjaśnienie')}</span>
                </Button>
              </>
            )}
            <Button
              color={'secondary'}
              variant="contained"
              onClick={() => handleChagneState('accept')}
            >
              <span>{translate('Akceptuj')}</span>
            </Button>
            <CustomDialog
              handleToogle={() => handleChagneState(null)}
              open={!!state}
              title={'Informacja dla Wnioskodawcy'}
              confirmedButton={true}
              onConfirm={handleQuestionConfirmed}
              component={() => (
                <StringType
                  name={'question'}
                  setValue={handleChangeQuestion}
                  value={question}
                  disabled={false}
                  renderError={false}
                  type={'textarea'}
                  setError={() => {}}
                  label={''}
                  width={'100%'}
                />
              )}
            />
          </div>
        )}
      {(budgetChange.status === BUDGET_CHANGE_STATUS_REJECTED ||
        budgetChange.status === BUDGET_CHANGE_STATUS_ACCEPTED) && (
        <Grid container spacing={3} className={classes.marginBot}>
          <Grid item xs={12} md={2}>
            <Typography variant={'subtitle1'} color={'primary'}>
              <Moment date={budgetChange.updatedAt} format={'DD.MM.YYYY'} />
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              variant={'h3'}
              color={
                budgetChange.status === BUDGET_CHANGE_STATUS_REJECTED
                  ? 'error'
                  : 'primary'
              }
              className={clsx(
                classes.marginBot,
                budgetChange.status === BUDGET_CHANGE_STATUS_ACCEPTED &&
                  classes.textGreen
              )}
            >
              {translate(`T_BUDGET_CHANGE_STATUS_${budgetChange.status}`)}
            </Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography
              variant={'subtitle1'}
              color={'primary'}
              style={{ lineHeight: '28px' }}
            >
              {budgetChange?.userAcceptedRejected?.replace('|', ' ')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={10}>
            <Typography
              variant={'body1'}
              color={'primary'}
              className={classes.marginBot}
            >
              {budgetChange.message}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}
