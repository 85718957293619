import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { DateType } from 'components/applicant/form/fields/DateType'
import { getBlockFieldsMapping } from 'pages/applicant/Proposals/_helpers/getBlockFieldsMapping'
import { blockTypes } from 'pages/applicant/Proposals/_helpers/blockTypes'
import { translate } from 'core/_helpers/translate'

const VALIDATE_DATE_FORMAT = 'YYYY-MM-DD'
const DATE_FORMAT = 'DD.MM.YYYY'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 8,
  },
  info: {
    marginBottom: 8,
  },
  label: {
    marginTop: 6,
  },
  input: {
    width: 150,
  },
  help: {
    marginLeft: 25,
    color: theme.palette.disabled,
  },
  flex: {
    display: 'flex',
    '&> *:first-child': {
      marginRight: 20,
    },
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 8,
  },
}))

export const RealizationDateTimeBlock = ({
  data,
  values,
  errors,
  renderError,
  globalRenderError,
  setValue,
  setError,
  contest,
  disabled,
  setAdditional,
}) => {
  const mapping = useMemo(
    () => getBlockFieldsMapping(blockTypes.REALIZATION_DATE_TIME, data),
    [data]
  )

  const validators = useMemo(
    () => ({
      inputs: [
        ...(mapping.inputs.required ? ['required'] : []),
        ...(contest.projectRealizationDateFrom
          ? [
              `minDate(${moment(contest.projectRealizationDateFrom).format(
                VALIDATE_DATE_FORMAT
              )})`,
            ]
          : []),
        ...(contest.projectRealizationDateTo
          ? [
              `maxDate(${moment(contest.projectRealizationDateTo).format(
                VALIDATE_DATE_FORMAT
              )})`,
            ]
          : []),
      ],
    }),
    [
      mapping,
      contest.projectRealizationDateFrom,
      contest.projectRealizationDateTo,
    ]
  )

  useEffect(() => {
    if (
      !contest.minWorkingMonthNumber ||
      !values?.project_realization_date_from ||
      !values?.project_realization_date_to ||
      moment(values?.project_realization_date_to)
        .add(1, 'days')
        .diff(moment(values?.project_realization_date_from), 'M', true) >=
        contest.minWorkingMonthNumber
    ) {
      return
    }

    setError(
      'project_realization_date_to',
      `Minimalna liczba miesięcy działań: ${contest.minWorkingMonthNumber}`,
      true
    )
  }, [contest.minWorkingMonthNumber, setError, values])

  useEffect(() => {
    setAdditional(additional => ({
      projectRealizationDate: {
        ...additional.projectRealizationDate,
        start: values?.project_realization_date_from,
      },
    }))
  }, [setAdditional, values])

  useEffect(() => {
    setAdditional(additional => ({
      projectRealizationDate: {
        ...additional.projectRealizationDate,
        end: values?.project_realization_date_to,
      },
    }))
  }, [setAdditional, values])

  const classes = useStyles()

  return (
    <div className={classes.row}>
      <div>
        <div className={classes.title}>
          {translate('T_MODULE_PROPOSALS_REALIZATION_DATE_TIME')}
        </div>
        <div className={classes.info}>
          {translate('T_MODULE_PROPOSALS_REALIZATION_DATE_TIME_RANGE')}:{' '}
          {contest.projectRealizationDateFrom &&
            moment(contest.projectRealizationDateFrom).format(DATE_FORMAT)}
          {' - '}
          {contest.projectRealizationDateTo &&
            moment(contest.projectRealizationDateTo).format(DATE_FORMAT)}
        </div>
        <div className={classes.flex}>
          <div className={classes.flex}>
            <div className={classes.label}>
              {translate('T_MODULE_PROPOSALS_REALIZATION_DATE_TIME_FROM')}
            </div>
            <DateType
              name="project_realization_date_from"
              value={values?.project_realization_date_from}
              error={errors?.project_realization_date_from}
              renderError={
                globalRenderError && renderError?.project_realization_date_from
              }
              disabled={disabled}
              validators={validators.inputs}
              setValue={setValue}
              setError={setError}
              classes={{
                root: classes.input,
              }}
            />
          </div>
          <div className={classes.flex}>
            <div className={classes.label}>
              {translate('T_MODULE_PROPOSALS_REALIZATION_DATE_TIME_TO')}
            </div>
            <DateType
              name="project_realization_date_to"
              value={values?.project_realization_date_to}
              error={errors?.project_realization_date_to}
              renderError={
                globalRenderError && renderError?.project_realization_date_to
              }
              disabled={disabled}
              validators={validators.inputs}
              setValue={setValue}
              setError={setError}
              classes={{
                root: classes.input,
              }}
            />
          </div>
        </div>
      </div>
      <div className={classes.help}>{mapping.inputs.help}</div>
    </div>
  )
}

RealizationDateTimeBlock.propTypes = {
  data: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  contest: PropTypes.shape({
    minWorkingMonthNumber: PropTypes.number,
    projectRealizationDateFrom: PropTypes.string,
    projectRealizationDateTo: PropTypes.string,
  }).isRequired,
  disabled: PropTypes.bool,
}
