import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { ArrowForwardOutlined } from '@material-ui/icons'
import { Paper } from 'core/components/Paper'
import { ContestCollection as OriginalContestCollection } from 'components/applicant/contest_collection'
import { ContestItemComponent } from './ContestItemComponent'
import { translate } from 'core/_helpers/translate'
import contestSchema from '_schema/contests'
import currentContestsRoutes from 'pages/applicant/CurrentContests/routes'

const useStyles = makeStyles(theme => ({
  bar: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: 0,
    paddingRight: 0,
  },
  title: {
    fontSize: 26,
    lineHeight: 1.2,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&> *:first-child': {
      marginRight: 8,
    },
  },
  upper_bar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 18,
    marginBottom: 18,
  },
  lower_bar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

export const ContestCollection = () => {
  const classes = useStyles()

  return (
    <Paper>
      <div className={clsx(classes.bar, classes.upper_bar)}>
        <div className={classes.title}>
          {translate('T_MODULE_CONTESTS_NEWEST')}
        </div>
        <Link to={currentContestsRoutes().index.path} className={classes.link}>
          <span>{translate('T_MODULE_CONTESTS_SEE_ALL')}</span>
          <ArrowForwardOutlined />
        </Link>
      </div>
      <OriginalContestCollection
        endpoint={contestSchema.endpoint}
        ItemComponent={ContestItemComponent}
        perPage={3}
        filters={{}}
        isSingleColumn={true}
        isStatic={true}
      />
      <div className={clsx(classes.bar, classes.lower_bar)}>
        <Link to={currentContestsRoutes().index.path} className={classes.link}>
          <span>{translate('T_MODULE_CONTESTS_SEE_ALL')}</span>
          <ArrowForwardOutlined />
        </Link>
      </div>
    </Paper>
  )
}
