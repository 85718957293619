import {
  blockTypes
} from './blockTypes'

export const getBlockFieldsMapping = (type, data) => {
  const deserialized = blockTypes.TEXT === type
    ? data
    : data
      ? JSON.parse(data)
      : {}

  switch (type) {
    case blockTypes.TEXT:
      return {
        text: {
          description: deserialized
        }
      }
    case blockTypes.INPUT:
      return {
        input: {
          label: deserialized.label,
          placeholder: deserialized.input,
          maxLength: deserialized.limit,
          unitLabel: deserialized.unit,
          required: deserialized.required,
          validate: deserialized.validate,
          help: deserialized.help
        }
      }
    case blockTypes.TEXTAREA:
      return {
        input: {
          label: deserialized.label,
          maxLength: deserialized.limit,
          required: deserialized.required,
          help: deserialized.help
        }
      }
    case blockTypes.CHOICES_SELECT:
      return {
        input: {
          label: deserialized.label,
          defaultValue: deserialized.subform
            ? Object.keys(deserialized.subform).find(key => deserialized.subform[key].default)
            : null,
          choices: deserialized.subform
            ? Object.assign(
              {},
              ...Object.keys(deserialized.subform).map(key => ({ [key]: deserialized.subform[key].name }))
            ) : [],
          required: deserialized.required,
          help: deserialized.help
        }
      }
    case blockTypes.CHOICES_CHECKBOX:
      return {
        input: {
          label: deserialized.label,
          defaultValue: deserialized.subform
            ? JSON.stringify({
              key: Object.keys(deserialized.subform).find(key => deserialized.subform[key].default)
            })
            : null,
          choices: deserialized.subform
            ? Object.assign(
              {},
              ...Object.keys(deserialized.subform).map(key => ({ [key]: deserialized.subform[key].name }))
            ) : [],
          addOtherChoice: deserialized.other,
          required: deserialized.required,
          help: deserialized.help
        }
      }
    case blockTypes.DATE_TIME:
      return {
        input: {
          label: deserialized.label,
          isDate: deserialized.date,
          isTime: deserialized.time,
          required: deserialized.required,
          help: deserialized.help
        }
      }
    case blockTypes.ADDRESS:
      return {
        postOffice: {
          placeholder: deserialized.postOffice,
          required: deserialized.postRequired
        },
        postCode: {
          placeholder: deserialized.postCode,
          required: deserialized.postRequired
        },
        address: {
          placeholder: deserialized.address,
          required: deserialized.addressRequired
        },
        city: {
          placeholder: deserialized.city,
          required: deserialized.cityRequired
        },
        commune: {
          placeholder: deserialized.commune,
          required: deserialized.communeRequired
        },
        district: {
          placeholder: deserialized.district,
          required: deserialized.districtRequired
        },
        province: {
          placeholder: deserialized.province,
          required: deserialized.provinceRequired
        }
      }
    case blockTypes.REALIZATION_DATE_TIME:
      return {
        inputs: {
          required: deserialized.required,
          help: deserialized.help
        }
      }
    case blockTypes.PARTICIPANTS_COUNT:
      return {
        inputs: {
          required: deserialized.required,
          from: deserialized.from,
          to: deserialized.to,
          help: deserialized.help
        }
      }
    case blockTypes.PROJECT_SCHEDULE:
      return {
        inputs: {
          required: deserialized.required,
          help: deserialized.help
        }
      }
    default:
      return {}
  }
}
