import { Grid, IconButton, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ReactComponent as RemoveIcon } from 'theme/icons/remove.svg'
import React, { useState } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { makeStyles } from '@material-ui/styles'
import { BlankForm } from 'core/pages'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(() => ({
  count: {
    marginTop: 10,
  },
}))

export const ItemRate = ({
  schema,
  index,
  el,
  onRemove,
  definitions,
  disabled,
}) => {
  const classes = useStyles()
  const [showButton, setShowButton] = useState(false)

  const handleRemove = () => {
    const uuid = el.uuid
    const url = el['@id']
    fetchDataHandleAuthError(
      url,
      'DELETE',
      {},
      () => {
        onRemove(uuid)
        notification('warning', 'Wytyczna została usunięta')
      },
      error => {
        notification('error', error.response.detail)
      }
    )
  }
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={1}>
          <Typography
            color={'primary'}
            variant={'h2'}
            className={classes.count}
          >
            {index}.
          </Typography>
        </Grid>
        <Grid item xs={12} md={1}>
          {!disabled && (
            <IconButton onClick={handleRemove}>
              <RemoveIcon />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={12} md={10}>
          <BlankForm
            method={'PUT'}
            url={el['@id']}
            customResourceSchema={schema.resource}
            definitionSchema={definitions[schema.resource.definition]}
            resource={el}
            showSubmitAndStayButton={showButton}
            showCancelButton={false}
            submitAndStayButtonLabel={translate('Zapisz')}
            buttonsFixed={false}
            showSubmitButton={false}
            width="100%"
            isChange={() => setShowButton(true)}
            handleSuccessAndStay={() => setShowButton(false)}
            fullWidth={true}
            storeCollectionId={el.uuid}
            isCurrentResource={false}
            readOnly={disabled}
          />
        </Grid>
      </Grid>
    </>
  )
}

ItemRate.propTypes = {
  schema: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  el: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  definitions: PropTypes.object.isRequired,
}
