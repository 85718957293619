import React, { useReducer, useEffect, useCallback, useState } from 'react'
import clsx from 'clsx'
import _ from 'lodash'
import { Loader } from 'core/components/Loader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { isObjectEmpty } from 'core/_helpers/isObjectEmpty'
import { createObjectFromString } from 'core/_helpers/createObjectFromString'
import { SubmitButton, CancelButton } from './buttons'
import { initState } from './_helpers/initState'
import { constants, reducer } from './_state'
import { propTypes, useStyles } from '.'
import { useKrsDataApi } from '_helpers/useKrsDataApi'

export const Form = ({
  url,
  method = 'PUT',
  properties,
  resource = null,
  handleSubmit = null,
  handleSuccess = null,
  handleCancel = null,
  onlyPassedProperties = false,
  buttonContainerRef = null,
  submitButtonTitle = null,
  submitButtonVariant = 'contained',
  submitButtonSize = 'large',
  submitButtonDisabled = 'false',
  additionalButtons = null,
  children,
  getKrsFromApi = false,
  classes = {},
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    {
      url,
      method,
      resource,
      properties,
      onlyPassedProperties,
    },
    initState
  )

  const setValue = useCallback((name, value, setRenderError = true) => {
    dispatch({
      type: constants.SET_VALUE,
      payload: { name, value, setRenderError },
    })
  }, [])

  const setError = useCallback((name, error) => {
    dispatch({ type: constants.SET_ERROR, payload: { name, error } })
  }, [])

  useEffect(() => {
    if (!state.isSubmitted) {
      return
    }

    dispatch({ type: constants.RENDER_ERROR })
  }, [state.isSubmitted])

  const handleSubmitButton = e => {
    e.preventDefault()

    dispatch({ type: constants.SUBMIT })

    if (state.isInvalid) {
      return
    }

    if (handleSubmit) {
      handleSubmit(state.values)

      return
    }

    dispatch({ type: constants.PROCESS, payload: true })

    fetchDataHandleAuthError(
      url,
      method,
      { body: JSON.stringify(state.values) },
      response => {
        if (properties?.stat === undefined && response?.stat !== undefined) {
          delete response.stat
        }

        dispatch({
          type: constants.SUCCESS,
          payload: {
            method,
            resource: response,
          },
        })

        notification(
          'success',
          ['PUT', 'PATCH'].includes(method)
            ? 'T_FORM_RECORD_UPDATED'
            : 'T_FORM_RECORD_CREATED',
          'T_FORM_SUCCESS'
        )

        handleSuccess && handleSuccess(response)
      },
      error => {
        const errors = error.response.violations.reduce(
          (processedErrors, item) => {
            const processedError = createObjectFromString(
              item.propertyPath.replace('[', '.').replace(']', ''),
              item.message
            )

            return _.merge(processedErrors, processedError)
          },
          {}
        )

        dispatch(
          isObjectEmpty(errors)
            ? { type: constants.PROCESS, payload: false }
            : { type: constants.FAILURE, payload: { errors } }
        )

        notification(
          'error',
          error.response.violations.length
            ? 'T_FORM_INCORRECT'
            : error.response.detail,
          error.response.title
        )
      },
      {}
    )
  }

  const defaultClasses = useStyles()

  const [krsIsFetching, setKrsIsFetching] = useState(false)

  useKrsDataApi(
    krsIsFetching,
    setKrsIsFetching,
    state.values.taxId,
    state.values[state.values.taxId],
    setValue,
    setError,
    getKrsFromApi
  )

  const handleSetDataFromKRS = useCallback(() => setKrsIsFetching(true), [])

  return (
    <form className={clsx(defaultClasses.root, classes.root)}>
      {children &&
        children({
          state,
          properties,
          resource,
          setValue,
          setError,
          krsIsFetching,
          handleSetDataFromKRS,
        })}
      <div className={clsx(defaultClasses.buttons, classes.buttons)}>
        {state.isProcessing && <Loader classes={{ root: classes.loader }} />}
        {additionalButtons}
        {handleCancel && (
          <CancelButton
            handleCancel={handleCancel}
            disabled={state.isProcessing}
            classes={{ submit: classes.submit }}
            nodeRef={buttonContainerRef}
          />
        )}
        {!state.isProcessing && (
          <SubmitButton
            title={submitButtonTitle}
            variant={submitButtonVariant}
            size={submitButtonSize}
            handleSubmit={handleSubmitButton}
            disabled={
              state.isProcessing ||
              (state.isSubmitted && state.isInvalid) ||
              submitButtonDisabled
            }
            classes={{ submit: classes.submit }}
            nodeRef={buttonContainerRef}
          />
        )}
      </div>
    </form>
  )
}

Form.propTypes = propTypes
