import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { ContractRepresentativeItemType } from './ContractRepresentativeItemType'
import { isContractRepresentativeCollectionInvalid } from 'pages/applicant/AppliedProposals/_helpers/isContractRepresentativeCollectionInvalid'
import { generateContractRepresentativeCollectionRenderError } from 'pages/applicant/AppliedProposals/_helpers/generateContractRepresentativeCollectionRenderError'
import { useContractRepresentativeCollectionTypeStyles } from './ContractRepresentativeCollectionType.styles'
import { ReactComponent as Delete } from 'theme/icons/remove.svg'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { translate } from 'core/_helpers/translate'

export const ContractRepresentativeCollectionType = ({
  name,
  value,
  disabled,
  renderError,
  setValue,
  setError,
  isFormSubmitted,
}) => {
  const [state, setState] = useState({
    values: value.length > 0 ? value : [{}],
    errors: value.length > 0 ? Array(value.length).fill({}) : [{}],
    renderError: value.length > 0 ? Array(value.length).fill({}) : [{}],
    isInvalid: false,
    isFormSubmitted: false,
    init: true,
  })
  useEffect(() => {
    console.log(state)
  }, [state])

  const handleAdd = useCallback(() => {
    setState(state => ({
      ...state,
      values: [...state.values, {}],
      errors: [...state.errors, {}],
      renderError: [...state.renderError, {}],
    }))
  }, [setState])

  const handleDelete = useCallback(
    index => () => {
      setState(state => ({
        ...state,
        values: state.values?.filter((item, i) => i !== index),
        errors: state.errors?.filter((item, i) => i !== index),
        renderError: state.renderError?.filter((item, i) => i !== index),
        isInvalid: isContractRepresentativeCollectionInvalid(
          state.errors?.filter((item, i) => i !== index)
        ),
      }))
    },
    [setState]
  )

  useEffect(() => {
    setValue(name, state.values, !state.init)
  }, [name, state.values, state.init, setValue])

  useEffect(() => {
    setError(name, state.isInvalid)
  }, [name, setError, state.isInvalid])

  useEffect(() => {
    if (!isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      isFormSubmitted,
    }))
  }, [setState, isFormSubmitted])

  useEffect(() => {
    if (!state.isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      renderError: generateContractRepresentativeCollectionRenderError(
        state.errors,
        true
      ),
    }))
  }, [setState, state.isFormSubmitted])

  const classes = useContractRepresentativeCollectionTypeStyles()

  return (
    <div className={classes.root}>
      <div>
        {state.values.map((item, index) => (
          <div className={classes.container} key={index}>
            <div>
              <ContractRepresentativeItemType
                index={index}
                values={state.values?.[index]}
                errors={state.errors[index]}
                renderError={state.renderError[index]}
                globalRenderError={renderError}
                setState={setState}
                disabled={disabled}
              />
            </div>
            <div className={classes.sidebar}>
              {state.values.length > 1 && (
                <Delete onClick={handleDelete(index)} />
              )}
            </div>
          </div>
        ))}
      </div>
      <ButtonPlus
        text={translate('T_MODULE_PROPOSALS_ADD_CONTRACT_REPRESENTATIVE')}
        disabled={disabled}
        onClick={handleAdd}
      />
    </div>
  )
}

ContractRepresentativeCollectionType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  renderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
}
