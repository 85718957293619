import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  grow: {
    flexGrow: 1,
  },
  toolbar: {
    paddingLeft: 24,
    paddingRight: 12,
    backgroundColor: theme.elements.topBar,
  },
  sidebarClose: {
    marginRight: 12,
  },
  reloadLoader: {
    marginRight: 15,
  },
  breadcrumbs: {
    display: 'flex',
    marginRight: 'auto',
  },
  skeleton: {
    display: 'flex',
    marginRight: 'auto',
    width: 220,
  },
  toolbar_box: {
    display: 'flex',
    width: 'calc(100% - 100px)',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}))
