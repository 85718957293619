import React, { useMemo } from 'react'
import { standard } from 'core/components/table/_columns/standard'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import schema from '_schema/proposal'
import { TranslateCell } from 'core/components/table/cells/TranslateCell'
import { ApplicationStatusCell } from 'components/foundation/table/cells/ApplicationStatusCell'
import routes from '../../Projects/routes'

export const ProjectsCollection = ({ resource }) => {
  const columns = useMemo(
    () => [
      {
        ...standard('contest.contestEdition.title'),
        sortable: false,
        filterable: false,
        width: '10%',
        description: {
          text: 'Edycja',
        },
      },
      {
        ...standard('contest.title'),
        sortable: false,
        filterable: false,
        width: '20%',
      },
      {
        ...standard('title'),
        sortable: false,
        filterable: false,
        width: '20%',
      },
      {
        accessor: 'applicationStatus',
        Cell: ApplicationStatusCell,
        pre: 'T_MODULE_PROPOSALS_APPLICATION_STATUS',
        sortable: false,
        filterable: false,
        width: '10%',
      },
      {
        accessor: 'acceptationStatus',
        Cell: TranslateCell,
        pre: 'T_ACCEPTATION_STATUS',
        sortable: false,
        filterable: false,
        width: '10%',
      },
      {
        accessor: 'recommendationStatus',
        Cell: TranslateCell,
        pre: 'T_RECOMMENDATION_STATUS',
        sortable: false,
        filterable: false,
        width: '10%',
      },
      {
        accessor: 'realizationStatus',
        Cell: TranslateCell,
        sortable: false,
        filterable: false,
        width: '10%',
      },
      {
        ...standard('budgetsTotalAmount'),
        sortable: false,
        filterable: false,
        width: '10%',
      },
    ],
    []
  )
  return (
    <>
      <SchemableCollection
        component={CollectionTable}
        path={`paths.${schema.endpoint_foundation_user}.get`}
        endpoint={schema.endpoint_foundation_user}
        customResourceSchema={schema.resource_foundation_user}
        columns={columns}
        autoWidth={false}
        filterable={true}
        filterInline={true}
        sortable={true}
        numerable={false}
        customFilters={[
          {
            name: 'applicant.uuid',
            value: resource.uuid,
            title: 'applicant',
          },
        ]}
        resetable={false}
        paginable={false}
        definitionSchema={schema.resource_foundation_user}
        isRowLinkable={true}
        editPath={routes().item.path}
        storeCollectionId={resource['@id']}
      />
    </>
  )
}
