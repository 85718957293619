export const hasResourceAccess = (resourceAccess, user) => {
  if (!resourceAccess) {
    return true
  }

  if (user.wasPasswordChangedByOtherAdmin) {
    return false
  }

  if (
    user.isOpenform ||
    user.isSuperAdmin ||
    (user.roles && user.roles.some(r => resourceAccess.includes(r)))
  ) {
    return true
  }

  return false
}
