import { createTheme, alpha } from '@material-ui/core/styles'
import { plPL } from '@material-ui/core/locale'
import typography from './theme/typography'
import overrides from './theme/overrides'
import { COLORS } from './theme/colors'

const LOCALES = {
  pl: plPL,
}

const common = {
  login: {
    logo: COLORS.GRAY.DARK_2,
    submit: {
      color: COLORS.WHITE,
      background: COLORS.GRAY.DARK_2,
    },
  },
  logo: COLORS.BLACK,
  sideBar: {
    background: COLORS.GRAY.DARK_2,
    text: COLORS.GRAY.LIGHT_1,
    hover: COLORS.WHITE,
    divider: COLORS.GRAY.DARK_3,
  },
  menu: {
    text: COLORS.PRIMARY,
    hover: alpha(COLORS.SECONDARY, 0.05),
    active: COLORS.SECONDARY,
  },
  subMenu: COLORS.GRAY.DARK_1,
}

const light = {
  typography,
  overrides,
  palette: {
    type: 'light',
    primary: {
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.SECONDARY,
    },
    disabled: COLORS.DISABLED,
    error: {
      main: COLORS.ERROR,
    },
    success: {
      main: COLORS.SUCCESS,
    },
    info: {
      main: COLORS.INFO,
    },
    warning: {
      main: COLORS.WARNING,
    },
    white: COLORS.WHITE,
    wariant: COLORS.WARIANT,
    lighting: COLORS.LIGHTNING,
    back: COLORS.BACK,
    separator: COLORS.SEPARATOR,
    yellow: COLORS.YELLOW,
    orange: COLORS.ORANGE,
    blue: COLORS.BLUE,
  },
  elements: {
    ...common,
    login: {
      ...common.login,
      text: 'rgba(0, 0, 0, .54)',
    },
    topBar: COLORS.WHITE,
    content: COLORS.WHITE,
    background: COLORS.BACK,
    title: COLORS.GRAY.DARK_2,
    table: {
      odd: COLORS.WHITE,
      tHead: COLORS.WHITE,
      tHeadC: COLORS.WARIANT,
    },
    header: {
      background: COLORS.GRAY.DARK_5,
      color: COLORS.GRAY.LIGHT_5,
    },
    tab: {
      header: {
        background: COLORS.GRAY.LIGHT_4,
        color: COLORS.DARK_5,
      },
      body: {
        background: COLORS.WHITE,
        color: COLORS.BLACK,
      },
    },
    tooltip: {
      background: COLORS.WHITE,
      color: COLORS.BLACK,
    },
    embedded_collection: {
      background: COLORS.WHITE,
      color: COLORS.PRIMARY,
    },
    messenger: {
      recipient: {
        background: COLORS.GRAY.LIGHT_3,
      },
      timestamp: {
        color: COLORS.GRAY.DARK_6,
      },
    },
    remember_me: 'rgba(0, 0, 0, .54)',
  },
}

export const theme = type =>
  createTheme(light, LOCALES[process.env.REACT_APP_LOCALE])
