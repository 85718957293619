import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { ProposalSteps } from 'components/ProposalSteps'
import { TabPanel } from 'components/TabPanel'
import {
  ProposalForm,
  ApplicantStep,
  ProjectStep,
  BudgetStep,
  AttachmentStep,
} from './components/form'
import { Loader } from 'core/components/Loader'
import { Delete } from 'core/components/resource/Delete'
import { NotFound } from 'core/pages/NotFound'
import { useContestState } from './_helpers/useContestState'
import { useContestFetch } from './_helpers/useContestFetch'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useProposalFetch } from './_helpers/useProposalFetch'
import { useEditStyles } from './Edit.styles'
import { ContestDetails } from 'components/applicant'
import { translate } from 'core/_helpers/translate'
import { contestPublicationStatuses } from '_helpers/contestPublicationStatuses'
import { proposalApplicationStatuses } from '_helpers/proposalApplicationStatuses'
import { proposalSteps } from '_helpers/proposalSteps'
import contestSchema from '_schema/contests'
import proposalSchema from '_schema/proposal'
import contestRoutes from 'pages/applicant/Contests/routes'
import { ButtonDownload } from 'core/components/buttons/button-download'
import { useDispatch, useSelector } from 'react-redux'
import { formSettingsConstans } from '_constans/formSettings.constans'
import { Beforeunload } from 'react-beforeunload'
import { Prompt } from 'react-router-dom'
import { downloadFile } from '_helpers/downloadFile'

const DATE_FORMAT = 'YYYY-MM-DD'

export const Edit = ({ schema: definitions, match }) => {
  const isChange = useSelector(state => state.formSetting.isChange)
  const dispatchRedux = useDispatch()
  const contestUuid = match.params.contest_id
  const contestIri = `${contestSchema.endpoint}/${contestUuid}`

  const [contestState, setContestState] = useContestState()

  useEffect(() => {
    dispatchRedux({ type: formSettingsConstans.RESET_IS_CHANGE })
  }, [dispatchRedux])

  const {
    resource: contestResource,
    isFetching: contestResourceIsFetching,
    fetchError: contestResourceFetchError,
  } = contestState

  useContestFetch(
    contestIri,
    setContestState.resource,
    setContestState.form,
    setContestState.fetchError,
    setContestState.formFetched
  )

  const [proposalState, setProposalState] = useResourceState()

  const {
    resource: proposalResource,
    isFetching: proposalResourceIsFetching,
    fetchError: proposalResourceFetchError,
  } = proposalState

  useProposalFetch(
    proposalSchema.endpoint_applicant_contest,
    proposalSchema.endpoint_proposal_create,
    contestUuid,
    setProposalState.resource,
    setProposalState.fetchError
  )

  const history = useHistory()

  const handleProposalDelete = () => {
    history.push(contestRoutes().show.path.replace(':id', contestUuid))
  }

  const [activeStep, setActiveStep] = useState(proposalSteps.APPLICANT)

  const handleStepChange = step => () => {
    setActiveStep(step)
  }

  const [formButtonsRef, setFormButtonsRef] = useState(null)

  const onFormButtonsRef = element => {
    setFormButtonsRef(element)
  }

  const [sidebarBudgetRef, setSidebarBudgetRef] = useState(null)

  const onSidebarBudgetRef = element => {
    setSidebarBudgetRef(element)
  }

  const classes = useEditStyles()

  const handlePdfDownlad = () => {
    const iri = `${proposalSchema.endpoint_pdf_download}${proposalResource.uuid}`
    downloadFile(iri, `${proposalResource.uuid}-wniosek.pdf`)
  }

  return contestResourceIsFetching || proposalResourceIsFetching ? (
    <Loader align="center" marginTop={15} />
  ) : contestResourceFetchError ||
    proposalResourceFetchError ||
    contestResource.publicationStatus !== contestPublicationStatuses.CURRENT ||
    proposalResource.applicationStatus !== proposalApplicationStatuses.DRAFT ||
    !contestResource.proposalCollectingDateTo ||
    moment(contestResource.proposalCollectingDateTo).format(DATE_FORMAT) <
      moment().format(DATE_FORMAT) ? (
    <NotFound />
  ) : (
    <Grid container className={classes.container}>
      <Grid item xs={8}>
        <div className={classes.title_wrapper}>
          <div>{translate('T_MODULE_PROPOSALS_EDIT')}</div>
          <div className={classes.title_contest}>{contestResource.title}</div>
        </div>
        {isChange && (
          <>
            <Beforeunload onBeforeunload={event => event.preventDefault()} />
            <Prompt
              when={isChange}
              message={
                'Wprowadzone zmiany mogą nie zostać zapisane. Czy kontynować?'
              }
            />
          </>
        )}
        <ProposalForm
          url={proposalResource['@id']}
          resource={proposalResource}
          contestResource={contestResource}
          definitionSchema={definitions[proposalSchema.resource.definition]}
          customResourceSchema={proposalSchema.resource}
          buttonContainerRef={formButtonsRef}
          autosave={true}
          classes={{
            root: classes.form,
            buttons: classes.form_buttons,
          }}
        >
          {({ state, properties, resource, setValue, setError }) => {
            return (
              <>
                <ProposalSteps
                  isAttachable={contestResource.attachmentsRequired}
                  activeStep={activeStep}
                  handleStepChange={handleStepChange}
                  errors={state.errors}
                />
                <>
                  <TabPanel
                    key={proposalSteps.APPLICANT}
                    index={proposalSteps.APPLICANT}
                    value={proposalSteps.APPLICANT}
                    style={
                      activeStep === proposalSteps.APPLICANT
                        ? {}
                        : { display: 'none' }
                    }
                  >
                    <ApplicantStep
                      contest={contestResource}
                      state={state}
                      properties={properties}
                      resource={resource}
                      setValue={setValue}
                      setError={setError}
                    />
                  </TabPanel>
                  <TabPanel
                    key={proposalSteps.PROJECT}
                    index={proposalSteps.PROJECT}
                    value={proposalSteps.PROJECT}
                    style={
                      activeStep === proposalSteps.PROJECT
                        ? {}
                        : { display: 'none' }
                    }
                  >
                    <ProjectStep
                      contest={contestResource}
                      state={state}
                      properties={properties}
                      resource={resource}
                      setValue={setValue}
                      setError={setError}
                    />
                  </TabPanel>
                  <TabPanel
                    key={proposalSteps.BUDGET}
                    index={proposalSteps.BUDGET}
                    value={proposalSteps.BUDGET}
                    style={
                      activeStep === proposalSteps.BUDGET
                        ? {}
                        : { display: 'none' }
                    }
                  >
                    <BudgetStep
                      contest={contestResource}
                      state={state}
                      properties={properties}
                      resource={resource}
                      setValue={setValue}
                      setError={setError}
                      sidebarNodeRef={sidebarBudgetRef}
                    />
                  </TabPanel>
                  {contestResource.attachmentsRequired && (
                    <TabPanel
                      key={proposalSteps.ATTACHMENTS}
                      index={proposalSteps.ATTACHMENTS}
                      value={proposalSteps.ATTACHMENTS}
                      style={
                        activeStep === proposalSteps.ATTACHMENTS
                          ? {}
                          : { display: 'none' }
                      }
                    >
                      <AttachmentStep
                        contest={contestResource}
                        state={state}
                        properties={properties}
                        resource={resource}
                        setValue={setValue}
                        setError={setError}
                      />
                    </TabPanel>
                  )}
                </>
              </>
            )
          }}
        </ProposalForm>
      </Grid>
      <Grid item xs={4} className={classes.info}>
        <div className={classes.upper_info_buttons}>
          <Delete
            resource={proposalResource}
            accessor="title"
            onSuccess={handleProposalDelete}
            deleteText={translate('T_MODULE_PROPOSALS_DELETE_SKETCH')}
          />
        </div>
        <ContestDetails resource={contestResource} />
        <div ref={onSidebarBudgetRef}></div>
        <div
          ref={onFormButtonsRef}
          className={classes.lower_info_buttons}
        ></div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <ButtonDownload
            text={'pobierz jako pdf'}
            onClick={handlePdfDownlad}
          />
        </div>
      </Grid>
    </Grid>
  )
}
