import React, { useMemo, useCallback, useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  Table,
  TableHead,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  Checkbox,
  IconButton,
  InputBase,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import {
  BodySkeleton,
  TableLoader,
  TableReset,
  FilterBar,
  ManuallyOrderableTableBody,
  ManuallyOrderableTableRow,
  ManualOrderDragHandle,
  ManualOrderPageDropzone,
  OrderBy,
  MassSelectChoice,
  MassSelectCheckbox,
  PaginationActions,
  useCollectionTableStyles,
  collectionTablePropTypes,
} from '.'
import { DefaultCell } from 'core/components/table/cells/DefaultCell'
import {
  prepareColumns,
  prepareFilters,
  prepareSorters,
  handleSorter as deafultHandleSorter,
  performManualOrderChange,
  performManualOrderBetweenPageChange,
  addCustomFilters,
  useCollectionFetch,
  usePageDropzoneRef,
} from './_helpers'
import { applyProperties } from 'core/_helpers/applyProperties'
import { translate } from 'core/_helpers/translate'
import { isObjectEmpty } from 'core/_helpers/isObjectEmpty'
import { constants, reducer } from './_state'
import { ReactComponent as SearchIcon } from 'theme/icons/search.svg'
import { SelectFilter } from 'core/components/table/filters/SelectFilter'
import { TableSaveToCSV } from 'core/components/table/TableSaveToCSV'

export const CollectionTable = ({
  schema,
  definitionSchema,
  endpoint,
  customResourceSchema = {},
  columns,
  defaultSorters,
  defaultFilters,
  customFilters = [],
  autoWidth = true,
  isRowLinkable = false,
  perPage = null,
  perPageOptions = null,
  resetable = true,
  paginable = true,
  filterable = true,
  sortable = true,
  orderable = false,
  numerable = true,
  selectable = [],
  isManualOrderSorterVisible = true,
  isPaginationVisible = true,
  isRowSelectable = true,
  isRowKeyTimestamped = true,
  prepareResources = null,
  storeCollectionId = null,
  editPath = null,
  transparent = false,
  children = null,
  classes = {},
  reload = false,
  filterInline = false,
  setReload = () => false,
  downloadCSV = false,
  downloadCSVFileTitle = 'example',
  saveIndexInPath = false,
  ...rest
}) => {
  const history = useHistory()

  const preparedColumns = useMemo(
    () => prepareColumns(columns, definitionSchema.properties),
    [columns, definitionSchema.properties]
  )

  const preparedFilters = useMemo(
    () => prepareFilters(defaultFilters, preparedColumns, schema.parameters),
    [defaultFilters, preparedColumns, schema.parameters]
  )

  const filters = useMemo(
    () => addCustomFilters(customFilters, preparedFilters, schema.parameters),
    [customFilters, preparedFilters, schema.parameters]
  )

  const sorters = useMemo(
    () =>
      prepareSorters(
        orderable,
        defaultSorters,
        preparedColumns,
        schema.parameters
      ),
    [orderable, defaultSorters, preparedColumns, schema.parameters]
  )

  const [state, dispatch] = useCollectionFetch(
    reducer,
    endpoint,
    filters,
    sorters,
    perPage,
    paginable,
    prepareResources,
    storeCollectionId
  )

  const resourceMergedProperties = useMemo(
    () =>
      applyProperties(
        definitionSchema.properties,
        customResourceSchema.properties
      ),
    [definitionSchema.properties, customResourceSchema.properties]
  )

  const handleFilters = useCallback(
    filters =>
      dispatch({ type: constants.HANDLE_COLUMN_FILTERS, payload: { filters } }),
    [dispatch]
  )

  const handleSorter = useCallback(
    (column, order) => deafultHandleSorter(column, order, orderable, dispatch),
    [dispatch, orderable]
  )

  const [idToPath, setIdToPath] = useState(null)
  const handleSetPageIndex = (pageIndex, id) => {
    dispatch({ type: constants.FETCH_START })
    dispatch({ type: constants.SET_INDEX, payload: { pageIndex } })
    setIdToPath(id)

    // editPath &&
    //   history.push(
    //     editPath.replace(
    //       ':id',
    //       id
    //     )
    //   )
  }

  useEffect(() => {
    console.log('state', state)
    if (idToPath && state.pageIndex && !state.isFetching) {
      // setTimeout(() => {
      editPath && history.push(editPath.replace(':id', idToPath))
    }
    // , 100)
    // }
  }, [state, idToPath, editPath, history])

  useEffect(() => {
    if (reload) {
      dispatch({ type: constants.RESET })
      dispatch({ type: constants.SET_FILTERS, payload: { filters } })
      setReload(false)
    }
  }, [dispatch, filters, handleFilters, reload, setReload])

  const {
    previous: pageDropzonePreviousRef,
    next: pageDropzoneNextRef,
    onPrevious: onPageDropzonePreviousRef,
    onNext: onPageDropzoneNextRef,
  } = usePageDropzoneRef()

  const handleManualOrderSorter = useCallback(
    (column, order) =>
      dispatch({
        type: constants.HANDLE_MANUAL_ORDER_SORTER,
        payload: { order },
      }),
    [dispatch]
  )

  const handleShowManualOrderPageDropzone = useCallback(
    () => dispatch({ type: constants.MANUAL_ORDER_PAGE_DROPZONE_SHOW }),
    [dispatch]
  )

  const handleManualOrderChange = useCallback(
    ({ oldIndex, newIndex }, e) => {
      dispatch({ type: constants.MANUAL_ORDER_PAGE_DROPZONE_HIDE })

      if (oldIndex === newIndex) {
        return
      }

      if ([pageDropzonePreviousRef, pageDropzoneNextRef].includes(e.target)) {
        performManualOrderBetweenPageChange(
          state.data.items,
          oldIndex,
          dispatch,
          state.config.sorters.ord?.order,
          pageDropzoneNextRef === e.target ? 'next' : 'previous'
        )
      } else {
        performManualOrderChange(
          state.data.items,
          state.data.selected,
          oldIndex,
          newIndex,
          dispatch,
          state.config.sorters.ord?.order
        )
      }
    },
    [
      state.data.selected,
      state.data.items,
      state.config.sorters.ord,
      pageDropzonePreviousRef,
      pageDropzoneNextRef,
      dispatch,
    ]
  )

  const handleReset = useCallback(() => {
    dispatch({ type: constants.RESET })
  }, [dispatch])

  const setPage = useCallback(
    (e, page) =>
      dispatch({ type: constants.SET_PAGE, payload: { page: page + 1 } }),
    [dispatch]
  )

  const setPerPage = useCallback(
    e => {
      const perPage = e.target.value
      dispatch({ type: constants.SET_PER_PAGE, payload: { perPage } })
    },
    [dispatch]
  )

  const setSorters = useCallback(
    sorters => dispatch({ type: constants.SET_SORTERS, payload: { sorters } }),
    [dispatch]
  )

  const setFilters = useCallback(
    filters => dispatch({ type: constants.SET_FILTERS, payload: { filters } }),
    [dispatch]
  )

  const handleSingleSelect = useCallback(
    row => () =>
      dispatch({ type: constants.SET_SINGLE_SELECT, payload: { row } }),
    [dispatch]
  )

  const defaultClasses = useCollectionTableStyles()

  const TableBodyComponent =
    orderable && state.config.sorters.ord.order
      ? ManuallyOrderableTableBody
      : TableBody

  const tableBodyComponentProps =
    orderable && state.config.sorters.ord.order
      ? {
          onSortStart: handleShowManualOrderPageDropzone,
          onSortEnd: handleManualOrderChange,
          helperClass: clsx(defaultClasses.draggedRow, classes.draggedRow),
          useDragHandle: true,
        }
      : {}

  const TableRowComponent =
    orderable && state.config.sorters.ord.order
      ? ManuallyOrderableTableRow
      : TableRow

  const isSelectable = !!(selectable && selectable.length)

  const columnsAmount =
    preparedColumns.length +
    (numerable && 1) +
    ((isSelectable || orderable) && 1)

  const [stateFilters, setStateFilters] = useState(state.config.filters)

  const [openFilters, setOpenFilters] = useState(
    Object.keys(state.config.filters).map(el => ({
      [el]: !!state.config.filters[el].value,
    }))
  )

  const [filtersStatus, setFiltersStatus] = useState(false)

  const handleChangeSearch = name => {
    setOpenFilters(prevState => ({
      ...prevState,
      [name]: !prevState[name],
    }))
  }

  const handleStateFilters = (name, value) => {
    setStateFilters(state => ({
      ...state,
      [name]: {
        ...state[name],
        value,
      },
    }))
    setFiltersStatus(true)
  }

  useEffect(() => {
    handleFilters(stateFilters)
  }, [handleFilters, stateFilters])

  return (
    <TableContainer
      className={clsx(defaultClasses.container, classes.container)}
    >
      <TableLoader show={!state.isInit && state.isFetching && !filtersStatus} />
      {downloadCSV && !state.isInit && !state.isFetching && (
        <TableSaveToCSV
          endpoint={endpoint}
          storeCollectionId={storeCollectionId || endpoint}
          columns={preparedColumns}
          fileTitle={downloadCSVFileTitle}
        />
      )}
      <div className={clsx(defaultClasses.toolbar, classes.toolbar)}>
        {resetable && (
          <TableReset
            disabled={state.isInit || state.isFetching}
            handleReset={handleReset}
          />
        )}
        {filterable && !isObjectEmpty(filters) && !filterInline && (
          <FilterBar
            filters={state.config.filters}
            disabled={state.isInit || state.isFetching}
            handleFilters={handleFilters}
          />
        )}
      </div>
      {children &&
        children({
          init: state.init,
          isFetching: state.isFetching,
          sorters: state.config.sorters,
          filters: state.config.filters,
          setSorters,
          setFilters,
        })}
      {orderable && (
        <div
          className={clsx(
            defaultClasses.manualOrderContainer,
            classes.manualOrderContainer
          )}
        >
          {isManualOrderSorterVisible && !numerable && (
            <>
              <span
                className={clsx(
                  defaultClasses.manualOrderContainerTitle,
                  classes.manualOrderContainerTitle
                )}
              >
                {translate('T_GENERAL_MANUAL_ORDER')}
              </span>
              {!state.isInit && !state.isFetching && (
                <OrderBy
                  order={state.config.sorters.ord.order}
                  column="ord"
                  handleSort={handleManualOrderSorter}
                />
              )}
            </>
          )}
          {paginable &&
            state.config.sorters.ord.order &&
            !state.isInit &&
            !state.isFetching && (
              <ManualOrderPageDropzone
                isFirst={state.data.page === 1}
                isLast={
                  state.data.page ===
                  Math.ceil(state.data.totalItems / state.data.perPage)
                }
                previousPageRef={onPageDropzonePreviousRef}
                nextPageRef={onPageDropzoneNextRef}
                hide={!state.showManualOrderPageDropzone}
              />
            )}
        </div>
      )}
      {isSelectable && (
        <MassSelectChoice
          selectable={selectable}
          tableState={state}
          tableStateDispatch={dispatch}
          classes={{
            container: classes.massSelectContainer,
            title: classes.massSelectContainerTitle,
          }}
        />
      )}
      <Table size="medium">
        <TableHead
          className={
            transparent ? defaultClasses.tHeadTransparent : defaultClasses.tHead
          }
        >
          <TableRow>
            {(isSelectable || orderable) && (
              <TableCell
                key="header-mass"
                style={{
                  width: (autoWidth ? 100 / columnsAmount : 5) + '%',
                }}
              >
                <div
                  className={clsx(
                    defaultClasses.massHeadColumn,
                    orderable && defaultClasses.massHeadColumnIsOrderable,
                    classes.massHeadColumn
                  )}
                >
                  {isSelectable && (
                    <MassSelectCheckbox
                      tableState={state}
                      tableStateDispatch={dispatch}
                      isRowSelectable={isRowSelectable}
                    />
                  )}
                </div>
              </TableCell>
            )}
            {numerable && (
              <TableCell
                key="header-number"
                style={{
                  width: (autoWidth ? 100 / columnsAmount : 7) + '%',
                }}
              >
                {translate('T_GENERAL_NO')}
                {orderable &&
                  isManualOrderSorterVisible &&
                  !state.isInit &&
                  !state.isFetching && (
                    <OrderBy
                      order={state.config.sorters.ord.order}
                      column="ord"
                      handleSort={handleManualOrderSorter}
                    />
                  )}
              </TableCell>
            )}
            {preparedColumns.map((column, i) => (
              <TableCell
                key={`header-${i}`}
                style={
                  autoWidth
                    ? { width: 100 / columnsAmount + '%' }
                    : column.width && { width: column.width }
                }
                className={clsx(
                  !state.isInit &&
                    filterable &&
                    filterInline &&
                    column.filterable &&
                    (column.filterName || column.accessor) &&
                    stateFilters[column.filterName || column.accessor] &&
                    defaultClasses.columnHeaderFilterCell,
                  classes.columnHeaderFilterCell
                )}
              >
                <div
                  className={clsx(
                    defaultClasses.columnHeaderFilter,
                    classes.columnHeaderFilter
                  )}
                >
                  {!state.isInit &&
                    filterable &&
                    filterInline &&
                    column.filterable &&
                    (column.filterName || column.accessor) &&
                    stateFilters[column.filterName || column.accessor] && (
                      <>
                        <IconButton
                          onClick={() =>
                            handleChangeSearch(
                              column.filterName || column.accessor
                            )
                          }
                        >
                          <SearchIcon />
                        </IconButton>
                        {(openFilters[column.filterName || column.accessor] ||
                          !!stateFilters[column.filterName || column.accessor]
                            .value) && (
                          <>
                            {column.filterSchema ? (
                              <>
                                <SelectFilter
                                  column={column}
                                  stateFilters={stateFilters}
                                  onChange={handleStateFilters}
                                />
                              </>
                            ) : (
                              <>
                                <InputBase
                                  className={defaultClasses.inputSearch}
                                  autoFocus={true}
                                  inputProps={{
                                    'aria-label':
                                      stateFilters[
                                        column.filterName || column.accessor
                                      ].title,
                                    placeholder: `${translate(
                                      'Wpisz wartość którą szukasz'
                                    )} - ${column.filterName ||
                                      column.accessor}`,
                                  }}
                                  name={
                                    stateFilters[
                                      column.filterName || column.accessor
                                    ].name
                                  }
                                  value={
                                    stateFilters[
                                      column.filterName || column.accessor
                                    ].value || ''
                                  }
                                  onChange={e =>
                                    handleStateFilters(
                                      stateFilters[
                                        column.filterName || column.accessor
                                      ].name,
                                      e.target.value
                                    )
                                  }
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  {(filterable &&
                    filterInline &&
                    column.filterable &&
                    (column.filterName || column.accessor) &&
                    stateFilters[column.filterName || column.accessor] &&
                    (openFilters[column.filterName || column.accessor] ||
                      !!stateFilters[column.filterName || column.accessor]
                        .value)) || (
                    <span
                      style={{ flexGrow: 1 }}
                      className={clsx(
                        column.vertical ? defaultClasses.cellVertical : null
                      )}
                    >
                      {column.header}
                    </span>
                  )}
                  {/*{column.header}*/}
                  {!state.isInit &&
                    !state.isFetching &&
                    sortable &&
                    column.sortable &&
                    (column.sorterName || column.accessor) &&
                    state.config.sorters[
                      column.sorterName || column.accessor
                    ] && (
                      <OrderBy
                        order={
                          state.config.sorters[
                            column.sorterName || column.accessor
                          ].order
                        }
                        column={column.sorterName || column.accessor}
                        handleSort={handleSorter}
                      />
                    )}
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBodyComponent {...tableBodyComponentProps}>
          {state.init ? (
            <BodySkeleton rows={state.config.perPage} columns={columnsAmount} />
          ) : state.data.items.length > 0 ? (
            state.data.items.map((item, i) => {
              const rowAsLink =
                typeof isRowLinkable === 'boolean'
                  ? isRowLinkable
                  : isRowLinkable(item)

              const handleRowClick = (e, index) => {
                if (saveIndexInPath && rowAsLink) {
                  handleSetPageIndex(
                    index,
                    item[process.env.REACT_APP_RESOURCE_ID]
                  )
                } else if (rowAsLink && e.target.tagName === 'TD') {
                  editPath &&
                    history.push(
                      editPath.replace(
                        ':id',
                        item[process.env.REACT_APP_RESOURCE_ID]
                      )
                    )
                }
              }

              return (
                <TableRowComponent
                  onClick={e =>
                    handleRowClick(
                      e,
                      (paginable
                        ? (state.config.page - 1) * state.config.perPage
                        : 0) +
                        i +
                        1
                    )
                  }
                  className={clsx(
                    defaultClasses.row,
                    classes.row,
                    rowAsLink &&
                      clsx(defaultClasses.rowAsLink, classes.rowAsLink)
                  )}
                  index={i}
                  data-index={
                    (paginable
                      ? (state.config.page - 1) * state.config.perPage
                      : 0) +
                    i +
                    1
                  }
                  key={`${item[process.env.REACT_APP_RESOURCE_ID]}${
                    isRowKeyTimestamped ? `-${Date.now()}` : ''
                  }`}
                >
                  {(isSelectable || orderable) && (
                    <TableCell
                      key="header-mass"
                      style={{
                        width: (autoWidth ? 100 / columnsAmount : 5) + '%',
                      }}
                    >
                      <div
                        className={clsx(
                          defaultClasses.massColumn,
                          classes.massColumn
                        )}
                      >
                        {orderable && state.config.sorters.ord.order && (
                          <ManualOrderDragHandle />
                        )}
                        {isSelectable &&
                          (typeof isRowSelectable === 'boolean'
                            ? isRowSelectable
                            : isRowSelectable(item)) && (
                            <Checkbox
                              checked={!!state.data.selected[i]}
                              onChange={handleSingleSelect(i)}
                              name="mass"
                              color="secondary"
                              className={
                                !orderable ||
                                state.config.sorters.ord.order === null
                                  ? defaultClasses.massHeadColumnIsOrderable
                                  : null
                              }
                            />
                          )}
                      </div>
                    </TableCell>
                  )}
                  {numerable && (
                    <TableCell
                      key="column-number"
                      style={{
                        width: (autoWidth ? 100 / columnsAmount : 7) + '%',
                      }}
                    >
                      {orderable && state.data.ord === 'asc'
                        ? state.data.totalItems -
                          ((state.data.page - 1) * state.data.perPage + i)
                        : (state.data.page - 1) * state.data.perPage + i + 1}
                    </TableCell>
                  )}
                  {preparedColumns.map((column, j) => {
                    const CellComponent = column.Cell || DefaultCell

                    return (
                      <TableCell
                        className={clsx(defaultClasses.cell, classes.cell)}
                        key={`column-${j}`}
                        style={
                          autoWidth
                            ? { width: 100 / columnsAmount + '%' }
                            : column.width && { width: column.width }
                        }
                      >
                        <CellComponent
                          resource={item}
                          accessor={column.accessor}
                          propertySchema={
                            resourceMergedProperties?.[column.accessor]
                          }
                          pre={column.pre}
                          tableState={state}
                          tableStateDispatch={dispatch}
                          isRowLinkable={saveIndexInPath ? false : rowAsLink}
                          editPath={editPath}
                          orderable={orderable}
                          {...rest}
                        />
                      </TableCell>
                    )
                  })}
                </TableRowComponent>
              )
            })
          ) : (
            <TableRow>
              <TableCell
                colSpan={columnsAmount}
                className={clsx(
                  defaultClasses.emptyResults,
                  classes.emptyResults
                )}
              >
                {translate('T_TABLE_NO_RESULTS')}
              </TableCell>
            </TableRow>
          )}
        </TableBodyComponent>
        {paginable && isPaginationVisible && (
          <TableFooter>
            <TableRow>
              <TablePagination
                page={state.init ? 0 : state.data.page - 1}
                count={state.data.totalItems}
                onChangePage={setPage}
                onChangeRowsPerPage={setPerPage}
                rowsPerPage={state.data.perPage}
                rowsPerPageOptions={
                  perPageOptions ||
                  process.env.REACT_APP_COLLECTION_PER_PAGE_OPTIONS.split(
                    ','
                  ).map(option => parseInt(option.trim()))
                }
                ActionsComponent={PaginationActions}
              />
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}

CollectionTable.propTypes = collectionTablePropTypes
