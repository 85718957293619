import { makeStyles } from '@material-ui/styles'

export const useContractAttachmentItemTypeStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    marginBottom: 10,
    display: 'flex',
    '&> *:first-child': {
      marginRight: 30
    }
  },
  file_wrapper: {
    marginTop: 28
  }
}))
