import React from 'react'
import { BlankForm } from 'pages/applicant/BlankForm'
import proposalSchema from '_schema/proposal'
import { BudgetForm } from './components/BudgetForm'
import { useBudgetChargesFetch } from '../_helpers/useBudgetChargesFetch'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import { useResourceState } from 'core/_helpers/useResourceState'

export const FormsWrapper = ({
  schema: definitions,
  budgetlUuid,
  localClasses,
}) => {
  const [budgetChargesState, setBudgetChargesState] = useResourceState()

  const {
    resource: budgetCharges,
    isFetching: budgetChargesIsFetching,
    fetchError: budgetChargesFetchError,
  } = budgetChargesState

  useBudgetChargesFetch(
    proposalSchema.endpoint_budget_charges,
    budgetlUuid,
    setBudgetChargesState.resource,
    setBudgetChargesState.fetchError
  )

  return budgetChargesIsFetching ? (
    <Loader align="center" marginTop={15} />
  ) : budgetChargesFetchError ? (
    <NotFound />
  ) : (
    <>
      <BlankForm
        url={`/proposal_budget/charges/${budgetlUuid}`}
        method="PUT"
        resource={budgetCharges}
        definitionSchema={
          definitions[proposalSchema.resource_budget_charges.definition]
        }
        customResourceSchema={proposalSchema.resource_budget_charges}
        submitButtonVariant="outlined"
        classes={{
          root: localClasses.form,
          buttons: localClasses.form_buttons,
        }}
      >
        {({ state, properties, resource, setValue, setError }) => (
          <>
            <BudgetForm
              state={state}
              properties={properties}
              resource={resource}
              setValue={setValue}
              setError={setError}
            />
          </>
        )}
      </BlankForm>
    </>
  )
}
