import React, { useEffect, useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import schema from '_schema/form'
import { Loader } from 'core/components/Loader'
import { Grid, Typography } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import Moment from 'react-moment'
import { SectionGenerateCollection } from 'components/sectionFormGenerate/SectionGenerateCollection'

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(3),
  },
}))

export const Preview = ({ schema: definitions, ...rest }) => {
  const classes = useStyles()
  const { match } = rest

  const [form, setForm] = useState({})

  const url = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  useEffect(() => {
    fetchDataHandleAuthError(url, 'GET', {}, resp => {
      setForm(resp)
    })
  }, [url])

  return (
    <>
      {Object.keys(form).length > 0 ? (
        <>
          <CollectionHeader
            hideButton
            hideButtonBack={false}
            buttonTitleLeft={'Wróć do edycji szablonu'}
            buttonPathLeft={url}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {translate('Podgląd:')} {form.title}
              </Grid>
              <Grid item xs={12} md={3}>
                {form.creator && (
                  <>
                    <Typography variant={'h3'}>
                      {translate('Utworzony')}
                    </Typography>
                    <Typography variant={'body2'}>
                      <Moment
                        format={'DD.MM.YYYY'}
                        date={new Date(form.createdAt)}
                      />{' '}
                      {form.creator.firstName} {form.creator.lastName}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                {form.updater && (
                  <>
                    <Typography variant={'h3'}>
                      {translate('Zmodyfikowany')}
                    </Typography>
                    <Typography variant={'body2'}>
                      <Moment
                        format={'DD.MM.YYYY'}
                        date={new Date(form.updatedAt)}
                      />{' '}
                      {form.updater.firstName} {form.updater.lastName}
                    </Typography>
                  </>
                )}
              </Grid>
            </Grid>
          </CollectionHeader>
          <div className={classes.paper}>
            <SectionGenerateCollection collection={form.formSections} />
          </div>
        </>
      ) : (
        <Loader align={'center'} marginTop={100} size={'3rem'} />
      )}
    </>
  )
}
