import * as React from 'react'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { BlankForm } from 'pages/applicant/BlankForm'
import { FieldWrapper } from 'components/applicant/form'
import { downloadFile } from '_helpers/downloadFile'
import {
  CONTENT_REPORT_STATUS_DRAFT,
  CONTENT_REPORT_STATUS_PENDING,
  CONTENT_REPORT_STATUS_RETURNED,
  CONTENT_REPORT_STATUS_REJECTED,
} from '_schema/proposal'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/proposal'
import routes from '../../routes'
import { Link } from 'react-router-dom'
import { CheckBox } from '@material-ui/icons'
import { useCallback, useEffect } from 'react'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyles = makeStyles(theme => ({
  root: {
    // maxWidth: 400,
    '& .MuiStepper-root': {
      marginBottom: 0,
    },
  },
  buttons: {
    display: 'flex',
    columnGap: theme.spacing(1),
    flexWrap: 'wrap',
  },
  button: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  error: {
    color: theme.palette.error.main,
  },
  returned: {
    marginBottom: 15,
    color: theme.palette.error.main,
  },
}))

export const ReportBudgetSteppers = ({
  match,
  type,
  handleToogle,
  activeReport,
  handleDownload,
  definitions,
  handleRefresh,
}) => {
  const classes = useStyles()
  const handleUploadSuccess = response => {
    handleRefresh()
  }
  const [confirmBudget, setConfirmBudget] = React.useState(!!activeReport)
  const handleChangeConfirmBudget = event => {
    setConfirmBudget(event.target.checked)
  }

  const steps = [
    {
      label: `Uzupełnij zestawienie wydatków i złóż raport finansowy ${
        type === 'FINAL' ? 'końcowy' : 'częściowy'
      }`,
      description: (
        <>
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            label={translate(routes().statement.title)}
            to={routes().statement.path.replace(':id', match.params.id)}
            component={Link}
            disabled={!!activeReport}
          >
            Uzupełnij zestawienie wydatków
          </Button>
          <FormControlLabel
            control={
              <Checkbox
                checked={confirmBudget}
                onChange={handleChangeConfirmBudget}
                name="confirmBudget"
                disabled={!!activeReport}
              />
            }
            style={{ marginTop: 10 }}
            label="Potwierdzam, że zestawienie wydatków jest zgodne z rzeczywistymi wydatkami"
          />
        </>
      ),
    },
    {
      label: ' Pobierz pdf raportu, wydrukuj, a następnie podpisz.',
      description: (
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          size="small"
          onClick={() => handleDownload(activeReport.uuid)}
          disabled={!activeReport}
        >
          pobierz raport {type === 'FINAL' ? 'końcowy' : 'częściowy'} jako pdf
        </Button>
      ),
    },
    {
      label:
        'Zeskanowany raport wgraj do systemu, a oryginał wyślij pocztą na adres fundacji.',
      description: (
        <>
          {!!activeReport && (
            <BlankForm
              url={schema[`endpoint_financialReport_upload_file`].replace(
                ':id',
                activeReport.uuid
              )}
              method="PUT"
              resource={activeReport}
              definitionSchema={
                definitions[
                  schema[`resource_financialReport_upload`].definition
                ]
              }
              customResourceSchema={schema[`resource_financialReport_upload`]}
              handleSuccess={handleUploadSuccess}
              onlyPassedProperties={true}
              submitButtonTitle={translate('Wyślij podpisany skan')}
              submitButtonVariant="contained"
              submitButtonSize="small"
            >
              {({ state, properties, resource, setValue, setError }) => (
                <div>
                  <FieldWrapper
                    name={`uploadedReportFile`}
                    label={translate('Wgraj podpisany raport')}
                    property={properties[`uploadedReportFile`]}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                  />
                </div>
              )}
            </BlankForm>
          )}
        </>
      ),
    },
  ]

  const [activeStep, setActiveStep] = React.useState(0)

  useEffect(() => {
    if (!!activeReport) {
      setActiveStep(1)
    }
  }, [activeReport])

  const handleNext = useCallback(() => {
    if (activeStep === 0 && !activeReport) {
      handleToogle(type)
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1)
    }
  }, [activeStep, activeReport, handleToogle, type])

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Ostatni krok</Typography>
                ) : null
              }
            >
              <Typography variant="h3">{step.label}</Typography>
            </StepLabel>
            <StepContent>
              {step.description}
              <div className={classes.actionsContainer}>
                <div>
                  {index < steps.length - 1 && (
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={handleNext}
                      disabled={index === 0 ? !confirmBudget : false}
                      className={classes.button}
                    >
                      {index === 0 && !activeReport ? 'Złóż raport' : 'Dalej'}
                    </Button>
                  )}
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    className={classes.button}
                    variant="outlined"
                    color="primary"
                    size="small"
                  >
                    Wstecz
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}
