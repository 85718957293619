import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { StringType } from 'components/applicant/form/fields/StringType'
import { useBudgetItemTypeStyles } from './BudgetItemType.styles'
import { IconButton } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import schema from '_schema/proposal'
import { notification } from 'core/_helpers/notification'

export const BudgetItemNewType = ({ disabled, resource, setState }) => {
  const classes = useBudgetItemTypeStyles()

  const [value, setValue] = useState({
    title: '',
    calculationMethod: '',
  })
  const [error, setError] = useState(false)

  const handleChange = (name, value) => {
    setValue(prevState => ({ ...prevState, [name]: value ? value : '' }))
  }

  const handleClick = () => {
    fetchDataHandleAuthError(
      schema.endpoint_budgets,
      'POST',
      {
        body: JSON.stringify({
          ...value,
          ownContributionAmount: 0,
          subsidyAmount: 0,
          proposal: resource.proposal,
        }),
      },
      resp => {
        setState(resp)
        notification(
          'success',
          'T_SUCCESS_BUDGET_NEW_CATEGORY',
          'T_FORM_SUCCESS'
        )
      },
      error => {
        notification(
          'error',
          'T_ERROR_BUDGET_NEW_CATEGORY',
          'Error'
        )
      }
    )
  }

  return (
    <div className={classes.root}>
      <div>
        <StringType
          type="text"
          name="title"
          placeholder={'Nazwa'}
          value={value.title}
          disabled={disabled}
          setValue={handleChange}
          setError={setError}
          classes={{
            root: classes.input,
          }}
        />
      </div>
      <div>
        <StringType
          type="text"
          name="calculationMethod"
          placeholder={'Metoda kalkulacji'}
          value={value.calculationMethod}
          disabled={disabled}
          setValue={handleChange}
          setError={setError}
          classes={{
            root: classes.input,
          }}
        />
      </div>
      <div className={classes.input}>
        <IconButton
          color={'primary'}
          disabled={
            value.title.length === 0 || value.calculationMethod.length === 0
          }
          onClick={handleClick}
        >
          <SaveIcon />
        </IconButton>
      </div>
    </div>
  )
}

BudgetItemNewType.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  setState: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
