import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import { constants } from 'core/components/embedded/_state'
import { ReactComponent as AddIcon } from 'theme/icons/plus.svg'
import { ReactComponent as ArrowIcon } from '../../../theme/icons/arrow-down.svg'

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.elements.embedded_collection.color,
    '&:hover': {
      '& svg': {
        '& circle': {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
  menuButton: {
    fontFamily: ['Sora', 'sans-serif'],
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '26px',
    letterSpacing: '0.05em',
    border: 0,
    boxShadow: 0,
    background: 'none',
    cursor: 'pointer',
    padding: '0 10px',
    color: theme.palette.primary.main,
    transition: 'color 0.2s',
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 12,
      '& path': {
        transition: 'stroke 0.2s',
      },
    },
    '&:hover, &:focus': {
      color: theme.palette.secondary.main,
      '& svg path': {
        stroke: theme.palette.secondary.main,
      },
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      minWidth: '250px',
    },
  },
  select: {
    color: theme.elements.embedded_collection.color,
  },
}))

export const Add = ({ endpoint, parent, types, dispatch, disabled }) => {
  const [type, setType] = useState('null')

  const handleChange = type => {
    const value = type
    setType(value)
    setMenu(null)
  }

  const handleSubmit = () => {
    if (type === 'null') {
      return
    }

    dispatch({ type: constants.FETCH_START })

    fetchDataHandleAuthError(
      endpoint,
      'POST',
      {
        body: JSON.stringify({
          contest: parent,
          type: type.name,
          stat: false,
        }),
      },
      response => {
        dispatch({ type: constants.ADD_ITEM, payload: { value: response } })
        setType('null')

        notification('success', 'T_FORM_RECORD_CREATED', 'T_FORM_SUCCESS')
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        dispatch({ type: constants.FETCH_FAILURE })
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const classes = useStyles()

  const [menu, setMenu] = useState(null)

  const handleOpenClick = event => {
    setMenu(event.currentTarget)
  }

  const handleClose = () => {
    setMenu(null)
  }

  return (
    <>
      <IconButton
        size="small"
        onClick={handleSubmit}
        disabled={disabled}
        className={classes.button}
      >
        <AddIcon />
      </IconButton>
      <button
        className={classes.menuButton}
        aria-controls="block-menu"
        aria-haspopup="true"
        onClick={handleOpenClick}
      >
        <span>
          {type !== 'null'
            ? translate(type.label)
            : translate('T_GENERAL_CHOOSE')}
        </span>
        <ArrowIcon />
      </button>
      <Menu
        id="block-menu"
        keepMounted
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={handleClose}
        className={classes.menu}
      >
        <MenuItem value="null">{translate('T_GENERAL_CHOOSE')}</MenuItem>
        {types.map(typeItem => (
          <MenuItem
            value={typeItem.name}
            key={typeItem.name}
            onClick={() => handleChange(typeItem)}
          >
            {translate(typeItem.label)}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

Add.propTypes = {
  endpoint: PropTypes.string.isRequired,
  parent: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  dispatch: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
