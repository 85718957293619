import { tokenVerify } from './tokenVerify'
import { store } from 'core/_store'

export const authHeader = (method = 'GET') => {
  const token = store.getState().auth.data?.token

  if (tokenVerify(token)) {
    return { Authorization: `Bearer ${token}` }
  }

  return {}
}
