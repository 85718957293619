import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Step, StepButton, Stepper, Typography } from '@material-ui/core'
import { BlankForm } from './BlankForm'
import { translate } from '../_helpers/translate'
import { CustomDialog } from '../components/Dialog'
import { useDispatch, useSelector } from 'react-redux'
import { formSettingsConstans } from '_constans/formSettings.constans'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`form-panel-${index}`}
      aria-labelledby={`form-panel-step-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

export const FormWithStepper = ({
  resource,
  iri,
  collectionPath,
  editPath,
  storeCollectionId,
  customResourcesSchemas,
  fetchCompareResource,
  setResource,
  subForm = null,
  tab = 0,
  buttonSubmitDisabled = false,
  saveStepperDisabled = false,
  readOnly = false,
  removeElementsBeforeSend = [],
  ...rest
}) => {
  const isChange = useSelector(state => state.formSetting.isChange)
  const dispatch = useDispatch()

  const [activeStep, setActiveStep] = useState(tab)
  const [nextActiveStep, setNextActiveStep] = useState(tab)
  // const [isChange, setIsChange] = useState(false)
  const [saveForm, setSaveForm] = useState(false)

  const changeIsChangeStatus = () => {
    dispatch({ type: formSettingsConstans.SET_IS_CHANGE })
    // setIsChange(true)
  }

  const handleStep = step => () => {
    setNextActiveStep(step)
    if (saveStepperDisabled) {
      setActiveStep(step)
    }
  }

  const handleSave = resource => {
    setResource(resource)
    dispatch({ type: formSettingsConstans.RESET_IS_CHANGE })
    // setIsChange(false)
    setSaveForm(false)
    // setActiveStep(nextActiveStep)
  }

  const handleCancel = () => {
    // setIsChange(false)
    dispatch({ type: formSettingsConstans.RESET_IS_CHANGE })
  }

  const handleModalClose = () => {
    setNextActiveStep(activeStep)
  }

  const handleModalConfirm = () => {
    setSaveForm(true)
  }

  useEffect(() => {
    if (!isChange && nextActiveStep !== activeStep) {
      setActiveStep(nextActiveStep)
      window.history.replaceState(null, '', `${iri}/${nextActiveStep}`)
    }
  }, [nextActiveStep, activeStep, isChange, iri])

  const Form = el => (
    <>
      <BlankForm
        subForm={subForm}
        resource={resource}
        iri={iri}
        collectionPath={collectionPath}
        editPath={editPath}
        storeCollectionId={storeCollectionId}
        fetchCompareResource={fetchCompareResource}
        buttonsFixed={true}
        {...rest}
        customResourceSchema={el.schema}
        isChange={changeIsChangeStatus}
        isSubmit={handleSave}
        saveForm={saveForm}
        setSaveForm={() => setSaveForm(false)}
        buttonSubmitDisabled={buttonSubmitDisabled}
        readOnly={readOnly}
        removeElementsBeforeSend={removeElementsBeforeSend}
      />
      {typeof el.children === 'function' ? el.children(resource) : el.children}
    </>
  )
  return (
    <>
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        {customResourcesSchemas.map((el, index) => {
          return (
            <Step key={index}>
              <StepButton
                onClick={handleStep(index)}
                id={`form-panel-step-${index}`}
                aria-controls={`form-panel-${index}`}
              >
                {translate(el.title)}
              </StepButton>
            </Step>
          )
        })}
      </Stepper>
      {customResourcesSchemas.map((el, index) => (
        <TabPanel key={index} index={index} value={activeStep}>
          {Form(el)}
        </TabPanel>
      ))}
      <CustomDialog
        title="Uwaga, nie zapisałeś szkicu!"
        component={() => (
          <Typography variant="body1" color="primary">
            {translate('T_CONTEST_TAB_CHANGE')}
          </Typography>
        )}
        handleToogle={handleModalClose}
        cancelText="Nie zapisuj i przejdź dalej"
        confirmedText="Zapisz i przejdź"
        showActions={true}
        confirmedButton={true}
        onConfirm={handleModalConfirm}
        open={isChange && activeStep !== nextActiveStep}
        onCancel={handleCancel}
      />
    </>
  )
}

FormWithStepper.propTypes = {
  iri: PropTypes.string,
  collectionPath: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  editPath: PropTypes.string,
  storeCollectionId: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  customResourcesSchemas: PropTypes.array,
  fetchCompareResource: PropTypes.bool,
  setResource: PropTypes.func,
  resource: PropTypes.object,
  subForm: PropTypes.func,
  tab: PropTypes.number,
  buttonSubmitDisabled: PropTypes.bool,
  saveStepperDisabled: PropTypes.bool,
  removeElementsBeforeSend: PropTypes.array,
}
