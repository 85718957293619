import React, { useState } from 'react'
import schema, {
  CONTEST_PUBLICATION_STATUS_CURRENT,
  CONTEST_PUBLICATION_STATUS_FINISHED,
} from '_schema/contests'
import { Loader } from 'core/components/Loader'
import { makeStyles } from '@material-ui/styles'
import { StyledTab, StyledTabs } from 'components/StyledTab'
import { translate } from 'core/_helpers/translate'
import { Paper } from 'core/components/Paper'
import { constants, reducer } from 'core/components/table/_state'
import { useCollectionFetch } from 'core/components/table/_helpers'
import { Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import routes from './routes'
import { ReactComponent as IconLink } from 'theme/icons/arrow-link.svg'

const useStyles = makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  wraper: {
    maxWidth: 1500,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  url: {
    display: 'block',
    paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
    position: 'relative',
    borderBottom: `1px solid ${theme.palette.separator}`,
    maxWidth: 541,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    paddingRight: 40,
    '& > span': {
      display: 'block',
    },
    '&:hover': {
      color: theme.palette.secondary.main,
      '& svg path': {
        stroke: theme.palette.secondary.main,
      },
    },
  },
  iconLink: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  titleLink: {
    marginBottom: 6,
  },
}))

const Item = ({ el }) => {
  const classess = useStyles()
  return (
    <Link
      to={routes().collection_proposals.path.replace(':id', el.uuid)}
      className={classess.url}
    >
      <span className={classess.iconLink}>
        <IconLink />
      </span>
      <Typography variant={'subtitle1'} component={'span'}>
        {el?.contestEdition?.title}
      </Typography>
      <Typography
        variant={'h2'}
        component={'span'}
        className={classess.titleLink}
      >
        {el.title}
      </Typography>
      <Typography variant={'subtitle1'} component={'span'}>
        {translate('Liczba przydzielonych projektów')}:{' '}
        {el.expertProposalsCount}
      </Typography>
    </Link>
  )
}

export const Collection = () => {
  const classess = useStyles()
  const endpoint = schema.endpoint_expert_list

  const filters = {
    publicationStatus: {
      name: 'publicationStatus',
      value: CONTEST_PUBLICATION_STATUS_CURRENT,
      title: 'publicationStatus',
      type: 'string',
      in: 'query',
      prop: 'value',
    },
  }

  const [state, dispatch] = useCollectionFetch(
    reducer,
    endpoint,
    filters,
    [],
    null,
    false,
    null,
    endpoint
  )

  const [tab, setTab] = useState(CONTEST_PUBLICATION_STATUS_CURRENT)

  const handleChangeTab = (event, value) => {
    setTab(value)
    dispatch({
      type: constants.HANDLE_COLUMN_FILTERS,
      payload: {
        filters: {
          publicationStatus: {
            name: 'publicationStatus',
            value: value,
            title: 'publicationStatus',
            type: 'string',
            in: 'query',
            prop: 'value',
          },
        },
      },
    })
  }

  return (
    <>
      <StyledTabs
        value={tab}
        onChange={handleChangeTab}
        variant="scrollable"
        scrollButtons="auto"
        className={classess.tabs}
      >
        <StyledTab
          label={translate('Trwające')}
          value={CONTEST_PUBLICATION_STATUS_CURRENT}
        />
        <StyledTab
          label={translate('Zakończone')}
          value={CONTEST_PUBLICATION_STATUS_FINISHED}
        />
      </StyledTabs>
      <Paper withPadding={false}>
        {state.isFetching ? (
          <Loader align="center" marginTop={15} />
        ) : (
          <>
            <Grid container spacing={3} className={classess.wraper}>
              {state.data.items.map(el => (
                <Grid key={el.uuid} item xs={12} md={6}>
                  <Item el={el} />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Paper>
    </>
  )
}
