import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { BudgetItemType } from './BudgetItemType'
import { isBudgetCollectionInvalid } from 'pages/applicant/Proposals/_helpers/isBudgetCollectionInvalid'
import { generateBudgetCollectionRenderError } from 'pages/applicant/Proposals/_helpers/generateBudgetCollectionRenderError'
import { useBudgetCollectionTypeStyles } from './BudgetCollectionType.styles'
import { ReactComponent as Delete } from 'theme/icons/remove.svg'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { translate } from 'core/_helpers/translate'

export const BudgetChargesCollectionType = ({
  name,
  value,
  disabled,
  renderError,
  setValue,
  setError,
  isFormSubmitted,
  extraStuff,
}) => {
  const [state, setState] = useState({
    values: value,
    // eslint-disable-next-line array-callback-return
    errors: value.map(() => {}),
    renderError: [],
    isInvalid: false,
    isFormSubmitted: false,
    init: true,
  })

  const handleAdd = useCallback(() => {
    setState(state => ({
      ...state,
      values: [...state.values, {}],
      errors: [...state.errors, {}],
      renderError: [...state.renderError, {}],
    }))
  }, [setState])

  const handleDelete = useCallback(
    index => () => {
      setState(state => ({
        ...state,
        values: state.values?.filter((item, i) => i !== index),
        errors: state.errors?.filter((item, i) => i !== index),
        renderError: state.renderError?.filter((item, i) => i !== index),
        isInvalid: isBudgetCollectionInvalid(
          state.errors?.filter((item, i) => i !== index)
        ),
      }))
    },
    [setState]
  )

  useEffect(() => {
    setValue(name, state.values, !state.init)
  }, [name, state.values, state.init, setValue])

  useEffect(() => {
    setError(name, state.isInvalid)
  }, [name, setError, state.isInvalid])

  useEffect(() => {
    if (!isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      isFormSubmitted,
    }))
  }, [setState, isFormSubmitted])

  useEffect(() => {
    if (!state.isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      renderError: generateBudgetCollectionRenderError(state.errors, true),
    }))
  }, [setState, state.isFormSubmitted])

  const bruttoAmountSum = state.values.reduce(
    (sum, budget) => sum + (budget.bruttoAmount || 0),
    0
  )

  const nettoAmountSum = state.values.reduce(
    (sum, budget) => sum + (budget.nettoAmount || 0),
    0
  )

  const subsidyAmountSum = state.values.reduce(
    (sum, budget) => sum + (budget.subsidyAmount || 0),
    0
  )

  const classes = useBudgetCollectionTypeStyles()

  return (
    <>
      <div className={classes.root}>
        <div>
          <div className={classes.header}>
            <div className={classes.header_number}></div>
            <div>Numer dokumentu</div>
            <div>Data wystawienia</div>
            <div>Wystawca dokumentu</div>
            <div>Kwota netto</div>
            <div>Kwota brutto</div>
            <div>Kwota rozliczona z dotacji</div>
            <div>Załącznik z dokumentacją</div>
          </div>
          {state.values.map((item, index) => (
            <div className={classes.container} key={index}>
              <BudgetItemType
                index={index}
                values={state.values?.[index]}
                errors={state.errors[index]}
                renderError={state.renderError[index]}
                globalRenderError={renderError}
                setState={setState}
                disabled={disabled}
                extraStuff={extraStuff}
                state={state}
              />
              <div className={classes.sidebar}>
                <Delete onClick={handleDelete(index)} />
              </div>
            </div>
          ))}
        </div>
        <div style={{ overflow: 'visible' }}>
          {state.values.length > 0 && (
            <div className={classes.header}>
              <div className={classes.header_number}></div>
              <div style={{ fontWeight: 'bold' }}>RAZEM</div>
              <div></div>
              <div></div>
              <div>
                {Number(nettoAmountSum).toFixed(2)}{' '}
                {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
              </div>
              <div>
                {Number(bruttoAmountSum).toFixed(2)}{' '}
                {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
              </div>
              <div style={{ fontWeight: 'bold', fontSize: 16 }}>
                {Number(subsidyAmountSum).toFixed(2)}{' '}
                {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
              </div>
              <div></div>
            </div>
          )}
        </div>
        <ButtonPlus
          text={translate('T_MODULE_PROPOSALS_ADD_BUDGET')}
          disabled={disabled}
          onClick={handleAdd}
        />
      </div>
    </>
  )
}

BudgetChargesCollectionType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  renderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
}
