import React from 'react'
import { Route } from 'react-router-dom'
import { Collection } from './Collection'
import { roles } from '_helpers/roles'
import {
  CurrentBudget,
  StatementBudget,
  ChangesBudget,
  ReportBudget,
  DeclarationBudget,
} from './Budget'
import { LeadTime, Details, DateOfSubmission } from './ProjectSchedule'
import { Relations, RelationsItem } from './Relations'
import { PartialReport } from './PartialReport'
import { FinalReport } from './FinalReport'
import { Visit } from './Visit'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { View } from 'pages/applicant/InProgressProposals/View'

const routes = () => {
  return {
    index: {
      title: 'T_MODULE_PROPOSALS_APPLICATION_STATUS_INPROGRESS',
      type: Route,
      render: props => <Collection {...props} />,
      path: '/proposals/inprogress',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    view: {
      type: Route,
      render: props => (
        <SchemableComponent path="definitions" component={View} {...props} />
      ),
      exact: true,
      resourceAccess: [roles.APPLICANT],
      path: '/proposals/inprogress/:id',
    },
    budget: {
      title: 'T_MODULE_PROPOSALS_CURRENT_BUDGET',
      type: Route,
      render: props => <CurrentBudget {...props} />,
      path: '/proposals/inprogress/:id/budget/current',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    statement: {
      title: 'T_MODULE_PROPOSALS_STATEMENT_BUDGET',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={StatementBudget}
          {...props}
        />
      ),
      path: '/proposals/inprogress/:id/budget/statement',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    changes: {
      title: 'T_MODULE_PROPOSALS_CHANGES_BUDGET',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={ChangesBudget}
          {...props}
        />
      ),
      path: '/proposals/inprogress/:id/budget/changes',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    report: {
      title: 'T_MODULE_PROPOSALS_REPORT_BUDGET',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={ReportBudget}
          {...props}
        />
      ),
      // render: props => <ReportBudget {...props} />,
      path: '/proposals/inprogress/:id/budget/report',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    declaration: {
      title: 'T_MODULE_PROPOSALS_DECLARATION_BUDGET',
      type: Route,
      render: props => <DeclarationBudget {...props} />,
      path: '/proposals/inprogress/:id/budget/declaration',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    leadtime: {
      title: 'T_MODULE_PROPOSALS_SCHEDULE_LEAD_TIME',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={LeadTime}
          {...props}
        />
      ),
      path: '/proposals/inprogress/:id/schedule/leadtime',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    details: {
      title: 'T_MODULE_PROPOSALS_SCHEDULE_DETAILS',
      type: Route,
      render: props => (
        <SchemableComponent path="definitions" component={Details} {...props} />
      ),
      path: '/proposals/inprogress/:id/schedule/details',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    dateofsubmission: {
      title: 'T_MODULE_PROPOSALS_SCHEDULE_DETAILS_DATE_OF_SUBMISSION',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={DateOfSubmission}
          {...props}
        />
      ),
      path: '/proposals/inprogress/:id/schedule/dateofsubmission',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    relations: {
      title: 'T_MODULE_PROPOSALS_RELATIONS',
      type: Route,
      render: props => <Relations {...props} />,
      path: '/proposals/inprogress/:id/relations',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    relationsItemAdd: {
      title: 'T_MODULE_PROPOSALS_RELATIONS_ADD',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={RelationsItem}
          {...props}
        />
      ),
      path: '/proposals/inprogress/:id/relations/add',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    relationsItemEdit: {
      title: 'T_MODULE_PROPOSALS_RELATIONS_EDIT',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={RelationsItem}
          {...props}
        />
      ),
      path: '/proposals/inprogress/:id/relations/:itemId',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    partialReport: {
      title: 'T_MODULE_PROPOSALS_PARTIAL_REPORT',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={PartialReport}
          {...props}
        />
      ),
      path: '/proposals/inprogress/:id/partial-report',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    finalReport: {
      title: 'T_MODULE_PROPOSALS_FINAL_REPORT',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={FinalReport}
          {...props}
        />
      ),
      path: '/proposals/inprogress/:id/final-report',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
    visit: {
      title: 'T_MODULE_PROPOSALS_VISIT',
      type: Route,
      render: props => (
        <SchemableComponent path="definitions" component={Visit} {...props} />
      ),
      path: '/proposals/inprogress/:id/visit',
      exact: true,
      resourceAccess: [roles.APPLICANT],
    },
  }
}

export default routes
