import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  AppBar,
  Typography,
  FormControl,
  InputLabel,
  FormHelperText,
} from '@material-ui/core'
import { Logo } from 'core/components/Logo'
import { translate } from 'core/_helpers/translate'
import { authActions } from 'core/_actions'
import { validate } from 'email-validator'
import { boolean } from 'core/_helpers/boolean'
import { useStyles, CustomInput } from '.'
import { Footer } from '../../components/footer/Footer'

export const Login = () => {
  const [state, setState] = useState({
    email: '',
    password: '',
    _remember_me: false,
    submitted: false,
  })
  const dispatch = useDispatch()

  const handleChange = useCallback(e => {
    const { name, value } = e.target
    setState(prevState => ({
      ...prevState,
      [name]: name === '_remember_me' ? Boolean(value) : value,
    }))
  }, [])

  const handleSubmit = useCallback(
    e => {
      e.preventDefault()
      setState(prevState => ({ ...prevState, submitted: true }))
      const { email, password, _remember_me } = state

      if (validate(email) && password) {
        dispatch(authActions.login(email, password, _remember_me))
      }
    },
    [dispatch, state]
  )

  const isLogging = useSelector(state => state.auth.isLogging)
  const { email, password, submitted } = state

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Logo className={classes.logo} />
      <Card className={classes.card}>
        <AppBar position="static" className={classes.login_app_bar}>
          <div className={classes.login_menu}>
            <Typography
              variant="h1"
              className={classes.login_title}
              color="secondary"
            >
              <span>{translate('T_GENERAL_LOGIN')}</span>
            </Typography>
            <Link to="/create_account" className={classes.login_link}>
              <Typography variant="h1" component="span" color="primary">
                {translate('T_GENERAL_CREATE_ACCOUNT')}
              </Typography>
            </Link>
          </div>
        </AppBar>

        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <div className={classes.form_inner}>
            <FormControl
              error={submitted && !validate(email)}
              className={classes.form_control}
            >
              <InputLabel
                shrink={false}
                htmlFor="login"
                className={classes.form_label}
              >
                {translate('T_FORM_FIELD_EMAIL')}
              </InputLabel>
              <CustomInput
                value={email}
                id="login"
                onChange={handleChange}
                aria-describedby="error-login"
                {...emailInputConfig()}
              />
              <FormHelperText id="error-login">
                {submitted && !validate(email)
                  ? translate('T_VALIDATION_EMAIL')
                  : null}
              </FormHelperText>
            </FormControl>
            <FormControl
              error={submitted && !password}
              className={classes.form_control}
            >
              <InputLabel
                shrink={false}
                htmlFor="pass"
                className={classes.form_label}
              >
                {translate('T_FORM_FIELD_PASSWORD')}
              </InputLabel>
              <CustomInput
                value={password}
                id="pass"
                aria-describedby="error-pass"
                onChange={handleChange}
                {...passwordInputConfig()}
              />
              <FormHelperText id="error-pass">
                {submitted && !password
                  ? translate('T_VALIDATION_REQUIRED')
                  : null}
              </FormHelperText>
            </FormControl>
            {boolean(process.env.REACT_APP_RESET_PASSWORD_SHOW) ? (
              <Link to="/reset-password" className={classes.lower_link}>
                {translate('T_GENERAL_FORGOT_PASSWORD')}
              </Link>
            ) : null}
          </div>
          <Button
            disabled={isLogging}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            {isLogging ? (
              <img
                className={classes.spinner}
                src="/img/spinner-white.svg"
                alt="loading"
              />
            ) : (
              translate('T_GENERAL_LOGIN')
            )}
          </Button>
        </form>
      </Card>
      <Footer />
    </div>
  )
}

const emailInputConfig = () => ({
  required: true,
  id: 'email',
  name: 'email',
  label: translate('T_FORM_FIELD_EMAIL'),
  autoComplete: 'email',
  autoFocus: true,
})

const passwordInputConfig = () => ({
  required: true,
  id: 'password',
  name: 'password',
  type: 'password',
  label: translate('T_FORM_FIELD_PASSWORD'),
  autoComplete: 'current-password',
})
