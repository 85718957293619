import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Card,
  Typography,
  AppBar,
  InputLabel,
  FormHelperText,
  FormControl,
} from '@material-ui/core'
import { Logo } from 'core/components/Logo'
import { validators } from 'core/_helpers/validators'
import { translate } from 'core/_helpers/translate'
import { CustomInput, useStyles } from '.'
import clsx from 'clsx'
import { Footer } from '../../components/footer/Footer'

export const ResetPasswordChange = ({ match }) => {
  const [state, setState] = useState({
    password: null,
    passwordRepeat: null,
    passwordErrorMessage: translate('T_VALIDATION_PASSWORD'),
    passwordRepeatErrorMessage: null,
    errorMessage: null,
    isSubmitted: false,
    isInvalid: true,
    isFetching: false,
    isCompleted: false,
  })

  const handleChange = {
    password: e => {
      const password = getValue(e.target.value)
      setState(state => ({
        ...state,
        password,
      }))
      validate(password, state.passwordRepeat)
    },
    passwordRepeat: e => {
      const passwordRepeat = getValue(e.target.value)
      setState(state => ({
        ...state,
        passwordRepeat,
      }))
      validate(state.password, passwordRepeat)
    },
  }

  const validate = (password, passwordRepeat) => {
    const isPasswordValid =
      validators.required(password).result &&
      validators.password(password).result

    setState(state => ({
      ...state,
      passwordErrorMessage: !isPasswordValid
        ? translate('T_VALIDATION_PASSWORD')
        : null,
    }))

    const isPasswordRepeatValid = password === passwordRepeat

    setState(state => ({
      ...state,
      passwordRepeatErrorMessage: !isPasswordRepeatValid
        ? translate('T_VALIDATION_PASSWORDS_DO_NOT_MATCH')
        : null,
      isInvalid: !isPasswordValid || !isPasswordRepeatValid,
    }))
  }

  const url = `${process.env.REACT_APP_API_ENTRYPOINT}/security/${match.params.id}/resetPassword/${match.params.hash}`

  const handleSubmit = e => {
    e.preventDefault()

    setState(state => ({
      ...state,
      isSubmitted: true,
    }))

    if (state.isInvalid) {
      return
    }

    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ plainPassword: password }),
    })
      .then(response =>
        response.text().then(text =>
          text
            ? JSON.parse(text)
            : {
                success: false,
                errorMessage: translate('Error'),
              }
        )
      )
      .then(data => {
        if (data['hydra:description'] !== undefined) {
          setState(state => ({
            ...state,
            errorMessage: translate(data['hydra:description']),
            isFetching: false,
          }))

          return
        }

        setState(state => ({
          ...state,
          isFetching: false,
          isCompleted: true,
        }))
      })
  }

  const {
    password,
    passwordRepeat,
    passwordErrorMessage,
    passwordRepeatErrorMessage,
    errorMessage,
    isSubmitted,
    isFetching,
    isCompleted,
  } = state

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Logo className={classes.logo} />
      <Card className={classes.card}>
        <AppBar position="static" className={classes.login_app_bar}>
          <div className={clsx(classes.login_menu, classes.login_menu_title)}>
            <Typography
              variant="h1"
              className={clsx(classes.login_title, classes.login_title_full)}
              color="secondary"
            >
              <span>{translate('T_GENERAL_NEW_PASSWORD_TITLE')}</span>
            </Typography>
          </div>
        </AppBar>
        {isCompleted ? (
          <div className={classes.form_message}>
            <div className={classes.reset_message}>
              {translate('T_GENERAL_NEW_PASSWORD_RESETTED')}
            </div>
            <Link to="/login" className={classes.lower_link}>
              {translate('T_GENERAL_BACK_TO_LOGIN')}
            </Link>
          </div>
        ) : (
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            {errorMessage && (
              <div className={classes.new_password_error}>{errorMessage}</div>
            )}
            <div className={classes.form_inner}>
              <FormControl
                error={isSubmitted && !!passwordErrorMessage}
                className={classes.form_control}
              >
                <InputLabel
                  shrink={false}
                  htmlFor="pass"
                  className={classes.form_label}
                >
                  {translate('T_GENERAL_NEW_PASSWORD_TYPE_NEW')}
                </InputLabel>
                <CustomInput
                  value={password || ''}
                  id="pass"
                  type="password"
                  required={true}
                  onChange={handleChange.password}
                  aria-describedby="error-pass"
                />
                <FormHelperText id="error-pass">
                  {isSubmitted && passwordErrorMessage
                    ? passwordErrorMessage
                    : null}
                </FormHelperText>
              </FormControl>
              <FormControl
                error={isSubmitted && !!passwordRepeatErrorMessage}
                className={classes.form_control}
              >
                <InputLabel
                  shrink={false}
                  htmlFor="repeat"
                  className={classes.form_label}
                >
                  {translate('T_GENERAL_NEW_PASSWORD_RETYPE_NEW')}
                </InputLabel>
                <CustomInput
                  value={passwordRepeat || ''}
                  id="repeat"
                  type="password"
                  required={true}
                  onChange={handleChange.passwordRepeat}
                  aria-describedby="error-repeat"
                />
                <FormHelperText id="error-repeat">
                  {isSubmitted && passwordRepeatErrorMessage
                    ? passwordRepeatErrorMessage
                    : null}
                </FormHelperText>
              </FormControl>
              <Link to="/login" className={classes.lower_link}>
                {translate('T_GENERAL_BACK_TO_LOGIN')}
              </Link>
            </div>
            <Button
              disabled={isFetching}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {isFetching ? (
                <img
                  className={classes.spinner}
                  src="/img/spinner-white.svg"
                  alt="loading"
                />
              ) : (
                translate('T_GENERAL_NEW_PASSWORD_CHANGE')
              )}
            </Button>
          </form>
        )}
      </Card>
      <Footer />
    </div>
  )
}

const getValue = value => (value === '' ? null : value)
