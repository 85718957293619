import { makeStyles } from '@material-ui/styles'

export const useViewStyles = makeStyles(theme => ({
  back_to_list: {
    paddingTop: 60,
    paddingLeft: 85,
    paddingRight: 85,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 17,
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&> :first-child': {
      marginRight: 10
    }
  },
  steps_container: {
    width: 'auto',
    marginTop: 60,
    marginLeft: 82,
    marginRight: 82,
  },
}))
