import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { FieldWrapper } from 'components/applicant/form'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    marginBottom: 22,
  },
}))

export const TrainingForm = ({
  contest,
  state,
  properties,
  resource,
  setValue,
  setError,
  proposalState,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      {!!contest.proposalTrainingFormValues?.formSections?.length && (
        <FieldWrapper
          name="proposalTrainingFormValues"
          property={properties.proposalTrainingFormValues}
          resource={resource}
          state={state}
          setValue={setValue}
          setError={setError}
          contest={contest}
          proposal={proposalState.proposal}
          sectionsMapping={contest.proposalTrainingFormValues.formSections}
        />
      )}
    </div>
  )
}

TrainingForm.propTypes = {
  contest: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  proposalState: PropTypes.object,
}
