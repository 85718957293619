import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { NotFound } from 'core/pages/NotFound'
import { Loader } from 'core/components/Loader'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { OrganizationDataForm } from 'components/organizationData/components'
import { translate } from 'core/_helpers/translate'
import schema from 'core/_schema/user'

const useStyles = makeStyles(theme => ({
  root: {
    padding: 60,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 24,
    fontWeight: 'bold',
  },
}))

export const OrganizationData = ({ schema: definitions }) => {
  const profile = useSelector(state => state.profile)

  const iri = `${schema('user').endpoint}/${profile.uuid}`

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError
  )

  const classes = useStyles()

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <div className={classes.root}>
      <div className={classes.title}>
        {translate('T_MODULE_ORGANIZATION_DATA')}
      </div>
      <OrganizationDataForm
        url={`${schema('applicant_edit').endpoint}/${profile.uuid}`}
        resetUrl={`${schema('change_email').endpoint}/${profile.uuid}`}
        method="PUT"
        resource={resource}
        definitionSchema={
          definitions[schema('applicant_edit').resource.definition]
        }
        customResourceSchema={schema('applicant_edit').resource}
      />
    </div>
  )
}
