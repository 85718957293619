import { makeStyles } from '@material-ui/styles'

export const useFormTemplateSectionTypeStyles = makeStyles(theme => ({
  root: {
    marginTop: 60,
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  block: {
    marginTop: 15,
  },
  sidebar: {
    display: 'flex',
    marginTop: 35,
    alignItems: 'flex-start',
    '&> *': {
      cursor: 'pointer',
    },
  },
  blockMultiDiv: {
    display: 'flex',
    marginTop: 20,
    marginBottom: 5,
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& hr': {
      flex: 1,
      marginLeft: 20,
      marginRight: 20,
      maxWidth: 500,
    },
    '& svg': {
      cursor: 'pointer',
    },
  },
}))
