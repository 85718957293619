import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { FieldWrapper } from 'components/applicant/form'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    marginBottom: 22
  }
}))

export const ProjectStep = ({
  contest,
  state,
  properties,
  resource,
  setValue,
  setError
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <FieldWrapper
        name="title"
        label={translate('T_MODULE_PROPOSALS_PROJECT_TITLE')}
        property={properties.title}
        resource={resource}
        state={state}
        setValue={setValue}
        setError={setError}
      />
      {!!contest.applicationProjectForm?.formSections?.length && (
        <FieldWrapper
          name="applicationProjectFormValues"
          property={properties.applicationProjectFormValues}
          resource={resource}
          state={state}
          setValue={setValue}
          setError={setError}
          contest={contest}
          proposal={resource}
          sectionsMapping={contest.applicationProjectForm.formSections}
        />
      )}
    </div>
  )
}

ProjectStep.propTypes = {
  contest: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}
