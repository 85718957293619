import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Title } from 'core/components/Title'
import { makeStyles } from '@material-ui/styles'
import { PageTitleCustomBar } from './bar/page-title/PageTitleCustomBar'
import { ButtonRight } from './buttons/button-right'
import { ButtonLeft } from './buttons/button-left'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  buttons: {
    '&> *': {
      marginRight: 10,
    },
    '&> *:last-child': {
      marginRight: 0,
    },
  },
  buttonTitle: {
    marginLeft: '.7em',
  },
  fullWidth: {
    width: '100%',
    position: 'sticky',
    top: 65,
    backgroundColor: theme.elements.background,
    zIndex: 1,
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}))

export const CollectionHeader = ({
  children,
  buttonTitle,
  buttonPath,
  buttonPathLeft = null,
  buttonTitleLeft = null,
  hideButton = false,
  hideButtonBack = true,
  additionalButtons = null,
  classes = {},
  small = false,
}) => {
  const defaultClasses = useStyles()
  return (
    <>
      {!hideButtonBack && (
        <div className={defaultClasses.fullWidth}>
          <ButtonLeft href={buttonPathLeft} text={buttonTitleLeft} />
        </div>
      )}
      <PageTitleCustomBar className={clsx(defaultClasses.root, classes.root)}>
        <Title variant={small ? 'h2' : 'h1'}>{children}</Title>
        {!hideButton && (
          <div className={clsx(defaultClasses.buttons, classes.buttons)}>
            <ButtonRight href={buttonPath} text={buttonTitle} />
            {additionalButtons}
          </div>
        )}
      </PageTitleCustomBar>
    </>
  )
}

CollectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
  buttonTitle: PropTypes.string,
  buttonTitleLeft: PropTypes.string,
  buttonPath: PropTypes.string,
  buttonPathLeft: PropTypes.string,
  hideButton: PropTypes.bool,
  additionalButtons: PropTypes.node,
  classes: PropTypes.shape({
    root: PropTypes.string,
    buttons: PropTypes.string,
    buttonTitle: PropTypes.string,
  }),
  hideButtonBack: PropTypes.bool,
  small: PropTypes.bool,
}
