import { makeStyles, fade } from '@material-ui/core/styles'

export const useCollectionTableStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  row: {
    backgroundColor: theme.elements.table.odd,
    borderBottom: `1px solid ${theme.palette.separator}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.lighting, 1),
    },
  },
  rowAsLink: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: fade(theme.palette.primary.main, 0.1),
    },
  },
  cell: {
    border: 0,
    color: theme.palette.primary.main,
    fontSize: 16,
  },
  cellVertical: {
    transform: 'rotate(-80deg)',
    display: 'block',
    whiteSpace: 'nowrap',
    transformOrigin: '0 100%',
    position: 'absolute',
    marginLeft: '20px',
  },
  emptyResults: {
    textAlign: 'center',
  },
  manualOrderContainer: {
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    fontFamily: theme.typography.fontFamily,
    margin: 8,
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    textAlign: 'left',
  },
  manualOrderContainerTitle: {
    marginRight: 8,
  },
  draggedRow: {
    display: 'block',
    background: theme.elements.table.odd,
    '& td': {
      borderBottom: 'none',
    },
  },
  massHeadColumn: {
    display: 'flex',
  },
  massHeadColumnIsOrderable: {
    marginLeft: 24,
  },
  massColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  tHead: {
    backgroundColor: theme.elements.table.tHead,
    borderBottom: `1px solid ${theme.palette.separator}`,
    '& .MuiTableCell-head': {
      color: theme.elements.table.tHeadC,
      fontSize: 16,
      fontStyle: 'italic',
      border: 0,
      paddingBottom: theme.spacing(3),
    },
  },
  tHeadTransparent: {
    '& .MuiTableCell-head': {
      fontWeight: 700,
    },
  },
  columnHeaderFilter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  columnHeaderFilterCell: {
    paddingLeft: 0,
  },
  inputSearch: {
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    paddingLeft: 4,
    '& input': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& input::placeholder': {
      color: theme.palette.primary.main,
      fontStyle: 'italic',
      opacity: 1,
    },
  },
}))
