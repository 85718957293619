import React from 'react'
import PropTypes from 'prop-types'
import { FieldWrapper } from 'components/applicant/form'
import { ProposalRelationForm } from './ProposalRelationForm'
import { makeStyles } from '@material-ui/styles'
import schema from '_schema/proposalRelation'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
  },
  root: {
    width: 'fit-content',
    marginTop: 30,
  },
}))

export const MainForm = ({
  iri = null,
  resource = null,
  definitionSchema,
  customResourceSchema,
  ...rest
}) => {
  const localClasses = useStyles()

  return (
    <ProposalRelationForm
      url={iri || schema.endpoint}
      method={iri ? 'PUT' : 'POST'}
      resource={resource}
      definitionSchema={definitionSchema}
      customResourceSchema={customResourceSchema}
      classes={{
        root: localClasses.root,
      }}
      {...rest}
    >
      {({ state, properties, resource, setValue, setError }) => (
        <div className={localClasses.wrapper}>
          <div>
            <FieldWrapper
              label="Tytuł relacji"
              name="title"
              property={properties.title}
              resource={resource}
              state={state}
              setValue={setValue}
              setError={setError}
            />
            <FieldWrapper
              label="Treść"
              name="description"
              property={properties.description}
              resource={resource}
              state={state}
              setValue={setValue}
              setError={setError}
            />
            <FieldWrapper
              label="opcjonalny link do materiałów"
              name="link"
              property={properties.link}
              resource={resource}
              state={state}
              setValue={setValue}
              setError={setError}
            />
          </div>
          <div>
            <div>
              <FieldWrapper
                label=""
                name="attachments"
                property={properties.attachments}
                resource={resource}
                state={state}
                setValue={setValue}
                setError={setError}
                relation={resource}
              />
            </div>
            <div>
              <FieldWrapper
                label="Zgadzam się na publikację"
                name="isPublicationAccepted"
                property={properties.isPublicationAccepted}
                resource={resource}
                state={state}
                setValue={setValue}
                setError={setError}
              />
            </div>
          </div>
        </div>
      )}
    </ProposalRelationForm>
  )
}

MainForm.propTypes = {
  iri: PropTypes.string,
  resource: PropTypes.object.isRequired,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
}
