import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
    alignItems: 'center',
  },
  center: {
    justifyContent: 'center',
  },
  left: {
    justifyContent: 'left',
  },
  right: {
    justifyContent: 'right',
  },
}))

export const Loader = ({
  align,
  color = 'primary',
  marginTop = null,
  marginBottom = null,
  size = '1.5rem',
  disableShrink = false,
  classes = {},
  ...rest
}) => {
  const defaultClasses = useStyles()

  return (
    <div
      className={clsx(defaultClasses.root, defaultClasses[align] || null, classes.root)}
      style={{ marginTop, marginBottom }}
      {...rest}
    >
      <CircularProgress
        size={size}
        color={color}
        disableShrink={disableShrink}
      />
    </div>
  )
}

Loader.propTypes = {
  align: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
  marginTop: PropTypes.number,
  marginBottom: PropTypes.number,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableShrink: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string
  })
}
