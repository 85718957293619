import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { FormHelperText, IconButton } from '@material-ui/core'
import { AddAPhotoOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
import { CustomDialog } from '../../../Dialog'

const useStyles = makeStyles(theme => ({
  dialogContent: {
    textAlign: 'center',
  },
  chosenFile: {
    color: theme.palette.text.secondary,
    marginLeft: 3,
  },
  fileButton: {
    marginTop: 10,
  },
  sendButton: {},
}))

export const UploadDialog = ({
  name,
  type = 'file',
  hint = null,
  endpoint,
  disabled = false,
  validators,
  setValue,
  setUpdatedAt,
  formUrl,
  formMethod,
  formProcessResponse = null,
  accept = null,
  isOpen,
  handleToggle,
  classes = {},
  customUploadSuccess = null,
}) => {
  const [state, setState] = useState({
    value: null,
    error: false,
    isFetching: false,
  })
  const fileInputRef = useRef(null)

  const handleInputClick = () => {
    fileInputRef.current.click()
  }

  const handleInputChange = e => {
    const value = e.target.files[0]
    setState(state => ({
      ...state,
      value,
    }))
    validateField(value)
  }

  const handleSend = () => {
    if (!state.value) {
      return
    }

    const formData = new FormData()
    formData.append('file', state.value)

    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetchDataHandleAuthError(
      endpoint,
      'POST',
      { body: formData },
      response => {
        const resource = response

        if (!['PUT', 'PATCH'].includes(formMethod)) {
          uploadSuccess(resource)

          return
        }

        fetchDataHandleAuthError(
          formUrl,
          'PUT',
          { body: JSON.stringify({ [name]: resource }) },
          response => {
            uploadSuccess(resource)

            formProcessResponse && formProcessResponse(response)

            if (!response.updatedAt) {
              return
            }

            setUpdatedAt(response.updatedAt)
          },
          error => {
            uploadFailure(error)
          }
        )
      },
      error => {
        uploadFailure(error)
      },
      { 'Content-Type': 'multipart/form-data' }
    )
  }

  const uploadSuccess = resource => {
    setState({
      value: null,
      error: false,
      isFetching: false,
    })

    setValue(resource)

    if (typeof customUploadSuccess === 'function') {
      customUploadSuccess(resource)
    } else {
      notification('success', 'T_FORM_RECORD_CREATED', 'T_FORM_SUCCESS')
    }

    handleToggle()
  }

  const uploadFailure = error => {
    if (error.response.title === 'AbortError') {
      return
    }

    setState(state => ({
      ...state,
      isFetching: false,
    }))

    notification('error', error.response.detail, error.response.title)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setState(state => ({
          ...state,
          error: false,
        }))

        return
      }

      const valid = validate(validators, value)

      setState(state => ({
        ...state,
        error: !valid.result && valid.message,
      }))
    },
    [validators]
  )

  const defaultClasses = useStyles()

  const DialogComponent = () => (
    <div className={clsx(defaultClasses.dialogContent, classes.dialogContent)}>
      <div>
        {translate('T_GENERAL_CHOSEN_FILE')}:
        <span className={clsx(defaultClasses.chosenFile, classes.chosenFile)}>
          {state.value ? state.value.name : translate('T_GENERAL_NONE')}
        </span>
      </div>
      <IconButton
        color="primary"
        component="span"
        onClick={handleInputClick}
        className={clsx(defaultClasses.fileButton, classes.fileButton)}
        disabled={state.isFetching || disabled}
      >
        <AddAPhotoOutlined />
      </IconButton>
      <FormHelperText error={!!state.error} disabled={disabled}>
        {translate(state.error || hint)}
      </FormHelperText>
      <input
        type="file"
        name="file"
        ref={fileInputRef}
        accept={
          accept || (type === 'image' ? 'image/jpg,image/jpeg,image/png' : '*')
        }
        style={{ display: 'none' }}
        onChange={handleInputChange}
      />
    </div>
  )

  return (
    <>
      <CustomDialog
        component={DialogComponent}
        handleToogle={handleToggle}
        open={isOpen}
        title={translate('T_GENERAL_UPLOAD')}
        confirmedButton={true}
        confirmedText={translate('T_GENERAL_SEND')}
        isFetching={state.isFetching}
        onConfirm={handleSend}
      />
    </>
  )
}

UploadDialog.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['file', 'image']),
  hint: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setUpdatedAt: PropTypes.func.isRequired,
  formUrl: PropTypes.string.isRequired,
  formMethod: PropTypes.string.isRequired,
  formProcessResponse: PropTypes.func,
  accept: PropTypes.string,
  isOpen: PropTypes.bool,
  handleToggle: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    dialogContent: PropTypes.string,
    chosenFile: PropTypes.string,
    fileButton: PropTypes.string,
    sendButton: PropTypes.string,
  }),
  customUploadSuccess: PropTypes.func,
}
