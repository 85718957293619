import { makeStyles } from '@material-ui/styles'

export const useOrganizationDataFormStyles = makeStyles(theme => ({
  root: {
    marginTop: 60,
    marginBottom: 60,
    marginLeft: 40,
    marginRight: 40,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  loader: {
    marginLeft: 0,
    marginBottom: 18,
  },
  submit: {
    marginLeft: 0,
  },
  bordered: {
    borderRight: '1px solid #ccc',
  },
  left_col: {
    marginRight: '10%',
  },
  center_col: {
    width: '80%',
    margin: '0 auto',
  },
  right_col: {
    marginLeft: '10%',
  },
  section: {
    marginBottom: 50,
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 30,
  },
  label: {
    color: theme.palette.primary.main,
    fontSize: 14,
    marginBottom: 14,
  },
  row: {
    marginBottom: theme.spacing(3),
  },
  row_two_col: {
    display: 'flex',
    alignItems: 'flex-start',
    '&> *:first-child': {
      marginRight: 20,
    },
  },
  textarea: {
    width: 450,
  },
  input: {
    width: 350,
  },
  input_post_code: {
    width: 80,
  },
  input_date: {
    width: 150,
  },
  input_tax_number: {
    width: 250,
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '-moz-appearance': 'textfield',
  },
  select: {
    width: 220,
  },
  select_tax_id: {
    width: 100,
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
}))
