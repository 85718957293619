import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SectionTitle } from 'core/components/SectionTitle'
import { BlockSectionFormGenerate } from '../blockSectionFormGenerate/BlockSectionFormGenerate'
import { ButtonPlus } from '../../core/components/buttons/button-plus'
import { Grid, IconButton } from '@material-ui/core'
import { ReactComponent as RemoveIcon } from 'theme/icons/remove.svg'

export const SectionGenerateCollection = ({ collection }) => {
  const [form, setForm] = useState(
    collection.map(el => ({
      ...el,
      formSectionBlocksList: [el.formSectionBlocks],
    }))
  )

  const handleClone = i => {
    setForm(prevState =>
      prevState.map((el, index) => {
        if (i === index) {
          return {
            ...el,
            formSectionBlocksList: [
              ...el.formSectionBlocksList,
              el.formSectionBlocks,
            ],
          }
        } else {
          return el
        }
      })
    )
  }

  const handleRemove = (section, i) => {
    const array = form.map((el, key) => {
      if (key === section) {
        el.formSectionBlocksList.splice(i, 1)
        return {
          ...el,
          formSectionBlocksList: el.formSectionBlocksList,
        }
      } else {
        return el
      }
    })
    setForm(array)
  }

  return (
    <>
      {form.map((section, i) => {
        if (section.stat) {
          return (
            <div key={section.uuid}>
              {section.titleVisibility && (
                <SectionTitle
                  key={section.uuid}
                  label={section.title}
                  divider={true}
                />
              )}
              {section.formSectionBlocksList.map((el, key) => (
                <Grid container spacing={1} key={key}>
                  <Grid item xs={10} md={11}>
                    <BlockSectionFormGenerate collection={el} />
                  </Grid>
                  <Grid item xs={2} md={1}>
                    {section.isCloneable &&
                      section.formSectionBlocksList.length > 1 && (
                        <IconButton onClick={() => handleRemove(i, key)}>
                          <RemoveIcon />
                        </IconButton>
                      )}
                  </Grid>
                </Grid>
              ))}
              {section.isCloneable &&
                (section.cloneMaxCount === null ||
                  section.cloneMaxCount >
                    section.formSectionBlocksList.length) && (
                  <ButtonPlus
                    text={section.cloneButtonTitle || 'dodaj element'}
                    onClick={() => handleClone(i)}
                  />
                )}
            </div>
          )
        }

        return null
      })}
    </>
  )
}

SectionGenerateCollection.propTypes = {
  collection: PropTypes.array.isRequired,
}
