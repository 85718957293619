import React from 'react'
import PropTypes from 'prop-types'
import { BlankForm } from 'pages/applicant/BlankForm'
import { makeStyles } from '@material-ui/styles'
import { FieldWrapper } from 'components/applicant/form'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/proposal'

const useStyles = makeStyles({
  form: {
    marginBottom: 40
  },
  form_buttons: {
    display: 'flex',
    justifyContent: 'flex-start',
    '&> *': {
      margin: 0
    }
  },
})

export const UploadStep = ({
  contest,
  resource,
  definitionSchema,
  customResourceSchema,
  setResource
}) => {
  const classes = useStyles()

  const handleSuccess = response => {
    setResource({ ...resource, ...response })
  }

  return (
    <BlankForm
      url={schema.endpoint_contract_edit.replace(':id', resource.uuid)}
      method="PUT"
      resource={{
        ...resource,
        contractIsApply: true
      }}
      definitionSchema={definitionSchema}
      customResourceSchema={customResourceSchema}
      handleSuccess={handleSuccess}
      submitButtonTitle={translate('T_GENERAL_SEND_FILES')}
      submitButtonVariant="contained"
      classes={{
        root: classes.form,
        buttons: classes.form_buttons
      }}
    >
      {({ state, properties, resource, setValue, setError }) => (
        <div>
          <FieldWrapper
            name="contractAttachments"
            property={properties.contractAttachments}
            resource={resource}
            state={state}
            setValue={setValue}
            setError={setError}
            contest={contest}
          />
        </div>
      )}
    </BlankForm>
  )
}

UploadStep.propTypes = {
  contest: PropTypes.shape({
    '@id': PropTypes.string.isRequired
  }).isRequired,
  resource: PropTypes.shape({
    contractAttachments: PropTypes.arrayOf(PropTypes.shape({
      file: PropTypes.object.isRequired,
    })),
  }).isRequired,
  definitionSchema: PropTypes.object.isRequired,
  customResourceSchema: PropTypes.object.isRequired,
  setResource: PropTypes.func.isRequired,
}
