import { makeStyles } from '@material-ui/styles'

export const useCollectionItemStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    paddingBottom: 12,
    marginBottom: 16,
    borderBottom: '1px solid #ccc',
    display: 'block',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  root_not_link: {
    position: 'relative',
    paddingBottom: 12,
    marginBottom: 16,
    borderBottom: '1px solid #ccc',
    display: 'block',
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  link_container: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  link: {
    color: theme.palette.primary.main,
  },
  edition: {
    fontSize: 14,
    paddingBottom: 8,
  },
  title: {
    fontSize: 18,
    lineHeight: 1.2,
    fontWeight: 'bold',
    paddingBottom: 8,
  },
  subtitle: {
    fontSize: 16,
    lineHeight: 1.2,
    fontWeight: 'bold',
    paddingBottom: 8,
  },
  dates: {
    fontSize: 14,
    paddingBottom: 8,
  },
  type: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 8,
  },
  lead: {
    marginTop: 14,
  },
  delimiter: {
    marginLeft: 10,
    marginRight: 10,
  },
  flex_center: {
    display: 'flex',
    alignItems: 'center',
  },
  publication_status: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 14,
    '&> *:first-child': {
      marginRight: 4,
    },
  },
}))
