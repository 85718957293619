import {
  BLOCK_TYPE_ADDRESS,
  BLOCK_TYPE_DATA_TIME,
  BLOCK_TYPE_IMPLEMENTATNION,
  BLOCK_TYPE_INFO,
  BLOCK_TYPE_INPUT,
  BLOCK_TYPE_INSTYTUTION,
  BLOCK_TYPE_PARTICIPANTS,
  BLOCK_TYPE_SELECT,
  BLOCK_TYPE_TIMETABLE,
  BLOCK_TYPE_CHECKBOX,
  BLOCK_TYPE_TEXTAREA,
  BLOCK_TYPE_ADDITIONAL_INFO,
  BLOCK_TYPE_TARGET_INFORMATIONS,
  BLOCK_TYPE_COMPANY_DATA,
} from '_schema/formSectionBlock'
import { Info } from './Info'
import { Instytution } from './Instytution'
import { Address } from './Address'
import { Input } from './Input'
import { DataTime } from './DataTime'
import { Implementation } from './Implementation'
import { Timetable } from './Timetable'
import { Participants } from './Participants'
import { Select } from './Select'
import { Chekcboxes } from './Chekcboxes'
import { Textarea } from './Textarea'
import { Additional } from './Additional'
import { Target } from './Target'
import { OrganizationData } from './OrganizationData'

export const types = {
  [BLOCK_TYPE_INFO]: Info,
  [BLOCK_TYPE_INSTYTUTION]: Instytution,
  [BLOCK_TYPE_ADDRESS]: Address,
  [BLOCK_TYPE_INPUT]: Input,
  [BLOCK_TYPE_DATA_TIME]: DataTime,
  [BLOCK_TYPE_IMPLEMENTATNION]: Implementation,
  [BLOCK_TYPE_TIMETABLE]: Timetable,
  [BLOCK_TYPE_PARTICIPANTS]: Participants,
  [BLOCK_TYPE_SELECT]: Select,
  [BLOCK_TYPE_CHECKBOX]: Chekcboxes,
  [BLOCK_TYPE_TEXTAREA]: Textarea,
  [BLOCK_TYPE_ADDITIONAL_INFO]: Additional,
  [BLOCK_TYPE_TARGET_INFORMATIONS]: Target,
  [BLOCK_TYPE_COMPANY_DATA]: OrganizationData,
}
