import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SectionTitle } from 'core/components/SectionTitle'
import { types } from './types'
import { useSelector } from 'react-redux'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import schema from 'core/_schema/user'
import { Grid, Typography } from '@material-ui/core'

const BlockGlobalComponent = (block, user) => {
  const BlockComponent =
    typeof block.type === 'string' ? types[block.type] : null
  return <BlockComponent block={block} user={user} />
}

export const BlockSectionFormGenerate = ({ collection }) => {
  const profile = useSelector(state => state.profile)
  const [user, setUser] = useState({})

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    fetchDataHandleAuthError(
      `${schema('user').endpoint}/${profile.uuid}`,
      'GET',
      { signal },
      resp => {
        setUser(resp)
      }
    )
    return () => controller.abort()
  }, [profile])
  return (
    <>
      {collection.map(block => {
        if (block.stat) {
          return (
            <div key={block.uuid}>
              {block.titleVisibility && (
                <SectionTitle
                  key={block.uuid}
                  label={block.title}
                  divider={true}
                />
              )}
              {Object.keys(user).length > 0 && (
                <Grid container spacing={3}>
                  <Grid item md={8}>
                    {BlockGlobalComponent(block, user)}
                  </Grid>
                  <Grid item md={4}>
                    {block.help && (
                      <Typography variant={'body1'} color={'primary'}>
                        {block.help}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              )}
            </div>
          )
        }
        return null
      })}
    </>
  )
}

BlockSectionFormGenerate.propTypes = {
  collection: PropTypes.array.isRequired,
}
