import React, { useCallback, useState } from 'react'
import { SectionTitle } from 'core/components/SectionTitle'
import {
  Button,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { ButtonDownload } from 'core/components/buttons/button-download'
import { useStyleTable } from 'components/styledTable'
import { translate } from 'core/_helpers/translate'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { CustomDialog } from 'core/components/Dialog'
import { StringType } from 'core/components/form/fields/StringType'
import Moment from 'react-moment'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import moment from 'moment'
import { downloadFile } from '../../../../_helpers/downloadFile'
import { downloadFileFromFile } from '../../../../_helpers/downloadFileFromFile'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyle = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
  buttonRed: {
    borderColor: theme.palette.error.main,
    '& .MuiButton-label': {
      color: theme.palette.error.main,
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 24px rgba(11, 43, 124, 0.4)',
      '& .MuiButton-label': {
        color: theme.palette.primary.main,
      },
    },
  },
}))

const SubTable = ({ charges }) => {
  const tableClasses = useStyleTable()
  return (
    <>
      <Table className={tableClasses.table} size={'small'}>
        <TableHead className={tableClasses.tableHead}>
          <TableRow>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('Data')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('Numer i nazwa')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('Wystawca dokumentu')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('Netto')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('Brutto')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell} colSpan={2}>
              {translate('Z dotacji')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {charges.map((item, i) => (
            <TableRow key={i}>
              <TableCell className={tableClasses.tableCell}>
                {moment(item.documentDate).format(DATE_FORMAT)}
              </TableCell>
              <TableCell
                className={clsx(
                  tableClasses.tableCell,
                  item.file &&
                    item.file.filePath &&
                    tableClasses.tableCellClick,
                  tableClasses.tableCellNoWrap
                )}
              >
                {item.file && item.file.filePath ? (
                  <ButtonDownload
                    text={`${item.documentName || ''} ${item.documentNumber}`}
                    path={`${process.env.REACT_APP_API_ENTRYPOINT}/${item.file.filePath}`}
                  />
                ) : (
                  <>
                    {item.documentName || ''} {item.documentNumber}
                  </>
                )}
              </TableCell>
              <TableCell className={clsx(tableClasses.tableCell)}>
                {item.documentCreator}
              </TableCell>
              <TableCell
                className={clsx(
                  tableClasses.tableCell,
                  tableClasses.tableCellNoWrap
                )}
              >
                {Number(item.nettoAmount).toFixed(2)} zł
              </TableCell>
              <TableCell
                className={clsx(
                  tableClasses.tableCell,
                  tableClasses.tableCellNoWrap
                )}
              >
                {Number(item.bruttoAmount).toFixed(2)} zł
              </TableCell>
              <TableCell
                className={clsx(
                  tableClasses.tableCell,
                  tableClasses.tableCellNoWrap
                )}
              >
                {Number(item.subsidyAmount).toFixed(2)} zł
              </TableCell>
              <TableCell
                className={clsx(
                  tableClasses.tableCell,
                  tableClasses.tableCellNoWrap
                )}
              >
                {parseFloat(item.budgetPercentUsage).toFixed(2)} %
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}

export const FinalReport = ({ eventObject, updateResource, resource }) => {
  const tableClasses = useStyleTable()
  const classes = useStyle()

  const [state, setState] = useState({
    REJECTED: false,
    NEW: false,
    description: '',
  })

  const handleToogle = useCallback(type => {
    setState(prevState => ({ ...prevState, [type]: !prevState[type] }))
  }, [])

  const handleChange = useCallback((name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }))
  }, [])

  const { financialReports } = eventObject

  // console.log(eventObject)

  const {
    budgets,
    bruttoAmountTotal,
    nettoAmountTotal,
    subsidyAmountTotal,
    budgetPercentUsageTotal,
  } = financialReports?.[0]?.snapshot

  function totalSubsidyAmount(budgets) {
    return budgets
      .map(({ subsidyAmount }) => subsidyAmount)
      .reduce((sum, i) => sum + i, 0)
  }

  /*const bruttoAmountTotal = budgets.reduce(
    (sum, budget) => sum + (budget.budgetBruttoAmountTotal || 0),
    0
  )

  const budgetPercentUsageTotal = budgets.reduce(
    (sum, budget) => sum + (budget.budgetPercentUsage || 0),
    0
  )*/

  const handleSubmit = useCallback(
    status => {
      const iri = `/proposal_financial_reports/${financialReports[0].uuid}`
      fetchDataHandleAuthError(
        iri,
        'PUT',
        {
          body: JSON.stringify({
            status: status,
            description: state.description,
          }),
        },
        () => {
          handleToogle(status)
          updateResource()
          notification('success', translate('Zmieniono status'))
        },
        error => {
          notification('error', error.response.detail, error.response.title)
        }
      )
    },
    [financialReports, handleToogle, updateResource, state.description]
  )

  const handleDelete = () => {
    const iri = `/proposal_financial_reports/${financialReports[0].uuid}`
    if (window.confirm('Czy na pewno skasować? Operacja jest nieodwracalna')) {
      fetchDataHandleAuthError(
        iri,
        'DELETE',
        {},
        () => {
          updateResource()
          notification('success', translate('Skasowano'))
        },
        error => {
          notification('error', error.response.detail, error.response.title)
        }
      )
    }
  }

  const handleChargesFileAssignRefresh = useCallback(
    status => {
      const iri = `/proposal_financial_reports/charges_file_assign_refresh/${financialReports[0].uuid}`
      if (window.confirm('Czy na pewno odświeżyć załączniki w raporcie?')) {
        fetchDataHandleAuthError(
          iri,
          'PUT',
          {
            body: JSON.stringify({
              chargesFileAssignRefresh: true,
            }),
          },
          () => {
            handleToogle(status)
            // updateResource() to nie działa wiec przeładowuję całą stronę
            window.location.reload()
            notification('success', translate('Załączniki odświeżone'))
          },
          error => {
            notification('error', error.response.detail, error.response.title)
          }
        )
      }
    },
    [financialReports, handleToogle]
  )

  const handleDownload = file => {
    // downloadFile(`/files/original/${file.uuid}`, `${file.uuid}-raport.pdf`)
    downloadFileFromFile(file.uuid)
  }

  const handleDownloadR = (url, id) => {
    downloadFile(
      `/proposal_financial_reports/download/${id}`,
      `raport-${resource.contractNumber || id}.pdf`
    )
  }
  return (
    <>
      <Grid container spacing={3} justify={'space-between'}>
        <Grid item xs={4} md={2}>
          <Typography color="primary" variant="subtitle1">
            {financialReports[0].createdAt ? (
              <Moment
                date={financialReports[0].createdAt}
                format={'DD.MM.YYYY'}
              />
            ) : (
              '--.--.----'
            )}
          </Typography>
        </Grid>
        <Grid item xs={8} md={10}>
          {!!financialReports[0].filePath && (
            <ButtonDownload
              text={'Pobierz wygenerowany pdf do sprawdzenia'}
              onClick={() =>
                handleDownloadR(
                  financialReports[0].filePath,
                  financialReports[0].uuid
                )
              }
            />
          )}
          {!!financialReports[0].uploadedReportFile && (
            <ButtonDownload
              text={'Pobierz wgrany przez wnioskodawcę podpisany skan raportu'}
              onClick={() =>
                handleDownload(financialReports[0].uploadedReportFile)
              }
            />
          )}
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        <Button
          onClick={() => handleChargesFileAssignRefresh()}
          color={'secondary'}
          variant="contained"
        >
          <span>{translate('Odśwież załączniki')}</span>
        </Button>

        <Button
          variant="outlined"
          className={classes.buttonRed}
          onClick={() => handleDelete('DELETED')}
        >
          <span>{translate('Skasuj')}</span>
        </Button>

        <Button
          variant="outlined"
          className={classes.buttonRed}
          onClick={() => handleToogle('REJECTED')}
        >
          <span>{translate('Odrzuć')}</span>
        </Button>
        {/*<Button
          color={'secondary'}
          variant="outlined"
          onClick={() => handleToogle('NEW')}
        >
          <span>{translate('Cofnij do edycji')}</span>
        </Button>*/}
        <Button
          onClick={() => handleSubmit('ACCEPTED')}
          color={'secondary'}
          variant="contained"
        >
          <span>{translate('Akceptuj')}</span>
        </Button>

        <CustomDialog
          handleToogle={() => handleToogle('REJECTED')}
          open={state['REJECTED']}
          title={'Informacja dla Wnioskodawcy'}
          confirmedButton={true}
          component={() => (
            <StringType
              name={'description'}
              setValue={handleChange}
              disabled={false}
              renderError={false}
              type={'textarea'}
              setError={() => {}}
              label={''}
              width={'100%'}
              value={state.description}
            />
          )}
          onConfirm={() => handleSubmit('REJECTED')}
        />
        {/*<CustomDialog
          handleToogle={() => handleToogle('NEW')}
          open={state['NEW']}
          title={'Informacja dla Wnioskodawcy'}
          confirmedButton={true}
          component={() => (
            <StringType
              name={'description'}
              setValue={handleChange}
              disabled={false}
              renderError={false}
              type={'textarea'}
              setError={() => {}}
              label={''}
              width={'100%'}
              value={state.description}
            />
          )}
          onConfirm={() => handleSubmit('NEW')}
        />*/}
      </div>
      <SectionTitle label={'Raport finansowy'} divider={true} />
      <Table className={tableClasses.table}>
        <TableHead className={tableClasses.tableHead}>
          <TableRow>
            <TableCell
              className={tableClasses.tableHeadCell}
              style={{ width: '50%' }}
              colSpan={3}
            >
              {translate('Kategoria budżetowa')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('Dotowano')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('Wydatki')}
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              {translate('% wykorzystania budżetu')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {budgets?.map((item, i) => (
            <React.Fragment key={i}>
              <TableRow>
                <TableCell className={clsx(tableClasses.cellTSum)} colSpan={3}>
                  <div className={tableClasses.cellDivider}>
                    <span>{item.title}</span>
                    <Divider />
                  </div>
                </TableCell>
                <TableCell className={clsx(tableClasses.cellTSum)}>
                  <div className={tableClasses.cellDivider}>
                    <span>{Number(item.subsidyAmount).toFixed(2)} zł</span>
                    <Divider />
                  </div>
                </TableCell>
                <TableCell className={clsx(tableClasses.cellTSum)}>
                  <div className={tableClasses.cellDivider}>
                    <span>
                      {item.budgetSubsidyAmountTotal !== undefined &&
                      item.budgetSubsidyAmountTotal ? (
                        <>
                          {Number(item.budgetSubsidyAmountTotal).toFixed(2)} zł
                        </>
                      ) : (
                        <>bd.</>
                      )}
                    </span>
                    <Divider />
                  </div>
                </TableCell>
                <TableCell className={tableClasses.cellTSum}>
                  {item.budgetSubsidyAmountTotal !== undefined &&
                  item.budgetSubsidyAmountTotal ? (
                    <>{parseFloat(item.budgetPercentUsage).toFixed(2)} %</>
                  ) : (
                    <>bd.</>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={tableClasses.tableCell} colSpan={6}>
                  <SubTable charges={item.charges} />
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell
              className={tableClasses.tableHeadCell}
              style={{ width: '30%', textAlign: 'right' }}
            ></TableCell>
            <TableCell className={tableClasses.tableHeadCell}>Netto</TableCell>
            <TableCell className={tableClasses.tableHeadCell}>Brutto</TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              Dotowano
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              Z dotacji
            </TableCell>
            <TableCell className={tableClasses.tableHeadCell}>
              % wykorzystania budżetu
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              className={tableClasses.tableTitle}
              style={{ width: '30%', textAlign: 'right' }}
            >
              {translate('Suma')}:
            </TableCell>
            <TableCell className={tableClasses.tableTitle}>
              {Number(nettoAmountTotal).toFixed(2)} zł
            </TableCell>
            <TableCell className={tableClasses.tableTitle}>
              {Number(bruttoAmountTotal).toFixed(2)} zł
            </TableCell>
            <TableCell className={tableClasses.tableTitle}>
              {Number(totalSubsidyAmount(budgets)).toFixed(2)} zł
            </TableCell>
            <TableCell className={tableClasses.tableTitle}>
              {Number(subsidyAmountTotal).toFixed(2)} zł
            </TableCell>
            <TableCell className={tableClasses.tableTitle}>
              {budgetPercentUsageTotal &&
                parseFloat(budgetPercentUsageTotal).toFixed(2)}{' '}
              %
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      {/*<SectionTitle label={'Raport merytoryczny'} divider={true} />*/}
      {/*<div className={classes.buttons}></div>*/}
      {/*Tutaj będą dane z formularza raportu merytorycznego*/}
    </>
  )
}
