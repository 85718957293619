import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import { useOrganizationDataFormStyles } from '.'
import { BlankForm } from 'pages/applicant/BlankForm'
import { FieldWrapper } from 'components/applicant/form'
import { SectionTitle } from 'core/components/SectionTitle'
import { useSelector } from 'react-redux'
import { OrganizationEmailReset } from './OrganizationEmailReset'
import { OrganizationEmailChange } from './OrganizationEmailChange'
import { translate } from 'core/_helpers/translate'
import { Status } from 'core/components/resource/Status'

export const OrganizationDataForm = ({
  url,
  resetUrl,
  method,
  resource = null,
  definitionSchema,
  customResourceSchema,
  ...rest
}) => {
  const classes = useOrganizationDataFormStyles()

  const profile = useSelector(state => state.profile)

  return (
    <BlankForm
      url={url}
      method={method}
      resource={resource}
      definitionSchema={definitionSchema}
      customResourceSchema={customResourceSchema}
      getKrsFromApi={true}
      classes={{
        root: classes.root,
        buttons: classes.buttons,
        submit: classes.submit,
        loader: classes.loader,
      }}
      {...rest}
    >
      {({
        state,
        properties,
        resource,
        setValue,
        setError,
        krsIsFetching = false,
        handleSetDataFromKRS = null,
      }) => (
        <Grid container>
          <Grid item xs={4} className={classes.bordered}>
            <div className={classes.left_col}>
              <div className={classes.section}>
                <SectionTitle
                  label={'T_MODULE_ORGANIZATION_DATA_BASIC_INFORMATIONS'}
                  divider={false}
                />
                <div className={clsx(classes.row_two_col)}>
                  <FieldWrapper
                    label={''}
                    name="taxId"
                    property={properties.taxId}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.select_tax_id,
                    }}
                    provideAdditionalValues={['nip', 'regon', 'krs']}
                  />
                  {state.values.taxId === 'nip' && (
                    <FieldWrapper
                      label={''}
                      name="nip"
                      property={properties.nip}
                      resource={resource}
                      state={state}
                      setValue={setValue}
                      setError={setError}
                      classes={{
                        root: classes.input_tax_number,
                      }}
                    />
                  )}
                  {state.values.taxId === 'regon' && (
                    <FieldWrapper
                      label={''}
                      name="regon"
                      property={properties.regon}
                      resource={resource}
                      state={state}
                      setValue={setValue}
                      setError={setError}
                      classes={{
                        root: classes.input_tax_number,
                      }}
                    />
                  )}
                  {state.values.taxId === 'krs' && (
                    <FieldWrapper
                      label={''}
                      name="krs"
                      property={properties.krs}
                      resource={resource}
                      state={state}
                      setValue={setValue}
                      setError={setError}
                      classes={{
                        root: classes.input_tax_number,
                      }}
                    />
                  )}
                  <Button
                    type={'button'}
                    variant={'outlined'}
                    size={'small'}
                    color={'secondary'}
                    style={{
                      height: 38,
                      marginLeft: 20,
                    }}
                    disabled={
                      state.values.taxId === '' ||
                      state.values.taxId === 'other' ||
                      krsIsFetching
                    }
                    onClick={handleSetDataFromKRS}
                  >
                    {krsIsFetching ? (
                      <CircularProgress size={24} />
                    ) : (
                      <span>{translate('Pobierz dane')}</span>
                    )}
                  </Button>
                </div>
                <div>
                  <FieldWrapper
                    label={'T_MODULE_ORGANIZATION_DATA_ORGANIZATION_NAME'}
                    name="organizationName"
                    property={properties.organizationName}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.input,
                    }}
                  />
                </div>
                <div>
                  <FieldWrapper
                    label={'T_MODULE_ORGANIZATION_DATA_ORGANIZATION_TYPE'}
                    name="organizationType"
                    property={properties.organizationType}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.select,
                    }}
                  />
                </div>
                <div className={classes.row}>
                  <FieldWrapper
                    label={'T_FORM_FIELD_ORAGNIZATION_PHONE'}
                    name="phone"
                    property={properties.phone}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.input,
                    }}
                  />
                </div>
              </div>
              <div className={classes.section}>
                <SectionTitle
                  label={'T_MODULE_ORGANIZATION_DATA_ORGANIZATION_ADDRESS'}
                  divider={false}
                />
                <div className={classes.row_two_col}>
                  <div>
                    <FieldWrapper
                      label={'T_MODULE_ORGANIZATION_DATA_POST_OFFICE'}
                      name="postOffice"
                      property={properties.postOffice}
                      resource={resource}
                      state={state}
                      setValue={setValue}
                      setError={setError}
                      classes={{
                        root: classes.input,
                      }}
                    />
                  </div>
                  <div>
                    <FieldWrapper
                      label={'T_MODULE_ORGANIZATION_DATA_POST_CODE'}
                      name="postCode"
                      property={properties.postCode}
                      resource={resource}
                      state={state}
                      setValue={setValue}
                      setError={setError}
                      classes={{
                        root: classes.input_post_code,
                      }}
                    />
                  </div>
                </div>
                <div>
                  <FieldWrapper
                    label={'T_MODULE_ORGANIZATION_DATA_ADDRESS'}
                    name="address"
                    property={properties.address}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.input,
                    }}
                  />
                </div>
                <div>
                  <FieldWrapper
                    label={'T_MODULE_ORGANIZATION_DATA_CITY'}
                    name="city"
                    property={properties.city}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.input,
                    }}
                  />
                </div>
                <div>
                  <FieldWrapper
                    label={'T_MODULE_ORGANIZATION_DATA_COMMUNE'}
                    name="commune"
                    property={properties.commune}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.input,
                    }}
                  />
                </div>
                <div>
                  <FieldWrapper
                    label={'T_MODULE_ORGANIZATION_DATA_DISTRICT'}
                    name="district"
                    property={properties.district}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.input,
                    }}
                  />
                </div>
                <div>
                  <FieldWrapper
                    label={'T_MODULE_ORGANIZATION_DATA_PROVINCE'}
                    name="province"
                    property={properties.province}
                    resource={resource}
                    state={state}
                    setValue={setValue}
                    setError={setError}
                    classes={{
                      root: classes.select,
                    }}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4} className={classes.bordered}>
            <div className={classes.center_col}>
              {profile?.roles.includes('ROLE_ADMIN') && (
                <>
                  <SectionTitle
                    label={'Ustaw e-mail i zresetuj hasło'}
                    divider={false}
                  />
                  <div>
                    <FieldWrapper
                      label={'E-mail'}
                      name="email"
                      property={properties.email}
                      resource={resource}
                      state={state}
                      setValue={setValue}
                      setError={setError}
                      classes={{
                        root: classes.select,
                      }}
                    />
                  </div>
                  <OrganizationEmailReset
                    resource={resource}
                    setValue={setValue}
                    url={resetUrl}
                  />
                </>
              )}
              <SectionTitle
                label={'T_MODULE_ORGANIZATION_DATA_ADDITIONAL_INFORMATIONS'}
                divider={false}
              />
              <div>
                <FieldWrapper
                  label={'T_MODULE_ORGANIZATION_DATA_POPULATION'}
                  name="population"
                  property={properties.population}
                  resource={resource}
                  state={state}
                  setValue={setValue}
                  setError={setError}
                  classes={{
                    root: classes.select,
                  }}
                />
              </div>
              <div>
                <FieldWrapper
                  label={'T_MODULE_ORGANIZATION_DATA_POPULATION_DATA_SOURCE'}
                  name="populationDataSource"
                  property={properties.populationDataSource}
                  resource={resource}
                  state={state}
                  setValue={setValue}
                  setError={setError}
                  classes={{
                    root: classes.input,
                  }}
                />
              </div>
              <div>
                <FieldWrapper
                  label={'T_MODULE_ORGANIZATION_DATA_WEBSITE'}
                  name="website"
                  property={properties.website}
                  resource={resource}
                  state={state}
                  setValue={setValue}
                  setError={setError}
                  classes={{
                    root: classes.input,
                  }}
                />
              </div>
              <div>
                <FieldWrapper
                  label={
                    'T_MODULE_ORGANIZATION_DATA_ORGANIZATION_CREATION_DATE'
                  }
                  name="organizationCreationDate"
                  property={properties.organizationCreationDate}
                  resource={resource}
                  state={state}
                  setValue={setValue}
                  setError={setError}
                  classes={{
                    root: classes.input_date,
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.right_col}>
              <SectionTitle
                label={'T_MODULE_ORGANIZATION_DATA_GOALS_INFO'}
                divider={false}
              />
              <div>
                <FieldWrapper
                  label={'T_MODULE_ORGANIZATION_DATA_ORGANIZATION_GOALS'}
                  name="organizationGoals"
                  property={properties.organizationGoals}
                  resource={resource}
                  state={state}
                  setValue={setValue}
                  setError={setError}
                  classes={{
                    root: classes.textarea,
                  }}
                />
              </div>
              <div>
                <FieldWrapper
                  label={'T_MODULE_ORGANIZATION_DATA_STATUTORY_ACTIVITIES'}
                  name="statutoryActivities"
                  property={properties.statutoryActivities}
                  resource={resource}
                  state={state}
                  setValue={setValue}
                  setError={setError}
                  classes={{
                    root: classes.textarea,
                  }}
                />
              </div>

              {profile?.roles.includes('ROLE_APPLICANT') && (
                <>
                  <SectionTitle label={'Zmień e-mail'} divider={false} />
                  <OrganizationEmailChange
                    resource={resource}
                    setValue={setValue}
                    url={resetUrl}
                  />
                </>
              )}

              <SectionTitle label={'Zmień status'} divider={false} />
              <div className={classes.status}>
                <Typography variant={'subtitle2'} color={'primary'}>
                  Aktywny
                </Typography>
                <Status resource={resource} isSwitch={true} size={'small'} />
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </BlankForm>
  )
}

OrganizationDataForm.propTypes = {
  url: PropTypes.string.isRequired,
  resetUrl: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  resource: PropTypes.object.isRequired,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
}
