import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useCollectionItemStyles } from 'components/applicant/collection_styles/CollectionItem.styles'
import { ArrowForwardOutlined } from '@material-ui/icons'
import { translate } from 'core/_helpers/translate'
import routes from 'pages/applicant/DraftProposals/routes'

const DATE_FORMAT = 'DD.MM.YYYY'

export const ItemComponent = ({ item }) => {
  const classes = useCollectionItemStyles()

  const Component = ({ children }) => {
    if (moment(item.contest.proposalCollectingDateTo).isAfter(new Date())) {
      return (
        <Link
          to={routes().view.path.replace(':id', item.contest.uuid)}
          className={classes.root}
        >
          {children}
        </Link>
      )
    } else {
      return <div className={classes.root_not_link}>{children}</div>
    }
  }

  return (
    <Component>
      {item.contestEdition && (
        <div className={classes.edition}>{item.contestEdition.title}</div>
      )}
      <div className={classes.title}>{item.title || item.contest?.title}</div>
      <div className={classes.dates}>
        <div>
          {translate('T_MODULE_CONTESTS_PROPOSAL_COLLECTING_DATE')}:
          <span>
            {item.contest.proposalCollectingDateTo &&
              ` ${moment(item.contest.proposalCollectingDateTo).format(
                DATE_FORMAT
              )}`}
          </span>
        </div>
        <div>
          {translate('T_MODULE_CONTESTS_PROJECT_REALIZATION_DATE')}:
          <span>
            {item.contest.projectRealizationDateFrom &&
              ` ${moment(item.contest.projectRealizationDateFrom).format(
                DATE_FORMAT
              )}`}
            {item.contest.projectRealizationDateTo &&
              ` - ${moment(item.contest.projectRealizationDateTo).format(
                DATE_FORMAT
              )}`}
          </span>
        </div>
      </div>

      <div className={classes.lead}>{item.pageLead}</div>
      {moment(item.contest.proposalCollectingDateTo).isAfter(new Date()) ? (
        <div className={classes.link_container}>
          <div className={classes.link}>
            <ArrowForwardOutlined />
          </div>
        </div>
      ) : (
        <div className={classes.subtitle}>Termin składania wniosków minął</div>
      )}
    </Component>
  )
}

ItemComponent.propTypes = {
  item: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    pageLead: PropTypes.string,
    projectRealizationDateFrom: PropTypes.string,
    projectRealizationDateTo: PropTypes.string,
    proposalCollectingDateTo: PropTypes.string,
    contestEdition: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
}
