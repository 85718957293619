import React, { useReducer, useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import { Loader } from 'core/components/Loader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { applyProperties } from 'core/_helpers/applyProperties'
import { notification } from 'core/_helpers/notification'
import { isObjectEmpty } from 'core/_helpers/isObjectEmpty'
import { createObjectFromString } from 'core/_helpers/createObjectFromString'
import { SubmitButton } from 'components/applicant/form/buttons'
import { initState } from 'components/applicant/form/_helpers/initState'
import { constants, reducer } from 'components/applicant/form/_state'
import { useStyles } from 'components/applicant/form'
import { proposalApplicationStatuses } from '_helpers/proposalApplicationStatuses'
import { translate } from 'core/_helpers/translate'
import appliedProposalRoutes from 'pages/applicant/AppliedProposals/routes'
import { CustomDialog } from 'core/components/Dialog'
import { Preview } from 'pages/applicant/Proposals/components/form/Preview'
import { useDispatch, useSelector } from 'react-redux'
import { formSettingsConstans } from '_constans/formSettings.constans'

export const ProposalForm = ({
  url,
  definitionSchema,
  customResourceSchema,
  resource = null,
  buttonContainerRef = null,
  children,
  contestResource = null,
  classes = {},
  autosave = false,
}) => {
  const dispatchRedux = useDispatch()

  const properties = applyProperties(
    customResourceSchema.properties,
    definitionSchema.properties,
    'missing'
  )

  const [state, dispatch] = useReducer(
    reducer,
    {
      url,
      method: 'PUT',
      resource,
      properties,
      onlyPassedProperties: false,
    },
    initState
  )

  const [valuesToPreview, setValuesToPreview] = useState({})

  const setValue = useCallback(
    (name, value, setRenderError = true) => {
      if (setRenderError) {
        dispatchRedux({ type: formSettingsConstans.SET_IS_CHANGE })
      }
      dispatch({
        type: constants.SET_VALUE,
        payload: { name, value, setRenderError },
      })
    },
    [dispatchRedux]
  )

  const setError = useCallback((name, error) => {
    dispatch({ type: constants.SET_ERROR, payload: { name, error } })
  }, [])

  useEffect(() => {
    if (!state.isSubmitted) {
      return
    }

    dispatch({ type: constants.RENDER_ERROR })
  }, [state.isSubmitted])

  const history = useHistory()

  const handleValidate = e => {
    e.preventDefault()
    dispatch({ type: constants.SUBMIT })
    !state.isInvalid
      ? notification(
          'success',
          'Może zostać złożony',
          'Wniosek jest wypełniony poprawnie'
        )
      : notification(
          'error',
          'Przeglądnij wszystkie strony wniosku i uzupełnij informacje zgodnie z instrukcjami',
          'Wniosek posiada błędy lub braki'
        )
  }

  const handleSubmitSketch = e => {
    e.preventDefault()

    dispatch({ type: constants.PROCESS, payload: true })

    fetchDataHandleAuthError(
      url,
      'PUT',
      { body: JSON.stringify(state.values) },
      response => {
        dispatch({
          type: constants.SUCCESS,
          payload: {
            method: 'PUT',
            resource: response,
          },
        })
        setValuesToPreview(response)
        dispatchRedux({ type: formSettingsConstans.RESET_IS_CHANGE })
        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')
      },
      error => {
        const errors = error.response.violations.reduce(
          (processedErrors, item) => {
            const processedError = createObjectFromString(
              item.propertyPath.replace('[', '.').replace(']', ''),
              item.message
            )

            return _.merge(processedErrors, processedError)
          },
          {}
        )

        dispatch(
          isObjectEmpty(errors)
            ? { type: constants.PROCESS, payload: false }
            : { type: constants.FAILURE, payload: { errors } }
        )

        notification(
          'error',
          error.response.violations.length
            ? 'T_FORM_INCORRECT'
            : error.response.detail,
          error.response.title
        )
      },
      {}
    )
  }

  // AUTOSAVE - start
  const formIsChange = useSelector(state => state.formSetting.isChange)
  const [formAutoSaveControl, setFormAutoSaveControl] = useState(false)
  useEffect(() => {
    if (formIsChange && autosave) {
      setTimeout(() => {
        setFormAutoSaveControl(true)
      }, 30000)
    }
  }, [formIsChange, autosave])

  useEffect(() => {
    const controller = new AbortController()
    if (formAutoSaveControl) {
      setFormAutoSaveControl(false)
      fetchDataHandleAuthError(
        url,
        'PUT',
        { body: JSON.stringify(state.values) },
        response => {
          setValuesToPreview(response)
          dispatchRedux({ type: formSettingsConstans.RESET_IS_CHANGE })
          notification('success', 'Zapisano szkic', 'Autosave')
        },
        error => {
          const errors = error.response.violations.reduce(
            (processedErrors, item) => {
              const processedError = createObjectFromString(
                item.propertyPath.replace('[', '.').replace(']', ''),
                item.message
              )

              return _.merge(processedErrors, processedError)
            },
            {}
          )

          dispatch(
            isObjectEmpty(errors)
              ? { type: constants.PROCESS, payload: false }
              : { type: constants.FAILURE, payload: { errors } }
          )

          notification(
            'error',
            error.response.violations.length
              ? 'T_FORM_INCORRECT'
              : error.response.detail,
            error.response.title
          )
        },
        {}
      )
    }
    return () => controller.abort()
  }, [dispatchRedux, formAutoSaveControl, state, url, formIsChange])

  // useEffect(() => {
  //   if (autoSaveControl) {
  //     setTimeout(() => {
  //       console.log('test')
  //       dispatchRedux({ type: formSettingsConstans.RESET_IS_CHANGE })
  //     }, 3000)
  //   }
  // }, [autoSaveControl])

  // AUTOSAVE - stop

  const handleSubmitProposal = e => {
    e.preventDefault()

    dispatch({ type: constants.SUBMIT })

    if (state.isInvalid) {
      return
    }

    dispatch({ type: constants.PROCESS, payload: true })

    fetchDataHandleAuthError(
      url,
      'PUT',
      {
        body: JSON.stringify({
          ...state.values,
          applicationStatus: proposalApplicationStatuses.APPLIED,
        }),
      },
      response => {
        dispatchRedux({ type: formSettingsConstans.RESET_IS_CHANGE })
        dispatch({
          type: constants.SUCCESS,
          payload: {
            method: 'PUT',
            resource: response,
          },
        })

        history.push(
          appliedProposalRoutes().view.path.replace(':id', response.uuid)
        )
        notification('success', 'Wniosek został złożony', 'Komunikat')
      },
      error => {
        const errors = error.response.violations.reduce(
          (processedErrors, item) => {
            const processedError = createObjectFromString(
              item.propertyPath.replace('[', '.').replace(']', ''),
              item.message
            )

            return _.merge(processedErrors, processedError)
          },
          {}
        )

        dispatch(
          isObjectEmpty(errors)
            ? { type: constants.PROCESS, payload: false }
            : { type: constants.FAILURE, payload: { errors } }
        )

        notification(
          'error',
          error.response.violations.length
            ? 'T_FORM_INCORRECT'
            : error.response.detail,
          error.response.title
        )
      },
      {}
    )
  }

  const defaultClasses = useStyles()

  const [openPreview, setOpenPreview] = useState(false)

  const handleOpenPreviewToogle = (e, validate = true) => {
    if (validate) {
      handleValidate(e)
      e.preventDefault()

      if (state.isInvalid) {
        return
      }

      dispatch({ type: constants.PROCESS, payload: true })

      fetchDataHandleAuthError(
        url,
        'PUT',
        { body: JSON.stringify(state.values) },
        response => {
          dispatch({
            type: constants.SUCCESS,
            payload: {
              method: 'PUT',
              resource: response,
            },
          })
          setValuesToPreview(response)
          dispatchRedux({ type: formSettingsConstans.RESET_IS_CHANGE })
          if (!state.isInvalid) {
            setOpenPreview(prevState => !prevState)
          }
        },
        error => {
          const errors = error.response.violations.reduce(
            (processedErrors, item) => {
              const processedError = createObjectFromString(
                item.propertyPath.replace('[', '.').replace(']', ''),
                item.message
              )

              return _.merge(processedErrors, processedError)
            },
            {}
          )

          dispatch(
            isObjectEmpty(errors)
              ? { type: constants.PROCESS, payload: false }
              : { type: constants.FAILURE, payload: { errors } }
          )

          notification(
            'error',
            error.response.violations.length
              ? 'T_FORM_INCORRECT'
              : error.response.detail,
            error.response.title
          )
        },
        {}
      )
    } else {
      setOpenPreview(prevState => !prevState)
    }
  }

  return (
    <form className={clsx(defaultClasses.root, classes.root)}>
      {children({
        state,
        properties,
        resource,
        setValue,
        setError,
      })}
      <div className={clsx(defaultClasses.buttons, classes.buttons)}>
        {state.isProcessing ? (
          <Loader classes={{ root: classes.loader }} />
        ) : (
          <>
            <SubmitButton
              title={translate('T_MODULE_PROPOSALS_VALIDATE')}
              variant="outlined"
              size="small"
              handleSubmit={handleValidate}
              classes={{ submit: classes.submit }}
              nodeRef={buttonContainerRef}
            />

            <SubmitButton
              title={translate('T_MODULE_PROPOSALS_SAVE_SKETCH')}
              variant="outlined"
              size="large"
              handleSubmit={handleSubmitSketch}
              disabled={state.isProcessing}
              classes={{ submit: classes.submit }}
              nodeRef={buttonContainerRef}
            />
            <SubmitButton
              title={translate('T_MODULE_PROPOSALS_APPLY')}
              variant="contained"
              size="large"
              handleSubmit={handleOpenPreviewToogle}
              disabled={
                state.isProcessing || (state.isSubmitted && state.isInvalid)
              }
              classes={{ submit: classes.submit }}
              nodeRef={buttonContainerRef}
            />
          </>
        )}
      </div>
      <CustomDialog
        handleToogle={e => handleOpenPreviewToogle(e, false)}
        open={openPreview}
        title={'Czy wniosek jest gotowy do złożenia?'}
        component={() => (
          <Preview
            resource={{ ...resource, ...valuesToPreview }}
            contestResource={contestResource}
          />
        )}
        confirmedButton={true}
        confirmedText={'Złóż wniosek'}
        onConfirm={handleSubmitProposal}
        maxWidth={'md'}
      />
    </form>
  )
}

ProposalForm.propTypes = {
  url: PropTypes.string.isRequired,
  resource: PropTypes.object,
  contestResource: PropTypes.object,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  buttonContainerRef: PropTypes.object,
  children: PropTypes.func.isRequired,
  classes: PropTypes.object,
  autosave: PropTypes.bool,
}
