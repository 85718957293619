import React from 'react'
import { useCollectionContainerStyles } from 'components/applicant/collection_styles/CollectionContainer.styles'
import { Paper } from 'core/components/Paper'
import { ProposalCollection } from 'components/applicant/proposal_collection'
import { ProposalsTabs } from 'pages/applicant/ProposalsTabs'
import { ItemComponent } from './components'
import { proposalApplicationStatuses } from '_helpers/proposalApplicationStatuses'
import schema from '_schema/proposal'
import routes from './routes'

export const Collection = () => {
  const filters = {
    realizationStatus: proposalApplicationStatuses.INPROGRESS,
    'order[updatedAt]': 'desc',
  }

  const classes = useCollectionContainerStyles()

  return (
    <>
      <ProposalsTabs
        value={routes().index.path}
        classes={{ root: classes.tabs }}
      />
      <Paper classes={{ root: classes.paper }}>
        <ProposalCollection
          endpoint={schema.endpoint}
          ItemComponent={ItemComponent}
          filters={filters}
        />
      </Paper>
    </>
  )
}
