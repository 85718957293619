import React, { useState } from 'react'
import { Grid, IconButton, Typography } from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { makeStyles } from '@material-ui/styles'
import Moment from 'react-moment'
import { translate } from 'core/_helpers/translate'
import moment from 'moment/moment'

const useStyles = makeStyles(theme => ({
  table_header_line: {
    fontSize: 18,
    fontWeight: 'regular',
    color: theme.palette.primary.main,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    flexGrow: 1,
    marginRight: 30,
  },
  header_status: {
    fontWeight: 'bold',
    marginLeft: 20,
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 800,
    cursor: 'pointer',
  },
  listSchedule: {
    maxWidth: 800,
    marginTop: 30,
  },
  header: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}))

const DATE_FORMAT = 'DD.MM.YYYY'

export const ChangesBudgetHistory = ({ changesBudgetRequest, budgets }) => {
  const tableClasses = useStyles()
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(prevState => !prevState)
  }

  return (
    <>
      <div className={tableClasses.titleRow} onClick={handleOpen}>
        <h3 className={tableClasses.table_header_line}>
          <Moment date={changesBudgetRequest.createdAt} format={DATE_FORMAT} />
          <div className={tableClasses.header_status}>
            {translate(`T_BUDGET_CHANGE_STATUS_${changesBudgetRequest.status}`)}
          </div>
        </h3>
        <IconButton color={'primary'}>
          {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        </IconButton>
      </div>
      {changesBudgetRequest.message && (
        <Typography variant={'body2'} color={'primary'}>
          {changesBudgetRequest.message}
        </Typography>
      )}
      {open && (
        <div className={tableClasses.listSchedule}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography
                variant={'subtitle1'}
                color={'primary'}
                className={tableClasses.header}
              >
                Rodzaj kosztów
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant={'subtitle1'}
                color={'primary'}
                className={tableClasses.header}
              >
                Było
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                variant={'subtitle1'}
                color={'primary'}
                className={tableClasses.header}
              >
                Zgłoszenie
              </Typography>
            </Grid>
          </Grid>
          {changesBudgetRequest.budgetChangeHistories.map(change => (
            <div key={change.uuid}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <Typography variant={'subtitle2'} color={'primary'}>
                    {change.titleOld && change.titleOld !== change.title ? (
                      <>
                        <span style={{ textDecoration: 'line-through' }}>
                          {change.titleOld}
                        </span>
                        <br />
                        <span style={{ fontWeight: 'bold' }}>
                          {change.title}
                        </span>
                      </>
                    ) : (
                      <span
                        style={{
                          fontWeight:
                            change.subsidyAmountBudget !== change.subsidyAmount
                              ? 'bold'
                              : 'initial',
                        }}
                      >
                        {budgets.find(el => change.budget === el['@id'])?.title}
                      </span>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant={'body1'} color={'primary'}>
                    {change.subsidyAmountBudget}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography variant={'body1'} color={'primary'}>
                    {change.subsidyAmount}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
