export const initState = ({
  properties,
  resource,
  defaultData,
  url,
  method,
  fetchCompareResource,
}) => ({
  values:
    method === 'PUT' && resource
      ? {
          ...defaultData,
          ...resource,
        }
      : Object.assign(
          defaultData,
          ...Object.keys(properties).map(name => {
            if (properties[name].type === 'sectionTitle') {
              return false
            }
            if (properties[name].type === 'krstype') {
              return false
            }
            if (properties[name].type === 'subform') {
              return false
            }
            if (properties[name].type === 'group') {
              const group = {}
              Object.keys(properties[name].properties).forEach(el => {
                group[el] =
                  resource?.[el] !== undefined
                    ? resource[el]
                    : properties[name].properties[el].defaultValue !== undefined
                    ? properties[name].properties[el].defaultValue
                    : null
              })
              return group
            }
            if (properties[name].type === 'column') {
              const group = {}
              Object.keys(properties[name].properties).forEach(el => {
                Object.keys(properties[name].properties[el].properties).forEach(
                  element => {
                    group[element] =
                      resource?.[element] !== undefined
                        ? resource[element]
                        : properties[name].properties[el].properties[element]
                            .defaultValue !== undefined
                        ? properties[name].properties[el].properties[element]
                            .defaultValue
                        : null
                  }
                )
              })
              return group
            }
            return {
              [name]:
                resource?.[name] !== undefined
                  ? resource[name]
                  : properties[name].defaultValue !== undefined
                  ? properties[name].defaultValue
                  : null,
            }
          })
        ),
  errors: Object.assign(
    {},
    ...Object.keys(properties).map(name => {
      if (properties[name].type === 'sectionTitle') {
        return false
      }
      if (properties[name].type === 'krstype') {
        return false
      }
      if (properties[name].type === 'subform') {
        return false
      }
      if (properties[name].type === 'group') {
        const group = {}
        Object.keys(properties[name].properties).forEach(el => {
          group[el] = false
        })
        return group
      }
      if (properties[name].type === 'column') {
        const group = {}
        Object.keys(properties[name].properties).forEach(el => {
          Object.keys(properties[name].properties[el].properties).forEach(
            element => {
              group[element] = false
            }
          )
        })
        return group
      }
      return { [name]: false }
    })
  ),
  renderError: Object.assign(
    {},
    ...Object.keys(properties).map(name => {
      if (properties[name].type === 'sectionTitle') {
        return false
      }
      if (properties[name].type === 'krstype') {
        return false
      }
      if (properties[name].type === 'subform') {
        return false
      }
      if (properties[name].type === 'group') {
        const group = {}
        Object.keys(properties[name].properties).forEach(el => {
          group[el] = false
        })
        return group
      }
      if (properties[name].type === 'column') {
        const group = {}
        Object.keys(properties[name].properties).forEach(el => {
          Object.keys(properties[name].properties[el].properties).forEach(
            element => {
              group[element] = false
            }
          )
        })
        return group
      }
      return { [name]: false }
    })
  ),
  additional: {},
  isInvalid: false,
  isProcessing: false,
  isSubmitted: false,
  fetchCompareResource,
  compareValues: null,
  url:
    resource &&
    resource?.[process.env.REACT_APP_RESOURCE_ID] &&
    url.includes(':' + process.env.REACT_APP_RESOURCE_ID)
      ? url.replace(
          ':' + process.env.REACT_APP_RESOURCE_ID,
          resource[process.env.REACT_APP_RESOURCE_ID]
        )
      : url,
})
