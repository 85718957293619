import React, { useMemo } from 'react'
import { standard } from 'core/components/table/_columns/standard'
import { Paper } from 'core/components/Paper'
import { CollectionTable } from 'core/components/table'
import schema from 'core/_schema/user'
import provinceSchema from '_schema/province'
import routes from './routes'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { ButtonTrash } from 'core/components/buttons/button-trash'
import { makeStyles } from '@material-ui/styles'
import { DateCell } from 'core/components/table/cells/DateCell'

const useStyles = makeStyles(theme => ({
  removeContent: {
    position: 'absolute',
    bottom: theme.spacing(5),
    right: theme.spacing(4),
  },
}))

export const Collection = () => {
  const classes = useStyles()
  const columns = useMemo(
    () => [
      {
        ...standard('organizationName'),
        sortable: false,
        filterable: true,
        width: '20%',
      },
      {
        ...standard('email'),
        sortable: false,
        filterable: true,
        width: '10%',
      },
      {
        ...standard('organizationType.title'),
        sortable: false,
        filterable: false,
        width: '10%',
      },
      {
        ...standard('province.title'),
        sortable: false,
        filterable: true,
        filterName: 'province.uuid',
        filterSchema: provinceSchema.endpoint,
        width: '10%',
      },
      {
        ...standard('city'),
        sortable: false,
        filterable: true,
        width: '10%',
      },
      {
        ...standard('population.title'),
        sortable: false,
        filterable: false,
        width: '10%',
      },
      {
        ...standard('applicantProposalsFinishedCount'),
        sortable: false,
        filterable: true,
        width: '10%',
      },
      {
        ...standard('applicantProposalsAppliedCount'),
        sortable: false,
        filterable: true,
        width: '10%',
      },
      {
        accessor: 'organizationLastProjectDate',
        Cell: DateCell,
        sortable: true,
        width: '10%',
      },
    ],
    []
  )
  return (
    <>
      <Paper withPadding={false}>
        <SchemableCollection
          component={CollectionTable}
          path={`paths.${schema('applicant').endpoint}.get`}
          endpoint={schema('applicant').endpoint}
          customResourceSchema={schema('applicant').resource}
          columns={columns}
          autoWidth={false}
          filterable={true}
          filterInline={true}
          sortable={true}
          orderable={false}
          defaultSorters={'asc'}
          numerable={false}
          downloadCSV={true}
          downloadCSVFileTitle={'Lista-organizacji'}
          customFilters={[
            {
              name: 'isDeleted',
              value: 'false',
              title: 'isDeleted',
            },
          ]}
          resetable={false}
          paginable={true}
          definitionSchema={schema('applicant').resource}
          isRowLinkable={true}
          editPath={routes().item.path}
        />
        <div className={classes.removeContent}>
          <ButtonTrash text={'Kosz'} path={`/organizations/trash`} />
        </div>
      </Paper>
    </>
  )
}
