import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { FieldWrapper } from 'components/applicant/form'
import { translate } from 'core/_helpers/translate'
import { isEnableBudgetMenageAdv } from '_helpers/isEnableBudgetMenageAdv'
import { useContestBudgetCategories } from '_helpers/useContestBudgetCategories'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
  },
  row: {
    marginBottom: 22,
  },
  own_contribution_amout: {
    display: 'flex',
    maxWidth: 325,
    paddingRight: 25,
    position: 'relative',
  },
  pln: {
    position: 'absolute',
    right: 5,
    bottom: 35,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 26,
  },
}))

export const BudgetStep = ({
  contest,
  state,
  properties,
  resource,
  setValue,
  setError,
  sidebarNodeRef,
}) => {
  const classes = useStyles()

  const validators = useMemo(() => {
    return {
      subsidyAmount: [`maxValue(${contest.grantMaxAmount})`],
    }
  }, [contest.grantMaxAmount])

  useEffect(() => {
    if (state.values.subsidyAmount > 0) {
      const subsidyAmountSum = state.values.budgets.reduce(
        (sum, budget) => sum + (budget.subsidyAmount || 0),
        0
      )
      if (Number(subsidyAmountSum) !== Number(state.values.subsidyAmount)) {
        setError(
          'subsidyAmount',
          'Suma kwot w budżecie musi być równa kwocie wnioskowanej od FCP'
        )
      } else {
        setError('subsidyAmount', null)
      }
    }
  }, [setError, state.values])

  const budgetCategories = useContestBudgetCategories(contest.uuid)

  return (
    <div className={classes.root}>
      {isEnableBudgetMenageAdv(state.values.budgets, budgetCategories.data) && (
        <>
          <div className={classes.title}>
            Kwota wnioskowana od FCP (w zł) - wymagane
          </div>
          <div>
            Wypełnij to pole, aby włączyć możliwość wprowadzenia kosztów
            projektu. Pamiętaj, suma wszystkich wartości w poszczególnych polach
            - Wnioskowane do FCP - musi być równa tej kwocie.
          </div>
          <div className={classes.own_contribution_amout}>
            <FieldWrapper
              name="subsidyAmount"
              property={properties.subsidyAmount}
              resource={resource}
              state={state}
              setValue={setValue}
              setError={setError}
              contest={contest}
              validators={validators.subsidyAmount}
              label={'Całkowita kwota wnioskowana od FCP'}
            />
            <div className={classes.pln}>
              {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
            </div>
          </div>
        </>
      )}

      <FieldWrapper
        name="budgets"
        property={properties.budgets}
        resource={resource}
        state={state}
        setValue={setValue}
        setError={setError}
        contest={contest}
        sidebarNodeRef={sidebarNodeRef}
        disabled={!state.values.subsidyAmount > 0}
        subsidyAmount={state.values.subsidyAmount}
      />
    </div>
  )
}

BudgetStep.propTypes = {
  contest: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  sidebarNodeRef: PropTypes.object,
}
