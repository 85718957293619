import React, { useState, useCallback } from 'react'
import { ReactComponent as HintIcon } from 'theme/icons/hint.svg'
import { IconButton, Tooltip, Typography } from '@material-ui/core'
import { CustomDialog } from 'core/components/Dialog'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles({
  help: {
    padding: 0,
    marginLeft: 10,
    marginTop: -2.5,
    position: 'absolute',
  },
})

export const HelpModule = ({ title, description }) => {
  const classes = useStyles()
  const [state, setState] = useState({
    open: false,
  })

  const handleToogle = useCallback(() => {
    setState(prevState => ({ ...prevState, open: !prevState.open }))
  }, [])

  return (
    <>
      <Tooltip title={`Dowiedz się jak podać pole`} arrow placement="top">
        <IconButton onClick={handleToogle} className={classes.help}>
          <HintIcon />
        </IconButton>
      </Tooltip>
      <CustomDialog
        handleToogle={handleToogle}
        open={state.open}
        title={title}
        component={() => (
          <Typography variant={'body1'} color={'primary'}>
            {description}
          </Typography>
        )}
        showActions={false}
      />
    </>
  )
}
