import { makeStyles } from '@material-ui/styles'

export const useBudgetCollectionTypeStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,

    '& > div': {
      overflow: 'auto'
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 26,
  },
  header: {
    display: 'flex',
    minWidth: 'fit-content',
    '& > div': {
      marginRight: 20,
      width: 153,

    },
    '& $header_number': {
      width: 20,
    },
  },
  header_number: {
    width: 20,
    marginRight: 20,
  },

  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #ccc',
    paddingTop: 20,
  },
  container: {
    display: 'flex',
  },
  container_inner: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  sidebar: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 12,
    '&> *': {
      cursor: 'pointer',
    },
  },
  outer_summary_root: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  outer_summary_row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  error: {
    color: theme.palette.error.main,
  },
}))
