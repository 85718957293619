import 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import DateFnsUtils from '@date-io/date-fns'
import plLocale from 'date-fns/locale/pl'
import moment from 'moment'
import clsx from 'clsx'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { InputAdornment, FormHelperText } from '@material-ui/core'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
import { ReactComponent as DateTimeIcon } from '../../../../theme/icons/date.svg'
import { StringType } from './StringType'
import { useStyles } from './fields.style'

const LOCALES = {
  pl: plLocale,
}

const FORMAT = 'yyyy-MM-dd'

const DATE_PICKER_ERRORS = [
  translate('T_GENERAL_INVALID_DATE'),
  translate('T_GENERAL_INVALID_MIN_DATE'),
  translate('T_GENERAL_INVALID_MAX_DATE'),
]

export const DateType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  minDate = '1900-01-01',
  maxDate = '2100-01-01',
  width = '350px',
}) => {
  const [id] = useState(randomHash())

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleChange = value => {
    const date = value ? moment(value).format(FORMAT.toUpperCase()) : null
    setValue(name, date)
    validateField(date)
    setAnchorEl(null)
  }

  const handleError = message => {
    if (message && message !== error) {
      setError(name, message)
    }
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()

  const handleOpen = event => {
    !disabled && setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div
      style={{
        maxWidth: width,
      }}
    >
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={LOCALES[process.env.REACT_APP_LOCALE]}
      >
        <>
          <DatePicker
            id={id}
            name={name}
            autoOk
            label={
              translate(label && label.text ? label.text : label ? label : '') +
              (validators && validators.includes('required') ? ' *' : '')
            }
            value={![null, undefined].includes(value) ? new Date(value) : null}
            disabled={disabled}
            disableToolbar
            variant="inline"
            format={FORMAT}
            margin="normal"
            invalidDateMessage={DATE_PICKER_ERRORS[0]}
            minDateMessage={DATE_PICKER_ERRORS[1]}
            maxDateMessage={DATE_PICKER_ERRORS[2]}
            onChange={handleChange}
            onError={handleError}
            PopoverProps={{
              anchorEl: anchorEl,
              onClose: handleClose,
              classes: { root: classes.dateTime },
              PaperProps: {
                classes: {
                  root: classes.dateTimePaper,
                },
              },
            }}
            endIcon={
              <InputAdornment
                position="end"
                onClick={handleOpen}
                disablePointerEvents={disabled}
                className={clsx(classes.field_icon, classes.field_icon_pointer)}
              >
                <DateTimeIcon />
              </InputAdornment>
            }
            TextFieldComponent={StringType}
            setError={() => false}
            type="text"
            renderError={renderError}
            setValue={() => false}
            open={Boolean(anchorEl)}
            readonly="readonly"
            minDate={minDate || '1900-01-01'}
            maxDate={maxDate || '2100-01-01'}
          />
        </>
      </MuiPickersUtilsProvider>
      {renderError && !!error && !DATE_PICKER_ERRORS.includes(error) && (
        <FormHelperText className={classes.helper} error={true}>
          {translate(error)}
        </FormHelperText>
      )}
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue && moment(compareValue).format(FORMAT.toUpperCase())}
        </div>
      )}
    </div>
  )
}

DateType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
}
