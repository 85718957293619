import userSchema from './user'

const schema = {
  endpoint: '/users/profile',
  profile: {
    definition: 'User-user:read',
    properties: {
      email: userSchema('user').resource.properties.email,
      roles: {},
    },
    get: '/users/profile',
    set: '/users/profile',
  },
  // changePassword: {
  //   properties: {
  //     currentPassword: {
  //       type: 'password',
  //       description: 'T_FORM_FIELD_PASSWORD',
  //       validate: ['required'],
  //     },
  //     password: adminSchema.resource.properties.password,
  //   },
  //   set: '/users/profile',
  // },
}

export default schema
