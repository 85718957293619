import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { createPortal } from 'react-dom'
import { Button as OriginalButton } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'

export const SubmitButton = ({
  title = null,
  variant = 'contained',
  handleSubmit,
  disabled = false,
  nodeRef = null,
  size = 'large',
  classes = {},
}) => {
  const button = (
    <Button
      title={title}
      variant={variant}
      size={size}
      handleSubmit={handleSubmit}
      disabled={disabled}
      classes={classes}
    />
  )

  return nodeRef ? createPortal(button, nodeRef) : button
}

const Button = ({
  title = null,
  variant = 'contained',
  handleSubmit,
  disabled = false,
  classes = {},
  size = 'large'
}) => (
  <div className={clsx(classes.submit)}>
    <OriginalButton
      type="submit"
      variant={variant}
      color="secondary"
      size={size}
      disabled={disabled}
      onClick={handleSubmit}
    >
      {(title || translate('T_FORM_SAVE'))}
    </OriginalButton>
  </div>
)

SubmitButton.propTypes = {
  title: PropTypes.string,
  variant: PropTypes.oneOf([
    'contained', 'outlined', 'inherit'
  ]),
  size: PropTypes.oneOf([
    'large', 'small'
  ]),
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  nodeRef: PropTypes.object,
  classes: PropTypes.shape({
    submit: PropTypes.string,
  }),
}
