import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import clsx from 'clsx'
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    '&> div': {
      borderRadius: 0,
      '&> fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  select: {
    backgroundColor: 'white',
    paddingTop: 9,
    paddingBottom: 9,
  },
  error: {
    marginLeft: 0,
  },
}))

export const SelectType = ({
  name,
  defaultValue: initDefaultValue = null,
  value,
  choices,
  error = false,
  renderError = false,
  validators,
  setValue,
  setError,
  disabled,
  classes = {},
  custom = false,
}) => {
  const [defaultValue, setDefaultValue] = useState(initDefaultValue)

  const [id] = useState(randomHash())

  const handleChange = e => {
    const value = e.target.value

    setValue(name, value)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(value)
  }, [validateField, value])

  useEffect(() => {
    if (!value && defaultValue) {
      setValue(name, defaultValue)
      setDefaultValue(null)
    }
  }, [value, setValue, name, defaultValue, setDefaultValue])

  const defaultClasses = useStyles()

  return (
    <FormControl
      error={renderError && !!error}
      disabled={disabled}
      className={clsx(defaultClasses.root, classes.root)}
      variant="outlined"
    >
      <Select
        id={id}
        name={name}
        onChange={handleChange}
        value={value || defaultValue || ''}
        classes={{
          select: defaultClasses.select,
        }}
      >
        {(!validators || !validators.includes('required')) && (
          <MenuItem value={null}>&nbsp;</MenuItem>
        )}
        {custom
          ? choices?.map(item => (
              <MenuItem value={item['@id']} key={item['@id']}>
                {item.title}
              </MenuItem>
            ))
          : Object.keys(choices).map(key => (
              <MenuItem value={key} key={key}>
                {translate(choices[key])}
              </MenuItem>
            ))}
      </Select>
      <FormHelperText classes={{ contained: defaultClasses.error }}>
        {translate(renderError && error)}
      </FormHelperText>
    </FormControl>
  )
}

SelectType.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  choices: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
}
