const CONTEST_PAGE_BLOCK_TYPE_TEXT = 'TEXT'
export const text = () => ({
  name: CONTEST_PAGE_BLOCK_TYPE_TEXT,
  label: 'T_GENERAL_BLOCK_TYPE_TEXT',
  properties: {
    type: {
      type: 'hidden',
      defaultValue: CONTEST_PAGE_BLOCK_TYPE_TEXT,
    },
    title: {
      type: 'string',
      label: 'Tytuł bloku',
      width: '650px',
    },
    description: {
      label: 'Treść',
      type: 'tinymce',
      fullWidth: true,
      formWidth: 650,
    },
  },
})
