import React, { useCallback, useMemo } from 'react'
import { CollectionHeader } from '../../../core/components/CollectionHeader'
import { translate } from '../../../core/_helpers/translate'
import routes from './routes'
import { Form } from '../../../core/pages'
import schema from '../../../_schema/form'
import { Grid, Typography } from '@material-ui/core'
import Moment from 'react-moment'
import { SectionCollection } from 'components/sectionForms/SectionCollection'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const url = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])

  const prevurl = useMemo(
    () => `${schema.endpoint}/preview/${match.params.id}`,
    [match.params.id]
  )

  const handleBeforeSubmit = body => {
    const obj = body
    delete obj.formSections
    return obj
  }

  const headerTitle = useCallback(
    resource => (
      <CollectionHeader
        hideButton
        hideButtonBack={false}
        buttonTitleLeft={translate(resource.type)}
        buttonPathLeft={routes().index.path}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {resource.title}
          </Grid>
          <Grid item xs={12} md={3}>
            {resource.creator && (
              <>
                <Typography variant={'h3'}>{translate('Utworzony')}</Typography>
                <Typography variant={'body2'}>
                  <Moment
                    format={'DD.MM.YYYY'}
                    date={new Date(resource.createdAt)}
                  />{' '}
                  {resource.creator.firstName} {resource.creator.lastName}
                </Typography>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {resource.updater && (
              <>
                <Typography variant={'h3'}>
                  {translate('Zmodyfikowany')}
                </Typography>
                <Typography variant={'body2'}>
                  <Moment
                    format={'DD.MM.YYYY'}
                    date={new Date(resource.updatedAt)}
                  />{' '}
                  {resource.updater.firstName} {resource.updater.lastName}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </CollectionHeader>
    ),
    []
  )

  return (
    <>
      <Form
        header={headerTitle}
        url={url}
        method={'PUT'}
        iri={url}
        collectionPath={routes().index.path}
        definitionSchema={definitions[schema.resource_edit.definition]}
        customResourceSchema={schema.resource_edit}
        showSubmitAndStayButton={true}
        showCancelButton={true}
        showSubmitButton={false}
        submitAndStayButtonLabel={translate('Zapisz')}
        storeCollectionId={url}
        sidebar={true}
        key={url}
        fetchCompareResource={false}
        buttonsFixed={false}
        width="100%"
        sidebarWidth={4}
        sidebarStatusIsSwitch={false}
        sidebarUrl={url}
        sidebarStatable={false}
        buttonSaveInSidebar={true}
        sidebarButtonInline={true}
        preview={true}
        prevurl={prevurl}
        customBeforeSubmit={handleBeforeSubmit}
      >
        <SectionCollection
          endpoint={schema.subresources.formSection.endpoint}
          pid={match.params.id}
          parentIri={url}
          definitionSchema={
            definitions[schema.subresources.formSection.definition]
          }
          key={url}
          parent={'form'}
          titleAccessor={'title'}
          definitions={definitions}
        />
      </Form>
    </>
  )
}
