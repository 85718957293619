import { makeStyles } from '@material-ui/styles'

export const useStyleTable = makeStyles(theme => ({
  tableHead: {
    borderBottom: `1px solid ${theme.palette.separator}`,
  },
  tableCell: {
    border: 0,
    color: theme.palette.primary.main,
  },
  tableCellNoWrap: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  table: {
    marginBottom: theme.spacing(3),
  },
  tableHeadCell: {
    color: theme.elements.table.tHeadC,
    fontSize: 16,
    fontStyle: 'italic',
    border: 0,
    paddingBottom: theme.spacing(3),
  },
  tableTitle: {
    color: theme.elements.table.tHeadC,
    fontSize: 18,
    border: 0,
    paddingBottom: theme.spacing(3),
    fontWeight: 600,
  },
  cellTSum: {
    color: theme.palette.primary.main,
    fontSize: 16,
    border: 0,
    fontWeight: 600,
  },
  cellDivider: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    '& hr': {
      display: 'block',
      width: '100%',
      // flexGrow: 1,
      backgroundColor: 'transparent',
      borderBottom: `1px dashed ${theme.palette.separator}`,
    },
    '& span': {
      display: 'block',
      width: '100%',
      flexGrow: 1,
      whiteSpace: 'nowrap',
      paddingRight: theme.spacing(3),
    },
  },
  tableCellFontGreater: {
    fontSize: theme.typography.pxToRem(16),
  },
  tableCellClick: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  footerCell: {
    paddingTop: theme.spacing(4),
  },
  rowStrong: {
    '& td span': {
      fontWeight: 700,
    }
  }
}))
