import React, { useCallback, useEffect, useState } from 'react'
import { SectionTitle } from 'core/components/SectionTitle'
import { Button, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import schemaGuideline, {
  CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_FORMAL,
  RATE_FAILED,
  RATE_PASSED,
} from '_schema/contestProposalRateGuidelines'
import { translate } from 'core/_helpers/translate'
import { RadioType } from './RadioType'
import { StringType } from 'core/components/form/fields/StringType'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import schema from '_schema/proposalEvents'
import { notification } from 'core/_helpers/notification'
import schemaFormalRate from '_schema/proposalFormalRate'
import {
  CONTRACT_STATUS_APPLIED,
  REALIZATION_STATUS_FINISHED,
} from '_schema/proposal'
import moment from 'moment'

const useStyle = makeStyles(theme => ({
  item: {
    marginBottom: theme.spacing(8),
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(5),
  },
}))

const ItemRate = ({ rate, index, setValue, disabled }) => {
  const classes = useStyle()
  return (
    <div className={classes.item}>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <Typography variant={'h3'} component={'span'} color={'primary'}>
            {index}.
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography variant={'subtitle1'} color={'primary'}>
            {rate.desc}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={1} md={1} />
        <Grid item xs={5} md={3}>
          <RadioType
            value={RATE_PASSED}
            name={'rate'}
            rate={rate.rate}
            label={translate(`T_RATE_FORMAL_${RATE_PASSED}`)}
            disabled={disabled}
            color={'blue'}
            onChange={event =>
              setValue('rate', event.target.value, rate.contestRateGuideline)
            }
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <RadioType
            value={RATE_FAILED}
            name={'rate'}
            rate={rate.rate}
            label={translate(`T_RATE_FORMAL_${RATE_FAILED}`)}
            disabled={disabled}
            color={'red'}
            onChange={event =>
              setValue('rate', event.target.value, rate.contestRateGuideline)
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <StringType
            name={'description'}
            setValue={(name, value) =>
              setValue(name, value, rate.contestRateGuideline)
            }
            disabled={disabled}
            renderError={false}
            type={'textarea'}
            setError={() => false}
            error={false}
            label={'note'}
            width={'100%'}
            value={rate.description}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export const RateFormal = ({
  resource,
  updateResource,
  updateGlobalResource,
  event,
  bis = false,
}) => {
  const classes = useStyle()
  const [state, setState] = useState([])
  const [fetchingRateEnd, setFetchingRateEnd] = useState(false)

  const getRates = useCallback(() => {
    const iri = `${schemaFormalRate.endpoint}?event.uuid=${event}&pagination=false`
    fetchDataHandleAuthError(iri, 'GET', {}, resp => {
      setState(
        resp['hydra:member'].map(el => {
          return {
            proposal: el.proposal,
            contestRateGuideline: el.rateGuideline['@id'],
            rate: el.rate,
            description: el.description,
            desc: el.rateGuideline.title,
            id: el['@id'],
          }
        })
      )
      setFetchingRateEnd(true)
    })
  }, [event])

  useEffect(() => {
    getRates()
  }, [event, getRates])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    const iri = `${schemaGuideline(null).endpoint}?contest.uuid=${
      resource.contest.uuid
    }&type=${CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_FORMAL}&pagination=false`
    if (fetchingRateEnd && state.length === 0) {
      fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
        setFetchingRateEnd(false)
        setState(
          resp['hydra:member'].map(el => {
            return {
              rate: null,
              desc: el.title,
              proposal: resource['@id'],
              contestRateGuideline: el['@id'],
              description: '',
              id: null,
            }
          })
        )
      })
    }
    return () => controller.abort()
  }, [state, fetchingRateEnd, resource])

  const handleChangePoints = (name, value, rateGuideline) => {
    setState(prevState => {
      const index = prevState.findIndex(
        el => el.contestRateGuideline === rateGuideline
      )
      const array = [...prevState]
      array[index][name] = value
      return array
    })
  }

  const handleSubbmit = useCallback(() => {
    let formalRates = state.map(el => {
      const rate = {
        rate: el.rate,
        description: el.description,
        proposal: el.proposal,
        rateGuideline: el.contestRateGuideline,
      }
      if (el.id) {
        rate['@id'] = el.id
      }
      return rate
    })

    fetchDataHandleAuthError(
      `${schema.endpointFormalRate}/${event}`,
      'PUT',
      {
        body: JSON.stringify({
          formalRates,
        }),
      },
      e => {
        let formalRate = RATE_PASSED
        e.formalRates.forEach(el => {
          if (el.rate === RATE_FAILED) {
            formalRate = RATE_FAILED
          }
        })
        notification('success', translate('Ocena została wystawiona'))
        getRates()
        updateResource()
        updateGlobalResource({ formalRateStatus: formalRate })
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [state, event, getRates, updateResource, updateGlobalResource])

  const formalRatingDateFrom = moment(resource.contest.formalRatingDateFrom)
  const formalRatingDateTo = moment(resource.contest.formalRatingDateTo)

  const disabled =
    resource.contractStatus === CONTRACT_STATUS_APPLIED ||
    resource.realizationStatus === REALIZATION_STATUS_FINISHED ||
    (!bis && moment().isAfter(formalRatingDateTo, 'day')) ||
    moment().isBefore(formalRatingDateFrom, 'day')

  return (
    <>
      <SectionTitle
        label={'Ocena formalna'}
        divider={false}
        marginTopSm={true}
      />
      <SectionTitle label={'Kryteria'} divider={true} />
      {state.map((el, index) => (
        <ItemRate
          key={index}
          rate={el}
          index={index + 1}
          setValue={handleChangePoints}
          disabled={disabled}
        />
      ))}
      {!disabled && (
        <div className={classes.button}>
          <Button color={'primary'} variant="contained" onClick={handleSubbmit}>
            {translate('Zapisz')}
          </Button>
        </div>
      )}
    </>
  )
}
