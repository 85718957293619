import schemaSection from './formSection'

export const FORM_TYPE_APPLICATION_APPLICANT = 'APPLICATION_APPLICANT'
export const FORM_TYPE_APPLICATION_PROJECT = 'APPLICATION_PROJECT'
export const FORM_TYPE_TRAINING_INQUIRY = 'TRAINING_INQUIRY'
export const FORM_TYPE_APPOINTMENT = 'APPOINTMENT'
export const FORM_TYPE_AGREEMENT_TEMPLATE = 'AGREEMENT_TEMPLATE'
export const FORM_TYPE_RELATION = 'RELATION'
export const FORM_TYPE_PARTIAL_REPORT = 'PARTIAL_REPORT'
export const FORM_TYPE_FINAL_REPORT = 'FINAL_REPORT'

const schema = {
  endpoint: '/forms',
  resource: {
    definition: 'Form-form:read',
    properties: {
      title: {
        description: {
          text: 'Nazwa szablonu',
        },
      },
      createdAt: {
        description: {
          text: 'Utworzony',
        },
        addInfo: 'creator',
      },
      updatedAt: {
        description: {
          text: 'Zmodyfikowany',
        },
        addInfo: 'updater',
      },
      usageCount: {
        description: {
          text: 'Użyty',
        },
      },
    },
  },
  resource_new: {
    definition: 'Form-form:write',
    properties: {
      title: {
        type: 'string',
        validate: ['required'],
        label: 'Nazwa szablonu',
      },
      type: {
        type: 'hidden',
        defaultValue: '',
      },
    },
  },
  resource_edit: {
    definition: 'Form-form:write',
    properties: {
      title: {
        type: 'string',
        validate: ['required'],
        label: 'Nazwa szablonu',
      },
    },
  },
  subresources: {
    formSection: {
      endpoint: schemaSection.endpoint,
      definition: schemaSection.resource.definition,
    },
  },
}

export default schema
