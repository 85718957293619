import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'
import { DataInfo } from 'components/DataInfo'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles({
  row_post: {
    maxWidth: 600,
  },
})

export const CompanyAdditionalInfoBlock = ({ values, populations }) => {
  const classes = useStyles()

  return (
    <div>
      <Grid container spacing={3} className={classes.row_post}>
        <Grid item xs={6}>
          <DataInfo
            label={translate('T_MODULE_ORGANIZATION_DATA_POPULATION')}
            data={
              populations?.find(
                population => population['@id'] === values?.population
              )?.title || ''
            }
          />
        </Grid>
        <Grid item xs={6}>
          <DataInfo
            label={translate(
              'T_MODULE_ORGANIZATION_DATA_POPULATION_DATA_SOURCE'
            )}
            data={values?.populationDataSource || ''}
          />
        </Grid>
      </Grid>
      <DataInfo
        label={translate('T_MODULE_ORGANIZATION_DATA_WEBSITE')}
        data={values?.website || ''}
      />
      <DataInfo
        label={translate(
          'T_MODULE_ORGANIZATION_DATA_ORGANIZATION_CREATION_DATE'
        )}
        data={`${values?.organizationCreationDate || ''}`}
      />
    </div>
  )
}

CompanyAdditionalInfoBlock.propTypes = {
  values: PropTypes.object,
  populations: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
}
