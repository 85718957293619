import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Card, Typography, AppBar } from '@material-ui/core'
import { Logo } from 'core/components/Logo'
import { translate } from 'core/_helpers/translate'
import { useStyles } from '.'
import clsx from 'clsx'
import { Footer } from '../../components/footer/Footer'

export const ChangeEmail = ({ match }) => {
  const [state, setState] = useState({
    isSubmitted: true,
    isInvalid: true,
    isFetching: true,
    isCompleted: false,
    errorMessage: '',
  })
  const url = `${process.env.REACT_APP_API_ENTRYPOINT}/security/changeEmailConfirm/user/${match.params.id}/${match.params.hash}`
  const classes = useStyles()

  useEffect(() => {
    fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    })
      .then(response =>
        response.text().then(text =>
          text
            ? JSON.parse(text)
            : {
                success: false,
                errorMessage: translate('Error'),
              }
        )
      )
      .then(data => {
        if (data['hydra:description'] !== undefined) {
          setState(state => ({
            ...state,
            errorMessage: translate(data['hydra:description']),
            isFetching: false,
          }))

          return
        }

        setState(state => ({
          ...state,
          isFetching: false,
          isCompleted: true,
        }))
      })
  }, [url])

  const { errorMessage, isCompleted, isFetching } = state

  return (
    <div className={classes.root}>
      <Logo className={classes.logo} />
      <Card className={classes.card}>
        <AppBar position="static" className={classes.login_app_bar}>
          <div className={clsx(classes.login_menu, classes.login_menu_title)}>
            <Typography
              variant="h1"
              className={clsx(classes.login_title, classes.login_title_full)}
              color="secondary"
            >
              <span>Zmiana e-maila</span>
            </Typography>
          </div>
        </AppBar>
        {!isFetching ? (
          <>
            {!isCompleted ? (
              <div className={classes.form_message}>
                <div className={classes.error_message}>
                  {errorMessage ||
                    'Błąd zmiany e-maila. Prawdopodobnie Twój link wygasł.'}
                </div>
                <Link to="/login" className={classes.lower_link}>
                  {translate('T_GENERAL_BACK_TO_LOGIN')}
                </Link>
              </div>
            ) : (
              <div className={classes.form_message}>
                <div className={classes.reset_message}>
                  Twój e-mail został zmieniony.
                </div>
                <Link to="/login" className={classes.lower_link}>
                  {translate('T_GENERAL_BACK_TO_LOGIN')}
                </Link>
              </div>
            )}
          </>
        ) : (
          <img
            className={classes.spinner}
            src="/img/spinner.svg"
            alt="loading"
          />
        )}
      </Card>
      <Footer />
    </div>
  )
}
