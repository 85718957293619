import React, { useState, useEffect, useCallback } from 'react'

import PropTypes from 'prop-types'
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { CustomInput, useCustomSelect, useStyles } from './fields.style'
import { ReactComponent as IconArrow } from 'theme/icons/arrow-down.svg'
import { useKrsDataApi } from '_helpers/useKrsDataApi'

export const KrsType = ({
  values,
  setValue,
  setError,
  error = false,
  renderError = false,
}) => {
  const classes = useStyles()
  const classesCustomSelect = useCustomSelect()
  const [type, setType] = useState('')
  const [valueLocal, setValueLocal] = useState('')
  const handleChange = {
    type: e => {
      setType(e.target.value)
    },
    value: e => {
      setValueLocal(e.target.value.replace(/\D/g, ''))
    },
  }

  useEffect(() => {
    values.forEach(el => {
      if (el === type) {
        setValue(type, valueLocal)
      } else {
        setValue(el, '')
      }
    })
  }, [type, valueLocal, values]) // eslint-disable-line react-hooks/exhaustive-deps

  const menuProps = {
    classes: {
      paper: classesCustomSelect.paper,
      list: classesCustomSelect.list,
    },
  }

  const [krsIsFetching, setKrsIsFetching] = useState(false)

  useKrsDataApi(
    krsIsFetching,
    setKrsIsFetching,
    type,
    valueLocal,
    setValue,
    setError,
    true
  )

  const handleSetData = useCallback(() => setKrsIsFetching(true), [])

  return (
    <>
      <Grid
        container
        spacing={2}
        // style={{
        //   maxWidth: width,
        // }}
      >
        <Grid item xs={4}>
          <FormControl className={classes.form_control_select}>
            <Select
              displayEmpty
              value={type}
              className={classesCustomSelect.root}
              MenuProps={menuProps}
              onChange={handleChange.type}
              IconComponent={() => (
                <IconArrow className={classesCustomSelect.icon} />
              )}
            >
              <MenuItem className={classesCustomSelect.item} value="">
                {translate('T_SELECT')}
              </MenuItem>
              {values.map(el => {
                return (
                  <MenuItem
                    className={classesCustomSelect.item}
                    key={el}
                    value={el}
                  >
                    {translate('T_KRSTYPE_' + el)}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl error={renderError && !!error}>
            <InputLabel htmlFor="krs-value" className={classes.labelHidden}>
              {type}
            </InputLabel>
            <CustomInput
              id="krs-value"
              value={valueLocal}
              onChange={handleChange.value}
              disabled={['0', ''].includes(type)}
              type={'text'}
              error={renderError && !!error}
              classes={{
                input: classes.input,
              }}
            />
            <FormHelperText id={'krs-value'}>
              {translate(renderError && error ? error : '')}
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Button
            type={'button'}
            variant={'outlined'}
            size={'small'}
            color={'secondary'}
            style={{
              width: '100%',
              height: 38,
            }}
            disabled={type === '' || type === 0 || krsIsFetching}
            onClick={handleSetData}
          >
            {krsIsFetching ? (
              <CircularProgress size={24} />
            ) : (
              <span>{translate('Pobierz dane')}</span>
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

KrsType.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  width: PropTypes.string,
  setValue: PropTypes.func.isRequired,
}
