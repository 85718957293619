import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { NavLink } from 'react-router-dom'
import { Tab } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none',
    textTransform: 'none',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    color: theme.elements.menu.text,
    borderBottom: 0,
    width: 'fit-content',
    minWidth: 100,
    '& *': {
      color: theme.elements.menu.text,
      fontWeight: 600,
      fontSize: 14,
    },
  },
  active: {
    color: theme.elements.menu.active,
    borderBottom: 0,
    '& *': {
      color: theme.elements.menu.active,
    },
  },
}))

export const MenuItem = ({ item, exact = false }) => {
  const classes = useStyles()

  return (
    <Tab
      component={NavLink}
      to={item.path}
      className={classes.link}
      activeClassName={classes.active}
      label={translate(item.title)}
      role="navigation"
    />
  )
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    exact: PropTypes.bool,
  }).isRequired,
  exact: PropTypes.bool,
}
