import { makeStyles } from '@material-ui/styles'

export const useShowStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
  },
  description_col: {
    paddingTop: 60,
    paddingBottom: 60,
    paddingLeft: 45,
    paddingRight: 45,
  },
  description_back_to_list: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 17,
    textDecoration: 'none',
    color: theme.palette.primary.main,
    '&> :first-child': {
      marginRight: 10,
    },
  },
  description: {
    paddingTop: 60,
    paddingLeft: '15%',
    paddingRight: '15%',
  },
  description_title: {
    fontSize: 26,
    fontWeight: 'bold',
    marginBottom: 28,
  },
  description_header: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 28,
  },
  description_lead: {
    marginBottom: 28,
  },
  description_block: {
    paddingBottom: 36,
  },
  info_col: {
    paddingTop: 60,
    paddingBottom: 60,
    paddingRight: 100,
    paddingLeft: 100,
    backgroundColor: 'white',
  },
  info_apply_for_the_amount: {
    marginTop: 50,
    fontSize: 20,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  info_button: {
    margin: '30px auto 0 auto',
    display: 'flex',
    justifyContent: 'center',
  },
}))
