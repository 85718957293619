import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { StringType } from 'components/applicant/form/fields/StringType'
import { ResourceType } from 'components/applicant/form/fields/ResourceType'
import { getBlockFieldsMapping } from 'pages/applicant/Proposals/_helpers/getBlockFieldsMapping'
import { blockTypes } from 'pages/applicant/Proposals/_helpers/blockTypes'
import { translate } from 'core/_helpers/translate'
import provinceSchema from '_schema/province'

const useStyles = makeStyles({
  row: {
    marginTop: 8
  },
  row_post: {
    display: 'flex',
    '&> *:first-child': {
      marginRight: 20
    }
  },
  label: {
    marginBottom: 5
  },
  input: {
    width: 350,
  },
  input_post_code: {
    width: 80
  },
})

export const AddressBlock = ({
  data,
  values,
  errors,
  renderError,
  globalRenderError,
  setValue,
  setError,
  disabled
}) => {
  const mapping = useMemo(
    () => getBlockFieldsMapping(blockTypes.ADDRESS, data),
    [data]
  )

  const validators = useMemo(() => ({
      post: mapping.postOffice.required ? [ 'required' ] : [],
      address: mapping.address.required ? [ 'required' ] : [],
      city: mapping.city.required ? [ 'required' ] : [],
      commune: mapping.commune.required ? [ 'required' ] : [],
      district: mapping.district.required ? [ 'required' ] : [],
      province: mapping.province.required ? [ 'required' ] : [],
    }),
    [mapping]
  )

  const classes = useStyles()

  return (
    <div>
      <div className={classes.row_post}>
        <div>
          <div className={classes.label}>
            {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_POST_OFFICE')}
          </div>
          <StringType
            type="string"
            name="postOffice"
            value={values?.postOffice}
            error={errors?.postOffice}
            renderError={globalRenderError && renderError?.postOffice}
            disabled={disabled}
            validators={validators.post}
            setValue={setValue}
            setError={setError}
            classes={{
              root: classes.input
            }}
          />
        </div>
        <div>
          <div className={classes.label}>
            {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_POST_CODE')}
          </div>
          <StringType
            type="string"
            name="postCode"
            value={values?.postCode}
            error={errors?.postCode}
            renderError={globalRenderError && renderError?.postCode}
            disabled={disabled}
            validators={validators.post}
            setValue={setValue}
            setError={setError}
            classes={{
              root: classes.input_post_code
            }}
          />
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.label}>
          {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_ADDRESS')}
        </div>
        <StringType
          type="string"
          name="address"
          value={values?.address}
          error={errors?.address}
          renderError={globalRenderError && renderError?.address}
          disabled={disabled}
          validators={validators.address}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input
          }}
        />
      </div>
      <div className={classes.row}>
        <div className={classes.label}>
          {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_CITY')}
        </div>
        <StringType
          type="string"
          name="city"
          value={values?.city}
          error={errors?.city}
          renderError={globalRenderError && renderError?.city}
          disabled={disabled}
          validators={validators.city}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input
          }}
        />
      </div>
      <div className={classes.row}>
        <div className={classes.label}>
          {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_COMMUNE')}
        </div>
        <StringType
          type="string"
          name="commune"
          value={values?.commune}
          error={errors?.commune}
          renderError={globalRenderError && renderError?.commune}
          disabled={disabled}
          validators={validators.commune}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input
          }}
        />
      </div>
      <div className={classes.row}>
        <div className={classes.label}>
          {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_DISTRICT')}
        </div>
        <StringType
          type="string"
          name="district"
          value={values?.district}
          error={errors?.district}
          renderError={globalRenderError && renderError?.district}
          disabled={disabled}
          validators={validators.district}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input
          }}
        />
      </div>
      <div className={classes.row}>
        <div className={classes.label}>
          {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_PROVINCE')}
        </div>
        <ResourceType
          name="province"
          endpoint={`${provinceSchema.endpoint}?order[title]=desc&pagination=false`}
          titleAccessor="title"
          value={values?.province}
          error={errors?.province}
          renderError={globalRenderError && renderError?.province}
          disabled={disabled}
          validators={validators.province}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input
          }}
        />
      </div>
    </div>
  )
}

AddressBlock.propTypes = {
  data: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
