import React from 'react'
import { Route } from 'react-router-dom'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { Show } from './Show'
import { roles } from '_helpers/roles'
import schema from '_schema/contests'

const routes = () => {
  return {
    show: {
      type: Route,
      render: props => (
        <SchemableComponent path="definitions" component={Show} {...props} />
      ),
      path: `${schema.endpoint}/:id`,
      exact: true,
      resourceAccess: [ roles.APPLICANT ],
    },
  }
}

export default routes
