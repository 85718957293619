import React, { useCallback, useState } from 'react'
import { useOrganizationDataFormStyles } from './OrganizationDataForm.styles'
import clsx from 'clsx'
import { Button, Typography } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { CustomDialog } from 'core/components/Dialog'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

export const OrganizationEmailReset = ({ resource, setValue, url }) => {
  const classes = useOrganizationDataFormStyles()
  const [state, setState] = useState({
    email: resource.email,
    error: false,
    renderError: false,
    open: false,
    isFetching: false,
  })

  const handleToogle = useCallback(() => {
    setState(prevState => ({ ...prevState, open: !prevState.open }))
  }, [])

  const handleConfirm = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      isFetching: !prevState.isFetching,
      error: false,
      renderError: false,
    }))
    fetchDataHandleAuthError(
      url,
      'POST',
      { body: JSON.stringify({ email: state.email }) },
      resp => {
        if (resp['@id']) {
          setValue('email', state.email)
          setState(prevState => ({
            ...prevState,
            open: !prevState.open,
            isFetching: !prevState.isFetching,
          }))
          notification(
            'success',
            'Wysłano link resetujący hasło na nowy adres email.',
            'T_FORM_SUCCESS'
          )
        }
      },
      error => {
        setState(prevState => ({
          ...prevState,
          error: error?.response.detail || translate('Błędny adres email'),
          renderError: true,
          open: !prevState.open,
          isFetching: !prevState.isFetching,
        }))
      }
    )
  }, [setValue, state.email, url])

  return (
    <>
      <div className={clsx(classes.row_two_col)}>
        <div>
          <Button
            variant="contained"
            color={'secondary'}
            size={'large'}
            style={{ maxHeight: 37 }}
            onClick={handleToogle}
          >
            <span>{translate('Resetuj hasło')}</span>
          </Button>
        </div>
      </div>
      <CustomDialog
        handleToogle={handleToogle}
        open={state.open}
        title={'Czy na pewno zresetować hasło?'}
        component={() => (
          <Typography variant={'body1'} color={'primary'}>
            {translate(
              'Na adres email zostanie wysłany link do zresetowania hasła.'
            )}
          </Typography>
        )}
        confirmedButton={true}
        onConfirm={handleConfirm}
        confirmedText={'Resetuj'}
        isFetching={state.isFetching}
      />
    </>
  )
}
