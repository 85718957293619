import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import clsx from 'clsx'
import {
  FormControl,
  TextField,
  FormHelperText,
  Popper,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
import { prop } from 'core/_helpers/prop'

const useStyles = makeStyles(theme => ({
  autocomplete_root: {
    backgroundColor: 'white',
    minWidth: 260,
  },
  input_root: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    paddingLeft: 5,
    height: 33,
    borderColor: theme.palette.primary.main,
    borderRadius: 0,
    '&:before': {
      content: 'none',
    },
    '&:hover:before': {
      content: 'none',
    },
  },
  input: {
    paddingTop: '3px !important',
    paddingBottom: '3px !important',
  },
}))

export const ResourceType = ({
  name,
  value,
  endpoint = null,
  resources = null,
  titleAccessor,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  placeholder = null,
  classes = {},
}) => {
  const [id] = useState(randomHash())

  const [choices, setChoices] = useState(resources || [])

  const handleChange = (e, option) => {
    setValue(name, option ? option.value : null)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(value)
  }, [validateField, value])

  useEffect(() => {
    if (!endpoint || resources) {
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      endpoint,
      'GET',
      { signal },
      response => {
        setChoices(response['hydra:member'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, resources, setChoices])

  const options = choices.map(resource => ({
    value: resource['@id'],
    title: prop(resource, titleAccessor),
  }))

  const defaultClasses = useStyles()

  return (
    <FormControl
      className={classes.root}
      error={renderError && !!error}
      disabled={disabled || !choices.length}
    >
      <Autocomplete
        id={id}
        name={name}
        options={options}
        getOptionLabel={option => option.title}
        onChange={handleChange}
        value={
          getValue(value)
            ? {
                value: getValue(value),
                title:
                  options.find(option => option.value === getValue(value))
                    ?.title || '',
              }
            : null
        }
        renderInput={params => (
          <TextField {...params} placeholder={placeholder} variant="standard" />
        )}
        PopperComponent={props => (
          <Popper
            {...props}
            style={{ width: 'auto' }}
            placement="bottom-start"
          />
        )}
        disabled={disabled || !choices.length}
        classes={{
          root: clsx(defaultClasses.autocomplete_root, classes.autocomplete),
          inputRoot: defaultClasses.input_root,
          input: defaultClasses.input,
        }}
      />
      <FormHelperText>{translate(renderError && error)}</FormHelperText>
    </FormControl>
  )
}

const getValue = value => {
  if (!value) {
    return null
  }

  if (typeof value === 'string') {
    return value
  }

  return value['@id']
}

ResourceType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    }),
  ]),
  endpoint: PropTypes.string,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    })
  ),
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  classes: PropTypes.shape({
    root: PropTypes.string,
    autocomplete: PropTypes.string,
  }),
}
