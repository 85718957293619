import React, { useEffect, useMemo, useState } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { SectionTitle } from 'core/components/SectionTitle'
import { blocks } from 'pages/applicant/AppliedProposals/components/blocks'
import provinceSchema from '_schema/province'
import populationSchema from '_schema/population'
import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
import organizationTypeSchema from '_schema/organizationType'
import { Divider, Typography } from '@material-ui/core'
import { useFormTemplateSectionTypeStyles } from 'pages/applicant/Proposals/components/form/fields'
import { useSelector } from 'react-redux'
import schema from 'core/_schema/user'

export const ReadFormGenerator = ({ values, formIRI, contest, proposal }) => {
  const classes = useFormTemplateSectionTypeStyles()
  const [form, setForm] = useState(null)
  const profile = useSelector(state => state.profile)
  const [user, setUser] = useState({})

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    fetchDataHandleAuthError(
      `${schema('user').endpoint}/${profile.uuid}`,
      'GET',
      { signal },
      resp => {
        setUser(resp)
      }
    )
    return () => controller.abort()
  }, [profile])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    fetchDataHandleAuthError(formIRI, 'GET', { signal }, resp => {
      setForm(resp)
    })

    return () => controller.abort()
  }, [formIRI])

  const additionalResourcesMapping = useMemo(
    () => ({
      provinces: `${provinceSchema.endpoint}?order[title]=desc&pagination=false`,
      populations: `${populationSchema.endpoint}?order[title]=desc&pagination=false`,
      organizationTypes: `${organizationTypeSchema.endpoint}?order[title]=desc&pagination=false`,
    }),
    []
  )

  const [additionalResources] = usePropertyResourcesFetch(
    additionalResourcesMapping
  )

  useEffect(() => {
    console.log(values)
  }, [values])

  return (
    <>
      {!!form && (
        <>
          {form.formSections.map(section => {
            if (section.stat) {
              const valuesArray =
                values[section.uuid] === undefined
                  ? []
                  : Array.isArray(values[section.uuid])
                  ? values[section.uuid]
                  : Object.keys(values[section.uuid]).length > 0
                  ? Object.values(values[section.uuid])
                  : []
              return (
                <div key={section.uuid}>
                  {section.titleVisibility && (
                    <SectionTitle label={section.title} divider={true} />
                  )}
                  {valuesArray?.map((item, index) => {
                    return (
                      <div key={index}>
                        {section.isCloneable && (
                          <div className={classes.blockMultiDiv}>
                            <Typography variant={'body2'} color={'primary'}>
                              {index + 1}
                            </Typography>
                            <Divider color={'primary'} />
                          </div>
                        )}

                        {section.formSectionBlocks?.map(block => {
                          const BlockComponent = blocks[block.type]

                          return (
                            <div key={`${index}-${block['@id']}`}>
                              <BlockComponent
                                data={block.data}
                                values={
                                  values?.[section.uuid]?.[index]?.[block.uuid]
                                }
                                contest={contest}
                                proposal={proposal}
                                provinces={additionalResources.provinces}
                                populations={additionalResources.populations}
                                organizationTypes={
                                  additionalResources.organizationTypes
                                }
                                user={user}
                              />
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                </div>
              )
            }
          })}
        </>
      )}
    </>
  )
}
