import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { DetailsItemType } from './DetailsItemType'
import { useDetailsCollectionTypeStyles } from './DetailsCollectionType.styles'
import moment from 'moment/moment'

export const DetailsCollectionType = ({
  name,
  value,
  initialValue = null,
  disabled,
  renderError,
  setValue,
  setError,
  isFormSubmitted,
  resource,
}) => {
  const [state, setState] = useState({
    values: value,
    errors: [],
    renderError: [],
    isInvalid: false,
    isFormSubmitted: false,
    init: true,
    edited: [],
  })

  useEffect(() => {
    setValue(name, state.values, !state.init)
  }, [name, state.values, state.init, setValue])

  useEffect(() => {
    setError(name, state.isInvalid)
  }, [name, setError, state.isInvalid])

  useEffect(() => {
    if (!isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      isFormSubmitted,
    }))
  }, [setState, isFormSubmitted])

  useEffect(() => {
    if (resource.status === 'AUTO_DRAFT') {
      setValue('status', 'PENDING', false)
    }
  }, [resource])

  useEffect(() => {
    if (!state.isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      renderError: [],
    }))
  }, [setState, state.isFormSubmitted])

  useEffect(() => {
    if (value.length === 0 && state.values.length === 0) {
      setState(prevState => ({ ...prevState, values: initialValue }))
    }
  }, [initialValue, state.values.length, value])

  const classes = useDetailsCollectionTypeStyles()

  const [scheduleDate, setScheduleDate] = useState([])

  useEffect(() => {
    setScheduleDate([
      ...new Set(
        [...Object.keys(state.values), ...Object.keys(resource.current)].sort(
          (a, b) => {
            const dateA = moment(a)
            const dateB = moment(b)
            if (dateA.isAfter(dateB)) {
              return 1
            } else {
              return -1
            }
          }
        )
      ),
    ])
  }, [state.values, resource])

  return (
    <>
      <div className={classes.root}>
        <div>
          <div className={classes.header}>
            <div>Miesiąc</div>
            <div>Obecnie</div>
            <div>
              Zmiana{' '}
              <small
                style={{ fontSize: 10, fontWeight: 400, display: 'block' }}
              >
                Aby wyczyścić miesiąc wyślij "-"
              </small>
            </div>
          </div>
          {scheduleDate.map((item, index) => (
            <div className={classes.container} key={index}>
              <DetailsItemType
                index={item}
                values={state.values}
                errors={state.errors}
                renderError={state.renderError}
                globalRenderError={renderError}
                setState={setState}
                disabled={disabled}
                resource={resource}
                edited={state.edited}
                initialValue={initialValue}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

DetailsCollectionType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  renderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
}
