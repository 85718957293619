import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { commonConstants } from 'core/_constants'

export const useResourceFetch = (
  iri,
  resource,
  setIsFetching,
  setResource,
  setFetchError,
  isByPidFetch = false,
  isNotEditable = null,
  setIsNotEditable = null,
  dispatchOnFetch = null,
  filter = null
) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!iri) {
      setIsFetching(false)
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    let url = iri

    if (filter) {
      url =
        iri +
        '?' +
        Object.entries(filter)
          .map(([key, val]) => `${key}=${val}`)
          .join('&')
    }

    fetchDataHandleAuthError(
      url,
      'GET',
      { signal },
      response => {
        if (isNotEditable && isNotEditable(response)) {
          setIsNotEditable(true)

          return
        }

        setResource(response)
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
        setFetchError(true)
      }
    )

    return () => controller.abort()
  }, [
    iri,
    setIsFetching,
    setIsNotEditable,
    setResource,
    setFetchError,
    isNotEditable,
    filter,
  ])

  useEffect(() => {
    if (!resource) {
      return
    }

    dispatchOnFetch
      ? dispatchOnFetch(resource)
      : dispatch({
          type: isByPidFetch
            ? commonConstants.SET_CURRENT_PARENT_RESOURCE
            : commonConstants.SET_CURRENT_RESOURCE,
          payload: { resource },
        })
  }, [dispatch, dispatchOnFetch, resource, isByPidFetch])

  return
}
