import { makeStyles } from '@material-ui/styles'

export const useBudgetItemTypeStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    marginTop: 10,
    marginBottom: 20,
    display: 'flex',
    paddingBottom: 20,
    borderBottom: '1px solid #ccc',
    '& > div': {
      marginRight: 20,
    },
  },
  number: {
    width: 20,
    marginRight: 20,
    fontWeight: 'bold',
  },
  input: {
    width: 253,
  },
  inputSmall: {
    width: 213,
  },
  save: {
    width: 20,
  },
}))
