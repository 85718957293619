import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  IconButton,
  Grow,
  ClickAwayListener,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Divider,
} from '@material-ui/core'
import Popper from '@material-ui/core/Popper'
import { Paper } from '../Paper'
import { StyledBadge } from './styles'
import { ReactComponent as Icon } from './icons/icon.svg'
import { ReactComponent as IconActive } from './icons/icon-active.svg'
import { ReactComponent as IconWarning } from '../../../theme/icons/warrning.svg'
import { useStyles } from './styles'
import { ButtonRight } from '../buttons/button-right'
import ColoredScrollbars from '../ColoredScrollbars'
import { useNotificationsFetch } from './_helpers/useNotificationsFetch'
import Moment from 'react-moment'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

export const Notifications = props => {
  const classes = useStyles()
  const [menuAnchor, setMenuAnchor] = useState(null)

  const handleMenu = e => {
    setMenuAnchor(e.currentTarget)
  }

  const handleClose = () => {
    setMenuAnchor(null)
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setMenuAnchor(null)
    }
  }

  const userUuid = useSelector(state => state.profile.uuid)

  const [state, setState] = useNotificationsFetch('/notifications', userUuid)

  const notReaded = state.items.filter(item => item.status === 'NEW')

  const handleRead = id => {
    fetchDataHandleAuthError(
      `/notifications/${id}/readed`,
      'PATCH',
      {
        body: JSON.stringify({}),
      },
      () => {
        setState({
          ...state,
          refresh: true,
        })
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }
  return (
    <div {...props}>
      <IconButton
        aria-label="user menu"
        aria-controls="user-menu"
        aria-haspopup="true"
        onClick={handleMenu}
        className={classes.menu_button}
      >
        <StyledBadge badgeContent={notReaded.length} color="secondary">
          {!!menuAnchor ? <IconActive /> : <Icon />}
        </StyledBadge>
      </IconButton>
      <Popper
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <ColoredScrollbars
                  autoHeight
                  autoHeightMin={100}
                  autoHeightMax={578}
                >
                  <List className={classes.list} onKeyDown={handleListKeyDown}>
                    {state.items.map((item, index) => (
                      <>
                        <ListItem
                          alignItems="flex-start"
                          className={classes.list_item}
                          disabled={item.status === 'READED'}
                        >
                          <div className={classes.list_item_date}>
                            <Typography variant="subtitle1">
                              <Moment
                                date={item.updatedAt}
                                format={'DD.MM.YYYY'}
                              />
                            </Typography>
                            <ListItemAvatar
                              className={classes.list_item_avatar}
                            >
                              <IconWarning />
                            </ListItemAvatar>
                          </div>
                          <ListItemText
                            primary={
                              <>
                                <Typography variant="h3" component="span">
                                  {item.title}
                                </Typography>
                              </>
                            }
                            secondary={
                              <>
                                <Typography
                                  variant="subtitle1"
                                  color="primary"
                                  component="span"
                                >
                                  {item.subTitle}
                                </Typography>
                                {item.status === 'NEW' && (
                                  <span className={classes.list_item_button}>
                                    <ButtonRight
                                      onClick={() => handleRead(item.uuid)}
                                      text={'Oznacz jako przeczytane'}
                                    />
                                  </span>
                                )}
                              </>
                            }
                          />
                        </ListItem>
                        <Divider component="li" className={classes.divider} />
                      </>
                    ))}
                  </List>
                </ColoredScrollbars>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}
