import { blockTypes } from 'pages/applicant/Proposals/_helpers/blockTypes'
import { TextBlock } from './TextBlock'
import { InputBlock } from './InputBlock'
import { TextareaBlock } from './TextareaBlock'
import { ChoicesSelectBlock } from './ChoicesSelectBlock'
import { ChoicesCheckboxBlock } from './ChoicesCheckboxBlock'
import { DateTimeBlock } from './DateTimeBlock'
import { AddressBlock } from './AddressBlock'
import { CompanyDataBlock } from './CompanyDataBlock'
import { CompanyAddressBlock } from './CompanyAddressBlock'
import { CompanyAdditionalInfoBlock } from './CompanyAdditionalInfoBlock'
import { CompanyTargetInformationsBlock } from './CompanyTargetInformationsBlock'
import { RealizationDateTimeBlock } from './RealizationDateTimeBlock'
import { ParticipantsCountBlock } from './ParticipantsCountBlock'
import { ProjectScheduleBlock } from './ProjectScheduleBlock'

export const blocks = {
  [blockTypes.TEXT]: TextBlock,
  [blockTypes.INPUT]: InputBlock,
  [blockTypes.TEXTAREA]: TextareaBlock,
  [blockTypes.CHOICES_SELECT]: ChoicesSelectBlock,
  [blockTypes.CHOICES_CHECKBOX]: ChoicesCheckboxBlock,
  [blockTypes.DATE_TIME]: DateTimeBlock,
  [blockTypes.ADDRESS]: AddressBlock,
  [blockTypes.COMPANY_DATA]: CompanyDataBlock,
  [blockTypes.COMPANY_ADDRESS]: CompanyAddressBlock,
  [blockTypes.COMPANY_ADDITIONAL_INFO]: CompanyAdditionalInfoBlock,
  [blockTypes.COMPANY_TARGET_INFORMATIONS]: CompanyTargetInformationsBlock,
  [blockTypes.REALIZATION_DATE_TIME]: RealizationDateTimeBlock,
  [blockTypes.PARTICIPANTS_COUNT]: ParticipantsCountBlock,
  [blockTypes.PROJECT_SCHEDULE]: ProjectScheduleBlock,
}
