import React from 'react'
import { SectionTitle } from 'core/components/SectionTitle'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  sub_account: {
    fontSize: 16,
    color: theme.palette.primary.main
  },
  representatives: {
    fontSize: 16,
    marginTop: 10,
    '&> *': {
      display: 'flex',
      marginTop: 10,
      '&> *': {
        marginRight: 10
      }
    },
    color: theme.palette.primary.main
  },
  bolded: {
    fontWeight: 'bold'
  },
  role: {
    marginTop: 6
  }
}))

export const ContractInquiry = ({ resource }) => {
  const classes = useStyles()

  return (
    <>
      <SectionTitle label={'Ankieta umowy'} marginTopSm={true} />
      <div className={classes.sub_account}>
        <span className={classes.bolded}>Nr subkonta:</span> { resource.contractSubAccount}
      </div>
      <div className={classes.representatives}>
        {resource.contractRepresentatives.map((representative, index) => (
          <div key={representative['@id']}>
            <div className={classes.bolded}>osoba odpowiedzialna {index + 1}:</div>
            <div>
              <div>
                {representative.name} {representative.surname}
              </div>
              <div className={classes.role}>
                {representative.role}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}
