import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Collection } from './Collection'
import { roles } from '_helpers/roles'

const routes = () => {
  return {
    index: {
      title: 'T_MODULE_PROPOSALS_APPLICATION_STATUS_DRAFT',
      type: Route,
      render: props => <Collection {...props} />,
      path: '/proposals/draft',
      exact: true,
      resourceAccess: [ roles.APPLICANT ],
    },
    view: {
      type: Redirect,
      from: '/proposals/draft/:id',
      to: '/contests/:id/proposal',
      resourceAccess: [ roles.APPLICANT ],
      exact: true,
      path: '/proposals/draft/:id'
    },


  }
}

export default routes
