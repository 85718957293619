import 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import DateFnsUtils from '@date-io/date-fns'
import clsx from 'clsx'
import plLocale from 'date-fns/locale/pl'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { InputAdornment, FormHelperText } from '@material-ui/core'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
import { TimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ReactComponent as DateTimeIcon } from 'theme/icons/datetime.svg'
import { useStyles } from 'core/components/form/fields/fields.style'
import { StringType } from 'core/components/form/fields/StringType'

const LOCALES = {
  pl: plLocale,
}

const FORMAT = 'HH:mm'

const useLocalStyles = makeStyles({
  error: {
    marginTop: -22
  }
})

export const TimeType = ({
  name,
  value,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  width = '350px',
}) => {
  const [id] = useState(randomHash())

  const handleChange = value => {
    const date = value ? moment(value).format() : null
    setValue(name, date)
    validateField(date)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  const handleError = message => {
    if (message && message !== error) {
      setError(name, message)
    }
  }

  useEffect(() => {
    validateField(value)
  }, [validateField, value])

  const classes = useStyles()
  const localClasses = useLocalStyles()

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div
      style={{
        maxWidth: width,
      }}
    >
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={LOCALES[process.env.REACT_APP_LOCALE]}
      >
        <div>
          <TimePicker
            id={id}
            name={name}
            disabled={disabled}
            label=""
            variant="inline"
            value={![null, undefined].includes(value) ? new Date(value) : null}
            format={FORMAT}
            margin="normal"
            autoOk
            ampm={false}
            disableToolbar
            onChange={handleChange}
            onError={handleError}
            PopoverProps={{
              anchorEl: anchorEl,
              onClose: handleClose,
              classes: { root: classes.dateTime },
              PaperProps: {
                classes: {
                  root: classes.dateTimePaper,
                },
              },
            }}
            keyboardIcon={
              <InputAdornment position="end">
                <DateTimeIcon />
              </InputAdornment>
            }
            endIcon={
              <InputAdornment
                position="end"
                onClick={handleOpen}
                className={clsx(classes.field_icon, classes.field_icon_pointer)}
              >
                <DateTimeIcon />
              </InputAdornment>
            }
            TextFieldComponent={StringType}
            setError={() => false}
            type="text"
            renderError={renderError}
            setValue={() => false}
            open={Boolean(anchorEl)}
            readonly="readonly"
          />
          {renderError && !!error && (
            <FormHelperText error={true} className={localClasses.error}>
              {translate(error)}
            </FormHelperText>
          )}
        </div>
      </MuiPickersUtilsProvider>
    </div>
  )
}

TimeType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  width: PropTypes.string
}
