import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import {
  Button,
  Card,
  Typography,
  AppBar,
  InputLabel,
  FormHelperText,
  FormControl,
} from '@material-ui/core'
import { Logo } from 'core/components/Logo'
import { validators } from 'core/_helpers/validators'
import { translate } from 'core/_helpers/translate'
import { CustomInput, useStyles } from '.'
import { Footer } from '../../components/footer/Footer'

export const ResetPasswordLink = () => {
  const [state, setState] = useState({
    email: null,
    errorMessage: null,
    isSubmitted: false,
    isInvalid: true,
    isFetching: false,
    isCompleted: false,
  })

  const handleChange = e => {
    const email = getValue(e.target.value)

    const isInvalid =
      !validators.required(email).result || !validators.email(email).result
    setState(state => ({
      ...state,
      email,
      errorMessage: isInvalid
        ? translate('T_GENERAL_FORGOT_PASSWORD_VALIDATION_EMAIL')
        : null,
      isInvalid,
    }))
  }

  const url = `${process.env.REACT_APP_API_ENTRYPOINT}/security/startResettingPassword`

  const handleSubmit = e => {
    e.preventDefault()

    setState(state => ({
      ...state,
      isSubmitted: true,
    }))

    if (state.isInvalid) {
      return
    }

    setState(state => ({
      ...state,
      isFetching: true,
    }))

    fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email }),
    })
      .then(response =>
        response.text().then(text =>
          text
            ? JSON.parse(text)
            : {
                success: false,
                errorMessage: translate('Error'),
              }
        )
      )
      .then(data => {
        if (data.uuid === undefined) {
          setState(state => ({
            ...state,
            errorMessage: translate(data['hydra:description']),
            isFetching: false,
          }))

          return
        }

        setState(state => ({
          ...state,
          isFetching: false,
          isCompleted: true,
        }))
      })
  }

  const { email, errorMessage, isSubmitted, isFetching, isCompleted } = state

  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Logo className={classes.logo} />
      <Card className={classes.card}>
        <AppBar position="static" className={classes.login_app_bar}>
          <div className={clsx(classes.login_menu, classes.login_menu_title)}>
            <Typography
              variant="h1"
              className={clsx(classes.login_title, classes.login_title_full)}
              color="secondary"
            >
              <span>{translate('T_GENERAL_FORGOT_PASSWORD_TITLE')}</span>
            </Typography>
          </div>
        </AppBar>
        {isCompleted ? (
          <div className={classes.form_message}>
            <div className={classes.reset_message}>
              {translate('T_GENERAL_FORGOT_PASSWORD_LINK_SENT')}
            </div>
          </div>
        ) : (
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <div className={classes.form_inner}>
              <FormControl
                error={isSubmitted && !!errorMessage}
                className={classes.form_control}
              >
                <InputLabel
                  shrink={false}
                  htmlFor="login"
                  className={classes.form_label}
                >
                  {translate('T_GENERAL_FORGOT_PASSWORD_TYPE_EMAIL')}
                </InputLabel>
                <CustomInput
                  value={email || ''}
                  id="login"
                  required={true}
                  onChange={handleChange}
                  aria-describedby="error-login"
                />
                <FormHelperText id="error-login">
                  {isSubmitted && !!errorMessage ? errorMessage : null}
                </FormHelperText>
              </FormControl>
              <Link to="/login" className={classes.lower_link}>
                {translate('T_GENERAL_BACK_TO_LOGIN')}
              </Link>
            </div>
            <Button
              disabled={isFetching}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {isFetching ? (
                <img
                  className={classes.spinner}
                  src="/img/spinner-white.svg"
                  alt="loading"
                />
              ) : (
                translate('T_GENERAL_FORGOT_PASSWORD_SEND_LINK')
              )}
            </Button>
          </form>
        )}
      </Card>
      <Footer />
    </div>
  )
}

const getValue = value => (value === '' ? null : value.trim())
