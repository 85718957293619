import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { makeStyles } from '@material-ui/styles'
import { getBlockFieldsMapping } from 'pages/applicant/Proposals/_helpers/getBlockFieldsMapping'
import { blockTypes } from 'pages/applicant/Proposals/_helpers/blockTypes'
import { useSelector } from 'react-redux'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import schema from 'core/_schema/user'

const useStyles = makeStyles({
  row: {
    marginTop: 8,
  },
})

export const TextBlock = ({ data, setValue, proposal }) => {
  const profile = useSelector(state => state.profile)
  const [user, setUser] = useState({})
  const [province, setProvince] = useState({ title: null })
  const mapping = useMemo(() => getBlockFieldsMapping(blockTypes.TEXT, data), [
    data,
  ])

  const [cleanDescription, setCleanDescription] = useState(
    DOMPurify.sanitize(mapping.text.description, {
      USE_PROFILES: { html: true },
    })
  )

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    fetchDataHandleAuthError(
      `${schema('user').endpoint}/${profile.uuid}`,
      'GET',
      { signal },
      resp => {
        setUser(resp)
      }
    )
    return () => controller.abort()
  }, [profile])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    if (proposal && proposal.province !== undefined && proposal.province) {
      fetchDataHandleAuthError(proposal.province, 'GET', { signal }, resp => {
        setProvince(resp)
      })
    }
    return () => controller.abort()
  }, [proposal])

  useEffect(() => {
    if (proposal && user && profile) {
      const ID = proposal.nip || proposal.regon || proposal.krs
      const orgType = user?.organizationType?.title
      const fullAddress = `Poczta: ${proposal.postOffice ||
        user.postOffice}, Kod pocztowy: ${proposal.postCode ||
        user.postCode} <br />
Adres: ${proposal.address || user.address} <br />
Miejscowość: ${proposal.city || user.city} <br />
Gmina: ${proposal.commune || user.commune} <br />
Powiat: ${proposal.district || user.district} <br />
Województwo: ${province.title || user.province?.title}<br />
`

      let representatives = ``
      if (proposal.contractRepresentatives !== undefined) {
        proposal.contractRepresentatives.map(el => {
          representatives = `${representatives} <br /> ${el.name} ${el.surname} - ${el.role}`
        })
      }

      let html = `${mapping.text.description}`
      html = html.replace('&lt;ID&gt;', ID || '')
      html = html.replace('&lt;TAXIDTYPE&gt;', 'Rodzaj numeru ident.')
      html = html.replace('&lt;ORGNAME&gt;', proposal.organizationName || '')
      html = html.replace('&lt;ORGTYPE&gt;', orgType || '')
      html = html.replace('&lt;ORGMAIL&gt;', profile.email)
      html = html.replace('&lt;ORGADDRESS&gt;', fullAddress)
      html = html.replace('&lt;ORGSUBACCOUNT&gt;', '20214589632145698745632145')
      html = html.replace('&lt;ORGREPRESENTATIVES&gt;', representatives)

      setCleanDescription(
        DOMPurify.sanitize(html, {
          USE_PROFILES: { html: true },
        })
      )
    }
  }, [mapping, proposal, user, profile, province])

  useEffect(() => {
    setValue('input', mapping.text.description)
  }, [setValue, mapping.text.description])

  const classes = useStyles()

  return (
    <div
      dangerouslySetInnerHTML={{ __html: cleanDescription }}
      className={classes.row}
    ></div>
  )
}

TextBlock.propTypes = {
  data: PropTypes.string,
  setValue: PropTypes.func.isRequired,
}
