import React, { useCallback, useEffect, useMemo, useState } from 'react'
import schema, {
  CONTEST_PUBLICATION_STATUS_DRAFT,
  CONTEST_PUBLICATION_STATUS_FINISHED,
} from '_schema/contests'
import { Form } from 'core/pages'
import routes from 'pages/Foundation/Home/routes'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { translate } from 'core/_helpers/translate'
import { AttachmentName } from './AttachmentName'
import { DatesReport } from './DatesReport'
import schemaPartialReportPeriod from '_schema/contestPartialReportPeriod'
//import contestQuarterReportPeriod from '_schema/contestQuarterReportPeriod'
import { BlockCollection } from 'core/components/block'
import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
import { addBlockPropertyResources } from 'core/_helpers/addBlockPropertyResources'
import { CollectionContestExpertProvince } from 'components/contestExpertProvinceCollection'
import { SidebarContest } from './SidebarContest'
import { useDispatch, useSelector } from 'react-redux'
import { Collection as ContestProposalRateGuideline } from 'components/contestProposalRateGuideline/Collection'
import { TrainingForm } from './TrainingForm'
import { CategoriesBudget } from './CategoriesBudget'
import schemaForms, { FORM_TYPE_TRAINING_INQUIRY } from '_schema/form'
import schemaTraining from '_schema/contestTraining'
import schemaBudgetCategories from '_schema/contestBudgetCategories'
import subsidyTranche from '_schema/subsidyTranche'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'
import { formSettingsConstans } from '_constans/formSettings.constans'
import { ExpertShowButton } from './ExpertShowButton'
import { SubsidyTrancheForm } from './SubsidyTrancheForm'

export const Edit = ({ schema: definitions, ...rest }) => {
  const { match } = rest

  const currentResourcesFromRedux = useSelector(
    state => state.common.currentResource
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch({ type: formSettingsConstans.RESET_IS_CHANGE })
  }, [dispatch])

  const iri = useMemo(() => `${schema.endpoint}/${match.params.id}`, [
    match.params.id,
  ])
  const tab = match.params.tab ? Number(match.params.tab) : 0

  const [title, setTitle] = useState('')

  const [disable, setDisable] = useState(false)
  const handleDisable = useCallback(resource => {
    setDisable(
      resource.publicationStatus === CONTEST_PUBLICATION_STATUS_FINISHED
    )
    return resource.publicationStatus === CONTEST_PUBLICATION_STATUS_FINISHED
  }, [])

  const setHeaderHandle = useCallback(
    resource => {
      if (!title) {
        fetchDataHandleAuthError(
          resource.contestEdition,
          'GET',
          {},
          response => {
            setTitle(response.title)
          }
        )
      }
      return (
        <>
          <CollectionHeader buttonTitle="" buttonPath="" hideButton>
            {translate('Edycja')}: {title}
          </CollectionHeader>
        </>
      )
    },
    [title]
  )

  const packageResourcesMapping = useMemo(
    () => ({
      // packageFile: `${packageFileSchema.endpoint}?pagination=false`,
      // packageGallery: `${packageGallerySchema.endpoint}?pagination=false`,
      // packageFaq: `${packageFaqSchema.endpoint}?pagination=false`,
      // packageForm: `${packageFormSchema.endpoint}?pagination=false`,
      trainingForms: `${schemaForms.endpoint}?pagination=false&type=${FORM_TYPE_TRAINING_INQUIRY}`,
    }),
    []
  )

  const [packageResources] = usePropertyResourcesFetch(packageResourcesMapping)

  const blockTypes = useMemo(
    () =>
      addBlockPropertyResources(
        schema.subresources.block.types,
        packageResources
      ),
    [packageResources]
  )

  const subForm = resource => ({
    attachment: {
      component: (
        <>
          <AttachmentName
            resource={resource}
            disabled={
              resource.publicationStatus !== CONTEST_PUBLICATION_STATUS_DRAFT
            }
          />
        </>
      ),
    },
    datesReports: {
      component: (
        <>
          <DatesReport
            resource={resource}
            schema={schemaPartialReportPeriod}
            buttonText={'Dodaj sprawozdanie'}
            disabled={disable}
          />
        </>
      ),
    },
    // datesPartialReport: {
    //   component: (
    //     <>
    //       <DatesReport
    //         resource={resource}
    //         schema={contestQuarterReportPeriod}
    //         buttonText={'Dodaj deklarację'}
    //         disabled={disable}
    //       />
    //     </>
    //   ),
    // },
    categoriesBudget: {
      component: (
        <>
          <CategoriesBudget
            resource={resource}
            schema={schemaBudgetCategories}
            buttonText={'Dodaj kategorię budżetową'}
            budgetCategories={packageResources.budgetCategories}
            disabled={disable}
          />
        </>
      ),
    },
    datesTraining: {
      component: (
        <>
          <TrainingForm
            resource={resource}
            schema={schemaTraining}
            buttonText={'Dodaj szkolenie'}
            trainingForms={packageResources.trainingForms}
            disabled={disable}
          />
        </>
      ),
    },
    subsidyTranche: {
      component: (
        <>
          <SubsidyTrancheForm
            resource={resource}
            schema={subsidyTranche}
            buttonText={'Dodaj transze wypłaty dotacji'}
            disabled={disable}
          />
        </>
      ),
    },
    expertShowButton: {
      component: (
        <>
          <ExpertShowButton resource={resource} />
        </>
      ),
    },
  })

  return (
    <>
      <Form
        subForm={subForm}
        url={iri}
        tab={tab}
        method="PUT"
        readOnly={disable}
        handleResourceChange={handleDisable}
        collectionPath={routes().contest_foundation.path}
        definitionSchema={definitions[schema.resource_new.definition]}
        customResourceSchema={schema.resource_new}
        showSubmitAndStayButton={true}
        showCancelButton={false}
        showSubmitButton={false}
        submitAndStayButtonLabel={resource =>
          resource.publicationStatus === CONTEST_PUBLICATION_STATUS_DRAFT
            ? translate('Zapisz szkic konkursu')
            : translate('Zapisz konkurs')
        }
        iri={iri}
        customResourcesSchemas={[
          {
            title: 'Dane ogólne',
            schema: schema.resource_new_step_1,
            children: null,
          },
          {
            title: 'Strona',
            schema: schema.resource_new_step_2,
            children: () => (
              <>
                <BlockCollection
                  endpoint={schema.subresources.block.endpoint}
                  pid={match.params.id}
                  parentIri={iri}
                  definitionSchema={
                    definitions[schema.subresources.block.definition]
                  }
                  types={blockTypes}
                  statable={true}
                  key={iri}
                  parent={'contest'}
                  titleAccessor={'title'}
                  disabled={disable}
                />
                <Button
                  style={{ marginTop: 32 }}
                  variant={'outlined'}
                  color={'secondary'}
                  component={Link}
                  to={routes().contest_preview.path.replace(
                    ':id',
                    match.params.id
                  )}
                >
                  {translate('Podgląd strony')}
                </Button>
              </>
            ),
          },
          {
            title: 'Szablony',
            schema: schema.resource_new_step_3,
            children: null,
          },
          {
            title: 'Kryteria oceny',
            schema: schema.resource_new_step_4,
            children: resource => (
              <ContestProposalRateGuideline
                resource={resource}
                definitions={definitions}
                // disabled={false}
                disabled={
                  resource.publicationStatus !==
                  CONTEST_PUBLICATION_STATUS_DRAFT
                }
              />
            ),
          },
          {
            title: 'Eksperci',
            schema: schema.resource_new_step_5,
            children: resource => (
              <>
                <CollectionContestExpertProvince
                  resourceContest={resource}
                  disabled={disable}
                />
              </>
            ),
          },
          {
            title: 'Ustawienia',
            schema: schema.resource_new_step_6,
            children: null,
          },
        ]}
        storeCollectionId={schema.endpoint}
        sidebar={true}
        key={iri}
        width="100%"
        header={setHeaderHandle}
        sidebarDeleteText={'Usuń szkic'}
        buttonSaveInSidebar={true}
        SidebarComponent={SidebarContest}
        sidebarWidth={4}
        sidebarStatAccessor={'publicationStatus'}
        buttonSubmitDisabled={
          !!currentResourcesFromRedux &&
          currentResourcesFromRedux.publicationStatus ===
            CONTEST_PUBLICATION_STATUS_FINISHED
        }
        saveStepperDisabled={
          !!currentResourcesFromRedux &&
          currentResourcesFromRedux.publicationStatus ===
            CONTEST_PUBLICATION_STATUS_FINISHED
        }
        removeElementsBeforeSend={[
          'contestProvinceExperts',
          'contestPageBlocks',
          'contestProposalRateGuidelines',
          'budgetCategories',
          'contestProposalAttachments',
          'contestPartialReportPeriods',
          'trainings',
          'subsidyTranches',
        ]}
      />
    </>
  )
}
