export const initState = ({
  url,
  method,
  resource = {},
  properties,
  onlyPassedProperties = false,
}) => ({
  url,
  method,
  values: Object.assign(
    onlyPassedProperties ? {} : { ...resource },
    ...Object.keys(properties).map(name => ({
      [name]:
        resource?.[name] !== undefined
          ? resource[name]?.['@id'] || resource[name]
          : properties[name].defaultValue !== undefined
          ? properties[name].defaultValue
          : null,
    }))
  ),
  errors: Object.assign(
    {},
    ...Object.keys(properties).map(name => ({ [name]: false }))
  ),
  renderError: Object.assign(
    {},
    ...Object.keys(properties).map(name => ({ [name]: false }))
  ),
  isInvalid: false,
  isProcessing: false,
  isSubmitted: false,
})
