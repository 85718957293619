import React from 'react'
import PropTypes from 'prop-types'
import { FormControlLabel, Radio } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'

const GreenRadio = withStyles(theme => ({
  root: {
    color: theme.palette.success.main,
    '&$checked': {
      color: theme.palette.success.main,
    },
  },
  checked: {},
}))(props => <Radio color="default" {...props} />)

const RedRadio = withStyles(theme => ({
  root: {
    color: theme.palette.error.main,
    '&$checked': {
      color: theme.palette.error.main,
    },
  },
  checked: {},
}))(props => <Radio color="default" {...props} />)

const BlueRadio = withStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))(props => <Radio color="default" {...props} />)

const useStyle = makeStyles(theme => ({
  label: {
    color: theme.palette.primary.main,
    '& .MuiFormControlLabel-label': {
      fontSize: theme.typography.pxToRem(18),
    },
  },
}))

export const RadioType = ({
  value,
  name,
  rate,
  label,
  disabled = false,
  color = 'default',
  onChange,
}) => {
  const classes = useStyle()
  return (
    <>
      <FormControlLabel
        control={
          color === 'green' ? (
            <GreenRadio />
          ) : color === 'red' ? (
            <RedRadio />
          ) : color === 'blue' ? (
            <BlueRadio />
          ) : (
            <Radio />
          )
        }
        className={classes.label}
        label={label}
        checked={rate === value}
        onChange={onChange}
        value={value}
        name={name}
        disabled={disabled}
      />
    </>
  )
}

RadioType.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  color: PropTypes.oneOf(['green', 'red', 'blue', 'default']),
  onChange: PropTypes.func.isRequired,
}
