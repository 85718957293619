import React, { useCallback, useState } from 'react'
import schema from '_schema/contestProposalAttachment'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { makeStyles } from '@material-ui/styles'
import { IconButton, Typography } from '@material-ui/core'
import { StringType } from 'core/components/form/fields/StringType'
import { ReactComponent as RemoveIcon } from 'theme/icons/remove.svg'
import SaveIcon from '@material-ui/icons/Save'
import { notification } from 'core/_helpers/notification'
import { ButtonPlus } from 'core/components/buttons/button-plus'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(5),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  itemCount: {
    fontWeight: 700,
    marginRight: theme.spacing(2),
  },
  itemSave: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  itemRemove: {
    '&:hover svg path': {
      fill: theme.palette.primary.main,
    },
  },
}))

const ItemAttachment = ({
  resourceId,
  count,
  collect,
  onSuccess,
  onRemove,
  disabled,
}) => {
  const classes = useStyles()
  const [state, setState] = useState(
    collect
      ? collect
      : {
          title: '',
          contest: resourceId,
          id: null,
          uuid: '',
        }
  )

  const [error, setError] = useState({
    error: null,
    renderError: false,
  })

  const [logChange, setLogChange] = useState(false)

  const handleChange = (name, value) => {
    setState(state => ({ ...state, title: value }))
    setLogChange(true)
    setError({
      error: null,
      renderError: false,
    })
  }
  const handleError = () => {}

  const handleRemove = () => {
    if (state.uuid) {
      const url = `${schema.endpoint}/${state.uuid}`
      fetchDataHandleAuthError(url, 'DELETE', {}, () => {
        onRemove(count, state.uuid)
        notification('warning', 'Załącznik został usunięty')
      })
    } else {
      onRemove(count, state.uuid)
    }
  }

  const handleSubmit = useCallback(() => {
    setLogChange(false)

    let url = schema.endpoint
    let method = 'POST'
    if (state.uuid) {
      url = `${schema.endpoint}/${state.uuid}`
      method = 'PUT'
    }

    fetchDataHandleAuthError(
      url,
      method,
      {
        body: JSON.stringify(state),
      },
      response => {
        notification('success', 'Załącznik został zapisany')
        onSuccess(response, count)
      },
      error => {
        if (
          error.response.violations !== undefined &&
          error.response.violations.length > 0
        ) {
          setError({
            error: error.response.violations[0].message,
            renderError: true,
          })
        } else {
          notification('error', 'Błąd')
        }
      }
    )
  }, [count, onSuccess, state])
  return (
    <>
      <div className={classes.item}>
        <Typography
          color={'primary'}
          variant={'h2'}
          className={classes.itemCount}
        >
          {count}
        </Typography>
        <StringType
          name={'title'}
          setValue={handleChange}
          disabled={disabled}
          renderError={error.renderError}
          type={'text'}
          setError={handleError}
          label={'Nazwa dokumentu'}
          width={'500px'}
          fullWidth={true}
          value={state.title}
          error={error.error}
        />
        {logChange && (
          <IconButton
            className={classes.itemSave}
            aria-label={'save'}
            color={'primary'}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </IconButton>
        )}
        {!disabled && (
          <IconButton
            className={classes.itemRemove}
            aria-label={'remove'}
            onClick={handleRemove}
          >
            <RemoveIcon />
          </IconButton>
        )}
      </div>
    </>
  )
}

export const AttachmentName = ({ resource, disabled }) => {
  const classes = useStyles()
  const [collection, setCollection] = useState(
    resource.contestProposalAttachments
  )
  const [newAttachemtn, setNewAttachemnt] = useState(false)

  const handleSuccess = (collect, index) => {
    if (index === collection.length + 1) {
      setCollection(state => [...state, collect])
      setNewAttachemnt(false)
    } else {
      setCollection(state => {
        const array = state
        array[index] = collect
        return array
      })
    }
  }

  const handleRemove = (index, uuid) => {
    if (index === collection.length + 1) {
      setNewAttachemnt(false)
    } else {
      const array = collection.filter(el => el.uuid !== uuid)
      setCollection([])
      setCollection(array)
    }
  }

  const handleClickAdd = () => {
    setNewAttachemnt(true)
  }
  return (
    <div className={classes.root}>
      {collection.map((el, index) => (
        <ItemAttachment
          key={index}
          resourceId={resource['@id']}
          count={index + 1}
          collect={el}
          onSuccess={handleSuccess}
          onRemove={handleRemove}
          disabled={disabled}
        />
      ))}
      {newAttachemtn ? (
        <ItemAttachment
          resourceId={resource['@id']}
          count={collection.length + 1}
          collect={null}
          onSuccess={handleSuccess}
          onRemove={handleRemove}
          disabled={disabled}
        />
      ) : (
        <ButtonPlus
          text={'Dodaj wymagany załącznik'}
          onClick={handleClickAdd}
          disabled={disabled}
        />
      )}
    </div>
  )
}
