import { COLORS } from '../colors'

export default {
  paper: {
    border: `1px solid ${COLORS.SECONDARY}`,
    borderRadius: 0,
    marginLeft: -1,
    maxWidth: 202,
    maxHeight: 400,
    overflowY: 'auto',
  },
  list: {},
}
