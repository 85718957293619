import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  hideFirstPadding: {
    '& td:first-of-type, & th:first-of-type': {
      paddingLeft: 0,
    },
  },
  sumRow: {
    '& td:first-of-type': {
      paddingLeft: 0,
    },
    borderTop: `1px solid ${theme.palette.primary.main}`,
  },
  table_cell_head: {
    color: '#A19AAA',
    fontWeight: 600,
    fontStyle: 'italic',
    border: 0,
  },
  table_cell: {
    fontWeight: 600,
    border: 0,
  },
  table_header: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    margin: 0,
  },
  table_header_line: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '&::after': {
      content: '""',
      height: 1,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      marginLeft: 10,
    },
  },
}))

export const TableList = ({ items, title }) => {
  const tableClasses = useStyles()

  function totalSubsidyAmount(items) {
    return items
      .map(
        ({ quarterlyDeclarationItem }) =>
          quarterlyDeclarationItem?.subsidyAmount
      )
      .reduce((sum, i) => sum + i, 0)
  }

  function totalnettoAmount(items) {
    return items
      .map(
        ({ quarterlyDeclarationItem }) => quarterlyDeclarationItem?.nettoAmount
      )
      .reduce((sum, i) => sum + i, 0)
  }

  return (
    <TableContainer>
      <h3 className={tableClasses.table_header_line}>{title}</h3>

      <Table>
        <TableHead>
          <TableRow className={tableClasses.hideFirstPadding}>
            <TableCell className={tableClasses.table_cell_head}>
              Rodzaj kosztów
            </TableCell>
            <TableCell className={tableClasses.table_cell_head}>
              Kwota dotowana
            </TableCell>

            <TableCell className={tableClasses.table_cell_head}>
              Kwota wydatkowana
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map(row =>
            row.title ||
            row.quarterlyDeclarationItem?.subsidyAmount > 0 ||
            row?.quarterlyDeclarationItem?.nettoAmount > 0 ? (
              <TableRow
                key={`${row['@id']}-td`}
                className={tableClasses.hideFirstPadding}
              >
                <TableCell className={tableClasses.table_cell}>
                  {row.title}
                </TableCell>
                <TableCell className={tableClasses.table_cell}>
                  {row?.quarterlyDeclarationItem?.subsidyAmount}
                </TableCell>
                <TableCell className={tableClasses.table_cell}>
                  {Number(row?.quarterlyDeclarationItem?.nettoAmount).toFixed(
                    2
                  )}
                </TableCell>
              </TableRow>
            ) : null
          )}
          {items?.length > 0 ? (
            <TableRow key={`sum-td`} className={tableClasses.sumRow}>
              <TableCell className={tableClasses.table_cell}>
                <h3 className={tableClasses.table_header}>Razem:</h3>
              </TableCell>
              <TableCell className={tableClasses.table_cell}>
                {Number(totalSubsidyAmount(items)).toFixed(2)} zł
              </TableCell>
              <TableCell className={tableClasses.table_cell}>
                {Number(totalnettoAmount(items)).toFixed(2)} zł
              </TableCell>
            </TableRow>
          ) : (
            'brak kosztów'
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
