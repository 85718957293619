import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Form as FormComponent } from 'core/components/form'
import { applyProperties } from 'core/_helpers/applyProperties'
import { collectionActions } from 'core/_actions'
import { commonConstants } from 'core/_constants'
import { translate } from '../_helpers/translate'

export const BlankForm = ({
  resource = null,
  defaultData = {},
  definitionSchema,
  customResourceSchema,
  modifyProperties = false,
  iri = null,
  isCurrentResource = true,
  method,
  title = null,
  url,
  collectionPath = null,
  editPath = null,
  storeCollectionId = null,
  handleSuccess: customHandleSuccess = null,
  handleSuccessAndStay: customHandleSuccessAndStay = null,
  handleCancel: customHandleCancel = null,
  showSubmitAndStayButton = true,
  showCancelButton = true,
  showSubmitButton = true,
  fetchCompareResource = false,
  fieldsFullWidth = true,
  submitAndStayButtonLabel = translate('T_FORM_SAVE_AND_STAY'),
  submitButtonLabel = null,
  width = 300,
  classes = {},
  buttonContainerRef = null,
  fieldNodeRefs = {},
  isChange = () => false,
  isSubmit = resource => false,
  subForm = null,
  buttonsFixed = true,
  saveForm = false,
  setSaveForm = null,
  buttonSubmitDisabled = false,
  customBeforeSubmit = null,
  readOnly = false,
  removeElementsBeforeSend = [],
}) => {
  // const properties = applyProperties(
  //   customResourceSchema.properties,
  //   definitionSchema.properties,
  //   'missing'
  // )

  // const modifiedProperties = modifyProperties
  //   ? modifyProperties(properties, resource, definitionSchema.properties)
  //   : properties

  const [properties, setProperties] = useState(
    applyProperties(
      customResourceSchema.properties,
      definitionSchema.properties,
      'missing'
    )
  )

  const [modifiedProperties, setModifiedProperties] = useState(
    modifyProperties
      ? modifyProperties(properties, resource, definitionSchema.properties)
      : properties
  )

  useEffect(() => {
    setProperties(
      applyProperties(
        customResourceSchema.properties,
        definitionSchema.properties,
        'missing'
      )
    )
  }, [customResourceSchema, definitionSchema])

  useEffect(() => {
    setModifiedProperties(
      modifyProperties
        ? modifyProperties(properties, resource, definitionSchema.properties)
        : properties
    )
  }, [properties, resource, definitionSchema, modifyProperties])

  const dispatch = useDispatch()
  const history = useHistory()

  const defaultHandleSuccess = () => {
    if (!iri && storeCollectionId) {
      dispatch(collectionActions.resetConfig(storeCollectionId))
    }
    collectionPath && history.push(collectionPath)
  }

  const defaultHandleSuccessAndStay = resource => {
    if (iri) {
      return
    }
    editPath &&
      history.push(
        editPath.replace(':id', resource[process.env.REACT_APP_RESOURCE_ID])
      )
  }

  const defaultHandleCancel = () => {
    collectionPath && history.push(collectionPath)
  }

  const handleSuccess = resource => {
    isSubmit(resource)
    customHandleSuccess
      ? customHandleSuccess(resource)
      : defaultHandleSuccess(resource)
  }

  const handleSuccessAndStay = resource => {
    isSubmit(resource)
    customHandleSuccessAndStay
      ? customHandleSuccessAndStay(resource)
      : defaultHandleSuccessAndStay(resource)

    isCurrentResource &&
      dispatch({
        type: commonConstants.SET_CURRENT_RESOURCE,
        payload: { resource },
      })
  }

  const handleCancel = () => {
    customHandleCancel ? customHandleCancel() : defaultHandleCancel()
  }

  return (
    <FormComponent
      subForm={subForm}
      title={title}
      url={url}
      method={method}
      properties={modifiedProperties}
      resource={resource}
      defaultData={defaultData}
      fieldsFullWidth={fieldsFullWidth}
      width={width}
      handleSuccess={handleSuccess}
      handleSuccessAndStay={handleSuccessAndStay}
      handleCancel={handleCancel}
      showSubmitAndStayButton={showSubmitAndStayButton}
      showCancelButton={showCancelButton}
      classes={classes}
      buttonContainerRef={buttonContainerRef}
      fieldNodeRefs={fieldNodeRefs}
      buttonsFixed={buttonsFixed}
      fetchCompareResource={fetchCompareResource}
      showSubmitButton={showSubmitButton}
      submitAndStayButtonLabel={submitAndStayButtonLabel}
      isChange={isChange}
      saveForm={saveForm}
      setSaveForm={setSaveForm}
      buttonSubmitDisabled={buttonSubmitDisabled}
      customBeforeSubmit={customBeforeSubmit}
      readOnly={readOnly}
      submitButtonLabel={submitButtonLabel}
      removeElementsBeforeSend={removeElementsBeforeSend}
    />
  )
}

BlankForm.propTypes = {
  resource: PropTypes.object,
  defaultData: PropTypes.object,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  modifyProperties: PropTypes.func,
  iri: PropTypes.string,
  isCurrentResource: PropTypes.bool,
  method: PropTypes.string.isRequired,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  collectionPath: PropTypes.string,
  editPath: PropTypes.string,
  storeCollectionId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  handleSuccess: PropTypes.func,
  handleSuccessAndStay: PropTypes.func,
  handleCancel: PropTypes.func,
  showSubmitAndStayButton: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  fetchCompareResource: PropTypes.bool,
  fieldsFullWidth: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  classes: PropTypes.object,
  buttonContainerRef: PropTypes.object,
  fieldNodeRefs: PropTypes.object,
  showSubmitButton: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isChange: PropTypes.func,
  isSubmit: PropTypes.func,
  subForm: PropTypes.func,
  buttonsFixed: PropTypes.bool,
  saveForm: PropTypes.bool,
  setSaveForm: PropTypes.func,
  buttonSubmitDisabled: PropTypes.bool,
  customBeforeSubmit: PropTypes.func,
  removeElementsBeforeSend: PropTypes.func,
}
