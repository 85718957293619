import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import { Loader } from 'core/components/Loader'
import { useConfirmStepStyles } from './ConfirmStep.styles'
import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { downloadFile } from '_helpers/downloadFile'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/proposal'
import provinceSchema from '_schema/province'
import organizationTypeSchema from '_schema/organizationType'

export const ConfirmStep = ({ resource, setResource }) => {
  const [isConfirmFetching, setIsConfirmFetching] = useState(false)

  const handleAccept = () => {
    setIsConfirmFetching(true)

    fetchDataHandleAuthError(
      schema.endpoint_contract_edit.replace(':id', resource.uuid),
      'PUT',
      { body: JSON.stringify({
        isContractDataAccepted: true
      })},
      () => {
        setResource({ ...resource, isContractDataAccepted: true })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
        setIsConfirmFetching(false)
      }
    )
  }

  const handleDownload = () => {
    downloadFile(schema.endpoint_download_contract_pdf.replace(':id', resource.uuid), `umowa-${resource.contractNumber || resource.uuid}.pdf`)
  }

  const additionalResourcesMapping = useMemo(
    () => ({
      provinces: `${provinceSchema.endpoint}?order[title]=desc&pagination=false`,
      organizationTypes: `${organizationTypeSchema.endpoint}?order[title]=desc&pagination=false`,
    }),
    []
  )

  const [additionalResources] = usePropertyResourcesFetch(
    additionalResourcesMapping
  )

  const classes = useConfirmStepStyles()

  return resource.isContractDataAccepted ? (
    <div className={classes.button_container}>
      <Button variant="contained" color="secondary" onClick={handleDownload}>
        {translate('T_MODULE_PROPOSALS_CONTRACT_DOWNLOAD_PDF')}
      </Button>
    </div>
  ) : (
    <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.title}>
          {translate('T_MODULE_PROPOSALS_CONTRACT_REPRESENTATIVES')}
        </div>
        {resource.contractRepresentatives?.map(contractRepresentative => (
          <div className={classes.representative_row}>
            <div>
              <div className={classes.label}>
                {translate('T_MODULE_PROPOSALS_CONTRACT_REPRESENTATIVE_NAME')}
              </div>
              {contractRepresentative.name}
            </div>
            <div>
              <div className={classes.label}>
                {translate('T_MODULE_PROPOSALS_CONTRACT_REPRESENTATIVE_SURNAME')}
              </div>
              {contractRepresentative.surname}
            </div>
            <div>
              <div className={classes.label}>
                {translate('T_MODULE_PROPOSALS_CONTRACT_REPRESENTATIVE_ROLE')}
              </div>
              {contractRepresentative.role}
            </div>
          </div>
        ))}
      </div>
      <div className={classes.row}>
        <div className={classes.title}>
          {translate('T_MODULE_PROPOSALS_CONTRACT_SUBACCOUNT')}
        </div>
        <div className={classes.label}>
          {translate('T_MODULE_PROPOSALS_CONTRACT_SUBACCOUNT_NUMBER')}
        </div>
        {resource.contractSubAccount}
      </div>
      <div className={classes.row}>
        <div className={classes.title}>
          {translate('T_MODULE_PROPOSALS_CONTRACT_ORGANIZATION_DATA')}
        </div>
        <div>
          {(resource.contractNip || resource.contractRegon || resource.contractKrs) && (
            <div className={classes.not_editable_row}>
              <div className={classes.not_editable_label}>
                {resource.nip ?
                  translate('T_MODULE_ORGANIZATION_DATA_NIP')
                  : resource.contractRegon ?
                    translate('T_MODULE_ORGANIZATION_DATA_REGON')
                  : resource.contractKrs ?
                    translate('T_MODULE_ORGANIZATION_DATA_KRS')
                : null}
              </div>
              {resource.contractNip || resource.contractRegon || resource.contractKrs}
            </div>)}
          <div className={classes.not_editable_row}>
            <div className={classes.not_editable_label}>
              {translate('T_MODULE_ORGANIZATION_DATA_ORGANIZATION_NAME')}
            </div>
            {resource.contractOrganizationName}
          </div>
          <div className={classes.not_editable_row}>
            <div className={classes.not_editable_label}>
              {translate('T_MODULE_ORGANIZATION_DATA_ORGANIZATION_TYPE')}
            </div>
            {additionalResources.organizationTypes?.find(organizationType => organizationType['@id'] === resource.contractOrganizationType)?.title}
          </div>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.title}>
          {translate('T_MODULE_PROPOSALS_CONTRACT_ORGANIZATION_ADDRESS')}
        </div>
        <div>
          <div className={classes.not_editable_row_post}>
            <div>
              <div className={classes.not_editable_label}>
                {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_POST_OFFICE')}
              </div>
              {resource.contractPostOffice}
            </div>
            <div>
              <div className={classes.not_editable_label}>
                {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_POST_CODE')}
              </div>
              {resource.contractPostCode}
            </div>
          </div>
          <div className={classes.not_editable_row}>
            <div className={classes.not_editable_label}>
              {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_ADDRESS')}
            </div>
            {resource.contractAddress}
          </div>
          <div className={classes.not_editable_row}>
            <div className={classes.not_editable_label}>
              {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_CITY')}
            </div>
            {resource.contractCity}
          </div>
          <div className={classes.not_editable_row}>
            <div className={classes.not_editable_label}>
              {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_COMMUNE')}
            </div>
            {resource.contractCommune}
          </div>
          <div className={classes.not_editable_row}>
            <div className={classes.not_editable_label}>
              {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_DISTRICT')}
            </div>
            {resource.contractDistrict}
          </div>
          <div className={classes.not_editable_row}>
            <div className={classes.not_editable_label}>
              {translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_PROVINCE')}
            </div>
            {additionalResources.provinces?.find(province => province['@id'] === resource.contractProvince)?.title}
          </div>
        </div>
      </div>
      <div className={classes.button_container}>
        {isConfirmFetching ? (
          <Loader />
        ) : (
          <Button variant="contained" color="secondary" onClick={handleAccept}>
            {translate('T_MODULE_PROPOSALS_CONTRACT_DATA_ACCEPT')}
          </Button>
        )}
      </div>
    </div>
  )
}

ConfirmStep.propTypes = {
  resource: PropTypes.shape({
    isContractDataAccepted: PropTypes.bool,
    contractRepresentatives: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      surname: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired
    })),
    contractNip: PropTypes.string,
    contractRegon: PropTypes.string,
    contractKrs: PropTypes.string,
    contractOrganizationName: PropTypes.string,
    contractOrganizationType: PropTypes.string,
    contractPostOffice: PropTypes.string,
    contractPostCode: PropTypes.string,
    contractAddress: PropTypes.string,
    contractCity: PropTypes.string,
    contractCommune: PropTypes.string,
    contractDistrict: PropTypes.string,
    contractProvince: PropTypes.string
  }).isRequired,
}
