import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  PowerSettingsNewOutlined,
  AccountCircleOutlined,
} from '@material-ui/icons'
import {
  MenuItem,
  ListItemIcon,
  IconButton,
  Button,
  Grow,
  ClickAwayListener,
  MenuList,
  Typography,
} from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import { authActions } from 'core/_actions'
import { translate } from 'core/_helpers/translate'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Popper from '@material-ui/core/Popper'
import { Paper } from '../Paper'
import { roles } from '_helpers/roles'
import changePasswordRoutes from 'pages/ChangePassword/routes'
import organizationDataRoutes from 'pages/applicant/OrganizationData/routes'
import { CustomDialog } from 'core/components/Dialog'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'

const useStyles = makeStyles({
  menuItem: {
    fontSize: 14,
    '& svg': {
      fontSize: 20,
    },
  },
  listItem: {
    minWidth: 32,
  },
  menu_button: {
    '& svg': {
      transition: 'transform 0.2s',
    },
  },
  menu_button_active: {
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
  menu_paper: {
    padding: 0,
    '&> *:first-child': {
      paddingBottom: 24,
    },
  },
})

export const UserMenu = props => {
  const classes = useStyles()
  const [menuAnchor, setMenuAnchor] = useState(null)
  const dispatch = useDispatch()
  const matches = useMediaQuery('(min-width:600px)')
  const profile = useSelector(state => state.profile)

  const handleMenu = e => {
    setMenuAnchor(e.currentTarget)
  }

  const handleClose = () => {
    setMenuAnchor(null)
  }

  const [openExpertInfo, setOpenExpertInfo] = useState(false)

  const handleToogleExpoertInfo = () => {
    setOpenExpertInfo(prevState => !prevState)
  }

  const checkEkspertConfirmedProposal = () => {
    fetchDataHandleAuthError(
      '/users/profile',
      'GET',
      {},
      resp => {
        if (resp.expertRatesRequired) {
          setOpenExpertInfo(true)
        } else {
          dispatch(authActions.logout())
        }
      },
      () => {
        dispatch(authActions.logout())
      }
    )
  }

  const handleLogoutExpert = () => {
    setOpenExpertInfo(false)
    dispatch(authActions.logout())
  }

  const handleLogout = () => {
    if (profile.roles.includes('ROLE_EXPERT')) {
      checkEkspertConfirmedProposal()
    } else {
      dispatch(authActions.logout())
    }
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setMenuAnchor(null)
    }
  }

  const items = [changePasswordRoutes().index].concat(
    profile.roles?.includes(roles.APPLICANT)
      ? [organizationDataRoutes().index]
      : []
  )

  return (
    <div {...props}>
      {matches && profile.email !== undefined ? (
        <Button
          onClick={handleMenu}
          color="primary"
          endIcon={<ExpandMoreIcon />}
          className={clsx(
            classes.menu_button,
            !!menuAnchor && classes.menu_button_active
          )}
        >
          {profile.email}
        </Button>
      ) : (
        <IconButton
          aria-label="user menu"
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleMenu}
        >
          <AccountCircleOutlined color="primary" />
        </IconButton>
      )}
      <Popper
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper classes={{ root: classes.menu_paper }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={!!menuAnchor}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {items.map((item, i) => {
                    const IconComponent = item.icon
                    return (
                      <MenuItem
                        onClick={handleClose}
                        key={`user-menu-link-${i}`}
                        component={Link}
                        to={item.path}
                        className={classes.menuItem}
                      >
                        <ListItemIcon className={classes.listItem}>
                          <IconComponent />
                        </ListItemIcon>
                        {translate(item.title)}
                      </MenuItem>
                    )
                  })}
                  <MenuItem onClick={handleLogout} className={classes.menuItem}>
                    <ListItemIcon className={classes.listItem}>
                      <PowerSettingsNewOutlined />
                    </ListItemIcon>
                    {translate('T_GENERAL_LOGOUT')}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <CustomDialog
        open={openExpertInfo}
        handleToogle={handleToogleExpoertInfo}
        title={'Czy wylogować?'}
        onConfirm={handleLogoutExpert}
        confirmedText={'Wyloguj'}
        confirmedButton={true}
        component={() => (
          <Typography variant={'body1'} color={'primary'}>
            Posiadasz niezatwierdzone oceny wniosków. Czy na pewno chcesz się
            wylogować
          </Typography>
        )}
      />
    </div>
  )
}
