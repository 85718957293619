import { authHeader } from './authHeader'
import { handleResponse as defaultHandleResponse } from './handleResponse'
import { store } from '../_store'

export const fetchData = (
  url,
  method = 'GET',
  headers = {},
  request = {},
  handleResponse = null,
  absoluteUrl = false
) => {
  const isFormData = headers['Content-Type'] === 'multipart/form-data'
  delete headers['Content-Type']

  const showUser = store.getState().auth.showUser

  // sprawdź czy schowUser jest ustawiony i czy metoda jest jedną z tych, które wymagają uprawnień i czy system jest uruchomiony w trybie produkcyjnym
  if (
    !!showUser &&
    ['PUT', 'POST', 'PATCH', 'DELETE'].includes(method) &&
    process.env.NODE_ENV !== 'development'
  ) {
    return defaultHandleResponse({
      status: 403,
      statusText: 'Nie masz uprawnień do tego zasobu',
    })
  }

  return fetch(
    absoluteUrl ? url : `${process.env.REACT_APP_API_ENTRYPOINT}${url}`,
    {
      method,
      headers: {
        accept: 'application/ld+json',
        ...(isFormData
          ? {}
          : {
              'Content-Type':
                method === 'PATCH'
                  ? 'application/merge-patch+json'
                  : 'application/ld+json',
            }),
        ...headers,
        ...authHeader(method),
      },
      ...request,
    }
  ).then(handleResponse || defaultHandleResponse)
}
