import React from 'react'
import { BooleanType } from 'core/components/form/fields/BooleanType'
import { Grid } from '@material-ui/core'
import { StringType } from 'core/components/form/fields/StringType'

export const Chekcboxes = ({ block }) => {
  const values = block.data ? JSON.parse(block.data) : {}
  return (
    <>
      <Grid container spacing={1} style={{ maxWidth: '500px' }}>
        {Object.keys(values?.subform).map(el => (
          <Grid item md={4} key={el}>
            <BooleanType
              setValue={() => {}}
              name={el}
              disabled={false}
              setError={() => false}
              label={values?.subform[el].name}
              top={false}
            />
          </Grid>
        ))}
      </Grid>
      {values?.other && (
        <>
          <BooleanType
            setValue={() => {}}
            name={'other'}
            disabled={false}
            setError={() => false}
            label={'Inne'}
            top={true}
          />
          <div>
            <StringType
              name={'othertext'}
              setValue={() => false}
              disabled={false}
              renderError={false}
              type={'string'}
              setError={() => false}
              error={false}
              label={''}
            />
          </div>
        </>
      )}
    </>
  )
}
