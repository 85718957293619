import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
  FormControl,
  Checkbox,
  TextField,
  FormHelperText
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme=>({
  root: {
    '&> div': {
      borderRadius: 0,
      '&> fieldset': {
        borderColor: theme.palette.primary.main,
      }
    }
  },
  text_field: {
    width: 260,
    marginLeft: 12,
    '&> div': {
      borderRadius: 0,
      '&> fieldset': {
        borderColor: theme.palette.primary.main,
      }
    },
    '& input': {
      backgroundColor: 'white',
      padding: '4px 4px 4px 12px',
      height: 25,
    },
  },
  error: {
    marginLeft: 0,
  },
}))

export const ChoicesCheckboxType = ({
  name,
  defaultValue: initDefaultValue = null,
  value,
  choices,
  addOtherChoice = false,
  error = false,
  renderError = false,
  validators,
  setValue,
  setError,
  disabled,
  classes = {},
}) => {
  const [defaultValue, setDefaultValue] = useState(initDefaultValue)

  const handleCheckboxChange = e => {
    const key = e.target.name
    const checked = e.target.checked

    const value = checked ? JSON.stringify({ key }) : null

    setValue(name, value)
  }

  const handleInputChange = e => {
    const value = e.target.value

    setValue(name, JSON.stringify({ key: 'other', value }))
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(value)
  }, [validateField, value])

  useEffect(() => {
    if (!value && defaultValue) {
      setValue(name, defaultValue)
      setDefaultValue(null)
    }
  }, [
    value,
    setValue,
    name,
    defaultValue,
    setDefaultValue
  ])

  const checkboxes = {
    ...choices,
    ...(addOtherChoice ? { other: translate('T_MODULE_PROPOSALS_CHOICES_CHECKBOX_OTHER') } : {})
  }

  const parsedValue = value ? JSON.parse(value) : null

  const defaultClasses = useStyles()

  return (
    <FormControl
      error={renderError && !!error}
      disabled={disabled}
      className={clsx(defaultClasses.root, classes.root)}
      variant="outlined"
    >
      <div>
        {Object.keys(checkboxes).map(key => (
          <div key={key}>
            <Checkbox
              name={key}
              checked={key === parsedValue?.key}
              onChange={handleCheckboxChange}
            />
            {checkboxes[key]}
          </div>
        ))}
      </div>
      <FormHelperText classes={{ contained: defaultClasses.error }}>
        {translate(renderError && error)}
      </FormHelperText>
      {parsedValue?.key === 'other' && (
        <TextField
          name="other"
          type="text"
          value={parsedValue?.value || ''}
          disabled={disabled}
          onChange={handleInputChange}
          variant="outlined"
          classes={{
            root: defaultClasses.text_field
          }}
        />
      )}
    </FormControl>
  )
}

ChoicesCheckboxType.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  choices: PropTypes.object,
  addOtherChoice: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
}
