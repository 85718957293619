import { useReducer, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { collectionActions } from 'core/_actions/collection.actions'
import { notification } from 'core/_helpers/notification'
import { constants } from 'core/components/table/_state'
import buildUrl from 'build-url'

const initState = ({
  initialConfig,
  endpoint,
  filters,
  sorters,
  perPage,
  paginable,
  storeCollectionId,
}) => {
  const defaultConfig = {
    page: 1,
    perPage: perPage || parseInt(process.env.REACT_APP_COLLECTION_PER_PAGE),
    paginable,
    sorters,
    filters,
    pageIndex: null,
  }

  return {
    collection: endpoint,
    storeCollectionId: storeCollectionId || endpoint,
    data: {
      items: [],
      selected: [],
      selectAll: false,
      totalItems: 0,
      page: initialConfig
        ? initialConfig.page || defaultConfig.page
        : defaultConfig.page,
      perPage: initialConfig
        ? initialConfig.perPage || defaultConfig.perPage
        : defaultConfig.perPage,
      ord: initialConfig
        ? initialConfig?.sorters?.ord?.order ||
          defaultConfig?.sorters?.ord?.order ||
          null
        : defaultConfig.sorters.ord?.order || null,
    },
    config: initialConfig || defaultConfig,
    defaultConfig: defaultConfig,
    showManualOrderPageDropzone: false,
    init: true,
    isFetching: false,
  }
}

export const useCollectionFetch = (
  reducer,
  endpoint,
  filters = [],
  sorters = [],
  perPage = null,
  paginable = true,
  prepareResources = null,
  storeCollectionId = null
) => {
  const initialConfig = useSelector(
    state => state.collection[storeCollectionId || endpoint]
  )

  const [state, stateDispatch] = useReducer(
    reducer,
    {
      initialConfig,
      endpoint,
      filters,
      sorters,
      perPage,
      paginable,
      storeCollectionId,
    },
    initState
  )

  const reduxDispatch = useDispatch()

  const reload = useSelector(state => state.common.reload)

  useEffect(() => {
    stateDispatch({ type: constants.FETCH_START })

    const body = {
      ...getParametersIn(state.config.sorters, 'body', 'order'),
      ...getParametersIn(state.config.filters, 'body', 'value'),
    }

    const query = {
      ...getParametersIn(state.config.sorters, 'query', 'order'),
      ...getParametersIn(state.config.filters, 'query', 'value'),
    }

    const url = buildUrl(state.collection, {
      queryParams: {
        ...query,
        ...(paginable
          ? {
              page: state.config.page,
              perPage: state.config.perPage,
            }
          : {
              pagination: false,
            }),
      },
    })

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      url,
      'GET',
      { ...body, signal },
      response => {
        const preparedResources = prepareResources
          ? prepareResources(response['hydra:member'])
          : response['hydra:member']

        const data = {
          items: preparedResources,
          selected: new Array(preparedResources.length).fill(false),
          selectAll: false,
          totalItems: response['hydra:totalItems'],
          page: state.config.page,
          perPage: state.config.perPage,
          ord: state.config.sorters?.ord?.order || null,
        }

        stateDispatch({ type: constants.FETCH_SUCCESS, payload: { data } })
        reduxDispatch(
          collectionActions.setConfig(state.storeCollectionId, state.config)
        )
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        stateDispatch({ type: constants.FETCH_FAILURE })
        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [
    reduxDispatch,
    paginable,
    prepareResources,
    state.storeCollectionId,
    state.config,
    reload,
    state.collection,
  ])

  useEffect(() => {
    if (state.pageIndex) {
      reduxDispatch(
        collectionActions.setConfig(state.storeCollectionId, {
          ...state.config,
          pageIndex: state.pageIndex,
        })
      )
      stateDispatch({ type: constants.FETCH_FAILURE })
    }
  }, [reduxDispatch, state.config, state.pageIndex, state.storeCollectionId])

  return [state, stateDispatch]
}

export const getParametersIn = (parameters, type, prop) => ({
  ...Object.assign(
    {},
    ...Object.keys(parameters)
      .filter(
        key => parameters[key][prop] !== null && parameters[key].in === type
      )
      .map(key => ({
        [parameters[key].name]: parameters[key][prop],
      }))
  ),
})
