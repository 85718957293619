import React, { useCallback, useState } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { translate } from 'core/_helpers/translate'
import { makeStyles } from '@material-ui/styles'
import { notification } from 'core/_helpers/notification'
import { DateType } from 'core/components/form/fields/DateType'
import SaveIcon from '@material-ui/icons/Save'
import { ReactComponent as RemoveIcon } from 'theme/icons/close.svg'

const useStyles = makeStyles(theme => ({
  columnFields: {
    background: theme.palette.white,
    padding: theme.spacing(3),
    minHeight: '195px',
    width: '100%',
    position: 'relative',
  },
  columnFieldsTitle: {
    marginBottom: theme.spacing(4),
  },
  icons: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  itemSave: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  itemRemove: {
    '&:hover svg path': {
      fill: theme.palette.secondary.main,
    },
  },
}))
const Item = ({
  resourceId,
  count,
  collect,
  onSuccess,
  onRemove,
  schema,
  disabled,
}) => {
  const classes = useStyles()
  const [state, setState] = useState(
    collect
      ? collect
      : {
          dateFrom: null,
          dateTo: null,
          contest: resourceId,
          id: null,
          uuid: '',
        }
  )

  const [error, setError] = useState({
    error: null,
    renderError: false,
  })

  const [logChange, setLogChange] = useState(false)

  const handleChange = (name, value) => {
    setState(state => ({ ...state, [name]: value }))
    setLogChange(true)
    setError({
      error: null,
      renderError: false,
    })
  }

  const handleError = () => {}

  const handleRemove = () => {
    if (state.uuid) {
      const url = `${schema.endpoint}/${state.uuid}`
      fetchDataHandleAuthError(url, 'DELETE', {}, () => {
        onRemove(count, state.uuid)
        notification('warning', 'Zakres dat został usunięty')
      })
    } else {
      onRemove(count, state.uuid)
    }
  }

  const handleSubmit = useCallback(() => {
    setLogChange(false)

    let url = schema.endpoint
    let method = 'POST'
    if (state.uuid) {
      url = `${schema.endpoint}/${state.uuid}`
      method = 'PUT'
    }

    fetchDataHandleAuthError(
      url,
      method,
      {
        body: JSON.stringify(state),
      },
      response => {
        notification('success', 'Zakres dat został zapisany')
        onSuccess(response, count)
      },
      error => {
        if (
          error.response.violations !== undefined &&
          error.response.violations.length > 0
        ) {
          setError({
            error: error.response.violations[0].message,
            renderError: true,
          })
        } else {
          notification('error', 'Błąd')
        }
      }
    )
  }, [count, onSuccess, schema.endpoint, state])

  return (
    <>
      <Grid xs={12} lg={6} xl={4} item style={{ width: '100%' }}>
        <div className={classes.columnFields}>
          <Typography
            className={classes.columnFieldsTitle}
            variant={'h2'}
            color={'primary'}
          >
            {count}
          </Typography>
          <div className={classes.icons}>
            {logChange && (
              <IconButton
                className={classes.itemSave}
                aria-label={'save'}
                color={'primary'}
                onClick={handleSubmit}
              >
                <SaveIcon />
              </IconButton>
            )}
            {!disabled && (
              <IconButton
                className={classes.itemRemove}
                aria-label={'remove'}
                onClick={handleRemove}
              >
                <RemoveIcon />
              </IconButton>
            )}
          </div>
          <Grid container spacing={3} style={{ width: '100%' }}>
            <Grid item xs>
              <DateType
                name={'dateFrom'}
                renderError={error.renderError}
                setError={handleError}
                error={error.error}
                setValue={handleChange}
                value={state.dateFrom}
                label={'Od'}
                disabled={disabled}
                maxDate={state.dateTo ? state.dateTo : '2100-01-01'}
              />
            </Grid>
            <Grid item xs>
              <DateType
                name={'dateTo'}
                renderError={error.renderError}
                setError={handleError}
                error={error.error}
                setValue={handleChange}
                value={state.dateTo}
                label={'Do'}
                disabled={disabled}
                minDate={state.dateFrom ? state.dateFrom : '1900-01-01'}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  )
}

export const DatesReport = ({ resource, schema, buttonText, disabled }) => {
  const [collection, setCollection] = useState(resource[schema.contestProperty])
  const [newCollext, setNewCollect] = useState(false)

  const handleSuccess = (collect, index) => {
    if (index === collection.length + 1) {
      setCollection(state => [...state, collect])
      setNewCollect(false)
    } else {
      setCollection(state => {
        const array = state
        array[index] = collect
        return array
      })
    }
  }

  const handleRemove = (index, uuid) => {
    if (index === collection.length + 1) {
      setNewCollect(false)
    } else {
      const array = collection.filter(el => el.uuid !== uuid)
      setCollection([])
      setCollection(array)
    }
  }

  const handleClickAdd = () => {
    setNewCollect(true)
  }

  return (
    <>
      <Grid container spacing={3} style={{ width: '100%' }}>
        {collection.map((el, index) => (
          <Item
            key={index}
            resourceId={resource['@id']}
            count={index + 1}
            collect={el}
            onSuccess={handleSuccess}
            onRemove={handleRemove}
            schema={schema}
            disabled={disabled}
          />
        ))}
        {newCollext ? (
          <Item
            resourceId={resource['@id']}
            count={collection.length + 1}
            collect={null}
            onSuccess={handleSuccess}
            onRemove={handleRemove}
            schema={schema}
            disabled={disabled}
          />
        ) : (
          <Grid
            xs={12}
            lg={6}
            xl={4}
            item
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ButtonPlus
              text={translate(buttonText)}
              onClick={handleClickAdd}
              disabled={disabled}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}
