import React, { useMemo } from 'react'
import { standard } from 'core/components/table/_columns/standard'
import { Paper } from 'core/components/Paper'
import { CollectionTable } from 'core/components/table'
import schema from '_schema/contests'
import { SchemableCollection } from 'core/components/SchemableCollection'
import routes from './routes'
import { TranslateCell } from 'core/components/table/cells/TranslateCell'
import { DateCell } from 'core/components/table/cells/DateCell'
import { makeStyles } from '@material-ui/styles'

const useStyle = makeStyles(() => ({
  columnHeaderFilter: {
    height: 100,
    alignItems: 'flex-end',
  },
}))

export const Collection = () => {
  const classes = useStyle()
  const columns = useMemo(
    () => [
      {
        ...standard('contestEdition.title'),
        sortable: false,
        filterable: false,
        width: '10%',
      },
      {
        ...standard('title'),
        sortable: false,
        filterable: false,
        width: '20%',
      },
      {
        Cell: TranslateCell,
        accessor: 'type',
        sortable: false,
        width: '6%',
      },
      {
        accessor: 'proposalCollectingDateFrom',
        Cell: DateCell,
        sortable: false,
        width: '6%',
      },
      {
        accessor: 'projectRealizationDateFrom',
        Cell: DateCell,
        sortable: false,
        width: '6%',
      },
      {
        accessor: 'projectRealizationDateTo',
        Cell: DateCell,
        sortable: false,
        width: '6%',
      },
      {
        ...standard('grantMaxAmount'),
        sortable: false,
        filterable: false,
        width: '6%',
      },
      {
        ...standard('proposalDraftCount'),
        sortable: false,
        filterable: false,
        width: '4%',
        vertical: true,
      },
      {
        ...standard('proposalSubmittedCount'),
        sortable: false,
        filterable: false,
        width: '4%',
        vertical: true,
      },
      {
        ...standard('proposalFormalCount'),
        sortable: false,
        filterable: false,
        width: '4%',
        vertical: true,
      },
      {
        ...standard('proposalNotFormalCount'),
        sortable: false,
        filterable: false,
        width: '4%',
        vertical: true,
      },
      {
        ...standard('proposalRecommendationYesCount'),
        sortable: false,
        filterable: false,
        width: '4%',
        vertical: true,
      },
      {
        ...standard('proposalRecommendationNoCount'),
        sortable: false,
        filterable: false,
        width: '4%',
        vertical: true,
      },
      {
        ...standard('proposalReserveCount'),
        sortable: false,
        filterable: false,
        width: '4%',
        vertical: true,
      },
      {
        ...standard('proposalSubsidizedCount'),
        sortable: false,
        filterable: false,
        width: '4%',
        vertical: true,
      },
      {
        ...standard('proposalRejectedCount'),
        sortable: false,
        filterable: false,
        width: '4%',
        vertical: true,
      },
      {
        ...standard('proposalEventOccurencesCount'),
        sortable: false,
        filterable: false,
        width: '4%',
        vertical: true,
      },
    ],
    []
  )
  return (
    <>
      <Paper withPadding={false}>
        <div style={{ paddingTop: 50 }}>
          <SchemableCollection
            component={CollectionTable}
            path={`paths.${schema.endpoint_proposal_status}.get`}
            endpoint={schema.endpoint_proposal_status}
            customResourceSchema={schema.resource_proposal_status}
            columns={columns}
            autoWidth={false}
            filterable={true}
            filterInline={true}
            sortable={true}
            numerable={false}
            resetable={false}
            paginable={true}
            definitionSchema={schema.resource_proposal_status}
            isRowLinkable={true}
            editPath={routes().list.path}
            storeCollectionId={routes().index}
            classes={classes}
          />
        </div>
      </Paper>
    </>
  )
}
