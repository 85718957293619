import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { FormTemplateSectionType } from './FormTemplateSectionType'
import { isFormTemplateInvalid } from 'pages/applicant/Proposals/_helpers/isFormTemplateInvalid'
import { generateFormTemplateRenderError } from 'pages/applicant/Proposals/_helpers/generateFormTemplateRenderError'

export const FormTemplateType = ({
  name,
  value,
  disabled,
  renderError,
  setValue,
  setError,
  contest,
  proposal,
  isFormSubmitted,
  sectionsMapping,
}) => {
  const objectKeys = Object.keys(value).length > 0 ? Object.keys(value) : null
  let valuesArray = {}
  if (objectKeys) {
    objectKeys.forEach(el => {
      valuesArray[el] =
        value[el] === undefined
          ? []
          : Array.isArray(value[el])
          ? value[el]
          : Object.keys(value[el]).length > 0
          ? Object.values(value[el])
          : []
    })
  }
  const [state, setState] = useState({
    values: Array.isArray(valuesArray) ? {} : valuesArray,
    errors: {},
    renderError: {},
    additional: {
      projectRealizationDate: {
        start: null,
        end: null,
      },
    },
    isInvalid: false,
    isFormSubmitted: false,
    init: true,
  })

  const handleAddSectionItem = useCallback(
    uuid => () => {
      setState(state => ({
        ...state,
        values: {
          ...state.values,
          [uuid]: [...(state.values?.[uuid] || []), {}],
        },
        errors: {
          ...state.errors,
          [uuid]: [...(state.errors?.[uuid] || []), {}],
        },
        renderError: {
          ...state.renderError,
          [uuid]: [...(state.renderError?.[uuid] || []), {}],
        },
      }))
    },
    [state.values]
  )

  const handleDeleteSectionItem = useCallback(
    uuid => index => () => {
      setState(state => ({
        ...state,
        values: {
          ...state.values,
          [uuid]: state.values?.[uuid].filter((item, i) => i !== index),
        },
        errors: {
          ...state.errors,
          [uuid]: state.errors?.[uuid].filter((item, i) => i !== index),
        },
        renderError: {
          ...state.renderError,
          [uuid]: state.renderError?.[uuid]?.filter((item, i) => i !== index),
        },
        isInvalid: isFormTemplateInvalid({
          ...state.errors,
          [uuid]: state.errors?.[uuid].filter((item, i) => i !== index),
        }),
      }))
    },
    [setState]
  )

  useEffect(() => {
    setValue(name, state.values, !state.init)
  }, [name, state.values, state.init, setValue])

  useEffect(() => {
    setError(name, state.isInvalid)
  }, [name, setError, state.isInvalid])

  useEffect(() => {
    if (!isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      isFormSubmitted,
    }))
  }, [setState, isFormSubmitted])

  useEffect(() => {
    if (!state.isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      renderError: generateFormTemplateRenderError(state.errors, true),
    }))
  }, [setState, state.isFormSubmitted])

  return (
    sectionsMapping
      ?.filter(section => section.stat)
      .map(section => (
        <FormTemplateSectionType
          uuid={section.uuid}
          title={section.title}
          titleVisibility={section.titleVisibility}
          isMulti={section.isCloneable}
          addButtonTitle={section.cloneButtonTitle}
          addMaxCount={section.cloneMaxCount}
          blocksMapping={section.formSectionBlocks}
          disabled={disabled}
          values={state.values?.[section.uuid]}
          errors={state.errors?.[section.uuid]}
          renderError={state.renderError?.[section.uuid]}
          globalRenderError={renderError}
          additional={state.additional}
          handleAddSectionItem={handleAddSectionItem(section.uuid)}
          handleDeleteSectionItem={handleDeleteSectionItem(section.uuid)}
          setState={setState}
          contest={contest}
          proposal={proposal}
          key={section.uuid}
        />
      )) || null
  )
}

FormTemplateType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  disabled: PropTypes.bool,
  renderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  contest: PropTypes.shape({
    projectRealizationDateFrom: PropTypes.string,
    projectRealizationDateTo: PropTypes.string,
  }).isRequired,
  proposal: PropTypes.object.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
  sectionsMapping: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      title: PropTypes.string,
      titleVisibility: PropTypes.bool,
      stat: PropTypes.bool,
      isCloneable: PropTypes.bool,
      cloneButtonTitle: PropTypes.string,
      cloneMaxCount: PropTypes.number,
      formSectionBlocks: PropTypes.array,
    })
  ),
}
