import { makeStyles } from '@material-ui/styles'

export const useCollectionTabsStyles = makeStyles(theme => ({
  tab: {
    minWidth: 'auto',
    maxWidth: 'initial',
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(3),
    paddingRight: theme.spacing(5),
    fontFamily: ['Sora', 'sans-serif'],
    letterSpacing: '0.05em',
    opacity: 1,
    '&:focus, &:hover': {
      color: theme.palette.secondary.main,
    },
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
    },
  },
  indicator: {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    paddingLeft: theme.spacing(1.5),
    '& > span': {
      maxWidth: 24,
      width: '100%',
      height: 4,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  back_to_list: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    fontSize: 17,
    textDecoration: 'none',
    color: theme.palette.primary.main,
    marginTop: 36,
    '&> :first-child': {
      marginRight: 10
    }
  },
  title: {
    fontSize: 26,
    fontWeight: 'bold',
    marginBottom: 44,
    marginTop: 44,
    color: theme.palette.primary.main,
  },
  bar: {
    paddingLeft: 12
  }
}))
