import React, { useCallback, useState, useMemo } from 'react'
import Moment from 'react-moment'
import { SectionTitle } from 'core/components/SectionTitle'
import { Button, Grid, Typography } from '@material-ui/core'
import { ButtonDownload } from 'core/components/buttons/button-download'
import { FormTemplate } from 'pages/applicant/AppliedProposals/components/FormTemplate'
import { translate } from 'core/_helpers/translate'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { usePropertyResourcesFetch } from 'core/_helpers/usePropertyResourcesFetch'
import { makeStyles } from '@material-ui/styles'
import { CustomDialog } from 'core/components/Dialog'
import { StringType } from 'core/components/form/fields/StringType'
import { downloadFile } from '_helpers/downloadFile'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import schema, {
  CONTENT_REPORT_STATUS_RETURNED,
  CONTENT_REPORT_STATUS_REJECTED,
  CONTENT_REPORT_STATUS_ACCEPTED,
} from '_schema/proposal'
import provinceSchema from '_schema/province'
import populationSchema from '_schema/population'
import organizationTypeSchema from '_schema/organizationType'
import { downloadFileFromFile } from '../../../../_helpers/downloadFileFromFile'

const useStyle = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
  buttonRed: {
    borderColor: theme.palette.error.main,
    '& .MuiButton-label': {
      color: theme.palette.error.main,
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 24px rgba(11, 43, 124, 0.4)',
      '& .MuiButton-label': {
        color: theme.palette.primary.main,
      },
    },
  },
}))

export const ContentFinalReport = ({
  resource,
  eventObject,
  setResource,
  setEvent,
}) => {
  const classes = useStyle()

  const [state, setState] = useState({
    [CONTENT_REPORT_STATUS_RETURNED]: false,
    [CONTENT_REPORT_STATUS_REJECTED]: false,
    description: resource.finalReportReturnExplanation || '',
  })

  const handleToggle = useCallback(type => {
    setState(prevState => ({ ...prevState, [type]: !prevState[type] }))
  }, [])

  const handleChange = useCallback((name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }))
  }, [])

  const handleSubmit = status => {
    fetchDataHandleAuthError(
      schema.endpoint_finalReport_status.replace(':id', resource.uuid),
      'PUT',
      {
        body: JSON.stringify({
          ...{ finalReportStatus: status },
          ...(status === CONTENT_REPORT_STATUS_ACCEPTED
            ? {}
            : { finalReportReturnExplanation: state.description }),
        }),
      },
      response => {
        handleToggle(status)
        setResource({ ...resource, ...response })
        setEvent({ proposalFinalReportStatus: response.finalReportStatus })
        notification('success', translate('Zmieniono status'))
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const handleDownload = () => {
    downloadFile(
      schema.endpoint_finalReport_download.replace(':id', resource.uuid),
      `raport-m-${resource.contractNumber || resource.uuid}.pdf`
    )
  }

  const additionalResourcesMapping = useMemo(
    () => ({
      provinces: `${provinceSchema.endpoint}?order[title]=desc&pagination=false`,
      populations: `${populationSchema.endpoint}?order[title]=desc&pagination=false`,
      organizationTypes: `${organizationTypeSchema.endpoint}?order[title]=desc&pagination=false`,
    }),
    []
  )

  const [additionalResources] = usePropertyResourcesFetch(
    additionalResourcesMapping
  )

  const [finalReportFormState, setFinalReportFormState] = useResourceState()

  const { resource: finalReportForm } = finalReportFormState

  useResourceFetch(
    resource.contest.finalReportForm,
    finalReportForm,
    setFinalReportFormState.isFetching,
    setFinalReportFormState.resource,
    setFinalReportFormState.fetchError
  )

  return (
    <>
      <Grid container spacing={3} justify={'space-between'}>
        <Grid item xs={4} md={2}>
          <Typography color="primary" variant="subtitle1">
            {eventObject.proposalFinalReportSigningDate && (
              <Moment
                date={eventObject.proposalFinalReportSigningDate}
                format={'DD.MM.YYYY'}
              />
            )}
          </Typography>
        </Grid>

        <Grid item xs={8} md={10}>
          <ButtonDownload
            text={'Pobierz wygenerowany raport'}
            onClick={handleDownload}
          />
          {eventObject.proposalFinalReportFile && (
            // <a
            //   href={`${process.env.REACT_APP_API_ENTRYPOINT}${eventObject.proposalFinalReportFile.url}`}
            //   rel="noopener noreferrer"
            // >
            <ButtonDownload
              text={
                'Pobierz ostatni wgrany przez wnioskodawcę skan do sprawdzenia'
              }
              onClick={() =>
                downloadFileFromFile(eventObject.proposalFinalReportFile.uuid)
              }
            />
            // </a>
          )}
        </Grid>
      </Grid>
      <div className={classes.buttons}>
        {eventObject.proposalFinalReportStatus !==
          CONTENT_REPORT_STATUS_REJECTED && (
          <Button
            variant="outlined"
            className={classes.buttonRed}
            onClick={() => handleToggle(CONTENT_REPORT_STATUS_REJECTED)}
          >
            <span>{translate('Odrzuć')}</span>
          </Button>
        )}
        {eventObject.proposalFinalReportStatus !==
          CONTENT_REPORT_STATUS_RETURNED && (
          <Button
            color={'secondary'}
            variant="outlined"
            onClick={() => handleToggle(CONTENT_REPORT_STATUS_RETURNED)}
          >
            <span>{translate('Cofnij do edycji')}</span>
          </Button>
        )}
        {eventObject.proposalFinalReportStatus !==
          CONTENT_REPORT_STATUS_ACCEPTED && (
          <Button
            onClick={() => handleSubmit(CONTENT_REPORT_STATUS_ACCEPTED)}
            color={'secondary'}
            variant="contained"
          >
            <span>{translate('Akceptuj')}</span>
          </Button>
        )}

        <CustomDialog
          handleToogle={() => handleToggle(CONTENT_REPORT_STATUS_REJECTED)}
          open={state[CONTENT_REPORT_STATUS_REJECTED]}
          title={'Informacja dla Wnioskodawcy'}
          confirmedButton={true}
          component={() => (
            <StringType
              name={'description'}
              setValue={handleChange}
              disabled={false}
              renderError={false}
              type={'textarea'}
              setError={() => {}}
              label={''}
              width={'100%'}
              value={state.description}
            />
          )}
          onConfirm={() => handleSubmit(CONTENT_REPORT_STATUS_REJECTED)}
        />
        <CustomDialog
          handleToogle={() => handleToggle(CONTENT_REPORT_STATUS_RETURNED)}
          open={state[CONTENT_REPORT_STATUS_RETURNED]}
          title={'Informacja dla Wnioskodawcy'}
          confirmedButton={true}
          component={() => (
            <StringType
              name={'description'}
              setValue={handleChange}
              disabled={false}
              renderError={false}
              type={'textarea'}
              setError={() => {}}
              label={''}
              width={'100%'}
              value={state.description}
            />
          )}
          onConfirm={() => handleSubmit(CONTENT_REPORT_STATUS_RETURNED)}
        />
      </div>
      <SectionTitle label={'Raport merytoryczny'} divider={true} />
      <div className={classes.buttons}></div>
      {finalReportForm && (
        <FormTemplate
          mapping={finalReportForm.formSections}
          values={resource.finalReportFormValues}
          contest={resource.contest}
          additionalResources={additionalResources}
        />
      )}
    </>
  )
}
