import { Divider, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { translate } from '../_helpers/translate'
import React from 'react'
import { useStyles } from './form'
import clsx from 'clsx'

export const SectionTitle = ({
  label,
  text = null,
  divider = false,
  marginTopSm = false,
}) => {
  const defaultClasses = useStyles()
  return (
    <>
      <div
        className={clsx(
          defaultClasses.sectionTitle,
          marginTopSm && defaultClasses.sectionTitleSmMargin
        )}
      >
        <div className={defaultClasses.sectionTitleText}>
          <Typography color="primary" variant="h2">
            {translate(label)}
          </Typography>
          {text && (
            <Typography color="primary" variant="subtitle1">
              {text}
            </Typography>
          )}
        </div>
        {divider && <Divider variant="inset" />}
      </div>
    </>
  )
}

SectionTitle.propTypes = {
  label: PropTypes.string.isRequired,
  divider: PropTypes.bool,
  marginTopSm: PropTypes.bool,
  text: PropTypes.string,
}
