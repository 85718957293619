import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { ContractAttachmentItemType } from './ContractAttachmentItemType'
import { isContractAttachmentCollectionInvalid } from 'pages/applicant/AppliedProposals/_helpers/isContractAttachmentCollectionInvalid'
import { generateContractAttachmentCollectionRenderError } from 'pages/applicant/AppliedProposals/_helpers/generateContractAttachmentCollectionRenderError'
import { useContractAttachmentCollectionTypeStyles } from './ContractAttachmentCollectionType.styles'
import { ReactComponent as Delete } from 'theme/icons/remove.svg'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { translate } from 'core/_helpers/translate'

export const ContractAttachmentCollectionType = ({
  name,
  value,
  disabled,
  renderError,
  setValue,
  setError,
  contest,
  isFormSubmitted
}) => {
  const [state, setState] = useState({
    values: value,
    errors: value ? Array(value.length).fill({}) : [],
    renderError: value ? Array(value.length).fill({}) : [],
    isInvalid: false,
    isFormSubmitted: false,
    init: true,
  })

  const handleAdd = useCallback(
    () =>  {
      setState(state => ({
        ...state,
        values: [
          ...state.values,
          {}
        ],
        errors: [
          ...state.errors,
          {}
        ],
        renderError: [
          ...state.renderError,
          {}
        ],
      }))
    }, [setState]
  )

  const handleDelete = useCallback(
    index =>
      () => {
        setState(state => ({
          ...state,
          values: state.values?.filter((item, i) => i !== index),
          errors: state.errors?.filter((item, i) => i !== index),
          renderError: state.renderError?.filter((item, i) => i !== index),
          isInvalid: isContractAttachmentCollectionInvalid(
            state.errors?.filter((item, i) => i !== index)
          )
        }))
      }, [setState]
  )

  useEffect(() => {
    setValue(name, state.values, !state.init)
  }, [name, state.values, state.init, setValue])

  useEffect(() => {
    setError(name, state.isInvalid)
  }, [name, setError, state.isInvalid])

  useEffect(() => {
    if (! isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      isFormSubmitted
    }))
  }, [setState, isFormSubmitted])

  useEffect(() => {
    if (!state.isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      renderError: generateContractAttachmentCollectionRenderError(state.errors, true)
    }))
  }, [setState, state.isFormSubmitted])

  const classes = useContractAttachmentCollectionTypeStyles()

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {translate('T_MODULE_PROPOSALS_CONTRACT_ATTACHMENTS_INFO')}
      </div>
      <div>
        {state.values.map((item, index) => (
          <div className={classes.container} key={index}>
            <div>
              <ContractAttachmentItemType
                index={index}
                values={state.values?.[index]}
                errors={state.errors[index]}
                renderError={state.renderError[index]}
                globalRenderError={renderError}
                setState={setState}
                disabled={disabled}
                contest={contest}
              />
            </div>
            <div className={classes.sidebar}>
              <Delete onClick={handleDelete(index)} />
            </div>
          </div>
        ))}
      </div>
      <ButtonPlus
        text={translate('T_MODULE_PROPOSALS_ADD_CONTRACT_ATTACHMENT')}
        disabled={disabled}
        onClick={handleAdd}
      />
    </div>
  )
}

ContractAttachmentCollectionType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  renderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  contest: PropTypes.shape({
    '@id': PropTypes.string.isRequired
  }).isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
}
