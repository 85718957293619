import React from 'react'
import { StatusCell } from 'core/components/table/cells/StatusCell'
import { StatusTextCell } from 'core/components/table/cells/StatusTextCell'

export const status = (
  accessor = 'stat',
  sortable = true,
  filterable = true,
  text = []
) => ({
  accessor,
  sortable,
  filterable,
  Cell: ({ resource, accessor, disabled, tableStateDispatch }) => {
    if (text.length > 0) {
      return (
        <StatusTextCell resource={resource} accessor={accessor} text={text} />
      )
    }

    return (
      <StatusCell
        resource={resource}
        accessor={accessor}
        disabled={disabled}
        tableStateDispatch={tableStateDispatch}
      />
    )
  },
})
