import React, { useCallback, useEffect, useState } from 'react'
import { useBlockCollectionStyles } from 'core/components/block'
import clsx from 'clsx'
import {
  Divider,
  Grid,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { constants, reducer } from 'core/components/embedded/_state'
import { constants as formConstants } from 'core/components/form/_state'
import { useEmbeddedCollectionFetch } from 'core/components/embedded/_helpers/useEmbeddedCollectionFetch'
import { performOrderChange } from 'core/components/embedded/_helpers/performOrderChange'
import {
  BodySkeleton,
  ManuallyOrderableTableBody,
  ManuallyOrderableTableRow,
  ManualOrderDragHandle,
  TableLoader,
} from 'core/components/table'
import { Add } from './Add'
import schema, { BLOCK_TYPE_INFO } from '_schema/formSectionBlock'
import { Delete } from 'core/components/resource/Delete'
import { boolean } from 'core/_helpers/boolean'
import { Form } from 'core/components/form'
import { applyProperties } from 'core/_helpers/applyProperties'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { createObjectFromString } from 'core/_helpers/createObjectFromString'
import _ from 'lodash'
import { isObjectEmpty } from 'core/_helpers/isObjectEmpty'
import { StringType } from 'core/components/form/fields/StringType'
import { BooleanType } from 'core/components/form/fields/BooleanType'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { ReactComponent as IconRemove } from 'theme/icons/remove.svg'
import { makeStyles } from '@material-ui/styles'

const useStyle = makeStyles(theme => ({
  iconRemove: {
    marginTop: theme.spacing(3),
    '&:hover': {
      '& svg path': {
        fill: theme.palette.primary.main,
      },
    },
  },
}))

const SelectSubform = (resource, handleSubFormChange, i) => {
  const classes = useStyle()
  const [items, setItems] = useState(
    resource?.subform || {
      1: {
        name: '',
        default: false,
      },
    }
  )

  const handlePlus = () => {
    setItems(prevState => ({
      ...prevState,
      [Object.keys(prevState).length + 1]: {
        name: '',
        default: false,
      },
    }))
  }

  const handleRemove = useCallback(
    item => {
      let obj = items
      delete obj[item]

      let newObj = {}
      Object.keys(obj).forEach((el, index) => {
        newObj[index + 1] = obj[el]
      })

      setItems(newObj)
      handleSubFormChange(resource['@id'], newObj, i)
    },
    [i, items, resource] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const handleChange = useCallback(
    (item, type, value) => {
      let obj = items
      if (type === 'default') {
        Object.keys(obj).forEach(el => {
          obj[el].default = false
        })
      }

      obj = {
        ...obj,
        [item]: {
          ...obj[item],
          [type]: value,
        },
      }

      setItems(obj)
      handleSubFormChange(resource['@id'], obj, i)
    },
    [i, items, resource] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <>
      {Object.keys(items).map(el => (
        <Grid key={el} container spacing={3} style={{ maxWidth: '650px' }}>
          <Grid item xs={2} md={1}>
            <IconButton
              className={classes.iconRemove}
              onClick={() => handleRemove(el)}
            >
              <IconRemove />
            </IconButton>
          </Grid>
          <Grid item xs={6} md={5}>
            <StringType
              name={el}
              setValue={(name, value) => handleChange(el, 'name', value)}
              disabled={false}
              renderError={false}
              type={'string'}
              setError={() => false}
              error={false}
              label={translate('Opcja ') + el}
              width={'350px'}
              value={items[el].name}
            />
          </Grid>
          <Grid item xs={4} md={6}>
            <BooleanType
              setValue={(name, value) => handleChange(el, 'default', value)}
              name={'bool' + el}
              disabled={false}
              value={items[el].default}
              setError={() => false}
              label={'Default'}
            />
          </Grid>
        </Grid>
      ))}
      <ButtonPlus text={'Dodaj opcję'} onClick={handlePlus} />
    </>
  )
}

export const BlockCollection = ({
  parent,
  endpoint,
  pid,
  parentIri,
  definitionSchema,
  titleAccessor,
  initialItems,
  types,
  setTypes,
}) => {
  const [state, dispatch] = useEmbeddedCollectionFetch(
    reducer,
    endpoint,
    pid,
    parent,
    initialItems
  )

  const defaultClasses = useBlockCollectionStyles()

  const [showSave, setShowSave] = useState({})
  const [subformValues, setSubformValues] = useState({})

  useEffect(() => {
    setShowSave(state.items.map(() => false))
  }, [state.items])

  const handleChange = i => {
    setShowSave(prevState => prevState.map((el, index) => index === i))
  }

  useEffect(() => {
    setTypes(state.items)
  }, [state.items]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = resource => {
    dispatch({
      type: constants.REMOVE_ITEM,
      payload: {
        value: resource,
      },
    })
  }

  const handleOrderChange = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return
      }

      performOrderChange(
        state.items,
        oldIndex,
        newIndex,
        dispatch,
        parent,
        'formSectionBlocks'
      )
    },
    [state.items, dispatch, parent]
  )

  const handleSubmit = (state, dispatch, item) => {
    const values = state.values
    if (item.type !== BLOCK_TYPE_INFO) {
      if (subformValues[item['@id']]) {
        values.subform = subformValues[item['@id']]
      }

      const cloned = {...values}
      delete cloned.data

      values.data = JSON.stringify(cloned)
    }

    dispatch({ type: formConstants.PROCESS, payload: true })

    fetchDataHandleAuthError(
      state.url,
      'PUT',
      { body: JSON.stringify(values) },
      response => {
        if (values?.stat === undefined && response?.stat !== undefined) {
          delete response.stat
        }

        dispatch({
          type: formConstants.SUCCESS,
          payload: {
            method: 'PUT',
            resource: response,
          },
        })
        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')
      },
      error => {
        const errors = error.response.violations.reduce(
          (processedErrors, itemv) => {
            const processedError = createObjectFromString(
              itemv.propertyPath.replace('[', '.').replace(']', ''),
              itemv.message
            )

            return _.merge(processedErrors, processedError)
          },
          {}
        )

        dispatch(
          isObjectEmpty(errors)
            ? { type: formConstants.PROCESS, payload: false }
            : { type: formConstants.FAILURE, payload: { errors } }
        )

        notification(
          'error',
          error.response.violations.length
            ? 'T_FORM_INCORRECT'
            : error.response.detail,
          error.response.title
        )
      },
      {}
    )
  }

  const handleSubFormChange = useCallback((id, values, i) => {
    setSubformValues(prevState => ({ ...prevState, [id]: values }))
    setShowSave(prevState => prevState.map((el, index) => index === i))
  }, [])

  const subForm = (resource, i) => ({
    select: {
      component: SelectSubform(resource, handleSubFormChange, i),
    },
  })
  return (
    <>
      <div className={clsx(defaultClasses.header)}>
        <div className={defaultClasses.sectionTitle}>
          <Typography color="primary" variant="h2">
            {translate('Bloki')}
          </Typography>
          <Divider />
        </div>
      </div>
      <TableContainer className={clsx(defaultClasses.collectionContainer)}>
        <TableLoader show={!state.init && state.isFetching} align="center" />
        <Table size="small">
          <ManuallyOrderableTableBody
            onSortEnd={handleOrderChange}
            helperClass={clsx(defaultClasses.draggedRow)}
            useDragHandle={true}
          >
            {state.init ? (
              <BodySkeleton rows={1} columns={1} />
            ) : state.items.length > 0 ? (
              state.items.map((item, i) => {
                const type = types.find(type => type.name === item.type)
                const properties = applyProperties(
                  schema(item.type).resource.properties,
                  definitionSchema.properties,
                  'missing'
                )
                const handleSuccess = resource => {
                  dispatch({
                    type: constants.UPDATE_ITEM,
                    payload: {
                      value: resource,
                    },
                  })
                }
                let resource = item
                if (item.type !== BLOCK_TYPE_INFO) {
                  resource = item.data
                    ? { ...JSON.parse(item.data), ...item }
                    : item
                }

                return (
                  <ManuallyOrderableTableRow
                    index={i}
                    key={item[process.env.REACT_APP_RESOURCE_ID]}
                  >
                    <TableCell
                      key="element"
                      className={clsx(defaultClasses.cell)}
                      style={{ width: '100%' }}
                    >
                      <div className={defaultClasses.expansionPanel}>
                        <div className={defaultClasses.expansionPanelSummary}>
                          {<ManualOrderDragHandle />}
                          <Typography
                            className={clsx(defaultClasses.blockTitle)}
                            color={'primary'}
                            variant={'h2'}
                          >
                            <span>{translate(type.label)}</span>
                          </Typography>
                          <div className={clsx(defaultClasses.blockIcon)}>
                            <Delete
                              resource={item}
                              accessor={
                                titleAccessor ||
                                `${process.env.REACT_APP_LOCALE}.title`
                              }
                              disabled={state.isFetching}
                              isIcon={true}
                              onSuccess={handleDelete}
                            />
                          </div>
                        </div>
                        <div className={defaultClasses.itemContainer}>
                          {schema(item.type).types[item.type]?.text && (
                            <Typography color={'primary'} variant={'body1'}>
                              {schema(item.type).types[item.type]?.text}
                            </Typography>
                          )}
                          <Form
                            readOnly={false}
                            url={item['@id']}
                            method={'PUT'}
                            properties={properties}
                            resource={resource}
                            fetchCompareResource={boolean(
                              process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
                            )}
                            handleSubmit={(state, disptach) =>
                              handleSubmit(state, disptach, item)
                            }
                            handleSuccess={handleSuccess}
                            showSubmitAndStayButton={false}
                            showSubmitButton={showSave[i]}
                            isChange={() => handleChange(i)}
                            showCancelButton={false}
                            fieldsFullWidth={true}
                            width={'100%'}
                            subForm={() => subForm(resource, i)}
                            key={`${
                              item[process.env.REACT_APP_RESOURCE_ID]
                            }-${i}`}
                          />
                        </div>
                      </div>
                    </TableCell>
                  </ManuallyOrderableTableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={2}
                  className={clsx(defaultClasses.emptyResults)}
                >
                  {translate('T_GENERAL_ITEMS_EMPTY')}
                </TableCell>
              </TableRow>
            )}
          </ManuallyOrderableTableBody>
        </Table>
      </TableContainer>
      <div className={clsx(defaultClasses.addContainer)}>
        <Add
          types={types}
          endpoint={endpoint}
          parent={parentIri}
          dispatch={dispatch}
          disabled={state.isFetching}
        />
      </div>
    </>
  )
}
