import React, { useCallback, useState } from 'react'
import { constants, reducer } from 'core/components/table/_state'
import { useCollectionFetch } from 'core/components/table/_helpers'
import schema from '_schema/proposal'
import schemaContest, { EXPERT_RATING_STATUS_CONFIRMED } from '_schema/contests'
import { Loader } from 'core/components/Loader'
import { makeStyles } from '@material-ui/styles'
import { BooleanType } from 'core/components/form/fields/BooleanType'
import { Link } from 'react-router-dom'
import routes from '../routes'
import { ReactComponent as IconLink } from 'theme/icons/arrow-link.svg'
import { ReactComponent as IconOpen } from 'theme/icons/open.svg'
import { ReactComponent as IconClose } from 'theme/icons/close-contest.svg'
import { Button, Grid, Typography } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import Moment from 'react-moment'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

const useStyles = makeStyles(theme => ({
  item: {
    borderBottom: `1px solid ${theme.palette.separator}`,
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    display: 'grid',
    gridTemplateColumns: '50px 1fr',
    alignItems: 'center',
    maxWidth: 1058,
  },
  iconLink: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  itemUrl: {
    display: 'block',
    position: 'relative',
    color: theme.palette.primary.main,
    textDecoration: 'none',
    paddingRight: 40,
    '&:hover': {
      color: theme.palette.secondary.main,
      '& svg path': {
        stroke: theme.palette.secondary.main,
      },
    },
  },
  itemTitle: {
    marginBottom: theme.spacing(1),
    maxWidth: 639,
    display: 'block',
  },
  itemOrg: {
    marginBottom: theme.spacing(2),
    maxWidth: 639,
  },
  itemInfo: {
    display: 'block',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  buttons: {
    marginTop: theme.spacing(8),
  },
}))

const Item = ({ el, resource, setChecked, checked }) => {
  const classes = useStyles()
  const url = routes()
    .proposal.path.replace(':id', resource.uuid)
    .replace(':proposal', el.uuid)
  return (
    <div className={classes.item}>
      <div className={classes.itemInput}>
        <BooleanType
          setValue={(name, value) => setChecked(name, value)}
          name={el['@id']}
          disabled={false}
          setError={() => null}
          label={''}
          top={false}
          value={!!checked.find(i => i === el['@id'])}
        />
      </div>
      <Link to={url} className={classes.itemUrl}>
        <span className={classes.iconLink}>
          <IconLink />
        </span>
        <Typography variant={'subtitle1'} component={'span'}>
          {resource?.contestEdition?.title}
        </Typography>
        <Typography
          variant={'h2'}
          component={'span'}
          className={classes.itemTitle}
        >
          {el.title}
        </Typography>
        <Grid
          container
          component={'span'}
          spacing={3}
          justify={'space-between'}
          className={classes.itemOrg}
        >
          <Grid item xs={6} md={6} component={'span'}>
            <Typography variant={'body1'} component={'span'}>
              {translate('Nazwa organizacji')}: {el.organizationName}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6} component={'span'}>
            <Typography variant={'body1'} component={'span'}>
              {translate('Numer wniosku')}: {el.proposalNumber}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          component={'span'}
          spacing={3}
          justify={'space-between'}
        >
          <Grid item xs={6} md={4} component={'span'}>
            <Typography
              variant={'subtitle1'}
              component={'span'}
              className={classes.itemInfo}
            >
              {translate('Termin oceny')}:{' '}
              <Moment
                date={resource.formalRatingDateTo}
                format={'DD.MM.YYYY'}
              />
            </Typography>
            <Typography
              variant={'subtitle1'}
              component={'span'}
              className={classes.itemInfo}
            >
              {resource.type === 'OPEN' ? <IconOpen /> : <IconClose />}
              {translate(`T_type_${resource.type}`)}
            </Typography>
          </Grid>
          <Grid item xs={6} md={4} component={'span'}>
            <Typography
              variant={'subtitle1'}
              component={'span'}
              className={classes.itemInfo}
            >
              {translate('Status')}:{' '}
              {translate(`T_RATE_STATUS_${el.expertRatingStatus}`)}
            </Typography>
            <Typography
              variant={'subtitle1'}
              component={'span'}
              className={classes.itemInfo}
            >
              {translate('Liczba punktów')}: {el.expertRatingPoints}
            </Typography>
          </Grid>
        </Grid>
      </Link>
    </div>
  )
}

export const ProposalList = ({ resource }) => {
  const classes = useStyles()
  const endpoint = schema.endpoint_expert_proposals

  const filters = {
    contestUuid: {
      name: 'contest.uuid',
      value: resource.uuid,
      title: 'contest.uuid',
      type: 'string',
      in: 'query',
      prop: 'value',
    },
  }

  const [state, dispatch] = useCollectionFetch(
    reducer,
    endpoint,
    filters,
    [],
    null,
    false,
    null,
    endpoint + resource.uuid
  )

  const [checked, setChecked] = useState([])

  const handleChecked = useCallback((name, value) => {
    setChecked(prevState =>
      value ? [...prevState, name] : prevState.filter(i => i !== name)
    )
  }, [])

  const handleClickConfirmed = useCallback(() => {
    const iri = `${schemaContest.endpoint_expert_confirmed_rate}/${resource.uuid}`
    const data = {
      proposals: checked.map(el => ({
        expertRatingConfirmation: EXPERT_RATING_STATUS_CONFIRMED,
        '@id': el,
      })),
    }

    fetchDataHandleAuthError(
      iri,
      'PUT',
      { body: JSON.stringify(data) },
      () => {
        dispatch({ type: constants.RELOAD })
        notification('success', translate('Oceny zostały potwierdzone'))
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [checked, dispatch, resource.uuid])

  return state.isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : (
    <>
      {state.data.items.map((el, index) => (
        <Item
          el={el}
          key={index}
          resource={resource}
          setChecked={handleChecked}
          checked={checked}
        />
      ))}
      <div className={classes.buttons}>
        <Button
          variant={'contained'}
          color={'secondary'}
          disabled={checked.length === 0}
          onClick={handleClickConfirmed}
        >
          {translate('Zatwierdź')}
        </Button>
      </div>
    </>
  )
}
