import { makeStyles } from '@material-ui/styles'

export const useCollectionStyles = makeStyles(theme => ({
  bar: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingRight: 215,
    paddingBottom: 15,
  },
  grid_container_masonry: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 35,
    paddingBottom: 35,
    paddingLeft: 0,
    paddingRight: 0,
    //columns: 2,
    display: 'grid',
    gridTemplateColumns: 'repeat(2,1fr)',
  },
  grid_container_masonry_single_column: {
    gridTemplateColumns: 'auto',
    //columns: 1
  },
  grid_item: {
    color: theme.palette.primary.main,
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 0,
    marginRight: 0,
    boxSizing: 'border-box',
    padding: 10,
    //pageBreakInside: 'avoid',
    //columnBreakInside: 'avoid',
    textAlign: 'left',
  },
  grid_item_single_column: {
    marginLeft: 0,
    marginRight: 0,
  },
}))
