import React from 'react'
import { ChoiceType } from 'core/components/form/fields/ChoiceType'

export const Select = ({ block }) => {
  const values = block.data ? JSON.parse(block.data) : {}

  const choices = {}
  let defaultValue = ''

  Object.keys(values.subform).forEach(el => {
    choices[el] = values.subform[el].name
    if (values.subform[el].default) {
      defaultValue = el
    }
  })

  return (
    <>
      <div>
        <ChoiceType
          name={'choice'}
          setValue={() => false}
          disabled={false}
          renderError={false}
          setError={() => false}
          label={values.label}
          choices={choices}
          validators={values.required ? ['required'] : null}
          value={defaultValue}
          width={'350px'}
        />
      </div>
    </>
  )
}
