import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { StringType } from 'components/applicant/form/fields/StringType'
import { isBudgetCollectionInvalid } from 'pages/applicant/Proposals/_helpers/isBudgetCollectionInvalid'
import { useBudgetItemTypeStyles } from './BudgetItemType.styles'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'

export const BudgetItemType = ({
  index,
  values,
  errors,
  renderError,
  globalRenderError,
  setState,
  disabled,
  resource,
}) => {
  const [changeTitle, setChangeTitle] = useState(
    disabled && values.title !== values.titleOld
  )
  useEffect(() => {
    console.log(values)
  }, [values])
  const setValue = useCallback(
    (name, value) => {
      setState(state => ({
        ...state,
        values: state.values.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: value,
              }
            : item
        ),
        renderError: state.renderError.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: true,
              }
            : item
        ),
        init: false,
      }))
    },
    [index, setState]
  )
  // console.log(values)
  const setError = useCallback(
    (name, error) => {
      setState(state => ({
        ...state,
        errors: state.errors.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: error,
              }
            : item
        ),
        renderError: state.isFormSubmitted
          ? state.renderError.map((item, i) =>
              i === index
                ? {
                    ...item,
                    [name]: true,
                  }
                : item
            )
          : state.renderError,
        isInvalid: isBudgetCollectionInvalid(
          state.errors.map((item, i) =>
            i === index
              ? {
                  ...item,
                  [name]: !!error,
                }
              : item
          )
        ),
      }))
    },
    [index, setState]
  )

  const validators = useMemo(
    () => ({
      subsidyAmount: ['required', 'minValue(0)'],
      title: ['required'],
    }),
    []
  )

  const classes = useBudgetItemTypeStyles()
  if (
    !values.title &&
    (!resource.subsidyAmount || resource.subsidyAmount === '0')
  ) {
    return null
  }

  const handleTitleChange = () => {
    setChangeTitle(true)
  }
  return (
    <div className={classes.root}>
      <div
        className={classes.input}
        style={{
          fontWeight:
            disabled &&
            resource?.subsidyAmountLast &&
            resource?.subsidyAmountLast !== resource?.subsidyAmount
              ? 700
              : resource?.subsidyAmount !== values?.subsidyAmount
              ? 700
              : 400,
        }}
      >
        {changeTitle ? (
          <>
            <span>{values?.titleOld}</span>
            <StringType
              type="string"
              name="title"
              value={
                disabled && resource?.titleOld
                  ? resource?.titleOld
                  : values?.title
              }
              error={errors?.title}
              renderError={globalRenderError && renderError?.title}
              validators={validators.title}
              disabled={disabled}
              setValue={setValue}
              setError={setError}
              classes={{
                root: classes.input,
              }}
            />
          </>
        ) : (
          <>
            {values?.title}
            {!disabled && (
              <IconButton
                onClick={handleTitleChange}
                style={{ marginLeft: 15 }}
              >
                <EditIcon />
              </IconButton>
            )}
          </>
        )}
      </div>
      <div className={classes.input}>{resource?.subsidyAmount || 0}</div>
      <div>
        <StringType
          type="number"
          name="subsidyAmount"
          value={
            disabled && resource?.subsidyAmountLast
              ? resource?.subsidyAmountLast
              : values?.subsidyAmount
          }
          error={errors?.subsidyAmount}
          renderError={globalRenderError && renderError?.subsidyAmount}
          validators={validators.subsidyAmount}
          disabled={disabled}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input,
          }}
        />
      </div>
    </div>
  )
}

BudgetItemType.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
