import { useEffect, useState } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import provinceSchema from '_schema/province'

export const useKrsDataApi = (
  isFetching,
  setIsFetching,
  type,
  value,
  setValue,
  setError,
  getKrsFromApi = false
) => {
  const [provinces, setProvinces] = useState([])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    if (getKrsFromApi) {
      const url = provinceSchema.endpoint
      fetchDataHandleAuthError(url, 'GET', { signal }, resp => {
        setProvinces(resp['hydra:member'])
      })
    }
    return () => controller.abort()
  }, [getKrsFromApi])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    if (type !== '' && type !== null && isFetching && provinces.length > 0) {
      const url = `/app/gusRegonSearch?${type}=${value}`
      fetchDataHandleAuthError(
        url,
        'GET',
        { signal },
        resp => {
          setIsFetching(false)
          if (resp.gusData.ErrorMessagePl !== undefined) {
            notification('warning', resp.gusData.ErrorMessagePl, 'Brak danych')
          } else if (resp.gusData) {
            setValue('organizationName', resp.gusData['Nazwa'] || '')
            setError('organizationName', false)
            setValue('postOffice', resp.gusData['MiejscowoscPoczty'] || '')
            setError('postOffice', false)
            setValue('postCode', resp.gusData['KodPocztowy'] || '')
            setError('postCode', false)
            let address =
              typeof resp.gusData['Ulica'] === 'string'
                ? resp.gusData['Ulica']
                : ''
            if (resp.gusData['NrNieruchomosci']) {
              address += ` ${resp.gusData['NrNieruchomosci']}`

              if (typeof resp.gusData['NrLokalu'] === 'string') {
                address += `/${resp.gusData['NrLokalu']}`
              }
            }
            setValue('address', address)
            setError('address', false)
            setValue('city', resp.gusData['Miejscowosc'] || '')
            setError('city', false)
            setValue('commune', resp.gusData['Gmina'] || '')
            setError('commune', false)
            setValue('district', resp.gusData['Powiat'] || '')
            setError('district', false)
            const prov = provinces.find(
              el =>
                el.title
                  .replace(/[^a-zA-Z ]/g, '')
                  .substring(0, 5)
                  .toLowerCase() ===
                resp.gusData['Wojewodztwo']
                  .replace(/[^a-zA-Z ]/g, '')
                  .substring(0, 5)
                  .toLowerCase()
            )
            if (prov) {
              setValue('province', prov['@id'])
              setError('province', false)
            }
            notification(
              'success',
              'Sprawdź czy wszystkie dane są poprawne',
              'Pobrano dane'
            )
          } else {
            notification('warning', 'Brak danych')
          }
        },
        error => {
          if (error.response.title === 'AbortError') {
            return
          }

          setIsFetching(false)

          notification('error', error.response.detail, error.response.title)
        }
      )
    }
    return () => controller.abort()
  }, [type, value, isFetching, setIsFetching, provinces, setValue, setError])
}
