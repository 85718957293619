import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { AttachmentItem } from './AttachmentItem'

const useStyles = makeStyles({

})

export const AttachmentStep = ({ attachments }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div>
        {attachments.map(attachment => (
          <AttachmentItem
            title={attachment.title}
            file={attachment.file}
          />
        ))}
      </div>
    </div>
  )
}

AttachmentStep.propTypes = {
  attachments: PropTypes.arrayOf(PropTypes.shape({
    '@id': PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    file: PropTypes.shape({
      originalName: PropTypes.string.isRequired,
      fileSize: PropTypes.number.isRequired,
      url: PropTypes.string.isRequired
    }).isRequired
  })).isRequired
}
