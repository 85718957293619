import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'core/_helpers/prop'

export const StatusTextCell = ({ resource, text, accessor = 'stat' }) => {
  return <>{prop(resource, accessor) ? text[0] : text[1]}</>
}

StatusTextCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.string,
  text: PropTypes.array.isRequired,
}
