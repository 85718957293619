import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { FieldWrapper } from 'components/applicant/form'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main
  },
  row: {
    marginBottom: 22
  }
}))

export const ApplicantStep = ({
  contest,
  state,
  properties,
  resource,
  setValue,
  setError
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <FieldWrapper
        name="coordinators"
        property={properties.coordinators}
        resource={resource}
        state={state}
        setValue={setValue}
        setError={setError}
      />
      <div className={classes.row}>
        {!!contest.applicationApplicantForm?.formSections?.length && (
          <FieldWrapper
            name="applicationApplicantFormValues"
            property={properties.applicationApplicantFormValues}
            state={state}
            setValue={setValue}
            setError={setError}
            contest={contest}
            proposal={resource}
            sectionsMapping={contest.applicationApplicantForm.formSections}
          />
        )}
      </div>
    </div>
  )
}

ApplicantStep.propTypes = {
  contest: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  properties: PropTypes.object.isRequired,
  resource: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired
}
