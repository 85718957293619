import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { RelationAttachmentItemType } from './RelationAttachmentItemType'
import { isRelationAttachmentCollectionInvalid } from 'pages/applicant/InProgressProposals/Relations/_helpers/isRelationAttachmentCollectionInvalid'
import { generateRelationAttachmentCollectionRenderError } from 'pages/applicant/InProgressProposals/Relations/_helpers/generateRelationAttachmentCollectionRenderError'
import { useRelationAttachmentCollectionTypeStyles } from './RelationAttachmentCollectionType.styles'
import { ReactComponent as Delete } from 'theme/icons/remove.svg'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { translate } from 'core/_helpers/translate'

export const RelationAttachmentCollectionType = ({
  name,
  value,
  disabled,
  renderError,
  setValue,
  setError,
  relation,
  isFormSubmitted
}) => {
  const [state, setState] = useState({
    values: value,
    errors: value ? Array(value.length).fill({}) : [],
    renderError: value ? Array(value.length).fill({}) : [],
    isInvalid: false,
    isFormSubmitted: false,
    init: true,
  })

  const handleAdd = useCallback(
    () =>  {
      setState(state => ({
        ...state,
        values: [
          ...state.values,
          {}
        ],
        errors: [
          ...state.errors,
          {}
        ],
        renderError: [
          ...state.renderError,
          {}
        ],
      }))
    }, [setState]
  )

  const handleDelete = useCallback(
    index =>
      () => {
        setState(state => ({
          ...state,
          values: state.values?.filter((item, i) => i !== index),
          errors: state.errors?.filter((item, i) => i !== index),
          renderError: state.renderError?.filter((item, i) => i !== index),
          isInvalid: isRelationAttachmentCollectionInvalid(
            state.errors?.filter((item, i) => i !== index)
          )
        }))
      }, [setState]
  )

  useEffect(() => {
    setValue(name, state.values, !state.init)
  }, [name, state.values, state.init, setValue])

  useEffect(() => {
    setError(name, state.isInvalid)
  }, [name, setError, state.isInvalid])

  useEffect(() => {
    if (! isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      isFormSubmitted
    }))
  }, [setState, isFormSubmitted])

  useEffect(() => {
    if (!state.isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      renderError: generateRelationAttachmentCollectionRenderError(state.errors, true)
    }))
  }, [setState, state.isFormSubmitted])

  const classes = useRelationAttachmentCollectionTypeStyles()

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {translate('T_MODULE_PROPOSALS_RELATION_ATTACHMENTS_INFO')}
      </div>
      <div>
        {state.values.map((item, index) => (
          <div className={classes.container} key={index}>
            <div>
              <RelationAttachmentItemType
                index={index}
                values={state.values?.[index]}
                errors={state.errors[index]}
                renderError={state.renderError[index]}
                globalRenderError={renderError}
                setState={setState}
                disabled={disabled}
                relation={relation}
              />
            </div>
            <div className={classes.sidebar}>
              <Delete onClick={handleDelete(index)} />
            </div>
          </div>
        ))}
      </div>
      <ButtonPlus
        text={translate('T_MODULE_PROPOSALS_ADD_RELATION_ATTACHMENT')}
        disabled={disabled}
        onClick={handleAdd}
      />
    </div>
  )
}

RelationAttachmentCollectionType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  renderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  relation: PropTypes.shape({
    '@id': PropTypes.string
  }),
  isFormSubmitted: PropTypes.bool.isRequired,
}
