import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Grid, Typography } from '@material-ui/core'
import { ButtonLeft } from 'core/components/buttons/button-left'
import { ButtonRight } from 'core/components/buttons/button-right'
import { translate } from 'core/_helpers/translate'
import { useSelector, useDispatch } from 'react-redux'
import { collectionActions } from 'core/_actions/collection.actions'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { getParametersIn } from 'core/components/table/_helpers'
import buildUrl from 'build-url'
import schema from '_schema/proposal'
import { useHistory } from 'react-router-dom'

const useStyle = makeStyles(theme => ({
  root: {
    background: theme.palette.white,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    marginTop: theme.spacing(1),
    minHeight: '85px',
    alignItems: 'stretch',
  },
  item: {
    minHeight: '85px',
  },
  title: {
    marginBottom: theme.spacing(2),
    fontStyle: 'italic',
    color: theme.palette.wariant,
    lineHeight: '20px',
  },
  wraper: {
    maxWidth: '100%',
  },
  arrows: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '85px',
    position: 'relative',
  },
}))

export const HeaderItem = ({ resource, parentResource }) => {
  const history = useHistory()
  const stateColletion = useSelector(
    state => state.collection[resource.contest.uuid]
  )
  const [pageIndex, setPageIndex] = React.useState(0)

  useEffect(() => {
    if (stateColletion && stateColletion.pageIndex) {
      setPageIndex(stateColletion.pageIndex)
    }
  }, [stateColletion])
  const reduxDispatch = useDispatch()
  const [prevButton, setPrevButton] = React.useState(null)
  const [nextButton, setNextButton] = React.useState(null)

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    if (pageIndex && stateColletion) {
      const query = {
        ...getParametersIn(stateColletion.sorters, 'query', 'order'),
        ...getParametersIn(stateColletion.filters, 'query', 'value'),
      }

      const url = buildUrl(schema.endpoint, {
        queryParams: {
          ...query,
          page: pageIndex - 1,
          perPage: 1,
        },
      })

      const url2 = buildUrl(schema.endpoint, {
        queryParams: {
          ...query,
          page: pageIndex + 1,
          perPage: 1,
        },
      })
      if (pageIndex > 1) {
        fetchDataHandleAuthError(url, 'GET', { signal }, response => {
          const item = response['hydra:member'][0]
          if (item) {
            setPrevButton(item.uuid)
          }
        })
      }

      fetchDataHandleAuthError(url2, 'GET', { signal }, response => {
        const item = response['hydra:member'][0]
        if (item) {
          setNextButton(item.uuid)
        }
      })
    }
    return () => controller.abort()
  }, [pageIndex, stateColletion, resource])

  const handleChangePageIndex = (direction, url) => {
    reduxDispatch(
      collectionActions.setConfig(resource.contest.uuid, {
        ...stateColletion,
        pageIndex: stateColletion.pageIndex + direction,
      })
    )
    history.push(url)
  }

  const classes = useStyle()
  return (
    <>
      <div className={classes.wraper}>
        <Grid
          container
          className={classes.root}
          alignItems={'center'}
          spacing={3}
        >
          <Grid item xs={12} md={1} className={classes.arrows}>
            {pageIndex ? (
              <>
                {prevButton && (
                  <ButtonLeft
                    size={'bigger'}
                    text={''}
                    color={'primary'}
                    onclick={() =>
                      handleChangePageIndex(-1, `/projects/item/${prevButton}`)
                    }
                  />
                )}
              </>
            ) : (
              <>
                {resource.prevProposal && (
                  <ButtonLeft
                    size={'bigger'}
                    href={`/projects/item/${resource.prevProposal.uuid}`}
                    text={''}
                  />
                )}
              </>
            )}
          </Grid>
          <Grid item xs={12} md={3} className={classes.item}>
            <Typography
              variant={'subtitle1'}
              className={classes.title}
              color={'primary'}
            >
              {translate('Konkurs')}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary'}>
              {resource.contest.contestEdition.title} {resource.contest.title}
            </Typography>
          </Grid>
          <Grid item xs={12} md={1} className={classes.item}>
            <Typography
              variant={'subtitle1'}
              className={classes.title}
              color={'primary'}
            >
              {translate('Nr wniosku')}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary'}>
              {resource?.proposalNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} md={1} className={classes.item}>
            <Typography
              variant={'subtitle1'}
              className={classes.title}
              color={'primary'}
            >
              {translate('Nr dotacji')}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary'}>
              {resource.subsidyNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} className={classes.item}>
            <Typography
              variant={'subtitle1'}
              className={classes.title}
              color={'primary'}
            >
              {translate('Nr umowy')}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary'}>
              {resource?.contractNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2} className={classes.item}>
            <Typography
              variant={'subtitle1'}
              className={classes.title}
              color={'primary'}
            >
              {translate('Projekt')}
            </Typography>
            <Typography variant={'subtitle1'} color={'primary'}>
              {resource.title}
            </Typography>
          </Grid>
          <Grid item xs={12} md={1} className={classes.arrows}>
            {pageIndex ? (
              <>
                {nextButton && (
                  <ButtonRight
                    size={'bigger'}
                    text={''}
                    onClick={() =>
                      handleChangePageIndex(1, `/projects/item/${nextButton}`)
                    }
                    color={'primary'}
                  />
                )}
              </>
            ) : (
              <>
                {resource.nextProposal && (
                  <ButtonRight
                    size={'bigger'}
                    text={''}
                    href={`/projects/item/${resource.nextProposal.uuid}`}
                    color={'primary'}
                  />
                )}
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  )
}
