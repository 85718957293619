import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import schema from 'core/_schema/user'
import { Form } from 'core/pages'
import routes from './routes'
import { translate } from 'core/_helpers/translate'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { ButtonLeft } from 'core/components/buttons/button-left'
import { ButtonRight } from 'core/components/buttons/button-right'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

const useStyles = makeStyles(theme => ({
  edit: {
    cursor: 'pointer',
    '&:hover, &:focus': {
      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  text: {
    cursor: 'pointer',
    width: '100%',
  },
  disabled: {
    '& path': {
      fill: theme.palette.disabled,
    },
  },
  confirmText: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 0,
  },
  tableHead: {
    borderBottom: `1px solid ${theme.palette.separator}`,
  },
  tableContainer: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(5),
    paddingTop: theme.spacing(3),
  },
  tableHeadCell: {
    color: theme.elements.table.tHeadC,
    fontSize: 16,
    fontStyle: 'italic',
    border: 0,
    paddingBottom: theme.spacing(3),
  },
  tableTitle: {
    color: theme.elements.table.tHeadC,
    fontSize: 18,
    border: 0,
    paddingBottom: theme.spacing(3),
    fontWeight: 600,
  },
  tableCell: {
    border: 0,
    color: theme.palette.primary.main,
  },
  table: {
    marginBottom: theme.spacing(3),
  },
  prevNext: {
    padding: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}))

export const Edit = ({ schema: definitions, ...rest }) => {
  const classes = useStyles()
  const { match } = rest

  const iri = useMemo(() => `${schema('user').endpoint}/${match.params.id}`, [
    match.params.id,
  ])
  const url = useMemo(
    () => `${schema('expert_edit').endpoint}/${match.params.id}`,
    [match.params.id]
  )

  const sidebarTitleAccessor = resources =>
    `${resources.firstName} ${resources.lastName}`

  const sidebarBottomContainer = resources => {
    return (
      <div className={classes.tableContainer}>
        {resources.expertCurrentContests.length > 0 && (
          <Table
            size={'small'}
            aria-label={'Bieżące konkursy'}
            className={classes.table}
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableTitle}>
                  {translate('Bieżące konkursy')}
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="right">
                  {translate('do oceny')}
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="right">
                  {translate('ocenione')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resources.expertCurrentContests.map(el => (
                <TableRow key={el['@id']}>
                  <TableCell className={classes.tableCell}>
                    {el.title}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {el.expertProposalsNotRatedCount}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {el.expertProposalsRatedCount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}

        {resources.expertFinishedContests.length > 0 && (
          <Table
            size={'small'}
            aria-label={'Archiwalne konkursy'}
            className={classes.table}
          >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableTitle}>
                  {translate('Archiwalne konkursy')}
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="right">
                  {translate('do oceny')}
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="right">
                  {translate('ocenione')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {resources.expertFinishedContests.map(el => (
                <TableRow key={el['@id']}>
                  <TableCell className={classes.tableCell}>
                    {el.title}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {el.expertProposalsNotRatedCount}
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    {el.expertProposalsRatedCount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </div>
    )
  }

  const headerTitle = useCallback(
    resource => (
      <CollectionHeader
        hideButton
        hideButtonBack={false}
        buttonTitleLeft={translate('Lista ekspertów')}
        buttonPathLeft={routes().index.path}
      >
        {resource.firstName} {resource.lastName}
      </CollectionHeader>
    ),
    []
  )

  const footer = resource => (
    <>
      <div className={classes.prevNext}>
        {resource.prevUser ? (
          <ButtonLeft
            href={routes().edit.path.replace(':id', resource.prevUser.uuid)}
            text={`${resource.prevUser.firstName} ${resource.prevUser.lastName}`}
            color={'secondary'}
          />
        ) : (
          <span></span>
        )}
        {resource.nextUser ? (
          <ButtonRight
            href={routes().edit.path.replace(':id', resource.nextUser.uuid)}
            text={`${resource.nextUser.firstName} ${resource.nextUser.lastName}`}
          />
        ) : (
          <span></span>
        )}
      </div>
    </>
  )

  const handleSuccess = resource => {
    const urlSuccess = `/security/activationEmailResend/user/${resource.uuid}`
    fetchDataHandleAuthError(
      urlSuccess,
      'GET',
      {},
      () => {
        notification(
          'success',
          translate('Email z linkiem aktywacyjnym wysłany do eksperta')
        )
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }
  const submitButtonLabel = resource => {
    return resource.stat
      ? translate('T_FORM_SAVE')
      : translate('Wyślij link do logowania')
  }

  const showSubmitButton = resource => {
    return !resource.stat
  }

  return (
    <>
      <Form
        header={headerTitle}
        url={url}
        method="PUT"
        collectionPath={routes().index.path}
        definitionSchema={
          definitions[schema('expert_edit').resource.definition]
        }
        customResourceSchema={schema('expert_edit').resource}
        showSubmitAndStayButton={true}
        showCancelButton={false}
        showSubmitButton={showSubmitButton}
        submitAndStayButtonLabel={translate('Zapisz i zostań')}
        iri={iri}
        storeCollectionId={schema('expert_edit').endpoint}
        sidebar={true}
        key={iri}
        fetchCompareResource={false}
        buttonsFixed={false}
        width="100%"
        sidebarWidth={5}
        sidebarStatusIsSwitch={true}
        sidebarTitleAccessor={sidebarTitleAccessor}
        statusTitle={translate('Aktywny')}
        sidebarUrl={url}
        sidebarBottomContainer={sidebarBottomContainer}
        sidebarDeletable={resource => !resource.isDeleted}
        sidebarStatable={resource => !resource.isDeleted}
        footer={footer}
        handleSuccess={handleSuccess}
        submitButtonLabel={submitButtonLabel}
      />
    </>
  )
}
