import { contestPublicationStatuses } from '_helpers/contestPublicationStatuses'
import currentContestsRoutes from 'pages/applicant/CurrentContests/routes'
import announcedContestsRoutes from 'pages/applicant/AnnouncedContests/routes'
import finishedContestsRoutes from 'pages/applicant/FinishedContests/routes'

export const getBackToCollectionLink = status => {
  switch(status) {
    case contestPublicationStatuses.CURRENT:
      return currentContestsRoutes().index.path
    case contestPublicationStatuses.ANNOUNCED:
      return announcedContestsRoutes().index.path
    case contestPublicationStatuses.FINISHED:
      return finishedContestsRoutes().index.path
    default:
      return '/'
  }
}
