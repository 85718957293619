import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { DateType } from 'core/components/form/fields/DateType'
import { translate } from '../../../core/_helpers/translate'

export const Implementation = ({ block }) => {
  const values = block.data ? JSON.parse(block.data) : {}
  return (
    <>
      <Typography variant={'body2'} color={'primary'}>
        {translate('Zakres realizacji')}: 1 września 2021 - 31 stycznia 2022
        (pobrane z danych konkursu)
      </Typography>
      <Grid container spacing={3} style={{ maxWidth: '600px', marginTop: 15 }}>
        <Grid item xs={12} md={6}>
          <DateType
            name={'from'}
            setValue={() => false}
            disabled={false}
            renderError={false}
            setError={() => false}
            label={'Od'}
            validators={values.required ? ['required'] : null}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <DateType
            name={'from'}
            setValue={() => false}
            disabled={false}
            renderError={false}
            setError={() => false}
            label={'Do'}
            validators={values.required ? ['required'] : null}
          />
        </Grid>
      </Grid>
    </>
  )
}
