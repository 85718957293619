import React from 'react'
import { useCollectionContainerStyles } from 'components/applicant/collection_styles/CollectionContainer.styles'
import { Paper } from 'core/components/Paper'
import { ContestCollection } from 'components/applicant/contest_collection'
import { ContestsTabs } from 'pages/applicant/ContestsTabs'
import { FilterBar, ItemComponent } from './components'
import { contestPublicationStatuses } from '_helpers/contestPublicationStatuses'
import { extractFilters } from './_helpers/extractFilters'
import schema from '_schema/contests'
import routes from './routes'

export const Collection = () => {
  const filters = {
    publicationStatus: contestPublicationStatuses.FINISHED,
    'contestEdition.year': null
  }

  const classes = useCollectionContainerStyles()

  return (
    <>
      <ContestsTabs
        value={routes().index.path}
        classes={{ root: classes.tabs }}
      />
      <Paper classes={{ root: classes.paper }}>
        <ContestCollection
          endpoint={schema.endpoint}
          FilterBarComponent={FilterBar}
          ItemComponent={ItemComponent}
          extractFilters={extractFilters}
          filters={filters}
        />
      </Paper>
    </>
  )
}
