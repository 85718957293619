import { authHeader } from 'core/_helpers/authHeader'
import { handleAuthError } from 'core/_helpers/handleAuthError'
import { processError } from 'core/_helpers/processError'
import { AuthError } from 'core/_helpers/authError'

export const fetchFileHandleAuthError = (
  url,
  method = 'GET',
  request = {},
  handleSuccess = () => {},
  handleError = () => {},
  headers = {},
  absoluteUrl = false
) =>
  fetch(absoluteUrl ? url : `${process.env.REACT_APP_API_ENTRYPOINT}${url}`, {
    method,
    headers: {
      'Content-Type': 'application/ld+json',
      ...headers,
      ...authHeader(),
    },
    ...request,
  })
    .then(response => {
      switch (response.status) {
        case 200:
          return response
        case 401:
          return Promise.reject(new AuthError())
        default:
          return Promise.reject({
            response: {
              title: response.status,
              detail: response.statusText,
              violations: [],
            },
          })
      }
    })
    .then(
      response => handleSuccess(response),
      error => {
        handleAuthError.default(error)

        return handleError(processError(error))
      }
    )
