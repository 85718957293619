import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { getBlockFieldsMapping } from 'pages/applicant/Proposals/_helpers/getBlockFieldsMapping'
import { blockTypes } from 'pages/applicant/Proposals/_helpers/blockTypes'
import { translate } from 'core/_helpers/translate'
import { Grid, Typography } from '@material-ui/core'
import { DataInfo } from 'components/DataInfo'

const DATE_FORMAT = 'YYYY-MM-DD'

const useStyles = makeStyles(theme => ({
  help: {
    marginBottom: theme.spacing(3),
    color: theme.palette.disabled,
  },
}))

export const ProjectScheduleBlock = ({ data, values, contest, proposal }) => {
  const mapping = useMemo(
    () => getBlockFieldsMapping(blockTypes.PROJECT_SCHEDULE, data),
    [data]
  )
  const months = useMemo(() => {
    if (
      (!proposal?.projectRealizationDateFrom ||
        !proposal?.projectRealizationDateTo) &&
      (!contest.projectRealizationDateFrom || !contest.projectRealizationDateTo)
    ) {
      return []
    }

    const startDate =
      proposal?.projectRealizationDateFrom &&
      proposal?.projectRealizationDateFrom >= contest.projectRealizationDateFrom
        ? proposal?.projectRealizationDateFrom
        : contest.projectRealizationDateFrom

    const endDate =
      proposal?.projectRealizationDateTo &&
      proposal?.projectRealizationDateTo <= contest.projectRealizationDateTo
        ? proposal?.projectRealizationDateTo
        : contest.projectRealizationDateTo

    const currentMonth = moment(`${moment(startDate).format('YYYY-MM')}-01`)

    const monthsBetween = []

    while (
      currentMonth.format(DATE_FORMAT) <= moment(endDate).format(DATE_FORMAT)
    ) {
      monthsBetween.push({
        title: `${translate(currentMonth.format('MMMM'))} ${currentMonth.format(
          'YYYY'
        )}`,
        date: currentMonth.format('YYYY-MM'),
      })
      currentMonth.add(1, 'M')
    }

    return monthsBetween
  }, [
    proposal,
    contest.projectRealizationDateFrom,
    contest.projectRealizationDateTo,
  ])

  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      <Grid item md={8}>
        {months.map((month, index) => (
          <DataInfo
            key={index}
            label={month.title}
            data={values?.[month.date] || ''}
          />
        ))}
      </Grid>
      <Grid item md={4}>
        {mapping.inputs.help && (
          <Typography
            variant={'body1'}
            color={'primary'}
            className={classes.help}
          >
            {mapping.inputs.help}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

ProjectScheduleBlock.propTypes = {
  data: PropTypes.string,
  values: PropTypes.object,
  contest: PropTypes.shape({
    projectRealizationDateFrom: PropTypes.string,
    projectRealizationDateTo: PropTypes.string,
  }),
  proposal: PropTypes.shape({
    projectRealizationDateFrom: PropTypes.string,
    projectRealizationDateTo: PropTypes.string,
  }),
}
