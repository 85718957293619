import { authConstants } from 'core/_constants'
import { tokenExpiration } from 'core/_helpers/tokenExpiration'

export const authReducer = (state = { isRestoring: true }, action) => {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      return {
        isLogging: true,
        data: { email: action.payload.email },
      }
    case authConstants.LOGIN_RESTORE_REQUEST:
      return {
        isRestoring: true,
      }
    case authConstants.LOGIN_SUCCESS:
    case authConstants.REFRESH_SUCCESS:
    case authConstants.LOGIN_RESTORE_SUCCESS:
      const expInMilliseconds = tokenExpiration(action.payload.token)
      return {
        isLogged: true,
        isRestoring: false,
        data: {
          token: action.payload.token,
          exp: {
            ms: expInMilliseconds,
            date: new Date(expInMilliseconds),
          },
        },
        showUser: action.payload.showUser || false,
      }
    case authConstants.REFRESH_REQUEST:
      return {
        ...state,
        isRefreshing: true,
      }
    case authConstants.LOGIN_FAILURE:
    case authConstants.REFRESH_FAILURE:
    case authConstants.LOGIN_RESTORE_FAILURE:
    case authConstants.LOGOUT:
      return {}
    default:
      return state
  }
}
