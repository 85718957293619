import React from 'react'
import PropTypes from 'prop-types'
import InfiniteScroll from 'react-infinite-scroll-component'
import clsx from 'clsx'
import { useCollectionStyles } from 'components/applicant/collection_styles/Collection.styles'
import { useProposalCollectionFetch } from './_helpers/useProposalCollectionFetch'
import { Loader } from 'core/components/Loader'
import { constants } from './_state'

export const ProposalCollection = ({
  endpoint,
  ItemComponent,
  filters,
  extractFilters = null,
  useCollectionFetch = null,
  perPage = null,
  isSingleColumn = false,
  isStatic = false,
}) => {
  const [state, dispatch] = (
    useCollectionFetch || useProposalCollectionFetch
  )(endpoint, filters, perPage, extractFilters)

  const handleMore = () => {
    if (isStatic) {
      return
    }

    dispatch({ type: constants.HANDLE_MORE })
  }

  const classes = useCollectionStyles()

  return (
    <>
      {state.init && <Loader align="center" />}
      <InfiniteScroll
        dataLength={state.items.length}
        next={handleMore}
        hasMore={isStatic ? false : state.items.length < state.totalItems}
        loader={<Loader align="center" />}
        className={clsx(
          classes.grid_container_masonry,
          isSingleColumn && classes.grid_container_masonry_single_column
        )}
      >
        {state.items.map(item => (
          <div
            className={clsx(
              classes.grid_item,
              isSingleColumn && classes.grid_item_single_column
            )}
            key={item['@id']}
          >
            <ItemComponent item={item} />
          </div>
        ))}
      </InfiniteScroll>
    </>
  )
}

ProposalCollection.propTypes = {
  endpoint: PropTypes.string.isRequired,
  ItemComponent: PropTypes.elementType.isRequired,
  filters: PropTypes.object.isRequired,
  extractFilters: PropTypes.func,
  useCollectionFetch: PropTypes.func,
  perPage: PropTypes.number,
  isSingleColumn: PropTypes.bool,
  isStatic: PropTypes.bool
}
