import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { constants } from 'core/components/table/_state'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { CustomDialog } from 'core/components/Dialog'
import { StringType } from 'core/components/form/fields/StringType'

const useStyles = makeStyles(() => ({
  buttonAddRoot: {
    marginTop: 0,
  },
  buttonAddText: {
    fontFamily: ['Sora', 'sans-serif'],
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '26px',
    letterSpacing: '0.05em',
  },
}))

export const AddCategoryFaq = ({ endpoint, dispatch, ord }) => {
  const [title, setTitle] = useState('')
  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const handleSubmit = () => {
    dispatch({ type: constants.FETCH_START })

    fetchDataHandleAuthError(
      endpoint,
      'POST',
      {
        body: JSON.stringify({
          title: title,
          ord: ord,
        }),
      },
      () => {
        setState({
          isConfirmOpen: false,
          isFetching: false,
        })
        dispatch({ type: constants.RELOAD })

        notification('success', 'Kategoria została dodana', 'T_FORM_SUCCESS')
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        dispatch({ type: constants.FETCH_FAILURE })
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const classes = useStyles()

  const handleChange = useCallback((name, value) => {
    setTitle(value)
  }, [])

  const handleConfirmToggle = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
  }

  const Dialog = useCallback(
    () => (
      <>
        <StringType
          name={'title'}
          setValue={handleChange}
          value={title}
          disabled={state.isFetching}
          renderError={false}
          error={false}
          type={'string'}
          setError={() => false}
          widthInput="360px"
          label={'Nazwa kategorii'}
          validators={[]}
        />
      </>
    ),
    [handleChange, state.isFetching, title]
  )

  return (
    <>
      <ButtonPlus
        text={'Dodaj kategorię'}
        onClick={handleConfirmToggle}
        customClasses={classes}
      />
      <CustomDialog
        title={'Dodaj kategorię'}
        confirmedButton={true}
        onConfirm={handleSubmit}
        open={state.isConfirmOpen}
        handleToogle={handleConfirmToggle}
        isFetching={state.isFetching}
        showActions={true}
        component={Dialog}
      />
    </>
  )
}

AddCategoryFaq.propTypes = {
  endpoint: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  ord: PropTypes.number.isRequired,
}
