import { useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

export const useReportFetch = (
  iri,
  setProposal,
  setContest,
  setForm,
  setFetchError,
  setFormFetched,
  type = 'finalReport'
) => {
  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    getProposal(iri, signal, setProposal, setContest, setForm, setFetchError, setFormFetched, type)

    return () => controller.abort()
  }, [iri, setProposal, setContest, setForm, setFetchError, setFormFetched, type])

  return
}

const getProposal = (iri, signal, setProposal, setContest, setForm, setFetchError, setFormFetched, type) => {
  fetchDataHandleAuthError(
    iri,
    'GET',
    { signal },
    response => {
      setProposal(response)
      getContest(response.contest, signal, setContest, setForm, setFetchError, setFormFetched, type)
    },
    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
      setFetchError(true)
    }
  )
}

const getContest = (iri, signal, setContest, setForm, setFetchError, setFormFetched, type) => {
  fetchDataHandleAuthError(
    iri,
    'GET',
    { signal },
    response => {
      setContest(response)

      if (response[`${type}Form`]) {
        getForm(response[`${type}Form`], signal, setForm, setFetchError)
      } else {
        setFormFetched(true)
      }
    },
    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
      setFetchError(true)
    }
  )
}

const getForm = (iri, signal, setForm, setFetchError) => {
  fetchDataHandleAuthError(
    iri,
    'GET',
    { signal },
    response => {
      setForm(response)
    },
    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
      setFetchError(true)
    }
  )
}
