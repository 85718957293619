const schema = {
  endpoint: '/contest_proposal_attachments',
  resource: {
    definition: 'ContestProposalAttachment-contest_proposal_attachment:write',
    properties: {
      contest: {
        type: 'hidden',
      },
      title: {
        type: 'string',
        validate: ['required'],
        label: 'Nazwa dokumentu',
      },
    },
  },
}

export default schema
