import { makeStyles } from '@material-ui/styles'

export const useFormStyles = makeStyles({
  root: {
    marginTop: 35,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  loader: {
    marginLeft: 0,
    marginBottom: 18,
  },
  submit: {
    marginLeft: 0,
  },
  textarea: {
    width: 400,
  },
})
