import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'
import { nl2br } from '_helpers/nl2br'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4),
  },
}))

export const DataInfo = ({ label, data, bold = false }) => {
  const classes = useStyles()
  return (
    <>
      {!!data && (
        <div className={classes.root}>
          {label && (
            <Typography variant={bold ? 'h3' : 'subtitle1'} color={'primary'}>
              {translate(label)}:
            </Typography>
          )}
          {data !== 'undefined ' && data !== 'undefined' && (
            <Typography variant={'subtitle1'} color={'primary'}>
              {typeof data === 'string' ? nl2br(data) : data}
            </Typography>
          )}
        </div>
      )}
    </>
  )
}

DataInfo.propTypes = {
  label: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
  bold: PropTypes.bool,
}
