import React from 'react'
import { useHistory } from 'react-router-dom'
import { Paper } from 'core/components/Paper'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { useCollectionContainerStyles } from 'components/applicant/collection_styles/CollectionContainer.styles'
import { useCollectionTabsStyles } from 'components/applicant/collection_styles/CollectionTabs.styles'
import { Link } from 'react-router-dom'
import { ArrowBackOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { MainForm } from './components'
import schema, { STATUS_DRAFT, STATUS_RETURNED } from '_schema/proposalRelation'
import proposalSchema from '_schema/proposal'
import routes from '../routes'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 16,
    paddingLeft: 45,
    paddingRight: 32,
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}))

export const RelationsItem = ({ schema: definitions, match }) => {
  const classes = useCollectionContainerStyles()
  const defaultClasses = useCollectionTabsStyles()
  const localClasses = useStyles()

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  const iri = match.params.itemId
    ? `${schema.endpoint}/${match.params.itemId}`
    : null

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError
  )

  const history = useHistory()

  const handleSuccess = () => {
    history.push(routes().relations.path.replace(':id', match.params.id))
  }

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ||
    ![undefined, STATUS_DRAFT, STATUS_RETURNED].includes(resource?.status) ? (
    <NotFound />
  ) : (
    <>
      <div className={localClasses.root}>
        <div className={defaultClasses.bar}>
          <Link to="." className={defaultClasses.back_to_list}>
            <ArrowBackOutlined /> W realizacji
          </Link>
          <h2 className={defaultClasses.title}>Relacje</h2>
        </div>
      </div>
      <Paper classes={{ root: classes.paper_wrapper }}>
        <h3 className={localClasses.header}>Dodaj relacje</h3>
        {resource &&
          resource.returnExplanation !== undefined &&
          resource.returnExplanation &&
          resource.returnExplanation && (
            <Typography variant={'subtitle2'} color={'error'}>
              {resource.returnExplanation}
            </Typography>
          )}
        <MainForm
          iri={iri}
          resource={
            resource || {
              proposal: `${proposalSchema.endpoint}/${match.params.id}`,
            }
          }
          definitionSchema={definitions[schema.resource.definition]}
          customResourceSchema={schema.resource}
          handleSuccess={handleSuccess}
        />
      </Paper>
    </>
  )
}
