import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { ArrowForwardOutlined } from '@material-ui/icons'
import { translate } from 'core/_helpers/translate'
import { proposalApplicationStatuses } from '_helpers/proposalApplicationStatuses'
import appliedProposalsRoutes from 'pages/applicant/AppliedProposals/routes'
import draftProposalsRoutes from 'pages/applicant/DraftProposals/routes'
import finishedProposalsRoutes from 'pages/applicant/FinishedProposals/routes'
import inProgressProposalsRoutes from 'pages/applicant/InProgressProposals/routes'
import { useCollectionItemStyles } from 'components/applicant/collection_styles/CollectionItem.styles'

const itemLink = (status, uuid) => {
  switch (status) {
    case proposalApplicationStatuses.APPLIED:
      return appliedProposalsRoutes().view.path.replace(':id', uuid)
    case proposalApplicationStatuses.DRAFT:
      return draftProposalsRoutes().view.path.replace(':id', uuid)
    case proposalApplicationStatuses.FINISHED:
      return finishedProposalsRoutes().view.path.replace(':id', uuid)
    case proposalApplicationStatuses.INPROGRESS:
      return inProgressProposalsRoutes().view.path.replace(':id', uuid)
    default:
      return '/'
  }
}

const DATE_FORMAT = 'DD.MM.YYYY'

export const ProposalItemComponent = ({ item }) => {
  const classes = useCollectionItemStyles()

  const Component = ({ children }) => {
    if (moment(item.contest.proposalCollectingDateTo).isAfter(new Date())) {
      return (
        <Link
          to={itemLink(
            item.realizationStatus === proposalApplicationStatuses.INPROGRESS
              ? item.realizationStatus
              : item.applicationStatus,
            item.applicationStatus === proposalApplicationStatuses.DRAFT
              ? item.contest.uuid
              : item.uuid
          )}
          className={classes.root}
        >
          {children}
        </Link>
      )
    } else {
      return <div className={classes.root_not_link}>{children}</div>
    }
  }

  return (
    <Component>
      {item.contestEdition && (
        <div className={classes.edition}>{item.contestEdition.title}</div>
      )}
      <div className={classes.title}>{item.title}</div>
      <div className={classes.dates}>
        <div>
          {translate('T_MODULE_CONTESTS_PROPOSAL_COLLECTING_DATE')}:
          <span>
            {item.contest.proposalCollectingDateTo &&
              ` ${moment(item.contest.proposalCollectingDateTo).format(
                DATE_FORMAT
              )}`}
          </span>
        </div>
        <div>
          {translate('T_MODULE_CONTESTS_PROJECT_REALIZATION_DATE')}:
          <span>
            {item.contest.projectRealizationDateFrom &&
              ` ${moment(item.contest.projectRealizationDateFrom).format(
                DATE_FORMAT
              )}`}
            {item.contest.projectRealizationDateTo &&
              ` - ${moment(item.contest.projectRealizationDateTo).format(
                DATE_FORMAT
              )}`}
          </span>
        </div>
      </div>

      <div className={classes.lead}>{item.pageLead}</div>
      {moment(item.contest.proposalCollectingDateTo).isAfter(new Date()) ? (
        <div className={classes.link_container}>
          <div className={classes.link}>
            <ArrowForwardOutlined />
          </div>
        </div>
      ) : (
        <div className={classes.subtitle}>Termin składania wniosku minął</div>
      )}
    </Component>
  )
}

ProposalItemComponent.propTypes = {
  item: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    pageLead: PropTypes.string,
    projectRealizationDateFrom: PropTypes.string,
    projectRealizationDateTo: PropTypes.string,
    proposalCollectingDateTo: PropTypes.string,
    contestEdition: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
}
