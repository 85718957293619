import { useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

export const useProposalFetch = (
  getEndpoint,
  id,
  setResource,
  setFetchError,
  hasUuid = true
) => {
  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    getProposal(getEndpoint, id, signal, setResource, setFetchError, hasUuid)

    return () => controller.abort()
  }, [getEndpoint, id, setResource, setFetchError, hasUuid])

  return
}

const getProposal = (
  getEndpoint,
  id,
  signal,
  setResource,
  setFetchError,
  hasUuid
) => {
  fetchDataHandleAuthError(
    hasUuid ? `${getEndpoint}?proposal.uuid=${id}` : `${getEndpoint}/${id}`,
    'GET',
    { signal },
    response => {
      if (hasUuid) {
        setResource(response['hydra:member'])
      } else {
        setResource(response)
      }
    },

    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
      setFetchError(true)
    }
  )
}
