import { makeStyles } from '@material-ui/styles'

export const useRelationAttachmentItemTypeStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    marginBottom: 10,
    display: 'flex',
    '&> *': {
      marginRight: 30
    },
    '&> *:last-child': {
      marginRight: 0
    }
  },
  label: {
    marginBottom: 10
  },
  input: {
    width: 250
  },
  file_wrapper: {
    marginTop: 28
  }
}))
