import { withStyles } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import { InputLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

export const CustomInput = withStyles(theme => ({
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.wariant}`,
    fontSize: 16,
    width: '100%',
    maxWidth: '350px',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color']),
    color: theme.palette.primary.main,
    '&:focus': {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.common.white,
    },
    '&::placeholder': {
      color: theme.palette.primary.main,
      fontStyle: 'italic',
      opacity: '0.8 !important',
    },
    '&::-webkit-input-placeholder': {
      color: theme.palette.primary.main,
      fontStyle: 'italic',
      opacity: '0.8 !important',
    },
    '&::-moz-placeholder': {
      color: theme.palette.primary.main,
      fontStyle: 'italic',
      opacity: '0.8 !important',
    },
    '&::-ms-input-placeholderr': {
      color: theme.palette.primary.main,
      fontStyle: 'italic',
      opacity: '0.8 !important',
    },
    '&:-ms-input-placeholderr': {
      color: theme.palette.primary.main,
      fontStyle: 'italic',
      opacity: '0.8 !important',
    },
    // '&:focus + .MuiInputAdornment-positionEnd': {
    //   display: 'none',
    // },
  },
  disabled: {
    border: `1px solid ${theme.palette.disabled}`,
  },
  error: {
    '& input': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
}))(InputBase)

export const CustomLabel = withStyles(theme => ({
  root: {
    color: theme.palette.wariant,
    fontSize: '16px',
    lineHeight: 1.4,
    width: '100%',
    maxWidth: '100%',
    position: 'relative',
    marginBottom: '4px',
    transform: 'none',

    '&.Mui-focused': {
      color: theme.palette.secondary.main,
    },
  },
  focused: {
    color: theme.palette.secondary.main,
  },
}))(InputLabel)

export const useCustomSelect = makeStyles(theme => ({
  root: {
    width: '100%',
    fontSize: 16,
    maxWidth: '350px',
    padding: '0',
    color: theme.palette.wariant,
    border: `1px solid ${theme.palette.wariant}`,
    borderRadius: 0,
    minHeight: '37px',
    '& .MuiSelect-select': {
      padding: '1px 12px',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    '& + input + svg': {
      pointerEvents: 'none',
      position: 'absolute',
      right: 6,
    },
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
      color: theme.palette.primary.main,
    },
  },
  paper: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 0,
    marginLeft: -1,
    maxWidth: 202,
    maxHeight: 400,
    overflowY: 'auto',
  },
  paper_auto_complete: {
    width: '100%',
    marginTop: 0,
    maxWidth: '100%',
    '& .MuiAutocomplete-option': {
      padding: '0',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  },
  list: {},
  field_icon: {
    cursor: 'pointer',
    marginLeft: 0,
  },
  icon: {
    pointerEvents: 'none',
    position: 'absolute',
    right: 6,
  },
  item: {
    color: theme.palette.primary.main,
    width: '100%',
    fontSize: 15,
    '&.Mui-selected': {
      backgroundColor: theme.palette.lighting,
      '&:hover': {
        backgroundColor: theme.palette.lighting,
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.lighting,
    },
  },
}))

export const useStyles = makeStyles(theme => ({
  compare: {
    marginTop: 5,
  },
  autocomplete: {
    '& .MuiAutocomplete-input': {
      width: '100%',
    },
  },
  compareNeq: {
    color: theme.palette.error.main,
  },
  form_control: {
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    width: '100%',
    '& div.Mui-disabled': {
      border: 0,
    },
  },
  form_control_select: {
    marginBottom: theme.spacing(3),
    fontSize: '16px',
    width: '100%',
    borderRadius: 0,
    position: 'relative',
    '& .MuiInputBase-root': {
      '&:after': {
        display: 'none',
      },
      '&:before': {
        display: 'none',
      },
    },
  },
  field_icon: {
    position: 'absolute',
    right: 5,
  },
  field_icon_pointer: {
    cursor: 'pointer',
  },
  labelHidden: {
    opacity: 0,
    overflow: 'hidden',
    width: 1,
    height: 1,
  },
  input: {
    '&::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    },
    '-moz-appearance': 'textfield',
  },
  helper: {
    marginTop: -20,
  },
  dateTime: {
    '& .MuiPaper-elevation8': {
      boxShadow: 'none',
    },
  },
  dateTimePaper: {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.disabled}`,
    borderRadius: 0,
    color: theme.palette.primary.main,
    '& .MuiPickersBasePicker-pickerView': {
      maxWidth: 430,
      width: '100%',
      minWidth: 430,
      minHeight: 400,
    },
    '& .MuiPickersCalendar-transitionContainer': {
      minHeight: 340,
    },
    '& .MuiIconButton-label': {
      color: theme.palette.primary.main,
      '& .MuiTypography-body2': {
        fontSize: 16,
      },
    },
    '& .MuiPickersCalendarHeader-dayLabel': {
      color: theme.palette.primary.main,
      fontWeight: '700',
      fontSize: 14,
      textTransform: 'uppercase',
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 40,
      display: 'block',
    },
    '& .MuiPickersCalendarHeader-daysHeader': {
      paddingBottom: '8px',
      borderBottom: `1px solid ${theme.palette.disabled}`,
    },
    '& .MuiPickersDay-day': {
      width: 40,
      height: 40,
    },
    '& .MuiPickersDay-daySelected': {
      borderRadius: 0,
      backgroundColor: theme.palette.secondary.main,
      '& .MuiIconButton-label': {
        color: theme.palette.white,
      },
    },
    '& .MuiPickersClockNumber-clockNumberSelected': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiPickersClockPointer-pointer': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiPickersClock-pin': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiPickersClockPointer-thumb': {
      backgroundColor: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
    },
    '& .MuiPickersClockPointer-noPoint': {
      backgroundColor: theme.palette.secondary.main,
    },
    '& .MuiPickersCalendar-week': {
      '& > div': {
        padding: theme.spacing(1),
      },
    },
    '& .MuiPickersDay-dayDisabled': {
      opacity: 0.5,
    },
    '& .MuiPickersClock-clock': {
      backgroundColor: theme.palette.white,
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '& .MuiPickersClockNumber-clockNumber': {
      fontSize: 16,
      color: theme.palette.primary.main,
      '&.MuiTypography-body1': {
        fontWeight: 700,
      },
    },
  },
  modalIcon: {
    marginTop: theme.spacing(2.5),
  },
}))
