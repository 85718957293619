import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Checkbox, Grid, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { getBlockFieldsMapping } from 'pages/applicant/Proposals/_helpers/getBlockFieldsMapping'
import { blockTypes } from 'pages/applicant/Proposals/_helpers/blockTypes'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  row: {
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: 12,
  },
  help: {
    marginBottom: theme.spacing(3),
    color: theme.palette.disabled,
  },
  label: {
    fontSize: theme.typography.pxToRem(16),
    marginRight: 50,
  },
  text_field: {
    width: 260,
    marginLeft: 12,
    marginTop: 10,
    '&> div': {
      borderRadius: 0,
      '&> fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& input': {
      backgroundColor: 'white',
      padding: '4px 4px 4px 12px',
      height: 25,
    },
  },
}))

export const ChoicesCheckboxBlock = ({ data, values }) => {
  const mapping = useMemo(
    () => getBlockFieldsMapping(blockTypes.CHOICES_CHECKBOX, data),
    [data]
  )

  const checkboxes = {
    ...mapping.input.choices,
    ...(mapping.input.addOtherChoice
      ? { other: translate('T_MODULE_PROPOSALS_CHOICES_CHECKBOX_OTHER') }
      : {}),
  }

  const parsedValue = values?.input ? JSON.parse(values?.input) : null

  const classes = useStyles()

  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item md={8}>
        {Object.keys(checkboxes).map(key => (
          <div key={key} className={classes.row}>
            <Checkbox
              name={key}
              checked={key === parsedValue?.key}
              disabled={true}
              color="secondary"
              size="small"
            />
            <Typography
              className={classes.label}
              variant={'body1'}
              color={'primary'}
            >
              {checkboxes[key]}
            </Typography>
          </div>
        ))}
        {parsedValue?.value && (
          <TextField
            name="other"
            type="text"
            value={parsedValue?.value || ''}
            disabled={true}
            variant="outlined"
            classes={{
              root: classes.text_field,
            }}
          />
        )}
      </Grid>
      <Grid item md={4}>
        {mapping.input.help && (
          <Typography
            variant={'body1'}
            color={'primary'}
            className={classes.help}
          >
            {mapping.input.help}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

ChoicesCheckboxBlock.propTypes = {
  data: PropTypes.string,
  values: PropTypes.object,
}
