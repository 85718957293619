import { makeStyles } from '@material-ui/styles'

export const useBudgetItemTypeStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    marginTop: 10,
    marginBottom: 0,
    display: 'flex',
    paddingBottom: 10,
    borderBottom: '1px solid #ccc',
    width: 'fit-content',
  },
  number: {
    width: 15,
    marginRight: 20,
    fontWeight: 'bold',
  },
  title: {
    width: 170,
    marginRight: 20,
  },
  calculation_method: {
    width: 170,
    marginRight: 20,
  },
  own_contribution_amout: {
    display: 'flex',
    width: 225,
    marginRight: 20,
    '& $input': {
      width: 205,
    },
  },
  subsidy_amount: {
    display: 'flex',
    width: 160,
    marginRight: 20,
  },
  textarea: {
    width: 170,
  },
  input: {
    width: 140,
  },
  pln: {
    marginTop: 5,
    marginLeft: 10,
  },
}))
