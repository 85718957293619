export const extractFilters = filters => {
  let query = {}

  query.publicationStatus = filters.publicationStatus

  if (filters['contestEdition.year']) {
    query['contestEdition.year'] = filters['contestEdition.year']
  }

  return query
}
