import React from 'react'
import { addRoutesConfig } from 'core/_helpers/addRoutesConfig'
import { Route } from 'react-router-dom'
import { Collection } from './Collection'
import { TrashCollection } from './TrashCollection'
import { Edit } from './Edit'
import { SchemableComponent } from 'core/components/SchemableComponent'

const routes = () =>
  addRoutesConfig({
    index: {
      title: 'Eksperci',
      type: Route,
      render: props => <Collection {...props} />,
      path: '/experts',
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
    trash: {
      title: 'Kosz ekspertów',
      type: Route,
      render: props => <TrashCollection {...props} />,
      path: '/experts/trash',
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
    edit: {
      title: 'Eksperci - edit',
      type: Route,
      render: props => (
        <SchemableComponent
          path="definitions"
          component={Edit}
          uniqueKey={true}
          {...props}
        />
      ),
      path: '/experts/:id',
      exact: true,
      resourceAccess: ['ROLE_FOUNDATION', 'ROLE_ADMIN', 'ROLE_GUEST'],
    },
  })

export default routes
