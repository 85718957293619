import React, { useMemo, useState } from 'react'
import schema from 'core/_schema/user'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { standard } from 'core/components/table/_columns/standard'
import { translate } from 'core/_helpers/translate'
import { Paper } from 'core/components/Paper'
import routes from './routes'
import { ButtonTrash } from 'core/components/buttons/button-trash'
import { makeStyles } from '@material-ui/styles'
import { Form } from 'core/components/form'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { CustomDialog } from 'core/components/Dialog'

const useStyles = makeStyles(theme => ({
  button: {
    fontFamily: ['Sora', 'sans-serif'],
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '42px',
    letterSpacing: '0.05em',
    border: 0,
    boxShadow: 0,
    background: 'none',
    cursor: 'pointer',
    padding: '0 10px',
    color: theme.palette.primary.main,
    transition: 'color 0.2s',
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 12,
      '& path': {
        transition: 'stroke 0.2s',
      },
    },
    '&:hover, &:focus': {
      color: theme.palette.secondary.main,
      '& svg path': {
        stroke: theme.palette.secondary.main,
      },
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      minWidth: '250px',
    },
  },
  removeContent: {
    position: 'absolute',
    bottom: theme.spacing(5),
    right: theme.spacing(4),
  },
}))

export const Collection = () => {
  const classes = useStyles()
  const columns = useMemo(
    () => [
      {
        ...standard('firstName'),
        sortable: true,
        filterable: true,
        width: '15%',
      },
      {
        ...standard('lastName'),
        sortable: true,
        filterable: true,
        width: '15%',
      },
      {
        ...standard('email'),
        sortable: true,
        filterable: true,
        width: '15%',
      },
      {
        ...standard('phone'),
        sortable: false,
        filterable: true,
        width: '15%',
      },
      {
        ...standard('organizationName'),
        sortable: true,
        filterable: true,
        width: '20%',
      },
      {
        ...standard('expertCurrentContestsCount'),
        sortable: false,
        filterable: false,
        width: '20%',
      },
    ],
    []
  )

  const [reload, setReload] = useState(false)

  const handleSetReload = () => {
    setReload(state => !state)
  }

  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const handleConfirmToggle = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
  }

  const handleConfirmSuccess = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
    setReload(true)
  }

  const [buttonRef, setButtonRef] = useState(null)

  const onButtonRef = element => {
    setButtonRef(element)
  }

  const Dialog = () => (
    <>
      <Form
        url={schema('expert_register').endpoint}
        method="POST"
        properties={schema('expert_register').resource.properties}
        showSubmitAndStayButton={false}
        showCancelButton={false}
        fieldsFullWidth={true}
        width="100%"
        handleCancel={handleConfirmToggle}
        buttonContainerRef={buttonRef}
        handleSuccess={handleConfirmSuccess}
      />
    </>
  )

  return (
    <>
      <CollectionHeader buttonTitle={''} buttonPath={''} hideButton={true}>
        {translate('Eksperci')}
      </CollectionHeader>
      <Paper withPadding={false}>
        <SchemableCollection
          component={CollectionTable}
          path={`paths.${schema('expert').endpoint}.get`}
          endpoint={schema('expert').endpoint}
          customResourceSchema={schema('expert').resource}
          columns={columns}
          autoWidth={false}
          filterable={true}
          filterInline={true}
          sortable={true}
          numerable={false}
          customFilters={[
            {
              name: 'isDeleted',
              value: 'false',
              title: 'isDeleted',
            },
          ]}
          resetable={false}
          paginable={true}
          definitionSchema={schema('expert').resource}
          isRowLinkable={true}
          editPath={routes().edit.path}
          reload={reload}
          setReload={handleSetReload}
        />
        <ButtonPlus text={'Dodaj eksperta'} onClick={handleConfirmToggle} />
        <CustomDialog
          title="Dodaj eksperta"
          component={Dialog}
          confirmedButton={false}
          onConfirm={() => false}
          open={state.isConfirmOpen}
          handleToogle={handleConfirmToggle}
          isFetching={state.isFetching}
          showActions={true}
          onButtonRef={onButtonRef}
        />
        <div className={classes.removeContent}>
          <ButtonTrash text={'Kosz'} path={`/experts/trash`} />
        </div>
      </Paper>
    </>
  )
}
