import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { useStyles } from '../style'
import { Button, Typography } from '@material-ui/core'

export const TextWithButton = ({
  title,
  description,
  buttonTitle,
  buttonUrl,
}) => {
  const classes = useStyles()

  const cleanDescription = DOMPurify.sanitize(description, {
    USE_PROFILES: { html: true },
  })

  return (
    <div className={classes.block}>
      {title && (
        <Typography
          variant={'h3'}
          color={'primary'}
          className={classes.titleSection}
        >
          {title}
        </Typography>
      )}
      {description && (
        <div
          dangerouslySetInnerHTML={{ __html: cleanDescription }}
          className={classes.description}
        ></div>
      )}
      {buttonTitle && buttonUrl && (
        <div className={classes.btn_center}>
          <Button variant="outlined" color="secondary" href={buttonUrl}>
            {buttonTitle}
          </Button>
        </div>
      )}
    </div>
  )
}

TextWithButton.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonUrl: PropTypes.string,
}
