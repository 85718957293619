import React from 'react'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { ArrowBackOutlined } from '@material-ui/icons'
import { useShowStyles } from 'pages/applicant/Contests/Show.styles'
import { makeStyles } from '@material-ui/styles'
import routes from './routes'
import routesa from 'pages/applicant/AppliedProposals/routes'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import schema from '_schema/proposal'
import { Sidebar } from 'pages/applicant/AppliedProposals/components'
import { useProposalState } from './_helpers/useProposalState'
import { useProposalFetch } from './_helpers/useProposalFetch'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  boxes_container: {
    paddingTop: 45,
    paddingLeft: '15%',
  },
  boxes: {
    display: 'grid',
    gridTemplateColumns: 'repeat( auto-fit, minmax(230px, 1fr) )',
    columnGap: '24px',
    rowGap: '24px',
    gridAutoRows: '187px',
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    color: theme.palette.secondary.main,
    border: '2px solid',
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 800,
    textDecoration: 'none',
    padding: '0 10px',
    position: 'relative',
  },
  disabled: {
    pointerEvents: 'none',
    opacity: 0.5,
  },
  linksvg: {
    position: 'absolute',
    right: 5,
    top: 5,
  },
}))
export const View = ({ match }) => {
  const defaultClasses = useShowStyles()
  const classes = useStyles()
  const iri = `${schema.endpoint}/${match.params.id}`

  const [proposalState, setProposalState] = useProposalState()

  const { proposal, contest, isFetching, fetchError } = proposalState

  useProposalFetch(
    iri,
    setProposalState.proposal,
    setProposalState.contest,
    setProposalState.fetchError,
    setProposalState.subresourceFetched
  )

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Grid container className={defaultClasses.root}>
      <Grid item xs={8} className={defaultClasses.description_col}>
        <div>
          <Link
            to={routes().index.path}
            className={defaultClasses.description_back_to_list}
          >
            <ArrowBackOutlined /> W realizacji
          </Link>
        </div>
        <div className={classes.boxes_container}>
          <div className={classes.boxes}>
            <Link
              className={classes.box}
              to={routes()
                .view.path.replace('inprogress', 'applied')
                .replace(':id', match.params.id)}
            >
              Zobacz wniosek
            </Link>

            <Link
              className={classes.box}
              to={routes().budget.path.replace(':id', match.params.id)}
            >
              Zarządzaj budżetem
            </Link>

            <Link
              className={classes.box}
              to={routes().relations.path.replace(':id', match.params.id)}
            >
              Zarządzaj relacjami
            </Link>

            <Link
              className={classes.box}
              to={routes().leadtime.path.replace(':id', match.params.id)}
            >
              Zarządzaj harmonogramem działań
            </Link>

            {proposal.isPartialReportRequired && (
              <Link
                className={classes.box}
                to={routes().partialReport.path.replace(':id', match.params.id)}
              >
                Złóż raport częściowy
              </Link>
            )}

            {moment().isSameOrAfter(contest.endingReportDateFrom) && (
              <Link
                className={classes.box}
                to={routes().finalReport.path.replace(':id', match.params.id)}
              >
                Złóż raport końcowy
              </Link>
            )}

            {proposal.proposalVisitation.length > 0 && (
              <Link
                className={classes.box}
                to={routes().visit.path.replace(':id', match.params.id)}
              >
                Wizyta monitoringowa
              </Link>
            )}

            {contest.trainings.map((el, index) => (
              <>
                {el.notificationSend && moment().isSameOrBefore(el.date) && (
                  <Link
                    className={classes.box}
                    to={routesa()
                      .training.path.replace(':id', match.params.id)
                      .replace(':index', `training-${index + 1}`)}
                  >
                    <svg
                      className={classes.linksvg}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M8.5 11V10.5H7.5V11H8.5ZM7.5 11.01V11.51H8.5V11.01H7.5ZM7.5 4.5V8.5H8.5V4.5H7.5ZM7.5 11V11.01H8.5V11H7.5ZM8 14.5C4.41015 14.5 1.5 11.5899 1.5 8H0.5C0.5 12.1421 3.85786 15.5 8 15.5V14.5ZM14.5 8C14.5 11.5899 11.5899 14.5 8 14.5V15.5C12.1421 15.5 15.5 12.1421 15.5 8H14.5ZM8 1.5C11.5899 1.5 14.5 4.41015 14.5 8H15.5C15.5 3.85786 12.1421 0.5 8 0.5V1.5ZM8 0.5C3.85786 0.5 0.5 3.85786 0.5 8H1.5C1.5 4.41015 4.41015 1.5 8 1.5V0.5Z"
                        fill="#FF9700"
                      />
                    </svg>
                    Szkolenie
                  </Link>
                )}
              </>
            ))}
          </div>
        </div>
      </Grid>
      <Grid item xs={4}>
        <Sidebar
          proposalUuid={match.params.id}
          contestUuid={contest?.uuid}
          collectingEndDate={contest?.collectingEndDate}
          applicationDate={proposal?.applicationDate}
          formalRatingDateTo={contest?.formalRatingDateTo}
          contractSigningDate={proposal?.contractSigningDate}
          contractSignedFile={proposal?.contractFile}
          contestTrainings={contest.trainings}
          acceptationStatus={proposal.acceptationStatus}
          proposalVisitation={proposal.proposalVisitation[0]}
          withEditButton={false}
          proposalTrainings={proposal.proposalTrainings}
          proposalNumber={proposal?.proposalNumber}
        />
      </Grid>
    </Grid>
  )
}
