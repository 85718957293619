import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  list: {
    padding: '41px 61px',
    margin: '0 0',
  },
  item: {
    borderBottom: `0`,
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
    padding: '8px 0',
    '& button, & div': {
      width: '100%',
    },
  },
  buttonsInline: {
    '& > div': {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: theme.spacing(1),
      '& div': {
        marginTop: '0',
      },
    },
  },
  itemRight: {
    justifyContent: 'flex-end',
    '& button': {
      width: 'auto',
    },
  },
  itemGridThree: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: theme.spacing(1),
  },
  buttonFullWidth: {
    '& button': {
      width: '100%',
      maxWidth: '300px',
    },
    '& > div': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  itemSpan: {
    textAlign: 'right',
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  active: {
    borderColor: `${theme.palette.secondary.main} !important`,
  },
}))
