import React, { useCallback, useState } from 'react'
import { StringType } from 'core/components/form/fields/StringType'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { Grid, IconButton } from '@material-ui/core'
import { ReactComponent as RemoveIcon } from 'theme/icons/remove.svg'

export const Input = ({ block }) => {
  const values = block.data ? JSON.parse(block.data) : {}
  const [inputs, setInputs] = useState([0])
  const handleClick = () => {
    setInputs(prevState => [...prevState, prevState.length])
  }
  const handleRemove = useCallback(() => {
    const array = [...inputs]
    array.pop()
    setInputs(array)
  }, [inputs])
  return (
    <>
      {inputs.map(el => (
        <Grid spacing={3} container key={el} style={{ maxWidth: 500 }}>
          <Grid item xs={values.multi ? 10 : 12}>
            <StringType
              name={el + 'example'}
              setValue={() => false}
              disabled={false}
              renderError={false}
              error={false}
              label={values.label}
              type={values.validate}
              setError={() => false}
              placeholder={values.input}
              validators={[
                values.validate,
                values.required ? 'required' : null,
              ]}
              maxLength={values.limit}
              endText={values.unit}
            />
          </Grid>
          {values.multi && inputs.length > 1 && (
            <Grid item xs={2} style={{ paddingTop: 32 }}>
              <IconButton onClick={handleRemove}>
                <RemoveIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}
      {values.multi && (inputs.length < values.max || !values.max) && (
        <div style={{ marginBottom: 30 }}>
          <ButtonPlus text={'Dodaj kolejny'} onClick={handleClick} />
        </div>
      )}
    </>
  )
}
