import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { translate } from '../../_helpers/translate'
import { ReactComponent as DownloadIcon } from 'theme/icons/download.svg'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    border: 'none',
    background: 'none',
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    justifyContent: 'center',
    // marginTop: theme.spacing(4),
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover, &:focus': {
      '& svg path': {
        stroke: theme.palette.primary.main,
      },
      '& span': {
        color: theme.palette.primary.main,
      },
    },
  },
  icon: {
    marginRight: 15,
    '& path': {
      transition: 'fill 0.2s',
    },
  },
  text: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    fontSize: 16,
    letterSpacing: '0.02em',
    lineHeight: 1.75,
    fontFamily: ['Source Sans Pro', 'sans-serif'],
    transition: 'color 0.2s',
  },
}))

export const ButtonDownload = ({ text, onClick, path, name }) => {
  const classes = useStyles()

  return (
    <>
      {path ? (
        <a
          href={path}
          className={classes.root}
          download={name || ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <DownloadIcon className={classes.icon} />
          <span className={classes.text}>{translate(text)}</span>
        </a>
      ) : (
        <button className={classes.root} onClick={onClick} type="button">
          <DownloadIcon className={classes.icon} />
          <span className={classes.text}>{translate(text)}</span>
        </button>
      )}
    </>
  )
}

ButtonDownload.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  path: PropTypes.string,
  name: PropTypes.string,
}
