import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { StringType } from 'components/applicant/form/fields/StringType'
import { getBlockFieldsMapping } from 'pages/applicant/Proposals/_helpers/getBlockFieldsMapping'
import { blockTypes } from 'pages/applicant/Proposals/_helpers/blockTypes'
import { translate } from 'core/_helpers/translate'

const DATE_FORMAT = 'YYYY-MM-DD'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 8
  },
  label: {
    marginBottom: 5
  },
  month: {
    marginTop: 8
  },
  input: {
    width: 450,
  },
  help: {
    marginLeft: 25,
    marginTop: 6,
    color: theme.palette.disabled,
  },
}))

export const ProjectScheduleBlock = ({
  data,
  values,
  errors,
  renderError,
  globalRenderError,
  setValue,
  setError,
  contest,
  disabled,
  additional,
}) => {
  const mapping = useMemo(
    () => getBlockFieldsMapping(blockTypes.PROJECT_SCHEDULE, data),
    [data]
  )

  const validators = useMemo(() => ({
      inputs: []
      // inputs: mapping.inputs.required ? [ 'required' ] : []
    }),
    []
    // [mapping]
  )

  const months = useMemo(() => {
    if (
      (!additional.projectRealizationDate.start || !additional.projectRealizationDate.end) &&
      (!contest.projectRealizationDateFrom || !contest.projectRealizationDateTo)
    ) {
      return []
    }

    const startDate = additional.projectRealizationDate.start && additional.projectRealizationDate.start >= contest.projectRealizationDateFrom
      ? additional.projectRealizationDate.start
      : contest.projectRealizationDateFrom

    const endDate = additional.projectRealizationDate.end && additional.projectRealizationDate.end <= contest.projectRealizationDateTo
      ? additional.projectRealizationDate.end
      : contest.projectRealizationDateTo

    const currentMonth = moment(`${moment(startDate).format('YYYY-MM')}-01`)

    const monthsBetween = []

    while(currentMonth.format(DATE_FORMAT) <= moment(endDate).format(DATE_FORMAT)) {
      monthsBetween.push({
        title: `${translate(currentMonth.format('MMMM'))} ${currentMonth.format('YYYY')}`,
        date: currentMonth.format('YYYY-MM')
      })
      currentMonth.add(1, 'M')
    }

    return monthsBetween
  }, [
    additional.projectRealizationDate.start,
    additional.projectRealizationDate.end,
    contest.projectRealizationDateFrom,
    contest.projectRealizationDateTo
  ])

  const classes = useStyles()

  return (
    <div className={classes.row}>
      <div>
        {months.map(month => (
          <div className={classes.month} key={month.date}>
            <div className={classes.label}>
              {month.title}
            </div>
            <StringType
              type="textarea"
              name={month.date}
              value={values?.[month.date]}
              error={errors?.[month.date]}
              renderError={globalRenderError && renderError?.[month.date]}
              disabled={disabled}
              validators={validators.inputs}
              setValue={setValue}
              setError={setError}
              classes={{
                root: classes.input
              }}
            />
          </div>
        ))}
      </div>
      <div className={classes.help}>
        {mapping.inputs.help}
      </div>
    </div>
  )
}

ProjectScheduleBlock.propTypes = {
  data: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  contest: PropTypes.shape({
    projectRealizationDateFrom: PropTypes.string,
    projectRealizationDateTo: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool,
  additional: PropTypes.object.isRequired
}
