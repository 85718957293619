import React, { useCallback, useEffect, useState } from 'react'
import { SectionTitle } from 'core/components/SectionTitle'
import { StringType } from 'core/components/form/fields/StringType'
import { BooleanType } from 'core/components/form/fields/BooleanType'
import { Button, Grid, Typography } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { makeStyles } from '@material-ui/styles'
import { ReadFormGenerator } from './ReadFormGenerator'
import { useSelector } from 'react-redux'
import schema, {
  RESULT_STATUS_FAILED,
  RESULT_STATUS_PASSED,
} from '_schema/proposalTraining'
import schemaUser from 'core/_schema/user'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import Moment from 'react-moment'
import clsx from 'clsx'

const useStyle = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
  textGreen: {
    color: theme.palette.success.main,
  },
  marginTop: {
    marginTop: theme.spacing(5),
  },
}))

export const TrainingResult = ({
  eventObject,
  resource,
  updateResource,
  updateGlobalResource,
}) => {
  const classes = useStyle()
  const user = useSelector(state => state.profile)
  const [state, setState] = useState({
    remarks: eventObject.training.remarks || '',
    proposalMonitoringRecommendation:
      resource.monitoringRecommendation || false,
    resultStatus: 'test',
    examinator: '',
  })

  useEffect(() => {
    setState({
      remarks: eventObject.training.remarks || '',
      proposalMonitoringRecommendation:
        resource.monitoringRecommendation || false,
      resultStatus: 'test',
      examinator: '',
    })
  }, [eventObject.training, resource.monitoringRecommendation])

  const handleChange = useCallback((name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }))
  }, [])

  const handleSubmit = useCallback(
    type => {
      const iri = `${schema.endpoint}/setResult/${eventObject.training.uuid}`
      const data = {
        ...state,
        examinator: `${schemaUser('user').endpoint}/${user.uuid}`,
        resultStatus: type,
        resultDate: new Date(),
      }
      fetchDataHandleAuthError(
        iri,
        'PUT',
        { body: JSON.stringify(data) },
        () => {
          notification(
            'success',
            translate('Wyniki szkolenia zostały ustawione.')
          )
          updateResource()
          updateGlobalResource({
            ...resource,
            monitoringRecommendation: state.proposalMonitoringRecommendation,
          })
        },
        error => {
          notification('error', error.response.detail, error.response.title)
        }
      )
    },
    [
      eventObject.training.uuid,
      resource,
      state,
      updateGlobalResource,
      updateResource,
      user.uuid,
    ]
  )

  return (
    <>
      <SectionTitle label={'Wyniki szkolenia'} marginTopSm={true} />
      {eventObject.training.proposalTrainingFormValues &&
      Object.keys(eventObject.training.proposalTrainingFormValues).length >
        0 ? (
        <>
          <ReadFormGenerator
            values={eventObject.training.proposalTrainingFormValues}
            formIRI={eventObject.training.contestTraining.inquiryForm}
            contest={resource.contest}
          />
          <StringType
            name={'remarks'}
            setValue={handleChange}
            disabled={false}
            renderError={false}
            type={'textarea'}
            setError={() => null}
            error={false}
            label={'Notes / komunikat'}
            width={'100%'}
            value={state.remarks}
          />
          <BooleanType
            setValue={handleChange}
            name={'proposalMonitoringRecommendation'}
            disabled={false}
            setError={() => null}
            value={state.proposalMonitoringRecommendation}
            label={'Rekomendowany do monitoringu'}
          />
          {/* {!eventObject.proposalTrainingResultStatus && ( */}
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              size="large"
              color={'secondary'}
              onClick={() => handleSubmit(RESULT_STATUS_FAILED)}
            >
              {translate('Niezaliczone')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => handleSubmit(RESULT_STATUS_PASSED)}
            >
              {translate('Zaliczone')}
            </Button>
          </div>
          {/* )} */}
          {eventObject.proposalTrainingResultStatus && (
            <>
              <Grid container spacing={3} className={classes.marginTop}>
                <Grid item xs={12} md={2}>
                  <Typography variant={'subtitle1'} color={'primary'}>
                    <Moment
                      date={eventObject.training.resultDate}
                      format={'DD.MM.YYYY'}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Typography
                    variant={'h3'}
                    color={
                      eventObject.proposalTrainingResultStatus ===
                      RESULT_STATUS_FAILED
                        ? 'error'
                        : 'primary'
                    }
                    className={clsx(
                      eventObject.proposalTrainingResultStatus ===
                        RESULT_STATUS_PASSED && classes.textGreen
                    )}
                  >
                    {translate(
                      `T_PROPOSAL_TRAINING_RESULT_${eventObject.proposalTrainingResultStatus}`
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Typography
                    variant={'subtitle1'}
                    color={'primary'}
                    style={{ lineHeight: '28px' }}
                  >
                    {eventObject.training.examinator.firstName || ''}{' '}
                    {eventObject.training.examinator.lastName || ''}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </>
      ) : (
        <Typography variant={'subtitle1'} color={'primary'}>
          {translate('Ankieta nie została wypełniona przez wnioskodawce')}
        </Typography>
      )}
    </>
  )
}
