export const isRelationAttachmentCollectionInvalid = relationAttachments => {
  if (!relationAttachments) {
    return false
  }

  let isInvalid = 0

  relationAttachments.forEach(relationAttachment => {
    if (!relationAttachment) {
      return
    }

    Object.keys(relationAttachment).forEach(field => {
      if (relationAttachment[field]) {
        isInvalid++
      }
    })
  })

  return isInvalid
}
