import React, { useCallback, useState } from 'react'
import { Step, StepButton, Stepper } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { ApplicantFormValues } from './ApplicantFormValues'
import { ButtonDownload } from 'core/components/buttons/button-download'
import { makeStyles } from '@material-ui/styles'
import { BudgetFormValues } from './BudgetFormValues'
import { AttachmentFormValues } from './AttachmentFormValues'
import { ProjectFormValues } from './ProjectFormValues'
import { downloadFile } from '_helpers/downloadFile'
import schema from '_schema/proposal'

const useStyle = makeStyles(theme => ({
  download: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(-2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`form-panel-${index}`}
      aria-labelledby={`form-panel-step-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  )
}

export const ContainerProposal = ({
  resource,
  withHeader = true,
  contestResource = null,
}) => {
  const classes = useStyle()
  const [activeStep, setActiveStep] = useState(0)
  const handleStepChange = useCallback(value => {
    setActiveStep(value)
  }, [])
  const handleDownload = () => {
    downloadFile(
      `${schema.endpoint_pdf_download}${resource.uuid}`,
      `wniosek-${resource?.proposalNumber || resource.uuid}.pdf`
    )
  }
  return (
    <>
      {withHeader && (
        <div className={classes.download}>
          <ButtonDownload text={'Pobierz'} onClick={handleDownload} />
        </div>
      )}
      <Stepper alternativeLabel nonLinear activeStep={activeStep}>
        <Step key={0}>
          <StepButton
            onClick={() => handleStepChange(0)}
            aria-controls={`form-panel-${0}`}
          >
            {translate('Wnioskodawca')}
          </StepButton>
        </Step>
        <Step key={1}>
          <StepButton
            onClick={() => handleStepChange(1)}
            aria-controls={`form-panel-${1}`}
          >
            {translate('Projekt')}
          </StepButton>
        </Step>
        <Step key={2}>
          <StepButton
            onClick={() => handleStepChange(2)}
            aria-controls={`form-panel-${2}`}
          >
            {translate('Budżet')}
          </StepButton>
        </Step>
        <Step key={3}>
          <StepButton
            onClick={() => handleStepChange(3)}
            aria-controls={`form-panel-${3}`}
          >
            {translate('Załączniki')}
          </StepButton>
        </Step>
      </Stepper>
      <TabPanel key={0} index={0} value={activeStep}>
        <ApplicantFormValues
          resource={resource}
          contestResource={contestResource}
        />
      </TabPanel>
      <TabPanel key={1} index={1} value={activeStep}>
        <ProjectFormValues
          resource={resource}
          contestResource={contestResource}
        />
      </TabPanel>
      <TabPanel key={2} index={2} value={activeStep}>
        <BudgetFormValues resource={resource} />
      </TabPanel>
      <TabPanel key={3} index={3} value={activeStep}>
        <AttachmentFormValues resource={resource} />
      </TabPanel>
    </>
  )
}
