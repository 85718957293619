import { makeStyles } from '@material-ui/styles'

export const useContractStyles = makeStyles(theme => ({
  title: {
    paddingTop: 60,
    paddingLeft: 85,
    paddingRight: 85,
    fontWeight: 'bold',
    fontSize: 26,
    color: theme.palette.primary.main,
    marginBottom: 20,
  },
  info: {
    paddingTop: 20,
    paddingLeft: 85,
    paddingRight: 85,
    color: theme.palette.primary.main,
  },
  info_header: {
    fontWeight: 'bold',
    fontSize: 18
  },
  info_footer: {
    fontStyle: 'italic'
  },
  steps_container: {
    width: 'auto',
    marginTop: 40,
    marginLeft: 82,
    marginRight: 82,
  },
  return_message: {
    color: theme.palette.error.main,
    marginTop: 20
  }
}))
