import { StringType } from './StringType'
import { IntegerType } from './IntegerType'
import { NumberType } from './NumberType'
import { TinymceType } from './TinymceType'
import { PasswordRepeatType } from './PasswordRepeatType'
import { BooleanType } from './BooleanType'
import { ResourceType } from './ResourceType'
import { MultipleResourceType } from './MultipleResourceType'
import { DateType } from './DateType'
import { DateTimeType } from './DateTimeType'
import { MapType } from './MapType'
import { TranslationType } from './translation/TranslationType'
import { FileType } from './file/FileType'
import { ImageType } from './ImageType'
import { YoutubeType } from './YoutubeType'
import { ChoiceType } from './ChoiceType'
import { MediaType, MediaImageType, MediaYoutubeType } from './media'
import { CollectionType } from './CollectionType'
import { KrsType } from './KrsType'
import { HiddenType } from './hiddenType'
import { RadioType } from './RadioType'
import { ModalStringType } from './ModalStringType'

export const fields = {
  string: StringType,
  integer: IntegerType,
  number: NumberType,
  textarea: StringType,
  tinymce: TinymceType,
  password: StringType,
  passwordRepeat: PasswordRepeatType,
  boolean: BooleanType,
  resource: ResourceType,
  multipleResource: MultipleResourceType,
  date: DateType,
  datetime: DateTimeType,
  map: MapType,
  translation: TranslationType,
  file: FileType,
  image: ImageType,
  youtube: YoutubeType,
  choice: ChoiceType,
  'media[type]': MediaType,
  'media[image]': MediaImageType,
  'media[youtube]': MediaYoutubeType,
  collection: CollectionType,
  krstype: KrsType,
  hidden: HiddenType,
  radio: RadioType,
  modal: ModalStringType,
}
