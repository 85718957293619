import React from 'react'
import { Form } from 'core/components/form'
import provincesSchema from '../../../core/_schema/provinces'

export const Address = ({ block }) => {
  const values = block.data ? JSON.parse(block.data) : {}
  const properties = {
    post: {
      type: 'group',
      width: '600px',
      properties: {
        postOffice: {
          label: 'T_FORM_FIELD_POST_OFFICE',
          type: 'string',
          width: '350px',
          column: 8,
          validate: [
            values.postRequired !== null && values.postRequired
              ? 'required'
              : '',
          ],
          placeholder: values.postOffice,
        },
        postCode: {
          label: 'T_FORM_FIELD_POST_CODE',
          type: 'string',
          width: '87px',
          column: 4,
          validate: [
            values.postRequired !== null && values.postRequired
              ? 'required'
              : '',
          ],
          placeholder: values.postCode,
        },
      },
    },
    address: {
      label: 'T_FORM_FIELD_ADDRESS',
      type: 'string',
      width: '350px',
      validate: [
        values.addressRequired !== null && values.addressRequired
          ? 'required'
          : '',
      ],
      placeholder: values.address,
    },
    city: {
      label: 'T_FORM_FIELD_CITY',
      type: 'string',
      width: '350px',
      validate: [
        values.cityRequired !== null && values.cityRequired ? 'required' : '',
      ],
      placeholder: values.city,
    },
    commune: {
      label: 'T_FORM_FIELD_COMMUNE',
      type: 'string',
      width: '350px',
      validate: [
        values.communeRequired !== null && values.communeRequired
          ? 'required'
          : '',
      ],
      placeholder: values.commune,
    },
    district: {
      label: 'T_FORM_FIELD_DISTRICT',
      type: 'string',
      width: '350px',
      validate: [
        values.districtRequired !== null && values.districtRequired
          ? 'required'
          : '',
      ],
      placeholder: values.district,
    },
    province: {
      label: 'T_FORM_FIELD_PROVICNES',
      type: 'resource',
      endpoint: `${provincesSchema.endpoint}?order[title]=desc&pagination=false`,
      titleAccessor: 'title',
      width: '210px',
      validate: [
        values.provinceRequired !== null && values.provinceRequired
          ? 'required'
          : '',
      ],
      placeholder: values.province,
    },
  }
  return (
    <>
      <Form
        method={'PUT'}
        url={'/'}
        properties={properties}
        showSubmitButton={false}
        showCancelButton={false}
        showSubmitAndStayButton={false}
        width={800}
      />
    </>
  )
}
