import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

export const StyledTabs = withStyles(theme => ({
  indicator: {
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    paddingLeft: theme.spacing(1.5),
    '& > span': {
      maxWidth: 24,
      width: '100%',
      height: 4,
      backgroundColor: theme.palette.secondary.main,
    },
  },
}))(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)

export const StyledTab = withStyles(theme => ({
  root: {
    minWidth: 'auto',
    textTransform: 'none',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(3),
    paddingRight: theme.spacing(5),
    fontFamily: ['Sora', 'sans-serif'],
    letterSpacing: '0.05em',
    opacity: 1,
    '&:focus, &:hover': {
      color: theme.palette.secondary.main,
    },
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
    },
  },
}))(props => <Tab disableRipple {...props} />)
