import React from 'react'
import { useCollectionContainerStyles } from 'components/applicant/collection_styles/CollectionContainer.styles'
import { Paper } from 'core/components/Paper'
import { BudgetTabs } from 'pages/applicant/InProgressProposals/Budget/BudgetTabs'
import routes from '../routes'
import { useBudgetCurrentFetch } from './_helpers/useBudgetCurrentFetch'
import proposalSchema from '_schema/proposal'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import { useResourceState } from 'core/_helpers/useResourceState'
import { TableList } from './components/TableList'

export const CurrentBudget = ({ match }) => {
  const classes = useCollectionContainerStyles()
  const proposalUuid = match.params.id

  const [proposalState, setProposalState] = useResourceState()

  const {
    resource: proposalResource,
    isFetching: proposalResourceIsFetching,
    fetchError: proposalResourceFetchError,
  } = proposalState

  useBudgetCurrentFetch(
    proposalSchema.endpoint_budget_current,
    proposalUuid,
    setProposalState.resource,
    setProposalState.fetchError
  )

  return proposalResourceIsFetching ? (
    <Loader align="center" marginTop={15} />
  ) : proposalResourceFetchError ? (
    <NotFound />
  ) : (
    <>
      <BudgetTabs
        value={routes().budget.path.replace(':id', match.params.id)}
        classes={{ root: classes.tabs }}
        id={match.params.id}
      />
      <Paper classes={{ root: classes.paper_wrapper }}>
        <TableList items={proposalResource} title="Koszta" />
      </Paper>
    </>
  )
}
