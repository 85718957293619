export const isContractRepresentativeCollectionInvalid = contractRepresentatives => {
  if (!contractRepresentatives) {
    return false
  }

  let isInvalid = 0

  contractRepresentatives.forEach(contractRepresentative => {
    if (!contractRepresentative) {
      return
    }

    Object.keys(contractRepresentative).forEach(field => {
      if (contractRepresentative[field]) {
        isInvalid++
      }
    })
  })

  return isInvalid
}
