import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Button, List, ListItem, Typography } from '@material-ui/core'
import { Delete } from 'core/components/resource/Delete'
import { useStyles } from 'core/components/resource/sidebar/Sidebar.styles'
import clsx from 'clsx'
import { translate } from 'core/_helpers/translate'
import {
  CONTEST_PUBLICATION_STATUS_ANNOUNCED,
  CONTEST_PUBLICATION_STATUS_CURRENT,
  CONTEST_PUBLICATION_STATUS_DRAFT,
  CONTEST_PUBLICATION_STATUS_FINISHED,
} from '_schema/contests'
import schema from '_schema/contests'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { useDispatch, useSelector } from 'react-redux'
import { commonConstants } from 'core/_constants'
import { CustomDialog } from 'core/components/Dialog'

export const SidebarContest = ({
  resource,
  titleAccessor = 'title',
  statAccessor = 'publicationStatus',
  collectionPath,
  onButtonSaveRef = () => false,
  setResource,
}) => {
  const dispatch = useDispatch()
  const isChange = useSelector(state => state.formSetting.isChange)
  const history = useHistory()
  const [status, setStatus] = useState(resource[statAccessor])
  const onDeleteSuccess = () => {
    history.push(collectionPath)
  }
  const defaultClasses = useStyles()

  const handleStatusClick = useCallback(
    status => {
      const url = `${schema.endpointPublicationStatus}/${resource.uuid}`
      fetchDataHandleAuthError(
        url,
        'PUT',
        {
          body: JSON.stringify({
            publicationStatus: status,
          }),
        },
        resp => {
          dispatch({
            type: commonConstants.SET_CURRENT_RESOURCE,
            payload: { resource: resp },
          })
          setResource({ ...resource, publicationStatus: status })
          setStatus(resp[statAccessor])
          notification('success', 'Zmieniono status konkursu')
        },
        error => {
          notification('error', error.response.detail, error.response.title)
        }
      )
    },
    [dispatch, resource, setResource, statAccessor]
  )

  const [open, setOpen] = useState(false)
  const handleToogle = () => {
    setOpen(prevState => !prevState)
  }
  const [openBack, setOpenBack] = useState(false)
  const handleToogleBack = () => {
    setOpenBack(prevState => !prevState)
  }
  const handleConfirm = () => {
    setOpen(false)
    handleStatusClick(CONTEST_PUBLICATION_STATUS_ANNOUNCED)
  }
  const handleConfirmBack = () => {
    setOpenBack(false)
    handleStatusClick(CONTEST_PUBLICATION_STATUS_DRAFT)
  }

  return (
    <>
      <List className={defaultClasses.list}>
        {status !== CONTEST_PUBLICATION_STATUS_DRAFT &&
          resource.publicationDate && (
            <ListItem className={defaultClasses.item}>
              <Typography variant={'subtitle1'} color={'primary'}>
                {translate('Konkurs opublikowany')} {resource.publicationDate}
              </Typography>
            </ListItem>
          )}
        <ListItem
          className={clsx(defaultClasses.item, defaultClasses.itemRight)}
        >
          <Delete
            resource={resource}
            accessor={titleAccessor}
            onSuccess={onDeleteSuccess}
            deleteText={
              status !== CONTEST_PUBLICATION_STATUS_DRAFT
                ? 'Usuń konkurs'
                : 'Usuń szkic'
            }
            url={`${schema.endpointDelete}/${resource.uuid}`}
          />
        </ListItem>
        {!!onButtonSaveRef && (
          <ListItem
            className={clsx(defaultClasses.item, defaultClasses.itemRight)}
          >
            <div
              className={clsx(
                defaultClasses.buttonFullWidth,
                defaultClasses.itemRight
              )}
              ref={onButtonSaveRef}
            ></div>
          </ListItem>
        )}
        <ListItem
          className={clsx(
            defaultClasses.item,
            defaultClasses.itemRight,
            defaultClasses.buttonFullWidth
          )}
        >
          <Button
            variant="contained"
            color="secondary"
            size="large"
            disableRipple
            disableFocusRipple
            disabled={status !== CONTEST_PUBLICATION_STATUS_DRAFT || isChange}
            onClick={handleToogle}
          >
            {translate('Opublikuj konkurs')}
          </Button>
        </ListItem>
        {status === CONTEST_PUBLICATION_STATUS_ANNOUNCED && (
          <ListItem
            className={clsx(
              defaultClasses.item,
              defaultClasses.itemRight,
              defaultClasses.buttonFullWidth
            )}
          >
            <Button
              size={'small'}
              variant={'text'}
              color={'primary'}
              onClick={handleToogleBack}
            >
              {translate('Przywróć do edycji')}
            </Button>
          </ListItem>
        )}
        {status !== CONTEST_PUBLICATION_STATUS_DRAFT && (
          <>
            <Typography
              variant={'body1'}
              color={'primary'}
              className={defaultClasses.item}
            >
              {translate('Ustaw status konkursu')}:
            </Typography>
            <ListItem
              className={clsx(
                defaultClasses.item,
                defaultClasses.itemGridThree
              )}
            >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disableRipple
                disableFocusRipple
                className={clsx(
                  status === CONTEST_PUBLICATION_STATUS_FINISHED &&
                    defaultClasses.active
                )}
                disabled={status === CONTEST_PUBLICATION_STATUS_FINISHED}
                onClick={() =>
                  handleStatusClick(CONTEST_PUBLICATION_STATUS_FINISHED)
                }
              >
                {translate(
                  `T_publicationStatus_${CONTEST_PUBLICATION_STATUS_FINISHED}`
                )}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disableRipple
                disableFocusRipple
                className={clsx(
                  status === CONTEST_PUBLICATION_STATUS_ANNOUNCED &&
                    defaultClasses.active
                )}
                disabled={[
                  CONTEST_PUBLICATION_STATUS_ANNOUNCED,
                  CONTEST_PUBLICATION_STATUS_FINISHED,
                  CONTEST_PUBLICATION_STATUS_CURRENT,
                ].includes(status)}
                onClick={() =>
                  handleStatusClick(CONTEST_PUBLICATION_STATUS_ANNOUNCED)
                }
              >
                {translate(
                  `T_publicationStatus_${CONTEST_PUBLICATION_STATUS_ANNOUNCED}`
                )}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disableRipple
                disableFocusRipple
                className={clsx(
                  status === CONTEST_PUBLICATION_STATUS_CURRENT &&
                    defaultClasses.active
                )}
                disabled={[
                  CONTEST_PUBLICATION_STATUS_CURRENT,
                  CONTEST_PUBLICATION_STATUS_FINISHED,
                ].includes(status)}
                onClick={() =>
                  handleStatusClick(CONTEST_PUBLICATION_STATUS_CURRENT)
                }
              >
                {translate(
                  `T_publicationStatus_${CONTEST_PUBLICATION_STATUS_CURRENT}`
                )}
              </Button>
            </ListItem>
          </>
        )}
      </List>
      <CustomDialog
        handleToogle={handleToogle}
        open={open}
        title={'Opublikować konkurs?'}
        confirmedButton={true}
        onConfirm={handleConfirm}
        component={() => (
          <Typography variant={'body1'} color={'primary'}>
            {translate('Po opublikowania zmiany nie będą już możliwe')}
          </Typography>
        )}
      />
      <CustomDialog
        handleToogle={handleToogleBack}
        open={openBack}
        title={'Przywrócić do edycji?'}
        confirmedButton={true}
        onConfirm={handleConfirmBack}
        component={() => (
          <Typography variant={'body1'} color={'primary'}>
            {translate(
              'Konkurs zostanie zmieniony na szkic. Pamiętaj aby ponownie opublikować konkurs.'
            )}
          </Typography>
        )}
      />
    </>
  )
}

SidebarContest.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  deletable: PropTypes.bool,
  statable: PropTypes.bool,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  statAccessor: PropTypes.string,
  collectionPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onButtonSaveRef: PropTypes.func,
  sidebarStatusIsSwitch: PropTypes.bool,
  statusTitle: PropTypes.string,
  sidebarUrl: PropTypes.string,
}
