export const CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_SUBSTANTIVE = 'SUBSTANTIVE'
export const CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_FORMAL = 'FORMAL'
export const CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_INTERNAL = 'INTERNAL'

export const RATE_PASSED = 'PASSED' // Pozytywna
export const RATE_FAILED = 'FAILED' // Negatywna

export const INTERNAL_RATE_STATUS_PASSED = 'PASSED'

export const SUBSTANTIVE_RATE_STATUS_PASSED = 'PASSED'

export const FORMAL_RATE_STATUS_PASSED = 'PASSED'

const properties = {
  [CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_SUBSTANTIVE]: {
    names: {
      type: 'group',
      width: '884px',
      properties: {
        title: {
          type: 'string',
          column: 9,
          width: '600px',
          label: '',
        },
        rateVisibleForApplicant: {
          type: 'boolean',
          column: 3,
          label: '',
          labelTooltip: 'czy ocena widoczna dla Wnioskodawcy?',
          top: false,
        },
      },
    },
    ratesColumns: {
      type: 'group',
      width: '100%',
      properties: {
        pointsMin: {
          type: 'number',
          label: 'Punkty: Od',
          width: '129px',
          column: 3,
        },
        pointsMax: {
          type: 'number',
          label: 'Do',
          width: '129px',
          column: 7,
        },
      },
    },
    description: {
      type: 'textarea',
      width: '650px',
      label: '',
    },
  },
  [CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_FORMAL]: {
    names: {
      type: 'group',
      width: '884px',
      properties: {
        title: {
          type: 'string',
          column: 9,
          width: '600px',
          label: '',
        },
        rateVisibleForApplicant: {
          type: 'boolean',
          column: 3,
          label: '',
          labelTooltip: 'czy ocena widoczna dla Wnioskodawcy?',
          top: false,
        },
      },
    },
  },
  [CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_INTERNAL]: {
    ratesColumns: {
      type: 'group',
      width: '100%',
      properties: {
        pointsMin: {
          type: 'number',
          label: 'Punkty: Od',
          width: '129px',
          column: 3,
        },
        pointsMax: {
          type: 'number',
          label: 'Do',
          width: '129px',
          column: 6,
        },
        rateVisibleForApplicant: {
          type: 'boolean',
          column: 2,
          label: '',
          labelTooltip: 'czy ocena widoczna dla Wnioskodawcy?',
          top: true,
        },
      },
    },
    description: {
      type: 'textarea',
      width: '600px',
      label: '',
    },
  },
}

const schema = type => ({
  endpoint: '/contest_proposal_rate_guidelines',
  resource: {
    definition:
      'ContestProposalRateGuideline-contest_proposal_rate_guideline:write',
    properties: properties[type],
  },
})

export default schema
