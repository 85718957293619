export default {
  vertical: {
    background: 'transparent',
    padding: 0,
    marginBottom: 110,
    '& .MuiStepConnector-root': {
      top: '18px',
    },
  },
}
