import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'core/_helpers/prop'
import { translate } from 'core/_helpers/translate'

export const ApplicationStatusCell = ({ resource, accessor = 'stat' }) => {
  return (
    <>
      {prop(resource, accessor) &&
        translate(
          `T_MODULE_PROPOSALS_APPLICATION_STATUS_${prop(resource, accessor)}`
        )}
    </>
  )
}

ApplicationStatusCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.string,
}
