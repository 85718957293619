import React, { useCallback, useEffect, useState } from 'react'
import { Tabs } from './components/Tabs'
import { CollectionHeader } from 'core/components/CollectionHeader'
import schema from '_schema/faq'
import { useCollectionFetch } from 'core/components/table/_helpers'
import { constants, reducer } from 'core/components/table/_state'
import { useBlockCollectionStyles } from 'core/components/block'
import clsx from 'clsx'
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import {
  BodySkeleton,
  ManuallyOrderableTableBody,
  ManuallyOrderableTableRow,
  ManualOrderDragHandle,
  TableLoader,
} from 'core/components/table'
import { AddCategoryFaq } from './components/AddCategoryFaq'
import { translate } from 'core/_helpers/translate'
import { Delete } from 'core/components/resource/Delete'
import { ExpandLessOutlined, ExpandMoreOutlined } from '@material-ui/icons'
import { Form } from 'core/components/form'
import { boolean } from 'core/_helpers/boolean'
import arrayMove from 'array-move'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { QuestionsCollection } from './components/QuestionsCollection'

export const Faq = ({ match }) => {
  const defaultClasses = useBlockCollectionStyles()
  const [state, dispatch] = useCollectionFetch(
    reducer,
    schema.endpoint_category,
    [],
    [],
    null,
    false,
    null,
    schema.endpoint_category
  )

  const handleOrderChange = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex) {
        return
      }

      const items = state.data.items

      const newOrders = arrayMove(items, oldIndex, newIndex)

      const newList = newOrders.map((el, index) => {
        fetchDataHandleAuthError(el['@id'], 'PUT', {
          body: JSON.stringify({
            ord: index + 1,
          }),
        })
        return {
          ...el,
          ord: index + 1,
        }
      })

      dispatch({
        type: constants.MASS_SELECT_ACTION_SUCCESS,
        payload: {
          items: newList,
        },
      })
    },
    [dispatch, state.data.items]
  )

  const [expanded, setExpanded] = useState({
    switch: false,
    items: {},
  })

  const handleSingleExpand = iri => () => {
    setExpanded(state => ({
      ...state,
      items: {
        ...state.items,
        [iri]:
          state.items[iri] !== undefined ? !state.items[iri] : !state.switch,
      },
    }))
  }

  const handleDelete = () => {
    dispatch({
      type: constants.RELOAD,
    })
  }

  const [showSave, setShowSave] = useState({})

  useEffect(() => {
    setShowSave(state.data.items.map(() => false))
  }, [state.data.items])

  const handleChange = i => {
    setShowSave(prevState => prevState.map((el, index) => index === i))
  }

  const beforeSubmit = data => {
    const obj = data
    delete obj.faqItem
    return obj
  }

  return (
    <>
      <CollectionHeader
        buttonTitle={''}
        hideButton={true}
        hideButtonBack={true}
      >
        <Tabs path={match.path} />
      </CollectionHeader>
      <TableContainer className={clsx(defaultClasses.collectionContainer)}>
        <TableLoader show={!state.init && state.isFetching} align="center" />
        <Table size="small">
          <ManuallyOrderableTableBody
            onSortEnd={handleOrderChange}
            helperClass={clsx(defaultClasses.draggedRow)}
            useDragHandle={true}
          >
            {state.init ? (
              <BodySkeleton rows={1} columns={1} />
            ) : state.data.items.length > 0 ? (
              state.data.items.map((item, i) => {
                const properties = schema.resource_category.properties

                const handleSuccess = resource => {
                  dispatch({
                    type: constants.UPDATE_ITEM_FIELD,
                    payload: {
                      '@id': resource['@id'],
                      accessor: 'title',
                      value: resource.title,
                    },
                  })
                }

                return (
                  <ManuallyOrderableTableRow
                    index={i}
                    key={item[process.env.REACT_APP_RESOURCE_ID]}
                  >
                    <TableCell
                      key="element"
                      className={clsx(defaultClasses.cell)}
                      style={{ width: '100%' }}
                    >
                      <ExpansionPanel
                        className={defaultClasses.expansionPanel}
                        square={true}
                        expanded={
                          expanded.items[item['@id']] !== undefined
                            ? expanded.items[item['@id']]
                            : expanded.switch
                        }
                      >
                        <ExpansionPanelSummary
                          classes={{
                            content: clsx(defaultClasses.expansionPanelSummary),
                          }}
                        >
                          <ManualOrderDragHandle />
                          <Typography
                            onClick={handleSingleExpand(item['@id'])}
                            className={clsx(defaultClasses.blockTitle)}
                            color={'primary'}
                            variant={'h2'}
                          >
                            <span>{translate('Kategoria pytań')}:</span>{' '}
                            {item.title}
                          </Typography>
                          <div className={clsx(defaultClasses.blockIcon)}>
                            <Delete
                              resource={item}
                              accessor={'title'}
                              disabled={state.isFetching}
                              isIcon={true}
                              onSuccess={handleDelete}
                            />
                          </div>
                          <div
                            onClick={handleSingleExpand(item['@id'])}
                            className={clsx(defaultClasses.expandIcon)}
                          >
                            {(expanded.items[item['@id']] !== undefined ? (
                              expanded.items[item['@id']]
                            ) : (
                              expanded.switch
                            )) ? (
                              <ExpandLessOutlined />
                            ) : (
                              <ExpandMoreOutlined />
                            )}
                          </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          classes={{
                            root: clsx(defaultClasses.itemContainer),
                          }}
                        >
                          <Form
                            readOnly={false}
                            url={item['@id']}
                            method={'PUT'}
                            properties={properties}
                            resource={item}
                            fetchCompareResource={boolean(
                              process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
                            )}
                            handleSuccess={handleSuccess}
                            showSubmitAndStayButton={false}
                            showSubmitButton={showSave[i] || false}
                            isChange={() => handleChange(i)}
                            showCancelButton={false}
                            fieldsFullWidth={true}
                            width={'100%'}
                            key={`${
                              item[process.env.REACT_APP_RESOURCE_ID]
                            }-${i}`}
                            customBeforeSubmit={beforeSubmit}
                          />
                          <QuestionsCollection
                            dispatch={dispatch}
                            item={item}
                          />
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </TableCell>
                  </ManuallyOrderableTableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={2}
                  className={clsx(defaultClasses.emptyResults)}
                >
                  {translate('T_GENERAL_ITEMS_EMPTY')}
                </TableCell>
              </TableRow>
            )}
          </ManuallyOrderableTableBody>
        </Table>
      </TableContainer>
      <div className={clsx(defaultClasses.addContainer)}>
        <AddCategoryFaq
          endpoint={schema.endpoint_category}
          dispatch={dispatch}
          disabled={state.isFetching}
          ord={state.data.items.length}
        />
      </div>
    </>
  )
}
