import React from 'react'
import { useStyles } from './styles'
import { Grid, Typography } from '@material-ui/core'

export const Footer = () => {
  const classes = useStyles()
  return (
    <footer className={classes.footer}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} className={classes.logos} md={4} lg={3}>
          <a href="https://pafw.pl/" target="_blank" rel="noopener noreferrer">
            <img
              src="/img/logo-wolnosc.svg"
              alt="Polsko-Amerykańska Fundacja Wolności"
            />
          </a>
          <a
            href="http://civispolonus.org.pl/"
            target={'_blank'}
            rel="noopener noreferrer"
          >
            <img
              src="/img/00_logo_Civis_colour.png"
              alt="Fundacja Civis Polonvs"
              style={{ maxWidth: 120 }}
            />
          </a>
        </Grid>
        <Grid item xs={12} md={8} lg={7}>
          <Typography variant="body2" className={classes.footerInfo}>
            Program Równać Szanse jest programem Polsko - Amerykańskiej Fundacji
            Wolności realizowanym przez Fundację Civis Polonus
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.footerSocial} md={12} lg={2}>
          <a
            href="https://www.facebook.com/programrownacszanse"
            target={'_blank'}
            rel="noopener noreferrer"
          >
            <img src="/img/fb.svg" alt="Zobacz nas na facebooku" />
          </a>
          <a
            href="https://twitter.com/rownacszanse"
            target={'_blank'}
            rel="noopener noreferrer"
          >
            <img src="/img/tw.svg" alt="Zobacz nas na twitterze" />
          </a>
          <a
            href="https://www.instagram.com/rownacszanse/"
            target={'_blank'}
            rel="noopener noreferrer"
          >
            <img src="/img/inst.svg" alt="Zobacz nas na instagramie" />
          </a>
        </Grid>
      </Grid>
    </footer>
  )
}
