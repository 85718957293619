const schema = {
  endpoint: '/users/profile',
  changePassword: {
    properties: {
      oldPassword: {
        type: 'password',
        description: 'T_FORM_FIELD_PASSWORD',
        validate: ['required'],
      },
      newPassword: {
        type: 'passwordRepeat',
        description: 'T_FORM_FIELD_NEW_PASSWORD',
        hint: {
          origin: 'T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD',
          repeat: 'T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD',
        },
        isGridable: false,
        validate: ['required', 'password'],
      },
    },
    set: '/users/:id/changePassword',
  },
}

export default schema
