const CONTEST_PAGE_BLOCK_TYPE_BUTTON = 'BUTTON'

export const textWithLink = () => ({
  name: CONTEST_PAGE_BLOCK_TYPE_BUTTON,
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_LINK',
  properties: {
    type: {
      type: 'hidden',
      defaultValue: CONTEST_PAGE_BLOCK_TYPE_BUTTON,
    },
    title: {
      type: 'string',
      label: 'Tytuł bloku',
      width: '650px',
    },
    description: {
      label: 'Treść',
      type: 'tinymce',
      fullWidth: true,
      formWidth: 650,
    },
    buttonInfo: {
      type: 'group',
      width: '650px',
      properties: {
        buttonTitle: {
          type: 'string',
          column: 4,
          label: 'Opis buttona',
          width: '100%',
        },
        buttonUrl: {
          type: 'string',
          column: 8,
          label: 'Link',
          width: '100%',
        },
      },
    },
  },
})
