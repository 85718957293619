export const generateFormTemplateRenderError = (sections, renderError) => {
  if (!sections) {
    return {}
  }

  let currentRenderError = {}

  Object.keys(sections).forEach(sectionUuid => {
    if (!sections[sectionUuid]) {
      return
    }

    if (!currentRenderError[sectionUuid]) {
      currentRenderError[sectionUuid] = []
    }

    sections[sectionUuid].forEach((sectionItem, sectionItemIndex) => {
      if (!currentRenderError[sectionUuid][sectionItemIndex]) {
        currentRenderError[sectionUuid][sectionItemIndex] = {}
      }

      Object.keys(sectionItem).forEach(blockUuid => {
        if (!currentRenderError[sectionUuid][sectionItemIndex][blockUuid]) {
          currentRenderError[sectionUuid][sectionItemIndex][blockUuid] = {}
        }

        Object.keys(sectionItem[blockUuid]).forEach(fieldName => {
          currentRenderError[sectionUuid][sectionItemIndex][blockUuid][fieldName] = renderError
        })
      })
    })
  })

  return currentRenderError
}
