import React, { useEffect, useMemo, useState } from 'react'
import { standard } from 'core/components/table/_columns/standard'
import schema, {
  FORM_TYPE_APPOINTMENT,
  FORM_TYPE_AGREEMENT_TEMPLATE,
  FORM_TYPE_APPLICATION_APPLICANT,
  FORM_TYPE_APPLICATION_PROJECT,
  FORM_TYPE_FINAL_REPORT,
  FORM_TYPE_PARTIAL_REPORT,
  // FORM_TYPE_RELATION,
  FORM_TYPE_TRAINING_INQUIRY,
} from '_schema/form'
import { Paper } from 'core/components/Paper'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { StyledTab, StyledTabs } from 'components/StyledTab'
import { translate } from 'core/_helpers/translate'
import { makeStyles } from '@material-ui/styles'
import { Form } from 'core/components/form'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { CustomDialog } from 'core/components/Dialog'
import { useSelector } from 'react-redux'
import { operations } from 'core/components/table/_columns/operations'
import { InfoCell } from './table/InfoCell'

const useStyles = makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

export const Collection = () => {
  const columns = useMemo(
    () => [
      {
        ...standard('title'),
        sortable: false,
        width: '30%',
      },
      {
        accessor: 'createdAt',
        Cell: InfoCell,
        sortable: false,
        width: '25%',
      },
      {
        accessor: 'updatedAt',
        Cell: InfoCell,
        sortable: false,
        width: '25%',
      },
      {
        ...standard('usageCount'),
        sortable: false,
        width: '10%',
      },
      {
        ...operations('title', true, true, true, () => false),
        width: '10%',
        editable: true,
        deletable: true,
        cloneable: true,
      },
    ],
    []
  )

  const classess = useStyles()

  const [reload, setReload] = useState(false)

  const handleSetReload = () => {
    setReload(state => !state)
  }

  const collection = useSelector(state => state.collection[schema.endpoint])

  const [tab, setTab] = useState(
    collection !== undefined &&
      collection.filters !== undefined &&
      collection.filters['type'] !== undefined
      ? collection.filters['type'].value
      : FORM_TYPE_APPLICATION_APPLICANT
  )

  const handleChangeTab = (event, newValue) => {
    setTab(newValue)
    setReload(true)
  }

  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const handleConfirmToggle = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
  }

  const handleConfirmSuccess = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
    setReload(true)
  }

  const [buttonRef, setButtonRef] = useState(null)

  const onButtonRef = element => {
    setButtonRef(element)
  }

  const [properties, setProperties] = useState(schema.resource_new.properties)

  useEffect(() => {
    setProperties(prevState => ({
      ...prevState,
      type: {
        type: 'hidden',
        defaultValue: tab,
      },
    }))
  }, [tab])

  const Dialog = () => (
    <>
      <Form
        method={'POST'}
        url={schema.endpoint}
        properties={properties}
        showSubmitAndStayButton={false}
        showCancelButton={false}
        fieldsFullWidth={true}
        width="100%"
        buttonContainerRef={buttonRef}
        handleCancel={handleConfirmToggle}
        handleSuccess={handleConfirmSuccess}
      />
    </>
  )

  return (
    <>
      <StyledTabs
        value={tab}
        onChange={handleChangeTab}
        variant="scrollable"
        scrollButtons="auto"
        className={classess.tabs}
      >
        <StyledTab
          label={translate(FORM_TYPE_APPLICATION_APPLICANT)}
          value={FORM_TYPE_APPLICATION_APPLICANT}
        />
        <StyledTab
          label={translate(FORM_TYPE_APPLICATION_PROJECT)}
          value={FORM_TYPE_APPLICATION_PROJECT}
        />
        <StyledTab
          label={translate(FORM_TYPE_TRAINING_INQUIRY)}
          value={FORM_TYPE_TRAINING_INQUIRY}
        />
        <StyledTab
          label={translate(FORM_TYPE_AGREEMENT_TEMPLATE)}
          value={FORM_TYPE_AGREEMENT_TEMPLATE}
        />
        {/*<StyledTab*/}
        {/*  label={translate(FORM_TYPE_APPOINTMENT)}*/}
        {/*  value={FORM_TYPE_APPOINTMENT}*/}
        {/*/>*/}
        <StyledTab
          label={translate(FORM_TYPE_PARTIAL_REPORT)}
          value={FORM_TYPE_PARTIAL_REPORT}
        />
        {/*<StyledTab*/}
        {/*  label={translate(FORM_TYPE_RELATION)}*/}
        {/*  value={FORM_TYPE_RELATION}*/}
        {/*/>*/}
        <StyledTab
          label={translate(FORM_TYPE_FINAL_REPORT)}
          value={FORM_TYPE_FINAL_REPORT}
        />
      </StyledTabs>
      <Paper withPadding={false}>
        <ButtonPlus text={'Dodaj szablon'} onClick={handleConfirmToggle} />
        <SchemableCollection
          component={CollectionTable}
          path={`paths.${schema.endpoint}.get`}
          endpoint={`${schema.endpoint}`}
          customResourceSchema={schema.resource}
          columns={columns}
          customFilters={[
            {
              name: 'type',
              value: tab,
              title: 'type',
            },
          ]}
          autoWidth={false}
          filterable={false}
          sortable={false}
          numerable={false}
          resetable={false}
          paginable={true}
          definitionSchema={schema.resource}
          reload={reload}
          setReload={handleSetReload}
        />
        <CustomDialog
          title={'Dodaj szablon'}
          component={Dialog}
          confirmedButton={false}
          onConfirm={() => false}
          open={state.isConfirmOpen}
          handleToogle={handleConfirmToggle}
          isFetching={state.isFetching}
          showActions={true}
          onButtonRef={onButtonRef}
        />
      </Paper>
    </>
  )
}
