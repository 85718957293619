import React from 'react'
import { Text, TextWithButton, TextWithFiles, TextWithPhoto } from './blocks'
import { Typography } from '@material-ui/core'
import { useStyles } from './style'

export const ShowDetailContest = ({ resource }) => {
  const classes = useStyles()
  return (
    <div className={classes.textwraper}>
      <Typography variant={'h1'} color={'primary'} className={classes.title}>
        {resource.pageTitle}
      </Typography>
      <Typography variant={'h2'} color={'primary'} className={classes.subtitle}>
        {resource.pageHeader}
      </Typography>
      <Typography
        variant={'subtitle1'}
        color={'primary'}
        component={'p'}
        className={classes.text}
      >
        {resource.pageLead}
      </Typography>
      {resource.contestPageBlocks
        .filter(block => block.stat)
        .map(block => (
          <div key={block['@id']}>
            {block.type === 'TEXT' ? (
              <Text title={block.title} description={block.description} />
            ) : block.type === 'PHOTO' ? (
              <TextWithPhoto
                title={block.title}
                description={block.description}
                photoTitle={block.photoTitle}
                photoUrl={block.photo?.url}
              />
            ) : block.type === 'BUTTON' ? (
              <TextWithButton
                title={block.title}
                description={block.description}
                buttonTitle={block.buttonTitle}
                buttonUrl={block.buttonUrl}
              />
            ) : block.type === 'FILES' ? (
              <TextWithFiles
                title={block.title}
                description={block.description}
                files={block.files}
              />
            ) : null}
          </div>
        ))}
    </div>
  )
}
