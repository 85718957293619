import moment from 'moment'
import { translate } from 'core/_helpers/translate'

const DATE_FORMAT = 'YYYY-MM-DD'

const required = value => {
  const result = !!value
  return { result, message: !result && 'T_VALIDATION_REQUIRED' }
}

const email = value => {
  const result = value
    ? /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        value
      )
    : true

  return { result, message: !result && 'T_VALIDATION_EMAIL' }
}

const phone = value => {
  const result = value ? /^[0-9\-+\s]{9,}$/.test(value) : true

  return { result, message: !result && 'T_VALIDATION_PHONE' }
}

const postCode = value => {
  const result = value ? /^[0-9]{2}-[0-9]{3}$/.test(value) : true

  return { result, message: !result && 'T_VALIDATION_POSTCODE' }
}

const password = value => {
  const result = value
    ? /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
        value
      )
    : true

  return { result, message: !result && 'T_VALIDATION_PASSWORD' }
}

const length = (value, length) => {
  const result = value ? value.toString().length >= length : true

  return {
    result,
    message: !result && translate('T_VALIDATION_LENGTH', [length]),
  }
}

const maxLength = (value, length) => {
  const result = value ? value.toString().length <= length : false

  return {
    result,
    message: !result && translate('T_VALIDATION_MAX_LENGTH', [length]),
  }
}

const minValue = (value, min) => {
  const result = value ? value >= parseInt(min) : true

  return {
    result,
    message: !result && translate('T_VALIDATION_MIN_VALUE', [min]),
  }
}

const maxValue = (value, max) => {
  const result = value ? value <= parseFloat(max) : true

  return {
    result,
    message: !result && translate('T_VALIDATION_MAX_VALUE', [max]),
  }
}

const minDate = (value, min) => {
  const result = value
    ? moment(value).format(DATE_FORMAT) >= moment(min).format(DATE_FORMAT)
    : true

  return {
    result,
    message: !result && translate('T_VALIDATION_MIN_VALUE', [min]),
  }
}

const maxDate = (value, max) => {
  const result = value
    ? moment(value).format(DATE_FORMAT) <= moment(max).format(DATE_FORMAT)
    : true

  return {
    result,
    message: !result && translate('T_VALIDATION_MAX_VALUE', [max]),
  }
}

const maxSize = (value, max = process.env.REACT_APP_MAX_UPLOAD_SIZE) => {
  if (Array.isArray(value)) {
    return maxSizeMultiple(value, max)
  }

  const result = value ? (value.size || value) <= parseInt(max) : true

  return {
    result,
    message:
      !result &&
      translate('T_VALIDATION_MAX_UPLOAD_SIZE', [`${max / (1024 * 1024)} MB`]),
  }
}

const maxSizeMultiple = (
  values,
  max = process.env.REACT_APP_MAX_UPLOAD_SIZE
) => {
  let completeSize = 0

  for (let i = 0; i < values.length; i++) {
    completeSize += values[i].size
  }

  const result = values.length ? completeSize <= parseInt(max) : true

  return {
    result,
    message:
      !result &&
      translate('T_VALIDATION_MAX_UPLOAD_MULTIPLE_SIZE', [
        `${max / (1024 * 1024)} MB`,
      ]),
  }
}

const bankaccount = value => {
  const result = value ? /^[0-9]{26}$/.test(value) : true
  return { result, message: !result && 'T_VALIDATION_BANCACCOUNT' }
}

export const validators = {
  required,
  email,
  phone,
  password,
  length,
  maxLength,
  maxSize,
  maxSizeMultiple,
  minValue,
  maxValue,
  postCode,
  minDate,
  maxDate,
  bankaccount,
}
