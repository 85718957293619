import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { StringType } from 'components/applicant/form/fields/StringType'
import { getBlockFieldsMapping } from 'pages/applicant/Proposals/_helpers/getBlockFieldsMapping'
import { blockTypes } from 'pages/applicant/Proposals/_helpers/blockTypes'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 8
  },
  label: {
    marginBottom: 5
  },
  input: {
    width: 350,
  },
  input_wrapper: {
    display: 'flex',
  },
  help: {
    marginLeft: 25,
    color: theme.palette.disabled,
  },
  unit_label: {
    marginTop: 6,
    marginLeft: 10
  }
}))

export const InputBlock = ({
  data,
  values,
  errors,
  renderError,
  globalRenderError,
  setValue,
  setError,
  disabled
}) => {
  const mapping = useMemo(
    () => getBlockFieldsMapping(blockTypes.INPUT, data),
    [data]
  )

  const validators = useMemo(() => ({
      input: [
        ...(mapping.input.required
          ? [ 'required' ]
          : []),
        ...(mapping.input.validate && mapping.input.validate !== 'number'
          ? [ mapping.input.validate ]
          : []),
        ...(mapping.input.maxLength
          ? [ `maxLength(${mapping.input.maxLength})`]
          : [])
      ]
    }),
    [mapping]
  )

  const classes = useStyles()

  return (
    <div className={classes.row}>
      <div>
        <div className={classes.label}>
          {mapping.input.label}
        </div>
        <div className={classes.input_wrapper}>
          <StringType
            type={mapping.input.validate === 'number' ? 'number' : 'string'}
            name="input"
            value={values?.input}
            error={errors?.input}
            renderError={globalRenderError && renderError?.input}
            placeholder={mapping.input.placeholder}
            disabled={disabled}
            validators={validators.input}
            setValue={setValue}
            setError={setError}
            maxLength={mapping.input.maxLength || null}
            classes={{
              root: classes.input
            }}
          />
          {mapping.input.unitLabel && (
            <div className={classes.unit_label}>
              {mapping.input.unitLabel}
            </div>
          )}
        </div>
      </div>
      <div className={classes.help}>
        {mapping.input.maxLength && (
          <div>
            {translate('T_MODULE_PROPOSALS_CHARS_LEFT')}: {mapping.input.maxLength - (values?.input?.length || 0)}
          </div>
        )}
        {mapping.input.help}
      </div>
    </div>
  )
}

InputBlock.propTypes = {
  data: PropTypes.string,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}
