import React, { useState } from 'react'
import moment from 'moment'
import { Button, Grid, Typography } from '@material-ui/core'
import { StringType } from 'core/components/form/fields/StringType'
import { ButtonDownload } from 'core/components/buttons/button-download'
import { CustomDialog } from 'core/components/Dialog'
import { BlankForm } from 'pages/applicant/BlankForm'
import { FieldWrapper } from 'components/applicant/form'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'
import { downloadFile } from '_helpers/downloadFile'
import { downloadFileFromFile } from '_helpers/downloadFileFromFile'
import schema, {
  CONTRACT_STATUS_TO_CORRECT,
  CONTRACT_STATUS_CONFIRMED,
  CONTRACT_STATUS_WAITING,
} from '_schema/proposal'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyle = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
  root: {
    marginBottom: theme.spacing(10),
  },
  attachment: {
    display: 'flex',
    alignItems: 'center',
    '&> *:first-child': {
      marginRight: 10,
    },
  },
}))

export const Contract = ({
  resource,
  eventObject,
  definitions,
  setResource,
  setEvent,
}) => {
  const classes = useStyle()

  const handleDownload = () => {
    console.log(resource)
    downloadFile(
      schema.endpoint_download_contract_pdf.replace(':id', resource.uuid),
      `umowa-${resource.contractNumber || resource.uuid}.pdf`
    )
  }

  const handleDownload2 = id => {
    downloadFileFromFile(id)
  }

  const [returnMessage, setReturnMessage] = useState(
    resource.contractReturnMessage || ''
  )

  const handleReturnMessage = (name, value) => {
    setReturnMessage(value)
  }

  const [isFetching, setIsFetching] = useState(false)

  const handleStatusChange = status => () => {
    setIsFetching(true)

    fetchDataHandleAuthError(
      schema.endpoint_contract_status_edit.replace(':id', resource.uuid),
      'PUT',
      {
        body: JSON.stringify({
          contractStatus: status,
        }),
      },
      response => {
        setIsFetching(false)
        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')

        setResource({ ...resource, ...response })
        setEvent({ proposalContractStatus: response.contractStatus })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        setIsFetching(false)
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const handleReturnMessageSend = () => {
    setIsFetching(true)

    fetchDataHandleAuthError(
      schema.endpoint_contract_status_edit.replace(':id', resource.uuid),
      'PUT',
      {
        body: JSON.stringify({
          contractReturnMessage: returnMessage,
          contractStatus: CONTRACT_STATUS_TO_CORRECT,
        }),
      },
      response => {
        setIsFetching(false)
        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')

        setResource({ ...resource, ...response })
        setEvent({ proposalContractStatus: response.contractStatus })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        setIsFetching(false)
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState(false)

  const handleUploadDialogToogle = () => {
    setIsUploadDialogOpen(state => !state)
  }

  const handleUploadSuccess = response => {
    setIsUploadDialogOpen(false)
    setResource({ ...resource, ...response })
  }

  return (
    <>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={4} md={2}>
          <Typography variant={'subtitle1'} color={'primary'}>
            {eventObject.proposalContractDataAcceptedDate &&
              moment(eventObject.proposalContractDataAcceptedDate).format(
                DATE_FORMAT
              )}
          </Typography>
        </Grid>
        <Grid item xs={6} md={5}>
          <Typography variant={'subtitle1'} color={'primary'}>
            {translate('PDF umowy po wypełnionej ankiecie')}
          </Typography>
        </Grid>
        <Grid item xs={2} md={5}>
          <ButtonDownload text="Zobacz" onClick={handleDownload} />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={4} md={2}>
          <Typography variant={'subtitle1'} color={'primary'}>
            {eventObject.proposalContractUploadDate &&
              moment(eventObject.proposalContractUploadDate).format(
                DATE_FORMAT
              )}
          </Typography>
        </Grid>
        <Grid item xs={6} md={5}>
          <Typography variant={'subtitle1'} color={'primary'}>
            {translate('Pliki wgrane przez Wnioskodawcę')}
          </Typography>
        </Grid>
        <Grid item xs={2} md={5}>
          {resource.contractStatus === CONTRACT_STATUS_WAITING &&
            (!resource.contractAttachments ||
              resource.contractAttachments.length === 0) && (
              <span>Oczekiwanie na wgranie umowy przez wnioskodawcę</span>
            )}

          {resource.contractAttachments.map(attachment => (
            <div className={classes.attachment} key={attachment['@id']}>
              {attachment.file && (
                <>
                  <div>{attachment?.file?.originalName}</div>
                  <div>
                    <ButtonDownload
                      text={'Zobacz'}
                      // path={`${process.env.REACT_APP_API_ENTRYPOINT}/${attachment.file.url}`}
                      name={attachment?.file?.originalName}
                      onClick={() => handleDownload2(attachment.file.uuid)}
                    />
                  </div>
                </>
              )}
            </div>
          ))}
        </Grid>

        {resource.contractStatus !== CONTRACT_STATUS_CONFIRMED && (
          <>
            <Grid item xs={12}>
              <StringType
                name="returnMessage"
                value={returnMessage}
                setValue={handleReturnMessage}
                disabled={isFetching}
                renderError={false}
                type="textarea"
                setError={() => null}
                error={false}
                label="Komunikat do poprawy"
                width="100%"
              />
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  disabled={isFetching}
                  onClick={handleReturnMessageSend}
                >
                  {translate('Wyślij komunikat')}
                </Button>
              </div>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <div className={classes.buttons}>
            {resource.contractStatus !== CONTRACT_STATUS_TO_CORRECT &&
              resource.contractStatus !== CONTRACT_STATUS_CONFIRMED && (
                <Button
                  variant="outlined"
                  size="large"
                  color="secondary"
                  disabled={isFetching}
                  onClick={handleStatusChange(CONTRACT_STATUS_TO_CORRECT)}
                >
                  {translate('Do poprawy')}
                </Button>
              )}
            {resource.contractStatus !== CONTRACT_STATUS_CONFIRMED && (
              <Button
                variant="contained"
                color="secondary"
                size="large"
                disabled={isFetching}
                onClick={handleStatusChange(CONTRACT_STATUS_CONFIRMED)}
              >
                {translate('Akceptuj')}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.root}>
        <Grid item xs={4} md={2}>
          <Typography variant={'subtitle1'} color={'primary'}>
            {resource.contractSigningDate &&
              moment(resource.contractSigningDate).format(DATE_FORMAT)}
          </Typography>
        </Grid>
        <Grid item xs={6} md={5}>
          <Typography variant={'subtitle1'} color={'primary'}>
            {translate('PDF skanu umowy wgrany przez Fundację')}
          </Typography>
        </Grid>
        <Grid item xs={2} md={5}>
          {resource.contractFile && (
            <ButtonDownload
              text={'Zobacz'}
              // path={`${process.env.REACT_APP_API_ENTRYPOINT}/${resource.contractFile.url}`}
              name={resource.contractFile.originalName}
              onClick={() => handleDownload2(resource.contractFile.uuid)}
            />
          )}
        </Grid>
        <Grid item xs={4} md={2}></Grid>
        {!resource.contractFile && (
          <Grid item xs={8} md={10}>
            <Button
              variant="outlined"
              size="large"
              color="secondary"
              onClick={handleUploadDialogToogle}
            >
              {translate('Wczytaj podpisany skan')}
            </Button>
            <CustomDialog
              handleToogle={handleUploadDialogToogle}
              open={isUploadDialogOpen}
              title={translate('Wczytaj podpisany skan')}
              component={() => (
                <BlankForm
                  url={schema.endpoint_contract_foundation_upload.replace(
                    ':id',
                    resource.uuid
                  )}
                  method="PUT"
                  resource={resource}
                  definitionSchema={
                    definitions[
                      schema.resource_contract_foundation_upload.definition
                    ]
                  }
                  customResourceSchema={
                    schema.resource_contract_foundation_upload
                  }
                  handleSuccess={handleUploadSuccess}
                  onlyPassedProperties={true}
                  submitButtonTitle={translate('Wyślij podpisany skan')}
                  submitButtonVariant="contained"
                >
                  {({ state, properties, resource, setValue, setError }) => (
                    <div>
                      <FieldWrapper
                        name="contractFile"
                        label={translate('Podpisany skan')}
                        property={properties.contractFile}
                        resource={resource}
                        state={state}
                        setValue={setValue}
                        setError={setError}
                      />
                    </div>
                  )}
                </BlankForm>
              )}
              showActions={false}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}
