import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { StringType } from 'components/applicant/form/fields/StringType'
import { FileType } from 'core/components/form/fields/file/FileType'
import { isAttachmentCollectionInvalid } from 'pages/applicant/Proposals/_helpers/isAttachmentCollectionInvalid'
import { useAttachmentItemTypeStyles } from './AttachmentItemType.styles'
import { translate } from 'core/_helpers/translate'

export const AttachmentItemType = ({
  index,
  values,
  errors,
  renderError,
  globalRenderError,
  setState,
  contest,
  disabled,
  disabledTitle = false,
}) => {
  const setValue = useCallback(
    (name, value) => {
      setState(state => ({
        ...state,
        values: state.values.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: value,
              }
            : item
        ),
        renderError: state.renderError.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: true,
              }
            : item
        ),
        init: false,
      }))
    },
    [index, setState]
  )

  const setError = useCallback(
    (name, error) => {
      setState(state => ({
        ...state,
        errors: state.errors.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: error,
              }
            : item
        ),
        renderError: state.isFormSubmitted
          ? state.renderError.map((item, i) =>
              i === index
                ? {
                    ...item,
                    [name]: true,
                  }
                : item
            )
          : state.renderError,
        isInvalid: isAttachmentCollectionInvalid(
          state.errors.map((item, i) =>
            i === index
              ? {
                  ...item,
                  [name]: !!error,
                }
              : item
          )
        ),
      }))
    },
    [index, setState]
  )

  const validators = useMemo(
    () => ({
      title: ['required'],
      file: ['required'],
    }),
    []
  )

  const classes = useAttachmentItemTypeStyles()

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.label}>
          {translate('T_MODULE_PROPOSALS_ATTACHMENT_TITLE')}
        </div>
        <StringType
          type="string"
          name="title"
          value={values?.title}
          error={errors?.title}
          renderError={globalRenderError && renderError?.title}
          validators={validators.title}
          disabled={disabled || disabledTitle}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input,
          }}
        />
      </div>
      <div className={classes.file_wrapper}>
        <FileType
          type="file"
          name="file"
          value={values?.file}
          initialValue={values?.file}
          error={errors?.file}
          renderError={globalRenderError && renderError?.file}
          validators={validators.file}
          setValue={setValue}
          setError={setError}
          disabled={disabled}
          endpoint="/files"
          formMethod="POST"
          label={translate('T_GENERAL_FILE_ADD')}
          formUrl={contest['@id']}
          hideHeader={true}
          updateButton={true}
          classes={classes}
        />
      </div>
    </div>
  )
}

AttachmentItemType.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
  contest: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  disabledTitle: PropTypes.bool,
}
