import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { PeopleOutlined, PersonOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
  },
  edition: {
    fontSize: 14,
    paddingBottom: 8,
  },
  scope: {
    fontSize: 18,
    fontWeight: 'bold',
    paddingBottom: 8,
  },
  dates: {
    fontSize: 14,
    paddingBottom: 8,
    '&> div': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 8,
    },
  },
  type: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 14,
  },
  type_icon: {
    marginRight: 8,
  },
}))

export const ContestDetails = ({ resource }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.edition}>{resource.contestEdition?.title}</div>
      <div className={classes.scope}>{resource.title}</div>
      <div className={classes.type}>
        {resource.type === 'OPEN' ? (
          <PeopleOutlined className={classes.type_icon} />
        ) : (
          <PersonOutlined className={classes.type_icon} />
        )}
        {translate(`T_MODULE_CONTESTS_TYPE_${resource.type}`)}
      </div>
      <div className={classes.dates}>
        <div>
          {translate('T_MODULE_CONTESTS_PUBLICATION_DATE')}:
          <span>
            {resource.publicationDate &&
              moment(resource.publicationDate).format(DATE_FORMAT)}
          </span>
        </div>
        <div>
          {translate('T_MODULE_CONTESTS_PROJECT_REALIZATION_START_DATE')}:
          <span>
            {resource.projectRealizationDateFrom &&
              moment(resource.projectRealizationDateFrom).format(DATE_FORMAT)}
          </span>
        </div>
        <div>
          {translate('T_MODULE_CONTESTS_PROJECT_REALIZATION_END_DATE')}:
          <span>
            {resource.projectRealizationDateTo &&
              moment(resource.projectRealizationDateTo).format(DATE_FORMAT)}
          </span>
        </div>
        <div>
          {translate('T_MODULE_CONTESTS_PROPOSAL_COLLECTING_END_DATE')}:
          <span>
            {resource.proposalCollectingDateTo &&
              moment(resource.proposalCollectingDateTo).format(DATE_FORMAT)}
          </span>
        </div>
      </div>
    </div>
  )
}

ContestDetails.propTypes = {
  resource: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    publicationDate: PropTypes.string,
    projectRealizationDateFrom: PropTypes.string,
    projectRealizationDateTo: PropTypes.string,
    proposalCollectingDateTo: PropTypes.string,
    contestEdition: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
