import React from 'react'
import PropTypes from 'prop-types'
import { useFormStyles } from '.'
import { BlankForm } from 'pages/applicant/BlankForm'
import { FieldWrapper } from 'components/applicant/form'

export const Form = ({
  url,
  method,
  iri = null,
  resource = null,
  definitionSchema,
  customResourceSchema,
  submitButtonDisabled,
  ...rest
}) => {
  const classes = useFormStyles()

  return (
    <BlankForm
      url={url}
      method={method}
      iri={iri}
      resource={resource}
      definitionSchema={definitionSchema}
      customResourceSchema={customResourceSchema}
      submitButtonDisabled={submitButtonDisabled}
      classes={{
        root: classes.root,
        buttons: classes.buttons,
        submit: classes.submit,
        loader: classes.loader,
      }}
      {...rest}
    >
      {({ state, properties, resource, setValue, setError }) => (
        <>
          <div className={classes.dates}>
            <FieldWrapper
              label="Wybierz termin"
              name="dateEnd"
              property={properties.dateEnd}
              resource={resource}
              state={state}
              setValue={setValue}
              setError={setError}
              disabled={submitButtonDisabled}
              classes={{
                root: classes.input_date,
              }}
            />
          </div>
          <FieldWrapper
            label="Uzasadnij zmianę"
            name="description"
            property={properties.description}
            resource={resource}
            state={state}
            setValue={setValue}
            setError={setError}
            disabled={submitButtonDisabled}
            classes={{
              root: classes.textarea,
            }}
          />
        </>
      )}
    </BlankForm>
  )
}

Form.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  resource: PropTypes.object.isRequired,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  submitButtonDisabled: PropTypes.bool
}
