import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '20px auto',
    width: '100%',
    gridGap: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    borderBottom: '1px solid rgba(11, 43, 124, 0.2)',
  },
  rootWithoutBorder: {
    borderBottom: 0,
  },
  item: {
    paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
  },
}))

const PersonItem = ({ item, classess }) => (
  <div className={classess.item}>
    <Typography variant={'subtitle1'} color={'primary'}>
      {item.fullName}
    </Typography>
    <Typography variant={'subtitle1'} color={'primary'}>
      {item.role}
    </Typography>
    <Typography variant={'subtitle1'} color={'primary'}>
      {item.email}
    </Typography>
    <Typography variant={'subtitle1'} color={'primary'}>
      {item.phone}
    </Typography>
  </div>
)

export const ContactPerson = ({ item, index, count }) => {
  const classes = useStyles()
  return (
    <div
      className={clsx(
        classes.root,
        count === index + 1 && classes.rootWithoutBorder
      )}
    >
      <Typography variant={'h5'} color={'primary'}>
        {String(count - index)}
      </Typography>
      <div>
        <Typography variant={'subtitle1'} color={'primary'}>
          {item?.contest.contestEdition.title}
        </Typography>
        <Typography variant={'h2'} color={'primary'}>
          {item?.title}
        </Typography>
        {item.coordinators.map(el => (
          <PersonItem item={el} classess={classes} key={el['@id']} />
        ))}
      </div>
    </div>
  )
}

PersonItem.propTypes = {
  classess: PropTypes.object,
  item: PropTypes.object.isRequired,
}

ContactPerson.propTypes = {
  count: PropTypes.number,
  index: PropTypes.number,
  item: PropTypes.object,
}
