import React from 'react'
import { useCollectionContainerStyles } from 'components/applicant/collection_styles/CollectionContainer.styles'
import { Paper } from 'core/components/Paper'
import { ProjectScheduleTabs } from 'pages/applicant/InProgressProposals/ProjectSchedule/ProjectScheduleTabs'
import routes from '../routes'
import { useProposalFetch } from './_helpers/useProposalFetch'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import { useResourceState } from 'core/_helpers/useResourceState'
import proposalSchema from '_schema/proposal'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'
import { Form } from './DateOfSubmission/components'
import moment from 'moment'

const DATE_FORMAT = 'DD.MM.YYYY'

const useStyles = makeStyles(theme => ({
  table_header_line: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '&::after': {
      content: '""',
      height: 1,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      marginLeft: 10,
    },
  },
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  flex: {
    display: 'flex',
    columnGap: 20,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '25% 25% 50%',
    columnGap: 20,
  },
  flex_item: {
    flexGrow: 1,
    fontSize: 16,
    '& > div': {
      padding: 10,
    },
  },
  bold: {
    backgroundColor: theme.elements.background,
    fontWeight: 'bold',
    fontSize: 18,
  },
  status: {
    fontSize: 18,
    flexGrow: 1,
    padding: 10,
    fontWeight: 'bold',
  },
  message: {
    fontSize: 14,
    fontWeight: 'normal',
  },
}))

export const DateOfSubmission = ({ schema: definitions, match }) => {
  const classes = useCollectionContainerStyles()
  const localClasses = useStyles()

  const proposalUuid = match.params.id

  const [proposalState, setProposalState] = useResourceState()

  const {
    resource: proposalResource,
    isFetching: proposalResourceIsFetching,
    fetchError: proposalResourceFetchError,
  } = proposalState

  const iri = `${proposalSchema.endpoint}/${proposalUuid}`

  useProposalFetch(
    proposalSchema.endpoint,
    proposalUuid,
    setProposalState.resource,
    setProposalState.fetchError,
    false
  )

  const [proposalStateEndDate, setProposalStateEndDate] = useResourceState()

  const {
    resource: proposalResourceEndDate,
    isFetching: proposalResourceEndDateIsFetching,
    fetchError: proposalResourceEndDateFetchError,
  } = proposalStateEndDate

  useProposalFetch(
    proposalSchema.endpoint_end_date_requests,
    proposalUuid,
    setProposalStateEndDate.resource,
    setProposalStateEndDate.fetchError
  )

  return proposalResourceEndDateIsFetching || proposalResourceIsFetching ? (
    <Loader align="center" marginTop={15} />
  ) : proposalResourceEndDateFetchError || proposalResourceFetchError ? (
    <NotFound />
  ) : (
    <>
      <ProjectScheduleTabs
        value={routes().dateofsubmission.path.replace(':id', match.params.id)}
        classes={{ root: classes.tabs }}
        id={match.params.id}
      />
      <Paper classes={{ root: classes.paper_wrapper }}>
        <h3 className={localClasses.table_header_line}>
          {translate(routes().dateofsubmission.title)}
        </h3>

        <div className={localClasses.flex}>
          <div className={localClasses.flex_item}>
            <div className={localClasses.bold}>Obecnie</div>
            {proposalResource && proposalResource.currentEndingReportDateTo ? (
              <div>
                {moment(proposalResource.currentEndingReportDateTo).format(
                  DATE_FORMAT
                )}
              </div>
            ) : (
              <div>
                Data składania raportów końcowych nie jest jeszcze ustalona
              </div>
            )}
          </div>
          {proposalResource && proposalResource.currentEndingReportDateTo && (
            <div className={localClasses.flex_item}>
              <div className={localClasses.bold}>Zmiana</div>
              <Form
                url={proposalSchema.endpoint_end_date_requests}
                method="POST"
                iri={iri}
                resource={{
                  proposal: iri,
                  dateEnd: proposalResourceEndDate?.[0]?.dateEnd,
                  previousDateEnd: proposalResource?.currentEndingReportDateTo,
                  description: proposalResourceEndDate?.[0]?.description,
                }}
                definitionSchema={
                  definitions[
                    proposalSchema.resource_end_date_requests.definition
                  ]
                }
                customResourceSchema={proposalSchema.resource_end_date_requests}
                submitButtonTitle={'Zgłoś zmianę'}
                submitButtonDisabled={
                  proposalResourceEndDate?.[0]?.status === 'PENDING'
                }
              />
            </div>
          )}
        </div>

        {proposalResourceEndDate.length > 0 && (
          <>
            <h3 className={localClasses.header}>Historia zgłoszeń:</h3>
            <div>
              {proposalResourceEndDate.map(el => (
                <div className={localClasses.grid} key={el.uuid}>
                  <div className={localClasses.flex_item}>
                    <div className={localClasses.bold}>Było</div>
                    <div>{moment(el?.previousDateEnd).format(DATE_FORMAT)}</div>
                  </div>
                  <div className={localClasses.flex_item}>
                    <div className={localClasses.bold}>Zgłoszenie</div>
                    <div>{moment(el?.dateEnd).format(DATE_FORMAT)}</div>
                  </div>
                  <div className={localClasses.flex_item}>
                    <div className={localClasses.status}>
                      {translate(`T_REPORT_END_CHANGE_STATUS_${el?.status}`)}
                    </div>
                    <div className={localClasses.message}>{el?.message}</div>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </Paper>
    </>
  )
}
