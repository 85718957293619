import { useState, useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import buildUrl from 'build-url'

const initialState = {
  items: [],
  totalItems: null,
  init: true,
  refresh: false,
}

export const useNotificationsFetch = (endpoint, proposalUuid) => {
  const [state, setState] = useState(initialState)

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    const url = buildUrl(endpoint, {
      queryParams: {
        'user.uuid': proposalUuid,
        pagination: false,
      },
    })

    proposalUuid &&
      fetchDataHandleAuthError(
        url,
        'GET',
        { signal },
        response => {
          setState({
            items: response['hydra:member'],
            totalItems: response['hydra:totalItems'],
            init: false,
            refresh: false,
          })
        },
        error => {
          if (error.response.title === 'AbortError') {
            return
          }

          notification('error', error.response.detail, error.response.title)
        }
      )

    return () => controller.abort()
  }, [endpoint, proposalUuid, state.refresh])

  return [state, setState]
}
