import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import clsx from 'clsx'
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'
import { CustomInput } from 'core/components/form/fields/fields.style'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: '125px',
    marginBottom: theme.spacing(3),
    '&> div': {
      borderRadius: 0,
      '&> fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
  select: {
    backgroundColor: 'white',
    paddingTop: 9,
    paddingBottom: 9,
  },
  error: {
    marginLeft: 0,
  },
}))

export const TaxIdType = ({
  name,
  value,
  choices,
  error = false,
  renderError = false,
  setValue,
  additionalValues = {},
  disabled = false,
  classes = {},
}) => {
  const [id] = useState(randomHash())

  const handleChange = e => {
    const value = e.target.value

    setValue(name, value)

    switch (value) {
      case 'nip':
        setValue('regon', null)
        setValue('contractRegon', null)
        setValue('krs', null)
        setValue('contractKrs', null)
        break
      case 'regon':
        setValue('contractNip', null)
        setValue('nip', null)
        setValue('contractKrs', null)
        setValue('krs', null)
        break
      case 'krs':
        setValue('nip', null)
        setValue('contractNip', null)
        setValue('regon', null)
        setValue('contractRegon', null)
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (additionalValues.nip.initial) {
      setValue(name, 'nip')

      return
    }

    if (additionalValues.regon.initial) {
      setValue(name, 'regon')

      return
    }

    if (additionalValues.krs.initial) {
      setValue(name, 'krs')

      return
    }

    setValue(name, 'other')
  }, [
    name,
    setValue,
    additionalValues.nip.initial,
    additionalValues.regon.initial,
    additionalValues.krs.initial,
  ])

  const defaultClasses = useStyles()

  return (
    <FormControl
      error={renderError && !!error}
      disabled={disabled}
      className={clsx(defaultClasses.root, classes.root)}
      variant="outlined"
    >
      <Select
        id={id}
        name={name}
        onChange={handleChange}
        value={value || 'nip'}
        classes={{
          select: defaultClasses.select,
        }}
        input={<CustomInput />}
      >
        {Object.keys(choices).map(key => (
          <MenuItem value={key} key={key}>
            {translate(choices[key])}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText classes={{ contained: defaultClasses.error }}>
        {translate(renderError && error)}
      </FormHelperText>
    </FormControl>
  )
}

TaxIdType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  choices: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
}
