import React, { useCallback, useEffect, useState } from 'react'
import { Tabs } from './components/Tabs'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SectionTitle } from 'core/components/SectionTitle'
import { makeStyles } from '@material-ui/styles'
import { IconButton, Typography } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import schema from '_schema/oragnizationType'
import { fetchDataHandleAuthError } from '../../../core/_helpers/fetchDataHandleAuthError'
import { notification } from '../../../core/_helpers/notification'
import { StringType } from '../../../core/components/form/fields/StringType'
import SaveIcon from '@material-ui/icons/Save'
import { ReactComponent as RemoveIcon } from '../../../theme/icons/remove.svg'
import { ButtonPlus } from '../../../core/components/buttons/button-plus'

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(5),
    backgroundColor: 'transparent',
  },
  root: {
    marginBottom: theme.spacing(5),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  itemCount: {
    fontWeight: 700,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  itemSave: {
    marginBottom: theme.spacing(3),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  itemRemove: {
    marginBottom: theme.spacing(3),
    '&:hover svg path': {
      fill: theme.palette.primary.main,
    },
  },
}))

const ItemElement = ({ count, collect, onSuccess, onRemove }) => {
  const classes = useStyles()
  const [state, setState] = useState(
    collect
      ? collect
      : {
          title: '',
          id: null,
          uuid: '',
        }
  )

  const [error, setError] = useState({
    error: null,
    renderError: false,
  })

  const [logChange, setLogChange] = useState(false)

  const handleChange = (name, value) => {
    setState(state => ({ ...state, title: value }))
    setLogChange(true)
    setError({
      error: null,
      renderError: false,
    })
  }
  const handleError = () => {}

  const handleRemove = () => {
    if (state.uuid) {
      const url = `${schema.endpoint}/${state.uuid}`
      fetchDataHandleAuthError(url, 'DELETE', {}, () => {
        onRemove(count, state.uuid)
        notification('warning', 'Element został usunięty')
      })
    } else {
      onRemove(count, state.uuid)
    }
  }

  const handleSubmit = useCallback(() => {
    setLogChange(false)

    let url = schema.endpoint
    let method = 'POST'
    if (state.uuid) {
      url = `${schema.endpoint}/${state.uuid}`
      method = 'PUT'
    }

    fetchDataHandleAuthError(
      url,
      method,
      {
        body: JSON.stringify(state),
      },
      response => {
        notification('success', 'Element został zapisany')
        onSuccess(response, count)
      },
      error => {
        if (
          error.response.violations !== undefined &&
          error.response.violations.length > 0
        ) {
          setError({
            error: error.response.violations[0].message,
            renderError: true,
          })
        } else {
          notification('error', 'Błąd')
        }
      }
    )
  }, [count, onSuccess, state])
  return (
    <>
      <div className={classes.item}>
        <Typography
          color={'primary'}
          variant={'h2'}
          className={classes.itemCount}
        >
          {count}
        </Typography>
        <StringType
          name={'title'}
          setValue={handleChange}
          disabled={false}
          renderError={error.renderError}
          type={'text'}
          setError={handleError}
          label={''}
          width={'500px'}
          fullWidth={true}
          value={state.title}
          error={error.error}
        />
        {logChange && (
          <IconButton
            className={classes.itemSave}
            aria-label={'save'}
            color={'primary'}
            onClick={handleSubmit}
          >
            <SaveIcon />
          </IconButton>
        )}
        <IconButton
          className={classes.itemRemove}
          aria-label={'remove'}
          onClick={handleRemove}
        >
          <RemoveIcon />
        </IconButton>
      </div>
    </>
  )
}

export const Dictionary = ({ match }) => {
  const classes = useStyles()
  const [collection, setCollection] = useState([])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    fetchDataHandleAuthError(schema.endpoint, 'GET', { signal }, resp => {
      setCollection(resp['hydra:member'])
    })
    return () => controller.abort()
  }, [])

  const [newItem, setNewItem] = useState(false)

  const handleSuccess = useCallback(
    (collect, index) => {
      if (newItem) {
        setCollection(state => [...state, collect])
        setNewItem(false)
      } else {
        setCollection(state => {
          const array = state
          array[index - 1] = collect
          return array
        })
      }
    },
    [newItem]
  )

  const handleRemove = useCallback(
    (index, uuid) => {
      if (index === collection.length + 1) {
        setNewItem(false)
      } else {
        const array = collection.filter(el => el.uuid !== uuid)
        setCollection([])
        setCollection(array)
      }
    },
    [collection]
  )

  const handleClickAdd = useCallback(() => {
    setNewItem(true)
  }, [])

  return (
    <>
      <CollectionHeader
        buttonTitle={''}
        hideButton={true}
        hideButtonBack={true}
      >
        <Tabs path={match.path} />
      </CollectionHeader>
      <div className={classes.container}>
        <SectionTitle label={'Słowniki'} divider={true} />
        <Typography variant={'h3'} color={'primary'}>
          {translate('Typ organizacji')}
        </Typography>
        <div className={classes.root}>
          {collection.map((el, index) => (
            <ItemElement
              key={index}
              count={index + 1}
              collect={el}
              onSuccess={handleSuccess}
              onRemove={handleRemove}
            />
          ))}
          {newItem ? (
            <ItemElement
              count={collection.length + 1}
              collect={null}
              onSuccess={handleSuccess}
              onRemove={handleRemove}
            />
          ) : (
            <ButtonPlus
              text={'Dodaj typ organizacji'}
              onClick={handleClickAdd}
            />
          )}
        </div>
      </div>
    </>
  )
}
