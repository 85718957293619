import React from 'react'
import { Route } from 'react-router-dom'
import { VpnKeyOutlined } from '@material-ui/icons'
import { ChangePassword } from './ChangePassword'

const routes = () => ({
  index: {
    title: 'T_MODULE_CHANGE_PASSWORD',
    type: Route,
    render: props => <ChangePassword {...props} />,
    path: '/change_password',
    exact: true,
    icon: VpnKeyOutlined
  },
})

export default routes
