import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { useStyles } from './fields.style'
import { ReactComponent as HelpIcon } from 'theme/icons/help.svg'
import { CustomDialog } from '../../Dialog'
import { StringType } from './StringType'

export const ModalStringType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setAdditional = null,
  setError,
  syncWithAdditional = false,
  fullWidth = false,
  widthInput = '100%',
  endIcon = null,
  readonly = false,
  placeholder = '',
  maxLength = null,
  endText = null,
  min = 0,
  max = null,
}) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const [valueState, setValueState] = useState(value)

  const handleConfirmToggle = () => {
    setOpen(prevState => !prevState)
  }

  const handleConfirm = () => {
    setValue(name, valueState)
    setOpen(prevState => !prevState)
  }

  const handleChangeValue = (name, value) => {
    setValueState(value)
  }

  const Dialog = () => (
    <>
      <StringType
        name={name}
        type={'textarea'}
        label={''}
        hint={hint}
        initialValue={initialValue}
        value={valueState}
        compareValue={compareValue}
        compare={compare}
        error={error}
        renderError={renderError}
        disabled={disabled}
        validators={validators}
        setValue={handleChangeValue}
        setAdditional={setAdditional}
        setError={setError}
        syncWithAdditional={syncWithAdditional}
        fullWidth={fullWidth}
        width={'100%'}
        widthInput={widthInput}
        endIcon={endIcon}
        readonly={readonly}
        placeholder={placeholder}
        maxLength={maxLength}
        endText={endText}
        min={min}
        max={max}
      />
    </>
  )

  return (
    <>
      <IconButton className={classes.modalIcon} onClick={handleConfirmToggle}>
        <HelpIcon />
      </IconButton>
      <CustomDialog
        handleToogle={handleConfirmToggle}
        open={open}
        title={translate(label.text || label)}
        confirmedButton={value !== valueState}
        onConfirm={handleConfirm}
        isFetching={false}
        showActions={true}
        component={Dialog}
        confirmedText={'Add Help'}
      />
    </>
  )
}

ModalStringType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compareValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setAdditional: PropTypes.func,
  setError: PropTypes.func.isRequired,
  syncWithAdditional: PropTypes.bool,
  fullWidth: PropTypes.bool,
  widthInput: PropTypes.string,
  endIcon: PropTypes.object,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  endText: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
}
