import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { makeStyles } from '@material-ui/styles'
import { STATUS_NOTREPORTED } from '_schema/proposalQuarterlyDeclaration'
import { IconButton } from '@material-ui/core'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'

const useStyles = makeStyles(theme => ({
  hideFirstPadding: {
    '& td:first-of-type, & th:first-of-type': {
      paddingLeft: 0,
    },
  },
  sumRow: {
    '& td:first-of-type': {
      paddingLeft: 0,
    },
    borderTop: `1px solid ${theme.palette.primary.main}`,
  },
  table_cell_head: {
    color: '#A19AAA',
    fontWeight: 600,
    fontStyle: 'italic',
    border: 0,
  },
  table_cell: {
    fontWeight: 600,
    border: 0,
  },
  table_header: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    margin: 0,
  },
  table_header_line: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    flexGrow: 1,
    marginRight: 30,
    '&::after': {
      content: '""',
      height: 1,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      marginLeft: 10,
    },
  },
  titleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const DeclarationList = ({ items, title, status }) => {
  const tableClasses = useStyles()

  const [open, setOpen] = useState(status === STATUS_NOTREPORTED)

  const handleOpen = () => {
    setOpen(prevState => !prevState)
  }

  function totalNettoAmount(items) {
    return items
      .map(({ nettoAmount }) => nettoAmount)
      .reduce((sum, i) => sum + i, 0)
  }

  function totalSubsidyAmount(items) {
    console.log(items)
    return items
      .map(({ subsidyAmount }) => Number(subsidyAmount || 0))
      .reduce((sum, i) => sum + i, 0)
  }

  function totalPercent(items) {
    const totalSubsidy = totalSubsidyAmount(items)
    const totalNetto = totalNettoAmount(items)
    return (totalNetto * 100) / totalSubsidy
  }

  const ItemRow = ({ row, classes }) => {
    if (
      (!row.budget || !row.budget.title) &&
      (!row.subsidyAmount || row.subsidyAmount === 0)
    ) {
      return null
    }
    return (
      <TableRow className={classes.hideFirstPadding}>
        <TableCell className={classes.table_cell}>{row.title}</TableCell>
        <TableCell className={classes.table_cell}>
          {Number(row.subsidyAmount).toFixed(2)}
        </TableCell>
        <TableCell className={classes.table_cell}>
          {Number(row.nettoAmount).toFixed(2)}
        </TableCell>
        <TableCell className={classes.table_cell}>
          {Number(row.precent).toFixed(2)}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <TableContainer>
      <div className={tableClasses.titleRow}>
        <h3 className={tableClasses.table_header_line}>{title}</h3>
        <IconButton onClick={handleOpen} color={'primary'}>
          {open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
        </IconButton>
      </div>

      <Table>
        <TableHead>
          <TableRow className={tableClasses.hideFirstPadding}>
            <TableCell className={tableClasses.table_cell_head}>
              {open && <>Rodzaj kosztów</>}
            </TableCell>
            <TableCell className={tableClasses.table_cell_head}>
              Kwota dotowana
            </TableCell>
            <TableCell className={tableClasses.table_cell_head}>
              Kwota wydatkowana
            </TableCell>
            <TableCell className={tableClasses.table_cell_head}>%</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {open &&
            items?.map(row => (
              <ItemRow row={row} key={row.uuid} classes={tableClasses} />
            ))}
          {items?.length > 0 ? (
            <TableRow key={`sum-td`} className={tableClasses.sumRow}>
              <TableCell className={tableClasses.table_cell}>
                <h3 className={tableClasses.table_header}>Razem:</h3>
              </TableCell>
              <TableCell className={tableClasses.table_cell}>
                {Number(totalSubsidyAmount(items)).toFixed(2)} zł
              </TableCell>
              <TableCell className={tableClasses.table_cell}>
                {Number(totalNettoAmount(items)).toFixed(2)} zł
              </TableCell>
              <TableCell className={tableClasses.table_cell}>
                {totalPercent(items).toFixed(2)}
              </TableCell>
            </TableRow>
          ) : (
            'brak kosztów'
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
