import { makeStyles } from '@material-ui/styles'

export const useContractAttachmentCollectionTypeStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    marginBottom: 50,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  container: {
    display: 'flex',
  },
  sidebar: {
    display: 'flex',
    alignItems: 'flex-start',
    marginLeft: 40,
    marginTop: 30,
    '&> *': {
      cursor: 'pointer'
    }
  },
}))
