import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { makeStyles } from '@material-ui/styles'
import { getBlockFieldsMapping } from 'pages/applicant/Proposals/_helpers/getBlockFieldsMapping'
import { blockTypes } from 'pages/applicant/Proposals/_helpers/blockTypes'
import { Grid, Typography } from '@material-ui/core'
import { DataInfo } from 'components/DataInfo'

const DATE_FORMAT = 'DD.MM.YYYY'
const TIME_FORMAT = 'HH:mm'
const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm'

const useStyles = makeStyles(theme => ({
  help: {
    marginBottom: theme.spacing(3),
    color: theme.palette.disabled,
  },
}))

export const DateTimeBlock = ({ data, values }) => {
  const mapping = useMemo(
    () => getBlockFieldsMapping(blockTypes.DATE_TIME, data),
    [data]
  )

  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      <Grid item md={8}>
        <DataInfo
          label={mapping.input.label}
          data={`${
            mapping.input.isDate && mapping.input.isTime && values?.input
              ? moment(values.input).format(DATE_TIME_FORMAT)
              : ''
          }${
            mapping.input.isDate && !mapping.input.isTime && values?.input
              ? moment(values.input).format(DATE_FORMAT)
              : ''
          }${
            !mapping.input.isDate && mapping.input.isTime && values?.input
              ? moment(values.input).format(TIME_FORMAT)
              : ''
          }`}
        />
      </Grid>
      <Grid item md={4}>
        {mapping.input.help && (
          <Typography
            variant={'body1'}
            color={'primary'}
            className={classes.help}
          >
            {mapping.input.help}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

DateTimeBlock.propTypes = {
  data: PropTypes.string,
  values: PropTypes.object,
}
