import React, { useState } from 'react'
import { ReactComponent as EditIcon } from 'theme/icons/edit.svg'
import { IconButton, Tooltip } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { makeStyles } from '@material-ui/core/styles'
import { CustomDialog } from 'core/components/Dialog'
import { Form } from 'core/components/form'
import schema from '_schema/contestEdition'
import { constants } from 'core/components/table/_state'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

const useStyles = makeStyles(theme => ({
  edit: {
    cursor: 'pointer',
    '&:hover, &:focus': {
      '& path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  text: {
    cursor: 'pointer',
    width: '100%',
  },
  disabled: {
    '& path': {
      fill: theme.palette.disabled,
    },
  },
  confirmText: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 0,
  },
}))

export const Edit = ({ resource, tableStateDispatch }) => {
  const classes = useStyles()
  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const handleConfirmToggle = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
  }

  const handleConfirmSuccess = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
    tableStateDispatch({ type: constants.RELOAD })
  }

  const [buttonRef, setButtonRef] = useState(null)

  const onButtonRef = element => {
    setButtonRef(element)
  }

  const iri = `${schema.endpoint}/${
    resource[process.env.REACT_APP_RESOURCE_ID]
  }`

  const handleConfirm = () => {
    fetchDataHandleAuthError(
      iri,
      'PUT',
      { body: JSON.stringify({ isFinished: !resource.isFinished }) },
      () => {
        setState({
          isConfirmOpen: false,
          isFetching: false,
        })
        tableStateDispatch({ type: constants.RELOAD })
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const Dialog = () => (
    <>
      <Form
        url={iri}
        method="PUT"
        properties={schema.resource_write.properties}
        showSubmitAndStayButton={false}
        showCancelButton={false}
        fieldsFullWidth={true}
        width="100%"
        handleCancel={handleConfirmToggle}
        buttonContainerRef={buttonRef}
        iri={iri}
        resource={resource}
        handleSuccess={handleConfirmSuccess}
      />
    </>
  )

  return (
    <>
      <Tooltip title={translate('T_GENERAL_EDIT')}>
        <IconButton
          className={classes.edit}
          aria-label={translate('T_GENERAL_EDIT')}
          disableRipple
          disableFocusRipple
          onClick={handleConfirmToggle}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <CustomDialog
        title="Edytuj edycję"
        confirmedButton={true}
        confirmedText={
          resource.isFinished === false || resource.isFinished === null
            ? 'Zakończ edycję'
            : 'Wznów edycję'
        }
        component={Dialog}
        onConfirm={handleConfirm}
        open={state.isConfirmOpen}
        handleToogle={handleConfirmToggle}
        isFetching={state.isFetching}
        showActions={true}
        onButtonRef={onButtonRef}
      />
    </>
  )
}
