import 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import randomHash from 'random-hash'
import clsx from 'clsx'
import { InputAdornment, FormHelperText } from '@material-ui/core'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
import DateFnsUtils from '@date-io/date-fns'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import plLocale from 'date-fns/locale/pl'
import moment from 'moment'
import { ReactComponent as DateTimeIcon } from '../../../../theme/icons/datetime.svg'
import { useStyles } from './fields.style'
import { StringType } from './StringType'
import { makeStyles } from '@material-ui/styles'

const LOCALES = {
  pl: plLocale,
}

const FORMAT = 'dd-MM-yyyy HH:mm'

const DATE_PICKER_ERRORS = [
  translate('T_GENERAL_INVALID_DATE'),
  translate('T_GENERAL_INVALID_MIN_DATE'),
  translate('T_GENERAL_INVALID_MAX_DATE'),
]

const useLocalStyles = makeStyles({
  error: {
    marginTop: -22,
  },
})

export const DateTimeType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
  width = '350px',
  minDate = '1900-01-01',
  maxDate = '2100-01-01',
}) => {
  const [id] = useState(randomHash())

  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleChange = val => {
    const date = val ? moment(val).format() : null
    setValue(name, date)
    validateField(date)
    setAnchorEl(null)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  const handleError = message => {
    if (message && message !== error) {
      setError(name, message)
    }
  }

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  const classes = useStyles()
  const localClasses = useLocalStyles()

  const handleOpen = event => {
    !disabled && setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div
      style={{
        maxWidth: width,
      }}
    >
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={LOCALES[process.env.REACT_APP_LOCALE]}
      >
        <DateTimePicker
          id={id}
          name={name}
          label={
            translate(label && label.text ? label.text : label ? label : '') +
            (validators && validators.includes('required') ? ' *' : '')
          }
          disabled={disabled}
          variant="dialog"
          value={![null, undefined].includes(value) ? new Date(value) : null}
          format={FORMAT}
          margin="normal"
          autoOk={true}
          ampm={false}
          disableToolbar={true}
          invalidDateMessage={DATE_PICKER_ERRORS[0]}
          minDateMessage={DATE_PICKER_ERRORS[1]}
          maxDateMessage={DATE_PICKER_ERRORS[2]}
          onChange={handleChange}
          onError={handleError}
          DialogProps={{
            PaperProps: {
              classes: {
                root: classes.dateTimePaper,
              },
            },
            onClose: handleClose,
          }}
          PopoverProps={{
            anchorEl: anchorEl,
            onClose: handleClose,
            classes: { root: classes.dateTime },
            PaperProps: {
              classes: {
                root: classes.dateTimePaper,
              },
            },
          }}
          cancelLabel={''}
          okLabel={''}
          keyboardIcon={
            <InputAdornment position="end">
              <DateTimeIcon />
            </InputAdornment>
          }
          endIcon={
            <InputAdornment
              position="end"
              onClick={handleOpen}
              disablePointerEvents={disabled}
              className={clsx(classes.field_icon, classes.field_icon_pointer)}
            >
              <DateTimeIcon />
            </InputAdornment>
          }
          TextFieldComponent={StringType}
          dateTimeType={true}
          setError={() => false}
          error={renderError && !!error}
          type="text"
          renderError={renderError}
          setValue={() => false}
          open={Boolean(anchorEl)}
          readonly="readonly"
          minDate={minDate || '1900-01-01'}
          maxDate={maxDate || '2100-01-01'}
        />
      </MuiPickersUtilsProvider>
      {renderError && !!error && (
        <FormHelperText error={true} className={localClasses.error}>
          {translate(error)}
        </FormHelperText>
      )}
      {compare && (
        <div
          className={clsx(
            classes.compare,
            value !== compareValue && classes.compareNeq
          )}
        >
          {compareValue}
        </div>
      )}
    </div>
  )
}

DateTimeType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.string,
  value: PropTypes.string,
  compareValue: PropTypes.string,
  compare: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
}
