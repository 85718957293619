import React, { useCallback, useEffect, useState } from 'react'
import { SectionTitle } from 'core/components/SectionTitle'
import { Button, Grid, Typography } from '@material-ui/core'
import { StringType } from 'core/components/form/fields/StringType'
import { makeStyles } from '@material-ui/styles'
import {
  CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_INTERNAL,
  RATE_FAILED,
} from '_schema/contestProposalRateGuidelines'
import { translate } from 'core/_helpers/translate'
import { useSelector } from 'react-redux'
import schemaUser from 'core/_schema/user'
import schema from '_schema/proposalEvents'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import schemaInternalRate from '_schema/proposalInternalRate'
import schemaGuideline from '_schema/contestProposalRateGuidelines'
import {
  CONTRACT_STATUS_APPLIED,
  REALIZATION_STATUS_FINISHED,
} from '_schema/proposal'
import moment from 'moment'
import Moment from 'react-moment'

const useStyle = makeStyles(theme => ({
  item: {
    marginBottom: theme.spacing(8),
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(5),
  },
}))

const ItemRate = ({
  rate,
  index,
  setValue,
  disabled,
  proposalInternalRateDate,
}) => {
  const classes = useStyle()
  return (
    <div className={classes.item}>
      <Grid container spacing={3} alignItems={'center'} justify={'flex-end'}>
        <Grid item>
          <Typography variant={'body1'} color={'primary'}>
            <Moment
              date={proposalInternalRateDate}
              local
              format={'DD-MM-YYYY'}
            />{' '}
            {rate.evaluator && (
              <>
                {rate.evaluator.firstName} {rate.evaluator.lastName}
              </>
            )}
          </Typography>
        </Grid>
        <Grid item>
          <StringType
            name={rate.contestRateGuideline}
            setValue={setValue}
            disabled={disabled}
            renderError={false}
            type={'number'}
            setError={() => false}
            label={`Punkty (${rate.pointsMin}-${rate.pointsMax} pkt)`}
            min={rate.pointsMin}
            max={rate.pointsMax}
            value={rate.points}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={1}>
          <Typography variant={'h3'} component={'span'} color={'primary'}>
            {index}.
          </Typography>
        </Grid>
        <Grid item xs={11}>
          <Typography variant={'subtitle1'} color={'primary'}>
            {rate.description}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export const RateInternal = ({
  resource,
  event,
  updateResource,
  eventObject,
}) => {
  const classes = useStyle()
  const user = useSelector(state => state.profile)
  const [state, setState] = useState([])
  const [fetchingRateEnd, setFetchingRateEnd] = useState(false)

  const getRates = useCallback(() => {
    const iri = `${schemaInternalRate.endpoint}?event.uuid=${event}&pagination=false`
    fetchDataHandleAuthError(iri, 'GET', {}, resp => {
      setState(
        resp['hydra:member'].map(el => {
          return {
            proposal: el.proposal,
            contestRateGuideline: el.rateGuideline['@id'],
            points: el.points || 0,
            description: el.rateGuideline.description,
            pointsMin: el.rateGuideline.pointsMin,
            pointsMax: el.rateGuideline.pointsMax,
            evaluator: el.evaluator,
            id: el['@id'],
          }
        })
      )
      setFetchingRateEnd(true)
    })
  }, [event])

  useEffect(() => {
    getRates()
  }, [resource, event, getRates])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    const iri = `${schemaGuideline(null).endpoint}?contest.uuid=${
      resource.contest.uuid
    }&type=${CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_INTERNAL}&pagination=false`
    if (fetchingRateEnd && state.length === 0) {
      fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
        setFetchingRateEnd(false)
        setState(
          resp['hydra:member'].map(el => {
            return {
              proposal: resource['@id'],
              contestRateGuideline: el['@id'],
              points: 0,
              description: el.description,
              pointsMin: el.pointsMin,
              pointsMax: el.pointsMax,
              evaluator: null,
              id: null,
            }
          })
        )
      })
    }
    return () => controller.abort()
  }, [state, fetchingRateEnd, resource])

  const handleChangePoints = (name, value) => {
    setState(prevState => {
      const index = prevState.findIndex(el => el.contestRateGuideline === name)
      const array = [...prevState]
      array[index].points = value
      return array
    })
  }

  const handleSubmit = useCallback(() => {
    const internalRates = state.map(el => {
      const rate = {
        points: el.points,
        proposal: el.proposal,
        rateGuideline: el.contestRateGuideline,
        evaluator: `${schemaUser('user').endpoint}/${user.uuid}`,
      }

      if (el.id) {
        rate['@id'] = el.id
      }

      return rate
    })
    fetchDataHandleAuthError(
      `${schema.endpointInternalRate}/${event}`,
      'PUT',
      {
        body: JSON.stringify({
          internalRates,
        }),
      },
      () => {
        notification('success', translate('Ocena została wystawiona'))
        getRates()
        updateResource()
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [event, state, updateResource, user.uuid, getRates])

  const internalRatingDateTo = moment(resource.contest.internalRatingDateTo)

  const disabled =
    resource.contractStatus === CONTRACT_STATUS_APPLIED ||
    resource.realizationStatus === REALIZATION_STATUS_FINISHED ||
    moment().isAfter(internalRatingDateTo, 'day') ||
    resource.formalRateStatus === RATE_FAILED ||
    resource.formalRateStatus === null

  return (
    <>
      <SectionTitle
        label={'Ocena wewnętrzna'}
        divider={false}
        marginTopSm={true}
      />
      <SectionTitle label={'Kryteria'} divider={true} />
      {state.map((el, index) => (
        <ItemRate
          key={index}
          rate={el}
          index={index + 1}
          setValue={handleChangePoints}
          disabled={disabled}
          proposalInternalRateDate={eventObject?.proposalInternalRateDate}
        />
      ))}
      {!disabled && (
        <div className={classes.button}>
          <Button color={'primary'} variant="contained" onClick={handleSubmit}>
            {translate('Zapisz')}
          </Button>
        </div>
      )}
    </>
  )
}
