import React, { createRef, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/ExpansionPanel'
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary'
import AccordionDetails from '@material-ui/core/ExpansionPanelDetails'
import { Grid, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: 55,
    marginTop: -155,
    paddingTop: 155,
  },
  heading: {
    marginBottom: 55,
  },
  category: {
    width: 'fit-content',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  accordionHead: {
    '& svg': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      '& h3': {
        color: theme.palette.secondary.main,
      },
      '& svg': {
        color: theme.palette.secondary.main,
      },
    },
  },
  sticky: {
    position: 'sticky',
    top: 105,
    paddingRight: 30,
  },
  grid: {
    alignItems: 'flex-start',
    padding: 30,
  },
  accordion: {
    borderRadius: '0 !important',
  },
}))

export const View = () => {
  const classes = useStyles()
  const iri = '/faq_categories'

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError
  )
  const itemsRef = useMemo(
    () =>
      Array(resource?.['hydra:totalItems'])
        .fill()
        .map(() => createRef()),
    [resource]
  )

  const handleScroll = i => {
    itemsRef[i].current.scrollIntoView({ behavior: 'smooth' })
  }

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Grid container className={classes.grid}>
      <Grid item xs={4} className={classes.sticky}>
        <Typography
          className={classes.heading}
          variant={'h1'}
          color={'primary'}
        >
          FAQ - Najczęściej zadawane pytania
        </Typography>
        {resource['hydra:member'].map((item, i) => (
          <Typography
            variant="h2"
            color={'secondary'}
            gutterBottom
            className={classes.category}
            onClick={() => handleScroll(i)}
            key={item.title}
          >
            {item.title}
          </Typography>
        ))}
      </Grid>
      <Grid item xs={8}>
        {resource['hydra:member'].map((item, i) => (
          <div key={item.title}>
            <Typography gutterBottom variant="h2" color={'primary'}>
              {item.title}
            </Typography>
            <div className={classes.root} ref={itemsRef[i]}>
              {item.faqItem.map((item, i) => (
                <Accordion
                  key={`${item.question}-${i}`}
                  className={classes.accordion}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${item.question}a-content`}
                    id={`panel${item.question}a-header`}
                    className={classes.accordionHead}
                  >
                    <Typography variant="h3" color={'primary'}>
                      {item.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="subtitle1" color={'primary'}>
                      {item.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </div>
        ))}
      </Grid>
    </Grid>
  )
}
