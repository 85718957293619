import { makeStyles } from '@material-ui/styles'

export const useDetailsCollectionTypeStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    '& > div': {
      overflow: 'auto',
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 26,
  },
  header: {
    display: 'flex',
    minWidth: 'fit-content',
    '& > div': {
      marginRight: 20,
      width: 253,
      fontWeight: 'bold'
    },
    '& $header_number': {
      width: 20,
    },
  },
  header_number: {
    width: 20,
    marginRight: 20,
  },
}))
