import { combineReducers } from 'redux'
import { authReducer } from 'core/_reducers'
import { commonReducer } from 'core/_reducers'
import { collectionReducer } from 'core/_reducers'
import { profileReducer } from 'core/_reducers'
import { schemaReducer } from 'core/_reducers'
import { formSettingsReducer } from '_reducers/formSettings.reducer'
import { projectReducer } from '_reducers/project.reducer'
import { contestBudgetCategoriesReducer } from './contestBudgetCategories.reducer'

const reducers = combineReducers({
  auth: authReducer,
  common: commonReducer,
  collection: collectionReducer,
  profile: profileReducer,
  schema: schemaReducer,
  formSetting: formSettingsReducer,
  project: projectReducer,
  budgetCategories: contestBudgetCategoriesReducer,
})

export default reducers
