export const isFormTemplateInvalid = sections => {
  if (!sections) {
    return false
  }

  let isInvalid = 0

  Object.keys(sections).forEach(sectionUuid => {
    if (!sections[sectionUuid]) {
      return
    }

    sections[sectionUuid].forEach(sectionItem => {
      Object.keys(sectionItem).forEach(blockUuid => {
        Object.keys(sectionItem[blockUuid]).forEach(fieldName => {
          if (sectionItem[blockUuid][fieldName]) {
            isInvalid++
          }
        })
      })
    })
  })

  return isInvalid
}
