import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { BlankForm } from 'pages/applicant/BlankForm'
import { FieldWrapper } from 'components/applicant/form'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import { Typography } from '@material-ui/core'

const VALIDATE_DATE_FORMAT = 'YYYY-MM-DD'

const useStyles = makeStyles({
  root: {
    width: 'fit-content',
  },
})

export const Form = ({
  url,
  method,
  iri = null,
  resource = null,
  definitionSchema,
  customResourceSchema,
  submitButtonTitle,
  submitButtonVariant,
  buttonContainerRef,
  visit,
  ...rest
}) => {
  const localClasses = useStyles()

  const validators = useMemo(
    () => ({
      inputs: [
        ...(visit.dateFrom
          ? [`minDate(${moment(visit?.dateFrom).format(VALIDATE_DATE_FORMAT)})`]
          : []),
        ...(visit.dateTo
          ? [`maxDate(${moment(visit?.dateTo).format(VALIDATE_DATE_FORMAT)})`]
          : []),
        'required',
      ],
    }),
    [visit]
  )

  return (
    <BlankForm
      url={url}
      method={method}
      iri={iri}
      resource={resource}
      definitionSchema={definitionSchema}
      customResourceSchema={customResourceSchema}
      submitButtonTitle={submitButtonTitle}
      submitButtonVariant={submitButtonVariant}
      buttonContainerRef={buttonContainerRef}
      classes={{
        root: localClasses.root,
      }}
      {...rest}
    >
      {({ state, properties, resource, setValue, setError }) => (
        <>
          <FieldWrapper
            label="Potwierdzam termin wizyty"
            name="userVisitationAcceptance"
            property={properties.userVisitationAcceptance}
            resource={resource}
            state={state}
            setValue={setValue}
            setError={setError}
          />
          <FieldWrapper
            label="Miejsce spotkania:"
            name="address"
            property={properties.address}
            resource={resource}
            state={state}
            setValue={setValue}
            setError={setError}
          />
          <FieldWrapper
            label="Potwierdzam obecność koordynatora"
            name="coordinatorPresenceConfirmation"
            property={properties.coordinatorPresenceConfirmation}
            resource={resource}
            state={state}
            setValue={setValue}
            setError={setError}
          />
          <FieldWrapper
            label="Potwierdzam obecność grupy projektowej"
            name="projectGroupPresenceConfirmation"
            property={properties.projectGroupPresenceConfirmation}
            resource={resource}
            state={state}
            setValue={setValue}
            setError={setError}
          />
          <Typography variant={'h3'}>
            Zasady organizacji wizyt monitoringowych
          </Typography>
          <Typography variant={'body1'}>
            <div
              dangerouslySetInnerHTML={{ __html: visit.visitationDescription }}
            ></div>
          </Typography>
          <FieldWrapper
            label="Zapoznałem/-am się z zasadami organizacji wizyt monitoringowych"
            name="visitationRulesAcceptation"
            property={properties.visitationRulesAcceptation}
            resource={resource}
            state={state}
            setValue={setValue}
            setError={setError}
          />
          <FieldWrapper
            label="Dodatkowe uwagi:"
            name="description"
            property={properties.description}
            resource={resource}
            state={state}
            setValue={setValue}
            setError={setError}
          />
        </>
      )}
    </BlankForm>
  )
}

Form.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  resource: PropTypes.object.isRequired,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
}
