import foundationHome from './Foundation/Home/routes'
import foundationExperts from './Foundation/Experts/routes'
import expertHome from './Expert/Home/routes'
import forms from './Foundation/Form/routes'
import applicantCurrentContests from './applicant/CurrentContests/routes'
import applicantProposals from './applicant/Proposals/routes'
import applicantFaq from './applicant/Faq/routes'
import foundationSettings from './Foundation/Settings/routes'
import foundationOrganizations from './Foundation/Organizations/routes'
import foundationProjects from './Foundation/Projects/routes'

const menu = () => [
  foundationHome().contest_foundation,
  foundationProjects().index,
  foundationOrganizations().index,
  expertHome().contest_expert,
  foundationExperts().index,
  forms().index,
  {
    ...applicantCurrentContests().index,
    title: 'T_MODULE_CONTESTS',
  },
  {
    ...applicantProposals().index,
    title: 'T_MODULE_PROPOSALS',
  },
  {
    ...applicantFaq().index,
    title: 'T_MODULE_FAQ',
  },
  {
    ...foundationSettings().administrators,
    title: 'Ustawienia',
  },
]

export default menu
