import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as AddIcon } from '../../theme/icons/plus.svg'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { translate } from '../../core/_helpers/translate'
import { ReactComponent as ArrowIcon } from '../../theme/icons/arrow-down.svg'
import schemaUser from '../../core/_schema/user'
import { fetchDataHandleAuthError } from '../../core/_helpers/fetchDataHandleAuthError'

const useStyles = makeStyles(theme => ({
  button: {
    color: theme.elements.embedded_collection.color,
    '&:hover': {
      '& svg': {
        '& circle': {
          fill: theme.palette.primary.main,
        },
      },
    },
  },
  menuButton: {
    fontFamily: ['Sora', 'sans-serif'],
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '26px',
    letterSpacing: '0.05em',
    border: 0,
    boxShadow: 0,
    background: 'none',
    cursor: 'pointer',
    padding: '0 10px',
    color: theme.palette.primary.main,
    transition: 'color 0.2s',
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 12,
      '& path': {
        transition: 'stroke 0.2s',
      },
    },
    '&:hover, &:focus': {
      color: theme.palette.secondary.main,
      '& svg path': {
        stroke: theme.palette.secondary.main,
      },
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      minWidth: '250px',
    },
  },
  select: {
    color: theme.elements.embedded_collection.color,
  },
}))

export const Add = ({ onSetExpert, expertsCollection, experts }) => {
  const classes = useStyles()

  const [menu, setMenu] = useState(null)

  const handleOpenClick = event => {
    setMenu(event.currentTarget)
  }

  const handleClose = () => {
    setMenu(null)
  }

  const handleChange = expert => {
    onSetExpert({ ...expert, contestProvinceExperts: [] })
    setMenu(null)
  }

  return (
    <>
      <IconButton
        size="small"
        onClick={handleOpenClick}
        className={classes.button}
      >
        <AddIcon />
      </IconButton>
      <button
        className={classes.menuButton}
        aria-controls="block-menu"
        aria-haspopup="true"
        onClick={handleOpenClick}
      >
        <span>{translate('T_GENERAL_CHOOSE')}</span>
        <ArrowIcon />
      </button>
      <Menu
        id="block-menu"
        keepMounted
        anchorEl={menu}
        open={Boolean(menu)}
        onClose={handleClose}
        className={classes.menu}
      >
        <MenuItem value="null">{translate('T_GENERAL_CHOOSE')}</MenuItem>
        {experts.map(expertItem => (
          <MenuItem
            key={expertItem.uuid}
            onClick={() => handleChange(expertItem)}
            disabled={
              !!expertsCollection.find(el => expertItem.uuid === el.uuid)
            }
          >
            {`${expertItem.firstName} ${expertItem.lastName}`}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

Add.propTypes = {
  onSetExpert: PropTypes.func,
  expertsCollection: PropTypes.array,
  experts: PropTypes.array,
}
