import React from 'react'
import { ChoiceType } from 'core/components/form/fields/ChoiceType'
import { StringType } from 'core/components/form/fields/StringType'

export const Additional = () => {
  return (
    <>
      <div style={{ marginBottom: '24px' }}>
        <ChoiceType
          name={'add1'}
          setValue={() => false}
          disabled={false}
          renderError={false}
          setError={() => false}
          error={false}
          label={'T_MODULE_ORGANIZATION_DATA_POPULATION'}
          choices={{
            1: '0 - 100',
            2: '100 - 1000',
            3: '1000 - 20 000',
          }}
          width={'350px'}
        />
      </div>
      <div>
        <StringType
          name={'add2'}
          setValue={() => false}
          disabled={false}
          renderError={false}
          type={'string'}
          setError={() => false}
          label={'T_MODULE_ORGANIZATION_DATA_POPULATION_DATA_SOURCE'}
        />
      </div>
      <div>
        <StringType
          name={'add3'}
          setValue={() => false}
          disabled={false}
          renderError={false}
          type={'string'}
          setError={() => false}
          label={'T_MODULE_ORGANIZATION_DATA_WEBSITE'}
        />
      </div>
      <div>
        <StringType
          name={'add4'}
          setValue={() => false}
          disabled={false}
          renderError={false}
          setError={() => false}
          error={false}
          type={'number'}
          label={'T_MODULE_ORGANIZATION_DATA_ORGANIZATION_CREATION_DATE'}
        />
      </div>
    </>
  )
}
