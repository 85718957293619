import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { EditLink } from 'core/components/resource/EditLink'
import { DeleteCell } from 'core/components/table/cells/DeleteCell'
import { CloneCell } from 'core/components/table/cells/CloneCell'
import { translate } from 'core/_helpers/translate'
import { RestoreCell } from '../cells/RestoreCell'
import { StatusCell } from '../cells/StatusCell'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: 40,
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
})

export const operations = (
  accessor = `translations.${process.env.REACT_APP_LOCALE}.title`,
  editable = true,
  deletable = true,
  cloneable = false,
  deletableDisabled = false,
  editableComponent = false,
  restoreable = false,
  url = null,
  statusable = false,
  header = translate('T_GENERAL_OPERATIONS'),
  statusableDisable = false
) => ({
  header: header,
  accessor,
  sortable: false,
  filterable: false,
  Cell: ({
    resource,
    accessor,
    tableState,
    tableStateDispatch,
    editPath = null,
  }) => {
    const classes = useStyles()
    const [deleteDisable, setDeleteDisable] = useState(false)
    useEffect(() => {
      setDeleteDisable(deletableDisabled(resource))
    }, [resource])

    return (
      <div className={classes.root}>
        {editable &&
          !!editableComponent &&
          editableComponent(resource, tableStateDispatch)}
        {(typeof editable === 'function' &&
          editable(resource) &&
          !editableComponent) ||
          (editable && !editableComponent && (
            <EditLink
              resource={resource}
              accessor={accessor}
              editPath={editPath}
              isIcon={true}
            />
          ))}
        {statusable && (
          <StatusCell
            tableStateDispatch={tableStateDispatch}
            resource={resource}
            accessor={accessor}
            tableState={tableState}
            url={url}
            disabled={statusableDisable}
          />
        )}
        {restoreable && (
          <RestoreCell
            resource={resource}
            accessor={accessor}
            tableState={tableState}
            tableStateDispatch={tableStateDispatch}
          />
        )}
        {cloneable && (
          <CloneCell
            resource={resource}
            accessor={accessor}
            tableStateDispatch={tableStateDispatch}
          />
        )}
        {deletable && (
          <DeleteCell
            resource={resource}
            accessor={accessor}
            tableState={tableState}
            tableStateDispatch={tableStateDispatch}
            disabled={deleteDisable}
            url={url}
          />
        )}
      </div>
    )
  },
})
