import React from 'react'
import PropTypes from 'prop-types'
import { Clone } from 'core/components/resource/Clone'
import { constants } from 'core/components/table/_state'

export const CloneCell = ({
  resource,
  disabled = false,
  onSuccess: customOnSuccess = null,
  tableStateDispatch,
  accessor = null,
}) => {
  const onSuccess = () => {
    customOnSuccess && customOnSuccess()

    tableStateDispatch({ type: constants.RELOAD })
  }

  return (
    <Clone
      resource={resource}
      disabled={disabled}
      onSuccess={onSuccess}
      isIcon={true}
      accessor={accessor}
    />
  )
}

CloneCell.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  tableStateDispatch: PropTypes.func.isRequired,
  accessor: PropTypes.string,
}
