import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { FormTemplate } from './FormTemplate'
import { SectionTitle } from 'core/components/SectionTitle'
import { Grid } from '@material-ui/core'
import { DataInfo } from 'components/DataInfo'
import { BooleanType } from 'core/components/form/fields/BooleanType'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: 15,
  },
  coordinators: {
    '&> *': {
      marginTop: 10,
      marginBottom: 10,
    },
  },
  coordinator_role: {
    fontWeight: 'bold',
    marginRight: 20,
  },
}))

export const ApplicantStep = ({
  mapping,
  values,
  coordinators,
  contest,
  proposal,
  additionalResources,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.coordinators}>
        <SectionTitle
          label={'Osoba do kontaktu w sprawie wniosku lub dotacji'}
          divider={false}
        />
        {(coordinators || []).map(el => (
          <div key={el['@id']}>
            {/*<span className={classes.coordinator_role}>{coordinator.role}</span> {coordinator.fullName}*/}
            <Grid container spacing={3}>
              <Grid item xs={7}>
                <DataInfo label={'Imię i Nazwisko'} data={el.fullName} />
              </Grid>
              <Grid item xs={5}>
                <DataInfo label={'Funkcja'} data={el.role} />
              </Grid>
            </Grid>
            <DataInfo
              label={'Adres mailowy do kontaktu w sprawie wniosku lub dotacji'}
              data={el.email}
            />
            <DataInfo
              label={'Numer telefonu do kontaktu w sprawie wniosku lub dotacji'}
              data={el.phone}
            />
            <BooleanType
              setValue={() => null}
              name={'rodo'}
              disabled={false}
              setError={() => null}
              error={false}
              renderError={false}
              label={''}
              labelNotTrans={
                'Informacje dotyczące przetwarzania danych osobowych znajdują się w Zasadach Konkursu Duże Granty zamieszczonych na stronie internetowej <a href="http://www.rownacszanse.org.pl" target="_blank">www.rownacszanse.org.pl</a>'
              }
              value={el.rodo}
              initialValue={el.rodo}
              validators={[]}
            />
          </div>
        ))}
      </div>
      <div>
        <FormTemplate
          mapping={mapping}
          values={values}
          contest={contest}
          proposal={proposal}
          additionalResources={additionalResources}
        />
      </div>
    </div>
  )
}

ApplicantStep.propTypes = {
  mapping: PropTypes.array,
  values: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  coordinators: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      role: PropTypes.string.isRequired,
    })
  ),
  contest: PropTypes.object,
  proposal: PropTypes.object,
  additionalResources: PropTypes.object.isRequired,
}
