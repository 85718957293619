import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'

const TEXTAREA_ROWS = 5
const TEXTAREA_MAX_ROWS = 80

const useStyles = makeStyles(theme => ({
  root: {
    '&> div': {
      borderRadius: 0,
      '&> fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
    '& input': {
      backgroundColor: 'white',
      padding: '4px 4px 4px 12px',
      height: 25,
    },
  },
  root_multiline: {
    '&> div': {
      backgroundColor: 'white',
      padding: '4px 4px 4px 12px',
    },
  },
  error: {
    marginLeft: 0,
  },
}))

export const StringType = ({
  name,
  type = 'string',
  value,
  error = false,
  renderError = false,
  validators,
  setValue,
  setError,
  rows = TEXTAREA_ROWS,
  rowsMax = TEXTAREA_MAX_ROWS,
  maxLength = null,
  placeholder = null,
  disabled = false,
  classes = {},
  max = null,
  min = null,
}) => {
  const handleChange = e => {
    let raw =
      e.target.value === ''
        ? null
        : maxLength
        ? e.target.value.slice(0, maxLength)
        : e.target.value

    let value =
      type === 'integer'
        ? parseInt(raw)
        : type === 'number'
        ? parseFloat(raw)
        : raw

    if (['nip', 'regon', 'krs', 'contractSubAccount'].includes(name)) {
      value = value && value.length > 0 ? value.replace(/\D/g, '') : ''
    }

    if (type === 'integer' || type === 'number') {
      if (max && value > max) {
        value = max
      }
      if (min && value < min) {
        value = min
      }
    }

    setValue(name, value)
    validateField(value, true)
  }

  const validateField = useCallback(
    (value, change = false) => {
      if (change && ['number', 'integer'].includes(type) && isNaN(value)) {
        setError(name, 'Niewłaściwy format danych')
      } else {
        if (!validators) {
          setError(name, false)

          return
        }

        const valid = validate(validators, value)

        if (Array.isArray(valid)) {
          setError(name, !valid[0].result && valid[0].message)
        } else {
          setError(name, !valid.result && valid.message)
        }
      }
    },
    [validators, setError, name, type]
  )

  useEffect(() => {
    validateField(
      value,
      (type === 'number' || type === 'integer') && isNaN(value)
    )
  }, [type, validateField, value])

  const additional =
    type === 'textarea'
      ? {
          rows,
          rowsMax,
          multiline: true,
          FormHelperTextProps: { style: { marginLeft: 0 } },
        }
      : {}

  const defaultClasses = useStyles()

  return (
    <TextField
      name={name}
      type={
        type === 'integer'
          ? 'number'
          : ['string', 'textarea'].includes(type)
          ? 'text'
          : type
      }
      value={value !== undefined ? value : ''}
      disabled={disabled}
      onChange={handleChange}
      error={renderError && !!error}
      helperText={translate(renderError && error)}
      placeholder={placeholder}
      variant="outlined"
      classes={{
        root: clsx(
          defaultClasses.root,
          type === 'textarea' && defaultClasses.root_multiline,
          classes.root
        ),
      }}
      FormHelperTextProps={{
        classes: {
          contained: defaultClasses.error,
        },
      }}
      inputProps={{
        ...(type === 'integer' ? { step: 1 } : {}),
        ...(maxLength ? { maxLength } : {}),
        ...(min ? { min } : {}),
        ...(max ? { max } : {}),
      }}
      {...additional}
    />
  )
}

StringType.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  rows: PropTypes.number,
  rowsMax: PropTypes.number,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
}
