import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { useStyles } from '../style'
import { Typography } from '@material-ui/core'

export const TextWithPhoto = ({ title, description, photoTitle, photoUrl }) => {
  const classes = useStyles()

  const cleanDescription = DOMPurify.sanitize(description, {
    USE_PROFILES: { html: true },
  })

  return (
    <div className={classes.block}>
      {title && (
        <Typography
          variant={'h3'}
          color={'primary'}
          className={classes.titleSection}
        >
          {title}
        </Typography>
      )}
      {description && (
        <div
          dangerouslySetInnerHTML={{ __html: cleanDescription }}
          className={classes.description}
        ></div>
      )}
      {photoTitle && (
        <Typography className={classes.photoTitle} variant={'h4'}>
          {photoTitle}
        </Typography>
      )}
      {photoUrl && (
        <div>
          <img
            src={`${process.env.REACT_APP_API_ENTRYPOINT}${photoUrl}`}
            className={classes.photo}
            alt={photoTitle}
          />
        </div>
      )}
    </div>
  )
}

TextWithPhoto.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  photoTitle: PropTypes.string,
  photoUrl: PropTypes.string,
}
