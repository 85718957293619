import { TaxIdType } from 'components/organizationData/form/fields'
import oganizationTypeSchema from '_schema/organizationType'
import provinceSchema from '_schema/province'
import populationSchema from '_schema/population'
import organizationTypeSchema from '_schema/organizationType'

const endpoints = {
  expert_edit: '/users/expert',
  foundation_edit: '/users/foundation',
  applicant_edit: '/users/applicant',
  admin_edit: '/users/admin',
  foundation_register: '/registration/foundation',
  applicant_register: '/registration/applicant',
  expert_register: '/registration/expert',
  admin_register: '/registration/admin',
  guest_register: '/registration/guest',
  user: '/users',
  expert: '/users/expert',
  admin_foundation: '/users/admin-foundation',
  applicant: '/users/applicant',
  applicant_set_email: '/users/applicant/setEmail',
  change_email: '/users/changeEmailStart',
  exper_activation: '/security/activationExpert/user/',
}

const definitions = {
  expert_edit: 'User:1f6172c261f430b215e249ebd584cd04-expert_edit:write',
  foundation_edit:
    'User:2af256625ab04ecadc0c7958cb4c44e6-foundation_edit:write',
  applicant_edit: 'User:51488f76acc6b19e2c9b0f77df5c8046-applicant_edit:write',
  admin_edit: 'User:9c8d265b4cf82a01ba2518bc4907a83e-admin_edit:write',
  foundation_register:
    'User:44f7df971b0bd232d51b4c202fcf2251-foundation_register:write',
  applicant_register:
    'User:6eb83b9b5156e45c5a32a4b1534999a2-applicant_register:write',
  expert_register:
    'User:c18fff655168d6daa89c8bf335a22885-expert_register:write',
  admin_register: 'User:9bd9ebdd3c49ba5b2d2b3a6d2a5673c0-admin_register:write',
  guest_register: 'User:9bd9ebdd3c49ba5b2d2b3a6d2a5673c0-guest_register:write',
  user: '',
  expert: 'User-user:read',
  admin_foundation: 'User-user:read',
  applicant: 'User-user:read',
  exper_activation: '',
}

const properties = {
  expert_edit: {
    names: {
      type: 'group',
      width: '600px',
      properties: {
        firstName: {
          type: 'string',
          label: 'Imię',
          width: '300px',
          validate: ['required'],
        },
        lastName: {
          type: 'string',
          label: 'Nazwisko',
          width: '300px',
          validate: ['required'],
        },
      },
    },
    email: {
      validate: ['required', 'email'],
      type: 'string',
      label: 'E-mail',
      width: '350px',
    },
    phone: {
      validate: ['phone'],
      type: 'string',
      label: 'Telefon',
      width: '350px',
    },
    organizationName: {
      type: 'string',
      label: 'Organizacja',
      width: '350px',
    },
  },
  foundation_edit: {
    names: {
      type: 'group',
      width: '600px',
      properties: {
        firstName: {
          type: 'string',
          label: 'Imię',
          width: '300px',
          validate: ['required'],
        },
        lastName: {
          type: 'string',
          label: 'Nazwisko',
          width: '300px',
          validate: ['required'],
        },
      },
    },
    email: {
      validate: ['required', 'email'],
      type: 'string',
      label: 'E-mail',
      width: '350px',
    },
    phone: {
      validate: ['phone'],
      type: 'string',
      label: 'Telefon',
      width: '350px',
    },
  },
  admin_edit: {
    names: {
      type: 'group',
      width: '600px',
      properties: {
        firstName: {
          type: 'string',
          label: 'Imię',
          width: '300px',
          validate: ['required'],
        },
        lastName: {
          type: 'string',
          label: 'Nazwisko',
          width: '300px',
          validate: ['required'],
        },
      },
    },
    email: {
      validate: ['required', 'email'],
      type: 'string',
      label: 'E-mail',
      width: '350px',
    },
    phone: {
      validate: ['phone'],
      type: 'string',
      label: 'Telefon',
      width: '350px',
    },
    roles: {
      type: 'choice',
      label: 'uprawnienia',
      valueType: 'array',
      choices: {
        ROLE_FOUNDATION: 'admin',
        ROLE_ADMIN: 'superadmin',
        ROLE_GUEST: 'gość'
      },
    },
    stat: {
      type: 'boolean',
      label: 'Aktywny',
    },
    receiveRelationsNotifications: {
      type: 'boolean',
      label: 'Odbiera powiadomienia o relacjach',
    },
  },
  applicant_edit: {
    taxId: {
      type: TaxIdType,
      choices: {
        nip: 'T_MODULE_ORGANIZATION_DATA_NIP',
        regon: 'T_MODULE_ORGANIZATION_DATA_REGON',
        krs: 'T_MODULE_ORGANIZATION_DATA_KRS',
      },
    },
    nip: {
      width: '145px',
    },
    regon: {
      width: '145px',
    },
    krs: {
      width: '145px',
    },
    email: {},
    organizationName: {},
    organizationType: {
      type: 'resource',
      endpoint: `${oganizationTypeSchema.endpoint}?order[title]=desc&pagination=false`,
      titleAccessor: 'title',
      width: '200px',
    },
    postOffice: {
      width: '350px',
    },
    postCode: {
      width: '85px',
    },
    address: {},
    city: {},
    commune: {},
    district: {},
    province: {
      type: 'resource',
      endpoint: `${provinceSchema.endpoint}?order[title]=desc&pagination=false`,
      titleAccessor: 'title',
      width: '200px',
    },
    phone: {
      type: 'string',
      label: 'T_FORM_FIELD_ORAGNIZATION_PHONE',
      width: '300px',
      validate: ['required', 'phone'],
    },
    population: {
      type: 'resource',
      endpoint: `${populationSchema.endpoint}?order[title]=desc&pagination=false`,
      titleAccessor: 'title',
      width: '200px',
    },
    populationDataSource: {},
    website: {},
    organizationCreationDate: {
      type: 'number',
      width: '250px',
      max: new Date().getFullYear(),
    },
    organizationGoals: {
      type: 'textarea',
      rows: 8,
      rowsMax: 8,
    },
    statutoryActivities: {
      type: 'textarea',
      rows: 8,
      rowsMax: 8,
    },
  },
  foundation_register: {
    email: {
      validate: ['required', 'email'],
      type: 'string',
      label: 'E-mail',
      width: '300px',
    },
    plainPassword: {
      type: 'passwordRepeat',
      label: 'T_FORM_FIELD_PASS',
      validate: ['required', 'password'],
    },
  },
  applicant_register: {
    email: {
      validate: ['required', 'email'],
      type: 'string',
      label: 'T_ORAGNIZATION_EMAIL',
      width: '300px',
    },
    plainPassword: {
      type: 'passwordRepeat',
      label: 'T_FORM_FIELD_PASS',
      validate: ['required', 'password'],
    },
    phone: {
      type: 'string',
      label: 'T_FORM_FIELD_ORAGNIZATION_PHONE',
      width: '300px',
      validate: ['required', 'phone'],
    },
    organizationInfo: {
      title: 'T_FORM_FIELD_ORAGNIZATION_TITLE',
      type: 'sectionTitle',
      divider: true,
    },
    krstype: {
      type: 'krstype',
      values: ['nip', 'regon', 'krs'],
    },
    organizationName: {
      type: 'string',
      label: 'T_FORM_FIELD_ORAGNIZATION_NAME',
      width: '440px',
      validate: ['required'],
    },
    organizationType: {
      label: 'Typ organizacji',
      type: 'resource',
      titleAccessor: 'title',
      width: '250px',
      validate: ['required'],
      endpoint: `${organizationTypeSchema.endpoint}?pagination=false`,
    },
    organizationAddress: {
      title: 'T_FORM_FIELD_ORAGNIZATION_ADDRESS',
      type: 'sectionTitle',
      divider: true,
    },
    post: {
      type: 'group',
      width: '600px',
      properties: {
        postOffice: {
          label: 'T_FORM_FIELD_POST_OFFICE',
          type: 'string',
          validate: ['required'],
          column: 8,
        },
        postCode: {
          label: 'T_FORM_FIELD_POST_CODE',
          type: 'string',
          validate: ['required', 'postCode'],
          column: 4,
        },
      },
    },
    address: {
      label: 'T_FORM_FIELD_ADDRESS',
      type: 'string',
      width: '300px',
      validate: ['required'],
    },
    city: {
      label: 'T_FORM_FIELD_CITY',
      type: 'string',
      width: '300px',
      validate: ['required'],
    },
    commune: {
      label: 'T_FORM_FIELD_COMMUNE',
      type: 'string',
      width: '300px',
      validate: ['required'],
    },
    district: {
      label: 'T_FORM_FIELD_DISTRICT',
      type: 'string',
      width: '300px',
      validate: ['required'],
    },
    province: {
      label: 'T_FORM_FIELD_PROVICNES',
      type: 'resource',
      endpoint: `${provinceSchema.endpoint}?order[title]=desc&pagination=false`,
      titleAccessor: 'title',
      width: '250px',
      validate: ['required'],
    },
  },
  expert_register: {
    email: {
      validate: ['required', 'email'],
      type: 'string',
      label: 'E-mail',
      width: '300px',
    },
    firstName: {
      type: 'string',
      label: 'Imię',
      width: '300px',
      validate: ['required'],
    },
    lastName: {
      type: 'string',
      label: 'Nazwisko',
      width: '300px',
      validate: ['required'],
    },
  },
  admin_register: {
    email: {
      validate: ['required', 'email'],
      type: 'string',
      label: 'E-mail',
      width: '300px',
    },
    plainPassword: {
      type: 'passwordRepeat',
      label: 'T_FORM_FIELD_PASS',
      validate: ['required', 'password'],
    },
  },
  guest_register: {
    email: {
      validate: ['required', 'email'],
      type: 'string',
      label: 'E-mail',
      width: '300px',
    },
    plainPassword: {
      type: 'passwordRepeat',
      label: 'T_FORM_FIELD_PASS',
      validate: ['required', 'password'],
    },
  },
  user: {},
  expert: {
    firstName: {
      description: {
        text: 'Imię',
      },
    },
    lastName: {
      description: {
        text: 'Nazwisko',
      },
    },
    email: {
      description: {
        text: 'E-mail',
      },
    },
    phone: {
      description: {
        text: 'Telefon',
      },
    },
    organizationName: {
      description: {
        text: 'Organizacja',
      },
    },
    expertCurrentContestsCount: {
      description: {
        text: 'Przypisane konkursy',
      },
    },
  },
  admin_foundation: {
    firstName: {
      description: {
        text: 'Imię',
      },
    },
    lastName: {
      description: {
        text: 'Nazwisko',
      },
    },
    email: {
      description: {
        text: 'E-mail',
      },
    },
    phone: {
      description: {
        text: 'Telefon',
      },
    },
    roles: {
      description: {
        text: 'Role',
      },
    },
  },
  applicant: {
    organizationName: {
      description: {
        text: 'Pełna nazwa organizacji',
      },
    },
    email: {
      description: {
        text: 'E-mail',
      },
    },
    organizationType: {
      description: {
        text: 'Typ organizacji',
      },
    },
    province: {
      description: {
        text: 'Województwo',
      },
    },
    city: {
      description: {
        text: 'Miejscowość',
      },
    },
    population: {
      description: {
        text: 'Liczba mieszkańców',
      },
    },
    applicantProposalsFinishedCount: {
      description: {
        text: 'Zrealizowane projekty',
      },
    },
    applicantProposalsAppliedCount: {
      description: {
        text: 'Złożone projekty',
      },
    },
    organizationLastProjectDate: {
      description: {
        text: 'Ostatni projekt',
      },
    },
  },
  exper_activation: {
    plainPassword: {
      type: 'passwordRepeat',
      label: 'T_FORM_FIELD_PASS',
      validate: ['required', 'password'],
    },
  },
}

const schema = type => ({
  endpoint: endpoints[type],
  resource: {
    definition: definitions[type],
    properties: {
      ...properties[type],
    },
  },
})

export default schema
