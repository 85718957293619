import {
  CoordinatorCollectionType,
  FormTemplateType,
  BudgetCollectionType,
  AttachmentCollectionType,
} from 'pages/applicant/Proposals/components/form/fields'
import {
  ContractRepresentativeCollectionType,
  ContractAttachmentCollectionType,
} from 'pages/applicant/AppliedProposals/components/form/fields'
import { TaxIdType } from 'components/organizationData/form/fields'
import { BudgetChargesCollectionType } from 'pages/applicant/InProgressProposals/Budget/StatementBudget/components/BudgetCollectionType'
import { BudgetChangesCollectionType } from 'pages/applicant/InProgressProposals/Budget/ChangesBudget/components'
import { DetailsCollectionType } from 'pages/applicant/InProgressProposals/ProjectSchedule/Details/components'

export const APPLICATION_STATUS_DRAFT = 'DRAFT' // Wniosek szkic
export const APPLICATION_STATUS_APPLIED = 'APPLIED' // Wniosek złożony

export const ACCEPTATION_STATUS_SUBSIDIZED = 'SUBSIDIZED' // dotowany
export const ACCEPTATION_STATUS_REJECTED = 'REJECTED' // odrzucony
export const ACCEPTATION_STATUS_RESERVE = 'RESERVE' // rezerwowy

export const RECOMMENDATION_STATUS_YES = 'YES' // Zarekomendowany
export const RECOMMENDATION_STATUS_NO = 'NO' // Niezarekomendowany

export const MONITORING_RECOMMENDATION_YES = 'MONITORING_RECOMMENDATION_YES' // Zarekomendowany do monitorowania

export const RATED_YES = 'RATED_YES' // Posiada jakąkolwiek ocenę
export const RATED_NO = 'RATED_NO' // Nie posiada żadnej oceny

export const TRAINING_YES = 'TRAINING_YES' // Posiada jakiekolwiek szkolenia
export const TRAINING_NO = 'TRAINING_NO' // Posiada ankiety wypełnione ale nieocenione

export const CONTRACT_YES = 'CONTRACT_YES' // Rozpoczęto proces sporządzania umowy
export const CONTRACT_NO = 'CONTRACT_NO' // Przed rozpoczęciem procesu sporządzania umowy

export const REALIZATION_STATUS_FINISHED = 'FINISHED' // Zrealizowany
export const REALIZATION_STATUS_INPROGRESS = 'INPROGRESS' // Wniosek w realizacji
export const REALIZATION_STATUS_CANCELED = 'CANCELED' // Wniosek anulowany
export const REALIZATION_STATUS_REJECTED = 'REJECTED' // Wniosek odrzucony

export const BUDGET_CHANGE_STATUS_NEW = 'NEW' //zgłoszona
export const BUDGET_CHANGE_STATUS_PENDING = 'PENDING' //do wyjasnienia
export const BUDGET_CHANGE_STATUS_ANSWERED = 'ANSWERED' //po wyjaśnieniu
export const BUDGET_CHANGE_STATUS_REJECTED = 'REJECTED' //odrzucona
export const BUDGET_CHANGE_STATUS_ACCEPTED = 'ACCEPTED' //zaakceptowana

export const SCHEDULE_CHANGE_STATUS_NEW = 'NEW'
export const SCHEDULE_CHANGE_STATUS_PENDING = 'PENDING'
export const SCHEDULE_CHANGE_STATUS_ANSWERED = 'ANSWERED'
export const SCHEDULE_CHANGE_STATUS_REJECTED = 'REJECTED'
export const SCHEDULE_CHANGE_STATUS_ACCEPTED = 'ACCEPTED'
export const SCHEDULE_CHANGE_STATUS_AUTO_DRAFT = 'AUTO_DRAFT'

export const RELATION_STATUS_DRAFT = 'DRAFT'
export const RELATION_STATUS_PENDING = 'PENDING'
export const RELATION_STATUS_REJECTED = 'REJECTED'
export const RELATION_STATUS_ACCEPTED = 'ACCEPTED'
export const RELATION_STATUS_RETURNED = 'RETURNED'

export const QUARTERLY_DECLARATION_STATUS_NOTREPORTED = 'NOTREPORTED'
export const QUARTERLY_DECLARATION_STATUS_REPORTED = 'REPORTED'
export const QUARTERLY_DECLARATION_STATUS_ACCEPTED = 'ACCEPTED'

export const REALIZATION_DATE_CHANGE_STATUS_PENDING = 'PENDING'
export const REALIZATION_DATE_CHANGE_STATUS_VERIFIED = 'VERIFIED' //do wyjaśnienia
export const REALIZATION_DATE_CHANGE_STATUS_ANSWERED = 'ANSWERED' //po wyjaśnieniu
export const REALIZATION_DATE_CHANGE_STATUS_REJECTED = 'REJECTED'
export const REALIZATION_DATE_CHANGE_STATUS_ACCEPTED = 'ACCEPTED'

export const REPORT_END_DATE_CHANGE_STATUS_PENDING = 'PENDING'
export const REPORT_END_DATE_CHANGE_STATUS_REJECTED = 'REJECTED'
export const REPORT_END_DATE_CHANGE_STATUS_ACCEPTED = 'ACCEPTED'

export const CONTRACT_STATUS_APPLIED = 'APPLIED'
export const CONTRACT_STATUS_WAITING = 'WAITING'
export const CONTRACT_STATUS_TO_CORRECT = 'TO_CORRECT'
export const CONTRACT_STATUS_CONFIRMED = 'CONFIRMED'

export const PROJECT_END_REJECTED = 'REJECTED'
export const PROJECT_END_CANCELED = 'CANCELED'
export const PROJECT_END_FINISHED = 'FINISHED'

export const CONTENT_REPORT_STATUS_DRAFT = 'DRAFT'
export const CONTENT_REPORT_STATUS_NEW = 'NEW'
export const CONTENT_REPORT_STATUS_PENDING = 'PENDING'
export const CONTENT_REPORT_STATUS_RETURNED = 'RETURNED'
export const CONTENT_REPORT_STATUS_REJECTED = 'REJECTED'
export const CONTENT_REPORT_STATUS_ACCEPTED = 'ACCEPTED'

const schema = {
  endpoint: '/proposals',
  endpoint_proposal_create: '/contest/proposalCreate',
  endpoint_applicant_contest: '/applicantContestProposals',
  endpoint_applicant_apply: '/proposals/applicationStatus/:id',
  endpoint_foundation_user: '/applicantProposals',
  endpoint_foundation_item: '/proposals/details',
  endpoint_contract_edit: '/proposals/contractEdit/:id',
  endpoint_contract_status_edit: '/proposals/contractStatus/:id',
  endpoint_contract_foundation_upload: '/proposals/contractUploadFile/:id',
  endpoint_download_contract_pdf: '/proposals/contract/:id/download',
  endpoint_realization_date_requests: '/proposal_realization_date_requests',
  endpoint_schedule_requests: '/proposal_schedule_requests',
  endpoint_budget_charges: '/proposal_budget_charges',
  endpoint_budget_change_requests: '/proposal_budget_change_requests',
  endpoint_budget_change_history: '/proposal_budget_change_histories',
  endpoint_budgets: '/proposal_budgets',
  endpoint_budget_current: '/proposal_budget/current',
  endpoint_acceptation_status: '/proposals/acceptationStatus',
  endpoint_recommendation_status: '/proposals/recommendationStatus',
  endpoint_expert_proposals: '/expertProposals',
  endpoint_expert_substantive_rates: '/proposals/substantiveRates',
  endpoint_end_date_requests: '/proposal_end_date_requests',
  endpoint_partialReport_edit: '/proposals/partialReportEdit/:id',
  endpoint_partialReport_upload_file: '/proposals/partialReportUploadFile/:id',
  endpoint_partialReport_download: '/proposals/partialReport/:id/download',
  endpoint_partialReport_status: '/proposals/partialReportStatus/:id',
  endpoint_finalReport_edit: '/proposals/finalReportEdit/:id',
  endpoint_finalReport_upload_file: '/proposals/finalReportUploadFile/:id',
  endpoint_financialReport_upload_file:
    '/proposal_financial_reports/uploadReportFile/:id',
  endpoint_finalReport_download: '/proposals/finalReport/:id/download',
  endpoint_finalReport_status: '/proposals/finalReportStatus/:id',
  endpoint_pdf_download: '/proposal/pdfDownload/',
  resource: {
    definition: 'Proposal-proposal:item_read',
    properties: {
      title: {
        width: '600px',
        validate: ['required'],
      },
      coordinators: {
        type: CoordinatorCollectionType,
        defaultValue: [],
      },
      applicationApplicantFormValues: {
        type: FormTemplateType,
        defaultValue: {},
      },
      applicationProjectFormValues: {
        type: FormTemplateType,
        defaultValue: {},
      },
      budgets: {
        type: BudgetCollectionType,
        defaultValue: [{}],
      },
      attachments: {
        type: AttachmentCollectionType,
        defaultValue: [],
      },
      subsidyAmount: {
        validate: ['required'],
        type: 'integer',
      },
    },
  },
  resource_partialReport_edit: {
    definition: 'Proposal-proposal:applicant_partial_report_edit_read',
    properties: {
      partialReportFormValues: {
        type: FormTemplateType,
        defaultValue: {},
      },
    },
  },
  resource_partialReport_upload: {
    definition: 'Proposal-proposal:put_partial_report_upload_file_read',
    properties: {
      partialReportFile: {
        type: 'file',
        endpoint: '/files',
        validate: ['maxSize', 'required'],
      },
    },
  },
  resource_finalReport_edit: {
    definition: 'Proposal-proposal:applicant_final_report_edit_read',
    properties: {
      finalReportFormValues: {
        type: FormTemplateType,
        defaultValue: {},
      },
    },
  },
  resource_finalReport_upload: {
    definition: 'Proposal-proposal:put_final_report_upload_file_read',
    properties: {
      finalReportFile: {
        type: 'file',
        endpoint: '/files',
        validate: ['maxSize', 'required'],
      },
    },
  },
  resource_financialReport_upload: {
    definition:
      'ProposalFinancialReport-proposal_financial_report:put_uploaded_report_upload_file_write',
    properties: {
      uploadedReportFile: {
        type: 'file',
        endpoint: '/files',
        validate: ['maxSize', 'required'],
      },
    },
  },
  resource_trainings: {
    definition: 'Proposal-proposal:item_read',
    properties: {
      proposalTrainingFormValues: {
        type: FormTemplateType,
        defaultValue: {},
      },
    },
  },
  resource_budget_charges: {
    definition: 'Proposal-proposal:item_read',
    properties: {
      charges: {
        type: BudgetChargesCollectionType,
        defaultValue: [],
      },
    },
  },

  resource_budget_change_requests_answer: {
    definition: 'Proposal-proposal:item_read',
    properties: {
      answer: {
        type: 'textarea',
        rows: 8,
        rowsMax: 8,
        width: '400px',
      },
    },
  },

  resource_schedule_requests_answer: {
    definition: 'Proposal-proposal:item_read',
    properties: {
      answer: {
        type: 'textarea',
        rows: 8,
        rowsMax: 8,
        width: '400px',
      },
    },
  },

  resource_budget_change_requests: {
    definition: 'Proposal-proposal:item_read',
    properties: {
      budgetChangeHistories: {
        type: BudgetChangesCollectionType,
        defaultValue: [],
      },
      description: {
        type: 'textarea',
        rows: 8,
        rowsMax: 8,
        width: '400px',
      },
      proposal: {},
    },
  },

  resource_contract_data: {
    definition: 'Proposal-proposal:applicant_contract_edit_read',
    properties: {
      contractRepresentatives: {
        type: ContractRepresentativeCollectionType,
        defaultValue: [],
      },
      contractSubAccount: {
        validate: ['required', 'bankaccount'],
      },
      taxId: {
        type: TaxIdType,
        choices: {
          nip: 'T_MODULE_ORGANIZATION_DATA_NIP',
          regon: 'T_MODULE_ORGANIZATION_DATA_REGON',
          krs: 'T_MODULE_ORGANIZATION_DATA_KRS',
        },
      },
      contractNip: {
        width: '145px',
      },
      contractRegon: {
        width: '145px',
      },
      contractKrs: {
        width: '145px',
      },
      contractOrganizationName: {
        validate: ['required'],
      },
      contractOrganizationType: {
        type: 'resource',
        titleAccessor: 'title',
        width: '200px',
        validate: ['required'],
      },
      contractPostOffice: {
        width: '350px',
        validate: ['required'],
      },
      contractPostCode: {
        width: '85px',
        validate: ['required'],
      },
      contractAddress: {
        validate: ['required'],
      },
      contractCity: {
        validate: ['required'],
      },
      contractCommune: {
        validate: ['required'],
      },
      contractDistrict: {
        validate: ['required'],
      },
      contractProvince: {
        type: 'resource',
        titleAccessor: 'title',
        width: '200px',
        validate: ['required'],
      },
    },
  },
  resource_contract_upload: {
    definition: 'Proposal-proposal:item_read',
    properties: {
      contractAttachments: {
        type: ContractAttachmentCollectionType,
        defaultValue: [],
      },
    },
  },
  resource_contract_foundation_upload: {
    definition: 'Proposal-proposal:put_contract_upload_file_read',
    properties: {
      contractFile: {
        type: 'file',
        endpoint: '/files',
        validate: ['maxSize', 'required'],
      },
    },
  },
  subresources: {
    coordinator: {
      properties: {
        fullName: {},
        role: {},
      },
      budget: {
        properties: {
          title: {},
          calculationMethod: {},
          ownContributionAmount: {},
          subsidyAmount: {},
        },
      },
      attachment: {
        title: {},
        file: {},
      },
    },
  },
  resource_foundation_user: {
    definition: 'Proposal-proposal:read',
    properties: {
      contest: {
        description: {
          text: 'Konkurs',
        },
      },
      'contest.contestEdition': {
        description: {
          text: 'Edycja',
        },
      },
      title: {
        description: {
          text: 'Projekt',
        },
      },
      applicationStatus: {
        description: {
          text: 'Status aplikacji',
        },
      },
      acceptationStatus: {
        description: {
          text: 'Status akceptacji',
        },
      },
      recommendationStatus: {
        description: {
          text: 'Status rekomendacji',
        },
      },
      realizationStatus: {
        description: {
          text: 'Status realizacji',
        },
      },
      budgetsTotalAmount: {
        description: {
          text: 'Dotacja',
        },
      },
    },
  },
  resource_proposal_contest: {
    definition: 'Proposal-proposal:list_read',
    properties: {
      proposalNumber: {
        description: {
          text: 'Numer projektu',
        },
      },
      subsidyNumber: {
        description: {
          text: 'Numer umowy',
        },
      },
      title: {
        description: {
          text: 'Projekt',
        },
      },
      organizationName: {
        description: {
          text: 'Organizacja',
        },
      },
      acceptationStatus: {
        description: {
          text: 'Status akceptacji',
        },
      },
      applicationStatus: {
        description: {
          text: 'Status aplikacji',
        },
      },
      contractStatus: {
        description: {
          text: 'Status umowy',
        },
      },
      finalReportStatus: {
        description: {
          text: 'Status raportu końcowego',
        },
      },
      formalRateStatus: {
        description: {
          text: 'Ocena formalna',
        },
      },
      internalRatePoints: {
        description: {
          text: 'Ocena wewnętrzna',
        },
      },
      substantiveRatePoints: {
        description: {
          text: 'Ocena merytoryczna',
        },
      },
      realizationStatus: {
        description: {
          text: 'Status realizacji',
        },
      },
      recommendationStatus: {
        description: {
          text: 'Status rekomendacji',
        },
      },
      budgetChangeStatus: {
        description: {
          text: 'Złożone zmiany budgetu',
        },
      },
      scheduleChangeStatus: {
        description: {
          text: 'Złożone zmiany harmonogramu',
        },
      },
      applicantEmail: {
        description: {
          text: 'Adres email wnioskodawcy',
        },
      },
    },
  },
  resource_schedule_requests: {
    definition: 'ProposalScheduleRequest-proposal_schedule_request:read',
    properties: {
      schedule: {
        type: DetailsCollectionType,
        defaultValue: [],
      },
      description: {
        type: 'textarea',
        rows: 8,
        rowsMax: 8,
        width: '400px',
        validate: ['required'],
      },
      proposal: {},
    },
  },
  resource_realization_date: {
    definition:
      'ProposalRealizationDateRequest-proposal_realization_date_request:read',
    properties: {
      dateFrom: {
        type: 'date',
        width: '150px',
        validate: ['required'],
      },
      dateTo: {
        type: 'date',
        width: '150px',
        validate: ['required'],
      },
      description: {
        type: 'textarea',
        rows: 8,
        rowsMax: 8,
        width: '400px',
        validate: ['required'],
      },
      proposal: {},
    },
  },
  resource_realization_date_answer: {
    definition:
      'ProposalRealizationDateRequest-proposal_realization_date_request:read',
    properties: {
      answer: {
        type: 'textarea',
        rows: 8,
        rowsMax: 8,
        width: '400px',
        validate: ['required'],
      },
    },
  },
  resource_end_date_requests: {
    definition: 'ProposalEndDateRequest-proposal_end_date_request:read',
    properties: {
      dateEnd: {
        type: 'date',
        width: '150px',
        validate: ['required'],
      },
      description: {
        type: 'textarea',
        rows: 8,
        rowsMax: 8,
        width: '400px',
        validate: ['required'],
      },
      proposal: {},
    },
  },
  resource_visit: {
    definition: 'Proposal-proposal:list_read',
    properties: {
      userVisitationAcceptance: {
        type: 'boolean',
        validate: ['required'],
      },
      coordinatorPresenceConfirmation: {
        type: 'boolean',
        validate: ['required'],
      },
      projectGroupPresenceConfirmation: {
        type: 'boolean',
        validate: ['required'],
      },
      visitationRulesAcceptation: {
        type: 'boolean',
        validate: ['required'],
      },
      address: {
        type: 'textarea',
        rows: 8,
        rowsMax: 8,
        width: '400px',
        validate: ['required'],
      },
      description: {
        type: 'textarea',
        rows: 8,
        rowsMax: 8,
        width: '400px',
      },
      status: {},
    },
  },
}

export default schema
