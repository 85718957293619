import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { ResourceType } from 'components/applicant/form/fields/ResourceType'
import { StringType } from 'components/applicant/form/fields/StringType'
import { translate } from 'core/_helpers/translate'
import populationSchema from '_schema/population'

const useStyles = makeStyles({
  row: {
    marginTop: 8,
  },
  label: {
    marginBottom: 5,
  },
  select: {
    width: 220,
  },
  input: {
    width: 350,
  },
  input_date: {
    width: 150,
  },
})

export const CompanyAdditionalInfoBlock = ({
  proposal,
  values,
  errors,
  renderError,
  globalRenderError,
  setValue,
  setError,
  disabled,
}) => {
  const validators = useMemo(
    () => ({
      population: null,
      populationDataSource: null,
      website: null,
      organizationCreationDate: null,
    }),
    []
  )

  const [first, setFirst] = useState(false)

  useEffect(() => {
    if (!first) {
      if (
        [undefined, null].includes(values?.population) &&
        proposal.population
      ) {
        setValue('population', proposal.population)
      }

      if (
        [undefined, null].includes(values?.populationDataSource) &&
        proposal.populationDataSource
      ) {
        setValue('populationDataSource', proposal.populationDataSource)
      }

      if ([undefined, null].includes(values?.website) && proposal.website) {
        setValue('website', proposal.website)
      }

      if (
        [undefined, null].includes(values?.organizationCreationDate) &&
        proposal.organizationCreationDate
      ) {
        setValue('organizationCreationDate', proposal.organizationCreationDate)
      }
    }
    setFirst(true)
  }, [values, proposal, setValue, first])

  const classes = useStyles()

  return (
    <div>
      <div className={classes.row}>
        <div className={classes.label}>
          {translate('T_MODULE_ORGANIZATION_DATA_POPULATION')}
        </div>
        <ResourceType
          name="population"
          endpoint={`${populationSchema.endpoint}?order[title]=desc&pagination=false`}
          titleAccessor="title"
          value={values?.population}
          error={errors?.population}
          renderError={globalRenderError && renderError?.population}
          disabled={disabled}
          validators={validators.population}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.select,
          }}
        />
      </div>
      <div className={classes.row}>
        <div className={classes.label}>
          {translate('T_MODULE_ORGANIZATION_DATA_POPULATION_DATA_SOURCE')}
        </div>
        <StringType
          type="string"
          name="populationDataSource"
          value={values?.populationDataSource}
          error={errors?.populationDataSource}
          renderError={globalRenderError && renderError?.populationDataSource}
          disabled={disabled}
          validators={validators.populationDataSource}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input,
          }}
        />
      </div>
      <div className={classes.row}>
        <div className={classes.label}>
          {translate('T_MODULE_ORGANIZATION_DATA_WEBSITE')}
        </div>
        <StringType
          type="string"
          name="website"
          value={values?.website}
          error={errors?.website}
          renderError={globalRenderError && renderError?.website}
          disabled={disabled}
          validators={validators.website}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input,
          }}
        />
      </div>
      <div className={classes.row}>
        <div className={classes.label}>
          {translate('T_MODULE_ORGANIZATION_DATA_ORGANIZATION_CREATION_DATE')}
        </div>
        <StringType
          type={'number'}
          max={new Date().getFullYear()}
          name="organizationCreationDate"
          value={values?.organizationCreationDate}
          error={errors?.organizationCreationDate}
          renderError={
            globalRenderError && renderError?.organizationCreationDate
          }
          disabled={disabled}
          validators={validators.organizationCreationDate}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input_date,
          }}
        />
      </div>
    </div>
  )
}

CompanyAdditionalInfoBlock.propTypes = {
  proposal: PropTypes.object.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
