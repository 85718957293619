import { makeStyles } from '@material-ui/styles'

export const useAttachmentItemTypeStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    marginBottom: 10,
    display: 'flex',
    '&> *:first-child': {
      marginRight: 30,
    },
  },
  label: {
    marginBottom: 10,
  },
  input: {
    width: 350,
  },
  file_wrapper: {
    marginTop: 28,
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}))
