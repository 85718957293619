const CONTEST_PAGE_BLOCK_TYPE_FILES = 'FILES'
export const textWithFiles = () => ({
  name: CONTEST_PAGE_BLOCK_TYPE_FILES,
  label: 'T_GENERAL_BLOCK_TYPE_PACKAGE_FILE',
  properties: {
    type: {
      type: 'hidden',
      defaultValue: CONTEST_PAGE_BLOCK_TYPE_FILES,
    },
    title: {
      type: 'string',
      label: 'Tytuł bloku',
      width: '650px',
    },
    description: {
      label: 'Treść',
      type: 'tinymce',
      fullWidth: true,
      formWidth: 650,
    },
    files: {
      type: 'subform',
      property: 'files',
    },
  },
})
