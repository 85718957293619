import React from 'react'
import { Grid } from '@material-ui/core'
import { ContestCollection, ProposalCollection } from './components'

export const Home = () => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <ContestCollection />
      </Grid>
      <Grid item xs={6}>
        <ProposalCollection />
      </Grid>
    </Grid>
  )
}
