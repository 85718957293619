import React from 'react'
import PropTypes from 'prop-types'
import { FieldError } from './_helpers/fieldError'
import { fields } from 'core/components/form/fields'

export const FieldWrapper = ({
  name,
  label = '',
  property,
  resource,
  state,
  setValue,
  setError,
  formUrl = null,
  formMethod = null,
  provideAdditionalValues = [],
  extraStuff = [],
  validators = null,
  ...rest
}) => {
  if (typeof property.type === 'string' && !fields[property.type]) {
    throw new FieldError(property.type)
  }

  const FieldComponent =
    typeof property.type === 'string' ? fields[property.type] : property.type

  const { type, validate, disabled, ...fieldRest } = property

  const additionalValues = Object.assign(
    {},
    ...provideAdditionalValues.map(name => ({
      [name]: {
        current: state.values[name],
        initial:
          resource?.[name] !== undefined
            ? resource?.[name]
            : property.defaultValue !== undefined
            ? property.defaultValue
            : null,
      },
    }))
  )

  return (
    <FieldComponent
      name={name}
      label={label}
      type={type}
      initialValue={
        resource?.[name] !== undefined
          ? resource?.[name]
          : property.defaultValue !== undefined
          ? property.defaultValue
          : null
      }
      value={state.values[name]}
      error={state.errors[name]}
      renderError={state.renderError[name]}
      disabled={disabled || state?.isProcessing}
      validators={validators ? validators : validate}
      setValue={setValue}
      setError={setError}
      isFormSubmitted={state.isSubmitted}
      formUrl={formUrl}
      formMethod={formMethod}
      additionalValues={additionalValues}
      resource={resource}
      extraStuff={extraStuff}
      {...rest}
      {...fieldRest}
    />
  )
}

FieldWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  property: PropTypes.object.isRequired,
  resource: PropTypes.object,
  state: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  formUrl: PropTypes.string,
  formMethod: PropTypes.string,
  provideAdditionalValues: PropTypes.arrayOf(PropTypes.string),
  validators: PropTypes.array,
}
