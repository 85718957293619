import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { makeStyles } from '@material-ui/core/styles'
import { isEnableBudgetMenageAdv } from '_helpers/isEnableBudgetMenageAdv'
import { useContestBudgetCategories } from '_helpers/useContestBudgetCategories'

const useStyles = makeStyles(theme => ({
  tableHead: {
    borderBottom: `1px solid ${theme.palette.separator}`,
  },
  tableFooter: {
    borderTop: `2px solid ${theme.palette.separator}`,
  },
  tableFooterTitle: {
    color: theme.palette.primary.main,
    fontSize: 18,
    border: 0,
    paddingBottom: theme.spacing(3),
    fontWeight: 600,
  },
  tableHeadCell: {
    color: theme.elements.table.tHeadC,
    fontSize: 16,
    fontStyle: 'italic',
    border: 0,
    paddingBottom: theme.spacing(3),
  },
  tableCell: {
    border: 0,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(16),
  },
  table: {
    marginBottom: theme.spacing(3),
  },
}))

export const BudgetFormValues = ({ resource }) => {
  const classes = useStyles()
  const [summary, setSummary] = useState({
    ownContributionAmount: 0,
    subsidyAmount: 0,
  })

  const budgetCategories = useContestBudgetCategories(resource.contest.uuid)

  useEffect(() => {
    const summaryTemp = {
      ownContributionAmount: 0,
      subsidyAmount: 0,
    }
    resource.budgets.forEach(el => {
      summaryTemp.ownContributionAmount += Number(el.ownContributionAmount)
      summaryTemp.subsidyAmount += Number(el.subsidyAmount)
    })
    setSummary(summaryTemp)
  }, [resource.budgets])

  const [enableBudgetMenage] = useState(
    isEnableBudgetMenageAdv(resource.budgets, budgetCategories.data)
  )

  const [budgetByCategory, setBudgetByCategory] = useState({})

  useEffect(() => {
    if (enableBudgetMenage && budgetCategories.data.length > 0) {
      const obj = {}
      budgetCategories.data.forEach(el => {
        obj[el.id] = {
          budgets: [],
          summary: 0,
          title: el.title,
        }
      })
      obj[0] = {
        budgets: [],
        summary: 0,
        title: 'Nieprzypisane do kategorii',
      }
      resource.budgets.forEach(el => {
        const catId =
          el.categoryId === null || el.categoryId === undefined
            ? 0
            : el.categoryId
        obj[catId].budgets.push(el)
        obj[catId].summary += Number(el.subsidyAmount)
      })
      setBudgetByCategory(obj)
    }
  }, [
    resource.budgets,
    resource.subsidyAmount,
    enableBudgetMenage,
    budgetCategories.data,
  ])

  const TableRowBudget = ({ budget }) => {
    if (
      (budget.title !== '' &&
        budget.title !== '0' &&
        budget.title !== null &&
        budget.title !== 0) ||
      budget.subsidyAmount > 0
    ) {
      return (
        <TableRow>
          <TableCell className={classes.tableCell}>{budget.title}</TableCell>
          <TableCell className={classes.tableCell}>
            {budget.calculationMethod}
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            {budget.ownContributionAmount} zł
          </TableCell>
          <TableCell className={classes.tableCell} align="right">
            {budget.subsidyAmount} zł
          </TableCell>
        </TableRow>
      )
    }
    return <></>
  }

  const CategoryBudget = ({ category }) => {
    console.log('category', category)
    return (
      <>
        <TableRow>
          <TableCell className={classes.tableCell}>
            <Typography variant="h3" component="h5">
              {category.title}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableCell}></TableCell>
          <TableCell className={classes.tableCell}></TableCell>
          <TableCell className={classes.tableCell} align="right">
            <Typography variant="h3" component="h5">
              {category.summary.toFixed(2)} zł{' '}
              {resource.subsidyAmount && (
                <>
                  (
                  {Number(
                    (category.summary * 100) / resource.subsidyAmount
                  ).toFixed(0)}
                  %)
                </>
              )}
            </Typography>
          </TableCell>
        </TableRow>
        {category.budgets.map(el => (
          <TableRowBudget key={el['@id']} budget={el} />
        ))}
      </>
    )
  }

  return (
    <>
      <Table
        size={'small'}
        aria-label={'Bieżące konkursy'}
        className={classes.table}
      >
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.tableHeadCell}>
              {translate('Rodzaje kosztów')}
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              {translate('Sposób kalkulacji')}
            </TableCell>
            <TableCell className={classes.tableHeadCell} align="right">
              {translate('Wkład własny oraz  z innych źródeł')}
            </TableCell>
            <TableCell className={classes.tableHeadCell} align="right">
              {translate('Wnioskowane od FCP')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {enableBudgetMenage ? (
            <>
              {Object.keys(budgetByCategory).map(key => (
                <CategoryBudget key={key} category={budgetByCategory[key]} />
              ))}
            </>
          ) : (
            <>
              {resource.budgets.map(el => (
                <TableRowBudget key={el['@id']} budget={el} />
              ))}
            </>
          )}
        </TableBody>
        <TableFooter className={classes.tableFooter}>
          <TableRow>
            <TableCell className={classes.tableFooterTitle}>
              {translate('Razem:')}
            </TableCell>
            <TableCell className={classes.tableHeadCell}></TableCell>
            <TableCell className={classes.tableHeadCell} align="right">
              {summary.ownContributionAmount.toFixed(2)} zł
            </TableCell>
            <TableCell className={classes.tableHeadCell} align="right">
              {summary.subsidyAmount.toFixed(2)} zł
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </>
  )
}
