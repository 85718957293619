import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Paper as OriginalPaper } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'transparent',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  rootWithPadding: {
    padding: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(3),
    display: 'flex',
    // overflow: 'auto',
    flexDirection: 'column',
    minHeight: '100%',
    backgroundColor: theme.elements.content,
    borderRadius: 0,
    boxShadow: 'none',
    border: 0,
    flexGrow: 1,
    position: 'relative',
    paddingBottom: theme.spacing(9),
    width: 'fit-content',
    minWidth: '100%',
  },
  paperWithHeader: {
    padding: 0,
  },
  header: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    color: theme.elements.header.color,
    backgroundColor: theme.elements.header.background,
    fontWeight: 700,
    fontSize: '16px',
  },
  content: {
    padding: theme.spacing(2),
  },
}))

export const Paper = ({
  children,
  header = null,
  withPadding = true,
  classes = {},
  ...rest
}) => {
  const defaultClasses = useStyles()

  return (
    <div
      className={clsx(
        defaultClasses.root,
        withPadding && defaultClasses.rootWithPadding,
        classes.root
      )}
      {...rest}
    >
      <OriginalPaper
        className={clsx(
          defaultClasses.paper,
          header && defaultClasses.paperWithHeader,
          classes.paper
        )}
      >
        {header ? (
          <>
            <div className={defaultClasses.header}>{header}</div>
            <div className={defaultClasses.content}>{children}</div>
          </>
        ) : (
          children
        )}
      </OriginalPaper>
    </div>
  )
}

Paper.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.string,
  withPadding: PropTypes.bool,
  classes: PropTypes.shape({
    root: PropTypes.string,
    paper: PropTypes.string,
  }),
}
