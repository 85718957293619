import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { StringType } from 'components/applicant/form/fields/StringType'
import { isCoordinatorCollectionInvalid } from 'pages/applicant/Proposals/_helpers/isCoordinatorCollectionInvalid'
import { useCoordinatorItemTypeStyles } from './CoordinatorItemType.styles'
import { translate } from 'core/_helpers/translate'
import { BooleanType } from 'core/components/form/fields/BooleanType'

export const CoordinatorItemType = ({
  index,
  values,
  errors,
  renderError,
  globalRenderError,
  setState,
  disabled,
}) => {
  const setValue = useCallback(
    (name, value) => {
      setState(state => ({
        ...state,
        values: state.values.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: value,
              }
            : item
        ),
        renderError: state.renderError.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: true,
              }
            : item
        ),
        init: false,
      }))
    },
    [index, setState]
  )

  const setError = useCallback(
    (name, error) => {
      setState(state => ({
        ...state,
        errors: state.errors.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: error,
              }
            : item
        ),
        renderError: state.isFormSubmitted
          ? state.renderError.map((item, i) =>
              i === index
                ? {
                    ...item,
                    [name]: true,
                  }
                : item
            )
          : state.renderError,
        isInvalid: isCoordinatorCollectionInvalid(
          state.errors.map((item, i) =>
            i === index
              ? {
                  ...item,
                  [name]: !!error,
                }
              : item
          )
        ),
      }))
    },
    [index, setState]
  )

  const validators = useMemo(
    () => ({
      fullName: ['required'],
      role: ['required'],
      email: ['required', 'email'],
      phone: ['required', 'phone'],
      rodo: ['required'],
    }),
    []
  )

  const classes = useCoordinatorItemTypeStyles()

  return (
    <>
      <div className={classes.root}>
        <div>
          <div className={classes.label}>
            {translate('T_MODULE_PROPOSALS_COORDINATOR_FULL_NAME')}
          </div>
          <StringType
            type="string"
            name="fullName"
            value={values?.fullName}
            error={errors?.fullName}
            renderError={globalRenderError && renderError?.fullName}
            validators={validators.fullName}
            disabled={disabled}
            setValue={setValue}
            setError={setError}
            classes={{
              root: classes.input_full_name,
            }}
          />
        </div>
        <div>
          <div className={classes.label}>
            {translate('T_MODULE_PROPOSALS_COORDINATOR_ROLE')}
          </div>
          <StringType
            type="string"
            name="role"
            value={values?.role}
            error={errors?.role}
            renderError={globalRenderError && renderError?.role}
            validators={validators.role}
            disabled={disabled}
            setValue={setValue}
            setError={setError}
            classes={{
              root: classes.input_role,
            }}
          />
        </div>
      </div>
      <div className={classes.root}>
        <div>
          <div className={classes.label}>
            {translate('T_MODULE_PROPOSALS_COORDINATOR_EMAIL')}
          </div>
          <StringType
            type="email"
            name="email"
            value={values?.email}
            error={errors?.email}
            renderError={globalRenderError && renderError?.email}
            validators={validators.email}
            disabled={disabled}
            setValue={setValue}
            setError={setError}
            classes={{
              root: classes.input,
            }}
          />
        </div>
      </div>
      <div className={classes.root}>
        <div>
          <div className={classes.label}>
            {translate('T_MODULE_PROPOSALS_COORDINATOR_TEL')}
          </div>
          <StringType
            type="tel"
            name="phone"
            value={values?.phone}
            error={errors?.phone}
            renderError={globalRenderError && renderError?.phone}
            validators={validators.phone}
            disabled={disabled}
            setValue={setValue}
            setError={setError}
            classes={{
              root: classes.input,
            }}
          />
        </div>
      </div>
      <div className={classes.root}>
        <div>
          <BooleanType
            setValue={setValue}
            name={'rodo'}
            disabled={disabled}
            setError={setError}
            error={errors?.rodo}
            renderError={globalRenderError && renderError?.rodo}
            label={''}
            labelNotTrans={
              'Informacje dotyczące przetwarzania danych osobowych znajdują się w Zasadach Konkursu Duże Granty zamieszczonych na stronie internetowej <a href="http://www.rownacszanse.org.pl" target="_blank">www.rownacszanse.org.pl</a>'
            }
            value={values?.rodo}
            initialValue={values?.rodo}
            validators={validators.rodo}
          />
        </div>
      </div>
    </>
  )
}

CoordinatorItemType.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
