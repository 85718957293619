import { makeStyles, fade } from '@material-ui/core/styles'
import InputBase from '@material-ui/core/InputBase'
import { withStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    fontFamily: theme.typography.fontFamily,
  },
  card: {
    width: 548,
    borderRadius: 0,
    border: 0,
    boxShadow: 'none',
    padding: 0,
    marginBottom: 40,
  },
  logo: {
    height: 100,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    paddingLeft: 45,
  },
  form: {
    width: '100%',
    padding: theme.spacing(7),
  },
  form_inner: {
    display: 'flex',
    flexDirection: 'column',
  },
  form_control: {
    marginBottom: theme.spacing(3),
  },
  form_label: {
    color: theme.palette.wariant,
    fontSize: 16,
    lineHeight: 1.75,
    marginBottom: '4px',
    position: 'relative',
    transform: 'none',
    '&.Mui-focused': {
      color: theme.palette.secondary.main,
    },
  },
  input: {
    // marginTop: '.4em',
  },
  remember_me: {
    marginTop: '.7em',
    color: theme.elements.remember_me,
  },
  remember_me_checkbox: {
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '&:active': {
      backgroundColor: 'transparent !important',
    },
  },
  submit_register: {
    marginTop: 20,
  },
  submit: {
    margin: theme.spacing(4, 0, 0, 0),
    color: theme.white,
    backgroundColor: theme.palette.secondary.main,
    height: 56,
    width: '100%',
    maxWidth: 168,
    borderRadius: 0,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.85),
      boxShadow: '0px 0px 24px rgba(11, 43, 124, 0.4)',
    },
  },
  '@keyframes spin': {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  spinner: {
    height: 32,
  },
  lower_link: {
    marginTop: theme.spacing(7),
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    fontWeight: '700',
    fontSize: 16,
    lineHeight: 1.5,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  reset_message: {
    width: '100%',
    textAlign: 'left',
    color: theme.palette.success.main,
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  error_message: {
    width: '100%',
    textAlign: 'left',
    color: theme.palette.error.main,
    fontSize: 16,
    fontStyle: 'italic',
    fontWeight: 600,
    marginBottom: theme.spacing(2),
  },
  form_message: {
    width: '100%',
    textAlign: 'left',
    marginTop: theme.spacing(1),
    padding: '3.0em 0 4.75em 3.0em',
  },
  new_password_error: {
    marginBottom: '1.5em',
    textAlign: 'center',
    color: theme.palette.error.main,
  },
  login_menu: {
    with: '100%',
    display: 'grid',
    gridTemplateColumns: '50% 50%',
  },
  login_menu_title: {
    paddingLeft: theme.spacing(7),
    gridTemplateColumns: '100%',
  },
  login_app_bar: {
    background: 'transparent',
    boxShadow: 'none',
    marginBottom: theme.spacing(3),
  },
  login_title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 16,
    paddingBottom: 16,
    '& span': {
      position: 'relative',
      display: 'block',
      lineHeight: '42px',
      '&::before': {
        content: '""',
        position: 'absolute',
        display: 'block',
        height: 4,
        width: 24,
        left: 0,
        bottom: -4,
        background: theme.palette.secondary.main,
      },
    },
  },
  login_title_full: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  login_link: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 16,
    paddingBottom: 16,
    background: theme.palette.back,
    textDecoration: 'none',
    '&:hover': {
      '& span': {
        color: theme.palette.secondary.main,
      },
    },
  },
}))

export const CustomInput = withStyles(theme => ({
  input: {
    borderRadius: 0,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.wariant}`,
    fontSize: 16,
    width: '100%',
    maxWidth: '300px',
    padding: '8px 12px',
    transition: theme.transitions.create(['border-color']),
    color: theme.palette.primary.main,
    '&:focus': {
      borderColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.common.white,
    },
  },
}))(InputBase)
