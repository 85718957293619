import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { StringType } from 'components/applicant/form/fields/StringType'
import { isBudgetCollectionInvalid } from 'pages/applicant/Proposals/_helpers/isBudgetCollectionInvalid'
import { useBudgetItemTypeStyles } from './BudgetItemType.styles'
import { translate } from 'core/_helpers/translate'

export const BudgetItemType = ({
  index,
  values,
  errors,
  renderError,
  globalRenderError,
  setState,
  disabled,
  enableBudgetMenage = true,
  subsidyAmountSum = 0,
}) => {
  const setValue = useCallback(
    (name, value) => {
      setState(state => ({
        ...state,
        values: state.values.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: value,
              }
            : item
        ),
        renderError: state.renderError.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: true,
              }
            : item
        ),
        init: false,
      }))
    },
    [index, setState]
  )

  const setError = useCallback(
    (name, error) => {
      setState(state => ({
        ...state,
        errors: state.errors.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: error,
              }
            : item
        ),
        renderError: state.isFormSubmitted
          ? state.renderError.map((item, i) =>
              i === index
                ? {
                    ...item,
                    [name]: true,
                  }
                : item
            )
          : state.renderError,
        isInvalid: isBudgetCollectionInvalid(
          state.errors.map((item, i) =>
            i === index
              ? {
                  ...item,
                  [name]: !!error,
                }
              : item
          )
        ),
      }))
    },
    [index, setState]
  )

  const validators = useMemo(() => {
    return {
      title: ['required'],
      calculationMethod: ['required'],
      ownContributionAmount: [
        !values.ownContributionAmount && !values.subsidyAmount
          ? 'required'
          : null,
        'minValue(0)',
      ],
      subsidyAmount: [
        !values.ownContributionAmount && !values.subsidyAmount
          ? 'required'
          : null,
      ],
    }
  }, [values.ownContributionAmount, values.subsidyAmount])

  const classes = useBudgetItemTypeStyles()

  return (
    <div className={classes.root}>
      <div className={classes.number}>{index + 1}</div>
      <div className={classes.title}>
        <StringType
          type="textarea"
          name="title"
          value={values?.title}
          error={errors?.title}
          renderError={globalRenderError && renderError?.title}
          disabled={disabled}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.textarea,
          }}
        />
      </div>
      <div className={classes.calculation_method}>
        <StringType
          type="textarea"
          name="calculationMethod"
          value={values?.calculationMethod}
          error={errors?.calculationMethod}
          renderError={globalRenderError && renderError?.calculationMethod}
          disabled={disabled}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.textarea,
          }}
        />
      </div>
      <div className={classes.own_contribution_amout}>
        <StringType
          type="number"
          name="ownContributionAmount"
          value={
            values?.ownContributionAmount === 0
              ? '0'
              : values?.ownContributionAmount
          }
          error={errors?.ownContributionAmount}
          renderError={globalRenderError && renderError?.ownContributionAmount}
          validators={validators.ownContributionAmount}
          disabled={disabled}
          setValue={setValue}
          setError={setError}
          min={0}
          classes={{
            root: classes.input,
          }}
        />
        <div className={classes.pln}>
          {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
        </div>
      </div>
      <div className={classes.subsidy_amount}>
        <StringType
          type="number"
          name="subsidyAmount"
          value={values?.subsidyAmount === 0 ? '0' : values?.subsidyAmount}
          error={errors?.subsidyAmount}
          renderError={globalRenderError && renderError?.subsidyAmount}
          validators={validators.subsidyAmount}
          disabled={disabled}
          setValue={setValue}
          setError={setError}
          min={0}
          classes={{
            root: classes.input,
          }}
        />
        <div className={classes.pln}>
          {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
        </div>
      </div>
    </div>
  )
}

BudgetItemType.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
