import { makeStyles } from '@material-ui/styles'

export const useCoordinatorItemTypeStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    marginBottom: 10,
    display: 'flex',
    '&> *:first-child': {
      marginRight: 30,
    },
  },
  label: {
    marginBottom: 10,
  },
  input_full_name: {
    width: 350,
  },
  input_role: {
    width: 200,
  },
  input: {
    width: 280,
  },
}))
