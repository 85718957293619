import { useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

export const useTrainingFetch = (
  iri,
  setProposal,
  setContest,
  setForm,
  setFetchError,
  setSubresourceFetched,
  index
) => {
  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      iri,
      'GET',
      { signal },
      response => {
        setProposal(response)

        if (response.contest) {
          getContest(response.contest, signal, setContest, setForm, setFetchError, setSubresourceFetched, index)
        } else {
          setSubresourceFetched('contest')
          setSubresourceFetched('proposalTrainingFormValues')
        }
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
        setFetchError(true)
      }
    )

    return () => controller.abort()
  }, [
    iri,
    setProposal,
    setContest,
    setForm,
    setFetchError,
    setSubresourceFetched,
    index
  ])

  return
}

const getContest = (iri, signal, setContest, setForm, setFetchError, setSubresourceFetched, index) => {
  fetchDataHandleAuthError(
    iri,
    'GET',
    { signal },
    response => {
      setContest(response)

      if (index !== undefined && response.trainings.length > 0) {
        getForm('proposalTrainingFormValues', response.trainings[index].inquiryForm, signal, setForm, setFetchError)
      } else {
        setSubresourceFetched('proposalTrainingFormValues')
      }

    },
    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
      setFetchError(true)
    }
  )
}

const getForm = (name, iri, signal, setForm, setFetchError) => {
  fetchDataHandleAuthError(
    iri,
    'GET',
    { signal },
    response => {
      setForm(name, response)
    },
    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
      setFetchError(true)
    }
  )
}
