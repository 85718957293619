import React from 'react'
import { StringType } from 'core/components/form/fields/StringType'

export const Timetable = ({ block }) => {
  const values = block.data ? JSON.parse(block.data) : {}
  return (
    <>
      <div>
        <StringType
          name={'timetable_1'}
          setValue={() => false}
          disabled={false}
          renderError={false}
          type={'textarea'}
          setError={() => false}
          error={false}
          label={'Wrzesień 2021'}
          width={'520px'}
          validators={values.required ? ['required'] : null}
        />
      </div>
      <div>
        <StringType
          name={'timetable_2'}
          setValue={() => false}
          disabled={false}
          renderError={false}
          type={'textarea'}
          setError={() => false}
          error={false}
          label={'Październik 2021'}
          width={'520px'}
          validators={values.required ? ['required'] : null}
        />
      </div>
    </>
  )
}
