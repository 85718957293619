import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { blocks } from './blocks'
import { useSelector } from 'react-redux'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import schema from 'core/_schema/user'

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: 60,
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  block: {
    marginTop: 15,
  },
}))

export const FormTemplate = ({
  mapping,
  values,
  contest,
  proposal,
  additionalResources,
}) => {
  const classes = useStyles()
  const profile = useSelector(state => state.profile)
  const [user, setUser] = useState({})

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    fetchDataHandleAuthError(
      `${schema('user').endpoint}/${profile.uuid}`,
      'GET',
      { signal },
      resp => {
        setUser(resp)
      }
    )
    return () => controller.abort()
  }, [profile])

  return (
    mapping
      ?.filter(section => section.stat)
      .map(section => (
        <div className={classes.section} key={section['@id']}>
          {section.titleVisibility && section.title && (
            <div className={classes.title}>{section.title}</div>
          )}
          {values?.[section.uuid]?.map((item, index) =>
            section.formSectionBlocks?.map(block => {
              const BlockComponent = blocks[block.type]

              return (
                <div className={classes.block} key={`${index}-${block['@id']}`}>
                  <BlockComponent
                    data={block.data}
                    values={values?.[section.uuid]?.[index]?.[block.uuid]}
                    contest={contest}
                    proposal={proposal}
                    provinces={additionalResources.provinces}
                    populations={additionalResources.populations}
                    organizationTypes={additionalResources.organizationTypes}
                    user={user}
                  />
                </div>
              )
            })
          )}
        </div>
      )) || null
  )
}

FormTemplate.propTypes = {
  mapping: PropTypes.array,
  values: PropTypes.object,
  contest: PropTypes.object,
  proposal: PropTypes.object,
  additionalResources: PropTypes.object.isRequired,
}
