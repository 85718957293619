import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { StringType } from 'components/applicant/form/fields/StringType'
import { TaxIdType } from 'pages/applicant/Proposals/components/form/fields'
import { ResourceType } from 'components/applicant/form/fields/ResourceType'
import { translate } from 'core/_helpers/translate'
import organizationTypeSchema from '_schema/organizationType'

const useStyles = makeStyles({
  row: {
    marginTop: 8,
  },
  row_post: {
    display: 'flex',
    '&> *:first-child': {
      marginRight: 20,
    },
  },
  label: {
    marginBottom: 5,
  },
  input: {
    width: 350,
  },
  input_post_code: {
    width: 80,
  },
  select_tax_id: {
    width: 100,
    marginRight: 15,
  },
})

export const CompanyDataBlock = ({
  proposal,
  values,
  errors,
  renderError,
  globalRenderError,
  setValue,
  setError,
  disabled,
}) => {
  const validators = useMemo(
    () => ({
      taxId: ['required'],
      nip: [],
      regon: [],
      krs: [],
      organizationName: ['required'],
      organizationType: ['required'],
    }),
    []
  )

  const [first, setFirst] = useState(false)

  useEffect(() => {
    if (!first) {
      if ([undefined, null].includes(values?.nip) && proposal?.nip) {
        setValue('taxId', 'nip')
        setValue('nip', proposal.nip)
      }

      if ([undefined, null].includes(values?.regon) && proposal?.regon) {
        setValue('taxId', 'regon')
        setValue('regon', proposal.regon)
      }

      if ([undefined, null].includes(values?.krs) && proposal?.krs) {
        setValue('taxId', 'krs')
        setValue('krs', proposal.krs)
      }

      if (
        [undefined, null].includes(values?.organizationName) &&
        proposal?.organizationName
      ) {
        setValue('organizationName', proposal.organizationName)
      }

      if (
        [undefined, null].includes(values?.organizationType) &&
        proposal?.organizationType
      ) {
        setValue('organizationType', proposal.organizationType)
      }
    }
    setFirst(true)
  }, [values, proposal, setValue, first])

  const classes = useStyles()

  return (
    <div>
      <div className={classes.row_two_col}>
        <TaxIdType
          name="taxId"
          choices={{
            nip: 'T_MODULE_ORGANIZATION_DATA_NIP',
            regon: 'T_MODULE_ORGANIZATION_DATA_REGON',
            krs: 'T_MODULE_ORGANIZATION_DATA_KRS',
          }}
          value={values?.taxId}
          error={errors?.taxId}
          renderError={globalRenderError && renderError?.taxId}
          disabled={disabled}
          validators={validators.taxId}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.select_tax_id,
          }}
        />
        {values?.taxId === 'nip' && (
          <StringType
            type="string"
            name="nip"
            value={values?.nip}
            error={errors?.nip}
            renderError={globalRenderError && renderError?.nip}
            disabled={disabled}
            validators={validators.nip}
            setValue={setValue}
            setError={setError}
            classes={{
              root: classes.input,
            }}
          />
        )}
        {values?.taxId === 'regon' && (
          <StringType
            type="string"
            name="regon"
            value={values?.regon}
            error={errors?.regon}
            renderError={globalRenderError && renderError?.regon}
            disabled={disabled}
            validators={validators.regon}
            setValue={setValue}
            setError={setError}
            classes={{
              root: classes.input,
            }}
          />
        )}
        {values?.taxId === 'krs' && (
          <StringType
            type="string"
            name="krs"
            value={values?.krs}
            error={errors?.krs}
            renderError={globalRenderError && renderError?.krs}
            disabled={disabled}
            validators={validators.krs}
            setValue={setValue}
            setError={setError}
            classes={{
              root: classes.input,
            }}
          />
        )}
      </div>
      <div className={classes.row}>
        <div className={classes.label}>
          {translate('T_MODULE_ORGANIZATION_DATA_ORGANIZATION_NAME')}
        </div>
        <StringType
          type="string"
          name="organizationName"
          value={values?.organizationName}
          error={errors?.organizationName}
          renderError={globalRenderError && renderError?.organizationName}
          disabled={disabled}
          validators={validators.organizationName}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input,
          }}
        />
      </div>
      <div className={classes.row}>
        <div className={classes.label}>
          {translate('T_MODULE_ORGANIZATION_DATA_ORGANIZATION_TYPE')}
        </div>
        <ResourceType
          name="organizationType"
          endpoint={`${organizationTypeSchema.endpoint}?order[title]=desc&pagination=false`}
          titleAccessor="title"
          value={values?.organizationType}
          error={errors?.organizationType}
          renderError={globalRenderError && renderError?.organizationType}
          disabled={disabled}
          validators={validators.organizationType}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input,
          }}
        />
      </div>
    </div>
  )
}

CompanyDataBlock.propTypes = {
  proposal: PropTypes.object.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
