import { formSettingsConstans } from '_constans/formSettings.constans'

const initialState = {
  isChange: false,
}

export const formSettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case formSettingsConstans.SET_IS_CHANGE:
      return {
        ...state,
        isChange: true,
      }
    case formSettingsConstans.RESET_IS_CHANGE:
      return {
        ...state,
        isChange: false,
      }
    default:
      return state
  }
}
