import React, { useCallback } from 'react'
import { SectionTitle } from 'core/components/SectionTitle'
import { Button, Typography } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { ReadFormGenerator } from './ReadFormGenerator'
import { makeStyles } from '@material-ui/styles'
import schema from '_schema/proposalTraining'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { REALIZATION_STATUS_FINISHED } from '_schema/proposal';

const useStyle = makeStyles(theme => ({
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
}))

export const TrainingInquiry = ({ eventObject, resource, updateResource }) => {
  const classes = useStyle()

  const handleClick = useCallback(() => {
    const iri = `${schema.endpoint}/inquiryReset/${eventObject.training.uuid}`
    fetchDataHandleAuthError(
      iri,
      'PUT',
      { body: JSON.stringify({}) },
      () => {
        notification(
          'success',
          translate(
            'Dane z ankiety zostały wyczyszczone. Klient został poinformowany o konieczności ponownego wypełnienia ankiety.'
          )
        )
        updateResource()
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [eventObject.training.uuid, updateResource])

  return (
    <>
      <SectionTitle label={'Ankieta szkolenia'} />
      {eventObject.proposalTrainingInquiryDate ? (
        <>
          <ReadFormGenerator
            values={eventObject.training.proposalTrainingFormValues}
            formIRI={eventObject.training.contestTraining.inquiryForm}
            contest={resource.contest}
          />
          {resource.realizationStatus !== REALIZATION_STATUS_FINISHED && (
            <div className={classes.buttons}>
              <Button
                variant="outlined"
                size="large"
                color={'secondary'}
                onClick={handleClick}
              >
                {translate('Poproś o ponowne wypełnienie ankiety')}
              </Button>
            </div>
          )}
        </>
      ) : (
        <Typography variant={'subtitle1'} color={'primary'}>
          {translate('Ankieta nie została wypełniona przez wnioskodawce')}
        </Typography>
      )}
    </>
  )
}
