import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { AttachmentItemType } from './AttachmentItemType'
import { isAttachmentCollectionInvalid } from 'pages/applicant/Proposals/_helpers/isAttachmentCollectionInvalid'
import { generateAttachmentCollectionRenderError } from 'pages/applicant/Proposals/_helpers/generateAttachmentCollectionRenderError'
import { useAttachmentCollectionTypeStyles } from './AttachmentCollectionType.styles'
import { ReactComponent as Delete } from 'theme/icons/remove.svg'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { translate } from 'core/_helpers/translate'
import { Typography } from '@material-ui/core'

export const AttachmentCollectionType = ({
  name,
  value,
  disabled,
  renderError,
  setValue,
  setError,
  contest,
  isFormSubmitted,
}) => {
  const [state, setState] = useState({
    values:
      value.length === 0 && contest.attachmentsRequired
        ? contest.contestProposalAttachments.map(el => ({ title: el.title }))
        : value,
    errors: value ? Array(value.length).fill({}) : [],
    renderError: value ? Array(value.length).fill({}) : [],
    isInvalid: false,
    isFormSubmitted: false,
    init: true,
  })

  const handleAdd = useCallback(() => {
    setState(state => ({
      ...state,
      values: [...state.values, {}],
      errors: [...state.errors, {}],
      renderError: [...state.renderError, {}],
    }))
  }, [setState])

  const handleDelete = useCallback(
    index => () => {
      setState(state => ({
        ...state,
        values: state.values?.filter((item, i) => i !== index),
        errors: state.errors?.filter((item, i) => i !== index),
        renderError: state.renderError?.filter((item, i) => i !== index),
        isInvalid: isAttachmentCollectionInvalid(
          state.errors?.filter((item, i) => i !== index)
        ),
      }))
    },
    [setState]
  )

  useEffect(() => {
    setValue(name, state.values, !state.init)
  }, [name, state.values, state.init, setValue])

  useEffect(() => {
    setError(name, state.isInvalid)
  }, [name, setError, state.isInvalid])

  useEffect(() => {
    if (!isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      isFormSubmitted,
    }))
  }, [setState, isFormSubmitted])

  useEffect(() => {
    if (!state.isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      renderError: generateAttachmentCollectionRenderError(state.errors, true),
    }))
  }, [setState, state.isFormSubmitted])

  const classes = useAttachmentCollectionTypeStyles()

  return (
    <div className={classes.root}>
      <div>
        {contest.attachmentsRequired && (
          <div style={{ marginBottom: 30 }}>
            <Typography
              variant={'h3'}
              color={'primary'}
              style={{ marginBottom: 10 }}
            >
              Wymagane załaczniki:
            </Typography>
            {contest.contestProposalAttachments.map(el => (
              <Typography
                variant={'subtitle1'}
                color={'secondary'}
                key={el.uuid}
              >
                {el.title}
              </Typography>
            ))}
          </div>
        )}
        {state.values.map((item, index) => (
          <div className={classes.container} key={index}>
            <div>
              <AttachmentItemType
                index={index}
                values={state.values?.[index]}
                errors={state.errors[index]}
                renderError={state.renderError[index]}
                globalRenderError={renderError}
                setState={setState}
                disabled={disabled}
                contest={contest}
                disabledTitle={
                  contest.attachmentsRequired &&
                  index < contest.contestProposalAttachments.length
                }
              />
            </div>
            {!(
              contest.attachmentsRequired &&
              index < contest.contestProposalAttachments.length
            ) && (
              <div className={classes.sidebar}>
                <Delete onClick={handleDelete(index)} />
              </div>
            )}
          </div>
        ))}
      </div>
      <ButtonPlus
        text={translate('T_MODULE_PROPOSALS_ADD_ATTACHMENT')}
        disabled={disabled}
        onClick={handleAdd}
      />
    </div>
  )
}

AttachmentCollectionType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  renderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  contest: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
}
