import React, { useReducer, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import _ from 'lodash'
import { Loader } from 'core/components/Loader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { applyProperties } from 'core/_helpers/applyProperties'
import { notification } from 'core/_helpers/notification'
import { isObjectEmpty } from 'core/_helpers/isObjectEmpty'
import { createObjectFromString } from 'core/_helpers/createObjectFromString'
import { SubmitButton } from 'components/applicant/form/buttons'
import { initState } from 'components/applicant/form/_helpers/initState'
import { constants, reducer } from 'components/applicant/form/_state'
import { useStyles } from 'components/applicant/form'
import {
  CONTENT_REPORT_STATUS_DRAFT,
  CONTENT_REPORT_STATUS_RETURNED,
} from '_schema/proposal'
import { createPortal } from 'react-dom'
import { Typography } from '@material-ui/core'

const ErrorInfo = ({ nodeRef, state }) => {
  const classes = useStyles()

  if (!state.isSubmitted || !state.isInvalid) {
    return null
  }

  return createPortal(
    <div className={classes.error}>
      <Typography variant="body2" color={'error'} style={{ marginBottom: 10 }}>
        Formularz posiada błędy. Sprawdź czy wszystkie pola są poprawnie
        wypełnione.
      </Typography>
    </div>,
    nodeRef
  )
}

export const ReportForm = ({
  url,
  definitionSchema,
  customResourceSchema,
  resource = null,
  buttonContainerRef = null,
  handleApplySuccess,
  children,
  disabled,
  type = 'finalReport',
  classes = {},
}) => {
  const properties = applyProperties(
    customResourceSchema.properties,
    definitionSchema.properties,
    'missing'
  )

  const [state, dispatch] = useReducer(
    reducer,
    {
      url,
      method: 'PUT',
      resource,
      properties,
      onlyPassedProperties: true,
    },
    initState
  )

  const setValue = useCallback((name, value, setRenderError = true) => {
    dispatch({
      type: constants.SET_VALUE,
      payload: { name, value, setRenderError },
    })
  }, [])

  const setError = useCallback((name, error) => {
    dispatch({ type: constants.SET_ERROR, payload: { name, error } })
  }, [])

  useEffect(() => {
    if (!state.isSubmitted) {
      return
    }

    dispatch({ type: constants.RENDER_ERROR })
  }, [state.isSubmitted])

  const handleSubmitSketch = e => {
    e.preventDefault()

    dispatch({ type: constants.PROCESS, payload: true })

    fetchDataHandleAuthError(
      url,
      'PUT',
      { body: JSON.stringify(state.values) },
      response => {
        dispatch({
          type: constants.SUCCESS,
          payload: {
            method: 'PUT',
            resource: response,
          },
        })

        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')
      },
      error => {
        const errors = error.response.violations.reduce(
          (processedErrors, item) => {
            const processedError = createObjectFromString(
              item.propertyPath.replace('[', '.').replace(']', ''),
              item.message
            )

            return _.merge(processedErrors, processedError)
          },
          {}
        )

        dispatch(
          isObjectEmpty(errors)
            ? { type: constants.PROCESS, payload: false }
            : { type: constants.FAILURE, payload: { errors } }
        )

        notification(
          'error',
          error.response.violations.length
            ? 'T_FORM_INCORRECT'
            : error.response.detail,
          error.response.title
        )
      },
      {}
    )
  }

  const handleSubmitApply = e => {
    e.preventDefault()

    dispatch({ type: constants.SUBMIT })

    if (state.isInvalid) {
      return
    }

    dispatch({ type: constants.PROCESS, payload: true })

    fetchDataHandleAuthError(
      url,
      'PUT',
      {
        body: JSON.stringify({
          ...state.values,
          [`${type}IsApply`]: true,
        }),
      },
      response => {
        dispatch({
          type: constants.SUCCESS,
          payload: {
            method: 'PUT',
            resource: response,
          },
        })

        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')
        handleApplySuccess(response)
      },
      error => {
        const errors = error.response.violations.reduce(
          (processedErrors, item) => {
            const processedError = createObjectFromString(
              item.propertyPath.replace('[', '.').replace(']', ''),
              item.message
            )

            return _.merge(processedErrors, processedError)
          },
          {}
        )

        dispatch(
          isObjectEmpty(errors)
            ? { type: constants.PROCESS, payload: false }
            : { type: constants.FAILURE, payload: { errors } }
        )

        notification(
          'error',
          error.response.violations.length
            ? 'T_FORM_INCORRECT'
            : error.response.detail,
          error.response.title
        )
      },
      {}
    )
  }

  const defaultClasses = useStyles()

  return (
    <form className={clsx(defaultClasses.root, classes.root)}>
      {children({
        state,
        properties,
        resource,
        setValue,
        setError,
      })}
      <div className={clsx(defaultClasses.buttons, classes.buttons)}>
        {state.isProcessing ? (
          <Loader classes={{ root: classes.loader }} />
        ) : (
          <>
            {buttonContainerRef &&
              [
                null,
                CONTENT_REPORT_STATUS_DRAFT,
                CONTENT_REPORT_STATUS_RETURNED,
              ].includes(resource[`${type}Status`]) && (
                <>
                  <ErrorInfo nodeRef={buttonContainerRef} state={state} />
                  <SubmitButton
                    title="Zapisz wersję roboczą"
                    variant="outlined"
                    size="small"
                    handleSubmit={handleSubmitSketch}
                    disabled={disabled || state.isProcessing}
                    classes={{ submit: classes.submit }}
                    nodeRef={buttonContainerRef}
                  />
                  <SubmitButton
                    title={`Złóż raport ${
                      type === 'finalReport' ? 'końcowy' : 'częściowy'
                    }`}
                    variant="contained"
                    size="small"
                    handleSubmit={handleSubmitApply}
                    disabled={
                      disabled ||
                      state.isProcessing ||
                      (state.isSubmitted && state.isInvalid)
                    }
                    classes={{ submit: classes.submit }}
                    nodeRef={buttonContainerRef}
                  />
                </>
              )}
          </>
        )}
      </div>
    </form>
  )
}

ReportForm.propTypes = {
  url: PropTypes.string.isRequired,
  resource: PropTypes.object,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  handleApplySuccess: PropTypes.func.isRequired,
  buttonContainerRef: PropTypes.object,
  children: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  classes: PropTypes.object,
}
