import React, { useMemo, useState } from 'react'
import { standard } from 'core/components/table/_columns/standard'
import { TranslateCell } from 'core/components/table/cells/TranslateCell'
import { translate } from 'core/_helpers/translate'
import { DateCell } from 'core/components/table/cells/DateCell'
import { AddInfoCell } from 'core/components/table/cells/AddInfoCell'
import { operations } from 'core/components/table/_columns/operations'
import schema from '_schema/contests'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import { Paper } from 'core/components/Paper'

export const CollectionTrash = () => {
  const columns = useMemo(
    () => [
      {
        ...standard('title'),
        sortable: false,
        width: '20%',
      },
      {
        Cell: TranslateCell,
        accessor: 'type',
        sortable: false,
        width: '10%',
      },
      {
        Cell: TranslateCell,
        accessor: 'publicationStatus',
        sortable: false,
        width: '10%',
      },
      {
        accessor: 'publicationDate',
        Cell: DateCell,
        sortable: false,
        width: '10%',
      },
      {
        accessor: 'proposalCollectingDateFrom',
        Cell: DateCell,
        sortable: false,
        width: '10%',
      },
      {
        accessor: 'projectRealizationDateFrom',
        Cell: DateCell,
        sortable: false,
        width: '10%',
      },
      {
        accessor: 'projectRealizationDateTo',
        Cell: DateCell,
        sortable: false,
        width: '10%',
      },
      {
        accessor: 'proposalSubmittedCount',
        Cell: AddInfoCell,
        sortable: false,
        width: '20%',
      },
      {
        ...operations(
          'title',
          false,
          true,
          false,
          resource => resource.proposalSubmittedCount > 0,
          false,
          true
        ),
        width: '10%',
        editable: false,
        deletable: true,
      },
    ],
    []
  )

  const [reload, setReload] = useState(false)

  const handleSetReload = () => {
    setReload(state => !state)
  }

  return (
    <>
      <CollectionHeader
        buttonTitle={translate('Kosz')}
        buttonPath={'/'}
        hideButton
      >
        {translate('Kosz')}
      </CollectionHeader>
      <Paper withPadding={false}>
        <>
          <SchemableCollection
            component={CollectionTable}
            path={`paths.${schema.endpoint}.get`}
            endpoint={`${schema.endpoint}`}
            customResourceSchema={schema.resource}
            columns={columns}
            customFilters={[
              {
                name: 'isDeleted',
                value: 'true',
                title: 'isDeleted',
              },
            ]}
            autoWidth={false}
            filterable={false}
            sortable={false}
            numerable={false}
            resetable={false}
            paginable={true}
            definitionSchema={schema.resource}
            reload={reload}
            setReload={handleSetReload}
            storeCollectionId={'trash'}
          />
        </>
      </Paper>
    </>
  )
}
