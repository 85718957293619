import React, { useCallback, useState } from 'react'
import { useOrganizationDataFormStyles } from './OrganizationDataForm.styles'
import clsx from 'clsx'
import { StringType } from 'core/components/form/fields/StringType'
import { Button, Typography } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { CustomDialog } from 'core/components/Dialog'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

export const OrganizationEmailChange = ({ resource, setValue, url }) => {
  const classes = useOrganizationDataFormStyles()
  const [state, setState] = useState({
    email: resource.email,
    password: '',
    error: false,
    renderError: false,
    open: false,
    isFetching: false,
  })

  const handleChangeField = useCallback((name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }))
  }, [])
  const handleError = useCallback((name, error) => {
    setState(prevState => ({ ...prevState, error: error }))
  }, [])

  const handleToogle = useCallback(() => {
    setState(prevState => ({ ...prevState, open: !prevState.open }))
  }, [])

  const handleConfirm = useCallback(() => {
    setState(prevState => ({
      ...prevState,
      isFetching: !prevState.isFetching,
      error: false,
      renderError: false,
    }))
    fetchDataHandleAuthError(
      url,
      'PUT',
      {
        body: JSON.stringify({
          emailNew: state.email,
          password: state.password,
        }),
      },
      resp => {
        if (resp['@id']) {
          setValue('email', state.email)
          setState(prevState => ({
            ...prevState,
            password: '',
            open: !prevState.open,
            isFetching: !prevState.isFetching,
          }))
          notification(
            'success',
            'Wysłano link potwierdzający zmianę na nowy adres e-mail.',
            'T_FORM_SUCCESS'
          )
        }
      },
      error => {
        setState(prevState => ({
          ...prevState,
          error:
            translate('Nieprawidłowe hasło. Spróbuj ponownie.') ||
            error?.response.detail,
          renderError: true,
          password: '',
          isFetching: !prevState.isFetching,
        }))
      }
    )
  }, [setValue, state.email, state.password, url])

  return (
    <>
      <div className={clsx(classes.row_two_col)}>
        <div>
          <StringType
            name={'email'}
            setValue={handleChangeField}
            disabled={false}
            renderError={state.renderError}
            error={state.error}
            type={'email'}
            setError={handleError}
            label={''}
            value={state.email}
          />
        </div>
        <div>
          <Button
            variant="contained"
            color={'secondary'}
            size={'large'}
            style={{ maxHeight: 37 }}
            onClick={handleToogle}
          >
            <span>{translate('Zmień')}</span>
          </Button>
        </div>
      </div>
      <CustomDialog
        handleToogle={handleToogle}
        open={state.open}
        title={'Czy na pewno zmienić adres email?'}
        component={() => (
          <>
            <Typography gutterBottom variant={'body1'} color={'primary'}>
              {translate('Musisz podać swoje hasło zanim zmienisz email.')}
            </Typography>
            <StringType
              name={'password'}
              setValue={handleChangeField}
              disabled={false}
              renderError={state.renderError}
              error={state.error}
              type={'password'}
              setError={handleError}
              label={''}
              value={state.password}
            />
          </>
        )}
        confirmedButton={true}
        onConfirm={handleConfirm}
        confirmedText={'Zmień'}
        isFetching={state.isFetching}
      />
    </>
  )
}
