import React from 'react'
import PropTypes from 'prop-types'
import { MenuItem } from './MenuItem'
import { Tabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  menu: {
    width: 'auto',
    maxWidth: 'calc(100% - 200px)',
  },
  menu_tabs: {
    '& .MuiTabs-scrollButtons svg': {
      '& path': {
        fill: '#0B2B7C',
      },
    },
  },
}))

export const Menu = ({ items = [], ...rest }) => {
  const classes = useStyles()
  return (
    <div {...rest} className={classes.menu}>
      <div>
        <Tabs
          value={0}
          aria-label="nav"
          style={{ marginRight: 30 }}
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="secondary"
          className={classes.menu_tabs}
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          {items.map((item, i) => {
            return <MenuItem item={item} key={`menu-item-${i}`} />
          })}
        </Tabs>
      </div>
    </div>
  )
}

const itemShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool,
})

Menu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([
      itemShape,
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        items: PropTypes.arrayOf(itemShape).isRequired,
      }),
    ])
  ),
}
