import { makeStyles } from '@material-ui/styles'

export const useStyles = makeStyles(theme => ({
  textwraper: {
    maxWidth: 752,
    position: 'relative',
    display: 'block',
    color: theme.palette.primary.main,
  },
  title: {
    marginBottom: theme.spacing(2),
    maxWidth: 660,
  },
  subtitle: {
    marginBottom: theme.spacing(3),
    maxWidth: 660,
  },
  text: {
    marginBottom: theme.spacing(8),
    maxWidth: 660,
  },
  titleSection: {
    marginBottom: theme.spacing(3),
    maxWidth: 660,
  },
  description: {
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(25),
    letterSpacing: '0.02em',
    marginBottom: theme.spacing(4),
    maxWidth: 660,
  },
  titleSectionWithBorder: {
    borderBottom: `1px solid ${theme.palette.separator}`,
    paddingBottom: 5,
  },
  block: {
    marginBottom: theme.spacing(8),
  },
  file: {
    marginBottom: theme.spacing(3),
  },
  file_details: {
    display: 'grid',
    gridTemplateColumns: '90px auto',
    textDecoration: 'none',
    alignItems: 'center',
    cursor: 'pointer',
    '&:hover': {
      '& svg path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  file_details_icon: {
    display: 'flex',
    justifyContent: 'center',
  },
  file_info: {
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(24),
    color: theme.palette.secondary.main,
    '& span:first-of-type': {
      display: 'block',
      textDecoration: 'underline',
    },
    '& span:last-of-type': {
      display: 'block',
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  photoTitle: {
    marginBottom: theme.spacing(2),
  },
  photo: {
    width: '100%',
    maxWidth: '580px',
    display: 'block',
  },
  btn_center: {
    display: 'flex',
    justifyContent: 'center',
  },
}))
