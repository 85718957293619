import React from 'react'
import PropTypes from 'prop-types'
import DOMPurify from 'dompurify'
import { formatBytes } from '_helpers/formatBytes'
import { useStyles } from '../style'
import { Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { ReactComponent as FileIcon } from 'theme/icons/file.svg'
import { downloadFile } from '_helpers/downloadFile'

export const TextWithFiles = ({ title, description, files }) => {
  const classes = useStyles()

  const cleanDescription = DOMPurify.sanitize(description, {
    USE_PROFILES: { html: true },
  })

  const handleDownload = item => {
    downloadFile(
      `/files/original/${item.file.uuid}`,
      `${item.fileName}.${item.file.originalName.slice(
        item.file.originalName.lastIndexOf('.') + 1
      )}`
    )
  }

  return (
    <div className={classes.block}>
      {title && (
        <Typography
          variant={'h3'}
          color={'primary'}
          className={clsx(classes.titleSection, classes.titleSectionWithBorder)}
        >
          {title}
        </Typography>
      )}
      {description && (
        <div
          dangerouslySetInnerHTML={{ __html: cleanDescription }}
          className={classes.description}
        ></div>
      )}
      {!!files.length && (
        <div className={classes.files}>
          {files.map(item => (
            <Grid
              key={item['@id']}
              container
              spacing={4}
              className={classes.file}
            >
              <Grid item xs={12} md={4}>
                <div
                  className={classes.file_details}
                  onClick={() => handleDownload(item)}
                >
                  <span className={classes.file_details_icon}>
                    <FileIcon />
                  </span>
                  <span className={classes.file_info}>
                    <span>{item.fileName}</span>
                    <span>{formatBytes(item.file.fileSize)}</span>
                  </span>
                </div>
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography variant={'subtitle1'}>{item.title}</Typography>
              </Grid>
            </Grid>
          ))}
        </div>
      )}
    </div>
  )
}

TextWithFiles.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
      file: PropTypes.shape({
        '@id': PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        fileSize: PropTypes.number.isRequired,
      }).isRequired,
    })
  ),
}
