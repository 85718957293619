export const generateAttachmentCollectionRenderError = (attachments, renderError) => {
  if (!attachments) {
    return []
  }

  let currentRenderError = []

  attachments.forEach((attachment, index) => {
    if (!attachment) {
      return
    }

    if (!currentRenderError[index]) {
      currentRenderError[index] = {}
    }

    Object.keys(attachment).forEach(field => {
      currentRenderError[index][field] = renderError
    })
  })

  return currentRenderError
}
