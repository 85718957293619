export const BLOCK_TYPE_INFO = 'TEXT'
export const BLOCK_TYPE_INSTYTUTION = 'COMPANY_ADDRESS'
export const BLOCK_TYPE_ADDRESS = 'ADDRESS'
export const BLOCK_TYPE_INPUT = 'INPUT_TEXT'
export const BLOCK_TYPE_SELECT = 'CHOICES_SELECT'
export const BLOCK_TYPE_CHECKBOX = 'CHOICES_CHECKBOX'
export const BLOCK_TYPE_DATA_TIME = 'DATE_TIME'
export const BLOCK_TYPE_IMPLEMENTATNION = 'REALIZATION_DATE_TIME'
export const BLOCK_TYPE_PARTICIPANTS = 'PARTICIPANTS_COUNT'
export const BLOCK_TYPE_TIMETABLE = 'PROJECT_SCHEDULE'
export const BLOCK_TYPE_TEXTAREA = 'TEXTAREA'
export const BLOCK_TYPE_ADDITIONAL_INFO = 'ADDITIONAL_INFO'
export const BLOCK_TYPE_TARGET_INFORMATIONS = 'TARGET_INFORMATIONS'
export const BLOCK_TYPE_COMPANY_DATA = 'COMPANY_DATA' // powoduje wyświetlenie bloku polami na nip,regon,pesel,nazwe organizacji i typ organizacji

const properties = {
  [BLOCK_TYPE_INFO]: {
    data: {
      label: 'Treść',
      type: 'tinymce',
      fullWidth: true,
      formWidth: 650,
      insertInfo: true,
    },
  },
  [BLOCK_TYPE_INSTYTUTION]: {},
  [BLOCK_TYPE_ADDRESS]: {
    post: {
      type: 'group',
      properties: {
        postOffice: {
          label: 'T_FORM_FIELD_POST_OFFICE',
          type: 'string',
          column: 7,
          placeholder: 'placeholder text',
        },
        postCode: {
          label: 'T_FORM_FIELD_POST_CODE',
          type: 'string',
          column: 3,
          placeholder: 'placeholder text',
        },
        postRequired: {
          label: 'required',
          type: 'boolean',
          column: 2,
        },
      },
    },
    addressRow: {
      type: 'group',
      properties: {
        address: {
          label: 'T_FORM_FIELD_ADDRESS',
          type: 'string',
          column: 10,
          placeholder: 'placeholder text',
        },
        addressRequired: {
          label: 'required',
          type: 'boolean',
          column: 2,
        },
      },
    },
    cityRow: {
      type: 'group',
      properties: {
        city: {
          label: 'T_FORM_FIELD_CITY',
          type: 'string',
          column: 10,
          placeholder: 'placeholder text',
        },
        cityRequired: {
          label: 'required',
          type: 'boolean',
          column: 2,
        },
      },
    },
    communeRow: {
      type: 'group',
      properties: {
        commune: {
          label: 'T_FORM_FIELD_COMMUNE',
          type: 'string',
          column: 10,
          placeholder: 'placeholder text',
        },
        communeRequired: {
          label: 'required',
          type: 'boolean',
          column: 2,
        },
      },
    },
    districtRow: {
      type: 'group',
      properties: {
        district: {
          label: 'T_FORM_FIELD_DISTRICT',
          type: 'string',
          column: 10,
          placeholder: 'placeholder text',
        },
        districtRequired: {
          label: 'required',
          type: 'boolean',
          column: 2,
        },
      },
    },
    provinceRow: {
      type: 'group',
      properties: {
        province: {
          label: 'T_FORM_FIELD_PROVICNES',
          type: 'string',
          column: 10,
          placeholder: 'placeholder text',
        },
        provinceRequired: {
          label: 'required',
          type: 'boolean',
          column: 2,
        },
      },
    },
  },
  [BLOCK_TYPE_INPUT]: {
    names: {
      type: 'group',
      width: '900px',
      properties: {
        help: {
          type: 'modal',
          label: 'Help',
          column: 2,
        },
      },
    },
    general: {
      type: 'group',
      width: '900px',
      properties: {
        label: {
          type: 'string',
          label: 'Label',
          column: 4,
        },
        input: {
          type: 'string',
          label: 'Input',
          placeholder: 'Placeholder',
          column: 4,
        },
        validate: {
          label: 'Typ walidacji',
          choices: {
            string: 'Brak walidacji',
            tel: 'Telefon',
            email: 'E-mail',
            url: 'Site address',
            number: 'Number',
          },
          validators: ['required'],
          type: 'choice',
          column: 4,
        },
      },
    },
    custom: {
      type: 'group',
      width: '900px',
      properties: {
        limit: {
          column: 2,
          label: 'Limit znaków',
          type: 'number',
        },
        unit: {
          column: 2,
          label: 'Jednostka',
          type: 'string',
          placeholder: 'np. zł',
        },
        required: {
          label: 'required',
          type: 'boolean',
          column: 4,
        },
      },
    },
  },
  [BLOCK_TYPE_SELECT]: {
    names: {
      type: 'group',
      width: '900px',
      properties: {
        help: {
          type: 'modal',
          label: 'Help',
          column: 2,
        },
      },
    },
    general: {
      type: 'group',
      width: '900px',
      properties: {
        label: {
          type: 'string',
          label: 'Label',
          column: 6,
        },
        required: {
          type: 'boolean',
          column: 6,
          label: 'required',
        },
      },
    },
    select: {
      type: 'subform',
      property: 'select',
    },
  },
  [BLOCK_TYPE_CHECKBOX]: {
    names: {
      type: 'group',
      width: '900px',
      properties: {
        help: {
          type: 'modal',
          label: 'Help',
          column: 2,
        },
      },
    },
    custom: {
      type: 'group',
      width: '900px',
      properties: {
        other: {
          type: 'boolean',
          column: 4,
          label: 'inne',
        },
        required: {
          type: 'boolean',
          column: 8,
          label: 'required',
        },
      },
    },
    select: {
      type: 'subform',
      property: 'select',
    },
  },
  [BLOCK_TYPE_DATA_TIME]: {
    names: {
      type: 'group',
      width: '900px',
      properties: {
        help: {
          type: 'modal',
          label: 'Help',
          column: 2,
        },
      },
    },
    general: {
      type: 'group',
      width: '900px',
      properties: {
        label: {
          type: 'string',
          label: 'Label',
          column: 8,
        },
        required: {
          column: 4,
          label: 'required',
          type: 'boolean',
        },
      },
    },
    custom: {
      type: 'group',
      width: '900px',
      properties: {
        date: {
          column: 2,
          label: 'Data',
          type: 'boolean',
        },
        time: {
          column: 6,
          label: 'Czas',
          type: 'boolean',
        },
      },
    },
  },
  [BLOCK_TYPE_IMPLEMENTATNION]: {
    names: {
      type: 'group',
      width: '900px',
      properties: {
        help: {
          type: 'modal',
          label: 'Help',
          column: 2,
        },
        required: {
          column: 2,
          label: 'required',
          type: 'boolean',
        },
      },
    },
  },
  [BLOCK_TYPE_PARTICIPANTS]: {
    names: {
      type: 'group',
      width: '900px',
      properties: {
        help: {
          type: 'modal',
          label: 'Help',
          column: 2,
        },
        required: {
          column: 2,
          label: 'required',
          type: 'boolean',
        },
      },
    },
    range: {
      type: 'group',
      width: '500px',
      properties: {
        from: {
          type: 'integer',
          label: 'Od',
          column: 6,
        },
        to: {
          type: 'integer',
          label: 'Do',
          column: 6,
        },
      },
    },
  },
  [BLOCK_TYPE_TIMETABLE]: {
    names: {
      type: 'group',
      width: '900px',
      properties: {
        help: {
          type: 'modal',
          label: 'Help',
          column: 2,
        },
        required: {
          column: 2,
          label: 'required',
          type: 'boolean',
        },
      },
    },
  },
  [BLOCK_TYPE_TEXTAREA]: {
    names: {
      type: 'group',
      width: '900px',
      properties: {
        label: {
          type: 'string',
          label: 'Label',
          column: 4,
        },
        input: {
          type: 'string',
          label: 'Input',
          placeholder: 'Placeholder',
          column: 4,
        },
        help: {
          type: 'modal',
          label: 'Help',
          column: 2,
        },
      },
    },
    additional: {
      type: 'group',
      width: '900px',
      properties: {
        required: {
          column: 2,
          label: 'required',
          type: 'boolean',
        },
        limit: {
          column: 2,
          label: 'Limit znaków',
          type: 'number',
        },
        rows: {
          column: 2,
          label: 'Rows',
          type: 'number',
        },
      },
    },
  },
  [BLOCK_TYPE_ADDITIONAL_INFO]: {},
  [BLOCK_TYPE_TARGET_INFORMATIONS]: {},
  [BLOCK_TYPE_COMPANY_DATA]: {},
}

const schema = (type = BLOCK_TYPE_INFO) => ({
  endpoint: '/form_section_blocks',
  resource: {
    definition: 'FormSectionBlock-form_section_block:write',
    properties: properties[type],
  },
  types: {
    [BLOCK_TYPE_COMPANY_DATA]: {
      name: 'Dane organizacji',
      text: 'Bloczek z danymi pobieranymi z danych organizacji',
      isMulti: 'false',
    },
    [BLOCK_TYPE_INSTYTUTION]: {
      name: 'Adres instytucji',
      text: 'Bloczek z danymi pobieranymi z danych organizacji',
      isMulti: false,
    },
    [BLOCK_TYPE_TARGET_INFORMATIONS]: {
      name: 'Informacje o celach',
      text: 'Bloczek z danymi pobieranymi z danych organizacji',
      isMulti: 'false',
    },
    [BLOCK_TYPE_ADDITIONAL_INFO]: {
      name: 'Dodatkowe informacje',
      text: 'Bloczek z danymi pobieranymi z danych organizacji',
      isMulti: 'false',
    },
    [BLOCK_TYPE_INFO]: {
      name: 'Tekst',
      isMulti: true,
    },
    [BLOCK_TYPE_ADDRESS]: {
      name: 'Adres',
      isMulti: true,
    },
    [BLOCK_TYPE_INPUT]: {
      name: 'Input',
      isMulti: true,
    },
    [BLOCK_TYPE_SELECT]: {
      name: 'Lista rozwijana',
      isMulti: true,
    },
    [BLOCK_TYPE_CHECKBOX]: {
      name: 'Checkbox',
      isMulti: true,
    },
    [BLOCK_TYPE_DATA_TIME]: {
      name: 'Data i czas',
      isMulti: true,
    },
    [BLOCK_TYPE_IMPLEMENTATNION]: {
      name: 'Czas realizacji projektu',
      isMulti: false,
    },
    [BLOCK_TYPE_PARTICIPANTS]: {
      name: 'Liczba uczestników',
      isMulti: false,
    },
    [BLOCK_TYPE_TIMETABLE]: {
      name: 'Harmonogram działań',
      isMulti: false,
    },
    [BLOCK_TYPE_TEXTAREA]: {
      name: 'Pole tekstowe',
      isMulti: true,
    },
  },
})

export default schema
