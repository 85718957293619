import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { ButtonDownload } from 'core/components/buttons/button-download'
import { downloadFileFromFile } from '_helpers/downloadFileFromFile'

const useStyles = makeStyles(theme => ({
  tableHead: {
    borderBottom: `1px solid ${theme.palette.separator}`,
  },
  tableFooter: {
    borderTop: `2px solid ${theme.palette.separator}`,
  },
  tableFooterTitle: {
    color: theme.palette.primary.main,
    fontSize: 18,
    border: 0,
    paddingBottom: theme.spacing(3),
    fontWeight: 600,
  },
  tableHeadCell: {
    color: theme.elements.table.tHeadC,
    fontSize: 16,
    fontStyle: 'italic',
    border: 0,
    paddingBottom: theme.spacing(3),
  },
  tableCell: {
    border: 0,
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(16),
  },
  table: {
    marginBottom: theme.spacing(3),
    maxWidth: 600,
  },
}))

export const AttachmentFormValues = ({ resource }) => {
  const classes = useStyles()
  const handleDownload = id => {
    downloadFileFromFile(id)
  }
  return (
    <>
      <Table
        size={'small'}
        aria-label={'Bieżące konkursy'}
        className={classes.table}
      >
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.tableHeadCell}>
              {translate('Załącznik')}
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              {translate('Pobierz')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resource.attachments.map(el => (
            <TableRow key={el['@id']}>
              <TableCell className={classes.tableCell}>{el.title}</TableCell>
              {el.file ? (
                <TableCell className={classes.tableCell}>
                  <ButtonDownload
                    text={'Pobierz'}
                    // path={`${process.env.REACT_APP_API_ENTRYPOINT}${el.file.url}`}
                    onClick={() => {
                      handleDownload(el.file.uuid)
                    }}
                  />
                </TableCell>
              ) : (
                <TableCell className={classes.tableCell}>
                  <Typography variant={'body2'} color={'primary'}>
                    {translate('Brak załącznika')}
                  </Typography>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  )
}
