import { useEffect } from 'react'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import contestSchema from '_schema/contests'

export const useProposalFetch = (
  getEndpoint,
  postEndpoint,
  contest,
  setResource,
  setFetchError
) => {
  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    getProposal(
      getEndpoint,
      postEndpoint,
      contest,
      signal,
      setResource,
      setFetchError
    )

    return () => controller.abort()
  }, [getEndpoint, postEndpoint, contest, setResource, setFetchError])

  return
}

const getProposal = (
  getEndpoint,
  postEndpoint,
  contest,
  signal,
  setResource,
  setFetchError
) => {
  fetchDataHandleAuthError(
    `${getEndpoint}?contest.uuid=${contest}`,
    'GET',
    { signal },
    response => {
      if (!response['hydra:member'].length) {
        postProposal(
          postEndpoint,
          contest,
          {
            body: JSON.stringify({
              contest: `${contestSchema.endpoint}/${contest}`,
            }),
          },
          setResource,
          setFetchError
        )

        return
      }

      setResource(response['hydra:member'][0])
    },
    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
      setFetchError(true)
    }
  )
}

const postProposal = (
  endpoint,
  contest,
  request,
  setResource,
  setFetchError
) => {
  fetchDataHandleAuthError(
    `${endpoint}/${contest}`,
    'PUT',
    request,
    response => {
      setResource(response.applicantProposal)
    },
    error => {
      if (error.response.title === 'AbortError') {
        return
      }

      notification('error', error.response.detail, error.response.title)
      setFetchError(true)
    }
  )
}
