import React, { useMemo } from 'react'
import { standard } from 'core/components/table/_columns/standard'
import { Paper } from 'core/components/Paper'
import { CollectionTable } from 'core/components/table'
import schema from 'core/_schema/user'
import routes from './routes'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { translate } from 'core/_helpers/translate'

export const TrashCollection = () => {
  const columns = useMemo(
    () => [
      {
        ...standard('organizationName'),
        sortable: false,
        filterable: true,
        width: '20%',
      },
      {
        ...standard('email'),
        sortable: false,
        filterable: true,
        width: '10%',
      },
      {
        ...standard('organizationType.title'),
        sortable: false,
        filterable: true,
        width: '10%',
      },
      {
        ...standard('province.title'),
        sortable: false,
        filterable: false,
        width: '10%',
      },
      {
        ...standard('city'),
        sortable: false,
        filterable: true,
        width: '10%',
      },
      {
        ...standard('population.title'),
        sortable: false,
        filterable: false,
        width: '10%',
      },
      {
        ...standard('applicantProposalsFinishedCount'),
        sortable: false,
        filterable: true,
        width: '10%',
      },
      {
        ...standard('applicantProposalsAppliedCount'),
        sortable: false,
        filterable: true,
        width: '10%',
      },
    ],
    []
  )
  return (
    <>
      <CollectionHeader buttonTitle={''} buttonPath={''} hideButton={true}>
        {translate('Kosz')}
      </CollectionHeader>
      <Paper withPadding={false}>
        <SchemableCollection
          component={CollectionTable}
          path={`paths.${schema('applicant').endpoint}.get`}
          endpoint={schema('applicant').endpoint}
          customResourceSchema={schema('applicant').resource}
          columns={columns}
          autoWidth={false}
          filterable={true}
          filterInline={true}
          sortable={true}
          numerable={false}
          customFilters={[
            {
              name: 'isDeleted',
              value: 'true',
              title: 'isDeleted',
            },
          ]}
          resetable={false}
          paginable={true}
          definitionSchema={schema('applicant').resource}
          isRowLinkable={true}
          editPath={routes().item.path}
          storeCollectionId={'trash'}
        />
      </Paper>
    </>
  )
}
