const CONTEST_PAGE_BLOCK_TYPE_PHOTO = 'PHOTO'
export const textWithPhoto = (photoEndpoint, thumbEndpoint) => ({
  name: CONTEST_PAGE_BLOCK_TYPE_PHOTO,
  label: 'T_GENERAL_BLOCK_TYPE_TEXT_WITH_PHOTO',
  properties: {
    type: {
      type: 'hidden',
      defaultValue: CONTEST_PAGE_BLOCK_TYPE_PHOTO,
    },
    title: {
      type: 'string',
      label: 'Tytuł bloku',
      width: '650px',
    },
    photoTitle: {
      type: 'string',
      label: 'Tytuł zdjęcia',
      width: '650px',
    },
    photo: {
      type: 'image',
      description: 'T_GENERAL_PHOTO',
      endpoint: photoEndpoint,
      // thumbs: thumbEndpoint && {
      //   endpoint: thumbEndpoint,
      // },
      validate: ['maxSize'],
    },
    photoDescription: {
      type: 'string',
      label: 'Autor / podpis zdjęcia',
      width: '650px',
    },
    description: {
      label: 'Opis zdjęcia',
      type: 'tinymce',
      fullWidth: true,
      formWidth: 650,
    },
  },
})
