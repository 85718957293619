import React from 'react'
import { translate } from 'core/_helpers/translate'
import { Grid, Typography } from '@material-ui/core'
import { StringType } from 'core/components/form/fields/StringType'

export const Participants = ({ block }) => {
  const values = block.data ? JSON.parse(block.data) : {}
  return (
    <>
      <Typography variant={'body2'} color={'primary'}>
        {translate('Minimalna ilość')}: {values.from || '0'}, {translate('do')}:{' '}
        {values.to || translate('bez ograniczeń')}
      </Typography>
      <Grid container spacing={3} style={{ maxWidth: '500px', marginTop: 15 }}>
        <Grid item xs={12} md={6}>
          <StringType
            name={'from'}
            setValue={() => false}
            disabled={false}
            renderError={false}
            setError={() => false}
            type={'integer'}
            min={values.from || 0}
            max={values.to || null}
            label={'Od'}
            validators={values.required ? ['required'] : null}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StringType
            name={'from'}
            setValue={() => false}
            disabled={false}
            renderError={false}
            setError={() => false}
            type={'integer'}
            label={'Do'}
            min={values.from || 0}
            max={values.to || null}
            validators={values.required ? ['required'] : null}
          />
        </Grid>
      </Grid>
    </>
  )
}
