import React from 'react'
import PropTypes from 'prop-types'
import { useFormStyles } from '.'
import { BlankForm } from 'pages/applicant/BlankForm'
import { FieldWrapper } from 'components/applicant/form'

export const FormStatus = ({
  url,
  method,
  iri = null,
  resource = null,
  definitionSchema,
  customResourceSchema,
  ...rest
}) => {
  const classes = useFormStyles()

  return (
    <BlankForm
      url={url}
      method={method}
      iri={iri}
      resource={resource}
      definitionSchema={definitionSchema}
      customResourceSchema={customResourceSchema}
      classes={{
        root: classes.root,
        buttons: classes.buttons,
        submit: classes.submit,
        loader: classes.loader,
      }}
      {...rest}
    >
      {({ state, properties, resource, setValue, setError }) => (
        <>
          <FieldWrapper
            label="Miejsce na Twoją odpowiedź"
            name="answer"
            property={properties.answer}
            resource={resource}
            state={state}
            setValue={setValue}
            setError={setError}
            classes={{
              root: classes.textarea,
            }}
          />
        </>
      )}
    </BlankForm>
  )
}

FormStatus.propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  resource: PropTypes.object.isRequired,
  definitionSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
  customResourceSchema: PropTypes.shape({
    properties: PropTypes.object.isRequired,
  }).isRequired,
}
