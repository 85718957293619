import { projectConstans } from '_constans/project.constans'

const initialState = {}

export const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectConstans.SET_PROJECT:
      return {
        ...state,
        ...action.payload,
      }
    case projectConstans.REMOVE_PROJECT:
      return {}
    default:
      return state
  }
}
