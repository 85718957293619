export const isContractAttachmentCollectionInvalid = contractAttachments => {
  if (!contractAttachments) {
    return false
  }

  let isInvalid = 0

  contractAttachments.forEach(contractAttachment => {
    if (!contractAttachment) {
      return
    }

    Object.keys(contractAttachment).forEach(field => {
      if (contractAttachment[field]) {
        isInvalid++
      }
    })
  })

  return isInvalid
}
