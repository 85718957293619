import { constants } from './constants'

export const reducer = (state, action) => {
  switch (action.type) {
    case constants.SET_PAGE:
      return {
        ...state,
        config: {
          ...state.config,
          page: action.payload.page,
        },
      }
    case constants.SET_PER_PAGE:
      return {
        ...state,
        config: {
          ...state.config,
          page: 1,
          perPage: action.payload.perPage,
        },
      }
    case constants.SET_INDEX:
      return {
        ...state,
        pageIndex: action.payload.pageIndex,
      }
    case constants.HANDLE_COLUMN_SORTERS:
      return {
        ...state,
        config: {
          ...state.config,
          sorters: {
            ...Object.assign(
              {},
              ...Object.keys(state.config.sorters).map(name => ({
                [name]: {
                  ...state.config.sorters[name],
                  order: action.payload.sorters[name]
                    ? state.config.sorters[name].order ===
                      action.payload.sorters[name].order
                      ? null
                      : action.payload.sorters[name].order
                    : state.config.sorters[name].order,
                },
              }))
            ),
          },
          page: 1,
        },
      }
    case constants.HANDLE_COLUMN_FILTERS:
      return {
        ...state,
        config: {
          ...state.config,
          filters: {
            ...Object.assign(
              {},
              ...Object.keys(state.config.filters).map(name => ({
                [name]: {
                  ...state.config.filters[name],
                  value: action.payload.filters[name]
                    ? action.payload.filters[name].value
                    : state.config.filters[name].value,
                },
              }))
            ),
          },
          page: 1,
        },
      }
    case constants.HANDLE_MANUAL_ORDER_SORTER:
      return {
        ...state,
        config: {
          ...state.config,
          sorters: {
            ...Object.assign(
              {},
              ...Object.keys(state.config.sorters).map(sorter => ({
                [sorter]: {
                  ...state.config.sorters[sorter],
                  order: null,
                },
              }))
            ),
            ord: {
              ...state.config.sorters.ord,
              order:
                state.config.sorters.ord.order === action.payload.order
                  ? null
                  : action.payload.order,
            },
          },
        },
      }
    case constants.SET_SORTERS:
      return {
        ...state,
        config: {
          ...state.config,
          sorters: {
            ...state.config.sorters,
            ...action.payload.sorters,
          },
          page: 1,
        },
      }
    case constants.SET_FILTERS:
      console.log('SET_FILTERS', action.payload.filters)
      return {
        ...state,
        config: {
          ...state.config,
          filters: {
            ...state.config.filters,
            ...action.payload.filters,
          },
          page: 1,
        },
      }
    case constants.RESET:
      return {
        ...state,
        config: {
          ...state.defaultConfig,
        },
      }
    case constants.SET_SINGLE_SELECT:
      return {
        ...state,
        data: {
          ...state.data,
          selected: state.data.selected.map((item, i) =>
            i === action.payload.row ? !item : item
          ),
        },
      }
    case constants.SET_MASS_SELECT:
      return {
        ...state,
        data: {
          ...state.data,
          selected: state.data.selected.map((isSelected, i) =>
            (typeof action.payload.isRowSelectable === 'boolean'
            ? action.payload.isRowSelectable
            : action.payload.isRowSelectable(state.data.items[i]))
              ? !state.data.selectAll
              : isSelected
          ),
          selectAll: !state.data.selectAll,
        },
      }
    case constants.RELOAD:
      return {
        ...state,
        config: {
          ...state.config,
        },
      }
    case constants.FETCH_START:
    case constants.MANUAL_ORDER_CHANGE_START:
    case constants.MASS_SELECT_ACTION_START:
      return {
        ...state,
        isFetching: true,
      }
    case constants.FETCH_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        init: false,
        isFetching: false,
      }
    case constants.MANUAL_ORDER_CHANGE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: action.payload.items,
          selected: action.payload.selected,
        },
        isFetching: false,
      }
    case constants.MASS_SELECT_ACTION_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: action.payload.items,
          selected: new Array(action.payload.items.length).fill(false),
          selectAll: false,
        },
        isFetching: false,
      }
    case constants.MANUAL_ORDER_CHANGE_FAILURE:
    case constants.MASS_SELECT_ACTION_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    case constants.FETCH_FAILURE:
      return {
        ...state,
        init: false,
        isFetching: false,
      }
    case constants.UPDATE_ITEM_FIELD:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items.map(item =>
            item['@id'] === action.payload['@id']
              ? {
                  ...item,
                  [action.payload.accessor]: action.payload.value,
                }
              : item
          ),
        },
      }
    case constants.UPDATE_ITEM:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items.map(item =>
            item['@id'] === action.payload['@id']
              ? {
                  ...item,
                  ...action.payload.value,
                }
              : item
          ),
        },
      }
    case constants.MANUAL_ORDER_PAGE_DROPZONE_SHOW:
      return {
        ...state,
        showManualOrderPageDropzone: true,
      }
    case constants.MANUAL_ORDER_PAGE_DROPZONE_HIDE:
      return {
        ...state,
        showManualOrderPageDropzone: false,
      }
    default:
      return {
        ...state,
        ...action.payload,
      }
  }
}
