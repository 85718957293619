import React from 'react'
import PropTypes from 'prop-types'
import { translate } from 'core/_helpers/translate'
import { DataInfo } from 'components/DataInfo'

export const CompanyTargetInformationsBlock = ({ values }) => {
  return (
    <div>
      <DataInfo
        label={translate('T_MODULE_ORGANIZATION_DATA_ORGANIZATION_GOALS')}
        data={values?.organizationGoals || ''}
      />
      <DataInfo
        label={translate('T_MODULE_ORGANIZATION_DATA_STATUTORY_ACTIVITIES')}
        data={values?.statutoryActivities || ''}
      />
    </div>
  )
}

CompanyTargetInformationsBlock.propTypes = {
  values: PropTypes.object,
}
