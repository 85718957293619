import React, { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { Card, AppBar, Typography } from '@material-ui/core'
import { Logo } from 'core/components/Logo'
import { translate } from 'core/_helpers/translate'
import { useStyles } from '.'
import { Footer } from '../../components/footer/Footer'
import schema from 'core/_schema/user'
import { Form as FormComponent } from 'core/components/form'

export const Register = () => {
  const classes = useStyles()
  const schemaUser = useMemo(() => schema('applicant_register'), [])

  const [isRegister, setIsRegister] = useState(false)

  const handleSuccess = useCallback(() => {
    setIsRegister(true)
  }, [])

  return (
    <div className={classes.root}>
      <Logo className={classes.logo} />
      <Card className={classes.card}>
        <AppBar position="static" className={classes.login_app_bar}>
          <div className={classes.login_menu}>
            <Link to="/login" className={classes.login_link}>
              <Typography variant="h1" component="span" color="primary">
                {translate('T_GENERAL_LOGIN')}
              </Typography>
            </Link>
            <Typography
              variant="h1"
              className={classes.login_title}
              color="secondary"
            >
              <span>{translate('T_GENERAL_CREATE_ACCOUNT')}</span>
            </Typography>
          </div>
        </AppBar>
        {isRegister ? (
          <div className={classes.form_message}>
            <div className={classes.reset_message}>
              {translate('T_GENERAL_REGISTER_FINISHED')}
            </div>
            <Link to="/login" className={classes.lower_link}>
              {translate('T_GENERAL_BACK_TO_LOGIN')}
            </Link>
          </div>
        ) : (
          <div className={classes.form}>
            <div className={classes.form_inner}>
              <FormComponent
                properties={schemaUser.resource.properties}
                url={schemaUser.endpoint}
                handleSuccess={handleSuccess}
                method="POST"
                showSubmitAndStayButton={false}
                showCancelButton={false}
                fieldsFullWidth={true}
                width="100%"
                submitButtonLabel={translate('T_CREATE_ACCOUNT')}
                handleCancel={() => null}
                classes={{
                  submit: classes.submit_register,
                }}
              />
            </div>
          </div>
        )}
      </Card>
      <Footer />
    </div>
  )
}
