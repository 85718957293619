import React from 'react'
import { DataInfo } from 'components/DataInfo'

export const OrganizationData = () => {
  return (
    <>
      <DataInfo data={'ex.687641254'} label={'NIP'} bold />
      <DataInfo
        data={'Pełna nazwa organizacji'}
        label={'Pełna nazwa organizacji'}
        bold
      />
      <DataInfo data={'Typ organizacji'} label={'Typ organizacji'} bold />
    </>
  )
}
