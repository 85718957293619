import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import schemaProposal, {
  ACCEPTATION_STATUS_REJECTED,
  ACCEPTATION_STATUS_SUBSIDIZED,
  APPLICATION_STATUS_DRAFT,
  BUDGET_CHANGE_STATUS_ACCEPTED,
  BUDGET_CHANGE_STATUS_ANSWERED,
  BUDGET_CHANGE_STATUS_NEW,
  BUDGET_CHANGE_STATUS_PENDING,
  BUDGET_CHANGE_STATUS_REJECTED,
  REALIZATION_DATE_CHANGE_STATUS_ACCEPTED,
  REALIZATION_DATE_CHANGE_STATUS_ANSWERED,
  REALIZATION_DATE_CHANGE_STATUS_PENDING,
  REALIZATION_DATE_CHANGE_STATUS_REJECTED,
  REALIZATION_DATE_CHANGE_STATUS_VERIFIED,
  RECOMMENDATION_STATUS_NO,
  REPORT_END_DATE_CHANGE_STATUS_ACCEPTED,
  REPORT_END_DATE_CHANGE_STATUS_PENDING,
  REPORT_END_DATE_CHANGE_STATUS_REJECTED,
  SCHEDULE_CHANGE_STATUS_ACCEPTED,
  SCHEDULE_CHANGE_STATUS_PENDING,
  SCHEDULE_CHANGE_STATUS_REJECTED,
  CONTRACT_STATUS_APPLIED,
  CONTRACT_STATUS_TO_CORRECT,
  CONTRACT_STATUS_CONFIRMED,
  PROJECT_END_REJECTED,
  PROJECT_END_CANCELED,
  PROJECT_END_FINISHED,
  CONTENT_REPORT_STATUS_PENDING,
  CONTENT_REPORT_STATUS_RETURNED,
  CONTENT_REPORT_STATUS_REJECTED,
  CONTENT_REPORT_STATUS_ACCEPTED,
} from '_schema/proposal'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { translate } from 'core/_helpers/translate'
import routesUser from '../Organizations/routes'
import routes from './routes'
import { makeStyles } from '@material-ui/styles'
import { HeaderItem } from './components/HeaderItem'
import { StyledTab, StyledTabs } from 'components/StyledTab'
import { Grid, IconButton } from '@material-ui/core'
import { Paper } from 'core/components/Paper'
import { ListEvent } from './components/ListEvent'
import schemaEvents, {
  TYPE_APPOINTMENT,
  TYPE_BUDGET_SHIFT,
  TYPE_CONTRACT,
  TYPE_CONTRACT_INQUIRY,
  TYPE_COORDINATOR_CHANGE,
  TYPE_PROJECT_END,
  TYPE_PROJECT_END_SHIFT,
  TYPE_PROPOSAL,
  TYPE_QUARTER_DECLARATION,
  TYPE_RATE_FORMAL,
  TYPE_RATE_FORMAL_BIS,
  TYPE_RATE_INTERNAL,
  TYPE_FINAL_REPORT,
  TYPE_RATE_SUBSTANTIVE,
  TYPE_SCHEDULE_SHIFT,
  TYPE_SUMMARY,
  TYPE_TRAINING_INQUIRY,
  TYPE_TRAINING_RESULT,
  TYPE_RELATION,
  TYPE_REPORT_END_DATE_SHIFT,
  TYPE_FINANCIAL_PARTIAL_REPORT,
  TYPE_FINANCIAL_FINAL_REPORT,
  TYPE_CONTENT_PARTIAL_REPORT,
  TYPE_CONTENT_FINAL_REPORT,
  TYPE_RATE_INTERNAL_MANUAL,
  TYPE_SUBSIDY_TRANCHES,
} from '_schema/proposalEvents'
import {
  RATE_PASSED,
  RATE_FAILED,
  INTERNAL_RATE_STATUS_PASSED,
  SUBSTANTIVE_RATE_STATUS_PASSED,
} from '_schema/contestProposalRateGuidelines'
import {
  STATUS_PENDING as RELATION_STATUS_PENDING,
  STATUS_RETURNED as RELATION_STATUS_RETURNED,
  STATUS_REJECTED as RELATION_STATUS_REJECTED,
  STATUS_ACCEPTED as RELATION_STATUS_ACCEPTED,
} from '_schema/proposalRelation'
import {
  STATUS_NOTREPORTED as QUARTER_DECLARATION_STATUS_NOTREPORTED,
  STATUS_REPORTED as QUARTER_DECLARATION_STATUS_REPORTED,
  STATUS_ACCEPTED as QUARTER_DECLARATION_STATUS_ACCEPTED,
} from '_schema/proposalQuarterlyDeclaration'
import { ContainerProposal } from './components/ContainerProposal'
import { RateInternal } from './components/RateInternal'
import { RateFormal } from './components/RateFormal'
import clsx from 'clsx'
import { Summary } from './components/Summary'
import { RateSubstantive } from './components/RateSubstantive'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { TrainingInquiry } from './components/TrainingInquiry'
import { TrainingResult } from './components/TrainingResult'
import { ContractInquiry } from './components/ContractInquiry'
import { Contract } from './components/Contract'
import { Appointment } from './components/Appointment'
import { ProjectEnd } from './components/ProjectEnd'
import { CoordinatorChange } from './components/CoordinatorChange'
import { QuarterDeclaration } from './components/QuarterDeclaration'
import { FinalReport } from './components/FinalReport'
import { Relation } from './components/Relation'
import { BudgetShift } from './components/BudgetShift'
import { ScheduleShift } from './components/ScheduleShift'
import { ProjectEndShift } from './components/ProjectEndShift'
import { ContentPartialReport } from './components/ContentPartialReport'
import { ContentFinalReport } from './components/ContentFinalReport'
import { commonConstants } from 'core/_constants'
import { RESULT_STATUS_PASSED } from '_schema/proposalTraining'
import { ReportEndDateShift } from './components/ReportEndDateShift'
import { ReactComponent as MoreIcon } from 'theme/icons/more.svg'
import { SubsidyTranches } from './components/SubsidyTranches'

const useStyle = makeStyles(theme => ({
  tabs: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(7),
  },
  wraper: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}))

const useStyleHeader = makeStyles(() => ({
  root: {
    maxWidth: '580px',
  },
  buttons: {},
  buttonTitle: {},
}))

export const Item = ({ schema: definitions, match }) => {
  const classesHeader = useStyleHeader()
  const classes = useStyle()
  const parentResource = useSelector(
    state => state.common.currentParentResource
  )
  const currentTabs = useSelector(state => state.common.currentTabs)
  const dispatch = useDispatch()

  const typesEvent = useMemo(
    () => ({
      [TYPE_PROPOSAL]: {
        name: translate('Wniosek'),
        type: TYPE_PROPOSAL,
        tab: ['all'],
        color: 'yellow',
        added: false,
        date: date => date.proposalApplicationDate,
        info: data => (
          <span
            className={clsx(
              data.proposalApplicationStatus !== APPLICATION_STATUS_DRAFT &&
                classes.success
            )}
          >
            {translate(
              `T_MODULE_PROPOSALS_APPLICATION_STATUS_${data.proposalApplicationStatus}`
            )}
          </span>
        ),
        container: props => <ContainerProposal {...props} />,
      },
      [TYPE_RATE_INTERNAL]: {
        name: translate('Ocena wewnętrzna'),
        type: TYPE_RATE_INTERNAL,
        tab: ['all'],
        color: 'yellow',
        added: false,
        date: date => date.proposalInternalRateDate,
        info: data => (
          <>
            {data.proposalInternalRateStatus === INTERNAL_RATE_STATUS_PASSED ? (
              <span className={classes.success}>{translate('Ocenione')}</span>
            ) : (
              <span className={classes.error}>{translate('Nieocenione')}</span>
            )}
          </>
        ),
        container: props => <RateInternal {...props} />,
      },
      [TYPE_RATE_INTERNAL_MANUAL]: {
        name: translate('Ponowna ocena wewnętrzna'),
        type: TYPE_RATE_INTERNAL_MANUAL,
        tab: ['all'],
        color: 'yellow',
        added: true,
        date: date => date.proposalInternalRateDate,
        info: data => (
          <>
            {data.proposalInternalRateStatus === INTERNAL_RATE_STATUS_PASSED ? (
              <span className={classes.success}>{translate('Ocenione')}</span>
            ) : (
              <span className={classes.error}>{translate('Nieocenione')}</span>
            )}
          </>
        ),
        container: props => <RateInternal {...props} />,
      },
      [TYPE_RATE_FORMAL]: {
        name: translate('Ocena formalna'),
        type: TYPE_RATE_FORMAL,
        tab: ['all'],
        color: 'yellow',
        added: false,
        date: date => date.proposalFormalRateDate,
        info: data => (
          <>
            {data.proposalFormalRateStatus ? (
              <span
                className={clsx(
                  data.proposalFormalRateStatus === RATE_PASSED &&
                    classes.success,
                  data.proposalFormalRateStatus === RATE_FAILED && classes.error
                )}
              >
                {translate(
                  `T_EVENT_RATE_FORMAL_STATUS_${data.proposalFormalRateStatus}`
                )}
              </span>
            ) : (
              <span className={classes.error}>{translate('Nieocenione')}</span>
            )}
          </>
        ),
        container: props => <RateFormal {...props} />,
      },
      [TYPE_RATE_FORMAL_BIS]: {
        name: translate('Ponowna ocena formalna'),
        type: TYPE_RATE_FORMAL_BIS,
        tab: ['all'],
        color: 'yellow',
        added: true,
        date: date => date.proposalFormalRateDate,
        info: data => (
          <>
            {data.proposalFormalRateStatus ? (
              <span
                className={clsx(
                  data.proposalFormalRateStatus === RATE_PASSED &&
                    classes.success,
                  data.proposalFormalRateStatus === RATE_FAILED && classes.error
                )}
              >
                {translate(
                  `T_EVENT_RATE_FORMAL_STATUS_${data.proposalFormalRateStatus}`
                )}
              </span>
            ) : (
              <span className={classes.error}>{translate('Nieocenione')}</span>
            )}
          </>
        ),
        container: props => <RateFormal {...props} bis={true} />,
      },
      [TYPE_RATE_SUBSTANTIVE]: {
        name: translate('Ocena merytoryczna'),
        type: TYPE_RATE_SUBSTANTIVE,
        tab: ['all'],
        color: 'yellow',
        added: false,
        date: date => date.proposalSubstantiveRateDate,
        info: data => (
          <>
            {data.proposalSubstantiveRateStatus ===
            SUBSTANTIVE_RATE_STATUS_PASSED ? (
              <span className={classes.success}>{translate('Ocenione')}</span>
            ) : (
              <span className={classes.error}>{translate('Nieocenione')}</span>
            )}
          </>
        ),
        container: props => <RateSubstantive {...props} />,
      },
      [TYPE_SUMMARY]: {
        name: translate('Podsumowanie'),
        type: TYPE_SUMMARY,
        tab: ['all'],
        color: 'yellow',
        added: false,
        date: data => data.proposalAcceptationDate,
        info: data => {
          let classNameAcceptation
          switch (data.proposalAcceptationStatus) {
            case ACCEPTATION_STATUS_SUBSIDIZED:
              classNameAcceptation = classes.success
              break
            case ACCEPTATION_STATUS_REJECTED:
              classNameAcceptation = classes.error
              break
            default:
              classNameAcceptation = null
              break
          }
          let classNameRecommendation
          switch (data.proposalRecommendationStatus) {
            case RECOMMENDATION_STATUS_NO:
              classNameRecommendation = classes.error
              break
            default:
              classNameRecommendation = null
              break
          }
          return (
            <>
              {data.proposalAcceptationStatus && (
                <span className={clsx(classNameAcceptation)}>
                  {translate(
                    `T_ACCEPTATION_STATUS_${data.proposalAcceptationStatus}`
                  )}
                  {', '}
                </span>
              )}
              {data.proposalRecommendationStatus && (
                <span className={clsx(classNameRecommendation)}>
                  {translate(
                    `T_RECOMMENDATION_STATUS_${data.proposalRecommendationStatus}`
                  )}
                </span>
              )}
            </>
          )
        },
        container: props => <Summary {...props} />,
      },
      [TYPE_TRAINING_INQUIRY]: {
        name: translate('Ankieta szkolenia'),
        type: TYPE_TRAINING_INQUIRY,
        tab: ['all'],
        color: 'red',
        added: false,
        date: data => data.proposalTrainingInquiryDate,
        info: data =>
          data.proposalTrainingInquiryDate ? (
            <span className={classes.success}>{translate('Wypełniona')}</span>
          ) : (
            <span>{translate('Oczekuje')}</span>
          ),
        container: props => <TrainingInquiry {...props} />,
      },
      [TYPE_TRAINING_RESULT]: {
        name: translate('Wyniki szkolenia'),
        type: TYPE_TRAINING_RESULT,
        tab: ['all'],
        color: 'red',
        added: false,
        date: data => data.proposalTrainingResultDate,
        info: data =>
          data.training && data.training.resultStatus ? (
            <span
              className={clsx(
                data.training.resultStatus === RESULT_STATUS_PASSED
                  ? classes.success
                  : classes.error
              )}
            >
              {translate(
                `T_PROPOSAL_TRAINING_RESULT_${data.training.resultStatus}`
              )}
            </span>
          ) : (
            <span>{translate('Oczekuje')}</span>
          ),
        container: props => <TrainingResult {...props} />,
      },
      [TYPE_CONTRACT_INQUIRY]: {
        name: translate('Ankieta umowy'),
        type: TYPE_CONTRACT_INQUIRY,
        tab: ['all'],
        color: 'red',
        added: true,
        date: data => data.proposalContractDataAcceptedDate,
        info: data =>
          data.proposalIsContractDataAccepted && (
            <span className={classes.success}>{translate('Złożona')}</span>
          ),
        container: props => <ContractInquiry {...props} />,
      },
      [TYPE_CONTRACT]: {
        name: translate('Umowa'),
        type: TYPE_CONTRACT,
        tab: ['all'],
        color: 'red',
        added: false,
        date: data =>
          data.proposalContractSigningDate || data.proposalContractUploadDate,
        info: data => {
          let classNameAcceptation
          switch (data.proposalContractStatus) {
            case CONTRACT_STATUS_APPLIED:
              classNameAcceptation = null
              break
            case CONTRACT_STATUS_TO_CORRECT:
              classNameAcceptation = classes.error
              break
            case CONTRACT_STATUS_CONFIRMED:
              classNameAcceptation = classes.success
              break
            default:
              classNameAcceptation = null
              break
          }

          return (
            <span className={clsx(classNameAcceptation)}>
              {translate(
                `T_MODULE_PROPOSAL_CONTRACT_STATUS_${data.proposalContractStatus}`
              )}
            </span>
          )
        },
        container: props => <Contract {...props} />,
      },
      [TYPE_QUARTER_DECLARATION]: {
        name: translate('Deklaracja kwartalna'),
        type: TYPE_QUARTER_DECLARATION,
        tab: ['all', 'budget', 'reports'],
        color: 'blue',
        added: false,
        date: data => data?.quarterlyDeclaration[0]?.reportedAt,
        info: data => {
          let classNameAcceptation
          switch (data?.quarterlyDeclaration[0]?.status) {
            case QUARTER_DECLARATION_STATUS_REPORTED:
              classNameAcceptation = null
              break
            case QUARTER_DECLARATION_STATUS_NOTREPORTED:
              classNameAcceptation = classes.error
              break
            case QUARTER_DECLARATION_STATUS_ACCEPTED:
              classNameAcceptation = classes.success
              break
            default:
              classNameAcceptation = null
              break
          }

          return (
            <span className={clsx(classNameAcceptation)}>
              {translate(
                `T_MODULE_PROPOSAL_QUARTERLY_DECLARATIONS_STATUS_${data?.quarterlyDeclaration[0]?.status}`
              )}
            </span>
          )
        },
        container: props => <QuarterDeclaration {...props} />,
      },
      [TYPE_CONTENT_PARTIAL_REPORT]: {
        name: translate('Raport częściowy merytoryczny'),
        type: TYPE_CONTENT_PARTIAL_REPORT,
        tab: ['all', 'reports'],
        color: 'blue',
        added: false,
        date: data => data.createdAt,
        info: data => {
          let classNameAcceptation
          switch (data.proposalPartialReportStatus) {
            case CONTENT_REPORT_STATUS_PENDING:
              classNameAcceptation = null
              break
            case CONTENT_REPORT_STATUS_RETURNED:
            case CONTENT_REPORT_STATUS_REJECTED:
              classNameAcceptation = classes.error
              break
            case CONTENT_REPORT_STATUS_ACCEPTED:
              classNameAcceptation = classes.success
              break
            default:
              classNameAcceptation = null
              break
          }

          return (
            <span className={clsx(classNameAcceptation)}>
              {translate(
                `T_MODULE_PROPOSAL_CONTENT_REPORT_STATUS_${data.proposalPartialReportStatus}`
              )}
            </span>
          )
        },
        container: props => <ContentPartialReport {...props} />,
      },
      [TYPE_CONTENT_FINAL_REPORT]: {
        name: translate('Raport końcowy merytoryczny'),
        type: TYPE_CONTENT_FINAL_REPORT,
        tab: ['all', 'reports'],
        color: 'blue',
        added: false,
        date: data => data.createdAt,
        info: data => {
          let classNameAcceptation
          switch (data.proposalFinalReportStatus) {
            case CONTENT_REPORT_STATUS_PENDING:
              classNameAcceptation = null
              break
            case CONTENT_REPORT_STATUS_RETURNED:
            case CONTENT_REPORT_STATUS_REJECTED:
              classNameAcceptation = classes.error
              break
            case CONTENT_REPORT_STATUS_ACCEPTED:
              classNameAcceptation = classes.success
              break
            default:
              classNameAcceptation = null
              break
          }

          return (
            <span className={clsx(classNameAcceptation)}>
              {translate(
                `T_MODULE_PROPOSAL_CONTENT_REPORT_STATUS_${data.proposalFinalReportStatus}`
              )}
            </span>
          )
        },
        container: props => <ContentFinalReport {...props} />,
      },
      [TYPE_FINAL_REPORT]: {
        name: translate('Raport końcowy'),
        type: TYPE_FINAL_REPORT,
        tab: ['all', 'budget', 'reports'],
        color: 'blue',
        added: false,
        date: () => null,
        info: () => null,
        container: props => <FinalReport {...props} />,
      },
      [TYPE_RELATION]: {
        name: translate('Relacja'),
        type: TYPE_RELATION,
        tab: ['all', 'relations'],
        color: 'blue',
        added: false,
        date: data => data.createdAt,
        info: data => {
          let classNameAcceptation
          switch (data.relation.status) {
            case RELATION_STATUS_PENDING:
              classNameAcceptation = null
              break
            case RELATION_STATUS_REJECTED:
            case RELATION_STATUS_RETURNED:
              classNameAcceptation = classes.error
              break
            case RELATION_STATUS_ACCEPTED:
              classNameAcceptation = classes.success
              break
            default:
              classNameAcceptation = null
              break
          }

          return (
            <span className={clsx(classNameAcceptation)}>
              {translate(
                `T_MODULE_PROPOSAL_RELATIONS_STATUS_${data.relation.status}`
              )}
            </span>
          )
        },
        container: props => <Relation {...props} />,
      },
      [TYPE_APPOINTMENT]: {
        name: translate('Wizyta monitoringowa'),
        type: TYPE_APPOINTMENT,
        tab: ['all'],
        color: 'blue',
        added: true,
        date: data => data.proposalVisitation?.[0]?.updatedAt,
        info: data => {
          let classNameAcceptation
          switch (data.proposalVisitation?.[0]?.status) {
            case 'PENDING':
            case 'FINISHED':
              classNameAcceptation = null
              break
            case 'ACCEPTED':
              classNameAcceptation = classes.success
              break
            default:
              classNameAcceptation = null
              break
          }

          return (
            <span className={clsx(classNameAcceptation)}>
              {data.proposalVisitation?.[0]?.status &&
                translate(
                  `T_MODULE_PROPOSAL_APPOINTMENT_STATUS_${data.proposalVisitation?.[0]?.status}`
                )}
            </span>
          )
        },
        container: props => <Appointment {...props} />,
      },
      [TYPE_BUDGET_SHIFT]: {
        name: translate('Zmiana budżetu'),
        type: TYPE_BUDGET_SHIFT,
        tab: ['all', 'budget'],
        color: 'blue',
        added: false,
        date: data => data.budgetChangeRequest[0].updatedAt,
        info: data => {
          let classNameAcceptation
          switch (data.budgetChangeRequest[0].status) {
            case BUDGET_CHANGE_STATUS_NEW:
              classNameAcceptation = null
              break
            case BUDGET_CHANGE_STATUS_PENDING:
              classNameAcceptation = null
              break
            case BUDGET_CHANGE_STATUS_ANSWERED:
              classNameAcceptation = null
              break
            case BUDGET_CHANGE_STATUS_REJECTED:
              classNameAcceptation = classes.error
              break
            case BUDGET_CHANGE_STATUS_ACCEPTED:
              classNameAcceptation = classes.success
              break
            default:
              classNameAcceptation = null
              break
          }

          return (
            <span className={clsx(classNameAcceptation)}>
              {translate(
                `T_BUDGET_CHANGE_STATUS_${data.budgetChangeRequest[0].status}`
              )}
            </span>
          )
        },
        container: props => <BudgetShift {...props} />,
      },
      [TYPE_SCHEDULE_SHIFT]: {
        name: translate('Zmiana harmonogramu'),
        type: TYPE_SCHEDULE_SHIFT,
        tab: ['all', 'timetable'],
        color: 'blue',
        added: false,
        date: data => data.scheduleRequest[0].updatedAt,
        info: data => {
          let classNameAcceptation
          switch (data.scheduleRequest[0].status) {
            case SCHEDULE_CHANGE_STATUS_PENDING:
              classNameAcceptation = null
              break
            case SCHEDULE_CHANGE_STATUS_REJECTED:
              classNameAcceptation = classes.error
              break
            case SCHEDULE_CHANGE_STATUS_ACCEPTED:
              classNameAcceptation = classes.success
              break
            default:
              classNameAcceptation = null
              break
          }

          return (
            <span className={clsx(classNameAcceptation)}>
              {translate(
                `T_SCHEDULE_CHANGE_STATUS_${data.scheduleRequest[0].status}`
              )}
            </span>
          )
        },
        container: props => <ScheduleShift {...props} />,
      },
      [TYPE_PROJECT_END_SHIFT]: {
        name: translate('Zmiana zakończenia projektu'),
        type: TYPE_PROJECT_END_SHIFT,
        tab: ['all', 'timetable'],
        color: 'blue',
        added: false,
        date: data => data.realizationDateRequest[0].updatedAt,
        info: data => {
          let classNameAcceptation
          switch (data.realizationDateRequest[0].status) {
            case REALIZATION_DATE_CHANGE_STATUS_PENDING:
              classNameAcceptation = null
              break
            case REALIZATION_DATE_CHANGE_STATUS_VERIFIED:
              classNameAcceptation = null
              break
            case REALIZATION_DATE_CHANGE_STATUS_ANSWERED:
              classNameAcceptation = null
              break
            case REALIZATION_DATE_CHANGE_STATUS_REJECTED:
              classNameAcceptation = classes.error
              break
            case REALIZATION_DATE_CHANGE_STATUS_ACCEPTED:
              classNameAcceptation = classes.success
              break
            default:
              classNameAcceptation = null
              break
          }

          return (
            <span className={clsx(classNameAcceptation)}>
              {translate(
                `T_REALIZATION_DATE_CHANGE_STATUS_${data.realizationDateRequest[0].status}`
              )}
            </span>
          )
        },
        container: props => <ProjectEndShift {...props} />,
      },
      [TYPE_PROJECT_END]: {
        name: translate('Zamknięcie projektu'),
        type: TYPE_PROJECT_END,
        tab: ['all'],
        color: 'blue',
        added: true,
        date: data => data.closeRequest?.[0]?.updatedAt,
        info: data => {
          let classNameAcceptation
          switch (data.closeRequest?.[0]?.status) {
            case PROJECT_END_REJECTED:
            case PROJECT_END_CANCELED:
              classNameAcceptation = null
              break
            case PROJECT_END_FINISHED:
              classNameAcceptation = classes.success
              break
            default:
              classNameAcceptation = null
              break
          }

          return (
            <span className={clsx(classNameAcceptation)}>
              {data.closeRequest?.[0]?.status &&
                translate(
                  `T_MODULE_PROJECT_END_STATUS_${data.closeRequest?.[0]?.status}`
                )}
            </span>
          )
        },
        container: props => <ProjectEnd {...props} />,
      },
      [TYPE_COORDINATOR_CHANGE]: {
        name: translate('Zmiana koordynatora'),
        type: TYPE_COORDINATOR_CHANGE,
        tab: ['all'],
        color: 'blue',
        added: true,
        date: () => null,
        info: () => null,
        container: props => <CoordinatorChange {...props} />,
      },
      [TYPE_FINANCIAL_PARTIAL_REPORT]: {
        name: translate('Raport finansowy częściowy'),
        type: TYPE_FINANCIAL_PARTIAL_REPORT,
        tab: ['all', 'budget', 'reports'],
        color: 'blue',
        added: false,
        date: data => data.financialReports?.[0]?.updatedAt,
        info: data => {
          let classNameAcceptation
          switch (data.financialReports?.[0]?.status) {
            case 'NEW':
              classNameAcceptation = null
              break
            case 'REJECTED':
              classNameAcceptation = classes.error
              break
            case 'ACCEPTED':
              classNameAcceptation = classes.success
              break
            default:
              classNameAcceptation = null
              break
          }

          return (
            <span className={clsx(classNameAcceptation)}>
              {data.financialReports?.[0]?.status &&
                translate(
                  `T_MODULE_FINANCIAL_REPORT_STATUS_${data.financialReports?.[0]?.status}`
                )}
            </span>
          )
        },
        container: props => <FinalReport {...props} />,
      },
      [TYPE_FINANCIAL_FINAL_REPORT]: {
        name: translate('Raport finansowy końcowy'),
        type: TYPE_FINANCIAL_FINAL_REPORT,
        tab: ['all', 'budget', 'reports'],
        color: 'blue',
        added: false,
        date: data => data.financialReports?.[0]?.updatedAt,
        info: data => {
          let classNameAcceptation
          switch (data.financialReports?.[0]?.status) {
            case 'NEW':
              classNameAcceptation = null
              break
            case 'REJECTED':
              classNameAcceptation = classes.error
              break
            case 'ACCEPTED':
              classNameAcceptation = classes.success
              break
            default:
              classNameAcceptation = null
              break
          }

          return (
            <span className={clsx(classNameAcceptation)}>
              {data.financialReports?.[0]?.status &&
                translate(
                  `T_MODULE_FINANCIAL_REPORT_STATUS_${data.financialReports?.[0]?.status}`
                )}
            </span>
          )
        },
        container: props => <FinalReport {...props} />,
      },
      [TYPE_REPORT_END_DATE_SHIFT]: {
        name: translate('Zmiana daty raportu końcowego'),
        type: TYPE_REPORT_END_DATE_SHIFT,
        tab: ['all', 'timetable'],
        color: 'blue',
        added: false,
        date: data => data.endDateRequests[0].updatedAt,
        info: data => {
          let classNameAcceptation
          switch (data.endDateRequests[0].status) {
            case REPORT_END_DATE_CHANGE_STATUS_PENDING:
              classNameAcceptation = null
              break
            case REPORT_END_DATE_CHANGE_STATUS_REJECTED:
              classNameAcceptation = classes.error
              break
            case REPORT_END_DATE_CHANGE_STATUS_ACCEPTED:
              classNameAcceptation = classes.success
              break
            default:
              classNameAcceptation = null
              break
          }

          return (
            <span className={clsx(classNameAcceptation)}>
              {translate(
                `T_REPORT_END_CHANGE_STATUS_${data.endDateRequests[0].status}`
              )}
            </span>
          )
        },
        container: props => <ReportEndDateShift {...props} />,
      },
      [TYPE_SUBSIDY_TRANCHES]: {
        name: translate('Transze wypłat dotacji'),
        type: TYPE_SUBSIDY_TRANCHES,
        tab: ['all', 'timetable', 'budget'],
        color: 'blue',
        added: false,
        date: data => null,
        info: data => null,
        container: props => <SubsidyTranches {...props} />,
      },
    }),
    [classes.error, classes.success]
  )

  const iri = `${schemaProposal.endpoint_foundation_item}/${match.params.id}`

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError
  )

  const iriEvents = `${schemaEvents.endpointTab}?proposal.uuid=${match.params.id}&pagination=false`
  const [events, setEvents] = useState([])

  const setSingleEvent = uuid => response => {
    setEvents(state =>
      state.map(e => (e.uuid === uuid ? { ...e, ...response } : e))
    )
  }

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    fetchDataHandleAuthError(iriEvents, 'GET', { signal }, resp => {
      setEvents(resp['hydra:member'])
    })
    return () => controller.abort()
  }, [iriEvents])

  const [tab, setTab] = useState('all')

  const handleChangeTab = useCallback((e, value) => {
    setTab(value)
  }, [])

  const [event, setEvent] = useState(
    match.params.event ? match.params.event : null
  )

  useEffect(() => {
    if (events.length > 0) {
      if (currentTabs[routes().itemWithEvent.path] !== undefined) {
        setEvent(currentTabs[routes().itemWithEvent.path])
      } else if (match.params.event) {
        setEvent(match.params.event)
      } else {
        setEvent(events[0].uuid)
      }
    }
  }, [match.params.event, events, currentTabs])

  const handleChangeEvent = useCallback(
    (value, uuid) => {
      setEvent(value)
      dispatch({
        type: commonConstants.SET_CURRENT_TAB,
        payload: {
          path: routes().itemWithEvent.path,
          value: value,
        },
      })
      window.history.replaceState(
        null,
        '',
        routes()
          .itemWithEvent.path.replace(':id', uuid)
          .replace(':event', value)
      )
    },
    [dispatch]
  )

  const handleUpdateEvents = useCallback(() => {
    fetchDataHandleAuthError(iriEvents, 'GET', {}, resp => {
      setEvents(resp['hydra:member'])
    })
  }, [iriEvents])

  const handleChangeEvents = useCallback(data => {
    setEvents(prevState => [...prevState, data])
  }, [])

  const handleUpdateResource = useCallback(
    data => {
      setState.resource({ ...resource, ...data })
    },
    [resource] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <>
      <CollectionHeader
        hideButton={true}
        hideButtonBack={false}
        buttonTitleLeft={
          !!parentResource && parentResource['@type'] === 'User'
            ? translate('Detal organizacji')
            : translate('Lista projektów')
        }
        buttonPathLeft={
          !!parentResource && parentResource['@type'] === 'User'
            ? routesUser().item.path.replace(':id', parentResource.uuid)
            : !!parentResource && parentResource['@type'] === 'Contest'
            ? routes().list.path.replace(':id', parentResource.uuid)
            : routes().index.path
        }
        small={true}
        classes={classesHeader}
      >
        {resource.organizationName}
        <IconButton
          component={'a'}
          href={`/organizations/${resource.applicant.uuid}`}
          target={'_blank'}
        >
          <MoreIcon />
        </IconButton>
      </CollectionHeader>
      <HeaderItem resource={resource} parentResource={parentResource} />
      <StyledTabs
        value={tab}
        onChange={handleChangeTab}
        variant="scrollable"
        scrollButtons="auto"
        className={classes.tabs}
      >
        <StyledTab value={'all'} label={translate('Wszystkie')} />
        <StyledTab value={'budget'} label={translate('Budżet')} />
        <StyledTab value={'timetable'} label={translate('Harmonogram')} />
        <StyledTab value={'relations'} label={translate('Relacje')} />
        <StyledTab value={'reports'} label={translate('Raporty')} />
      </StyledTabs>
      <div className={classes.wraper}>
        {event && events.length > 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <ListEvent
                resource={resource}
                events={events}
                setEvents={handleChangeEvents}
                typesEvent={typesEvent}
                tab={tab}
                event={event}
                setEvent={handleChangeEvent}
                handleUpdateEvents={handleUpdateEvents}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              {events.length > 0 && (
                <Paper withPadding={false}>
                  {typesEvent[
                    events.find(e => e.uuid === event)?.type
                  ]?.container({
                    resource: resource,
                    updateResource: handleUpdateEvents,
                    updateGlobalResource: handleUpdateResource,
                    event: event,
                    eventObject: events.find(e => e.uuid === event),
                    definitions,
                    setResource: setState.resource,
                    setEvent: setSingleEvent(event),
                  })}
                </Paper>
              )}
            </Grid>
          </Grid>
        )}
      </div>
    </>
  )
}
