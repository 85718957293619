import React, { useCallback, useEffect, useState } from 'react'
import { useEmbeddedCollectionFetch } from 'core/components/embedded/_helpers/useEmbeddedCollectionFetch'
import { constants, reducer } from 'core/components/embedded/_state'
import { performOrderChange } from 'core/components/embedded/_helpers/performOrderChange'
import { useBlockCollectionStyles } from 'core/components/block'
import clsx from 'clsx'
import {
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { ExpandLessOutlined, ExpandMoreOutlined } from '@material-ui/icons'
import {
  BodySkeleton,
  ManuallyOrderableTableBody,
  ManuallyOrderableTableRow,
  ManualOrderDragHandle,
  TableLoader,
} from 'core/components/table'
import { prop } from 'core/_helpers/prop'
import { Status } from 'core/components/resource/Status'
import { Delete } from 'core/components/resource/Delete'
import { Add } from './Add'
import { Form } from 'core/components/form'
import { applyProperties } from 'core/_helpers/applyProperties'
import schema from '_schema/formSection'
import schemaBlock from '_schema/formSectionBlock'
import { boolean } from 'core/_helpers/boolean'
import { BlockCollection } from '../blockSectionForm/BlockCollection'

export const SectionCollection = ({
  endpoint,
  pid,
  parentIri,
  definitionSchema,
  titleAccessor,
  parent,
  disabled = false,
  statable = false,
  statAccessor = 'stat',
  definitions = null,
}) => {
  const [state, dispatch] = useEmbeddedCollectionFetch(
    reducer,
    endpoint,
    pid,
    parent
  )

  const [types, setTypes] = useState(
    Object.keys(schemaBlock().types).map(el => ({
      name: el,
      label: translate(schemaBlock().types[el].name),
      isMulti: schemaBlock().types[el].isMulti,
      isUse: false,
    }))
  )

  const handleAddTypes = useCallback(
    items => {
      const array = types.map(el => {
        return {
          ...el,
          isUse: items.find(item => item.type === el.name) ? true : el.isUse,
        }
      })
      setTypes(array)
    },
    [types]
  )

  const [showSave, setShowSave] = useState({})

  useEffect(() => {
    setShowSave(state.items.map(() => false))
  }, [state.items])

  const defaultClasses = useBlockCollectionStyles()

  const handleDelete = resource => {
    if (disabled) {
      return
    }

    dispatch({
      type: constants.REMOVE_ITEM,
      payload: {
        value: resource,
      },
    })
  }

  const handleOrderChange = useCallback(
    ({ oldIndex, newIndex }) => {
      if (oldIndex === newIndex || disabled) {
        return
      }

      performOrderChange(
        state.items,
        oldIndex,
        newIndex,
        dispatch,
        parent,
        'formSections'
      )
    },
    [disabled, state.items, dispatch, parent]
  )

  const [expanded, setExpanded] = useState({
    switch: false,
    items: {},
  })

  const handleExpand = () => {
    if (state.isFetching) {
      return
    }

    setExpanded(state => ({
      switch: !state.switch,
      items: Object.assign(
        {},
        ...Object.keys(state).map(key => ({ [key]: !state.switch }))
      ),
    }))
  }

  const handleSingleExpand = iri => () => {
    setExpanded(state => ({
      ...state,
      items: {
        ...state.items,
        [iri]:
          state.items[iri] !== undefined ? !state.items[iri] : !state.switch,
      },
    }))
  }

  const handleChange = i => {
    setShowSave(prevState => prevState.map((el, index) => index === i))
  }

  const beforeSubmit = data => {
    const obj = data
    delete obj.formSectionBlocks
    return obj
  }

  return (
    <>
      <div className={clsx(defaultClasses.header)}>
        <div className={defaultClasses.sectionTitle}>
          <Typography color="primary" variant="h2">
            {translate('Sekcje')}
          </Typography>
          <Divider />
        </div>
        <div className={clsx(defaultClasses.massExpand)} onClick={handleExpand}>
          {translate(`T_GENERAL_EXPAND_${expanded.switch ? 'ON' : 'OFF'}`)}
          {expanded.switch ? <ExpandLessOutlined /> : <ExpandMoreOutlined />}
        </div>
      </div>
      <TableContainer className={clsx(defaultClasses.collectionContainer)}>
        <TableLoader show={!state.init && state.isFetching} align="center" />
        <Table size="small">
          <ManuallyOrderableTableBody
            onSortEnd={handleOrderChange}
            helperClass={clsx(defaultClasses.draggedRow)}
            useDragHandle={true}
          >
            {state.init ? (
              <BodySkeleton rows={1} columns={1} />
            ) : state.items.length > 0 ? (
              state.items.map((item, i) => {
                const formSectionBlocks = item.formSectionBlocks
                delete item.formSectionBlocks
                const properties = applyProperties(
                  schema.resource.properties,
                  definitionSchema.properties,
                  'missing'
                )

                const handleSuccess = resource => {
                  dispatch({
                    type: constants.UPDATE_ITEM,
                    payload: {
                      value: resource,
                    },
                  })
                }

                return (
                  <ManuallyOrderableTableRow
                    index={i}
                    key={item[process.env.REACT_APP_RESOURCE_ID]}
                  >
                    <TableCell
                      key="element"
                      className={clsx(defaultClasses.cell)}
                      style={{ width: '100%' }}
                    >
                      <ExpansionPanel
                        className={defaultClasses.expansionPanel}
                        square={true}
                        expanded={
                          expanded.items[item['@id']] !== undefined
                            ? expanded.items[item['@id']]
                            : expanded.switch
                        }
                      >
                        <ExpansionPanelSummary
                          classes={{
                            content: clsx(defaultClasses.expansionPanelSummary),
                          }}
                        >
                          {!disabled ? <ManualOrderDragHandle /> : null}
                          <Typography
                            onClick={handleSingleExpand(item['@id'])}
                            className={clsx(defaultClasses.blockTitle)}
                            color={'primary'}
                            variant={'h2'}
                          >
                            <span>{translate('Sekcja')}</span>
                            {titleAccessor
                              ? prop(item, titleAccessor)
                                ? `: ${prop(item, titleAccessor)}`
                                : `: ${translate('T_GENERAL_MISSING_TITLE')}`
                              : item.translations?.[
                                  process.env.REACT_APP_LOCALE
                                ]?.title
                              ? `: ${
                                  item.translations?.[
                                    process.env.REACT_APP_LOCALE
                                  ]?.title
                                }`
                              : `: ${translate('T_GENERAL_MISSING_TITLE')}`}
                          </Typography>
                          {statable && (
                            <div className={clsx(defaultClasses.blockIcon)}>
                              <Status
                                resource={item}
                                accessor={statAccessor}
                                disabled={disabled || state.isFetching}
                              />
                            </div>
                          )}
                          <div className={clsx(defaultClasses.blockIcon)}>
                            <Delete
                              resource={item}
                              accessor={
                                titleAccessor ||
                                `${process.env.REACT_APP_LOCALE}.title`
                              }
                              disabled={disabled || state.isFetching}
                              isIcon={true}
                              onSuccess={handleDelete}
                            />
                          </div>
                          <div
                            onClick={handleSingleExpand(item['@id'])}
                            className={clsx(defaultClasses.expandIcon)}
                          >
                            {(expanded.items[item['@id']] !== undefined ? (
                              expanded.items[item['@id']]
                            ) : (
                              expanded.switch
                            )) ? (
                              <ExpandLessOutlined />
                            ) : (
                              <ExpandMoreOutlined />
                            )}
                          </div>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                          classes={{
                            root: clsx(defaultClasses.itemContainer),
                          }}
                        >
                          <Form
                            readOnly={disabled}
                            url={item['@id']}
                            method={'PUT'}
                            properties={properties}
                            resource={item}
                            fetchCompareResource={boolean(
                              process.env.REACT_APP_FORM_FETCH_COMPARE_RESOURCE
                            )}
                            handleSuccess={handleSuccess}
                            showSubmitAndStayButton={false}
                            showSubmitButton={showSave[i]}
                            isChange={() => handleChange(i)}
                            showCancelButton={false}
                            fieldsFullWidth={true}
                            width={'100%'}
                            key={`${
                              item[process.env.REACT_APP_RESOURCE_ID]
                            }-${i}`}
                            customBeforeSubmit={beforeSubmit}
                          />
                          <BlockCollection
                            parent={'formSection'}
                            endpoint={
                              schema.subresources.formSectionBlocks.endpoint
                            }
                            pid={item.uuid}
                            parentIri={item['@id']}
                            definitionSchema={
                              definitions[
                                schema.subresources.formSectionBlocks.definition
                              ]
                            }
                            key={item['@id']}
                            titleAccessor={'title'}
                            initialItems={formSectionBlocks}
                            types={types}
                            setTypes={handleAddTypes}
                          />
                        </ExpansionPanelDetails>
                      </ExpansionPanel>
                    </TableCell>
                  </ManuallyOrderableTableRow>
                )
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={2}
                  className={clsx(defaultClasses.emptyResults)}
                >
                  {translate('T_GENERAL_ITEMS_EMPTY')}
                </TableCell>
              </TableRow>
            )}
          </ManuallyOrderableTableBody>
        </Table>
      </TableContainer>
      <div className={clsx(defaultClasses.addContainer)}>
        <Add
          endpoint={endpoint}
          parent={parentIri}
          dispatch={dispatch}
          disabled={disabled || state.isFetching}
        />
      </div>
    </>
  )
}
