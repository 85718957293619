import React from 'react'
import { Route } from 'react-router-dom'
import { Collection } from './Collection'
import { CollectionProposals } from './CollectionProposals'
import { Proposal } from './Proposal'

const routes = () => {
  return {
    contest_expert: {
      title: 'T_MODULE_CONTESTS',
      type: Route,
      render: props => <Collection {...props} />,
      path: '/',
      exact: true,
      resourceAccess: ['ROLE_EXPERT'],
    },
    collection_proposals: {
      title: 'Projekty',
      type: Route,
      render: props => <CollectionProposals {...props} />,
      path: '/contest/:id',
      exact: true,
      resourceAccess: ['ROLE_EXPERT'],
    },
    proposal: {
      title: 'Projekt',
      type: Route,
      render: props => <Proposal {...props} />,
      path: '/contest/:id/proposal/:proposal',
      exact: true,
      resourceAccess: ['ROLE_EXPERT'],
    },
  }
}

export default routes
