import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Typography } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import Moment from 'react-moment'
import schemaGuideline, {
  CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_SUBSTANTIVE,
} from '_schema/contestProposalRateGuidelines'
import schemaSubstantiveRate from '_schema/proposalSubstantiveRate'
import schema from '_schema/proposal'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { useSelector } from 'react-redux'
import { StringType } from 'core/components/form/fields/StringType'
import { EXPERT_RATING_STATUS_CONFIRMED } from '_schema/contests'
import { notification } from 'core/_helpers/notification'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6),
    paddingLeft: theme.spacing(5),
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  subtitle: {
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.separator}`,
  },
  item: {
    maxWidth: 439,
    display: 'grid',
    gridTemplateColumns: '20px auto 100px',
    marginBottom: theme.spacing(6),
    gridGap: 5,
  },
  itemCount: {
    fontWeight: 700,
  },
  itemTitle: {
    marginBottom: theme.spacing(1),
  },
  itemForm: {
    display: 'flex',
    '& span': {
      marginRight: theme.spacing(1),
      paddingTop: 8,
      display: 'block',
    },
    '& input': {
      paddingRight: 0,
      paddingLeft: 3,
    },
  },
  desc: {
    marginBottom: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(3),
  },
}))

const RateItem = ({ count, el, rate, confirmed, handleChange }) => {
  const classes = useStyles()
  return (
    <div className={classes.item}>
      <Typography
        variant={'h2'}
        color={'primary'}
        component={'span'}
        className={classes.itemCount}
      >
        {count}
      </Typography>
      <div>
        <Typography
          variant={'subtitle1'}
          color={'primary'}
          className={classes.itemTitle}
        >
          {el.title}
        </Typography>
        <Typography
          className={classes.desc}
          variant={'subtitle1'}
          component={'p'}
          color={'primary'}
        >
          {el.description}
        </Typography>
        <StringType
          name={'description'}
          setValue={(name, value) =>
            handleChange(rate.rateGuideline, name, value)
          }
          value={rate.description || ''}
          disabled={confirmed === EXPERT_RATING_STATUS_CONFIRMED}
          renderError={false}
          type={'textarea'}
          setError={() => null}
          width={'100%'}
          label={'Uzasadnienie'}
        />
      </div>
      <div className={classes.itemForm}>
        <Typography variant={'subtitle1'} color={'primary'} component={'span'}>
          {el.pointsMin}-{el.pointsMax}
        </Typography>
        <StringType
          name={'points'}
          setValue={(name, value) =>
            handleChange(rate.rateGuideline, name, value)
          }
          disabled={confirmed === EXPERT_RATING_STATUS_CONFIRMED}
          renderError={false}
          type={'integer'}
          setError={() => null}
          value={rate.points}
          label={''}
          width={'48px'}
          max={el.pointsMax}
          min={el.pointsMin}
        />
      </div>
    </div>
  )
}

export const RateSubstantive = ({ resource }) => {
  const classes = useStyles()
  const user = useSelector(state => state.profile)
  const [state, setState] = useState([])
  const [rates, setRates] = useState([])
  const [guideline, setGuideline] = useState([])
  const [fetchingRateEnd, setFetchingRateEnd] = useState(false)

  useEffect(() => {
    const iri = `${schemaSubstantiveRate.endpoint}?proposal.uuid=${resource.uuid}&expert.uuid=${user.uuid}&pagination=false`
    const controller = new AbortController()
    const { signal } = controller
    fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
      setRates(resp['hydra:member'])
      setFetchingRateEnd(true)
    })

    return () => controller.abort()
  }, [resource, user])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    const iri = `${schemaGuideline(null).endpoint}?contest.uuid=${
      resource.contest.uuid
    }&type=${CONTEST_PROPOSAL_RATE_GUIDELINE_TYPE_SUBSTANTIVE}&pagination=false`
    fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
      setGuideline(resp['hydra:member'])
    })
    return () => controller.abort()
  }, [resource])

  useEffect(() => {
    if (guideline.length > 0 && fetchingRateEnd) {
      setState(
        guideline.map(el => {
          const rate = rates.find(r => r.rateGuideline === el['@id'])
          return {
            '@id': rate ? rate['@id'] : null,
            points: rate ? rate.points : 0,
            description: rate ? rate.description : '',
            rateGuideline: el['@id'],
          }
        })
      )
    }
  }, [guideline, rates, fetchingRateEnd])

  const handleChange = useCallback((rateGuideline, name, value) => {
    setState(prevState => {
      const index = prevState.findIndex(
        el => el.rateGuideline === rateGuideline
      )
      const array = [...prevState]
      array[index][name] = value
      return array
    })
  }, [])

  const handleSubmit = useCallback(() => {
    const iri = `${schema.endpoint_expert_substantive_rates}/${resource.uuid}`
    const data = {
      substantiveRatesBis: state,
    }
    fetchDataHandleAuthError(
      iri,
      'PUT',
      { body: JSON.stringify(data) },
      resp => {
        setState(resp.substantiveRatesBis)
        notification('success', translate('Ocena została wystawiona'))
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [state, resource])

  return (
    <div className={classes.root}>
      <Typography variant={'h2'} color={'primary'} className={classes.title}>
        {translate('Kryteria oceny')}
      </Typography>
      <Typography
        variant={'subtitle1'}
        color={'primary'}
        className={classes.subtitle}
      >
        {translate('Ostateczny termin oceny')}:{' '}
        <Moment
          date={resource.contest.formalRatingDateTo}
          format={'DD.MM.YYYY'}
        />
      </Typography>
      {state.length > 0 && (
        <>
          {guideline.map((el, index) => (
            <RateItem
              key={index}
              count={index + 1}
              el={el}
              rate={state.find(st => st.rateGuideline === el['@id'])}
              confirmed={
                rates.find(st => st.rateGuideline === el['@id'])?.confirmed
              }
              handleChange={handleChange}
            />
          ))}
        </>
      )}
      <div className={classes.buttons}>
        <Button
          color={'secondary'}
          variant={'contained'}
          onClick={handleSubmit}
          disabled={
            rates.length > 0 &&
            rates[0].confirmed === EXPERT_RATING_STATUS_CONFIRMED
          }
        >
          <span>{translate('Oceń merytorycznie')}</span>
        </Button>
      </div>
    </div>
  )
}
