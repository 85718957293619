import { RelationAttachmentCollectionType } from 'pages/applicant/InProgressProposals/Relations/components/form/fields'

export const STATUS_DRAFT = 'DRAFT'
export const STATUS_PENDING = 'PENDING'
export const STATUS_RETURNED = 'RETURNED'
export const STATUS_REJECTED = 'REJECTED'
export const STATUS_ACCEPTED = 'ACCEPTED'

const schema = {
  endpoint: '/proposal_relations',
  endpoint_download_attachments: '/proposal_relations/:id/attachments/download',
  resource: {
    definition: 'ProposalRelation-proposal_relation:write',
    properties: {
      title: {
        width: '400px',
        type: 'string',
        validate: [ 'required' ]
      },
      description: {
        type: 'textarea',
        rows: 8,
        rowsMax: 8,
        width: '400px',
        validate: [ 'required' ]
      },
      link: {
        width: '400px',
        type: 'string',
      },
      attachments: {
        type: RelationAttachmentCollectionType,
        defaultValue: [],
      },
      isPublicationAccepted: {
        defaultValue: false
      },
      proposal: {},
    },
  },
}

export default schema
