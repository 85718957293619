import React from 'react'
import PropTypes from 'prop-types'
import { useCollectionTabsStyles } from 'components/applicant/collection_styles/CollectionTabs.styles'
import { Link } from 'react-router-dom'
import { Tabs, Tab } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import routes from '../routes'
import { ArrowBackOutlined } from '@material-ui/icons'

export const ProjectScheduleTabs = ({ value, classes = {}, id }) => {
  const defaultClasses = useCollectionTabsStyles()

  return (
    <div className={classes.root}>
      <div className={defaultClasses.bar}>
        <Link to=".." className={defaultClasses.back_to_list}>
          <ArrowBackOutlined /> W realizacji
        </Link>
        <h2 className={defaultClasses.title}>Harmonogram działań</h2>
      </div>
      <Tabs
        value={value}
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        indicatorColor="primary"
        style={{ marginRight: 30 }}
        classes={{ indicator: defaultClasses.indicator }}
        TabIndicatorProps={{ children: <span /> }}
      >
        <Tab
          disableRipple
          value={routes().leadtime.path.replace(':id', id)}
          label={translate(routes().leadtime.title)}
          to={routes().leadtime.path.replace(':id', id)}
          component={Link}
          classes={{ root: defaultClasses.tab }}
        />
        <Tab
          disableRipple
          value={routes().details.path.replace(':id', id)}
          label={translate(routes().details.title)}
          to={routes().details.path.replace(':id', id)}
          component={Link}
          classes={{ root: defaultClasses.tab }}
        />
        <Tab
          disableRipple
          value={routes().dateofsubmission.path.replace(':id', id)}
          label={translate(routes().dateofsubmission.title)}
          to={routes().dateofsubmission.path.replace(':id', id)}
          component={Link}
          classes={{ root: defaultClasses.tab }}
        />
      </Tabs>
    </div>
  )
}

ProjectScheduleTabs.propTypes = {
  value: (props, propName, componentName) => {
    if (
      [
        routes().leadtime.path,
        routes().details.path,
        routes().dateofsubmission.path,
      ].includes(props[propName])
    ) {
      return
    }

    return new Error(
      'Invalid prop `' +
        propName +
        '` supplied to' +
        ' `' +
        componentName +
        '`. Validation failed.'
    )
  },
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
}
