import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: '1.5em',
  },
  field: {},
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(5),
    overflow: 'hidden',
    '& hr': {
      display: 'block',
      width: '100%',
      backgroundColor: theme.palette.separator,
    },
    '& h2': {
      display: 'block',
      width: '100%',
      flexGrow: 1,
      whiteSpace: 'nowrap',
      paddingRight: theme.spacing(3),
    },
  },
  sectionTitleText: {
    display: 'flex',
    '& h6': {
      whiteSpace: 'nowrap',
      lineHeight: '26px',
    },
  },
  sectionTitleSmMargin: {
    marginTop: theme.spacing(2),
  },
  recordChanged: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.info.main,
    '&> span': {
      marginLeft: 10,
    },
  },
  buttonsNotFixed: {
    display: 'flex',
    marginTop: theme.spacing(3),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  buttonsFixed: {
    position: 'fixed',
    boxShadow:
      '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);',
    background: theme.elements.content,
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridColumnGap: '24px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 'calc(100% - 56px)',
    bottom: 0,
    right: 0,
    paddingRight: '24px',
    paddingBottom: '1.5em',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 1,
  },
  buttonsFixedOpen: {
    width: 'calc(100% - 240px)',
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  columnFields: {
    background: theme.palette.white,
    padding: theme.spacing(3),
    minHeight: '195px',
  },
  columnFieldsTitle: {
    marginBottom: theme.spacing(4),
  },
}))
