import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import proposalSchema from '_schema/proposal'
import { useCollectionTabsStyles } from 'components/applicant/collection_styles/CollectionTabs.styles'
import { Link } from 'react-router-dom'
import { ArrowBackOutlined } from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'
import routes from '../routes'
import { Form } from './components'
import { Sidebar } from 'pages/applicant/AppliedProposals/components'
import { useProposalState } from 'pages/applicant/InProgressProposals/_helpers/useProposalState'
import { useProposalFetch } from 'pages/applicant/InProgressProposals/_helpers/useProposalFetch'
import { useVisitFetch } from './_helpers/useVisitFetch'
import moment from 'moment'

const DATE_FORMAT = 'DD.MM.YYYY HH:mm'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 16,
    paddingLeft: 45,
    paddingRight: 32,
  },
  left_col: {
    backgroundColor: 'white',
    marginLeft: 45,
    marginRight: 100,
    marginTop: 30,
    padding: 30,
  },
  label: {
    fontSize: 16,
    color: theme.palette.wariant,
    marginBottom: 4,
    lineHeight: 1.4,
  },
  label_bold: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 14,
    lineHeight: 1.4,
  },
}))

export const Visit = ({ schema: definitions, match }) => {
  const iri = `${proposalSchema.endpoint}/${match.params.id}`

  const [proposalState, setProposalState] = useProposalState()

  const { proposal, contest, isFetching, fetchError } = proposalState

  useProposalFetch(
    iri,
    setProposalState.proposal,
    setProposalState.contest,
    setProposalState.fetchError,
    setProposalState.subresourceFetched
  )

  const [resource, setResource] = useVisitFetch(
    '/proposal_visitations',
    match.params.id
  )

  const handleSuccess = response => {
    setResource({
      items: response,
    })
  }

  const localClasses = useStyles()
  const defaultClasses = useCollectionTabsStyles()

  return isFetching || resource.init ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <Grid container>
      <Grid item xs={8}>
        <div className={localClasses.root}>
          <div className={defaultClasses.bar}>
            <Link
              to={routes().view.path.replace(':id', match.params.id)}
              className={defaultClasses.back_to_list}
            >
              <ArrowBackOutlined /> W realizacji
            </Link>
            <h2 className={defaultClasses.title}>
              Planowana wizyta monitoringowa
            </h2>
          </div>
        </div>
        <div className={localClasses.left_col}>
          {resource?.items?.status === 'PENDING' && (
            <>
              <div className={localClasses.label}>Wizytujący:</div>
              <div className={localClasses.label_bold}>
                {resource?.items.whoWillVisitation}
              </div>
              <div className={localClasses.label}>Wyznaczony termin:</div>
              <div className={localClasses.label_bold}>
                {moment(resource?.items.visitationDate).format(DATE_FORMAT)}
              </div>

              <Form
                url={resource?.items['@id']}
                method="PUT"
                iri={iri}
                resource={{ status: 'ACCEPTED' }}
                definitionSchema={
                  definitions[proposalSchema.resource_visit.definition]
                }
                customResourceSchema={proposalSchema.resource_visit}
                submitButtonTitle={'Wyślij'}
                visit={resource?.items}
                handleSuccess={handleSuccess}
              />
            </>
          )}

          {resource?.items?.status === 'ACCEPTED' && (
            <>
              <div className={localClasses.label}>Wizytujący:</div>
              <div className={localClasses.label_bold}>
                {resource?.items.whoWillVisitation}
              </div>
              <div className={localClasses.label}>
                Wyznaczony termin wizyty:
              </div>
              <div className={localClasses.label_bold}>
                {moment(resource?.items.visitationDate).format(DATE_FORMAT)}
              </div>
              <div className={localClasses.label}>
                Potwierdzony termin wizyty:
              </div>
              <div className={localClasses.label_bold}>
                {resource?.items?.userVisitationAcceptance ? 'TAK' : 'NIE'}
              </div>
              <div className={localClasses.label}>Miejsce spotkania :</div>
              <div className={localClasses.label_bold}>
                {resource?.items?.address}
              </div>
              <div className={localClasses.label}>
                Potwierdzona obecność koordynatora:
              </div>
              <div className={localClasses.label_bold}>
                {resource?.items?.coordinatorPresenceConfirmation
                  ? 'TAK'
                  : 'NIE'}
              </div>
              <div className={localClasses.label}>
                Potwierdzona obecność grupy projektowej:
              </div>
              <div className={localClasses.label_bold}>
                {resource?.items?.projectGroupPresenceConfirmation
                  ? 'TAK'
                  : 'NIE'}
              </div>
              <div className={localClasses.label}>
                Zasady organizacji wizyt monitoringowych
              </div>
              <Typography variant={'body1'}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: resource?.items?.visitationDescription,
                  }}
                ></div>
              </Typography>
              <div className={localClasses.label}>
                Zapoznałem/-am się z zasadami organizacji wizyt monitoringowych:
              </div>
              <div className={localClasses.label_bold}>
                {resource?.items?.visitationRulesAcceptation ? 'TAK' : 'NIE'}
              </div>
              <div className={localClasses.label}>Dodatkowe uwagi :</div>
              <div className={localClasses.label_bold}>
                {resource?.items?.description}
              </div>
            </>
          )}
        </div>
      </Grid>
      <Grid item xs={4}>
        <Sidebar
          proposalUuid={match.params.id}
          contestUuid={contest?.uuid}
          collectingEndDate={contest?.collectingEndDate}
          applicationDate={proposal?.applicationDate}
          formalRatingDateTo={contest?.formalRatingDateTo}
          contractSigningDate={proposal?.contractSigningDate}
          contractSignedFile={proposal?.contractFile}
          contestTrainings={contest.trainings}
          acceptationStatus={proposal.acceptationStatus}
          proposalVisitation={proposal.proposalVisitation[0]}
          withEditButton={false}
        />
      </Grid>
    </Grid>
  )
}
