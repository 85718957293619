import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CoordinatorItemType } from './CoordinatorItemType'
import { generateCoordinatorCollectionRenderError } from 'pages/applicant/Proposals/_helpers/generateCoordinatorCollectionRenderError'
import { useCoordinatorCollectionTypeStyles } from './CoordinatorCollectionType.styles'

export const CoordinatorCollectionType = ({
  name,
  value,
  disabled,
  renderError,
  setValue,
  setError,
  isFormSubmitted,
}) => {
  const [state, setState] = useState({
    values: value,
    errors: value ? Array(value.length).fill({}) : [],
    renderError: value ? Array(value.length).fill({}) : [],
    isInvalid: false,
    isFormSubmitted: false,
    init: true,
  })

  useEffect(() => {
    if (state.values.length === 0) {
      setState(state => ({
        ...state,
        values: [...state.values, {}],
        errors: [...state.errors, {}],
        renderError: [...state.renderError, {}],
      }))
    }
  }, [state])

  useEffect(() => {
    setValue(name, state.values, !state.init)
  }, [name, state.values, state.init, setValue])

  useEffect(() => {
    setError(name, state.isInvalid)
  }, [name, setError, state.isInvalid])

  useEffect(() => {
    if (!isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      isFormSubmitted,
    }))
  }, [setState, isFormSubmitted])

  useEffect(() => {
    if (!state.isFormSubmitted) {
      return
    }

    setState(state => ({
      ...state,
      renderError: generateCoordinatorCollectionRenderError(state.errors, true),
    }))
  }, [setState, state.isFormSubmitted])

  const classes = useCoordinatorCollectionTypeStyles()

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.title}>
          Osoba do kontaktu w sprawie wniosku lub dotacji
        </div>
        {state.values.map((item, index) => (
          <div className={classes.container} key={index}>
            <div>
              <CoordinatorItemType
                index={index}
                values={state.values?.[index]}
                errors={state.errors[index]}
                renderError={state.renderError[index]}
                globalRenderError={renderError}
                setState={setState}
                disabled={disabled}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

CoordinatorCollectionType.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.array,
  disabled: PropTypes.bool,
  renderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  isFormSubmitted: PropTypes.bool.isRequired,
}
