import React, { useCallback, useEffect, useState } from 'react'
import { SectionTitle } from 'core/components/SectionTitle'
import { makeStyles } from '@material-ui/styles'
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { translate } from 'core/_helpers/translate'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { StringType } from 'core/components/form/fields/StringType'
import schema from '_schema/proposalCoordinators'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { ButtonEdit } from 'core/components/buttons/button-edit'
import { ButtonTrash } from 'core/components/buttons/button-trash'

const useStyle = makeStyles(theme => ({
  tableHead: {
    borderBottom: `1px solid ${theme.palette.separator}`,
  },
  tableCell: {
    border: 0,
    color: theme.palette.primary.main,
  },
  table: {
    marginBottom: theme.spacing(3),
  },
  tableHeadCell: {
    color: theme.elements.table.tHeadC,
    fontSize: 16,
    fontStyle: 'italic',
    border: 0,
    paddingBottom: theme.spacing(3),
  },
  buttonSave: {
    marginBottom: theme.spacing(3),
  },
  buttonsEdit: {
    display: 'flex',
  },
}))

const Row = ({ el, handleChangeItem, handleRemoveItem }) => {
  const classes = useStyle()
  const [coordinator, setCoordinator] = useState(el)
  const [edit, setEdit] = useState(false)
  const handleChangeCoordinator = useCallback((name, value) => {
    setCoordinator(prevState => ({ ...prevState, [name]: value }))
  }, [])
  const handleSubmitCoordinator = useCallback(() => {
    handleChangeItem(coordinator)
    setEdit(prevState => !prevState)
  }, [coordinator, handleChangeItem])
  const handleChangeEdit = useCallback(() => {
    setEdit(prevState => !prevState)
  }, [])
  return (
    <TableRow>
      <TableCell className={classes.tableCell}>
        {edit ? (
          <>
            <StringType
              name={'fullName'}
              setValue={handleChangeCoordinator}
              disabled={false}
              renderError={false}
              type={'string'}
              setError={() => false}
              label={''}
              value={coordinator.fullName}
            />
          </>
        ) : (
          <>{el.fullName}</>
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {edit ? (
          <>
            <StringType
              name={'email'}
              setValue={handleChangeCoordinator}
              disabled={false}
              renderError={false}
              type={'string'}
              setError={() => false}
              label={''}
              value={coordinator.email}
            />
          </>
        ) : (
          <>{el.email}</>
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {edit ? (
          <>
            <StringType
              name={'phone'}
              setValue={handleChangeCoordinator}
              disabled={false}
              renderError={false}
              type={'string'}
              setError={() => false}
              label={''}
              value={coordinator.phone}
            />
          </>
        ) : (
          <>{el.phone}</>
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {edit ? (
          <>
            <StringType
              name={'role'}
              setValue={handleChangeCoordinator}
              disabled={false}
              renderError={false}
              type={'string'}
              setError={() => false}
              label={''}
              value={coordinator.role}
            />
          </>
        ) : (
          <>{el.role}</>
        )}
      </TableCell>
      <TableCell className={classes.tableCell}>
        {edit ? (
          <>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={handleSubmitCoordinator}
              className={classes.buttonSave}
            >
              <span>{translate('Zapisz')}</span>
            </Button>
          </>
        ) : (
          <div className={classes.buttonsEdit}>
            <ButtonEdit text={''} onClick={handleChangeEdit} />
            <ButtonTrash text={''} onClick={() => handleRemoveItem(el)} />
          </div>
        )}
      </TableCell>
    </TableRow>
  )
}

export const CoordinatorChange = ({ resource, updateGlobalResource }) => {
  const classes = useStyle()
  const [coordinators, setCoordinators] = useState(resource.coordinators)
  const [addNewStat, setAddNewStat] = useState(false)

  const handleAddNewButtonClick = useCallback(() => {
    setAddNewStat(prevState => !prevState)
  }, [])

  const [newCoordinator, setNewCoordinator] = useState({
    fullName: '',
    role: '',
    email: '',
    phone: '',
    proposal: resource['@id'],
  })

  const handleChangeNewCoordinator = useCallback((name, value) => {
    setNewCoordinator(prevState => ({ ...prevState, [name]: value }))
  }, [])

  const handleSubmitNewCoordinator = useCallback(() => {
    const iri = schema.endpoint
    fetchDataHandleAuthError(
      iri,
      'POST',
      { body: JSON.stringify(newCoordinator) },
      resp => {
        setCoordinators(prevState => [...prevState, resp])
        setAddNewStat(false)
        setNewCoordinator({
          fullName: '',
          role: '',
          email: '',
          phone: '',
          proposal: resource['@id'],
        })
        notification('success', translate('Dodano nowego koordynatora'))
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [newCoordinator, resource])

  useEffect(() => {
    updateGlobalResource({ coordinators: coordinators })
  }, [coordinators]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeItem = useCallback(
    el => {
      const iri = `${schema.endpoint}/${el.uuid}`
      fetchDataHandleAuthError(
        iri,
        'PUT',
        { body: JSON.stringify(el) },
        () => {
          const newArray = coordinators.map(item =>
            el.uuid === item.uuid ? el : item
          )
          setCoordinators(newArray)
          notification('success', translate('Zaktualizowano koordynatora'))
        },
        error => {
          notification('error', error.response.detail, error.response.title)
        }
      )
    },
    [coordinators]
  )

  const handleRemoveItem = useCallback(
    el => {
      const iri = `${schema.endpoint}/${el.uuid}`
      fetchDataHandleAuthError(
        iri,
        'DELETE',
        {},
        () => {
          const newArray = coordinators.filter(item => item.uuid !== el.uuid)
          setCoordinators(newArray)
          notification('success', translate('Usunięto koordynatora'))
        },
        error => {
          notification('error', error.response.detail, error.response.title)
        }
      )
    },
    [coordinators]
  )

  return (
    <>
      <SectionTitle label={'Zmiana koordynatora projektu'} marginTopSm={true} />
      <Table
        size={'small'}
        aria-label={'Oceny merytoryczne'}
        className={classes.table}
      >
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.tableHeadCell}>
              {translate('Imię i Nazwisko')}
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              {translate('Email')}
            </TableCell>
            <TableCell className={classes.tableHeadCell}>
              {translate('Telefon')}
            </TableCell>
            <TableCell className={classes.tableHeadCell} colSpan={2}>
              {translate('Funkcja')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {coordinators.map((el, index) => (
            <Row
              el={el}
              key={index}
              handleChangeItem={handleChangeItem}
              handleRemoveItem={handleRemoveItem}
            />
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            {addNewStat ? (
              <>
                <TableCell className={classes.tableCell}>
                  <StringType
                    name={'fullName'}
                    setValue={handleChangeNewCoordinator}
                    disabled={false}
                    renderError={false}
                    type={'string'}
                    setError={() => false}
                    label={''}
                    value={newCoordinator.fullName}
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <StringType
                    name={'email'}
                    setValue={handleChangeNewCoordinator}
                    disabled={false}
                    renderError={false}
                    type={'string'}
                    setError={() => false}
                    label={''}
                    value={newCoordinator.email}
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <StringType
                    name={'phone'}
                    setValue={handleChangeNewCoordinator}
                    disabled={false}
                    renderError={false}
                    type={'string'}
                    setError={() => false}
                    label={''}
                    value={newCoordinator.phone}
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <StringType
                    name={'role'}
                    setValue={handleChangeNewCoordinator}
                    disabled={false}
                    renderError={false}
                    type={'string'}
                    setError={() => false}
                    label={''}
                    value={newCoordinator.role}
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Button
                    variant="contained"
                    color="secondary"
                    size="small"
                    onClick={handleSubmitNewCoordinator}
                    className={classes.buttonSave}
                  >
                    <span>{translate('Dodaj')}</span>
                  </Button>
                </TableCell>
              </>
            ) : (
              <TableCell colSpan={3} className={classes.tableCell}>
                <ButtonPlus
                  text={'Dodaj nowego'}
                  onClick={handleAddNewButtonClick}
                />
              </TableCell>
            )}
          </TableRow>
        </TableFooter>
      </Table>
    </>
  )
}
