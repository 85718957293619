import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Select, MenuItem, FormControl } from '@material-ui/core'
import { translate } from 'core/_helpers/translate'

const START_YEAR = 2021
const YEAR_FORMAT = 'YYYY'

export const FilterBar = ({ filters, handleFilters }) => {

  const handleYearChange = e => {
    const value = e.target.value
    handleFilters({
      'contestEdition.year': value === 'all' ? null : value,
    })
  }

  const currentYear = parseInt(moment().format(YEAR_FORMAT))

  return (
    <FormControl variant="outlined">
      <Select
        value={
          [null, 'all'].includes(filters['contestEdition.year'])
            ? 'all'
            : JSON.stringify(filters['contestEdition.year'])
        }
        onChange={handleYearChange}
      >
        <MenuItem value="all">{translate('T_GENERAL_ALL_YEARS')}</MenuItem>
        {[...Array(currentYear - START_YEAR + 1).keys()].reverse().map(i => (
          <MenuItem value={START_YEAR + i} key={START_YEAR + i}>
            {START_YEAR + i}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

FilterBar.propTypes = {
  filters: PropTypes.object.isRequired,
  handleFilters: PropTypes.func.isRequired,
}
