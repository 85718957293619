import React from 'react'
import { ShowDetailContest } from 'components/showDetailContest/ShowDetailContest'

export const DetailContest = ({ resource }) => {
  return (
    <>
      <ShowDetailContest resource={resource} />
    </>
  )
}
