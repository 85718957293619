import { makeStyles } from '@material-ui/styles'

export const useCollectionContainerStyles = makeStyles({
  tabs: {
    paddingTop: 16,
    paddingLeft: 45,
    paddingRight: 32,
  },
  paper: {
    padding: 0,
    marginTop: 25,
  },
  paper_wrapper: {
    padding: 0,
    marginTop: 25,
    '& .MuiPaper-root' : {
      paddingLeft: 57,
      paddingRight: 57
    }
  }
})
