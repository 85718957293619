import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'
import { DataInfo } from 'components/DataInfo'
import { Grid } from '@material-ui/core'

const useStyles = makeStyles({
  row_post: {
    maxWidth: 600,
  },
})

export const CompanyAddressBlock = ({ values, provinces }) => {
  const classes = useStyles()

  return (
    <div>
      <Grid container spacing={3} className={classes.row_post}>
        <Grid item xs={6}>
          <DataInfo
            label={translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_POST_OFFICE')}
            data={values?.postOffice || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <DataInfo
            label={translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_POST_CODE')}
            data={values?.postCode || ''}
          />
        </Grid>
      </Grid>
      <DataInfo
        label={translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_ADDRESS')}
        data={values?.address || ''}
      />
      <Grid container spacing={3} className={classes.row_post}>
        <Grid item xs={6}>
          <DataInfo
            label={translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_CITY')}
            data={values?.city || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <DataInfo
            label={translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_COMMUNE')}
            data={values?.commune || ''}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes.row_post}>
        <Grid item xs={6}>
          <DataInfo
            label={translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_DISTRICT')}
            data={values?.district || ''}
          />
        </Grid>
        <Grid item xs={6}>
          <DataInfo
            label={translate('T_MODULE_PROPOSALS_BLOCK_ADDRESS_PROVINCE')}
            data={
              provinces?.find(province => province['@id'] === values?.province)
                ?.title || ''
            }
          />
        </Grid>
      </Grid>
    </div>
  )
}

CompanyAddressBlock.propTypes = {
  values: PropTypes.object,
  provinces: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ),
}
