import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Grid, Typography } from '@material-ui/core'
import { SectionTitle } from 'core/components/SectionTitle'
import { translate } from 'core/_helpers/translate'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import Moment from 'react-moment'
import schema, {
  BUDGET_CHANGE_STATUS_REJECTED,
  REALIZATION_DATE_CHANGE_STATUS_ACCEPTED,
  REALIZATION_DATE_CHANGE_STATUS_PENDING,
  REALIZATION_DATE_CHANGE_STATUS_REJECTED,
} from '_schema/proposal'
import { CustomDialog } from 'core/components/Dialog'
import { StringType } from 'core/components/form/fields/StringType'
import clsx from 'clsx'
import { notification } from 'core/_helpers/notification'

const useStyle = makeStyles(theme => ({
  marginBot: {
    marginBottom: theme.spacing(5),
  },
  textGreen: {
    color: theme.palette.success.main,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
  buttonRed: {
    borderColor: theme.palette.error.main,
    '& .MuiButton-label': {
      color: theme.palette.error.main,
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 24px rgba(11, 43, 124, 0.4)',
      '& .MuiButton-label': {
        color: theme.palette.primary.main,
      },
    },
  },
}))

export const ProjectEndShift = ({ eventObject, updateResource }) => {
  const classes = useStyle()
  const [state, setState] = useState(false)
  const handleChagneState = state => {
    setState(state)
  }

  const [realizationDateChange, setRealizationDateChange] = useState({})
  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    const iri = eventObject.realizationDateRequest[0]['@id']
    fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
      setRealizationDateChange(resp)
    })
    return () => controller.abort()
  }, [eventObject.realizationDateRequest])

  const [question, setQuestion] = useState('')
  const handleChangeQuestion = useCallback(
    (name, value) => setQuestion(value),
    []
  )

  const handleQuestionShift = useCallback(() => {
    const iri = `${schema.endpoint_realization_date_requests}/${realizationDateChange.uuid}/question`
    fetchDataHandleAuthError(
      iri,
      'PATCH',
      { body: JSON.stringify({ question }) },
      resp => {
        setRealizationDateChange(resp)
        setState(false)
        notification('success', translate('Odpowiedź została wysłana'))
        updateResource()
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [question, realizationDateChange.uuid, updateResource])

  const handleRejectShift = useCallback(() => {
    const iri = `${schema.endpoint_realization_date_requests}/${realizationDateChange.uuid}/reject`
    fetchDataHandleAuthError(
      iri,
      'PATCH',
      { body: JSON.stringify({ message: question }) },
      resp => {
        setRealizationDateChange(resp)
        setState(false)
        notification(
          'warning',
          translate(
            'Wniosek o zmianę daty zakończenia projektu został odrzucony'
          )
        )
        updateResource()
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [question, realizationDateChange.uuid, updateResource])

  const handleAcceptShift = useCallback(() => {
    const iri = `${schema.endpoint_realization_date_requests}/${realizationDateChange.uuid}/accept`
    fetchDataHandleAuthError(
      iri,
      'PATCH',
      { body: JSON.stringify({ message: question }) },
      resp => {
        setRealizationDateChange(resp)
        setState(false)
        notification(
          'success',
          translate(
            'Wniosek o zmianę daty zakończenia projektu został zaakceptowany'
          )
        )
        updateResource()
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [question, realizationDateChange.uuid, updateResource])


  const handleQuestionConfirmed = useCallback(() => {
    switch (state) {
      case 'accept':
        handleAcceptShift()
        break
      case 'reject':
        handleRejectShift()
        break
      case 'question':
        handleQuestionShift()
        break
      default:
        handleChagneState(false)
        break
    }
  }, [state, handleAcceptShift, handleRejectShift, handleQuestionShift])

  return (
    <>
      {Object.keys(realizationDateChange).length > 0 && (
        <>
          <Grid container spacing={3} className={classes.marginBot}>
            <Grid item xs={12} md={2}>
              <Typography variant={'subtitle1'} color={'primary'}>
                <Moment
                  date={realizationDateChange.createdAt}
                  format={'DD.MM.YYYY'}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography variant={'h3'} color={'secondary'}>
                {translate('Zgłoszenie')}
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <SectionTitle label={'Z'} marginTopSm={true} />
                  <Typography
                    variant={'subtitle1'}
                    color={'primary'}
                    className={classes.marginBot}
                  >
                    <Moment
                      date={realizationDateChange.previousDateFrom}
                      format={'DD MMMM YYYY'}
                    />{' '}
                    -{' '}
                    <Moment
                      date={realizationDateChange.previousDateTo}
                      format={'DD MMMM YYYY'}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                  <SectionTitle label={'Na'} marginTopSm={true} />
                  <Typography
                    variant={'subtitle1'}
                    color={'primary'}
                    className={classes.marginBot}
                  >
                    <Moment
                      date={realizationDateChange.dateFrom}
                      format={'DD MMMM YYYY'}
                    />{' '}
                    -{' '}
                    <Moment
                      date={realizationDateChange.dateTo}
                      format={'DD MMMM YYYY'}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <SectionTitle label={'Uzasadnienie zmiany'} />
              <Typography
                variant={'body1'}
                color={'primary'}
                className={classes.marginBot}
              >
                {realizationDateChange.description}
              </Typography>
            </Grid>
          </Grid>
          {(realizationDateChange.question ||
            realizationDateChange.questionDate) && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={2}>
                <Typography variant={'subtitle1'} color={'primary'}>
                  <Moment
                    date={realizationDateChange.questionDate}
                    format={'DD.MM.YYYY'}
                  />
                </Typography>
              </Grid>
              <Grid item xs={6} md={2}>
                <Typography variant={'h3'} color={'secondary'}>
                  {translate('Do wyjaśnienia')}
                </Typography>
              </Grid>
              <Grid item xs={6} md={8}>
                <Typography
                  variant={'subtitle1'}
                  color={'primary'}
                  style={{ lineHeight: '28px' }}
                >
                  {realizationDateChange?.userQuestion.replace('|', ' ')}
                </Typography>
              </Grid>
              <Grid item xs={12} md={2} />
              <Grid item xs={12} md={10}>
                <Typography
                  variant={'body1'}
                  color={'primary'}
                  className={classes.marginBot}
                >
                  {realizationDateChange.question}
                </Typography>
              </Grid>
            </Grid>
          )}
        </>
      )}
      {realizationDateChange.answer && realizationDateChange.answerDate && (
        <Grid container spacing={3}>
          <Grid item xs={12} md={2}>
            <Typography variant={'subtitle1'} color={'primary'}>
              <Moment
                date={realizationDateChange.answerDate}
                format={'DD.MM.YYYY'}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography variant={'h3'} color={'secondary'}>
              {translate('Wyjaśnienie')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2} />
          <Grid item xs={12} md={10}>
            <Typography
              variant={'body1'}
              color={'primary'}
              className={classes.marginBot}
            >
              {realizationDateChange.answer}
            </Typography>
          </Grid>
        </Grid>
      )}
      {realizationDateChange.status !==
        REALIZATION_DATE_CHANGE_STATUS_REJECTED &&
        realizationDateChange.status !==
          REALIZATION_DATE_CHANGE_STATUS_ACCEPTED && (
          <div className={classes.buttons}>
            <Button
              variant="outlined"
              className={classes.buttonRed}
              // onClick={handleRejectShift}
              onClick={() => handleChagneState('reject')}
            >
              <span>{translate('Odrzuć')}</span>
            </Button>
            {realizationDateChange.status ===
              REALIZATION_DATE_CHANGE_STATUS_PENDING && (
              <>
                <Button
                  color={'secondary'}
                  variant="outlined"
                  onClick={() => handleChagneState('question')}
                >
                  <span>{translate('Poproś o wyjaśnienie')}</span>
                </Button>
              </>
            )}
            <Button
              color={'secondary'}
              variant="contained"
              // onClick={handleAcceptShift}
              onClick={() => handleChagneState('accept')}
            >
              <span>{translate('Akceptuj')}</span>
            </Button>
            <CustomDialog
              handleToogle={() => handleChagneState(null)}
              open={!!state}
              title={'Informacja dla Wnioskodawcy'}
              confirmedButton={true}
              onConfirm={handleQuestionConfirmed}
              component={() => (
                <StringType
                  name={'question'}
                  setValue={handleChangeQuestion}
                  value={question}
                  disabled={false}
                  renderError={false}
                  type={'textarea'}
                  setError={() => {}}
                  label={''}
                  width={'100%'}
                />
              )}
            />
          </div>
        )}
      {(realizationDateChange.status ===
        REALIZATION_DATE_CHANGE_STATUS_REJECTED ||
        realizationDateChange.status ===
          REALIZATION_DATE_CHANGE_STATUS_ACCEPTED) && (
        <Grid container spacing={3} className={classes.marginBot}>
          <Grid item xs={12} md={2}>
            <Typography variant={'subtitle1'} color={'primary'}>
              <Moment
                date={realizationDateChange.updatedAt}
                format={'DD.MM.YYYY'}
              />
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              variant={'h3'}
              color={
                realizationDateChange.status === BUDGET_CHANGE_STATUS_REJECTED
                  ? 'error'
                  : 'primary'
              }
              className={clsx(
                classes.marginBot,
                realizationDateChange.status ===
                  REALIZATION_DATE_CHANGE_STATUS_ACCEPTED && classes.textGreen
              )}
            >
              {translate(
                `T_REALIZATION_DATE_CHANGE_STATUS_${realizationDateChange.status}`
              )}
            </Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography
              variant={'subtitle1'}
              color={'primary'}
              style={{ lineHeight: '28px' }}
            >
              {realizationDateChange?.userAcceptedRejected?.replace('|', ' ')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={10}>
            <Typography
              variant={'body1'}
              color={'primary'}
              className={classes.marginBot}
            >
              {realizationDateChange.message}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}
