import React from 'react'
import { DataInfo } from 'components/DataInfo'
import { ReadFormGenerator } from './ReadFormGenerator'
import { SectionTitle } from 'core/components/SectionTitle'
import { BooleanType } from 'core/components/form/fields/BooleanType'
import { Grid } from '@material-ui/core'

export const ApplicantFormValues = ({ resource, contestResource = null }) => {
  return (
    <div>
      <SectionTitle
        label={'Osoba do kontaktu w sprawie wniosku lub dotacji'}
        divider={true}
      />
      {resource.coordinators.map((el, index) => (
        <div key={index}>
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <DataInfo label={'Imię i Nazwisko'} data={el.fullName} />
            </Grid>
            <Grid item xs={5}>
              <DataInfo label={'Funkcja'} data={el.role} />
            </Grid>
          </Grid>
          <DataInfo
            label={'Adres mailowy do kontaktu w sprawie wniosku lub dotacji'}
            data={el.email}
          />
          <DataInfo
            label={'Numer telefonu do kontaktu w sprawie wniosku lub dotacji'}
            data={el.phone}
          />
          <BooleanType
            setValue={() => null}
            name={'rodo'}
            disabled={false}
            setError={() => null}
            error={false}
            renderError={false}
            label={''}
            labelNotTrans={
              'Informacje dotyczące przetwarzania danych osobowych znajdują się w Zasadach Konkursu Duże Granty zamieszczonych na stronie internetowej <a href="http://www.rownacszanse.org.pl" target="_blank">www.rownacszanse.org.pl</a>'
            }
            value={el.rodo}
            initialValue={el.rodo}
            validators={[]}
          />
        </div>
      ))}
      <ReadFormGenerator
        values={resource.applicationApplicantFormValues}
        formIRI={
          contestResource
            ? contestResource.applicationApplicantForm['@id']
            : resource.contest.applicationApplicantForm
        }
        contest={contestResource ? contestResource : resource.contest}
        proposal={resource}
      />
    </div>
  )
}
