import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { StringType } from 'components/applicant/form/fields/StringType'
import { isContractRepresentativeCollectionInvalid } from 'pages/applicant/AppliedProposals/_helpers/isContractRepresentativeCollectionInvalid'
import { useContractRepresentativeItemTypeStyles } from './ContractRepresentativeItemType.styles'
import { translate } from 'core/_helpers/translate'

export const ContractRepresentativeItemType = ({
  index,
  values,
  errors,
  renderError,
  globalRenderError,
  setState,
  disabled
}) => {
  const setValue = useCallback(
    (name, value) => {
      setState(state => ({
        ...state,
        values: state.values.map((item, i) => i === index
          ? {
            ...item,
            [name]: value
          } : item
        ),
        renderError: state.renderError.map((item, i) => i === index
          ? {
            ...item,
            [name]: true
          } : item
        ),
        init: false,
      }))
    },
    [index, setState]
  )

  const setError = useCallback(
    (name, error) => {
      setState(state => ({
        ...state,
        errors: state.errors.map((item, i) => i === index
          ? {
            ...item,
            [name]: error
          } : item
        ),
        renderError: state.isFormSubmitted
          ? state.renderError.map(
            (item, i) => i === index
              ? {
                ...item,
                [name]: true
              } : item
            )
          : state.renderError,
        isInvalid: isContractRepresentativeCollectionInvalid(
          state.errors.map((item, i) => i === index
            ? {
              ...item,
              [name]: !!error
            } : item
          )
        )
      }))
    },
    [index, setState]
  )

  const validators = useMemo(() => ({
      name: [ 'required' ],
      surname: [ 'required' ],
      role: [ 'required' ],
    }),
    []
  )

  const classes = useContractRepresentativeItemTypeStyles()

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.label}>
          {translate('T_MODULE_PROPOSALS_CONTRACT_REPRESENTATIVE_NAME')}
        </div>
        <StringType
          type="string"
          name="name"
          value={values?.name}
          error={errors?.name}
          renderError={globalRenderError && renderError?.name}
          validators={validators.name}
          disabled={disabled}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input_name
          }}
        />
      </div>
      <div>
        <div className={classes.label}>
          {translate('T_MODULE_PROPOSALS_CONTRACT_REPRESENTATIVE_SURNAME')}
        </div>
        <StringType
          type="string"
          name="surname"
          value={values?.surname}
          error={errors?.surname}
          renderError={globalRenderError && renderError?.surname}
          validators={validators.surname}
          disabled={disabled}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input_surname
          }}
        />
      </div>
      <div>
        <div className={classes.label}>
          {translate('T_MODULE_PROPOSALS_CONTRACT_REPRESENTATIVE_ROLE')}
        </div>
        <StringType
          type="string"
          name="role"
          value={values?.role}
          error={errors?.role}
          renderError={globalRenderError && renderError?.role}
          validators={validators.role}
          disabled={disabled}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input_role
          }}
        />
      </div>
    </div>
  )
}

ContractRepresentativeItemType.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
