import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import randomHash from 'random-hash'
import {
  FormControl,
  FormHelperText,
  Popper,
  MenuItem,
  Paper,
  InputAdornment,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { validate } from 'core/_helpers/validate'
import { translate } from 'core/_helpers/translate'
import { prop } from 'core/_helpers/prop'
import {
  CustomInput,
  CustomLabel,
  useCustomSelect,
  useStyles,
} from './fields.style'
import { ReactComponent as IconArrow } from '../../../../theme/icons/arrow-down.svg'

export const ResourceType = ({
  name,
  label,
  hint = null,
  initialValue,
  value,
  compareValue = null,
  compare = false,
  endpoint = null,
  resources = null,
  titleAccessor,
  error = false,
  renderError = false,
  disabled = false,
  validators,
  setValue,
  setError,
  fullWidth = false,
  width = '350px',
  placeholder = translate('Wybierz'),
}) => {
  const [id] = useState(randomHash())

  const [choices, setChoices] = useState(resources || [])

  const handleChange = (e, option) => {
    setValue(name, option ? option.value : null)
    validateField(option ? option.value : null)
  }

  const validateField = useCallback(
    value => {
      if (!validators) {
        setError(name, false)

        return
      }

      const valid = validate(validators, value)

      setError(name, !valid.result && valid.message)
    },
    [validators, setError, name]
  )

  useEffect(() => {
    validateField(initialValue)
  }, [validateField, initialValue])

  useEffect(() => {
    if (!endpoint || resources) {
      return
    }

    const controller = new AbortController()
    const { signal } = controller

    fetchDataHandleAuthError(
      endpoint,
      'GET',
      { signal },
      response => {
        setChoices(response['hydra:member'])
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        notification('error', error.response.detail, error.response.title)
      }
    )

    return () => controller.abort()
  }, [endpoint, resources, setChoices])

  const options = choices.map(resource => ({
    value: resource['@id'],
    title: prop(resource, titleAccessor),
  }))

  const classes = useStyles()
  const classesCustomSelect = useCustomSelect()

  return (
    <FormControl
      className={clsx(
        classes.form_control_select,
        fullWidth && classes.formControllFullWidth
      )}
      error={renderError && !!error}
      style={{
        maxWidth: width,
      }}
    >
      <CustomLabel shrink={false} error={false} htmlFor={id}>
        {translate(label.text || label) +
          (validators && validators.includes('required') ? ' *' : '')}
      </CustomLabel>
      <Autocomplete
        id={id}
        name={name}
        options={options}
        aria-describedby={'helper_' + name}
        getOptionLabel={option => option.title}
        onChange={handleChange}
        className={classes.autocomplete}
        value={
          getValue(value)
            ? {
                value: getValue(value),
                title:
                  options.find(option => option.value === getValue(value))
                    ?.title || '',
              }
            : null
        }
        renderInput={params => (
          <div ref={params.InputProps.ref}>
            <CustomInput
              {...params.inputProps}
              placeholder={placeholder}
              endAdornment={
                <InputAdornment
                  position="end"
                  className={classesCustomSelect.field_icon}
                >
                  <IconArrow className={classesCustomSelect.icon} />
                </InputAdornment>
              }
            />
          </div>
          // <TextField {...params} variant="outlined" />
        )}
        PaperComponent={props => (
          <Paper
            {...props}
            className={clsx(
              classesCustomSelect.paper,
              classesCustomSelect.paper_auto_complete
            )}
          />
        )}
        popupIcon={<IconArrow className={classesCustomSelect.icon} />}
        PopperComponent={props => (
          <Popper
            {...props}
            style={{ width: 'auto' }}
            placement="bottom-start"
          />
        )}
        disabled={disabled || !choices.length}
        // classes={{ root: classesCustomSelect.root }}
        renderOption={option => (
          <MenuItem
            className={classesCustomSelect.item}
            value={option.uuid}
            component="button"
          >
            {option.title}
          </MenuItem>
        )}
      />
      <FormHelperText id={'helper_' + name}>
        {translate(renderError && error ? error : hint)}
      </FormHelperText>
      {compare && (
        <div
          className={clsx(
            classes.compare,
            getValue(value) !== getValue(compareValue) && classes.compareNeq
          )}
        >
          {compareValue &&
            options.find(option => option.value === getValue(compareValue))
              ?.title}
        </div>
      )}
    </FormControl>
  )
}

const getValue = value => {
  if (!value) {
    return null
  }

  if (typeof value === 'string') {
    return value
  }

  return value['@id']
}

ResourceType.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ]).isRequired,
  hint: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    }),
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    }),
  ]),
  compareValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    }),
  ]),
  compare: PropTypes.bool,
  endpoint: PropTypes.string,
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string.isRequired,
    })
  ),
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  renderError: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  validators: PropTypes.arrayOf(PropTypes.string),
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
}
