import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'
import { nl2br } from '_helpers/nl2br'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    marginTop: 10,
    marginBottom: 20,
    display: 'flex',
    paddingBottom: 20,
    borderBottom: '1px solid #ccc',
    width: '100%'
  },
  number: {
    width: 15,
    marginRight: 20,
    fontWeight: 'bold'
  },
  title: {
    width: 220,
    marginRight: 20
  },
  calculation_method: {
    width: 220,
    marginRight: 20
  },
  own_contribution_amout: {
    display: 'flex',
    width: 160,
    marginRight: 20,
  },
  subsidy_amount: {
    display: 'flex',
    width: 160,
    marginRight: 20,
  },
}))

export const BudgetItem = ({
  index,
  title,
  calculationMethod,
  ownContributionAmount,
  subsidyAmount
}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.number}>
        {index + 1}
      </div>
      <div className={classes.title}>
        {nl2br(title)}
      </div>
      <div className={classes.calculation_method}>
        {nl2br(calculationMethod)}
      </div>
      <div className={classes.own_contribution_amout}>
       {ownContributionAmount} {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
      </div>
      <div className={classes.subsidy_amount}>
       {subsidyAmount} {translate('T_MODULE_PROPOSALS_BUDGET_PLN')}
      </div>
    </div>
  )
}

BudgetItem.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.string,
  calculationMethod: PropTypes.string,
  ownContributionAmount: PropTypes.number.isRequired,
  subsidyAmount: PropTypes.number.isRequired
}
