import { makeStyles } from '@material-ui/styles'

export const useContractRepresentativeItemTypeStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    marginBottom: 10,
    display: 'flex',
    '&> *': {
      marginRight: 30
    }
  },
  label: {
    marginBottom: 10
  },
  input_name: {
    width: 150
  },
  input_surname: {
    width: 200
  },
  input_role: {
    width: 200
  }
}))
