import React from 'react'
import { Route } from 'react-router-dom'
import { roles } from '_helpers/roles'
import { View } from 'pages/applicant/Faq/View'

const routes = () => {
  return {
    index: {
      type: Route,
      render: props => <View {...props} />,
      exact: true,
      resourceAccess: [roles.APPLICANT],
      path: '/faq',
    },
  }
}

export default routes
