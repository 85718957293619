import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { Button, List, ListItem } from '@material-ui/core'
import { Status } from 'core/components/resource/Status'
import { Delete } from 'core/components/resource/Delete'
import { CreateVersion } from 'core/components/resource/CreateVersion'
import { useStyles } from 'core/components/resource/sidebar/Sidebar.styles'
import clsx from 'clsx'
import { translate } from '../../../_helpers/translate'
import { RemoveRedEye } from '@material-ui/icons'

export const Sidebar = ({
  resource,
  deletable = true,
  statable = true,
  versionable = false,
  preview = false,
  titleAccessor = 'title',
  statAccessor = 'stat',
  collectionPath,
  disabled = false,
  deleteText = '',
  onButtonSaveRef = () => false,
  sidebarStatusIsSwitch = false,
  statusTitle = null,
  sidebarUrl = null,
  prevurl = '',
  sidebarButtonInline = false,
}) => {
  const history = useHistory()
  const [status, setStatus] = useState(resource[statAccessor])
  const onDeleteSuccess = () => {
    history.push(collectionPath)
  }
  const defaultClasses = useStyles()

  const handleChangeStatus = stat => {
    setStatus(stat)
  }
  return (
    <List className={defaultClasses.list}>
      {deletable && !(statable && status) && (
        <ListItem
          className={clsx(defaultClasses.item, defaultClasses.itemRight)}
        >
          <Delete
            resource={resource}
            accessor={titleAccessor}
            disabled={disabled}
            onSuccess={onDeleteSuccess}
            deleteText={deleteText}
            url={sidebarUrl}
          />
        </ListItem>
      )}
      {preview && (
        <ListItem
          className={clsx(defaultClasses.item, defaultClasses.itemRight)}
        >
          <Button
            onClick={() => history.push(prevurl)}
            size={'small'}
            color={'primary'}
            startIcon={<RemoveRedEye />}
          >
            {translate('Podgląd')}
          </Button>
        </ListItem>
      )}
      {statable && (
        <ListItem className={defaultClasses.item}>
          {statusTitle && (
            <span className={defaultClasses.itemSpan}>{statusTitle}</span>
          )}
          <Status
            resource={resource}
            accessor={statAccessor}
            disabled={disabled}
            isSwitch={sidebarStatusIsSwitch}
            url={sidebarUrl}
            onSuccess={handleChangeStatus}
          />
        </ListItem>
      )}
      {versionable && (
        <ListItem className={defaultClasses.item}>
          <CreateVersion resource={resource} disabled={disabled} />
        </ListItem>
      )}
      {!!onButtonSaveRef && (
        <ListItem
          className={clsx(
            defaultClasses.item,
            sidebarButtonInline && defaultClasses.buttonsInline
          )}
        >
          <div ref={onButtonSaveRef}></div>
        </ListItem>
      )}
    </List>
  )
}

Sidebar.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  deletable: PropTypes.bool,
  statable: PropTypes.bool,
  versionable: PropTypes.bool,
  preview: PropTypes.bool,
  titleAccessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  statAccessor: PropTypes.string,
  collectionPath: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  deleteText: PropTypes.string,
  onButtonSaveRef: PropTypes.func,
  sidebarStatusIsSwitch: PropTypes.bool,
  statusTitle: PropTypes.string,
  sidebarUrl: PropTypes.string,
  sidebarButtonInline: PropTypes.bool,
  prevurl: PropTypes.string,
}
