import React, { useCallback, useState } from 'react'
import schema from '_schema/contests'
import { useResourceState } from 'core/_helpers/useResourceState'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages'
import { Grid } from '@material-ui/core'
import { Paper } from 'core/components/Paper'
import { CollectionHeader } from 'core/components/CollectionHeader'
import routes from './routes'
import { makeStyles } from '@material-ui/styles'
import { translate } from 'core/_helpers/translate'
import { StyledTab, StyledTabs } from 'components/StyledTab'
import { ProposalList } from './components/ProposalList'
import { DetailContest } from './components/DetailContest'
import { RateGuidelines } from './components/RateGuidelines'

const useStyles = makeStyles(theme => ({
  tabs: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

export const CollectionProposals = ({ match }) => {
  const classess = useStyles()
  const iri = `${schema.endpoint}/${match.params.id}`

  const [state, setState] = useResourceState()

  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError
  )

  const [tab, setTab] = useState('proposal')
  const handleChangeTab = useCallback((event, value) => {
    setTab(value)
  }, [])

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={8}>
          <CollectionHeader
            hideButton={true}
            hideButtonBack={false}
            buttonTitleLeft={'Lista konkursów'}
            buttonPathLeft={routes().contest_expert.path}
          >
            {resource.title}
          </CollectionHeader>
          <StyledTabs
            value={tab}
            onChange={handleChangeTab}
            variant="scrollable"
            scrollButtons="auto"
            className={classess.tabs}
          >
            <StyledTab
              label={translate('Przypisane wnioski')}
              value={'proposal'}
            />
            <StyledTab
              label={translate('Informacje o konkursie')}
              value={'detail'}
            />
          </StyledTabs>
          <Paper withPadding={true}>
            {tab === 'proposal' ? (
              <ProposalList resource={resource} />
            ) : (
              <DetailContest resource={resource} />
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper withPadding={false}>
            <RateGuidelines resource={resource} />
          </Paper>
        </Grid>
      </Grid>
    </>
  )
}
