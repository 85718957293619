import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { useCollectionItemStyles } from 'components/applicant/collection_styles/CollectionItem.styles'
import {
  PeopleOutlined,
  PersonOutlined,
  ArrowForwardOutlined,
} from '@material-ui/icons'
import { translate } from 'core/_helpers/translate'
import routes from 'pages/applicant/Contests/routes'

const DATE_FORMAT = 'DD.MM.YYYY'

export const ItemComponent = ({ item }) => {
  const classes = useCollectionItemStyles()

  return (
    <Link
      to={routes().show.path.replace(':id', item.uuid)}
      className={classes.root}
    >
      {item.contestEdition && (
        <div className={classes.edition}>{item.contestEdition.title}</div>
      )}
      <div className={classes.title}>{item.title || item.contest.title}</div>
      <div className={classes.dates}>
        <div>
          {translate('T_MODULE_CONTESTS_PROPOSAL_COLLECTING_DATE')}:
          <span>
            {item.proposalCollectingDateTo &&
              ` ${moment(item.proposalCollectingDateTo).format(DATE_FORMAT)}`}
          </span>
        </div>
        <div>
          {translate('T_MODULE_CONTESTS_PROJECT_REALIZATION_DATE')}:
          <span>
            {item.projectRealizationDateFrom &&
              ` ${moment(item.projectRealizationDateFrom).format(DATE_FORMAT)}`}
            {item.projectRealizationDateTo &&
              ` - ${moment(item.projectRealizationDateTo).format(DATE_FORMAT)}`}
          </span>
        </div>
      </div>
      <div className={classes.type}>
        {item.type === 'OPEN' ? (
          <PeopleOutlined className={classes.icon} />
        ) : (
          <PersonOutlined className={classes.icon} />
        )}
        {translate(`T_MODULE_CONTESTS_TYPE_${item.type}`)}
      </div>
      <div className={classes.lead}>{item.pageLead}</div>
      <div className={classes.link_container}>
        <div className={classes.link}>
          <ArrowForwardOutlined />
        </div>
      </div>
    </Link>
  )
}

ItemComponent.propTypes = {
  item: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string.isRequired,
    pageLead: PropTypes.string,
    projectRealizationDateFrom: PropTypes.string,
    projectRealizationDateTo: PropTypes.string,
    proposalCollectingDateTo: PropTypes.string,
    contestEdition: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
}
