import React from 'react'
import { ShowDetailContest } from 'components/showDetailContest/ShowDetailContest'
import schema from '_schema/contests'
import { useResourceFetch } from 'core/_helpers/useResourceFetch'
import { useResourceState } from 'core/_helpers/useResourceState'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages'
import { Paper } from 'core/components/Paper'
import { translate } from 'core/_helpers/translate'
import { CollectionHeader } from 'core/components/CollectionHeader'
import routes from 'pages/Foundation/Home/routes'

export const PreviewPage = ({ match }) => {
  const contest = match.params.id
  const iri = `${schema.endpoint}/${contest}`

  const [state, setState] = useResourceState()
  const { resource, isFetching, fetchError } = state

  useResourceFetch(
    iri,
    resource,
    setState.isFetching,
    setState.resource,
    setState.fetchError
  )

  return isFetching ? (
    <Loader align="center" marginTop={15} />
  ) : fetchError ? (
    <NotFound />
  ) : (
    <>
      <CollectionHeader
        hideButton={true}
        buttonPathLeft={routes().contest_edit.path.replace(
          ':id',
          `${contest}/1`
        )}
        buttonTitleLeft={'Wróć do edycji'}
        hideButtonBack={false}
      >
        {translate('Podgląd strony')}: {resource.title}
      </CollectionHeader>
      <Paper withPadding={false}>
        <ShowDetailContest resource={resource} />
      </Paper>
    </>
  )
}
