import React from 'react'
import { ReadFormGenerator } from './ReadFormGenerator'
import { DataInfo } from 'components/DataInfo'

export const ProjectFormValues = ({ resource, contestResource = null }) => {
  return (
    <div>
      {resource.title && (
        <div>
          <DataInfo
            label={'Nazwa projektu'}
            data={resource.title}
            bold={true}
          />
        </div>
      )}
      <ReadFormGenerator
        values={resource.applicationProjectFormValues}
        formIRI={
          contestResource
            ? contestResource.applicationProjectForm['@id']
            : resource.contest.applicationProjectForm
        }
        contest={contestResource ? contestResource : resource.contest}
        proposal={resource}
      />
    </div>
  )
}
