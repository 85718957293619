export const generateCoordinatorCollectionRenderError = (coordinators, renderError) => {
  if (!coordinators) {
    return []
  }

  let currentRenderError = []

  coordinators.forEach((coordinator, index) => {
    if (!coordinator) {
      return
    }

    if (!currentRenderError[index]) {
      currentRenderError[index] = {}
    }

    Object.keys(coordinator).forEach(field => {
      currentRenderError[index][field] = renderError
    })
  })

  return currentRenderError
}
