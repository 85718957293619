import React, { useCallback, useMemo, useState } from 'react'
import { Tabs } from './components/Tabs'
import { CollectionHeader } from 'core/components/CollectionHeader'
import { Paper } from 'core/components/Paper'
import { SchemableCollection } from 'core/components/SchemableCollection'
import { CollectionTable } from 'core/components/table'
import schema from 'core/_schema/user'
import { standard } from 'core/components/table/_columns/standard'
import { Form } from 'core/components/form'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { CustomDialog } from 'core/components/Dialog'
import { ChoiceType } from 'core/components/form/fields/ChoiceType'
import { translate } from 'core/_helpers/translate'
import { makeStyles } from '@material-ui/core/styles'
import { constants } from 'core/components/form/_state'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { createObjectFromString } from 'core/_helpers/createObjectFromString'
import _ from 'lodash'
import { isObjectEmpty } from 'core/_helpers/isObjectEmpty'
import { operations } from 'core/components/table/_columns/operations'
import { RoleCell } from './components/RoleCell'
import routes from './routes'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  roleSelect: {
    marginBottom: theme.spacing(3),
  },
}))

export const Administrators = ({ match }) => {
  const user = useSelector(state => state.profile)

  const columns = useMemo(
    () => [
      {
        ...standard('firstName'),
        sortable: true,
        filterable: true,
        width: '15%',
      },
      {
        ...standard('lastName'),
        sortable: true,
        filterable: true,
        width: '15%',
      },
      {
        ...standard('email'),
        sortable: true,
        filterable: true,
        width: '15%',
      },
      {
        ...standard('phone'),
        sortable: false,
        filterable: true,
        width: '15%',
      },
      {
        accessor: 'roles',
        Cell: RoleCell,
        sortable: false,
        filterable: true,
        width: '15%',
      },
      {
        ...operations(
          'stat',
          false,
          false,
          false,
          () => false,
          false,
          false,
          resource => `${schema('user').endpoint}/${resource.uuid}`,
          true,
          translate('Aktywny'),
          !user.roles.includes('ROLE_ADMIN')
        ),
        width: '10%',
        editable: false,
        deletable: false,
      },
      {
        ...operations(
          resource =>
            resource.firstName
              ? `${resource.firstName} ${resource.lastName}`
              : resource.email,
          user.roles.includes('ROLE_ADMIN') ||
            (resource => {
              return resource.email !== user.email
            }),
          user.roles.includes('ROLE_ADMIN'),
          false,
          () => false,
          false,
          false,
          resource => `${schema('user').endpoint}/${resource.uuid}`
        ),
        width: '10%',
        editable: true,
        deletable: true,
      },
    ],
    [user.email, user.roles]
  )
  const classes = useStyles()
  const [reload, setReload] = useState(false)

  const handleSetReload = () => {
    setReload(state => !state)
  }

  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const handleConfirmToggle = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
  }

  const handleConfirmSuccess = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
    setReload(true)
  }

  const [buttonRef, setButtonRef] = useState(null)

  const onButtonRef = element => {
    setButtonRef(element)
  }

  const [formRole, setFormRole] = useState('foundation_register')

  const handleChangeFormRole = useCallback((name, value) => {
    setFormRole(value)
  }, [])

  const handleSubmit = useCallback(
    (state, dispatch) => {
      dispatch({ type: constants.PROCESS, payload: true })
      let body = state.values

      fetchDataHandleAuthError(
        schema(formRole).endpoint,
        'POST',
        { body: JSON.stringify(body) },
        response => {
          delete response.stat

          dispatch({
            type: constants.SUCCESS,
            payload: {
              method: 'POST',
              resource: response,
            },
          })
          notification('success', 'T_FORM_RECORD_CREATED', 'T_FORM_SUCCESS')
          handleConfirmSuccess()
        },
        error => {
          const errors = error.response.violations.reduce(
            (processedErrors, item) => {
              const processedError = createObjectFromString(
                item.propertyPath.replace('[', '.').replace(']', ''),
                item.message
              )

              return _.merge(processedErrors, processedError)
            },
            {}
          )

          dispatch(
            isObjectEmpty(errors)
              ? { type: constants.PROCESS, payload: false }
              : { type: constants.FAILURE, payload: { errors } }
          )

          notification(
            'error',
            error.response.violations.length
              ? 'T_FORM_INCORRECT'
              : error.response.detail,
            error.response.title
          )
        },
        {}
      )
    },
    [formRole]
  )

  const Dialog = () => (
    <>
      <div className={classes.roleSelect}>
        <ChoiceType
          name={'type'}
          setValue={handleChangeFormRole}
          disabled={false}
          renderError={false}
          error={false}
          choices={{
            foundation_register: translate('ROLE_FOUNDATION'),
            admin_register: translate('ROLE_ADMIN'),
            guest_register: translate('ROLE_GUEST'),
          }}
          value={formRole}
          validators={['required']}
          setError={() => false}
          label={'Uprawnienia'}
        />
      </div>
      <Form
        url={schema(formRole).endpoint}
        method="POST"
        properties={schema(formRole).resource.properties}
        showSubmitAndStayButton={false}
        showCancelButton={false}
        fieldsFullWidth={true}
        width="100%"
        handleCancel={handleConfirmToggle}
        buttonContainerRef={buttonRef}
        handleSuccess={handleConfirmSuccess}
        handleSubmit={handleSubmit}
      />
    </>
  )

  return (
    <>
      <CollectionHeader
        buttonTitle={''}
        hideButton={true}
        hideButtonBack={true}
      >
        <Tabs path={match.path} />
      </CollectionHeader>
      <Paper withPadding={false}>
        <SchemableCollection
          component={CollectionTable}
          path={`paths.${schema('admin_foundation').endpoint}.get`}
          endpoint={schema('admin_foundation').endpoint}
          customResourceSchema={schema('admin_foundation').resource}
          columns={columns}
          autoWidth={false}
          filterable={true}
          filterInline={true}
          sortable={true}
          numerable={false}
          customFilters={[
            {
              name: 'isDeleted',
              value: 'false',
              title: 'isDeleted',
            },
          ]}
          resetable={false}
          paginable={true}
          definitionSchema={schema('admin_foundation').resource}
          isRowLinkable={false}
          reload={reload}
          setReload={handleSetReload}
          editPath={routes().edit_administrator.path}
        />
        {user.roles.includes('ROLE_ADMIN') && (
          <ButtonPlus
            text={'Dodaj administratora'}
            onClick={handleConfirmToggle}
          />
        )}
        <CustomDialog
          title="Dodaj administratora"
          component={Dialog}
          confirmedButton={false}
          onConfirm={() => false}
          open={state.isConfirmOpen}
          handleToogle={handleConfirmToggle}
          isFetching={state.isFetching}
          showActions={true}
          onButtonRef={onButtonRef}
        />
      </Paper>
    </>
  )
}
