export const generateContractRepresentativeCollectionRenderError = (contractRepresentatives, renderError) => {
  if (!contractRepresentatives) {
    return []
  }

  let currentRenderError = []

  contractRepresentatives.forEach((contractRepresentative, index) => {
    if (!contractRepresentative) {
      return
    }

    if (!currentRenderError[index]) {
      currentRenderError[index] = {}
    }

    Object.keys(contractRepresentative).forEach(field => {
      currentRenderError[index][field] = renderError
    })
  })

  return currentRenderError
}
