import { useState, useMemo, useEffect } from 'react'

export const useContestState = () => {
  const [state, setState] = useState({
    isFetching: true,
    resource: null,
    fetchError: false,
    applicationApplicantFormFetched: false,
    applicationProjectFormFetched: false
  })

  const setters = useMemo(
    () => ({
      isFetching: isFetching =>
        setState(state => ({
          ...state,
          isFetching,
        })),
      resource: resource =>
        setState(state => ({
          ...state,
          resource,
        })),
      form: (name, form) => {
        setState(state => ({
          ...state,
          [`${name}Fetched`]: true,
          resource: {
            ...state.resource,
            [name]: form
          }
        }))
      },
      fetchError: fetchError =>
        setState(state => ({
          ...state,
          isFetching: false,
          fetchError,
        })),
      formFetched: name =>
        setState(state => ({
          ...state,
          [`${name}Fetched`]: true,
        }))
    }),
    [setState]
  )

  useEffect(() => {
    if (!state.applicationApplicantFormFetched || !state.applicationProjectFormFetched) {
      return
    }

    setState(state => ({
      ...state,
      isFetching: false
    }))
  }, [
    state.applicationApplicantFormFetched,
    state.applicationProjectFormFetched,
    setState
  ])

  return [state, setters]
}
