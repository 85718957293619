import React, { useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { StringType } from 'components/applicant/form/fields/StringType'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  upper_row: {
    marginTop: 8,
  },
  lower_row: {
    marginTop: 16,
  },
  label: {
    marginBottom: 5,
  },
  textarea: {
    width: 450,
  },
  help: {
    marginLeft: 25,
    color: theme.palette.disabled,
    marginTop: 30,
  },
  row: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 8,
  },
}))

export const CompanyTargetInformationsBlock = ({
  proposal,
  values,
  errors,
  renderError,
  globalRenderError,
  setValue,
  setError,
  disabled,
}) => {
  const validators = useMemo(
    () => ({
      organizationGoals: ['maxLength(2000)'],
      statutoryActivities: ['maxLength(2000)'],
    }),
    []
  )

  const [first, setFirst] = useState(false)

  useEffect(() => {
    if (!first) {
      if (
        [undefined, null].includes(values?.organizationGoals) &&
        proposal.organizationGoals
      ) {
        setValue('organizationGoals', proposal.organizationGoals)
      }

      if (
        [undefined, null].includes(values?.statutoryActivities) &&
        proposal.statutoryActivities
      ) {
        setValue('statutoryActivities', proposal.statutoryActivities)
      }
    }
    setFirst(true)
  }, [values, proposal, setValue, first])

  const classes = useStyles()

  return (
    <div>
      <div className={classes.row}>
        <div className={classes.upper_row}>
          <div className={classes.label}>
            {translate('T_MODULE_ORGANIZATION_DATA_ORGANIZATION_GOALS')}
          </div>
          <StringType
            type="textarea"
            name="organizationGoals"
            value={values?.organizationGoals}
            error={errors?.organizationGoals}
            renderError={globalRenderError && renderError?.organizationGoals}
            disabled={disabled}
            validators={validators.organizationGoals}
            setValue={setValue}
            setError={setError}
            maxLength={2000}
            classes={{
              root: classes.textarea,
            }}
          />
        </div>
        <div className={classes.help}>
          <div>
            {translate('T_MODULE_PROPOSALS_CHARS_LEFT')}:{' '}
            {2000 - (values?.organizationGoals?.length || 0)}
          </div>
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.lower_row}>
          <div className={classes.label}>
            {translate('T_MODULE_ORGANIZATION_DATA_STATUTORY_ACTIVITIES')}
          </div>
          <StringType
            type="textarea"
            name="statutoryActivities"
            value={values?.statutoryActivities}
            error={errors?.statutoryActivities}
            renderError={globalRenderError && renderError?.statutoryActivities}
            disabled={disabled}
            validators={validators.statutoryActivities}
            setValue={setValue}
            setError={setError}
            classes={{
              root: classes.textarea,
            }}
          />
        </div>
        <div className={classes.help}>
          <div>
            {translate('T_MODULE_PROPOSALS_CHARS_LEFT')}:{' '}
            {2000 - (values?.statutoryActivities?.length || 0)}
          </div>
        </div>
      </div>
    </div>
  )
}

CompanyTargetInformationsBlock.propTypes = {
  proposal: PropTypes.object.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setValue: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
