import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Grid, Typography } from '@material-ui/core'
import { SectionTitle } from 'core/components/SectionTitle'
import { translate } from 'core/_helpers/translate'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import schema, {
  SCHEDULE_CHANGE_STATUS_NEW,
  SCHEDULE_CHANGE_STATUS_PENDING,
  SCHEDULE_CHANGE_STATUS_ACCEPTED,
  SCHEDULE_CHANGE_STATUS_REJECTED,
  SCHEDULE_CHANGE_STATUS_AUTO_DRAFT,
} from '_schema/proposal'
import Moment from 'react-moment'
import moment from 'moment'
import { notification } from 'core/_helpers/notification'
import clsx from 'clsx'
import { StringType } from '../../../../core/components/form/fields/StringType'
import { CustomDialog } from '../../../../core/components/Dialog'

const useStyle = makeStyles(theme => ({
  marginBot: {
    marginBottom: theme.spacing(5),
  },
  textGreen: {
    color: theme.palette.success.main,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
  buttonRed: {
    borderColor: theme.palette.error.main,
    '& .MuiButton-label': {
      color: theme.palette.error.main,
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 24px rgba(11, 43, 124, 0.4)',
      '& .MuiButton-label': {
        color: theme.palette.primary.main,
      },
    },
  },
  item: {
    borderBottom: '1px solid rgba(11, 43, 124, 0.2)',
  },
}))

const Item = ({ date, previousSchedule, schedule }) => {
  const classes = useStyle()
  return (
    <div className={classes.item}>
      <SectionTitle label={<Moment date={date} format={'MMMM YYYY'} />} />
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <SectionTitle label={'Z'} marginTopSm={true} />
          <Typography
            variant={'body1'}
            color={'primary'}
            className={classes.marginBot}
          >
            {previousSchedule}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <SectionTitle label={'Na'} marginTopSm={true} />
          <Typography
            variant={'body1'}
            color={schedule === undefined ? 'secondary' : 'primary'}
            className={classes.marginBot}
          >
            {schedule !== undefined
              ? schedule
              : 'poza zakresem czasu realizacji projektu'}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

export const ScheduleShift = ({ eventObject, updateResource }) => {
  const classes = useStyle()

  const [scheduleChange, setScheduleChange] = useState({})
  const [scheduleDate, setScheduleDate] = useState([])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    const iri = eventObject.scheduleRequest[0]['@id']
    fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
      setScheduleChange(resp)
      setScheduleDate([
        ...new Set(
          [
            ...Object.keys(resp.schedule),
            ...Object.keys(resp.previousSchedule),
          ].sort((a, b) => {
            const dateA = moment(a)
            const dateB = moment(b)
            if (dateA.isAfter(dateB)) {
              return 1
            } else {
              return -1
            }
          })
        ),
      ])
    })
    return () => controller.abort()
  }, [eventObject.scheduleRequest])

  const [question, setQuestion] = useState('')
  const handleChangeQuestion = useCallback(
    (name, value) => setQuestion(value),
    []
  )

  const [state, setState] = useState(false)
  const handleChagneState = state => {
    setState(state)
  }

  const handleRejectShift = useCallback(() => {
    const iri = `${schema.endpoint_schedule_requests}/${scheduleChange.uuid}/reject`
    fetchDataHandleAuthError(
      iri,
      'PATCH',
      { body: JSON.stringify({ message: question }) },
      resp => {
        setScheduleChange(resp)
        notification(
          'warning',
          translate('Wniosek o zmianę harmonogramu został odrzucony')
        )
        updateResource()
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [scheduleChange.uuid, updateResource, question])

  const handleAcceptShift = useCallback(() => {
    const iri = `${schema.endpoint_schedule_requests}/${scheduleChange.uuid}/accept`
    fetchDataHandleAuthError(
      iri,
      'PATCH',
      { body: JSON.stringify({ message: question }) },
      resp => {
        setScheduleChange(resp)
        notification(
          'success',
          translate('Wniosek o zmianę harmonogramu został zaakceptowany')
        )
        updateResource()
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [scheduleChange.uuid, updateResource, question])

  const handleQuestionShift = useCallback(() => {
    const iri = `${schema.endpoint_schedule_requests}/${scheduleChange.uuid}/question`
    fetchDataHandleAuthError(
      iri,
      'PATCH',
      { body: JSON.stringify({ question }) },
      resp => {
        setScheduleChange(resp)
        setState(false)
        notification('success', translate('Odpowiedź została wysłana'))
        updateResource()
      },
      error => {
        notification('error', error.response.detail, error.response.title)
      }
    )
  }, [scheduleChange.uuid, question, updateResource])

  const handleQuestionConfirmed = useCallback(() => {
    switch (state) {
      case 'accept':
        handleAcceptShift()
        break
      case 'reject':
        handleRejectShift()
        break
      case 'question':
        handleQuestionShift()
        break
      default:
        handleChagneState(false)
        break
    }
  }, [state, handleAcceptShift, handleRejectShift, handleQuestionShift])

  return (
    <>
      {typeof scheduleChange === 'object' &&
        Object.keys(scheduleChange).length > 0 && (
          <>
            <Grid container spacing={3} className={classes.marginBot}>
              <Grid item xs={12} md={2}>
                <Typography variant={'subtitle1'} color={'primary'}>
                  <Moment
                    date={scheduleChange.createdAt}
                    format={'DD.MM.YYYY'}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography variant={'h3'} color={'secondary'}>
                  {scheduleChange.status === 'AUTO_DRAFT'
                    ? translate('T_SCHEDULE_WAIT_FOR_USER')
                    : translate('Zgłoszenie')}
                </Typography>
                {scheduleDate.map((el, index) => (
                  <Item
                    key={index}
                    date={el}
                    schedule={
                      scheduleChange.schedule[el] !== undefined &&
                      scheduleChange.schedule[el] !== undefined
                        ? scheduleChange.schedule[el]
                        : undefined
                    }
                    previousSchedule={
                      scheduleChange.previousSchedule[el] !== undefined &&
                      scheduleChange.previousSchedule[el] !== undefined
                        ? scheduleChange.previousSchedule[el]
                        : ''
                    }
                  />
                ))}

                <SectionTitle label={'Uzasadnienie'} />
                <Typography
                  variant={'body1'}
                  color={'primary'}
                  className={classes.marginBot}
                >
                  {scheduleChange.description}
                </Typography>
              </Grid>
            </Grid>
            {scheduleChange.question && scheduleChange.questionDate && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={2}>
                  <Typography variant={'subtitle1'} color={'primary'}>
                    <Moment
                      date={scheduleChange.questionDate}
                      format={'DD.MM.YYYY'}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Typography variant={'h3'} color={'secondary'}>
                    {translate('Do wyjaśnienia')}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Typography
                    variant={'subtitle1'}
                    color={'primary'}
                    style={{ lineHeight: '28px' }}
                  >
                    {scheduleChange?.userQuestion.replace('|', ' ')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} />
                <Grid item xs={12} md={10}>
                  <Typography
                    variant={'body1'}
                    color={'primary'}
                    className={classes.marginBot}
                  >
                    {scheduleChange.question}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {scheduleChange.answer && scheduleChange.answerDate && (
              <Grid container spacing={3}>
                <Grid item xs={12} md={2}>
                  <Typography variant={'subtitle1'} color={'primary'}>
                    <Moment
                      date={scheduleChange.answerDate}
                      format={'DD.MM.YYYY'}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography variant={'h3'} color={'secondary'}>
                    {translate('Wyjaśnienie')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2} />
                <Grid item xs={12} md={10}>
                  <Typography
                    variant={'body1'}
                    color={'primary'}
                    className={classes.marginBot}
                  >
                    {scheduleChange.answer}
                  </Typography>
                </Grid>
              </Grid>
            )}
            {scheduleChange.status !== SCHEDULE_CHANGE_STATUS_REJECTED &&
              scheduleChange.status !== SCHEDULE_CHANGE_STATUS_ACCEPTED && (
                <div className={classes.buttons}>
                  <Button
                    variant="outlined"
                    className={classes.buttonRed}
                    onClick={() => handleChagneState('reject')}
                  >
                    <span>{translate('Odrzuć')}</span>
                  </Button>
                  {scheduleChange.status === SCHEDULE_CHANGE_STATUS_NEW && (
                    <>
                      <Button
                        color={'secondary'}
                        variant="outlined"
                        onClick={() => handleChagneState('question')}
                      >
                        <span>{translate('Poproś o wyjaśnienie')}</span>
                      </Button>
                    </>
                  )}
                  <Button
                    color={'secondary'}
                    variant="contained"
                    onClick={() => handleChagneState('accept')}
                  >
                    <span>{translate('Akceptuj')}</span>
                  </Button>
                  <CustomDialog
                    handleToogle={() => handleChagneState(null)}
                    open={!!state}
                    title={'Informacja dla Wnioskodawcy'}
                    confirmedButton={true}
                    onConfirm={handleQuestionConfirmed}
                    component={() => (
                      <StringType
                        name={'question'}
                        setValue={handleChangeQuestion}
                        value={question}
                        disabled={false}
                        renderError={false}
                        type={'textarea'}
                        setError={() => {}}
                        label={''}
                        width={'100%'}
                      />
                    )}
                  />
                </div>
              )}
            {(scheduleChange.status === SCHEDULE_CHANGE_STATUS_REJECTED ||
              scheduleChange.status === SCHEDULE_CHANGE_STATUS_ACCEPTED) && (
              <Grid container spacing={3} className={classes.marginBot}>
                <Grid item xs={12} md={2}>
                  <Typography variant={'subtitle1'} color={'primary'}>
                    <Moment
                      date={scheduleChange.updatedAt}
                      format={'DD.MM.YYYY'}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Typography
                    variant={'h3'}
                    color={
                      scheduleChange.status === SCHEDULE_CHANGE_STATUS_REJECTED
                        ? 'error'
                        : 'primary'
                    }
                    className={clsx(
                      classes.marginBot,
                      scheduleChange.status ===
                        SCHEDULE_CHANGE_STATUS_ACCEPTED && classes.textGreen
                    )}
                  >
                    {translate(
                      `T_SCHEDULE_CHANGE_STATUS_${scheduleChange.status}`
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={8}>
                  <Typography
                    variant={'subtitle1'}
                    color={'primary'}
                    style={{ lineHeight: '28px' }}
                  >
                    {scheduleChange?.userAcceptedRejected?.replace('|', ' ')}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}></Grid>
                <Grid item xs={12} md={10}>
                  <Typography
                    variant={'body1'}
                    color={'primary'}
                    className={classes.marginBot}
                  >
                    {scheduleChange.message}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </>
        )}
    </>
  )
}
