import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from '@material-ui/core'
import schema from '_schema/contestProvinceExpert'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'

export const Item = ({
  provinceExpert,
  contest,
  expert,
  province,
  decrement,
  increment,
  disabled,
}) => {
  const handleChange = () => {
    let url = schema.endpoint
    let method = 'POST'
    let data = {
      province: province,
      contest: contest,
      expert: expert,
    }
    if (!!provinceExpert) {
      url = `${schema.endpoint}/${provinceExpert.uuid}`
      method = 'DELETE'
      data = {}
    }
    fetchDataHandleAuthError(
      url,
      method,
      { body: JSON.stringify(data) },
      resp => {
        if (method === 'DELETE') {
          decrement(provinceExpert.uuid)
        } else {
          increment(resp)
        }
      },
      error => {
        if (error.response.detail !== undefined) {
          notification('error', error.response.detail)
        } else {
          notification(
            'error',
            'Błąd zapisu danych. Odśwież stronę i spróbuj jeszcze raz'
          )
        }
      }
    )
  }
  return (
    <>
      <Checkbox
        checked={!!provinceExpert}
        color="secondary"
        size="small"
        onChange={handleChange}
        disabled={disabled}
      />
    </>
  )
}

Item.propTypes = {
  provinceExpert: PropTypes.object,
  contest: PropTypes.string,
  expert: PropTypes.string,
  province: PropTypes.string,
  decrement: PropTypes.func,
  increment: PropTypes.func,
}
