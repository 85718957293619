import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { SchemableComponent } from 'core/components/SchemableComponent'
import { Edit } from './Edit'
import { roles } from '_helpers/roles'

const routes = () => {
  return {
    index: {
      type: Redirect,
      from: '/proposals',
      to: '/proposals/inprogress',
      resourceAccess: [ roles.APPLICANT ],
      exact: true,
      path: '/proposals',
    },
    edit: {
      type: Route,
      render: props => (
        <SchemableComponent path="definitions" component={Edit} {...props} />
      ),
      exact: true,
      resourceAccess: [ roles.APPLICANT ],
      path: '/contests/:contest_id/proposal',
    },
  }
}

export default routes
