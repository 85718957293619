import React, { useEffect } from 'react'
import { useCollectionContainerStyles } from 'components/applicant/collection_styles/CollectionContainer.styles'
import { Paper } from 'core/components/Paper'
import { BudgetTabs } from 'pages/applicant/InProgressProposals/Budget/BudgetTabs'
import routes from '../routes'
import { useBudgetFetch } from './_helpers/useBudgetFetch'
import { useStatusFetch } from './_helpers/useStatusFetch'
import { Loader } from 'core/components/Loader'
import { NotFound } from 'core/pages/NotFound'
import { useResourceState } from 'core/_helpers/useResourceState'
import proposalSchema from '_schema/proposal'
import { makeStyles } from '@material-ui/styles'
import { FormStatus, BudgetForm } from './ChangesBudget/components'
import { translate } from 'core/_helpers/translate'
import { ChangesBudgetHistory } from './components/ChangesBudgetHistory'

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  table_header_line: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    marginBottom: 30,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    '&::after': {
      content: '""',
      height: 1,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      marginLeft: 10,
    },
  },
}))
export const ChangesBudget = ({ schema: definitions, match }) => {
  const classes = useCollectionContainerStyles()
  const localClasses = useStyles()

  const proposalUuid = match.params.id

  const [budgetsState, setBudgetsState] = useResourceState()

  // aktualny budżet
  const {
    resource: budgets,
    isFetching: budgetsIsFetching,
    fetchError: budgetsFetchError,
  } = budgetsState

  useBudgetFetch(
    proposalSchema.endpoint_budget_current,
    proposalUuid,
    setBudgetsState.resource,
    setBudgetsState.fetchError
  )

  const [statusState, setStatusState] = useResourceState()

  // historia zmian budżetu
  const {
    resource: statusResource,
    isFetching: statusIsFetching,
    fetchError: statusFetchError,
  } = statusState

  useStatusFetch(
    proposalSchema.endpoint_budget_change_requests,
    proposalUuid,
    setStatusState.resource,
    setStatusState.fetchError
  )

  const iri = `${proposalSchema.endpoint}/${proposalUuid}`

  const handleSuccess = (resp, statusResource) => {
    setStatusState.resource([resp, ...statusResource])
  }

  return budgetsIsFetching ? (
    <Loader align="center" marginTop={15} />
  ) : budgetsFetchError ? (
    <NotFound />
  ) : (
    <>
      <BudgetTabs
        value={routes().changes.path.replace(':id', match.params.id)}
        classes={{ root: classes.tabs }}
        id={match.params.id}
      />
      <Paper classes={{ root: classes.paper_wrapper }}>
        {statusIsFetching ? (
          <Loader align="center" marginTop={15} />
        ) : statusFetchError ? (
          <strong>error</strong>
        ) : (
          <>
            {statusResource !== null && statusResource.length > 0 && (
              <>
                <h3 className={localClasses.header}>Status zgłoszenia:</h3>
                <strong>
                  {translate(
                    `T_BUDGET_CHANGE_STATUS_${statusResource?.[0].status}`
                  )}
                </strong>
                <span>{statusResource[0].message}</span>
              </>
            )}

            {statusResource.length > 0 &&
              statusResource?.[0].status === 'PENDING' && (
                <>
                  {statusResource?.[0].question}
                  <FormStatus
                    url={`/proposal_budget_change_requests/${statusResource?.[0].uuid}/answer`}
                    method="PATCH"
                    definitionSchema={
                      definitions[
                        proposalSchema.resource_budget_change_requests_answer
                          .definition
                      ]
                    }
                    customResourceSchema={
                      proposalSchema.resource_budget_change_requests_answer
                    }
                    submitButtonTitle={'Wyślij'}
                  />
                </>
              )}

            <BudgetForm
              resource={{
                budgetChangeHistories: budgets.map(item => ({
                  budget: item['@id'],
                  title: item.title,
                  titleOld: (() => {
                    let title = item.title
                    if (statusResource.length > 0 && statusResource[0]) {
                      const history = statusResource[0].budgetChangeHistories.find(
                        el => el.budget === item['@id']
                      )
                      if (history) {
                        title =
                          history.titleOld !== undefined
                            ? history.titleOld
                            : item.title
                      }
                    }
                    return title
                  })(),
                  subsidyAmount: item.quarterlyDeclarationItem.subsidyAmount,
                  subsidyAmountLast:
                    statusResource.length > 0 && statusResource[0]
                      ? statusResource[0].budgetChangeHistories.find(
                          el => el.budget === item['@id']
                        )?.subsidyAmount
                      : item.quarterlyDeclarationItem.subsidyAmount,
                })),
                proposal: iri,
              }}
              definitionSchema={
                definitions[
                  proposalSchema.resource_budget_change_requests.definition
                ]
              }
              customResourceSchema={
                proposalSchema.resource_budget_change_requests
              }
              url={proposalSchema.endpoint_budget_change_requests}
              handleSuccess={resp => handleSuccess(resp, statusResource)}
              disabled={
                statusResource.length > 0 &&
                statusResource[0] &&
                ['PENDING', 'NEW', 'ANSWERED'].includes(
                  statusResource[0].status
                )
              }
            />
          </>
        )}
        {statusResource !== null && statusResource.length > 0 && (
          <>
            <h3
              className={localClasses.table_header_line}
              style={{ marginTop: 100 }}
            >
              Historia zmian
            </h3>
            {statusResource.map(el => (
              <ChangesBudgetHistory
                changesBudgetRequest={el}
                budgets={budgets}
                key={el.uuid}
              />
            ))}
          </>
        )}
      </Paper>
    </>
  )
}
