import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DialogLoader } from 'core/components/DialogLoader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import { ReactComponent as IconCopy } from 'theme/icons/copy.svg'
import { StringType } from '../form/fields/StringType'
import { DialogTitle } from './Delete'
import { Loader } from '../Loader'

const useStyles = makeStyles(theme => ({
  withIcon: {
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      '& svg path': {
        fill: theme.palette.secondary.main,
      },
      color: theme.palette.primary.main,
    },
  },
  withoutIcon: {
    width: '100%',
    textDecoration: 'none',
    cursor: 'pointer',
    color: 'inherit',
    '&:hover': {
      color: 'inherit',
    },
  },
  disabled: {
    '& path': {
      fill: theme.palette.disabled,
    },
    color: theme.palette.disabled,
  },
  paper: {
    borderRadius: 0,
  },
}))

export const Clone = ({
  resource,
  disabled = false,
  onSuccess = null,
  isIcon = false,
  accessor = null,
}) => {
  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const [value, setValue] = useState({ [accessor]: resource[accessor] })

  const handleChange = (name, value) => {
    setValue({ [name]: value })
  }

  const handleError = () => {}

  const handleConfirmToggle = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
  }

  const classes = useStyles()
  const handleClick = () => {
    if (disabled) {
      return
    }

    setState({
      isConfirmOpen: true,
      isFetching: true,
    })

    fetchDataHandleAuthError(
      resource['@id'],
      'PUT',
      {
        body: JSON.stringify({
          performCloneTitle: value[accessor],
        }),
      },
      () => {
        setState({
          isConfirmOpen: false,
          isFetching: false,
        })

        notification('success', 'T_FORM_RECORD_CLONED', 'T_FORM_SUCCESS')
        onSuccess && onSuccess()
      },
      error => {
        setState({
          isConfirmOpen: true,
          isFetching: false,
        })

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return (
    <>
      {disabled ? (
        isIcon ? (
          <IconCopy className={classes.disabled} disabled />
        ) : (
          <span className={classes.disabled}>
            {translate('T_GENERAL_CLONE')}
          </span>
        )
      ) : (
        <>
          {isIcon ? (
            <IconButton
              onClick={handleConfirmToggle}
              className={isIcon ? classes.withIcon : classes.withoutIcon}
            >
              <Tooltip title={translate('T_GENERAL_CLONE')}>
                <IconCopy />
              </Tooltip>
            </IconButton>
          ) : (
            <button onClick={handleClick}>
              {translate('T_GENERAL_CLONE')}
            </button>
          )}
        </>
      )}
      <Dialog
        className={classes.paper}
        open={state.isConfirmOpen}
        fullWidth={true}
        maxWidth="sm"
      >
        <DialogTitle onClose={handleConfirmToggle}>
          {translate('Skopiuj element pod nową nazwą')}
        </DialogTitle>
        <DialogContent dividers>
          {state.isFetching && <Loader align="center" marginBottom={15} />}
          <StringType
            name={accessor}
            setValue={handleChange}
            value={value[accessor]}
            disabled={false}
            renderError={false}
            type={'string'}
            label={'Nazwa'}
            fullWidth={true}
            error={null}
            setError={handleError}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleConfirmToggle}
            disabled={state.isFetching}
            autoFocus
            variant="outlined"
            size="large"
            color={'secondary'}
            disableRipple
            disableFocusRipple
            disableElevation
          >
            {translate('T_GENERAL_CANCEL')}
          </Button>
          <Button
            onClick={handleClick}
            disabled={state.isFetching}
            variant="contained"
            color="secondary"
            size="large"
            disableRipple
            disableFocusRipple
          >
            {translate('T_GENERAL_CLONE')}
          </Button>
        </DialogActions>
      </Dialog>
      <DialogLoader
        isOpen={state.isFetching}
        title={translate('T_GENERAL_CLONING')}
      />
    </>
  )
}

Clone.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  isIcon: PropTypes.bool,
  accessor: PropTypes.string,
}
