import foundationHome from './Foundation/Home/routes'
import foundationExperts from './Foundation/Experts/routes'
import applicantHome from './applicant/Home/routes'
import applicantCurrentContests from './applicant/CurrentContests/routes'
import applicantAnnouncedContests from './applicant/AnnouncedContests/routes'
import applicantFinishedContests from './applicant/FinishedContests/routes'
import applicantContests from './applicant/Contests/routes'
import applicantProposals from './applicant/Proposals/routes'
import applicantOrganizationData from './applicant/OrganizationData/routes'
import applicantInProgressProposals from './applicant/InProgressProposals/routes'
import applicantDraftProposals from './applicant/DraftProposals/routes'
import applicantAppliedProposals from './applicant/AppliedProposals/routes'
import applicantFinishedProposals from './applicant/FinishedProposals/routes'
import applicantFaq from './applicant/Faq/routes'
import changePassword from './ChangePassword/routes'
import expertHome from './Expert/Home/routes'
import forms from './Foundation/Form/routes'
import foundationSettings from './Foundation/Settings/routes'
import foundationOrganizations from './Foundation/Organizations/routes'
import foundationProjects from './Foundation/Projects/routes'

const routes = () => [
  ...getArray(foundationHome()),
  ...getArray(foundationExperts()),
  ...getArray(expertHome()),
  ...getArray(forms()),
  ...getArray(applicantHome()),
  ...getArray(applicantCurrentContests()),
  ...getArray(applicantAnnouncedContests()),
  ...getArray(applicantFinishedContests()),
  ...getArray(applicantContests()),
  ...getArray(applicantProposals()),
  ...getArray(applicantOrganizationData()),
  ...getArray(applicantInProgressProposals()),
  ...getArray(applicantDraftProposals()),
  ...getArray(applicantAppliedProposals()),
  ...getArray(applicantFinishedProposals()),
  ...getArray(applicantFaq()),
  ...getArray(changePassword()),
  ...getArray(foundationSettings()),
  ...getArray(foundationOrganizations()),
  ...getArray(foundationProjects()),
]

const getArray = routes => Object.values(routes)

export default routes
