import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { getBlockFieldsMapping } from 'pages/applicant/Proposals/_helpers/getBlockFieldsMapping'
import { blockTypes } from 'pages/applicant/Proposals/_helpers/blockTypes'
import { DataInfo } from 'components/DataInfo'
import { Grid, Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  help: {
    marginBottom: theme.spacing(3),
    color: theme.palette.disabled,
  },
}))

export const InputBlock = ({ data, values }) => {
  const mapping = useMemo(() => getBlockFieldsMapping(blockTypes.INPUT, data), [
    data,
  ])

  const classes = useStyles()

  return (
    <Grid container spacing={3}>
      <Grid item md={8}>
        <DataInfo
          label={mapping.input.label}
          data={`${values?.input || ''} ${
            mapping.input.unitLabel !== undefined ? mapping.input.unitLabel : ''
          }`}
        />
      </Grid>
      <Grid item md={4}>
        {mapping.input.help && (
          <Typography
            variant={'body1'}
            color={'primary'}
            className={classes.help}
          >
            {mapping.input.help}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

InputBlock.propTypes = {
  data: PropTypes.string,
  values: PropTypes.object,
}
