import React from 'react'
import { DateType } from 'core/components/form/fields/DateType'
import { DateTimeType } from 'core/components/form/fields/DateTimeType'

export const DataTime = ({ block }) => {
  const values = block.data ? JSON.parse(block.data) : {}
  return (
    <>
      {values.time ? (
        <DateTimeType
          name={'datatime'}
          setValue={() => false}
          disabled={false}
          renderError={false}
          setError={() => false}
          error={false}
          label={values.label}
          validators={[values.required ? 'required' : null]}
        />
      ) : (
        <DateType
          name={'datatime'}
          setValue={() => false}
          disabled={false}
          renderError={false}
          setError={() => false}
          error={false}
          label={values.label}
          validators={[values.required ? 'required' : null]}
        />
      )}
    </>
  )
}
