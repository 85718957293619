import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Tooltip,
  Typography,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Loader } from 'core/components/Loader'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { translate } from 'core/_helpers/translate'
import { prop } from 'core/_helpers/prop'
import { ReactComponent as RemoveIcon } from 'theme/icons/remove.svg'
import { ReactComponent as CloseIcon } from 'theme/icons/close.svg'

const useStyles = makeStyles(theme => ({
  delete: {
    cursor: 'pointer',
    '&:hover, &:focus': {
      '& path': {
        fill: theme.palette.primary.main,
      },
    },
  },
  text: {
    cursor: 'pointer',
    width: 'auto',
    fontWeight: 700,
    color: theme.palette.error.main,
    transition: 'color 0.2s',
    textAlign: 'right',
    background: 'transparent',
    border: 0,
    boxShadow: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  disabled: {
    '& path': {
      fill: theme.palette.disabled,
    },
  },
  confirmText: {
    marginTop: 12,
  },
  paper: {
    borderRadius: 0,
  },
}))

const styles = theme => ({
  root: {
    minHeight: 56,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    backgroundColor: 'transparent',
    '&:hover, &:focus': {
      backgroundColor: 'transparent',
      '& svg path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
})

export const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h2" color={'primary'}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

export const Delete = ({
  resource,
  accessor = 'title',
  disabled = false,
  onSuccess = null,
  isIcon = false,
  deleteText = '',
  url = null,
}) => {
  const [state, setState] = useState({
    isConfirmOpen: false,
    isFetching: false,
  })

  const handleConfirmToggle = () => {
    setState(state => ({
      ...state,
      isConfirmOpen: !state.isConfirmOpen,
    }))
  }

  const handleDelete = () => {
    setState(state => ({
      ...state,
      isFetching: true,
    }))

    let method = 'DELETE'
    let request = {}
    if (resource.isDeleted === false) {
      method = 'PUT'
      request = {
        body: JSON.stringify({ isDeleted: true }),
      }
    }

    fetchDataHandleAuthError(
      typeof url === 'function'
        ? url(resource)
        : url !== null
        ? url
        : resource['@id'],
      method,
      request,
      () => {
        setState({
          isConfirmOpen: false,
          isFetching: false,
        })

        notification('success', 'T_GENERAL_DELETED', 'T_FORM_SUCCESS')
        onSuccess && onSuccess(resource)
      },
      error => {
        setState({
          isConfirmOpen: false,
          isFetching: false,
        })

        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const classes = useStyles()

  return (
    <>
      {disabled ? (
        isIcon ? (
          <IconButton disabled className={classes.disabled}>
            <RemoveIcon disabled />
          </IconButton>
        ) : (
          <span className={classes.disabled}>
            {translate(deleteText ? deleteText : 'T_GENERAL_DELETE')}
          </span>
        )
      ) : (
        <>
          {isIcon ? (
            <Tooltip title={translate('T_GENERAL_DELETE')}>
              <IconButton
                disableRipple
                disableFocusRipple
                className={classes.delete}
                onClick={handleConfirmToggle}
              >
                <RemoveIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <button className={classes.text} onClick={handleConfirmToggle}>
              {translate(deleteText ? deleteText : 'T_GENERAL_DELETE')}
            </button>
          )}
          <Dialog
            open={state.isConfirmOpen}
            fullWidth={true}
            maxWidth="sm"
            classes={{
              paper: classes.paper,
            }}
          >
            <DialogTitle onClose={handleConfirmToggle}>
              {translate('T_GENERAL_DELETE')}
            </DialogTitle>
            <DialogContent dividers>
              {state.isFetching && <Loader align="center" marginBottom={15} />}
              {resource?.proposalSubmittedCount > 0 && (
                <DialogContentText
                  className={classes.confirmText}
                  variant="body1"
                >
                  <Typography
                    variant={'subtitle1'}
                    component={'span'}
                    color={'primary'}
                  >
                    {translate('W konkursie ')}
                    {accessor && typeof accessor === 'function'
                      ? accessor(resource)
                      : prop(resource, accessor)}
                    {translate(' znajdują się złożone wnioski - ')}
                    {prop(resource, 'proposalSubmittedCount')}
                  </Typography>
                </DialogContentText>
              )}
              <DialogContentText
                className={classes.confirmText}
                variant="body1"
              >
                <Typography
                  variant="subtitle1"
                  component="span"
                  color={'primary'}
                >
                  {resource?.isDeleted === false
                    ? translate('Czy na pewno przenieść do kosza')
                    : translate('T_GENERAL_DELETE_CONFIRM')}
                  {` "${
                    accessor
                      ? typeof accessor === 'function'
                        ? accessor(resource)
                        : prop(resource, accessor)
                      : translate('T_GENERAL_MISSING_TITLE')
                  }"?`}
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleConfirmToggle}
                disabled={state.isFetching}
                autoFocus
                variant="outlined"
                size="large"
                color={'secondary'}
                disableRipple
                disableFocusRipple
                disableElevation
              >
                {translate('T_GENERAL_CANCEL')}
              </Button>
              <Button
                onClick={handleDelete}
                disabled={state.isFetching}
                variant="contained"
                color="secondary"
                size="large"
                disableRipple
                disableFocusRipple
              >
                {translate('T_GENERAL_CONFIRM')}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}

Delete.propTypes = {
  resource: PropTypes.shape({
    '@id': PropTypes.string.isRequired,
  }).isRequired,
  accessor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.func,
  ]),
  disabled: PropTypes.bool,
  onSuccess: PropTypes.func,
  isIcon: PropTypes.bool,
  deleteText: PropTypes.string,
  url: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
}
