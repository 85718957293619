export const translations = {
  pl: {
    /* Inside application code */
    January: 'Styczeń',
    February: 'Luty',
    March: 'Marzec',
    April: 'Kwiecień',
    May: 'Maj',
    June: 'Czerwiec',
    July: 'Lipiec',
    August: 'Sierpień',
    September: 'Wrzesień',
    October: 'Październik',
    November: 'Listopad',
    December: 'Grudzień',
    T_GENERAL_OPERATIONS: 'Operacje',
    T_GENERAL_EDIT: 'Edytuj',
    T_GENERAL_NEW: 'Dodaj',
    T_GENERAL_CLONE: 'Klonuj',
    T_GENERAL_CLONING: 'Klonuję...',
    T_GENERAL_VERSION_CREATE: 'Archiwizuj',
    T_GENERAL_VERSION_CREATING: 'Archiwizuję...',
    T_GENERAL_VERSION_RESTORE: 'Przywróć wersję',
    T_GENERAL_VERSION_RESTORING: 'Przywracam wersję...',
    T_GENERAL_VERSION_CREATED_AT: 'Wersja z dnia',
    T_GENERAL_VIEW: 'Pokaż',
    T_GENERAL_DELETE: 'Usuń',
    T_GENERAL_DELETE_CONFIRM: 'Czy napewno usunąć',
    T_GENERAL_DELETED: 'Rekord usunięty',
    T_GENERAL_CREATED_AT: 'Utworzono',
    T_GENERAL_UPDATED_AT: 'Ostatnia aktualizacja',
    T_GENERAL_CANCEL: 'Anuluj',
    T_GENERAL_CONFIRM: 'Potwierdź',
    T_GENERAL_STATUS_CHANGED: 'Zmieniono status',
    T_GENERAL_SAVE: 'Zapisz',
    T_GENERAL_SAVE_AND_SEND: 'Zapisz i wyślij',
    T_GENERAL_SAVE_TO_KEEP_CHANGES: 'Zapisz aby zachować zmiany',
    T_GENERAL_SEARCH: 'Szukaj',
    T_GENERAL_ALL: 'Wszystkie',
    T_GENERAL_REFRESH: 'Odswież',
    T_GENERAL_TOGGLE_MENU: 'Przełącznik menu',
    T_GENERAL_RESULT_SUCCESS: 'Udane',
    T_GENERAL_RESULT_FAILURE: 'Nieudane',
    T_GENERAL_NO: 'Nr',
    T_GENERAL_OPERATION_DATE: 'Data operacji',
    T_GENERAL_MODULE: 'Moduł',
    T_GENERAL_RECORD: 'Rekord',
    T_GENERAL_OPERATION: 'Operacja',
    T_GENERAL_SHOW_ALL: 'Pokaż wszystkie',
    T_GENERAL_REQUIRED: 'Wymagany',
    T_GENERAL_DATE: 'Data',
    T_GENERAL_EMPTY: 'Brak',
    T_GENERAL_TITLE: 'Tytuł',
    T_GENERAL_INVALID_DATE: 'Niepoprawny format',
    T_GENERAL_INVALID_MIN_DATE: 'Data nie może być mniejsza niż 01.01.1990',
    T_GENERAL_INVALID_MAX_DATE: 'Data nie może być większa niż 01.01.2100',
    T_GENERAL_DESCRIPTION: 'Krótki opis',
    T_GENERAL_DESCRIPTION_HINT: 'Wyświetlany na detalu',
    T_GENERAL_LEAD: 'Lead',
    T_GENERAL_LEAD_HINT: 'Wyświetlany na liście',
    T_GENERAL_PUBLICATION: 'Publikacja',
    T_GENERAL_CODE: 'Kod',
    T_GENERAL_TIME: 'Czas',
    T_GENERAL_ADMIN: 'Administrator',
    T_GENERAL_RECORD_LIST: 'Lista',
    T_GENERAL_RECORD_NEW: 'Dodaj',
    T_GENERAL_RECORD_NEW_BUTTON: 'Dodaj nowy rekord',
    T_GENERAL_RECORD_EDIT: 'Edycja',
    T_GENERAL_RECORD_SHOW: 'Pokaż',
    T_GENERAL_RECORD_PREVIEW: 'Podgląd',
    T_GENERAL_RECORD_VERSION_COLLECTION: 'Poprzednie wersje',
    T_GENERAL_RECORD_VERSION_VIEW: 'Podgląd',
    T_GENERAL_ITEMS_EMPTY: 'Brak elementów',
    T_GENERAL_BLOCK: 'Bloki',
    T_GENERAL_BLOCK_TYPE_TEXT: 'Blok tekstowy',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_PHOTO: 'Blok tekstowy ze zdjęciem',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_QUOTATION: 'Blok tekstowy z cytatem',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_LINK: 'Blok tekstowy z linkiem',
    T_GENERAL_BLOCK_TYPE_TEXT_WITH_SECTIONS: 'Blok tekstowy z sekcjami',
    T_GENERAL_BLOCK_TYPE_PACKAGE_FILE: 'Blok z plikami',
    T_GENERAL_BLOCK_TYPE_PACKAGE_GALLERY: 'Blok z galerią',
    T_GENERAL_BLOCK_TYPE_PACKAGE_FAQ: 'Blok z FAQ',
    T_GENERAL_BLOCK_TYPE_PACKAGE_FORM: 'Blok z formularzem',
    T_GENERAL_BLOCK_MEDIA_POSITION_LEFT: 'Po lewej od tekstu',
    T_GENERAL_BLOCK_MEDIA_POSITION_TOP: 'Pod tekstem',
    T_GENERAL_BLOCK_MEDIA_POSITION_RIGHT: 'Po prawej od tekstu',
    T_GENERAL_BLOCK_GALLERY_TYPE_SLIDER: 'Slider',
    T_GENERAL_BLOCK_GALLERY_TYPE_TILES: 'Kafelki',
    T_GENERAL_CHOOSE: 'Wybierz',
    T_GENERAL_MISSING_TITLE: '<Brak tytułu>',
    T_GENERAL_SUPER_ADMIN: 'Super Admin',
    T_GENERAL_TRANSLATION: 'Tłumaczenie',
    T_GENERAL_MANUAL_ORDER: 'Manualna kolejność',
    T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_PREVIOUS:
      'Upuść tu żeby przenieść na poprzednią stronę',
    T_GENERAL_MANUAL_ORDER_PAGE_DROPZONE_NEXT:
      'Upuść tu żeby przenieść na następną stronę',
    T_GENERAL_DRAG_HANDLE: 'Przytrzymaj i przeciągnij, aby zmienić kolejność',
    T_GENERAL_UPLOAD: 'Upload pliku',
    T_GENERAL_UPLOAD_MULTIPLE: 'Upload plików',
    T_GENERAL_PHOTO: 'Zdjęcie',
    T_GENERAL_IMAGE_ADD: 'Dodaj zdjęcie',
    T_GENERAL_IMAGE_CHANGE: 'Wymień zdjęcie',
    T_GENERAL_IMAGE_REMOVE: 'Usuń zdjęcie',
    T_GENERAL_IMAGE_REMOVE_CONFIRM: 'Czy napewno usunąć zdjęcie?',
    T_GENERAL_PHOTO_THUMBS: 'Kadry',
    T_GENERAL_PHOTO_THUMBS_EMPTY: 'Brak kadrów',
    T_GENERAL_PHOTO_THUMBS_SAVE: 'Zapisz kadr',
    T_GENERAL_PHOTO_THUMBS_RESET: 'Resetuj kadr',
    T_GENERAL_PHOTO_THUMBS_CHANGE_FILE: 'Zmień plik dla kadru',
    T_GENERAL_EXPAND_ON: 'zwiń wszystkie',
    T_GENERAL_EXPAND_OFF: 'rozwiń wszystkie',
    T_GENERAL_EXPAND_BLOCKS: 'bloki',
    T_GENERAL_BLOCK_COLLECTION_TITLE: 'Bloki śródtekstowe',
    T_GENERAL_ALT: 'Znacznik alt zdjęcia',
    T_GENERAL_FILE: 'Plik',
    T_GENERAL_FILE_ADD: 'Dodaj plik',
    T_GENERAL_FILE_CHANGE: 'Wymień plik',
    T_GENERAL_FILE_REMOVE: 'Usuń plik',
    T_GENERAL_FILE_REMOVE_CONFIRM: 'Czy napewno usunąć plik?',
    T_GENERAL_NAME: 'Nazwa',
    T_GENERAL_SEND: 'Wyślij',
    T_GENERAL_CHOSEN_FILE: 'Wybrany plik',
    T_GENERAL_CHOSEN_FILES: 'Wybrane pliki',
    T_GENERAL_NONE: 'brak',
    T_GENERAL_YOUTUBE_CODE: 'Kod filmu youtube',
    T_GENERAL_VIDEO_MASK: 'Maska filmu',
    T_GENERAL_MEDIA_PHOTO: 'Zdjęcie',
    T_GENERAL_MEDIA_YOUTUBE: 'Film youtube',
    T_GENERAL_MASS_SELECT_ACTION: 'Co zrobić z zaznaczonymi rekordami',
    T_GENERAL_MASS_SELECT_CHOOSE: 'Wybierz',
    T_GENERAL_MASS_SELECT_PUBLISH: 'Publikuj',
    T_GENERAL_MASS_SELECT_UNPUBLISH: 'Odpublikuj',
    T_GENERAL_ENABLE: 'Włącz',
    T_GENERAL_DISABLE: 'Wyłącz',
    T_GENERAL_OPENFORM_ADMIN_GROUP_IS_OPENFORM: '[OF] Prawa openform',
    T_GENERAL_OPENFORM_PAGE_IDNAME: '[OF] Id name',
    T_GENERAL_OPENFORM_PAGE_IS_STATABLE: '[OF] Publikowalna',
    T_GENERAL_OPENFORM_PAGE_IS_EDITABLE: '[OF] Edytowalna',
    T_GENERAL_OPENFORM_PAGE_IS_DELETABLE: '[OF] Usuwalna',
    T_GENERAL_OPENFORM_PAGE_IS_SUBPAGEABLE: '[OF] Podstrony',
    T_GENERAL_SAVE_FORM_TO_EDIT: 'Zapisz formularz, aby edytować',
    T_GENERAL_PASSWORD_WAS_RESETTED:
      'Twoje hasło zostało zresetowane przez administratora. Ustaw nowe hasło, a następnie przeloguj się, aby odzyskać dostęp.',
    T_GENERAL_EDIT_DESCRIPTION: 'edytuj opis',
    T_GENERAL_BACK_TO_LIST: 'Wróć na listę',
    T_GENERAL_NEXT: 'Następny',
    T_GENERAL_PREVIOUS: 'Poprzedni',
    T_GENERAL_FULL_SCREEN: 'Full screen',
    T_GENERAL_LOGIN: 'Zaloguj się',
    T_GENERAL_LOGOUT: 'Wyloguj',
    T_GENERAL_REMEMBER_ME: 'Zapamiętaj mnie',
    T_GENERAL_FORGOT_PASSWORD: 'Zapomniałem hasła',
    T_GENERAL_FORGOT_PASSWORD_TITLE: 'Zapomniałem hasła',
    T_GENERAL_FORGOT_PASSWORD_TYPE_EMAIL: 'Twój adres e-mail',
    T_GENERAL_FORGOT_PASSWORD_SEND_LINK: 'Wyślij link',
    T_GENERAL_FORGOT_PASSWORD_VALIDATION_EMAIL: 'Niepoprawny adres e-mail',
    T_GENERAL_FORGOT_PASSWORD_LINK_SENT:
      'Link wysłany. Sprawdź swój adres e-mail',
    T_GENERAL_NEW_PASSWORD_TITLE: 'Nadaj nowe hasło',
    T_GENERAL_NEW_PASSWORD_TYPE_NEW: 'Wpisz nowe hasło',
    T_GENERAL_NEW_PASSWORD_RETYPE_NEW: 'Powtórz nowe hasło',
    T_GENERAL_NEW_PASSWORD_CHANGE: 'Zmień hasło',
    T_GENERAL_NEW_PASSWORD_RESETTED: 'Hasło zostało zmienione',
    T_GENERAL_BACK_TO_LOGIN: 'Powrót do logowania',
    T_GENERAL_MAP: 'Mapa',
    T_GENERAL_MAP_HINT:
      'Wpisz pełną lokalizację lub częściową i wybierz z listy. Marker na mapie zostanie automatycznie ustawiony',
    T_GENERAL_MAP_INPUT: 'Wpisz lokalizację',
    T_GENERAL_NORMAL_VIEW: 'Powrót do poprzedniego widoku',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_CHOSEN: 'Dodajesz % plików',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_INFO: 'Nadaj wszystkim dodawanym:',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING: 'Dodaję',
    T_GENERAL_RESOURCE_ADD_MULTIPLE_UPLOADING_FROM: 'z',
    T_GENERAL_CONTEXTUAL_HINT: 'Pomoc kontekstowa',
    T_GENERAL_YOU_ARE_LOGGED_AS: 'Jesteś zalogowany jako',
    T_GENERAL_PREVIEW: 'Podgląd',
    T_GENERAL_MASS_UPLOAD_DRAG_AND_DROP:
      'Przeciągnij i upuść lub kliknij, żeby wybrać',
    T_GENERAL_PLANNED_PUBLISH_DATE_TIME: 'Planowana publikacja',
    T_GENERAL_PUBLISH: 'Publikuj',
    T_GENERAL_UNPUBLISH: 'Odpublikuj',
    T_GENERAL_VERSION: 'Wersja',
    T_GENERAL_DOWNLOAD_YOUTUBE_THUMBNAIL: 'Pobierz zaślepkę z youtube',
    T_GENERAL_SEE: 'zobacz',
    T_GENERAL_SAVE_DATA: 'Zapisz dane',
    T_LOGIN_DATE: 'Data logowania',
    T_TABLE_FIRST_PAGE: 'Pierwsza strona',
    T_TABLE_LAST_PAGE: 'Ostatnia strona',
    T_TABLE_RESET: 'Wyczyść ustawienia tabeli',
    T_TABLE_NO_RESULTS: 'Brak wyników',
    T_MODULE_DASHBOARD: 'Dashboard',
    T_MODULE_SETTINGS: 'Ustawienia',
    T_MODULE_PROFILE: 'Profil',
    T_MODULE_PACKAGE_GALLERIES: 'Lista galerii',
    T_MODULE_PACKAGE_GALLERIES_NEW_BUTTON: 'Dodaj nową galerię',
    T_MODULE_PACKAGE_GALLERY_ITEMS: 'Lista mediów',
    T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_BUTTON: 'Dodaj nowe zdjęcie',
    T_MODULE_PACKAGE_GALLERY_ITEMS_NEW_MULTIPLE_BUTTON: 'Dodaj wiele zdjęć',
    T_MODULE_PACKAGE_FILES: 'Lista pakietów plików',
    T_MODULE_PACKAGE_FILES_NEW_BUTTON: 'Dodaj nowy pakiet',
    T_MODULE_PACKAGE_FAQS: 'Lista FAQ',
    T_MODULE_PACKAGE_FAQS_NEW_BUTTON: 'Dodaj nowy FAQ',
    T_MODULE_PACKAGE_FAQ_ITEMS: 'Sekcje',
    T_MODULE_PACKAGE_FAQ_ITEMS_NEW_BUTTON: 'Dodaj nową sekcję',
    T_MODULE_PACKAGE_FAQ_ITEM_ELEMENTS: 'Lista pytań',
    T_MODULE_PACKAGE_FORMS: 'Lista formularzy',
    T_MODULE_PACKAGE_FORMS_NEW_BUTTON: 'Dodaj nowy formularz',
    T_MODULE_PACKAGE_FORM_ITEMS_NEW_BUTTON: 'Dodaj nowy element',
    T_MODULE_PACKAGE_FORM_ITEM_TYPE_TEXT: 'Pole tekstowe krótkie',
    T_MODULE_PACKAGE_FORM_ITEM_TYPE_TEXTAREA: 'Pole tekstowe długie',
    T_MODULE_PACKAGE_FORM_ITEM_TYPE_RADIO: 'Grupa radio buttonów',
    T_MODULE_PACKAGE_FORM_ITEM_TYPE_CHECKBOX: 'Grupa checkboxów',
    T_MODULE_TRANSLATOR_ENTRIES: 'Lista tłumaczeń',
    T_MODULE_TRANSLATOR_ENTRIES_NEW_BUTTON: 'Dodaj nowe tłumaczenie',
    T_MODULE_ADMINS: 'Lista administratorów',
    T_MODULE_ADMINS_NEW_BUTTON: 'Dodaj nowego administratora',
    T_MODULE_ADMIN_GROUPS: 'Lista grup administratorskich',
    T_MODULE_ADMIN_GROUPS_NEW_BUTTON: 'Dodaj nową grupę administratorską',
    T_MODULE_ARTICLES: 'Lista artykułów',
    T_MODULE_ARTICLES_NEW_BUTTON: 'Dodaj nowy artykuł',
    T_MODULE_PAGES: 'Lista stron',
    T_MODULE_PAGES_NEW_BUTTON: 'Dodaj nową stronę',
    T_MODULE_PAGES_EDIT_STRUCTURE: 'Edycja struktury',
    T_MODULE_PAGES_SHOW_ALL_CHILDREN: 'Rozwiń wszystko',
    T_MODULE_PAGES_HIDE_ALL_CHILDREN: 'Zwiń wszystko',
    T_MODULE_PAGES_ADD_SUBPAGE: 'Dodaj podstronę',
    T_MODULE_PAGES_SUBPAGE_UNDER_PREVIOUS_PAGE:
      'Przenieś jako podstronę strony wyżej',
    T_MODULE_PACKAGE_FILE_ITEMS_HEADER_TITLE: 'Pliki:',
    T_MODULE_PACKAGE_FILE_ITEMS_EXPAND_TITLE: 'pliki',
    T_MODULE_PACKAGE_FAQ_ITEM_ELEMENTS_HEADER_TITLE: 'Pytania:',
    T_MODULE_PACKAGE_FAQ_ITEM_ELEMENTS_EXPAND_TITLE: 'pytania',
    T_MODULE_PACKAGE_FORM_ITEM_VARIANTS_HEADER_TITLE: 'Podelementy:',
    T_MODULE_PACKAGE_FORM_ITEM_VARIANTS_EXPAND_TITLE: 'podelementy',
    T_MODULE_MESSENGER: 'Wiadomości',
    T_MODULE_MESSENGER_RECIPIENT: 'Odbiorca',
    T_MODULE_MESSENGER_SEND_TO: 'Napisz do',
    T_MODULE_MESSENGER_GO_FURTHER: 'Przejdź dalej',
    T_MODULE_MESSENGER_THREAD: 'Wątek',
    T_MODULE_MESSENGER_MESSAGE: 'Wiadomość',
    T_MODULE_MESSENGER_WITH: 'z',
    T_MODULE_MESSENGER_SENT: 'Wiadomość wysłana',
    T_MODULE_MESSENGER_MORE: 'Więcej',
    T_MODULE_MESSENGER_UPDATED_AT: 'Aktualizacja',
    T_MODULE_MESSENGER_YOU: 'Ty',
    T_MENU_PACKAGES: 'Pakiety',
    T_MENU_REGISTRIES: 'Rejestry',
    T_REQUEST_METHOD_GET: 'Wyświetlenie',
    T_REQUEST_METHOD_PATCH: 'Edycja',
    T_REQUEST_METHOD_PUT: 'Edycja',
    T_REQUEST_METHOD_POST: 'Utworzenie',
    T_REQUEST_METHOD_DELETE: 'Usunięcie',
    T_FORM_PROFILE_TITLE: 'Dane',
    T_FORM_CHANGE_PASSWORD_TITLE: 'Zmiana hasła',
    T_FORM_FIELD_EMAIL: 'E-mail',
    T_FORM_FIELD_PASSWORD: 'Hasło',
    T_FORM_FIELD_NEW_PASSWORD: 'Nowe hasło',
    T_FORM_FIELD_NEW_PASSWORD_REPEAT: 'Powtórz nowe hasło',
    T_FORM_FIELD_HINT_ENTER_NEW_PASSWORD: 'Podaj nowe hasło',
    T_FORM_FIELD_HINT_REPEAT_NEW_PASSWORD: 'Powtórz nowe hasło',
    T_FORM_FIELD_RESOURCE_ACCESS: 'Dostęp do modułów',
    T_FORM_FIELD_BOOLEAN_ON: 'Tak',
    T_FORM_FIELD_BOOLEAN_OFF: 'Nie',
    T_FORM_INCORRECT: 'Niepoprawny formularz',
    T_FORM_SUCCESS: 'Zapisano',
    T_FORM_RECORD_UPDATED: 'Rekord zaktualizowany',
    T_FORM_RECORD_CREATED: 'Rekord dodany',
    T_FORM_RECORD_CLONED: 'Rekord sklonowany',
    T_FORM_RECORD_MULTIPLE_CREATED: 'Rekordy dodane',
    T_FORM_RECORD_MULTIPLE_UPDATED: 'Rekordy zaktaulizowane',
    T_FORM_RECORD_UPLOADED: 'Rekord wgrany',
    T_FORM_RECORD_VERSION_CREATED: 'Wersja rekordu utworzona',
    T_FORM_RECORD_VERSION_RESTORED: 'Wersja rekordu przywrócona',
    T_FORM_CONTEXTUAL_HINTS_ON: 'Pomoc kontekstowa włączona',
    T_FORM_CONTEXTUAL_HINTS_OFF: 'Pomoc kontekstowa wyłączona',
    T_FORM_SAVE: 'Zapisz',
    T_FORM_SAVE_AND_STAY: 'Zapisz i pozostań',
    T_FORM_ACCEPT: 'Akceptuj',
    T_FORM_CANCEL: 'Anuluj',
    T_FORM_RECORD_CHANGED:
      'Rekord został zmieniony przez innego administratora. Sprawdź zmiany i zaakceptuj.',
    T_VALIDATION_REQUIRED: 'Pole wymagane',
    T_VALIDATION_EMAIL: 'Niepoprawny e-mail',
    T_VALIDATION_PHONE: 'Niepoprawny numer telefonu',
    T_VALIDATION_POSTCODE: 'Niepoprawny kod pocztowy',
    T_VALIDATION_PASSWORD:
      'Hasło musi składać się z min. 8 znaków (duża litera, mała litera, cyfra, znak specjalny)',
    T_VALIDATION_PASSWORDS_DO_NOT_MATCH: 'Hasła nie zgadzają się',
    T_VALIDATION_LENGTH: 'To pole musi się składać z min. % znaków',
    T_VALIDATION_MAX_LENGTH: 'To pole może się składać z max. % znaków',
    T_VALIDATION_MAX_UPLOAD_SIZE: 'Maksymalny rozmiar pliku to %',
    T_VALIDATION_MAX_UPLOAD_MULTIPLE_SIZE:
      'Maksymalny rozmiar wszystkich plików to %',
    T_VALIDATION_MIN_VALUE: 'Minimalna wartość to %',
    T_VALIDATION_MAX_VALUE: 'Maksymalna wartość to %',
    T_404_TITLE: 'Strona nie istnieje',
    T_404_TEXT:
      'Wygląda na to, że kliknąłeś niedziałający link lub wprowadziłeś adres URL, który nie istnieje na tej witrynie.',
    T_404_BACK: 'Powrót na stronę główną',
    T_SETTINGS_MODE_LIGHT: 'Przejdź na tryb jasny',
    T_SETTINGS_MODE_DARK: 'Przejdź na tryb ciemny',
    T_FRONT_PREVIEW_ERROR_TITLE: 'Błąd',
    T_FRONT_PREVIEW_ERROR_TEXT: 'Błąd przy pobieraniu strony frontowej',
    /* Thumbs */
    T_THUMB_PAGE_BLOCK_THUMBS_BLOCK: 'Kadr bloku',
    T_THUMB_PAGE_BLOCK_THUMBS_SLIDER: 'Kadr slidera',
    T_THUMB_PACKAGE_GALLERY_ITEM_THUMBS_BLOCK: 'Kadr bloku',
    T_THUMB_PACKAGE_GALLERY_ITEM_THUMBS_SLIDER: 'Kadr slidera',
    T_THUMB_PACKAGE_GALLERY_ITEM_THUMBS_TEST: 'Kadr testowy',
    T_THUMB_PAGE_BLOCK_SECTION_THUMBS_BLOCK: 'Kadr sekcji',
    /* Returned by API: modules */
    Administrators: 'Administratorzy',
    'Administrator Groups': 'Grupy administratorskie',
    'Registry entries': 'Rejestr zmian',
    'Login entries': 'Historia logowania',
    Pages: 'Struktura stron',
    'Page blocks': 'Bloki strony',
    'Page block thumbs': 'Kadry bloku strony',
    'File packages': 'Pliki',
    'File package items': 'Elementy pakietu plików',
    'Gallery packages': 'Galerie',
    'Gallery package items': 'Elementy galerii',
    'Gallery package item thumbs': 'Kadry elementu galerii',
    'Faq packages': 'FAQ',
    'Faq package sections': 'Sekcje FAQ',
    'Faq package section questions': 'Pytania sekcji FAQ',
    'Form packages': 'Formularze',
    'Form package elements': 'Elementy formularza',
    'Form package element variants': 'Podelementy elementu formularza',
    'Translator entries': 'Tłumaczenia',
    Articles: 'Artykuły',
    'Page block sections': 'Sekcje bloku strony',
    'Page block section thumbs': 'Kadry sekcji bloku strony',
    /* Returned by API: fields */
    'Name and surname': 'Imię i nazwisko',
    Group: 'Grupa',
    'Is enabled': 'Aktywny',
    Title: 'Tytuł',
    Username: 'Nazwa użytkownika',
    'Origin ip': 'IP',
    Result: 'Status',
    'Is super admin': 'Prawa super admina',
    'Title of article': 'Tytuł artykułu',
    'Text of article': 'Zawartość artykułu',
    'Show in main menu': 'Pokaż w menu głównym',
    Text: 'Tekst główny',
    'Meta title': '[SEO] Meta tytuł',
    'Meta description': '[SEO] Meta opis',
    'Meta keywords': '[SEO] Meta słowa kluczowe',
    Slug: 'Slug (generowany automatycznie z tytułu)',
    'Code of translator entry': 'Kod tłumaczenia',
    'Title of translator entry': 'Tłumaczenie',
    Question: 'Pytanie',
    Answer: 'Odpowiedź',
    Name: 'Nazwa',
    'Type of element': 'Typ elementu',
    'Is required': 'Wymagany do uzupełnienia',
    Description: 'Opis',
    Subtitle: 'Podtytuł',
    Quotation: 'Cytat',
    'Button title': 'Tytuł linka',
    'Button link': 'Link',
    'File package': 'Pakiet plików',
    'Gallery package': 'Pakiet galerii',
    'Faq package': 'Pakiet FAQ',
    'Form package': 'Pakiet formularza',
    Sections: 'Sekcje',
    'Text before media': 'Tekst przed zdjęciem',
    'Text after media': 'Tekst po zdjęciu',
    'Item type': 'Typ medium',
    'Media position': 'Pozycja zdjęcia',
    'Gallery type': 'Typ galerii',
    'Photo count': 'Zdjęć',
    'Video count': 'Filmów',
    'Media type': 'Typ medium',
    Photographer: 'Fotograf',
    /* Returned by API: general */
    'Your current password is incorrect.': 'Podałeś niepoprawne hasło',
    'Bad credentials.': 'Niepoprawne dane',
    Error: 'Błąd',
    TypeError: 'Błąd',
    'An error occurred': 'Błąd',
    'Not Found': 'Rekord nie odnaleziony',
    'Syntax error': 'Błąd składni. Skontaktuj się z administratorem',
    'This value is already used.': 'Ta wartość jest już używana',
    'This value should not be null.': 'Pole wymagane',
    Forbidden: 'Brak dostępu do tego zasobu',
    Unauthorized: 'Dostęp zablokowany',
    'Internal Server Error': 'Wystąpił błąd. Skontaktuj się z administratorem',
    'TypeError: Failed to fetch':
      'Błąd połączenia z serwerem. Skontaktuj się z administratorem',
    'Password is common.': 'Wybrałeś znane hasło',
    'You have already used that password.': 'Hasło zostało już wcześniej użyte',
    'Login blocked for 15 mins.': 'Logowanie zablokowane na 15 minut',
    'Circular parentage detected. Current entity or current entity descendants cannot be set as a parent to current entity.':
      'Niepoprawna zmiana rodzica. Nie można przenieść strony w dół aktualnego drzewa.',
    'Parent page is not subpageable.':
      'Niepoprawna zmiana rodzica. Rodzic nie może posiadać podstron.',
    'Nesting level exceeded.':
      'Niepoprawna zmiana rodzica. Osiągnięto maksymalny poziom zagnieżdżenia stron.',
    'Youtube thumbnail does not exist.': 'Zaślepka nie istnieje',
    'Admin does not exist.': 'Administrator nie istnieje.',
    'Reset password link has been already generated.':
      'Link do zresetowania hasła został już wygenerowany.',
    'Incorrect hash.': 'Niepoprawny link.',
    'Link expired.': 'Wygasły link.',
    'Reset password blocked for 15 mins.':
      'Resetowanie hasła zablokowane na 15 minut',
    T_GENERAL_CREATE_ACCOUNT: 'Utwórz konto',
    'User with this email doesnt exist!':
      'Użytkownik o tym adresie e-mail nie istnieje',
    'Bad resetting password token': 'Wygasł Twój link do odzyskania hasła.',
    T_GENERAL_ACCOUNT_ACTIVATION_TITLE: 'Aktywacja konta',
    T_GENERAL_ACCOUNT_ACTIVATION_DONE: 'Twoje konto zostało aktywowane',
    T_GENERAL_ACCOUNT_ACTIVATION_ERROR:
      'Błąd aktywacji konta. Prawdopodobnie Twój link wygasł.',
    'Bad activation token':
      'Błąd aktywacji konta. Prawdopodobnie Twój link wygasł.',
    T_GENERAL_ALL_YEARS: 'Wszystkie lata',
    T_GENERAL_SEND_FILES: 'Prześlij pliki',
    T_BUTTON_GO_TO_APPLICATION: 'przejdź do wniosku',
    T_MODULE_CONTESTS: 'Konkursy',
    T_MODULE_CONTESTS_TYPE_OPEN: 'Otwarty',
    T_MODULE_CONTESTS_TYPE_CLOSED: 'Zamknięty',
    T_MODULE_CONTESTS_PROPOSAL_COLLECTING_DATE: 'termin składania wniosków',
    T_MODULE_CONTESTS_PROJECT_REALIZATION_DATE: 'czas trwania projektu',
    T_MODULE_CONTESTS_SCOPE_NATIONAL: 'Krajowy',
    T_MODULE_CONTESTS_SCOPE_LOCAL: 'Regionalny',
    T_MODULE_CONTESTS_PUBLICATION_STATUS_CURRENT: 'Trwające',
    T_MODULE_CONTESTS_PUBLICATION_STATUS_ANNOUNCED: 'Zapowiadane',
    T_MODULE_CONTESTS_PUBLICATION_STATUS_FINISHED: 'Zakończone',
    T_MODULE_CONTESTS_PROJECT_REALIZATION_START_DATE: 'Data rozpoczęcia',
    T_MODULE_CONTESTS_PROJECT_REALIZATION_END_DATE: 'Data zakończenia',
    T_MODULE_CONTESTS_PROPOSAL_COLLECTING_END_DATE: 'Termin naboru wniosków',
    T_MODULE_CONTESTS_PUBLICATION_DATE: 'Ogłoszenie konkursu',
    T_MODULE_CONTESTS_APPLY_FOR_THE_AMOUNT_START: 'Aplikuj o dotację do',
    T_MODULE_CONTESTS_APPLY_FOR_THE_AMOUNT_END: 'PLN!',
    T_MODULE_CONTESTS_NEWEST: 'Najnowsze konkursy',
    T_MODULE_CONTESTS_SEE_ALL: 'zobacz wszystkie',
    T_MODULE_CONTESTS_FILL: 'Wypełnij wniosek',
    T_MODULE_CONTEST: 'Konkurs',
    T_MODULE_PROPOSALS: 'Moje projekty',
    T_MODULE_PROPOSALS_APPLICATION_STATUS_APPLIED: 'Złożone',
    T_MODULE_PROPOSALS_APPLICATION_STATUS_INPROGRESS: 'W realizacji',
    T_MODULE_PROPOSALS_APPLICATION_STATUS_DRAFT: 'W edycji',
    T_MODULE_PROPOSALS_APPLICATION_STATUS_FINISHED: 'Zakończone',
    T_MODULE_PROPOSAL_APPOINTMENT_STATUS_PENDING: 'Zapowiedziana',
    T_MODULE_PROPOSAL_APPOINTMENT_STATUS_FINISHED: 'Wykonana',
    T_MODULE_PROPOSAL_APPOINTMENT_STATUS_ACCEPTED: 'Potwierdzona',
    T_MODULE_PROPOSALS_STEP_APPLICANT: 'Wnioskodawca',
    T_MODULE_PROPOSALS_STEP_PROJECT: 'Projekt',
    T_MODULE_PROPOSALS_STEP_BUDGET: 'Budżet',
    T_MODULE_PROPOSALS_STEP_ATTACHMENTS: 'Załączniki',
    T_MODULE_PROPOSALS_EDIT: 'Edycja wniosku',
    T_MODULE_PROPOSALS_SAVE_SKETCH: 'Zapisz szkic',
    T_MODULE_PROPOSALS_DELETE_SKETCH: 'Usuń szkic',
    T_MODULE_PROPOSALS_ADD_COORDINATOR: 'Dodaj koordynatora',
    T_MODULE_PROPOSALS_COORDINATOR_FULL_NAME: 'Imię i nazwisko',
    T_MODULE_PROPOSALS_COORDINATOR_ROLE: 'Funkcja',
    T_MODULE_PROPOSALS_COORDINATOR_EMAIL:
      'Adres mailowy do kontaktu w sprawie wniosku lub dotacji',
    T_MODULE_PROPOSALS_COORDINATOR_TEL:
      'Numer telefonu do kontaktu w sprawie wniosku lub dotacji',
    T_MODULE_PROPOSALS_CHOICES_CHECKBOX_OTHER: 'Inne',
    T_MODULE_PROPOSALS_REALIZATION_DATE_TIME_RANGE: 'zakres realizacji',
    T_MODULE_PROPOSALS_REALIZATION_DATE_TIME_FROM: 'od',
    T_MODULE_PROPOSALS_REALIZATION_DATE_TIME_TO: 'do',
    T_MODULE_PROPOSALS_PARTICIPANTS_COUNT_MIN: 'minimalna ilość',
    T_MODULE_PROPOSALS_PARTICIPANTS_COUNT_MAX: 'do',
    T_MODULE_PROPOSALS_PARTICIPANTS_COUNT_NO_LIMIT: 'bez ograniczeń',
    T_MODULE_PROPOSALS_ADD_BUDGET: 'Dodaj koszty',
    T_MODULE_PROPOSALS_BUDGET_COSTS: 'Koszta',
    T_MODULE_PROPOSALS_BUDGET_TITLE: 'Rodzaj kosztów',
    T_MODULE_PROPOSALS_BUDGET_CALCULATION_METHOD: 'Sposób kalkulacji',
    T_MODULE_PROPOSALS_BUDGET_OWN_CONTRIBUTION_AMOUNT:
      'Wkład własny oraz z innych źródeł',
    T_MODULE_PROPOSALS_BUDGET_SUBSIDY_AMOUNT: 'Wnioskowane od FCP',
    T_MODULE_PROPOSALS_BUDGET_SUM: 'Razem',
    T_MODULE_PROPOSALS_BUDGET_ADD_NEW: 'Dodaj nową kategorię',
    T_MODULE_PROPOSALS_BUDGET_TOTAL_PROJECT_COST: 'Całkowity koszt projektu',
    T_MODULE_PROPOSALS_MAX_DOTATION_AMOUNT: 'Maksymalna kwota dotacji',
    T_MODULE_PROPOSALS_MIN_OWN_CONTRIBUTION_AMOUNT: 'Minimalny wkład własny',
    T_MODULE_PROPOSALS_LEFT_TO_DOTATE: 'Pozostało do zadotowania',
    T_MODULE_PROPOSALS_LEFT_REQUIRED_OWN_CONTRIBUTION_AMOUNT:
      'Pozostało wymaganego wkładu własnego',
    T_MODULE_PROPOSALS_BUDGET_PLN: 'zł',
    T_MODULE_PROPOSALS_ADD_ATTACHMENT: 'Dodaj załącznik',
    T_MODULE_PROPOSALS_ATTACHMENT_TITLE: 'Nazwa załącznika',
    T_MODULE_PROPOSALS_APPLY: 'Złóż wniosek',
    T_MODULE_PROPOSALS_CURRENT_BUDGET: 'Aktualny budżet',
    T_MODULE_PROPOSALS_STATEMENT_BUDGET: 'Zestawienie wydatków',
    T_MODULE_PROPOSALS_CHANGES_BUDGET: 'Zgłaszanie zmian',
    T_MODULE_PROPOSALS_REPORT_BUDGET: 'Złóż raport finansowy',
    T_MODULE_PROPOSALS_DECLARATION_BUDGET: 'Deklaracja kwartalnych wydatków',
    T_MODULE_PROPOSALS_SCHEDULE_LEAD_TIME: 'Czas realizacji',
    T_MODULE_PROPOSALS_SCHEDULE_DETAILS: 'Harmonogram szczegółowy',
    T_MODULE_PROPOSALS_SCHEDULE_DETAILS_DATE_OF_SUBMISSION:
      'Data złożenia raportu końcowego',
    T_MODULE_PROPOSALS_DATES_AND_STATUS: 'Daty i status',
    T_MODULE_PROPOSALS_COLLECTING_END_DATE: 'Nabór wniosków',
    T_MODULE_PROPOSALS_COLLECTED_AT: 'Wniosek złożony',
    T_MODULE_PROPOSALS_FORMAL_RATING: 'Ocena formalna',
    T_MODULE_PROPOSALS_EXPERTS_RATING: 'Ocena merytoryczna',
    T_MODULE_PROPOSALS_CONTRACT: 'Umowa',
    T_MODULE_PROPOSALS_CHANGE_APPLIED_PROPOSAL: 'Zmień złożony wniosek',
    T_MODULE_PROPOSALS_CONTRACT_STEP_FILL: 'Uzupełnij formularz',
    T_MODULE_PROPOSALS_CONTRACT_STEP_CONFIRM:
      'Sprawdź dane i pobierz pdf umowy',
    T_MODULE_PROPOSALS_CONTRACT_STEP_UPLOAD: 'Wgraj skan umowy',
    T_MODULE_PROPOSALS_MANUAL_TITLE: 'Instrukcja do umowy',
    T_MODULE_PROPOSALS_MANUAL_LINE_1: 'Wypełnij poniższy formularz',
    T_MODULE_PROPOSALS_MANUAL_LINE_2: 'Sprawdź poprawność danych w umowie',
    T_MODULE_PROPOSALS_MANUAL_LINE_3: 'Pobierz PDF',
    T_MODULE_PROPOSALS_MANUAL_LINE_4: 'Wydrukuj i podpisz 2 egzemplarze',
    T_MODULE_PROPOSALS_MANUAL_LINE_5: 'Wgraj skan podpisanej umowy',
    T_MODULE_PROPOSALS_MANUAL_LINE_6: 'Wyślij pocztą 2 podpisane egzemplarze',
    T_MODULE_PROPOSALS_MANUAL_ADDRESS_LINE_1: 'Fundacja Civis Polonus',
    T_MODULE_PROPOSALS_MANUAL_ADDRESS_LINE_2: 'ul. Bagatela 10 lok. 34a i 47',
    T_MODULE_PROPOSALS_MANUAL_ADDRESS_LINE_3: '00-585 Warszawa',
    T_MODULE_PROPOSALS_CONTRACT_REPRESENTATIVE_NAME: 'Imię',
    T_MODULE_PROPOSALS_CONTRACT_REPRESENTATIVE_SURNAME: 'Nazwisko',
    T_MODULE_PROPOSALS_CONTRACT_REPRESENTATIVE_ROLE: 'Obejmowana funkcja',
    T_MODULE_PROPOSALS_CONTRACT_REPRESENTATIVES: 'Dane osób reprezentujących',
    T_MODULE_PROPOSALS_CONTRACT_SUBACCOUNT: 'Subkonto organizacji',
    T_MODULE_PROPOSALS_CONTRACT_ORGANIZATION_DATA: 'Dane organizacji',
    T_MODULE_PROPOSALS_CONTRACT_ORGANIZATION_ADDRESS:
      'Adres reprezentowanej organizacji',
    T_MODULE_PROPOSALS_CONTRACT_ATTACHMENTS_INFO:
      'Wgraj podpisany skan umowy i potrzebne załączniki',
    T_MODULE_PROPOSALS_ADD_CONTRACT_REPRESENTATIVE: 'Dodaj kolejną osobę',
    T_MODULE_PROPOSALS_ADD_CONTRACT_ATTACHMENT: 'Dodaj kolejny załącznik',
    T_MODULE_PROPOSALS_CONTRACT_SUBACCOUNT_NUMBER: 'Numer subkonta',
    T_MODULE_PROPOSALS_CONTRACT_DATA_ACCEPT: 'Potwierdzam poprawność danych',
    T_MODULE_PROPOSALS_CONTRACT_DOWNLOAD_PDF: 'Pobierz umowę jako pdf',
    T_MODULE_PROPOSALS_BLOCK_ADDRESS_POST_OFFICE: 'Poczta',
    T_MODULE_PROPOSALS_BLOCK_ADDRESS_POST_CODE: 'Kod',
    T_MODULE_PROPOSALS_BLOCK_ADDRESS_ADDRESS: 'Adres',
    T_MODULE_PROPOSALS_BLOCK_ADDRESS_CITY: 'Miejscowość',
    T_MODULE_PROPOSALS_BLOCK_ADDRESS_COMMUNE: 'Gmina',
    T_MODULE_PROPOSALS_BLOCK_ADDRESS_DISTRICT: 'Powiat',
    T_MODULE_PROPOSALS_BLOCK_ADDRESS_PROVINCE: 'Województwo',
    T_MODULE_PROPOSALS_PROJECT_TITLE: 'Nazwa projektu',
    T_MODULE_PROPOSALS_CHARS_LEFT: 'Pozostało znaków',
    T_MODULE_PROPOSALS_VALIDATE: 'Sprawdź formularz',
    T_MODULE_PROPOSALS_REALIZATION_DATE_TIME: 'Czas realizacji projektu',
    T_MODULE_PROPOSALS_PARTICIPANTS_COUNT: 'Liczba uczestników projektu',
    T_MODULE_PROPOSALS_SAVE_AND_APPLY: 'Zapisz i wyślij do weryfikacji',
    T_MODULE_PROPOSAL_RELATIONS_STATUS_DRAFT: 'Wersja robocza',
    T_MODULE_PROPOSAL_RELATIONS_STATUS_PENDING: 'Trwa weryfikacja',
    T_MODULE_PROPOSAL_RELATIONS_STATUS_ACCEPTED: 'Zaakceptowana',
    T_MODULE_PROPOSAL_RELATIONS_STATUS_REJECTED: 'Odrzucona',
    T_MODULE_PROPOSAL_RELATIONS_STATUS_RETURNED: 'Zwrócona',
    T_MODULE_PROPOSALS_RELATION_ATTACHMENTS_INFO: 'Dodaj zdjęcia',
    T_MODULE_PROPOSALS_ADD_RELATION_ATTACHMENT: 'Dodaj kolejny załącznik',
    T_MODULE_PROPOSALS_RELATION_ATTACHMENT_TITLE: 'Nazwa wgranego pliku',
    T_MODULE_PROPOSALS_RELATION_ATTACHMENT_AUTHOR: 'Autor',
    T_MODULE_PROPOSAL_CONTRACT_STATUS_APPLIED: 'Wysłana',
    T_MODULE_PROPOSAL_CONTRACT_STATUS_TO_CORRECT: 'Odrzucona',
    T_MODULE_PROPOSAL_CONTRACT_STATUS_CONFIRMED: 'Zaakceptowana',
    T_MODULE_PROPOSAL_CONTRACT_STATUS_WAITING: 'Oczekująca',
    T_MODULE_PROPOSAL_QUARTERLY_DECLARATIONS_STATUS_NOTREPORTED: 'Niezłożona',
    T_MODULE_PROPOSAL_QUARTERLY_DECLARATIONS_STATUS_REPORTED: 'Złożona',
    T_MODULE_PROPOSAL_QUARTERLY_DECLARATIONS_STATUS_ACCEPTED: 'Zaakceptowana',
    T_MODULE_PROPOSAL_CONTENT_REPORT_STATUS_DRAFT: 'Szkic',
    T_MODULE_PROPOSAL_CONTENT_REPORT_STATUS_PENDING: 'Trwa weryfikacja',
    T_MODULE_PROPOSAL_CONTENT_REPORT_STATUS_RETURNED: 'Zwrócony',
    T_MODULE_PROPOSAL_CONTENT_REPORT_STATUS_REJECTED: 'Odrzucony',
    T_MODULE_PROPOSAL_CONTENT_REPORT_STATUS_ACCEPTED: 'Zaakceptowany',
    T_MODULE_FAQ: 'FAQ',
    T_MODULE_CHANGE_PASSWORD: 'Zmień hasło',
    T_MODULE_ORGANIZATION_DATA: 'Edycja danych organizacji',
    T_MODULE_ORGANIZATION_DATA_GOALS_INFO: 'Informacje o celach organizacji',
    T_MODULE_ORGANIZATION_DATA_ORGANIZATION_GOALS: 'Cele organizacji',
    T_MODULE_ORGANIZATION_DATA_STATUTORY_ACTIVITIES:
      'Formy działań statutowych',
    T_MODULE_ORGANIZATION_DATA_ADDITIONAL_INFORMATIONS: 'Dodatkowe informacje',
    T_MODULE_ORGANIZATION_DATA_POPULATION:
      'Liczba mieszkańców miejscowości, gdzie siedzibę ma organizacja/instytucja',
    T_MODULE_ORGANIZATION_DATA_POPULATION_DATA_SOURCE:
      'Źródło danych o liczbie mieszkańców',
    T_MODULE_ORGANIZATION_DATA_WEBSITE:
      'Strona internetowa organizacji/instytucji',
    T_MODULE_ORGANIZATION_DATA_ORGANIZATION_CREATION_DATE:
      'Rok powstania organizacji/instytucji',
    T_MODULE_ORGANIZATION_DATA_ORGANIZATION_ADDRESS:
      'Adres reprezentowanej organizacji',
    T_MODULE_ORGANIZATION_DATA_POST_OFFICE: 'Poczta',
    T_MODULE_ORGANIZATION_DATA_POST_CODE: 'Kod',
    T_MODULE_ORGANIZATION_DATA_ADDRESS: 'Ulica i numer domu',
    T_MODULE_ORGANIZATION_DATA_CITY: 'Miejscowość',
    T_MODULE_ORGANIZATION_DATA_COMMUNE: 'Gmina',
    T_MODULE_ORGANIZATION_DATA_DISTRICT: 'Powiat',
    T_MODULE_ORGANIZATION_DATA_PROVINCE: 'Województwo',
    T_MODULE_ORGANIZATION_DATA_BASIC_INFORMATIONS:
      'Podstawowe informacje o organizacji',
    T_MODULE_ORGANIZATION_DATA_ORGANIZATION_NAME: 'Pełna nazwa organizacji',
    T_MODULE_ORGANIZATION_DATA_ORGANIZATION_TYPE: 'Typ organizacji',
    T_MODULE_ORGANIZATION_DATA_NIP: 'NIP',
    T_MODULE_ORGANIZATION_DATA_REGON: 'Regon',
    T_MODULE_ORGANIZATION_DATA_KRS: 'KRS',
    T_MODULE_ORGANIZATION_DATA_OTHER: 'Nie dotyczy - grupa nieformalna',
    T_ORAGNIZATION_EMAIL:
      'e-mail (proszę podać oficjalny adres mailowy organizacji, a nie prywatny)',
    T_FORM_FIELD_PASS: 'Hasło',
    T_FORM_FIELD_PASS_REPEAT: 'Powtórz hasło',
    T_FORM_FIELD_ORAGNIZATION_NAME: 'Pełna nazwa organizacji:',
    T_FORM_FIELD_NIP: 'NIP',
    T_FORM_FIELD_REGON: 'REGON',
    T_FORM_FIELD_KRS: 'KRS',
    T_FORM_FIELD_POST_OFFICE: 'Poczta',
    T_FORM_FIELD_POST_CODE: 'Kod',
    T_FORM_FIELD_ADDRESS: 'Ulica, nr domu',
    T_FORM_FIELD_CITY: 'Miejscowość',
    T_FORM_FIELD_COMMUNE: 'Gmina',
    T_FORM_FIELD_DISTRICT: 'Powiat',
    T_FORM_FIELD_PROVICNES: 'Województwo',
    T_FORM_FIELD_ORAGNIZATION_PHONE: 'Telefon kontaktowy do organizacji',
    T_FORM_FIELD_ORAGNIZATION_TITLE: 'Dane reprezentowanej organizacji',
    T_SELECT: 'Wybierz',
    T_KRS_NOT_APPLY: 'Nie dotyczy - grupa nieformalna',
    T_KRSTYPE_nip: 'NIP',
    T_KRSTYPE_regon: 'REGON',
    T_KRSTYPE_krs: 'KRS',
    T_FORM_FIELD_ORAGNIZATION_ADDRESS: 'Adres reprezentowanej organizacji',
    T_CREATE_ACCOUNT: 'Utwórz konto',
    T_CONTEST_EDITION_URL: 'zarządzaj edycjami',
    T_MANAGE_EDITIONS: 'Zarządzaj edycjami',
    T_EDITION_NAME: 'Nazwa edycji',
    T_EDITION_YEAR: 'Rok',
    T_EDITION_CONTEST: 'Konkursy',
    T_NEW_EDITION: 'Dodaj edycję',
    T_scope_LOCAL: 'Regionalny',
    T_scope_NATIONAL: 'Ogólnopolski',
    T_type_OPEN: 'Otwarty',
    T_type_CLOSED: 'Zamknięty',
    T_CONTEST_TAB_CHANGE:
      'Wprowadziłeś zmiany w formularzu. Aby nie utracić zmian wprowadzonych w tej zakładce należy zapisać formularz przed przejściem do kolejnej zakładki.',
    T_GENERAL_REGISTER_FINISHED:
      'Dziękujemy za rejestrację. W celu zakończenia procesu rejestracji kliknij link w wiadomości, którą wyśłaliśmy na podany adres e-mail.',
    T_publicationStatus_CURRENT: 'Trwający',
    T_publicationStatus_FINISHED: 'Zakończony',
    T_publicationStatus_DRAFT: 'Szkic',
    T_publicationStatus_ANNOUNCED: 'Zapowiadany',
    APPLICATION_APPLICANT: 'Wnioskodawca',
    APPLICATION_PROJECT: 'Projekt',
    TRAINING_INQUIRY: 'Ankieta szkolenia',
    APPOINTMENT: 'Wizyta',
    AGREEMENT_TEMPLATE: 'Umowa',
    RELATION: 'Relacja',
    PARTIAL_REPORT: 'Raport częściowy',
    FINAL_REPORT: 'Raport końcowy',
    ROLE_FOUNDATION: 'admin',
    ROLE_ADMIN: 'superadmin',
    ROLE_GUEST: 'gość',
    T_RATE_FORMAL_PASSED: 'Pozytywna',
    T_RATE_FORMAL_FAILED: 'Negatywna',
    T_EVENT_RATE_FORMAL_STATUS_PASSED: 'Pozytywna',
    T_EVENT_RATE_FORMAL_STATUS_FAILED: 'Negatywna',
    T_ACCEPTATION_STATUS_SUBSIDIZED: 'Dotowane',
    T_ACCEPTATION_STATUS_RESERVE: 'Rezerwa',
    T_ACCEPTATION_STATUS_REJECTED: 'Odrzucone',
    T_RECOMMENDATION_STATUS_YES: 'Rekomendowane',
    T_RECOMMENDATION_STATUS_NO: 'Nie rekomendowane',
    T_RATE_STATUS_CONFIRMED: 'Zatwierdzone',
    T_RATE_STATUS_NOT_RATED: 'Do oceny',
    T_RATE_STATUS_NOT_CONFIRMED: 'Ocenione, nie zatwierdzone',
    T_BUDGET_CHANGE_STATUS_NEW: 'Zgłoszona',
    T_BUDGET_CHANGE_STATUS_PENDING: 'Do wyjaśnienia',
    T_BUDGET_CHANGE_STATUS_ANSWERED: 'Wyjaśnione',
    T_BUDGET_CHANGE_STATUS_REJECTED: 'Odrzucona',
    T_BUDGET_CHANGE_STATUS_ACCEPTED: 'Zaakceptowana',
    T_SCHEDULE_CHANGE_STATUS_NEW: 'Zgłoszona',
    T_SCHEDULE_CHANGE_STATUS_PENDING: 'Do wyjaśnienia',
    T_SCHEDULE_CHANGE_STATUS_ANSWERED: 'Wyjaśnione',
    T_SCHEDULE_CHANGE_STATUS_REJECTED: 'Odrzucona',
    T_SCHEDULE_CHANGE_STATUS_ACCEPTED: 'Zaakceptowana',
    T_SCHEDULE_CHANGE_STATUS_AUTO_DRAFT: 'Szkic',
    T_SCHEDULE_WAIT_FOR_USER:
      'Szkic automatyczny wygenerowany po zgłoszeniu zmiany czasu realizacji. Wnioskodawca może jescze wysłać zmiany.',
    T_REALIZATION_DATE_CHANGE_STATUS_PENDING: 'Zgłoszona',
    T_REALIZATION_DATE_CHANGE_STATUS_VERIFIED: 'Do wyjaśnienia',
    T_REALIZATION_DATE_CHANGE_STATUS_ANSWERED: 'Wyjaśnione',
    T_REALIZATION_DATE_CHANGE_STATUS_REJECTED: 'Odrzucona',
    T_REALIZATION_DATE_CHANGE_STATUS_ACCEPTED: 'Zaakceptowana',
    T_PROPOSAL_TRAINING_RESULT_PASSED: 'Zaliczone',
    T_PROPOSAL_TRAINING_RESULT_FAILED: 'Niezaliczone',
    T_REPORT_END_CHANGE_STATUS_PENDING: 'Zgłoszona',
    T_REPORT_END_CHANGE_STATUS_REJECTED: 'Odrzucona',
    T_REPORT_END_CHANGE_STATUS_ACCEPTED: 'Zaakceptowana',
    T_MODULE_PROJECT_END_STATUS_REJECTED: ' Rezygnacja',
    T_MODULE_PROJECT_END_STATUS_CANCELED: 'Zerwanie umowy',
    T_MODULE_PROJECT_END_STATUS_FINISHED: 'Zrealizowano',
    T_MODULE_FINANCIAL_REPORT_STATUS_REJECTED: ' Odrzucony',
    T_MODULE_FINANCIAL_REPORT_STATUS_NEW: 'Nowy',
    T_MODULE_FINANCIAL_REPORT_STATUS_ACCEPTED: 'Zaakceptowany',
    T_VALIDATION_BANCACCOUNT:
      'Numer konta musi mieć długość 26 znaków i składać się z samych cyfr',
    DRAFT: 'Szkic',
    T_internalRateStatus_PASSED: 'Oceniona',
    T_substantiveRateStatus_PASSED: 'Oceniona',
    T_internalRateStatus_FAILED: 'Nieocenione',
    T_substantiveRateStatus_FAILED: 'Nieocenione',
    T_realizationStatus_FINISHED: 'Zakończony',
    T_realizationStatus_INPROGRESS: 'W realizacji',
    T_realizationStatus_REJECTED: 'Odrzucony',
    T_realizationStatus_CANCELED: 'Anulowany',
    T_SUCCESS_BUDGET_NEW_CATEGORY: 'Dodano nową kategorię do budżetu.',
    T_ERROR_BUDGET_NEW_CATEGORY:
      'Nie możesz dodać nowej kategorii do tego budżetu.',
  },
}
