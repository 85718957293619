import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { formatBytes } from '_helpers/formatBytes'
import { translate } from 'core/_helpers/translate'

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: 16,
    marginBottom: 20
  },
  label: {
    fontWeight: 'bold'
  },
  label_wrapper: {
    marginBottom: 10
  },
  file_wrapper: {
    '&> *': {
      marginBottom: 5
    }
  }
}))

export const AttachmentItem = ({ title, file }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.label_wrapper}>
        <span className={classes.label}>
          {translate('T_MODULE_PROPOSALS_ATTACHMENT_TITLE')}:{' '}
        </span>
        {title}
      </div>
      <div className={classes.file_wrapper}>
        <div>
          {file?.originalName}
        </div>
        <div>
          {formatBytes(file?.fileSize)}
        </div>
        <a href={`${process.env.REACT_APP_API_ENTRYPOINT}/${file?.url}`} target="_blank" rel="noopener noreferrer">
          {file?.originalName}
        </a>
      </div>
    </div>
  )
}

AttachmentItem.propTypes = {
  title: PropTypes.string.isRequired,
  file: PropTypes.shape({
    originalName: PropTypes.string.isRequired,
    fileSize: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
}
