import PropTypes from 'prop-types'

export const propTypes = {
  url: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  properties: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
        .isRequired,
    })
  ).isRequired,
  resource: PropTypes.object,
  handleSubmit: PropTypes.func,
  handleSuccess: PropTypes.func,
  handleCancel: PropTypes.func,
  onlyPassedProperties: PropTypes.bool,
  buttonContainerRef: PropTypes.object,
  submitButtonTitle: PropTypes.string,
  submitButtonVariant: PropTypes.oneOf(['contained', 'outlined', 'inherit']),
  submitButtonSize: PropTypes.oneOf(['large', 'small']),
  additionalButtons: PropTypes.node,
  children: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    root: PropTypes.string,
    buttons: PropTypes.string,
    submit: PropTypes.string,
    loader: PropTypes.string,
  }),
  getKrsFromApi: PropTypes.bool,
}
