import { makeStyles } from '@material-ui/styles'

export const useBudgetCollectionTypeStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.primary.main,
    marginBottom: 50,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 26,
  },
  subtitle: {
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 16,
    marginTop: 26,
  },
  description: {
    marginBottom: 26,
  },
  header: {
    display: 'flex',
  },
  header_number: {
    width: 15,
    marginRight: 20,
  },
  header_title: {
    width: 170,
    marginRight: 20,
  },
  header_calculation_method: {
    width: 170,
    marginRight: 20,
  },
  header_own_contribution_amout: {
    width: 225,
    marginRight: 20,
  },
  header_subsidy_amount: {
    width: 160,
    marginRight: 20,
  },
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
    borderTop: '1px solid #ccc',
    paddingTop: 20,
  },
  container: {
    display: 'flex',
  },
  container_inner: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  sidebar: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: 0,
    '&> *': {
      cursor: 'pointer',
    },
  },
  outer_summary_root: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  outer_summary_row: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  error: {
    color: theme.palette.error.main,
  },
}))
