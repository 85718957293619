import { COLORS } from '../colors'

export default {
  root: {
    borderRadius: 0,
    '&.Mui-disabled': {
      borderColor: COLORS.DISABLED,
    },
  },
  sizeLarge: {
    padding: '10px 50px',
  },
  contained: {
    boxShadow: 'none',
    backgroundColor: COLORS.WHITE,
    textTransform: 'none',
    fontSize: '18px',
    fontWeight: '700',
    letterSpacing: '0.04em',
  },
  outlined: {
    textTransform: 'none',
    fontSize: '18px',
    fontWeight: '700',
    letterSpacing: '0.04em',
    borderWidth: 2,
  },
  outlinedSecondary: {
    border: `2px solid ${COLORS.SECONDARY}`,
    '&:hover': {
      border: `2px solid ${COLORS.PRIMARY}`,
      color: COLORS.PRIMARY,
      boxShadow: '0px 0px 24px rgba(11, 43, 124, 0.4)',
    },
  },
  containedSecondary: {
    border: `2px solid ${COLORS.SECONDARY}`,
    '&:hover': {
      border: `2px solid ${COLORS.PRIMARY}`,
      backgroundColor: COLORS.PRIMARY,
      boxShadow: ' 0px 0px 24px rgba(11, 43, 124, 0.4)',
    },
    '&.Mui-disabled': {
      backgroundColor: COLORS.DISABLED,
      color: COLORS.WHITE,
    },
  },
  label: {
    // color: COLORS.SECONDARY
  },
  text: {
    textTransform: 'none',
  },
}
