import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { StringType } from 'components/applicant/form/fields/StringType'
import { DateType } from 'components/applicant/form/fields/DateType'
import { FileType } from 'core/components/form/fields/file/FileType'
import { isBudgetCollectionInvalid } from 'pages/applicant/Proposals/_helpers/isBudgetCollectionInvalid'
import { useBudgetItemTypeStyles } from './BudgetItemType.styles'
import { translate } from 'core/_helpers/translate'
import { useProject } from '_helpers/useProject'
import { notification } from 'core/_helpers/notification'

export const BudgetItemType = ({
  index,
  values,
  errors,
  renderError,
  globalRenderError,
  setState,
  disabled,
  state,
}) => {
  const projekt = useProject()
  const setValue = useCallback(
    (name, value) => {
      setState(state => ({
        ...state,
        values: state.values.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: value,
              }
            : item
        ),
        renderError: state.renderError.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: true,
              }
            : item
        ),
        init: false,
      }))
    },
    [index, setState]
  )

  const setError = useCallback(
    (name, error) => {
      setState(state => ({
        ...state,
        errors: state.errors.map((item, i) =>
          i === index
            ? {
                ...item,
                [name]: error,
              }
            : item
        ),
        renderError: state.isFormSubmitted
          ? state.renderError.map((item, i) =>
              i === index
                ? {
                    ...item,
                    [name]: true,
                  }
                : item
            )
          : state.renderError,
        isInvalid: isBudgetCollectionInvalid(
          state.errors.map((item, i) =>
            i === index
              ? {
                  ...item,
                  [name]: !!error,
                }
              : item
          )
        ),
      }))
    },
    [index, setState]
  )

  const validators = useMemo(
    () => ({
      documentNumber: ['required'],
      documentDate: ['required'],
      documentCreator: ['required'],
      nettoAmount: ['required', 'minValue(0)'],
      bruttoAmount: ['required', 'minValue(0)'],
      subsidyAmount: [
        'required',
        'minValue(0)',
        `maxValue(${values?.bruttoAmount || 0})`,
      ],
      file: [],
    }),
    [values.bruttoAmount]
  )

  const classes = useBudgetItemTypeStyles()

  const customUploadSuccess = () => {
    notification(
      'warning',
      'Pamiętaj, aby zapisać zestawienie budżetu za pomocą przycisku "Zapisz"',
      'Plik wybrany'
    )
  }

  return (
    <div className={classes.root}>
      <div className={classes.number}>{index + 1}</div>
      <div>
        <StringType
          type="string"
          name="documentNumber"
          value={values?.documentNumber}
          error={errors?.documentNumber}
          renderError={globalRenderError && renderError?.documentNumber}
          disabled={disabled}
          validators={validators.documentNumber}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input,
          }}
        />
      </div>

      <div>
        <DateType
          name="documentDate"
          value={values?.documentDate}
          error={errors?.documentDate}
          renderError={globalRenderError && renderError?.documentDate}
          disabled={disabled}
          validators={validators.documentDate}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input,
          }}
        />
      </div>
      <div>
        <StringType
          type="string"
          name="documentCreator"
          value={values?.documentCreator}
          error={errors?.documentCreator}
          renderError={globalRenderError && renderError?.documentCreator}
          disabled={disabled}
          validators={validators.documentCreator}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input,
          }}
        />
      </div>
      <div>
        <StringType
          type="number"
          name="nettoAmount"
          value={values?.nettoAmount}
          error={errors?.nettoAmount}
          renderError={globalRenderError && renderError?.nettoAmount}
          validators={validators.nettoAmount}
          disabled={disabled}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input,
          }}
        />
      </div>
      <div>
        <StringType
          type="number"
          name="bruttoAmount"
          value={values?.bruttoAmount}
          error={errors?.bruttoAmount}
          renderError={globalRenderError && renderError?.bruttoAmount}
          validators={validators.bruttoAmount}
          disabled={disabled}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input,
          }}
        />
      </div>
      <div>
        <StringType
          type="number"
          name="subsidyAmount"
          value={values?.subsidyAmount}
          error={errors?.subsidyAmount}
          renderError={globalRenderError && renderError?.subsidyAmount}
          validators={validators.subsidyAmount}
          disabled={disabled}
          setValue={setValue}
          setError={setError}
          classes={{
            root: classes.input,
          }}
        />
      </div>
      <div className={classes.file}>
        <FileType
          type="file"
          name="file"
          value={values?.file}
          initialValue={values?.file}
          error={errors?.file}
          renderError={globalRenderError && renderError?.file}
          validators={validators.file}
          setValue={setValue}
          setError={setError}
          disabled={disabled}
          endpoint="/files"
          formMethod="POST"
          label={translate('T_GENERAL_FILE_ADD')}
          formUrl={null}
          hideHeader={true}
          customUploadSuccess={customUploadSuccess}
        />
      </div>
    </div>
  )
}

BudgetItemType.propTypes = {
  index: PropTypes.number.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  renderError: PropTypes.object,
  globalRenderError: PropTypes.bool.isRequired,
  setState: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
