import React, { useCallback, useRef, useState } from 'react'
import { Grid, IconButton, Typography, Button } from '@material-ui/core'
import { ButtonDownload } from 'core/components/buttons/button-download'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import { notification } from 'core/_helpers/notification'
import { downloadFile } from '_helpers/downloadFile'
import { makeStyles } from '@material-ui/styles'
import TinySlider from 'tiny-slider-react'
import { ReactComponent as PrevIcon } from 'theme/icons/left.svg'
import { ReactComponent as NextIcon } from 'theme/icons/right.svg'
import { nl2br } from '_helpers/nl2br'
import schema, {
  STATUS_RETURNED,
  STATUS_REJECTED,
  STATUS_ACCEPTED,
} from '_schema/proposalRelation'
import { translate } from 'core/_helpers/translate'
import { CustomDialog } from 'core/components/Dialog'
import { StringType } from 'core/components/form/fields/StringType'
import Moment from 'react-moment'
import clsx from 'clsx'

const useStyle = makeStyles(theme => ({
  margin: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  img: {
    width: '100%',
    height: '180px',
    objectFit: 'cover',
    objectPosition: 'center',
    maxWidth: '305px',
  },
  publication_accepted: {
    marginBottom: 20,
  },
  buttonIcon: {
    '& svg path': {
      fill: theme.palette.primary.main,
    },
    '&:hover': {
      '& svg path': {
        fill: theme.palette.secondary.main,
      },
    },
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    '& > *': {
      marginLeft: theme.spacing(3),
    },
  },
}))

export const Relation = ({ eventObject, setEvent }) => {
  const classes = useStyle()
  const settingsTinySlider = {
    lazyload: true,
    nav: false,
    mouseDrag: true,
    items: 2,
    controls: false,
    gutter: 20,
    loop: false,
  }
  const ref = useRef(null)

  const [state, setState] = useState(false)
  const handleChagneState = useCallback(() => {
    setState(prevState => !prevState)
  }, [])

  const onArrowSliderClick = direction => {
    ref.current.slider.goTo(direction)
  }

  const { relation } = eventObject

  const handleDownload = () => {
    downloadFile(
      schema.endpoint_download_attachments.replace(':id', relation.uuid),
      `relacja-${relation.uuid}-zdjecia.zip`
    )
  }

  const [returnExplanation, setReturnExplanation] = useState(
    relation.returnExplanation || ''
  )

  const handleReturnExplanation = (name, value) => {
    setReturnExplanation(value)
  }

  const [isFetching, setIsFetching] = useState(false)

  const handleStatusChange = status => () => {
    setIsFetching(true)

    fetchDataHandleAuthError(
      relation['@id'],
      'PUT',
      {
        body: JSON.stringify({
          status: status,
        }),
      },
      response => {
        setIsFetching(false)
        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')
        // updateResource()
        setEvent({ relation: { ...relation, status: response.status } })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        setIsFetching(false)
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  const handleReturn = () => {
    setIsFetching(true)
    setState(false)

    fetchDataHandleAuthError(
      relation['@id'],
      'PUT',
      {
        body: JSON.stringify({
          returnExplanation,
          status: STATUS_RETURNED,
        }),
      },
      response => {
        setIsFetching(false)
        notification('success', 'T_FORM_RECORD_UPDATED', 'T_FORM_SUCCESS')
        // updateResource()
        setEvent({ relation: { ...relation, status: response.status } })
      },
      error => {
        if (error.response.title === 'AbortError') {
          return
        }

        setIsFetching(false)
        notification('error', error.response.detail, error.response.title)
      }
    )
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={5}>
          <Typography
            className={classes.margin}
            variant={'subtitle1'}
            color={'primary'}
          >
            {nl2br(relation.title)}
          </Typography>
          <Typography
            className={classes.margin}
            variant={'subtitle2'}
            component={'p'}
            color={'primary'}
          >
            {nl2br(relation.description)}
          </Typography>
          {relation.isPublicationAccepted && (
            <Typography
              className={classes.margin}
              variant={'subtitle2'}
              component={'p'}
              color={'textSecondary'}
            >
              Jest zgoda na publikację.
            </Typography>
          )}
          {relation.link && (
            <ButtonDownload
              text={'opcjonalny link do materiałów'}
              path={relation.link}
            />
          )}
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid
            container
            spacing={3}
            alignItems={'center'}
            className={classes.margin}
          >
            <Grid item xs={2} xl={1}>
              <IconButton
                className={classes.buttonIcon}
                onClick={() => onArrowSliderClick('prev')}
              >
                <PrevIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8} xl={10}>
              <TinySlider settings={settingsTinySlider} ref={ref}>
                {relation.attachments.map((el, index) => (
                  <div key={index} style={{ position: 'relative' }}>
                    <img
                      src={`${process.env.REACT_APP_API_ENTRYPOINT}/${el.file.url}`}
                      alt="test"
                      className={classes.img}
                    />
                    <Typography
                      variant={'subtitle1'}
                      color={'primary'}
                      style={{ fontStyle: 'italic' }}
                    >
                      {el.title && <div>Nazwa: {el.title}</div>}
                      {el.author && <div>Autor: {el.author}</div>}
                    </Typography>
                  </div>
                ))}
              </TinySlider>
            </Grid>
            <Grid item xs={2} xl={1}>
              <IconButton
                className={classes.buttonIcon}
                onClick={() => onArrowSliderClick('next')}
              >
                <NextIcon />
              </IconButton>
            </Grid>
          </Grid>
          {relation.attachments?.length && (
            <ButtonDownload text="Pobierz zdjęcia" onClick={handleDownload} />
          )}
        </Grid>
      </Grid>
      {['PENDING', STATUS_RETURNED].includes(relation.status) && (
        <div className={classes.buttons}>
          <Button
            variant="outlined"
            className={classes.buttonRed}
            onClick={handleStatusChange(STATUS_REJECTED)}
          >
            <span>{translate('Odrzuć')}</span>
          </Button>
          {relation.status !== STATUS_RETURNED && (
            <Button
              color={'secondary'}
              variant="outlined"
              onClick={handleChagneState}
            >
              <span>{translate('Zwróc')}</span>
            </Button>
          )}

          <CustomDialog
            handleToogle={handleChagneState}
            open={state}
            title={'Informacja dla Wnioskodawcy'}
            confirmedButton={true}
            onConfirm={handleReturn}
            component={() => (
              <StringType
                multiline={true}
                name={'returnExplanation'}
                rows={3}
                rowsMax={3}
                value={returnExplanation}
                setValue={handleReturnExplanation}
                disabled={isFetching}
                renderError={false}
                type={'textarea'}
                setError={() => {}}
                label={''}
                width={'100%'}
              />
            )}
          />
          <Button
            color={'secondary'}
            variant="contained"
            onClick={handleStatusChange(STATUS_ACCEPTED)}
          >
            <span>{translate('Akceptuj')}</span>
          </Button>
        </div>
      )}
      {[STATUS_ACCEPTED, STATUS_REJECTED].includes(relation.status) && (
        <Grid container spacing={3} className={classes.marginBot}>
          <Grid item xs={12} md={2}>
            <Typography variant={'subtitle1'} color={'primary'}>
              <Moment date={relation.updatedAt} format={'DD.MM.YYYY'} />
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography
              variant={'h3'}
              color={relation.status === STATUS_REJECTED ? 'error' : 'primary'}
              className={clsx(
                classes.marginBot,
                relation.status === STATUS_ACCEPTED && classes.textGreen
              )}
            >
              {translate(`T_BUDGET_CHANGE_STATUS_${relation.status}`)}
            </Typography>
          </Grid>
          <Grid item xs={6} md={8}>
            <Typography
              variant={'subtitle1'}
              color={'primary'}
              style={{ lineHeight: '28px' }}
            >
              {relation?.userAcceptedRejected?.replace('|', ' ')}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  )
}
