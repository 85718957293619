export const STATUS_NOTREPORTED = 'NOTREPORTED'
export const STATUS_REPORTED = 'REPORTED'
export const STATUS_ACCEPTED = 'ACCEPTED'

const schema = {
  endpoint: '/proposal_quarterly_declarations',
  endpoint_latest: '/proposal_quarterly_declarations/latest',
  endpoint_report: '/proposal_quarterly_declarations/:id/report',
  endpoint_accept: '/proposal_quarterly_declarations/:id/accept',
  endpoint_cancel: '/proposal_quarterly_declarations/:id/cancel',
  endpoint_download: '/proposal_quarterly_declarations/:id/download',
  endpoint_delete: '/proposal_quarterly_declarations/:id',
}

export default schema
