import React, { useCallback, useEffect, useState } from 'react'
import { List, ListItem, ListItemText } from '@material-ui/core'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { ButtonPlus } from 'core/components/buttons/button-plus'
import { CustomDialog } from 'core/components/Dialog'
import { fetchDataHandleAuthError } from 'core/_helpers/fetchDataHandleAuthError'
import schema, { TYPE_TRAINING_INQUIRY_BIS } from '_schema/proposalEvents'
import { notification } from 'core/_helpers/notification'
import Moment from 'react-moment'
import schemaTraining from '_schema/proposalTraining'
import { translate } from 'core/_helpers/translate'

const useStyle = makeStyles(theme => ({
  item: {
    borderTop: `1px solid ${theme.palette.separator}`,
    borderBottom: `1px solid ${theme.palette.separator}`,
    marginTop: '-1px',
    borderLeftStyle: 'solid',
    borderLeftWidth: 8,
    borderLeftColor: 'transparent',
    background: theme.palette.white,
    color: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.main,
      color: theme.palette.white,
    },
  },
  itemSelected: {
    backgroundColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.white,
  },
  itemColorYellow: {
    borderLeftColor: theme.palette.warning.main,
  },
  itemColorRed: {
    borderLeftColor: '#EF957A',
  },
  itemColorBlue: {
    borderLeftColor: '#8ED5EB',
  },
  itemIndex: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    maxWidth: '30px',
    width: '100%',
  },
  itemDate: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
    maxWidth: '100px',
    width: '100%',
  },
  itemName: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 400,
  },
  itemInfo: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
  },
}))

export const ListEvent = ({
  resource,
  events,
  setEvents,
  typesEvent,
  tab,
  event,
  setEvent,
  handleUpdateEvents,
}) => {
  const classes = useStyle()
  const [open, setOpen] = useState(false)
  const [openTraining, setOpenTraining] = useState(false)
  const [training, setTraining] = useState([])

  const handleToogleModal = useCallback(() => {
    setOpen(prevState => !prevState)
  }, [])

  const handleToogleOpenTraining = useCallback(() => {
    setOpenTraining(prevState => !prevState)
  }, [])

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller
    const iri = `${schemaTraining.endpoint}?contest.uuid=${resource.contest.uuid}&pagination=false`
    fetchDataHandleAuthError(iri, 'GET', { signal }, resp => {
      setTraining(resp['hydra:member'])
    })
    return () => controller.abort()
  }, [resource.contest.uuid])

  const handleAddEvent = useCallback(
    type => {
      if (type === TYPE_TRAINING_INQUIRY_BIS) {
        setOpenTraining(true)
        setOpen(false)
      } else {
        const url = schema.endpoint
        fetchDataHandleAuthError(
          url,
          'POST',
          {
            body: JSON.stringify({
              type,
              proposal: resource['@id'],
            }),
          },
          resp => {
            setOpen(prevState => !prevState)
            setEvents(resp)
            handleUpdateEvents()
            notification('success', 'Zdarzenie zostało dodane')
          },
          error => {
            notification('error', error.response.detail)
          }
        )
      }
    },
    [resource, setEvents, handleUpdateEvents]
  )
  const handleTrainingEvent = useCallback(
    training => {
      const url = schema.endpoint
      fetchDataHandleAuthError(
        url,
        'POST',
        {
          body: JSON.stringify({
            type: TYPE_TRAINING_INQUIRY_BIS,
            proposal: resource['@id'],
            training: training['@id'],
          }),
        },
        resp => {
          setOpenTraining(prevState => !prevState)
          setEvents(resp)
          notification('success', 'Zdarzenie zostało dodane')
        },
        error => {
          notification('error', error.response.detail)
        }
      )
    },
    [resource, setEvents]
  )

  const AddEventList = () => (
    <List>
      {Object.keys(typesEvent).map(
        el =>
          typesEvent[el].added && (
            <ListItem
              key={el}
              className={classes.item}
              button
              onClick={() => handleAddEvent(el)}
            >
              <ListItemText disableTypography className={classes.itemName}>
                {typesEvent[el].name}
              </ListItemText>
            </ListItem>
          )
      )}
    </List>
  )
  const AddTrainingList = () => (
    <List>
      {training.map((el, index) => (
        <ListItem
          key={el.uuid}
          className={classes.item}
          button
          onClick={() => handleTrainingEvent(el)}
        >
          <ListItemText disableTypography className={classes.itemName}>
            <strong>
              {translate('Szkolenie ')} {index + 1}
            </strong>{' '}
            -{' '}
            <span>
              <Moment date={el.contestTraining.date} format={'DD.MM.YYYY'} />
            </span>
          </ListItemText>
        </ListItem>
      ))}
    </List>
  )

  return (
    <>
      <List component={'nav'} aria-label={'Lista zdarzeń'}>
        {events.map(
          (el, index) =>
            typesEvent[el.type].tab.includes(tab) && (
              <ListItem
                button
                selected={event === el.uuid}
                onClick={() => setEvent(el.uuid, resource.uuid)}
                key={index}
                classes={{
                  selected: classes.itemSelected,
                }}
                className={clsx(
                  classes.item,
                  typesEvent[el.type].color === 'yellow' &&
                    classes.itemColorYellow,
                  typesEvent[el.type].color === 'red' && classes.itemColorRed,
                  typesEvent[el.type].color === 'blue' && classes.itemColorBlue
                )}
              >
                <ListItemText disableTypography className={classes.itemIndex}>
                  {index + 1}
                </ListItemText>
                <ListItemText disableTypography className={classes.itemDate}>
                  {typesEvent[el.type].date(el) && (
                    <Moment
                      date={typesEvent[el.type].date(el)}
                      format={'DD.MM.YYYY'}
                    />
                  )}
                </ListItemText>
                <ListItemText disableTypography className={classes.itemName}>
                  {typesEvent[el.type].name}
                </ListItemText>
                <ListItemText disableTypography className={classes.itemInfo}>
                  {typesEvent[el.type].info(el)}
                </ListItemText>
              </ListItem>
            )
        )}
      </List>
      <ButtonPlus text={'Dodaj zdarzenie'} onClick={handleToogleModal} />
      <CustomDialog
        handleToogle={handleToogleModal}
        open={open}
        title={'Dodaj zdarzenie'}
        component={AddEventList}
        dividers={false}
      />
      <CustomDialog
        handleToogle={handleToogleOpenTraining}
        open={openTraining}
        title={'Wybierz szkolenie dla którego ponownie wygenerować ankietę'}
        component={AddTrainingList}
        dividers={false}
      />
    </>
  )
}
